import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  FormFieldType,
  ModalWindow,
  RevenueCenterOrderWindow as OrderWindow
} from 'types'
import { closeModal } from 'slices/modal'
import { ErrMsg, Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { serviceTypeOptions, weekdayOptions } from 'config'
import { RequestStatus, makeErrMsg } from 'utils'
import {
  fetchRevenueCenterOrderWindows,
  createRevenueCenterOrderWindows,
  resetRevenueCenterOrderWindows,
  selectRevenueCenterOrderWindows
} from 'slices/revenueCenterOrderWindows'

const hoursErrorMessage =
  'Order windows cannot be used with revenue centers that have regular hours set. Please remove hours first.'

const RevenueCenterOrderWindow = ({
  args,
  modalWindow
}: {
  args: { revenueCenterId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectRevenueCenterOrderWindows)
  const isLoading = loading === RequestStatus.Pending
  const [submitted, setSubmitted] = useState(false)
  const { revenueCenterId } = args
  const errMsg = makeErrMsg(error)
  const hoursError = errMsg?.includes('Hours are already defined')
    ? hoursErrorMessage
    : null

  const submit = async (values: OrderWindow) => {
    if (revenueCenterId) {
      values.revenue_center_id = Number(revenueCenterId)
      dispatch(createRevenueCenterOrderWindows({ data: [values] }))
      setSubmitted(true)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetRevenueCenterOrderWindows())
    }
  }, [dispatch])

  useEffect(() => {
    if (submitted && !error && !isLoading) {
      const params = { revenue_center_id: Number(revenueCenterId) }
      dispatch(fetchRevenueCenterOrderWindows(params))
      dispatch(closeModal())
    }
  }, [dispatch, submitted, error, revenueCenterId, isLoading])

  return (
    <div>
      <ModalHeader
        title="Add New Order Window"
        subtitle="Please fill in all of the fields below"
      />
      <ErrMsg errMsg={hoursError} />
      <Form
        fields={revenueCenterOrderWindowFields}
        data={revenueCenterOrderWindowEmpty}
        error={hoursError ? null : error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const revenueCenterOrderWindowEmpty = {
  weekday: 'SUNDAY',
  service_type: 'PICKUP',
  start_time: '00:00:00',
  end_time: '00:00:00',
  order_by_weekday: 'SUNDAY',
  order_by_time: '00:00:00'
}

const revenueCenterOrderWindowFields: FormFieldType[] = [
  {
    fieldType: 'Select',
    type: 'text',
    name: 'weekday',
    options: weekdayOptions,
    label: 'Weekday',
    required: true
  },
  {
    fieldType: 'Select',
    type: 'text',
    name: 'service_type',
    options: serviceTypeOptions.filter(
      service => service.value === 'PICKUP' || service.value === 'DELIVERY'
    ),
    label: 'Service Type',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'start_time',
    label: 'Start Time',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'end_time',
    label: 'End Time',
    required: true
  },
  {
    fieldType: 'Select',
    type: 'text',
    name: 'order_by_weekday',
    options: weekdayOptions,
    label: 'Order By Weekday',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'order_by_time',
    label: 'Order By Time',
    required: true
  }
]

export default RevenueCenterOrderWindow
