import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { LoyaltyTier } from 'types'
import { showNotification } from 'slices/notification'

export interface LoyaltyTierState {
  data: LoyaltyTier | null
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyTierState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type FetchLoyaltyTierParams = {
  expand?: string
  with_related?: string
}

export const fetchLoyaltyTier = createAsyncThunk<
  LoyaltyTier,
  { loyaltyTierId?: string | number; params?: FetchLoyaltyTierParams | void },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyTier/fetchLoyaltyTier',
  async ({ loyaltyTierId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `loyalty-tiers/${loyaltyTierId}${queryParams}`
      const resp = (await api?.request(endpoint)) as LoyaltyTier
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createLoyaltyTier = createAsyncThunk<
  LoyaltyTier,
  { data: LoyaltyTier; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyTier/createLoyaltyTier',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'loyalty-tiers',
        'POST',
        data
      )) as LoyaltyTier
      dispatch(showNotification('Loyalty tier successfully created!'))
      const pathname = `/loyalty/loyalty-programs/${data.loyalty_program_id}/tiers/${resp.loyalty_tier_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateLoyaltyTier = createAsyncThunk<
  LoyaltyTier,
  {
    loyaltyTierId: string | number
    data: LoyaltyTier
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyTier/updateLoyaltyTier',
  async ({ loyaltyTierId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-tiers/${loyaltyTierId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as LoyaltyTier
      dispatch(showNotification('Loyalty tier successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyTierSlice = createSlice({
  name: 'loyaltyTier',
  initialState,
  reducers: {
    resetLoyaltyTier: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyTier.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyTier.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchLoyaltyTier.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createLoyaltyTier.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyTier.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createLoyaltyTier.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateLoyaltyTier.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateLoyaltyTier.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateLoyaltyTier.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyTier } = loyaltyTierSlice.actions

export const selectLoyaltyTier = (state: RootState) => state.loyaltyTier

export default loyaltyTierSlice.reducer
