import styled from '@emotion/styled'
import { NavArrowDown, NavArrowUp } from 'iconoir-react'

const DesignInputNumberArrowsView = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  right: -0.1rem;
  bottom: 0.3rem;
  width: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DesignInputNumberArrowsButton = styled.button`
  display: block;
  color: ${props => props.theme.colors.text.primary};
  line-height: 0;
  height: 1rem;

  &:hover,
  &:active {
    opacity: 0.5;
  }
`

const DesignInputNumberArrows = ({
  value,
  step,
  min,
  max,
  setValue
}: {
  value: string | number
  step: number
  min?: number
  max?: number
  setValue: (value: string | number) => void
}) => {
  const decimals = step.toString().split('.')[1]
  const places = decimals ? decimals.length : 0

  const increment = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const val = typeof value === 'string' ? Number(value) || 0 : value
    if (max !== undefined && val >= max) return
    if (step) setValue(Number((val + step).toFixed(places)))
  }

  const decrement = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const val = typeof value === 'string' ? Number(value) || 0 : value
    if (min !== undefined && val <= min) return
    if (step) setValue(Number((val - step).toFixed(places)))
  }

  return (
    <DesignInputNumberArrowsView>
      <DesignInputNumberArrowsButton tabIndex={-1} onClick={increment}>
        <NavArrowUp width="1.3rem" height="1.3rem" />
      </DesignInputNumberArrowsButton>
      <DesignInputNumberArrowsButton tabIndex={-1} onClick={decrement}>
        <NavArrowDown width="1.3rem" height="1.3rem" />
      </DesignInputNumberArrowsButton>
    </DesignInputNumberArrowsView>
  )
}

export default DesignInputNumberArrows
