import styled from '@emotion/styled'

const ListLinks = styled.div`
  padding: 0 2rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`

export default ListLinks
