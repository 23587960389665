import TextHeading from 'components/TextHeading'
import {
  OnboardingStepContent,
  OnboardingStepVideo,
  OnboardingStepView
} from './OnboardingSteps.styled'

const OnboardingStep = ({
  children,
  src,
  title
}: {
  children: React.ReactNode
  src: string
  title: string
}) => {
  return (
    <OnboardingStepView>
      <OnboardingStepVideo src={src} title={title} allowFullScreen />
      <OnboardingStepContent>
        <TextHeading as="h3" size="h5">
          {title}
        </TextHeading>
        {children}
      </OnboardingStepContent>
    </OnboardingStepView>
  )
}

export default OnboardingStep
