import { IconoirProvider } from 'iconoir-react'
import { NavCardProps } from 'types'
import NavCard from './NavCard'
import { NavCardsItem, NavCardsItems, NavCardsView } from './NavCards.styled'

const NavCards = ({ items }: { items: NavCardProps[] }) => {
  return (
    <NavCardsView>
      <IconoirProvider
        iconProps={{
          width: '2rem',
          height: '2rem'
        }}
      >
        <NavCardsItems>
          {items.map(item => (
            <NavCardsItem key={item.title}>
              <NavCard {...item} />
            </NavCardsItem>
          ))}
        </NavCardsItems>
      </IconoirProvider>
    </NavCardsView>
  )
}

export default NavCards
