import { Categories, Files, FormFieldType } from 'types'
import { sortBy } from './helpers'

export const assembleCategories = (categories: Categories) => {
  const parents = sortBy(
    categories.filter(i => !i.parent_category_id),
    'menu_position'
  )
  const children = categories
    .filter(i => i.parent_category_id)
    .reduce(
      (obj, i) => {
        if (!i.parent_category_id) return obj
        const child = { ...i, full_name: `${i.full_name}` }
        const existing = obj[i.parent_category_id] || []
        obj[i.parent_category_id] = sortBy(
          [...existing, child],
          'menu_position'
        )
        return obj
      },
      {} as Record<number, Categories>
    )
  return parents.reduce((arr, i) => {
    return [...arr, { ...i, children: children[i.category_id] || [] }]
  }, [] as Categories)
}

export const makeFilesLookup = (files?: Files) => {
  return (files || []).reduce(
    (obj, i) => ({ ...obj, [i.file_type]: i.file_url }),
    {} as Record<string, string>
  )
}

export const nutritionalInfoFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'serving_size',
    required: false,
    label: 'Serving Size',
    parenthetical: '(in ounces)',
    error: 'Please enter serving size as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'calories',
    required: false,
    label: 'Calories',
    error: 'Please enter calories as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'total_fat',
    required: false,
    label: 'Total Fat',
    parenthetical: '(in grams)',
    error: 'Please enter total fat as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'saturated_fat',
    required: false,
    label: 'Saturated Fat',
    parenthetical: '(in grams)',
    error: 'Please enter saturated fat as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'trans_fat',
    required: false,
    label: 'Trans Fat',
    parenthetical: '(in grams)',
    error: 'Please enter trans fat as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'cholesterol',
    required: false,
    label: 'Cholesterol',
    parenthetical: '(in milligrams)',
    error: 'Please enter cholesterol as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'sodium',
    required: false,
    label: 'Sodium',
    parenthetical: '(in milligrams)',
    error: 'Please enter sodium as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'total_carbs',
    required: false,
    label: 'Total Carbs',
    parenthetical: '(in grams)',
    error: 'Please enter total carbs as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'dietary_fiber',
    required: false,
    label: 'Dietary Fiber',
    parenthetical: '(in grams)',
    error: 'Please enter dietary fiber as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'sugars',
    required: false,
    label: 'Sugar',
    parenthetical: '(in grams)',
    error: 'Please enter sugar as a number with or without decimals'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'protein',
    required: false,
    label: 'Protein',
    parenthetical: '(in grams)',
    error: 'Please enter protein as a number with or without decimals'
  }
]
