import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Item, ModifierGroups } from 'types'
import { fetchCategory } from './category'
import { fetchModifierGroup } from './modifierGroup'
import { showNotification } from './notification'

export interface ItemState {
  data: Item | null
  loading: RequestStatus
  error: RequestError
}

const initialState: ItemState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type ItemParams = {
  expand?: string
  with_related?: string
}

export const fetchItem = createAsyncThunk<
  Item | null,
  { itemId: string | number; params?: ItemParams },
  { state: RootState; rejectValue: RequestError }
>(
  'item/fetchItem',
  async ({ itemId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `items/${itemId}${queryParams}`
      return (await api?.request(endpoint)) as Item
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createItem = createAsyncThunk<
  Item,
  {
    menuId: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
    data: Item
    navigate?: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'item/createItem',
  async (
    { menuId, categoryId, modifierGroupId, data, navigate },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('items', 'POST', data)) as Item
      const itemId = resp.item_id
      const itemData = [{ item_id: itemId, menu_position: 99 }]
      if (categoryId) {
        await api?.request(`categories/${categoryId}/items`, 'POST', itemData)
        dispatch(
          fetchCategory({ categoryId, params: { with_related: 'items' } })
        )
      } else if (modifierGroupId) {
        await api?.request(
          `modifier-groups/${modifierGroupId}/items`,
          'POST',
          itemData
        )
        dispatch(
          fetchModifierGroup({
            modifierGroupId,
            params: { with_related: 'items' }
          })
        )
      }
      dispatch(showNotification('Item successfully created!'))
      if (navigate) {
        const pathname = categoryId
          ? `/menus/menus/${menuId}/categories/${categoryId}/items/${itemId}`
          : `/menus/menus/${menuId}/modifier-groups/${modifierGroupId}/items/${itemId}`
        navigate({ pathname })
      }
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItem = createAsyncThunk<
  Item,
  {
    itemId: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
    data: Item
  },
  { state: RootState; rejectValue: RequestError }
>(
  'item/updateItem',
  async (
    { categoryId, modifierGroupId, itemId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      await api?.request(`items/${itemId}`, 'PUT', data)
      if (categoryId) {
        dispatch(
          fetchCategory({ categoryId, params: { with_related: 'items' } })
        )
      } else if (modifierGroupId) {
        dispatch(
          fetchModifierGroup({
            modifierGroupId,
            params: { with_related: 'items' }
          })
        )
      }
      dispatch(showNotification('Item successfully updated!'))
      return (await api?.request(
        `items/${itemId}?with_related=modifier_groups`
      )) as Item
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const copyItems = createAsyncThunk<
  void,
  {
    itemIds: string[] | number[]
    categoryId?: string | number
    modifierGroupId?: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'item/copyItems',
  async (
    { itemIds, categoryId, modifierGroupId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const itemData = itemIds.map(itemId => ({
        item_id: itemId,
        menu_position: 99
      }))
      if (categoryId) {
        await api?.request(`categories/${categoryId}/items`, 'POST', itemData)
        dispatch(showNotification('Item(s) successfully copied!'))
      } else if (modifierGroupId) {
        await api?.request(
          `modifier-groups/${modifierGroupId}/items`,
          'POST',
          itemData
        )
        dispatch(showNotification('Modifier(s) successfully copied!'))
      }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    resetItem: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItem.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createItem.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItem.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(copyItems.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(copyItems.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(copyItems.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItem } = itemSlice.actions

export const selectItem = (state: RootState) => state.item
export const selectItemGroups = (state: RootState) => {
  const { data, loading, error } = state.item
  if (!data?.modifier_groups) {
    return { items: [], loading, error }
  }
  const items = data.modifier_groups.reduce((arr, itemGroup) => {
    const { modifier_group, menu_position } = itemGroup
    return [...arr, { ...modifier_group, menu_position }]
  }, [] as ModifierGroups)

  return { items, loading, error }
}

export default itemSlice.reducer
