import configs from 'config/enviroment'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { User } from 'types'
import { showNotification } from 'slices/notification'

export interface UserState {
  data: User | null
  loading: RequestStatus
  error: RequestError
}

const initialState: UserState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchUser = createAsyncThunk<
  User,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('user/fetchUser', async (userId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `users/${userId}`
    const resp = (await api?.request(endpoint)) as User
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createUser = createAsyncThunk<
  User,
  { data: User; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'user/createUser',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('users', 'POST', data)) as User
      dispatch(showNotification('User successfully created!'))
      const pathname = `/settings/users/manage/${resp.user_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const sendActivation = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'user/sendActivation',
  async (userId, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `users/${userId}/send-activation-email`
      const data = { link_url: configs.activationUrl }
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Activation email sent!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const setNewPassword = createAsyncThunk<
  void,
  { new_password: string; token: string },
  { state: RootState; rejectValue: RequestError }
>('user/setNewPassword', async (data, { dispatch, rejectWithValue }) => {
  try {
    const endpoint = 'reset-password/set-new-password'
    const res = await fetch(`${configs.apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (res.ok) {
      dispatch(showNotification('Password successfully reset!'))
    } else {
      const parsed = await res.json()
      return rejectWithValue(parsed as RequestError)
    }
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const resetPassword = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: RequestError }
>('user/resetPassword', async (email, { dispatch, rejectWithValue }) => {
  try {
    const endpoint = 'reset-password/send-email'
    const link_url = configs.activationUrl
    const res = await fetch(`${configs.apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, link_url })
    })
    if (res.ok) {
      dispatch(showNotification('Password reset email sent!'))
    } else {
      const parsed = await res.json()
      return rejectWithValue(parsed as RequestError)
    }
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const updateUser = createAsyncThunk<
  User,
  {
    userId: string | number
    data: any
  },
  { state: RootState; rejectValue: RequestError }
>(
  'user/updateUser',
  async ({ userId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `users/${userId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as User
      dispatch(showNotification('User successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteUser = createAsyncThunk<
  void,
  {
    userId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'user/deleteUser',
  async ({ userId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `users/${userId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('User removed!'))
      navigate({ pathname: '/settings/users' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(sendActivation.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(sendActivation.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(sendActivation.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(setNewPassword.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(setNewPassword.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(setNewPassword.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(resetPassword.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(resetPassword.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteUser.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetUser } = userSlice.actions

export const selectUser = (state: RootState) => state.user

export default userSlice.reducer
