import { ItemsParams } from 'slices/items'
import { Category, ModifierGroup, ServiceType } from 'types'
import { RequestError, RequestStatus } from 'utils'

export enum AttributeType {
  PRICE = 'PRICE',
  POINTS = 'POINTS',
  SERVING_SIZE = 'SERVING_SIZE',
  MIN_QUANTITY = 'MIN_QUANTITY',
  MAX_QUANTITY = 'MAX_QUANTITY',
  INCREMENT = 'INCREMENT',
  POS_ID = 'POS_ID',
  TAX_RATE = 'TAX_RATE'
}

export interface Attribute {
  attribute_type: AttributeType
  attribute_value: string
  category_id: number | null
  item_id: number
  modifier_group_id: number | null
  region_id: number | null
  revenue_center_id: number | null
  service_type: ServiceType
  error?: string
}

export type AttributesParams = ItemsParams & {
  attribute_type: AttributeType
}

export interface AttributesState {
  data: Attribute[]
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface AttributesAPI {
  data: Attribute[]
  links: {
    next: string
  }
}

export interface AttributesSelectOptions {
  value: string
  name: string
  isDisabled: boolean
}

export interface AttributesFilters {
  category_id?: number
  modifier_group_id?: number
  attribute_type: AttributeType
}

export interface AttributesTableRowORColumn {
  name: string
  value: null | number
  columnKey?: 'region_id' | 'revenue_center_id'
}

export type CategoriesSelectOptions = Partial<Category> &
  AttributesSelectOptions

export type ModifierGroupsSelectOptions = Partial<ModifierGroup> &
  AttributesSelectOptions
