import { cleanPhone } from '@open-tender/utils'
import { BulkOrder } from 'types'
import { formatDollars } from 'utils'

export interface ReceiptRow {
  label: string
  value: string | number | undefined
  indent?: boolean
  bold?: boolean
  withSeparator?: boolean
}

export const makeFulfillment = (ff: BulkOrder['order_fulfillment']) => {
  if (!ff) return null
  const arrival = ff.arrival_info ? ` @ ${ff.arrival_info}` : ''
  return `${ff.vehicle_type || ''} ${ff.vehicle_color || ''} ${
    ff.vehicle_id || ''
  }${arrival}`
}

export const makeReceipt = (order: BulkOrder) => {
  if (!order) return {}

  const customerSection: ReceiptRow[] = order.customer
    ? [
        {
          label: 'Name',
          value: [order.customer.first_name, order.customer.last_name].join(' ')
        },
        { label: 'Email', value: order.customer.email },
        { label: 'Phone', value: cleanPhone(order.customer.phone) },
        { label: 'Company', value: order.customer.company }
      ]
    : [{ label: 'no customer associated with this order', value: ' ' }]

  const addressSection: ReceiptRow[] = order.customer_address
    ? [
        {
          label: 'Street & Unit',
          value: `${order.customer_address.street}${order.customer_address.unit && ', ' + order.customer_address.unit}`
        },
        {
          label: 'City, State & Zip',
          value: `${order.customer_address.city}, ${order.customer_address.state} ${order.customer_address.postal_code}`
        },
        { label: 'Company', value: order.customer_address.company },
        {
          label: 'Contact & Phone',
          value: `${order.customer_address.contact}${order.customer_address.phone && ' @ ' + cleanPhone(order.customer_address.phone)}`
        }
      ]
    : [{ label: 'no address associated with this order', value: ' ' }]

  const surcharges: ReceiptRow[] =
    order.surcharges?.map(i => ({
      label: i.name,
      value: formatDollars(i.amount),
      indent: true
    })) || []

  const discounts: ReceiptRow[] =
    order.discounts?.map(i => ({
      label: i.name,
      value: formatDollars(i.amount),
      indent: true
    })) || []
  const giftCards: ReceiptRow[] = order.gift_cards.map(i => ({
    label: i.card_number,
    value: formatDollars(i.amount),
    indent: true
  }))
  const taxes: ReceiptRow[] =
    order.taxes?.map(i => ({
      label: i.name,
      value: formatDollars(i.amount),
      indent: true
    })) || []
  const tenderTypes = order.tenders?.length
    ? order.tenders.map(i => i.tender_type).join(', ')
    : 'none'

  const totalPriceWithDiscount =
    parseFloat(order.subtotal) +
    parseFloat(order.surcharge) -
    parseFloat(order.discount)

  const totalsSection: ReceiptRow[] = [
    { label: 'Subtotal', value: formatDollars(order.subtotal) },
    ...surcharges,
    { label: 'Surcharge', value: formatDollars(order.surcharge) },
    ...discounts,
    { label: 'Discount', value: formatDollars(order.discount) },
    {
      label: 'Total before Tax & Tip',
      value: formatDollars(totalPriceWithDiscount),
      bold: true
    },
    ...giftCards,
    { label: 'Gift Cards', value: formatDollars(order.gift_card || '0.00') },
    ...taxes,
    { label: 'Tax', value: formatDollars(order.tax) },
    { label: 'Tip', value: formatDollars(order.tip) },
    { label: 'Shipping', value: formatDollars(order.shipping) },
    {
      label: 'TOTAL AFTER TAX & TIP',
      value: formatDollars(order.total),
      bold: true,
      withSeparator: true
    },
    { label: 'Payment Type(s)', value: tenderTypes }
  ]

  const orderDetailsSection: ReceiptRow[] = order.order_properties
    ? [
        {
          label: 'Eating Utensils',
          value: order.order_properties.eating_utensils ? 'Yes' : 'No'
        },
        {
          label: 'Group Order',
          value: order.order_properties.is_group ? 'Yes' : 'No'
        },
        {
          label: 'Serving Utensils',
          value: order.order_properties.serving_utensils ? 'Yes' : 'No'
        },
        {
          label: 'No. of People',
          value: order.order_properties.person_count
        },
        ...(order.order_properties?.dispatched
          ? [
              {
                label: 'Delivered By',
                value: order.order_properties.delivery_service
              },
              {
                label: 'Delivery ID',
                value: order.order_properties.delivery_id
              }
            ]
          : [])
      ]
    : []

  const customerNotesSection: ReceiptRow[] = [
    {
      label: '',
      value: order.order_properties?.notes || 'none specified'
    }
  ]

  const internalNotesSection: ReceiptRow[] = [
    {
      label: '',
      value: order.order_properties?.notes_internal || 'none specified'
    }
  ]

  return {
    customerSection,
    addressSection,
    totalsSection,
    orderDetailsSection,
    customerNotesSection,
    internalNotesSection
  }
}
