import { useEffect } from 'react'
import { Cart, CartErrors } from '@open-tender/types'
import { useAppDispatch } from 'app/hooks'
import { ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { setCart } from 'slices/order'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { Button, InvalidItems } from 'components'

const OrderCartErrors = ({
  args,
  modalWindow
}: {
  args: {
    newCart: Cart
    cartErrors: CartErrors
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { newCart, cartErrors } = args
  const { missingItems, invalidItems } = cartErrors

  const update = () => {
    dispatch(closeModal())
  }

  useEffect(() => {
    return () => {
      dispatch(setCart(newCart))
    }
  }, [dispatch, newCart])

  return (
    <>
      <ModalHeader title="Change order type" />
      <ModalContent>
        <InvalidItems missingItems={missingItems} invalidItems={invalidItems} />
      </ModalContent>
      <ModalFooter>
        <Button color="primary" onClick={update}>
          Remove Invalid Items
        </Button>
      </ModalFooter>
    </>
  )
}

export default OrderCartErrors
