import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Oauth2Client } from 'types'
import { fetchOauth2Clients } from 'slices/oauth2Clients'
import { showNotification } from 'slices/notification'

export interface Oauth2ClientState {
  loading: RequestStatus
  data: Oauth2Client | null
  error: RequestError
}

const initialState: Oauth2ClientState = {
  loading: RequestStatus.Idle,
  data: null,
  error: null
}

export const fetchOauth2Client = createAsyncThunk<
  Oauth2Client | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'oauth2Client/fetchOauth2Client',
  async (oauth2ClientId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `oauth2-clients/${oauth2ClientId}`
      )) as Oauth2Client | null
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createOauth2Client = createAsyncThunk<
  Oauth2Client,
  { data: Oauth2Client; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'oauth2Client/createOauth2Client',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'oauth2-clients',
        'POST',
        data
      )) as Oauth2Client
      dispatch(showNotification('API Client successfully created!'))
      dispatch(fetchOauth2Clients())
      navigate({
        pathname: `/settings/misc/clients/${resp.oauth2_client_id}`
      })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertOauth2Client = createAsyncThunk<
  Oauth2Client,
  { oauth2ClientId: string | number; data: Oauth2Client },
  { state: RootState; rejectValue: RequestError }
>(
  'oauth2Client/upsertOauth2Client',
  async ({ oauth2ClientId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `oauth2-clients/${oauth2ClientId}`,
        'PUT',
        data
      )) as Oauth2Client
      dispatch(showNotification('API Client successfully updated!'))
      dispatch(fetchOauth2Clients())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revokeOauth2Client = createAsyncThunk<
  Oauth2Client,
  { oauth2ClientId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'oauth2Client/revokeOauth2Client',
  async ({ oauth2ClientId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `oauth2-clients/${oauth2ClientId}/revoke`,
        'POST'
      )) as Oauth2Client
      dispatch(showNotification('API Client has been revoked!'))
      dispatch(fetchOauth2Clients())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const oauth2ClientSlice = createSlice({
  name: 'oauth2Client',
  initialState,
  reducers: {
    resetOauth2Client: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOauth2Client.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOauth2Client.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchOauth2Client.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createOauth2Client.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createOauth2Client.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createOauth2Client.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertOauth2Client.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertOauth2Client.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(upsertOauth2Client.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(revokeOauth2Client.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(revokeOauth2Client.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(revokeOauth2Client.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOauth2Client } = oauth2ClientSlice.actions

export const selectOauth2Client = (state: RootState) => state.oauth2Client

export default oauth2ClientSlice.reducer
