import styled from '@emotion/styled'

export const PrintOrderContainer = styled.div`
  display: none;
  @page {
    margin: 1rem 0;
  }
`
export const PrintOrderContent = styled.div<{ isReceipt?: boolean }>`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: ${props => props.theme.colors.info.color};
  padding: ${props => (props.isReceipt ? '2rem' : '4rem')};
`
export const PrintOrderHeader = styled.div<{ isReceipt?: boolean }>`
  margin-bottom: 2.5rem;

  h2 {
    font-size: 20px;
    font-weight: bold;
    ${props =>
      props.isReceipt &&
      `
    display: flex;
    flex-direction:column;
    `}
  }
`
export const PrintOrderDesktop = styled.div`
  display: flex;
  gap: 4rem;
`

export const PrintOrderDesktopLeft = styled.div`
  flex: 4;
`
export const PrintOrderDesktopRight = styled.div`
  flex: 6;
`

export const PrintSection = styled.div`
  margin-bottom: 2rem;
`

export const PrintSeparator = styled.div<{ height?: string }>`
  height: ${props => props.height ?? '1px'};
  margin: 1rem 0;
  background-color: ${props => props.theme.colors.info.color};
`

export const PrintRow = styled.div<{
  isBold?: boolean
  indent?: boolean
  width?: string
  isReceipt?: boolean
}>`
  display: flex;
  justify-content: space-between;
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  margin: 1rem 0;
  width: ${props => props.width ?? ''};

  ${props =>
    props.isReceipt &&
    `
    padding: 1rem;
    color: ${props.theme.colors.text.primary};
    background-color:${props.theme.colors.info.color};
  `}

  & > :nth-of-type(1) {
    padding-left: ${props => (props.indent ? '1.5rem' : '')};
  }
  & > :nth-of-type(2) {
    max-width: 70%;
    text-align: right;
    line-break: anywhere;
  }
`

export const PrintOrderDetailsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20%;

  & > div {
    flex: 40%;
  }
`

export const PrintSignatureSection = styled.div`
  margin-top: -1rem;
  & > div {
    padding: 1rem 0 0.2rem;
    border-bottom: 1px solid ${props => props.theme.colors.info.color};
  }
  & > :nth-of-type(1) {
    margin-bottom: 2rem;
  }
`

export const PrintPackingSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin: -1rem 0 3rem;
  & > div {
    padding: 1rem 0 0.2rem;
    border-bottom: 1px solid ${props => props.theme.colors.info.color};
    flex: 1;
  }
`

export const ItemRowStyled = styled.div<{
  isBold?: boolean
  borderHeight?: string
  isModifier?: boolean
  withUnderlineBorder?: boolean
  isReceipt?: boolean
  isHeader?: boolean
}>`
  display: flex;
  justify-content: space-between;
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  padding: ${props => (props.isModifier ? '0.2rem' : '.75rem')} 0;
  padding-bottom: ${props => props.withUnderlineBorder && '.75rem'};
  border-bottom: ${props =>
      !props.withUnderlineBorder ? '0' : props.borderHeight}
    solid ${props => props.theme.colors.info.color};
  position: relative;

  ${props =>
    props.isReceipt &&
    props.isHeader &&
    `
    padding: 1rem;
    color: ${props.theme.colors.text.primary};
    background-color:${props.theme.colors.info.color};
  `}

  & > div:first-of-type {
    flex: 4;
    display: flex;
    align-items: center;

    & > :nth-of-type(1) {
      position: absolute;
      left: ${props => (props.isModifier ? '4rem' : '-2rem')};
    }
  }
  & > :nth-of-type(1) {
    flex: 1;
  }
  & > :nth-of-type(2) {
    flex: 1;
    text-align: center;
  }
  & > :nth-of-type(3) {
    flex: 1;
    text-align: right;
  }
`
export const ItemRowModifierGroupCheckBox = styled.div`
  width: 1rem;
  height: 1rem;
  border: 1px solid ${props => props.theme.colors.info.color};
  left: 2rem !important;
`
