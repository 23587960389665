import { NavLink } from 'react-router-dom'
import { SidebarLinkProps } from 'types'
import {
  EntitiesNavLinkSubsection,
  EntitiesNavLinkSubsectionLink,
  EntitiesNavLinkText,
  EntitiesNavLinkView
} from './EntitiesNav.styled'

const EntitiesNavLink = ({
  path,
  title,
  end = false,
  subsections
}: SidebarLinkProps) => {
  return (
    <EntitiesNavLinkView>
      <NavLink to={path} end={end} relative="path">
        <EntitiesNavLinkText>{title}</EntitiesNavLinkText>
      </NavLink>
      {subsections && (
        <EntitiesNavLinkSubsection>
          {subsections.map(subsection => {
            const subsectionPath = `${path}/${subsection.path}`
            return (
              <EntitiesNavLinkSubsectionLink key={subsectionPath}>
                <NavLink to={subsectionPath}>
                  <span>{subsection.title}</span>
                </NavLink>
              </EntitiesNavLinkSubsectionLink>
            )
          })}
        </EntitiesNavLinkSubsection>
      )}
    </EntitiesNavLinkView>
  )
}

export default EntitiesNavLink
