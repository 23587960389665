import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { OrderRemainingRefund } from 'types'

export interface OrderRemainingRefundState {
  data: OrderRemainingRefund | null
  loading: RequestStatus
  error: RequestError
}

const initialState: OrderRemainingRefundState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchOrderRemainingRefund = createAsyncThunk<
  OrderRemainingRefund,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'OrderRemainingRefund/fetchOrderRemainingRefund',
  async (orderId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `orders/${orderId}/remaining-refund`
      const resp = (await api?.request(endpoint)) as OrderRemainingRefund
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const orderRemainingRefundSlice = createSlice({
  name: 'OrderRemainingRefund',
  initialState,
  reducers: {
    resetOrderRemainingRefund: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderRemainingRefund.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchOrderRemainingRefund.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      fetchOrderRemainingRefund.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetOrderRemainingRefund } = orderRemainingRefundSlice.actions

export const selectOrderRemainingRefund = (state: RootState) =>
  state.orderRemainingRefund

export default orderRemainingRefundSlice.reducer
