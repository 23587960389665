import { BulkOrder } from 'types'
import {
  OrderCardButtonsOverlay,
  OrderCardButtonsView,
  OrderCardButtonsWorking,
  OrderCardButtonsWrapper
} from './OrderCard.styled'
import Button from 'components/Button'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  closeOrder,
  printOrder,
  resendEmail,
  selectOrderMgmtOrder
} from 'slices/orderMgmt'
import Loader from 'components/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import { openModal } from 'slices/modal'
import { editBulkOrder, selectOrder } from 'slices/order'
import { useEffect, useState } from 'react'

const OrderCardButtons = ({
  order,
  review
}: {
  order: BulkOrder
  review: (() => void) | null
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isCollecing, setIsCollecting] = useState(false)
  const { storeId } = useParams()
  const {
    receipt_id: orderId,
    receipt_status,
    tenders = [],
    total,
    deposit
  } = order
  const activeOrderId = useAppSelector(selectOrderMgmtOrder)
  const { submitting } = useAppSelector(selectOrder)
  const disabled = activeOrderId === orderId
  const isClosed = receipt_status === 'CLOSED'
  const isHeld = receipt_status === 'HELD'
  const isPending = receipt_status === 'PENDING'
  const closedDisabled = isClosed || isHeld || isPending
  const tendersTotal =
    tenders?.reduce((t, i) => (t += parseFloat(i.amount)), 0.0) || 0.0
  const amountDue = Math.round(parseFloat(total) - tendersTotal)
  const hasDeposit = deposit && amountDue > 0

  useEffect(() => {
    if (isCollecing && submitting) {
      setIsCollecting(false)
      dispatch(openModal({ type: 'orderEditing', disableClose: true }))
    }
  }, [dispatch, isCollecing, submitting])

  const close = () => dispatch(closeOrder(orderId))

  const collect = () => {
    setIsCollecting(true)
    dispatch(editBulkOrder({ bulkOrder: order, isCollect: true }))
  }

  const edit = () => {
    const args = { order, storeId }
    dispatch(openModal({ type: 'orderEdit', args }))
  }

  const cancel = () => {
    const args = { orderId }
    dispatch(openModal({ type: 'orderMgmtCancel', args }))
  }

  const updatePrepStatus = () => {
    const args = { order, storeId }
    dispatch(openModal({ type: 'orderPrepStatus', args }))
  }
  /* eslint-disable-next-line */
  const details = () => navigate(`/orders/${orderId}`)

  const print = () => dispatch(printOrder(orderId))

  const resend = () => dispatch(resendEmail(orderId))

  const closeButton = hasDeposit
    ? { title: 'Collect Payment', onClick: collect, disabled: closedDisabled }
    : { title: 'Close', onClick: close, disabled: closedDisabled }

  const buttons = [
    closeButton,
    { title: 'Edit', onClick: edit, disabled: isClosed },
    { title: 'Cancel', onClick: cancel, disabled: isClosed },
    { title: 'Prep Status', onClick: updatePrepStatus, disabled: false },
    { title: 'Print', onClick: print, disabled: false },
    { title: 'Resend', onClick: resend, disabled: false }
  ]

  return (
    <OrderCardButtonsView>
      {disabled ? (
        <OrderCardButtonsWorking>
          <Loader />
        </OrderCardButtonsWorking>
      ) : review ? (
        <>
          <OrderCardButtonsOverlay />
          <OrderCardButtonsWorking>
            <Button onClick={review} size="small" color="delete">
              Mark Reviewed
            </Button>
          </OrderCardButtonsWorking>
        </>
      ) : null}
      <OrderCardButtonsWrapper>
        {buttons.map(button => (
          <Button
            key={button.title}
            onClick={button.onClick}
            disabled={disabled || button.disabled}
            size="small"
            color="tertiary"
          >
            {button.title}
          </Button>
        ))}
      </OrderCardButtonsWrapper>
    </OrderCardButtonsView>
  )
}

export default OrderCardButtons
