import styled from '@emotion/styled'

interface JsonBlockProps {
  data: Array<any> | object
}

const JsonBlockView = styled.pre`
  padding: 1rem;
  background-color: ${props => props.theme.colors.background.secondary};
  border-style: solid;
  border-width: 0.1rem;
  border-color: ${props => props.theme.colors.border.primary};
`

const JsonBlock = ({ data }: JsonBlockProps) => {
  const json = JSON.stringify(data, null, 2)
  return <JsonBlockView>{json}</JsonBlockView>
}

export default JsonBlock
