import React from 'react'

const Pages = React.lazy(() => import('./Pages'))
const PagesRoutes = React.lazy(() => import('./PagesRoutes'))

const PagesAnnouncement = React.lazy(
  () => import('./PagesAnnouncements/PagesAnnouncement')
)
const PagesAnnouncementRouting = React.lazy(
  () => import('./PagesAnnouncements/PagesAnnouncementRouting')
)

export { Pages, PagesRoutes, PagesAnnouncement, PagesAnnouncementRouting }
