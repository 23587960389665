import { gql } from 'graphql-request'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, ONBOARDING_BRAND_FRAGMENT } from 'utils'
import {
  OnboardingBrand,
  OnboardingBrandUpdate,
  OnboardingBrandMutation
} from 'types'

export interface OnboardingBrandState {
  data: OnboardingBrand | null
  loading: RequestStatus
  error: RequestError
}

export interface OnboardingBrandAPI {
  onboardingBrandMutation: OnboardingBrandMutation
}

const initialState: OnboardingBrandState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const UPDATE_ONBOARDING_BRAND = gql`
  mutation UpdateOnboardingBrand($input: UpdateBrandInput!) {
    updateOnboardingBrand(input: $input) {
      id
      ...OnboardingBrandFragment
    }
  }
  ${ONBOARDING_BRAND_FRAGMENT}
`

export const archiveOnboardingBrand = createAsyncThunk<
  OnboardingBrandAPI,
  { id: string; archived: boolean },
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingBrand/archiveOnboardingBrand',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(UPDATE_ONBOARDING_BRAND, {
        input: params
      })) as OnboardingBrandAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateOnboardingBrand = createAsyncThunk<
  OnboardingBrandAPI,
  OnboardingBrandUpdate,
  { state: RootState; rejectValue: RequestError }
>(
  'onbaordingBrand/updateOnboardingBrand',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(UPDATE_ONBOARDING_BRAND, {
        input: params
      })) as OnboardingBrandAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingBrandSlice = createSlice({
  name: 'onboardingBrand',
  initialState,
  reducers: {
    resetOnboardingBrand: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(archiveOnboardingBrand.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(archiveOnboardingBrand.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(archiveOnboardingBrand.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })

    builder.addCase(updateOnboardingBrand.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateOnboardingBrand.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateOnboardingBrand.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOnboardingBrand } = onboardingBrandSlice.actions

export const selectOnboardingBrand = (state: RootState) => state.onboardingBrand

export default onboardingBrandSlice.reducer
