import TextBody from 'components/TextBody'
import { CheckmateModifier } from 'types'
import { centsToPrice, makeSuspendUntil } from 'utils'
import {
  CheckmateMenuItemDefault,
  CheckmateMenuItemDescription,
  CheckmateMenuItemDetails,
  CheckmateMenuItemSoldOut,
  CheckmateMenuModifierGroups,
  CheckmateMenuModifierView
} from './CheckmateMenu.styled'
import CheckmateMenuModifiers from './CheckmateMenuModifiers'

interface CheckmateMenuItemProps {
  modifier: CheckmateModifier
}

const CheckmateMenuModifier = ({ modifier }: CheckmateMenuItemProps) => {
  const { suspend_until, modifier_groups } = modifier
  const price = centsToPrice(modifier.price)
  const { tax_rate = null } = modifier
  const taxRate = tax_rate !== null ? `${tax_rate.toFixed(3)}%` : null
  const suspendUntilDateStr = makeSuspendUntil(suspend_until)
  const groups =
    modifier_groups && modifier_groups.length ? modifier_groups : null

  return (
    <>
      <CheckmateMenuModifierView>
        <TextBody as="p" size="small" style={{ lineHeight: 1.2 }}>
          {modifier.name} - {price} - {taxRate || 'no tax rate'}
          {modifier.default ? (
            <>
              {' '}
              - <CheckmateMenuItemDefault>default</CheckmateMenuItemDefault>
            </>
          ) : null}
        </TextBody>
        <CheckmateMenuItemDetails>
          {!!modifier.description && (
            <CheckmateMenuItemDescription
              as="p"
              color="secondary"
              size="xsmall"
            >
              {modifier.description}
            </CheckmateMenuItemDescription>
          )}
          {suspendUntilDateStr ? (
            <CheckmateMenuItemSoldOut as="p" size="xsmall">
              Sold out until {suspendUntilDateStr}
            </CheckmateMenuItemSoldOut>
          ) : null}
        </CheckmateMenuItemDetails>
      </CheckmateMenuModifierView>
      {groups && (
        <CheckmateMenuModifierGroups>
          <CheckmateMenuModifiers groups={groups} />
        </CheckmateMenuModifierGroups>
      )}
    </>
  )
}

export default CheckmateMenuModifier
