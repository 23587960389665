import { CustomerAddress } from 'types'
import { OrderCardAddressLine, OrderCardAddressView } from './OrderCard.styled'

const OrderCardAddress = ({
  address
}: {
  address?: CustomerAddress | null
}) => {
  if (!address) {
    return (
      <OrderCardAddressView>
        <OrderCardAddressLine>No address provided</OrderCardAddressLine>
      </OrderCardAddressView>
    )
  }

  const { street, unit, city, state, postal_code, company, contact, phone } =
    address
  const contactPerson =
    contact || phone
      ? `${contact}${contact && phone ? ' @ ' : ''}${phone}`
      : null
  const streetAddress =
    street && city && state && postal_code
      ? `${street}, ${city}, ${state} ${postal_code}`
      : 'No address provided'
  const aptSuite = unit ? ` - #${unit}` : ''

  return (
    <OrderCardAddressView>
      <OrderCardAddressLine>
        {streetAddress}
        {aptSuite}
      </OrderCardAddressLine>
      {company && <OrderCardAddressLine>{company}</OrderCardAddressLine>}
      {contactPerson && (
        <OrderCardAddressLine>
          {/* <strong>Contact</strong> */}
          {contactPerson}
        </OrderCardAddressLine>
      )}
    </OrderCardAddressView>
  )
}

export default OrderCardAddress
