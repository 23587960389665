import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus } from 'utils'
import { ModalCancel, ModalHeader } from 'components/Modal'
import { ButtonList, Loader } from 'components'
import { ModalWindow } from 'types'
import { fetchSurcharges, selectSurcharges } from 'slices/surcharges'
import { addSurcharge, selectOrder } from 'slices/order'
import { closeModal } from 'slices/modal'

const OrderSurcharge = ({
  args,
  modalWindow
}: {
  args: null
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data, loading } = useAppSelector(selectSurcharges)
  const { surcharges } = useAppSelector(selectOrder)
  const selectedIds = surcharges.map(i => i.id)
  const isLoading = loading === RequestStatus.Pending

  useEffect(() => {
    dispatch(fetchSurcharges())
  }, [dispatch])

  const choose = (id: number) => {
    dispatch(addSurcharge({ id }))
    dispatch(closeModal())
  }

  const buttons = data.map(i => ({
    title: i.name,
    onClick: () => choose(i.surcharge_id),
    disabled: selectedIds.includes(i.surcharge_id) || isLoading
  }))

  return (
    <>
      <ModalHeader title="Surcharges" subtitle="Add one or more surcharges" />
      {isLoading && <Loader />}
      <ButtonList buttons={buttons} />
      <ModalCancel>Nevermind, don't add a surcharge</ModalCancel>
    </>
  )
}

export default OrderSurcharge
