import {
  PreviewNutritionalInfoView,
  PreviewSectionTitle
} from './Preview.styled'
import { useEffect } from 'react'
import {
  deleteNutritionalInfo,
  fetchNutritionalInfo,
  selectNutritionalInfo,
  updateNutritionalInfo
} from 'slices/nutritionalInfo'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus, nutritionalInfoFields } from 'utils'
import { NutritionalInfo } from 'types'
import Button from 'components/Button'
import Form from 'components/Forms/Form'

const PreviewNutritionalInfo = ({ itemId }: { itemId: number }) => {
  const dispatch = useAppDispatch()
  const { data, error, loading } = useAppSelector(selectNutritionalInfo)
  const isLoading = loading === RequestStatus.Pending

  const submit = (values: NutritionalInfo) => {
    dispatch(updateNutritionalInfo({ itemId, data: values }))
  }

  const remove = () => {
    dispatch(deleteNutritionalInfo({ itemId }))
  }

  useEffect(() => {
    dispatch(fetchNutritionalInfo(itemId))
  }, [dispatch, itemId])

  return (
    <PreviewNutritionalInfoView>
      <PreviewSectionTitle as="p" size="xlarge">
        Nutritional Info
      </PreviewSectionTitle>
      <Form
        fields={nutritionalInfoFields}
        data={data || {}}
        error={error}
        isLoading={isLoading}
        submit={submit}
      >
        <Button color="delete" size="medium" type="button" onClick={remove}>
          Remove
        </Button>
      </Form>
    </PreviewNutritionalInfoView>
  )
}

export default PreviewNutritionalInfo
