import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { gql } from 'graphql-request'
import { ListFilter, SearchUserLocations } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface SearchUserLocationsState {
  data: SearchUserLocations | null
  loading: RequestStatus
  error: RequestError
}

export interface SearchUserLocationsAPI {
  searchUserLocations: SearchUserLocations
}

const initialState: SearchUserLocationsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_SEARCH_LOCATIONS = gql`
  query UseSearchUserLocations(
    $searchTerm: String!
    $userId: ID
    $tagId: ID
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $order: String
    $filters: [JSON!]
    $userQueryType: String
  ) {
    searchUserLocations(
      searchTerm: $searchTerm
      userId: $userId
      tagId: $tagId
      page: $page
      orderBy: $orderBy
      order: $order
      perPage: $perPage
      filters: $filters
      userQueryType: $userQueryType
    ) {
      page
      totalPageCount
      totalRecordsCount
      allData
      results {
        id
        text
        meta
        assignedViaBrand
        enterpriseLevel
        brand {
          id
          name
          parentBrandName
        }
        posType {
          id
          name
          displayName
        }
        orderingPlatforms {
          id
          name
          displayName
        }
      }
    }
  }
`

export type SearchUserLocationsParams = {
  filters?: ListFilter[] | []
  order?: string
  orderBy?: string
  page?: number
  perPage?: number
  searchTerm?: string
  useQueryType?: string
}

export const fetchSearchUserLocations = createAsyncThunk<
  SearchUserLocationsAPI,
  SearchUserLocationsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'searchUserLocations/fetchSearchUserLocations',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_SEARCH_LOCATIONS,
        params
      )) as SearchUserLocationsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const searchUserLocationsSlice = createSlice({
  name: 'searchUserLocations',
  initialState,
  reducers: {
    resetSearchUserLocations: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchSearchUserLocations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchSearchUserLocations.fulfilled,
      (state, { payload }) => {
        state.data = payload.searchUserLocations
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(fetchSearchUserLocations.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectSearchUserLocationOptions = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.searchUserLocations
    return { data, loading, error }
  },
  ({ data }) => {
    if (!data) return []
    const searchUserLocations = data.results.map(({ id, text }) => ({
      value: id,
      text: text
    }))
    return searchUserLocations
  }
)

export const { resetSearchUserLocations } = searchUserLocationsSlice.actions

export const selectSearchUserLocations = (state: RootState) =>
  state.searchUserLocations

export default searchUserLocationsSlice.reducer
