import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { PosInitStore } from 'types'
import { showNotification } from 'slices/notification'

export interface PosState {
  loading: RequestStatus
  error: RequestError
}

const initialState: PosState = {
  loading: RequestStatus.Idle,
  error: null
}

export const posInitStore = createAsyncThunk<
  void,
  number,
  { state: RootState; rejectValue: RequestError }
>(
  'pos/posInitStore',
  async (storeId, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const data = { store_id: storeId } as PosInitStore
      await api?.request('pos-v2/initialize-store', 'POST', data)
      dispatch(showNotification('Store successfully initialized for POS!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posSlice = createSlice({
  name: 'pos',
  initialState,
  reducers: {
    resetPos: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(posInitStore.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(posInitStore.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(posInitStore.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPos } = posSlice.actions

export const selectPos = (state: RootState) => state.pos

export default posSlice.reducer
