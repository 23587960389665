import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { SidebarExpand, Xmark } from 'iconoir-react'
import { sortBy } from 'utils'
import EntitiesNavLink from './EntitiesNavLink'
import {
  EntitiesNavClose,
  EntitiesNavContainer,
  EntitiesNavContent,
  EntitiesNavHeader,
  EntitiesNavLinks,
  EntitiesNavOpen,
  EntitiesNavView
} from './EntitiesNav.styled'

const EntitiesNav = <T extends {}>({
  title,
  route,
  entities,
  entityId,
  entityName,
  entityTitle,
  sortKey
}: {
  title: string
  entities: T[]
  entityId: keyof T
  entityName: keyof T
  entityTitle?: (entity: T) => string
  sortKey?: keyof T
  route?: string
}) => {
  const [show, setShow] = useState('')
  const prefix = '..'

  const links = useMemo(() => {
    const entitiesSortValue = (entities: T[], key: keyof T) => {
      return entities.map(entity => {
        const value = entity[key]
        const numberValue = Number(value)
        return {
          ...entity,
          _sortValue: isNaN(numberValue) ? value : numberValue
        }
      })
    }

    const sortValueEntities = entitiesSortValue(entities, sortKey || entityName)
    const sorted = sortBy(sortValueEntities, '_sortValue' as keyof T)
    const presorted = sorted.map(entity => {
      const id = entity[entityId]
      const title = entityTitle
        ? entityTitle(entity)
        : ((entity[entityName] || '') as string)
      const path = route ? `${prefix}/${id}/${route}` : `${prefix}/${id}`
      return { path, title }
    })
    return entityTitle ? sortBy(presorted, 'title') : presorted
  }, [entities, entityId, entityName, sortKey, route, prefix, entityTitle])

  useEffect(() => {
    setShow('-show')
  }, [])

  return (
    <>
      <EntitiesNavOpen className={show}>
        <button onClick={() => setShow('-show')}>
          <SidebarExpand />
        </button>
      </EntitiesNavOpen>
      <EntitiesNavView className={show}>
        <EntitiesNavContainer>
          <EntitiesNavHeader size="large" className={show}>
            <Link to={prefix} relative="path">
              {title}
            </Link>
            <EntitiesNavClose className={show}>
              <button onClick={() => setShow('')}>
                <Xmark />
              </button>
            </EntitiesNavClose>
          </EntitiesNavHeader>
          <EntitiesNavContent>
            <EntitiesNavLinks>
              {links.map(link => (
                <EntitiesNavLink key={link.path} {...link} />
              ))}
            </EntitiesNavLinks>
          </EntitiesNavContent>
        </EntitiesNavContainer>
      </EntitiesNavView>
    </>
  )
}

export default EntitiesNav
