import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Hours } from 'types'
import { showNotification } from 'slices/notification'

export interface HoursState {
  data: Hours
  links: Record<string, string | null>
  loading: RequestStatus
  error: RequestError
}

export interface HoursAPI {
  data: Hours
  links: {
    next: string | null
  }
}

const initialState: HoursState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchHours = createAsyncThunk<
  HoursAPI,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'hours/fetchHours',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `hours?revenue_center_id=${revenueCenterId}`
      const resp = (await api?.request(endpoint)) as HoursAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertHours = createAsyncThunk<
  Hours,
  Hours,
  { state: RootState; rejectValue: RequestError }
>(
  'hours/upsertHours',
  async (hours, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('hours', 'POST', hours)) as Hours
      dispatch(showNotification('Hours successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteHours = createAsyncThunk<
  void,
  Hours,
  { state: RootState; rejectValue: RequestError }
>(
  'hours/deleteHours',
  async (hours, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request('hours', 'DELETE', hours)
      dispatch(showNotification('Hours successfully removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const hoursSlice = createSlice({
  name: 'hours',
  initialState,
  reducers: {
    resetHours: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchHours.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchHours.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.links = payload.links
      state.error = null
    })
    builder.addCase(fetchHours.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertHours.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertHours.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertHours.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteHours.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteHours.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteHours.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHours } = hoursSlice.actions

export const selectHours = (state: RootState) => state.hours

export default hoursSlice.reducer
