import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  RequestError,
  RequestStatus,
  downloadPDF,
  makeQueryParams
} from 'utils'
import { BulkOrder } from 'types'
import { showNotification } from './notification'

export interface BulkOrderState {
  data: BulkOrder | null
  loading: RequestStatus
  error: RequestError
}

const initialState: BulkOrderState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}
export type FetchBulkOrderParams = {
  brand_id?: string
  expand?: string
  with_related?: string
}

export const fetchBulkOrder = createAsyncThunk<
  BulkOrder,
  { orderId?: string | number; params?: FetchBulkOrderParams | void },
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/fetchBulkOrder',
  async ({ orderId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `bulk-orders/${orderId}${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as BulkOrder
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const cancelOrder = createAsyncThunk<
  void,
  { orderId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/cancelOrder',
  async ({ orderId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const endpoint = `orders/${orderId}/cancel`
      const api = getState().authUser.api
      await api?.request(endpoint, 'POST')
      dispatch(showNotification('Order successfully cancelled!'))
      const params: FetchBulkOrderParams = {
        expand: 'true',
        with_related: 'true'
      }
      dispatch(fetchBulkOrder({ orderId, params }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const approveOrder = createAsyncThunk<
  void,
  { orderId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/approveOrder',
  async ({ orderId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const endpoint = `orders/${orderId}/approve`
      const api = getState().authUser.api
      await api?.request(endpoint, 'POST')
      dispatch(showNotification('Order approved!'))
      const params: FetchBulkOrderParams = {
        expand: 'true',
        with_related: 'true'
      }
      dispatch(fetchBulkOrder({ orderId, params }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const chargeOrder = createAsyncThunk<
  void,
  { orderId: string | number; skipFetch?: boolean },
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/chargeOrder',
  async (
    { orderId, skipFetch = false },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const endpoint = `orders/${orderId}/charge`
      const api = getState().authUser.api
      await api?.request(endpoint, 'POST')
      dispatch(showNotification(`Order #${orderId} charged successfully!`))
      if (skipFetch) return
      const params: FetchBulkOrderParams = {
        expand: 'true',
        with_related: 'true'
      }
      dispatch(fetchBulkOrder({ orderId, params }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const markOrderPaid = createAsyncThunk<
  void,
  { orderId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/markOrderPaid',
  async ({ orderId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const endpoint = `orders/${orderId}/mark-paid`
      const api = getState().authUser.api
      await api?.request(endpoint, 'POST')
      dispatch(showNotification(`Order #${orderId} marked as paid!`))
      const params: FetchBulkOrderParams = {
        expand: 'true',
        with_related: 'true'
      }
      dispatch(fetchBulkOrder({ orderId, params }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const markOrderNotPaid = createAsyncThunk<
  void,
  { orderId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/markOrderNotPaid',
  async ({ orderId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const endpoint = `orders/${orderId}/mark-not-paid`
      const api = getState().authUser.api
      await api?.request(endpoint, 'POST')
      dispatch(showNotification(`Order #${orderId} marked not paid!`))
      const params: FetchBulkOrderParams = {
        expand: 'true',
        with_related: 'true'
      }
      dispatch(fetchBulkOrder({ orderId, params }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const downloadInvoice = createAsyncThunk<
  void,
  number | string,
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrder/downloadInvoice',
  async (orderId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const data = await api?.request(`orders/${orderId}/invoice`, 'GET', null)
      downloadPDF(data as Blob, 'application/pdf', `order-${orderId}.pdf`)
      dispatch(showNotification('File Downloaded Successfully!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const bulkOrderSlice = createSlice({
  name: 'bulkOrder',
  initialState,
  reducers: {
    resetBulkOrder: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBulkOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBulkOrder.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchBulkOrder.rejected, (state, { payload }) => {
      state.data = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(cancelOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(cancelOrder.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(cancelOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(approveOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(approveOrder.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(approveOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(chargeOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(chargeOrder.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(chargeOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(markOrderPaid.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(markOrderPaid.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(markOrderPaid.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(markOrderNotPaid.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(markOrderNotPaid.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(markOrderNotPaid.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(downloadInvoice.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(downloadInvoice.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(downloadInvoice.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBulkOrder } = bulkOrderSlice.actions

export const selectBulkOrder = (state: RootState) => state.bulkOrder

export default bulkOrderSlice.reducer
