const Lockup = ({ width = undefined }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 256 256"
      fill="#191919"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="256" height="256" fill="#191919" />
      <path
        d="M45.3189 106.667C53.4717 97.4276 64.8848 92.7178 79.197 92.7178C93.5093 92.7178 104.923 97.4276 113.075 106.667C121.228 115.907 125.395 127.502 125.395 141.814C125.395 155.945 121.228 167.72 113.075 176.959C104.923 186.199 93.5093 190.729 79.197 190.729C64.8848 190.729 53.4717 186.199 45.3189 176.959C37.1667 167.72 33 155.945 33 141.814C33 127.502 37.1667 115.907 45.3189 106.667ZM100.394 115.725C94.7773 109.384 87.712 106.124 79.197 106.124C70.6821 106.124 63.6168 109.384 57.8194 115.725C52.2038 122.067 49.3045 130.762 49.3045 141.814C49.3045 152.683 52.2038 161.38 57.8194 167.72C63.6168 174.061 70.6821 177.141 79.197 177.141C87.712 177.141 94.7773 174.061 100.394 167.72C106.192 161.38 109.09 152.683 109.09 141.814C109.09 130.762 106.192 122.067 100.394 115.725Z"
        fill="#FDFAF6"
      />
      <path
        d="M158.953 114.999H138.844V94.8887H158.953V114.999ZM138.844 168.443H158.953V188.552H138.844V168.443Z"
        fill="#FDFAF6"
      />
      <path
        d="M222 108.298H203.34V168.989C203.34 173.336 204.608 174.787 210.224 174.787H221.094V188.555H202.796C191.202 188.555 187.397 182.033 187.397 170.619V108.298H171.998V94.8914H187.397V72.6079L202.796 65H203.34V94.8914H222V108.298Z"
        fill="#FDFAF6"
      />
    </svg>
  )
}

export default Lockup
