import styled from '@emotion/styled'

export const LabelView = styled.label`
  position: relative;
  display: block;
  margin: 0 0 3rem;
`

export const LabelText = styled.span`
  display: block;
  margin: 0 0 0.8rem;
  font-weight: 700;
  color: ${props => props.theme.labels.color};
  font-size: ${props => props.theme.labels.fontSize};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }
`

export const LabelRequired = styled.span`
  color: ${props => props.theme.buttons.colors.delete.default.background};
`

export const LabelParenthetical = styled.span`
  color: ${props => props.theme.colors.text.secondary};
  color: ${props => props.theme.labels.color};
  font-weight: 500;
  padding-left: 0.3rem;
`

export const LabelTooltipTrigger = styled.span`
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0.2rem;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
  text-align: center;
  line-height: 1.4;
  font-size: ${props => props.theme.labels.fontSize};
  font-weight: ${props => props.theme.inputs.fontWeight};

  &:hover + .tooltip__content {
    visibility: visible;
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }

  // &:not(:hover) + .tooltip__content {
  //   transition-delay: 0.3s;
  // }
`

export const LabelTooltipContent = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 110%;
  max-width: 40rem;
  padding: ${props => props.theme.messages.padding};
  border-radius: ${props => props.theme.inputs.borderRadius};
  font-size: ${props => props.theme.sizes.small};
  line-height: ${props => props.theme.layout.desktop.lineHeight};
  text-align: center;
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.secondary};
  border: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.secondary};

  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  transform: translate3D(0, 1rem, 0);

  &:hover {
    visibility: visible;
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
`
