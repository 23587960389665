import styled from '@emotion/styled'

const increments = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 60, 72, 96
]

const DesignInputFontSizeView = styled.div`
  label: DesignInputFontSize;
  position: absolute;
  top: 100%;
  z-index: 10;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${props => props.theme.colors.background.primary};
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  border-top: 0;
  padding: 0.5rem 0;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  input:focus ~ & {
    opacity: 1;
    visibility: visible;
    max-height: none;
  }
`

const DesignInputFontSizeButton = styled.button`
  display: block;
  padding: 0.5rem 1rem;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.sizes.xsmall};

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.background.tertiary};
  }
`

const DesignInputFontSize = ({
  setValue
}: {
  setValue: (value: string | number) => void
}) => {
  return (
    <DesignInputFontSizeView>
      {increments.map(value => (
        <DesignInputFontSizeButton key={value} onClick={() => setValue(value)}>
          {value}
        </DesignInputFontSizeButton>
      ))}
    </DesignInputFontSizeView>
  )
}

export default DesignInputFontSize
