import {
  OrderTotalsMainItemView,
  OrderTotalsItemLabel,
  OrderTotalsItemValue
} from './OrderTotalsSubItem.styled'

const OrderTotalsMainItem = ({
  label,
  value
}: {
  label: string
  value: string
}) => {
  return (
    <OrderTotalsMainItemView key="total">
      <OrderTotalsItemLabel>{label}</OrderTotalsItemLabel>
      <OrderTotalsItemValue>{value}</OrderTotalsItemValue>
    </OrderTotalsMainItemView>
  )
}

export default OrderTotalsMainItem
