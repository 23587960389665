import styled from '@emotion/styled'

const ListHeader = styled.div`
  margin: ${props => props.theme.layout.desktop.margin} 0 0;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: ${props => props.theme.layout.mobile.margin} 0 0;
  }

  & > div {
    margin: 0;
  }
`

export default ListHeader
