import styled from '@emotion/styled'
import { ErrMsg } from '..'

const DesignLabelView = styled.label`
  margin: 0 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    margin: 0;
  }
`

const DesignLabelText = styled.span<{ inherited?: boolean }>`
  display: block;
  color: ${props =>
    props.inherited
      ? props.theme.colors.text.secondary
      : props.theme.inputs.color};
  font-size: ${props => props.theme.sizes.xsmall};
`

const DesignLabelInput = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const DesignLabel = ({
  htmlFor,
  label,
  required,
  inherited,
  errMsg,
  children,
  style
}: {
  htmlFor?: string
  label?: string
  required?: boolean
  inherited?: boolean
  errMsg?: string
  children?: React.ReactNode
  style?: object
}) => {
  return (
    <>
      <DesignLabelView htmlFor={htmlFor} style={style}>
        {label && (
          <DesignLabelText inherited={inherited}>
            {label}
            {/* {required ? (
              <DesignLabelRequired>&nbsp;*</DesignLabelRequired>
            ) : null} */}
          </DesignLabelText>
        )}
        <DesignLabelInput>{children}</DesignLabelInput>
      </DesignLabelView>
      <ErrMsg errMsg={errMsg} />
    </>
  )
}

export default DesignLabel
