import React from 'react'
import { TableHeaderConfig, TableSort } from 'types'
import TableHeaderSortable from './TableHeaderSortable'

interface TableHeaderProps {
  headers: (string | React.ReactNode | TableHeaderConfig)[]
  onSort?: (value: string) => void
  sort?: TableSort
}

const TableHeader = ({ headers, onSort, sort }: TableHeaderProps) => {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={index} className={header === ' ' ? 'th-image' : ''}>
            <strong>
              {header && typeof header === 'object' && 'value' in header ? (
                <TableHeaderSortable
                  text={header.text}
                  value={header.value}
                  onClick={() => onSort && onSort(header.value)}
                  sort={sort || ({} as TableSort)}
                />
              ) : (
                header
              )}
            </strong>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default React.memo(TableHeader)
