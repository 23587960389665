import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { GiftCardBatch } from 'types'

export interface GiftCardBatchState {
  data: GiftCardBatch | null
  loading: RequestStatus
  error: RequestError
}

const initialState: GiftCardBatchState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchGiftCardBatch = createAsyncThunk<
  GiftCardBatch,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'giftCardBatch/fetchGiftCardBatch',
  async (batchId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `gift-card-batches/${batchId}`
      const resp = (await api?.request(endpoint)) as GiftCardBatch
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createGiftCardBatch = createAsyncThunk<
  GiftCardBatch,
  { data: { count: number }; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'giftCardBatch/createGiftCardBatch',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'gift-card-batches',
        'POST',
        data
      )) as GiftCardBatch
      dispatch(showNotification('Gift Card Batch successfully created!'))
      const pathname = `/loyalty/gift-cards/batches`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const giftCardBatchSlice = createSlice({
  name: 'giftCardBatch',
  initialState,
  reducers: {
    resetGiftCardBatch: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchGiftCardBatch.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchGiftCardBatch.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchGiftCardBatch.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createGiftCardBatch.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createGiftCardBatch.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(createGiftCardBatch.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetGiftCardBatch } = giftCardBatchSlice.actions

export const selectGiftCardBatch = (state: RootState) => state.giftCardBatch

export default giftCardBatchSlice.reducer
