import styled from '@emotion/styled'

const ModalView = styled.div`
  label: ModalView;
  position: relative;
  z-index: 500;
  width: 54rem;
  max-width: 90%;
  padding: 4rem;
  border: 0.1rem solid ${props => props.theme.colors.border.primary};
  background-color: ${props => props.theme.colors.background.primary};
  @media only screen and (max-width: 600px) {
    padding: 2.5rem;
  }
`

export default ModalView
