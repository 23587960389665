import { gql } from 'graphql-request'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  ONBOARDING_LOCATION_FRAGMENT_WITHOUT_APP,
  ONBOARDING_LOCATION_SUBMIT_OPTIONS_FRAGMENT,
  RequestError,
  RequestStatus
} from 'utils'
import { OnboardingAllLocation } from 'types'

export interface LocationState {
  data: OnboardingAllLocation | null
  loading: RequestStatus
  error: RequestError
}

export interface AllLocationsAPI {
  onboardingAllLocations: OnboardingAllLocation
}

const initialState: LocationState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const USE_MUTATE_ONBOARDING_LOCATION = gql`
  mutation MutateOnboardingLocation($input: UpdateOnboardingLocationInput!) {
    updateOnboardingLocation(input: $input) {
      ...OnboardingLocationFragment
      ...OnboardingLocationSubmitOptionsFragment
    }
  }
  ${ONBOARDING_LOCATION_FRAGMENT_WITHOUT_APP}
  ${ONBOARDING_LOCATION_SUBMIT_OPTIONS_FRAGMENT}
`

export type UpdateLocationParams = {
  locationName: string
  id: string | number
  brandId: string | number
  enterpriseLevelId: string | number
  locationStatus: string
  storeType: string
  onboardingSpecialistId?: string | number
}

export type ArchiveLocationParams = {
  id: string | number
  archived?: boolean
}

export type StarLocationParams = {
  id: string | number
  starred: boolean
}

export const updateLocation = createAsyncThunk<
  AllLocationsAPI,
  UpdateLocationParams | ArchiveLocationParams | StarLocationParams,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardinglocation/updateLocation',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(USE_MUTATE_ONBOARDING_LOCATION, {
        input: params
      })) as AllLocationsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingLocationSlice = createSlice({
  name: 'onboardingLocation',
  initialState,
  reducers: {
    resetOnboardingLocation: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(updateLocation.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateLocation.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateLocation.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOnboardingLocation } = onboardingLocationSlice.actions

export const selectOnboardingLocation = (state: RootState) =>
  state.onboardingLocation

export default onboardingLocationSlice.reducer
