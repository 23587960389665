import { useAppDispatch, useAppSelector } from 'app/hooks'
import { EntitiesNav, Entity } from 'components'
import { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'

import { fetchRegion, resetRegion, selectRegion } from 'slices/region'
import { selectRegions } from 'slices/regions'
import RegionSettings from './RegionSettings'
import links from './Region.links'
import RegionCrumbs from './RegionCrumbs'

const Region = () => {
  const dispatch = useAppDispatch()
  const { regionId } = useParams()

  const { data } = useAppSelector(selectRegion)
  const { data: regions } = useAppSelector(selectRegions)

  useEffect(() => {
    if (regionId === 'new') {
      dispatch(resetRegion())
    } else if (regionId) {
      dispatch(fetchRegion(regionId))
    }
  }, [regionId, dispatch])

  return (
    <>
      <Entity
        crumbs={<RegionCrumbs />}
        title={data?.name || 'New Region'}
        links={links}
      >
        <Routes>
          <Route path="" element={<RegionSettings />} />
        </Routes>
      </Entity>
      <EntitiesNav
        title="Regions"
        entities={regions}
        entityId="region_id"
        entityName="name"
      />
    </>
  )
}

export default Region
