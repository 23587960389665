import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { editorColors } from 'config'
import {
  AppDesignNavSection,
  AppLayout,
  AppVariablesSectionType,
  AppViewport,
  ConfigComponentType,
  ConfigPageType,
  EditorColors,
  EditorNavSection
} from 'types'

export type EditorState = {
  className: string | null
  colors: EditorColors
  dataName: string | null
  designSection: AppDesignNavSection
  layout: AppLayout
  navSection: EditorNavSection | null
  navSubSection: string | null
  pageType: ConfigPageType
  componentType: ConfigComponentType
  variableType: AppVariablesSectionType
  viewport: AppViewport
}

const initialState: EditorState = {
  colors: editorColors,
  className: null,
  dataName: null,
  designSection: 'typography',
  layout: 'portrait',
  navSection: 'THEME',
  navSubSection: null,
  pageType: 'guest',
  componentType: 'allergen',
  variableType: 'colors',
  viewport: 'desktop'
}

export const editor = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    resetEditorColors: state => {
      state.colors = editorColors
    },
    setClassName: (state, { payload }: { payload: string | null }) => {
      state.className = payload
    },
    setDataName: (state, { payload }: { payload: string | null }) => {
      state.dataName = payload
    },
    setDesignSection: (
      state,
      { payload }: { payload: AppDesignNavSection }
    ) => {
      state.designSection = payload
    },
    setEditorColors: (state, { payload }: { payload: EditorColors }) => {
      state.colors = payload
    },
    setNavSection: (
      state,
      { payload }: { payload: EditorNavSection | null }
    ) => {
      state.navSection = payload
    },
    setNavSubSection: (state, { payload }: { payload: string | null }) => {
      state.navSubSection = payload
    },
    setPageType: (state, { payload }: { payload: ConfigPageType }) => {
      state.pageType = payload
    },
    setComponentType: (
      state,
      { payload }: { payload: ConfigComponentType }
    ) => {
      state.componentType = payload
    },
    setVariableType: (
      state,
      { payload }: { payload: AppVariablesSectionType }
    ) => {
      state.variableType = payload
    },
    setViewport: (state, { payload }: { payload: AppViewport }) => {
      state.viewport = payload
    }
  }
})

export const {
  resetEditorColors,
  setClassName,
  setDataName,
  setDesignSection,
  setEditorColors,
  setNavSection,
  setNavSubSection,
  setPageType,
  setComponentType,
  setVariableType,
  setViewport
} = editor.actions

export const selectEditor = (state: RootState) => state.editor
export const selectEditorClassName = (state: RootState) =>
  state.editor.className
export const selectEditorDataName = (state: RootState) => state.editor.dataName
export const selectEditorDesignSection = (state: RootState) =>
  state.editor.designSection
export const selectEditorNavSection = (state: RootState) =>
  state.editor.navSection
export const selectEditorNavSubSection = (state: RootState) =>
  state.editor.navSubSection
export const selectEditorPageType = (state: RootState) => state.editor.pageType
export const selectEditorVariableType = (state: RootState) =>
  state.editor.variableType
export const selectEditorViewport = (state: RootState) => state.editor.viewport
export const selectEditorLayout = (state: RootState) => state.editor.layout

export default editor.reducer
