import { SidebarLinkProps } from 'types'
import {
  EntityNavHeader,
  EntityNavLinks,
  EntityNavToggle,
  EntityNavToggleIcon,
  EntityNavView
} from './EntityNav.styled'
import EntityNavLink from './EntityNavLink'
import TextHeading from 'components/TextHeading'
import { useEffect, useState } from 'react'
import { IconoirProvider, NavArrowDown, NavArrowUp } from 'iconoir-react'
import Button from 'components/Button'
import { useLocation } from 'react-router-dom'

interface EntityNavProps {
  title?: string
  links?: SidebarLinkProps[]
  children?: React.ReactNode
}

const EntityNav = ({ title, links, children }: EntityNavProps) => {
  const [showLinks, setShowLinks] = useState(false)
  const { pathname } = useLocation()
  const [, ...rest] = pathname.split('/')
  const current = links?.find(i => rest.includes(i.path))
  const currentPath = current || (links ? links[0] : null)

  useEffect(() => {
    setShowLinks(false)
  }, [pathname])

  return (
    <EntityNavView className="fadeUp" style={{ animationDelay: '0ms' }}>
      <EntityNavHeader>
        {children || (
          <TextHeading as="p" size="large">
            {title}
          </TextHeading>
        )}
      </EntityNavHeader>
      <EntityNavToggle>
        <Button onClick={() => setShowLinks(!showLinks)}>
          <TextHeading as="span" size="large">
            {currentPath?.title}
          </TextHeading>
          <EntityNavToggleIcon>
            <IconoirProvider
              iconProps={{
                width: '3rem',
                height: '3rem',
                strokeWidth: '1.5'
              }}
            >
              {showLinks ? <NavArrowUp /> : <NavArrowDown />}
            </IconoirProvider>
          </EntityNavToggleIcon>
        </Button>
      </EntityNavToggle>
      {links && (
        <EntityNavLinks as="ul" showLinks={showLinks}>
          {links.map(link => (
            <EntityNavLink key={link.path} {...link} />
          ))}
        </EntityNavLinks>
      )}
    </EntityNavView>
  )
}

export default EntityNav
