import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  CustomerAddress,
  ModalWindow,
  OrderType,
  RevenueCentersWithZone
} from 'types'
import { Alert, ButtonGroup, Highlight, Loader } from 'components'
import { ModalHeader } from 'components/Modal'
import {
  RevenueCentersParams,
  fetchRevenueCenters,
  selectRevenueCenters
} from 'slices/revenueCenters'
import { RequestStatus } from 'utils'
import { getLatLng, sortRevenueCenters } from 'pages/Orders/Order.utils'

const OrderRevenueCenterHighlight = styled.span`
  margin-right: 1rem;
`

export interface OrderRevenueCenterArgs {
  orderType: OrderType
  address: CustomerAddress | null
  choose: (revenueCenterId: number) => void
}

const OrderRevenueCenter = ({
  args,
  modalWindow
}: {
  args: OrderRevenueCenterArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [revenueCenters, setRevenueCenters] = useState<RevenueCentersWithZone>(
    []
  )
  const { orderType, address, choose } = args
  const { data, loading } = useAppSelector(selectRevenueCenters)
  const isLoading = loading === RequestStatus.Pending

  useEffect(() => {
    const latLng = address ? getLatLng(address) : null
    const revenueCenters = sortRevenueCenters(data, latLng)
    setRevenueCenters(revenueCenters ? revenueCenters : [])
  }, [dispatch, address, data])

  useEffect(() => {
    if (orderType) {
      const params: RevenueCentersParams = {
        revenue_center_type: orderType,
        with_related: 'delivery_zones',
        expand: 'store',
        with_settings: 'address'
      }
      dispatch(fetchRevenueCenters(params))
    }
  }, [dispatch, orderType])

  return (
    <>
      <ModalHeader title="Choose a revenue center" />
      {!address && (
        <Alert alertType="WARNING">
          Please add an address to allow for sorting by distance, delivery zone,
          and priority.
        </Alert>
      )}
      {data ? (
        <ButtonGroup>
          {revenueCenters.map(rc => (
            <button
              key={rc.revenue_center_id}
              onClick={() => choose(rc.revenue_center_id)}
              disabled={isLoading}
            >
              <span>{rc.full_name}</span>
              <span>
                {rc.inZone && rc.priority ? (
                  <OrderRevenueCenterHighlight>
                    <Highlight color="info">priority {rc.priority}</Highlight>
                  </OrderRevenueCenterHighlight>
                ) : null}
                {rc.inZone && (
                  <OrderRevenueCenterHighlight>
                    <Highlight color="success">in zone</Highlight>
                  </OrderRevenueCenterHighlight>
                )}
                {(rc.distance || rc.distance === 0) && (
                  <span>{rc.distance.toFixed(2)} miles</span>
                )}
              </span>
            </button>
          ))}
        </ButtonGroup>
      ) : isLoading ? (
        <Loader text="Retrieving revenue centers..." />
      ) : null}
    </>
  )
}

export default OrderRevenueCenter
