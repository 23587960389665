import React from 'react'
import { Internet, Mail, MessageText, SmartphoneDevice } from 'iconoir-react'
import {
  CampaignStatus,
  CampaignContent,
  Campaign,
  CampaignChannel,
  CampaignChannels,
  CampaignTrigger,
  CampaignTriggers,
  TimezonePython
} from 'types'
import { convertTimestamp, getTimezoneAbbreviation } from './datetimes'

export const getCampaignStatusColor = (
  campaignStatus?: CampaignStatus | null
) => {
  switch (campaignStatus) {
    case 'DRAFT':
      return 'default'
    case 'SCHEDULED':
      return 'info'
    case 'SENDING':
      return 'info'
    case 'INACTIVE':
      return 'default'
    case 'SENT':
      return 'success'
    case 'ACTIVE':
      return 'success'
    case 'PAUSED':
      return 'default'
    case 'FAILED':
      return 'error'
    default:
      return 'default'
  }
}
export const getCampaignChannels = (campaign: Campaign | null) => {
  return CampaignChannels.filter(channel =>
    campaign?.campaign_content?.find(content => content.channel === channel)
  )
}

export const getCampaignChannelFileUrl = (
  content: CampaignContent | null,
  file_type: string = 'LARGE_IMAGE'
) => {
  if (!content || !content.files) return ''
  return (
    content.files
      .filter(image => image.file_type === file_type)
      .toSorted((a, b) => b.file_id - a.file_id)[0]?.file_url || ''
  )
}

export const getCampaignFileUrl = (
  campaign: Campaign,
  file_type: string = 'LARGE_IMAGE'
) => {
  return CampaignChannels.map(channel =>
    campaign?.campaign_content?.find(content => content.channel === channel)
  )
    .map(content => content && getCampaignChannelFileUrl(content, file_type))
    .find(tumbnailUrl => tumbnailUrl)
}

export const getSortedTriggers = (triggers: CampaignTriggers) => {
  return triggers.reduce((earliest, trigger) => {
    if (!earliest) return trigger
    if (!earliest.start_date) return trigger
    if (!trigger.start_date) return earliest
    return earliest.start_date < trigger.start_date ? earliest : trigger
  }, triggers[0])
}

export const getScheduleSummary = (campaign?: Campaign | null) => {
  const triggers = campaign?.triggers || []
  const firstTrigger = getSortedTriggers(triggers)
  const isSingleSend = campaign?.campaign_type === 'SINGLE_SEND'

  if (!firstTrigger) return ''
  if (isSingleSend) {
    if (isSendNow(triggers)) return 'sending now'
    return `scheduled for ${convertTimestamp(firstTrigger.start_date || '')} ${firstTrigger.timezone ? getTimezoneAbbreviation(firstTrigger?.timezone as TimezonePython) : ''}`
  } else {
    let summary = 'active'
    if (firstTrigger.start_date)
      summary += ` from ${convertTimestamp(firstTrigger.start_date)}`
    if (firstTrigger.end_date)
      summary += ` until ${convertTimestamp(firstTrigger.end_date)}`
    if (firstTrigger.start_date || firstTrigger.end_date) {
      if (firstTrigger.timezone)
        summary += ` ${getTimezoneAbbreviation(firstTrigger.timezone as TimezonePython)}`
    } else {
      summary += ' immediately'
    }

    return summary
  }
}

export const getCampaignTriggerSummary = (campaign: Campaign | null) => {
  const triggers = campaign?.triggers || []
  const firstTrigger = getSortedTriggers(triggers)
  const isSingleSend = campaign?.campaign_type === 'SINGLE_SEND'
  if (isSingleSend) return ''
  if (!firstTrigger) return ''
  const eventType = firstTrigger.event_type
  const timeframe = (
    firstTrigger.offset_duration === 1
      ? (firstTrigger.offset_timeframe || '').replace(/s/i, '')
      : firstTrigger.offset_timeframe || ''
  ).toLowerCase()
  const direction = !firstTrigger.offset_duration
    ? 'on'
    : firstTrigger.offset_duration > 0
      ? 'after'
      : 'before'
  const offset = !firstTrigger.offset_duration
    ? direction
    : `${Math.abs(firstTrigger.offset_duration)} ${timeframe} ${direction}`
  if (!eventType) return ''
  switch (eventType) {
    case 'BIRTHDAY':
      return `sent ${offset} the customer's birthday`
    case 'ORDER_PLACED':
      return `sent ${offset} the customer's last order`
    case 'LAST_ORDER_DATE':
      return `sent ${offset} the customer's last order date`
    default:
      return ''
  }
}

export const getCampaignSummaryTitle = (campaign: Campaign | null) => {
  switch (campaign?.campaign_status) {
    case 'FAILED':
      return 'Your campaign failed to send'
    case 'SENT':
      return 'Your campaign has been sent'
    case 'SENDING':
      return 'Your campaign is sending'
    case 'SCHEDULED':
      return 'Your campaign is scheduled'
    case 'ACTIVE':
      return 'Your campaign is active'
    case 'PAUSED':
      return 'Your campaign is inactive'
    default:
      return "Your campaign hasn't been scheduled yet"
  }
}

export const getCampaignSummary = (campaign: Campaign | null) => {
  const summary = getScheduleSummary(campaign)
  switch (campaign?.campaign_status) {
    case 'FAILED':
      return `${campaign?.name} failed to send`
    case 'SENT':
      if (!campaign?.sent_at) return `${campaign?.name} was sent`
      return `${campaign?.name} was sent on ${convertTimestamp(campaign?.sent_at)}`
    case 'SENDING':
      return `${campaign?.name} is sending now`
    case 'SCHEDULED':
      return `${campaign?.name} is ${summary}`
    case 'ACTIVE':
      return `${campaign?.name} is ${summary}`
    case 'PAUSED':
      return `${campaign?.name} is inactive`
    default:
      return "Your campaign hasn't been scheduled yet"
  }
}

export const getCampaignChannelLabel = (channel: CampaignChannel) => {
  switch (channel) {
    case 'EMAIL':
      return 'Email'
    case 'SMS':
      return 'SMS'
    case 'PUSH':
      return 'Push'
    case 'WEB':
      return 'Web Page'
    default:
      return 'Content'
  }
}

export const isContentComplete = (content: CampaignContent): boolean => {
  return !!content.body
}

export const isStepComplete = (step: string, campaign: Campaign): boolean => {
  // Check if the campaign has content
  const hasContent =
    campaign.campaign_content?.length > 0 &&
    !campaign.campaign_content.find(content => !content.body)

  switch (step) {
    case 'content':
      return hasContent
    case 'audience':
      // Mark audience complete as soon as the campaign has content
      return !!campaign.campaign_content?.length
    case 'schedule':
      return !!campaign.triggers?.length
    case 'review':
      return (
        !!campaign.triggers?.length &&
        campaign.triggers?.every(trigger => trigger.is_active)
      )
    default:
      return false
  }
}

const channelIcons = {
  EMAIL: Mail,
  SMS: MessageText,
  PUSH: SmartphoneDevice,
  WEB: Internet
}

export const getCampaignChannelIcon = (channel: CampaignChannel) => {
  return React.createElement(channelIcons[channel])
}

export const isSendNow = (triggers: CampaignTrigger[]) => {
  // Check if any trigger has a start_date
  if (triggers && triggers.some(trigger => !trigger.start_date)) {
    return true // At least one trigger is send now
  } else {
    return false // Campaign is scheduled
  }
}

interface PageMetadata {
  title?: string
  description?: string
  subject?: string
  preheader?: string
}

export const setCampaignContentMetadata = (
  pageJson: string,
  metadata: PageMetadata
) => {
  const pageObject = JSON.parse(pageJson || '{}')
  const page = pageObject.page
  return JSON.stringify({
    ...pageObject,
    page: {
      ...page,
      head: {
        ...page?.head,
        meta: {
          ...page?.head?.meta,
          ...metadata
        }
      }
    }
  })
}

export const getCampaignScheduledDate = (campaign: Campaign) => {
  const earliestTrigger = campaign?.triggers?.reduce((earliest, trigger) => {
    if (!earliest) return trigger
    if (!earliest.start_date) return trigger
    if (!trigger.start_date) return earliest
    return earliest.start_date < trigger.start_date ? earliest : trigger
  }, campaign.triggers[0])

  const earliestTimezone = earliestTrigger?.timezone

  const scheduledDate = earliestTrigger?.start_date

  switch (campaign.campaign_status) {
    case 'DRAFT':
      return {
        scheduledDate: campaign.created_at,
        timezone: null
      }
    case 'SCHEDULED':
      return {
        scheduledDate: scheduledDate,
        timezone: earliestTimezone
      }
    case 'SENT':
      return {
        scheduledDate: campaign.sent_at,
        timezone: null
      }
    case 'ACTIVE':
      return {
        scheduledDate: campaign.sent_at,
        timezone: null
      }
    case 'PAUSED':
      return {
        scheduledDate: campaign.sent_at,
        timezone: null
      }
    case 'SENDING':
      return {
        scheduledDate: campaign.sent_at || scheduledDate || campaign.created_at,
        timezone: campaign.sent_at ? null : earliestTimezone
      }
    default:
      return {
        scheduledDate: campaign.sent_at || scheduledDate || campaign.created_at,
        timezone: campaign.sent_at ? null : earliestTimezone
      }
  }
}

export const getCampaignStatusLabel = (campaignStatus: CampaignStatus) => {
  switch (campaignStatus) {
    case 'DRAFT':
      return 'Draft'
    case 'SCHEDULED':
      return 'Scheduled'
    case 'SENDING':
      return 'Sending'
    case 'SENT':
      return 'Sent'
    case 'ACTIVE':
      return 'Active'
    case 'PAUSED':
      return 'Inactive'
    case 'FAILED':
      return 'Failed'
    default:
      return 'Unknown'
  }
}

export const getCampaignChannelLastUpdated = (
  campaign: Campaign,
  channel: CampaignChannel
): Date | null => {
  const channelContent = campaign.campaign_content.find(
    content => content.channel === channel
  )
  if (channelContent) {
    return new Date(channelContent.updated_at)
  }
  return null
}
