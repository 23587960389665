import styled from '@emotion/styled'

const ComingSoonView = styled.div`
  width: 100%;
  max-width: 36rem;
  aspect-ratio: 0.8;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ComingSoon = () => {
  return (
    <ComingSoonView>
      <img
        src="https://assets.opentender.io/admin/images/watch-this-space_1600x2000.jpg"
        alt="coming soon"
      />
    </ComingSoonView>
  )
}

export default ComingSoon
