import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions, ServiceType } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import {
  createRevenueCenterServiceTypes,
  fetchRevenueCenterServiceTypes,
  selectRevenueCenterServiceTypes
} from 'slices/revenueCenterServiceTypes'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { serviceTypeOptions } from 'config'

const RevenueCenterServiceType = ({
  args,
  modalWindow
}: {
  args: { revenueCenterId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { revenueCenterId } = args
  const { data, loading, error } = useAppSelector(
    selectRevenueCenterServiceTypes
  )
  const current = data.map(i => i.service_type)
  const isLoading = loading === RequestStatus.Pending

  const options = useMemo(() => {
    return serviceTypeOptions
      .filter(i => i.value !== 'none')
      .map(i => {
        return {
          ...i,
          disabled: current.includes(i.value as ServiceType)
        }
      })
  }, [current])

  const submit = async (values: { service_type: ServiceType }) => {
    if (revenueCenterId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(createRevenueCenterServiceTypes({ revenueCenterId, data }))
      dispatch(fetchRevenueCenterServiceTypes(revenueCenterId))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add New Service Type"
        subtitle="Choose a service type from the options below"
      />
      <Form
        fields={revenueCenterServiceTypeFields(options)}
        data={revenueCenterServiceTypeEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const revenueCenterServiceTypeEmpty = {
  service_type: null
}

const revenueCenterServiceTypeFields = (
  options: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    name: 'service_type',
    options: options,
    label: 'Service Type',
    error: 'Please choose a service type'
  }
]

export default RevenueCenterServiceType
