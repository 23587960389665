import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Regions } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface RegionsState {
  data: Regions
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface RegionsAPI {
  data: Regions
  links: {
    next: string
  }
}

export type RegionsParams = {
  limit?: number
  with_related?: string
  is_active?: boolean
}

const initialState: RegionsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRegions = createAsyncThunk<
  RegionsAPI,
  RegionsParams | void,
  { state: RootState; rejectValue: RequestError }
>('regions/fetchRegions', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `regions${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as RegionsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRegions.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRegions.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchRegions.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectRegions = (state: RootState) => state.regions

export const selectRegionsByStatus =
  (isActive: boolean) => (state: RootState) => {
    return state.regions.data.filter(i => i.is_active === isActive)
  }

export default regionsSlice.reducer
