import styled from '@emotion/styled'

export const EntitiesSelectTitle = styled.span`
  display: block;
`

export const EntitiesSelectWrapper = styled.span`
  display: block;
  position: relative;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
`

export const EntitiesSelectView = styled.select`
  width: 100%;
  outline: none;
  border: 0;
  border: 0.1rem solid ${props => props.theme.colors.border.primary};
  border-radius: ${props => props.theme.borderRadius.small};
  padding: 0.9rem 2rem 0.8rem 1rem;
  background-color: inherit;
  transition: ${props => props.theme.transition};
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: ${props => props.theme.sizes.medium};
  color: ${props => props.theme.colors.text.primary};
  font-family: 'Figtree', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

  &:active,
  &:focus {
    color: ${props => props.theme.inputs.color};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.inputs.color};
  }
`

export const EntitiesSelectDropdown = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0.1rem;
  bottom: 0;
  right: 0.5rem;
  width: 2.4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
`

export const EntitiesSelectArrow = styled.span`
  display: block;
  width: 0;
  height: 0;
  margin: 0.3rem 0 0;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-top: 0.7rem solid ${props => props.theme.colors.text.primary};
`
