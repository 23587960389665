import { useEffect, useMemo, useRef, useState } from 'react'
import Bee, { beeTypes } from '@mailupinc/bee-plugin'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BeeEditorVariants } from 'types'
import { fetchCampaignContentToken } from 'slices/campaignContentToken'
import { selectBrand } from 'slices/authUser'
export interface useBeeFileManagerProps {
  variant?: BeeEditorVariants
}

export type FileManagerOpenArgs = {
  resolve: (file: beeTypes.IPluginFilePicker) => void
  reject: () => void
  args: beeTypes.IPluginFilePicker
}

export interface IBeeFilePickerState {
  variant?: BeeEditorVariants
  filePickerRef?: React.RefObject<HTMLDivElement>
  filePicker?: FileManagerOpenArgs
}

export const useBeeFileManager = ({
  variant = 'file-manager'
}: useBeeFileManagerProps) => {
  const appDispatch = useAppDispatch()
  const { brand_id } = useAppSelector(selectBrand) || {}
  const [instance, setInstance] = useState<Bee>()
  const [filePicker, setFilePicker] = useState<FileManagerOpenArgs>()
  const [file, setFile] = useState<beeTypes.IPluginFilePicker>()
  const filePickerRef = useRef<HTMLInputElement>(null)

  // Compose the file picker configuration
  const config = useMemo<beeTypes.IBeeConfig>(
    () => ({
      onFilePickerInsert: variant !== 'file-manager' ? setFile : undefined, // Only set in file picker mode
      onFilePickerCancel:
        variant !== 'file-manager' ? setFilePicker : undefined, // Only set in file picker mode
      uid: `${brand_id || 'user'}`,
      container: `${variant}-file-manager`
    }),
    [setFile, setFilePicker, variant, brand_id]
  )

  useEffect(() => {
    if (!filePickerRef.current) return
    // Set the container id for the file picker
    filePickerRef.current.id = config.container
    // Fetch the token for the editor
    appDispatch(fetchCampaignContentToken({ variant }))
      .unwrap()
      .then(token => {
        // Create a new editor instance from token
        const editorInstance = new Bee(token)
        // Start the editor instance
        editorInstance.start(config, {}).then(() => {
          setInstance(editorInstance)
        })
      })
  }, [appDispatch, config, filePickerRef, variant])

  useEffect(() => {
    if (filePicker && file) {
      // Resolve the file picker
      filePicker.resolve(file)
      // Reset the file picker
      setFile(undefined)
      setFilePicker(undefined)
    }
  }, [instance, filePicker, file])

  return {
    variant,
    filePickerRef,
    filePicker
  }
}
export default useBeeFileManager
