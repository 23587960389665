import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { LoyaltyThreshold } from 'types'
import { showNotification } from 'slices/notification'

export interface LoyaltyThresholdState {
  data: LoyaltyThreshold | null
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyThresholdState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchLoyaltyThreshold = createAsyncThunk<
  LoyaltyThreshold,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyThreshold/fetchLoyaltyThreshold',
  async (loyaltyThresholdId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-thresholds/${loyaltyThresholdId}`
      const resp = (await api?.request(endpoint)) as LoyaltyThreshold
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createLoyaltyThreshold = createAsyncThunk<
  LoyaltyThreshold,
  { data: LoyaltyThreshold; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyThreshold/createLoyaltyThreshold',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'loyalty-thresholds',
        'POST',
        data
      )) as LoyaltyThreshold
      dispatch(showNotification('Loyalty Threshold successfully created!'))
      const pathname = `/loyalty/loyalty-programs/${data.loyalty_program_id}/thresholds/${resp.loyalty_threshold_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateLoyaltyThreshold = createAsyncThunk<
  LoyaltyThreshold,
  {
    loyaltyThresholdId: string | number
    data: LoyaltyThreshold
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyThreshold/updateLoyaltyThreshold',
  async (
    { loyaltyThresholdId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-thresholds/${loyaltyThresholdId}`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as LoyaltyThreshold
      dispatch(showNotification('Loyalty Threshold successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteLoyaltyThreshold = createAsyncThunk<
  void,
  {
    loyaltyProgramId: string | undefined
    loyaltyThresholdId: string | number
    navigate?: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyThreshold/deleteLoyaltyThreshold',
  async (
    { loyaltyProgramId, loyaltyThresholdId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-thresholds/${loyaltyThresholdId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Threshold removed!'))
      const pathname = `/loyalty/loyalty-programs/${loyaltyProgramId}/thresholds`
      if (navigate) navigate({ pathname })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyThresholdSlice = createSlice({
  name: 'loyaltyThreshold',
  initialState,
  reducers: {
    resetLoyaltyThreshold: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyThreshold.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyThreshold.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchLoyaltyThreshold.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createLoyaltyThreshold.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyThreshold.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createLoyaltyThreshold.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateLoyaltyThreshold.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateLoyaltyThreshold.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateLoyaltyThreshold.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyThreshold } = loyaltyThresholdSlice.actions

export const selectLoyaltyThreshold = (state: RootState) =>
  state.loyaltyThreshold

export default loyaltyThresholdSlice.reducer
