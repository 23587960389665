import { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { IconoirProvider } from 'iconoir-react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  fetchBrandLogo,
  selectAuthUser,
  selectUserSections
} from 'slices/authUser'
import { Lockup } from 'components'
import { links, footerLinks } from './Sidebar.links'
import {
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarHeaderText,
  SidebarLinkIcon,
  SidebarLinks,
  SidebarLogo,
  SidebarToggle,
  SidebarToggleButton,
  SidebarToggleText,
  SidebarView
} from './Sidebar.styled'
import SidebarLink from './SidebarLink'
import ToggleColorScheme from 'components/ToggleColorScheme'
import { selectGlobal, setShowSidebar } from 'slices/global'
import { capitalize, makeUserRoutes } from 'utils'
import { selectMarketingBrandSettings } from 'slices/marketingSettings'

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { colorScheme, showSidebar } = useAppSelector(selectGlobal)
  const { user, icon, brand } = useAppSelector(selectAuthUser)
  const shortName = brand?.short_name
  const userSections = useAppSelector(selectUserSections)
  const { is_marketing_enabled } = useAppSelector(selectMarketingBrandSettings)
  const userRoutes = makeUserRoutes(
    userSections,
    user?.is_super,
    is_marketing_enabled
  )
  const filtered = links.filter(i => userRoutes.includes(i.path))
  const isEditor = pathname.includes('editor')

  useEffect(() => {
    dispatch(fetchBrandLogo())
  }, [dispatch])

  useEffect(() => {
    if (!isMobile) {
      dispatch(setShowSidebar(!isEditor))
    }
  }, [dispatch, isEditor])

  return (
    <SidebarView className="icon-bar" showSidebar={showSidebar}>
      <IconoirProvider
        iconProps={{
          width: '2rem',
          height: '2rem',
          strokeWidth: '1.5'
        }}
      >
        <SidebarHeader>
          <NavLink to="/">
            <SidebarLinkIcon>
              <SidebarLogo>
                {icon ? <img src={icon} alt={shortName} /> : <Lockup />}
              </SidebarLogo>
            </SidebarLinkIcon>
            {shortName && (
              <SidebarHeaderText size="small">{shortName}</SidebarHeaderText>
            )}
          </NavLink>
        </SidebarHeader>
        <SidebarContent>
          <SidebarLinks>
            {filtered.map(link => (
              <SidebarLink key={link.path} {...link} />
            ))}
          </SidebarLinks>
        </SidebarContent>
        <SidebarFooter>
          <SidebarLinks>
            {footerLinks.map(link => (
              <SidebarLink key={link.path} {...link} />
            ))}
          </SidebarLinks>
          <SidebarToggle>
            <SidebarToggleButton>
              <ToggleColorScheme />
            </SidebarToggleButton>
            <SidebarToggleText size="small">
              {capitalize(colorScheme)}
            </SidebarToggleText>
          </SidebarToggle>
        </SidebarFooter>
      </IconoirProvider>
    </SidebarView>
  )
}

export default Sidebar
