import styled from '@emotion/styled'

const TableResultsView = styled.div`
  margin: 2rem 0 3rem;
`

const TableResultsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TableResults = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableResultsView>
      <TableResultsContent>{children}</TableResultsContent>
    </TableResultsView>
  )
}

export default TableResults
