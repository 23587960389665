import { useEffect, useState } from 'react'
import { ButtonSubmit, ErrMsg, Input, Select } from 'components'
import { brandTypes, enterpriseLevels } from 'config'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus } from 'utils'
import { OnboardingBrand, SelectOptionInterface } from 'types'
import {
  selectOnboardingBrand,
  updateOnboardingBrand
} from 'slices/iacm/onboardingBrand'
import {
  fetchCorporateOnlyOnboardingBrands,
  selectCorporateOnboardingBrands
} from 'slices/iacm/onboardingBrandsCorporateOnly'
import { closeModal } from 'slices/modal'

interface FormData {
  name: string
  brandType: string
  enterpriseLevelId: string
  parentBrandId: string | null
}

interface FormErrors {
  name: string
  brandType: string
  enterpriseLevelId: string
  parentBrandId?: string
}

const BrandOnboardingForm = ({
  brand,
  callback
}: {
  brand: OnboardingBrand
  callback?: () => void
}) => {
  const [submitted, setSubmitted] = useState(false)
  const { loading, error } = useAppSelector(selectOnboardingBrand)
  const { data: corporateBrands } = useAppSelector(
    selectCorporateOnboardingBrands
  )
  const isLoading = loading === RequestStatus.Pending
  const dispatch = useAppDispatch()

  const [formData, setFormData] = useState<FormData>({
    name: brand.name,
    brandType: brand.brandType,
    enterpriseLevelId: brand.enterpriseLevel.id,
    parentBrandId: brand.parentBrand?.id
  })

  const [formErrors, setFormErrors] = useState<FormErrors>({
    name: '',
    brandType: '',
    enterpriseLevelId: '',
    parentBrandId: ''
  })

  const validateForm = (): boolean => {
    const errors: FormErrors = {
      name: '',
      brandType: '',
      enterpriseLevelId: ''
    }

    // Validate each field
    if (!formData.name.trim()) {
      errors.name = 'Brand Name is required'
    }

    if (formData.brandType === null) {
      errors.brandType = 'Brand Type is required'
    }

    if (formData.enterpriseLevelId === null) {
      errors.enterpriseLevelId = 'Enterprise Level is required'
    }

    setFormErrors(errors)

    // Check if there are any errors
    return !Object.values(errors).some(error => error)
  }

  const handleInputChange = (name: string, value: string | null) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const getIndependentBrands = (): SelectOptionInterface[] => {
    if (corporateBrands?.results) {
      const brands = corporateBrands.results.map((brand: OnboardingBrand) => ({
        value: brand.brandId,
        text: brand.name
      }))
      return brands
    }
    return []
  }

  const handleSubmit = () => {
    if (validateForm()) {
      const updated = {
        id: brand.brandId,
        name: formData.name,
        brandType: formData.brandType,
        enterpriseLevelId: formData.enterpriseLevelId,
        parentBrandId: formData.parentBrandId ?? null
      }
      setSubmitted(true)
      dispatch(updateOnboardingBrand(updated))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (!error) {
        if (callback) callback()
        dispatch(closeModal())
      }
    }
  }, [submitted, isLoading, error, callback, loading, dispatch])

  useEffect(() => {
    dispatch(
      fetchCorporateOnlyOnboardingBrands({
        page: 1,
        perPage: 15,
        corporateOnly: true,
        appendArchiveInName: true
      })
    )
  }, [dispatch])

  return (
    <form onSubmit={handleSubmit}>
      {error && <ErrMsg errMsg={error.detail} />}
      <Input
        label="Brand Name"
        name="name"
        value={formData.name}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange('name', evt.target.value)
        }
        error={formErrors.name}
        required
        type={'text'}
      />

      <Select
        label="Brand Type"
        name="brandType"
        value={formData.brandType}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
          handleInputChange('brandType', evt.target.value)
        }
        error={formErrors.brandType}
        options={brandTypes}
        required
      />
      {formData.brandType === 'Franchise' && (
        <Select
          label="Parent Brand"
          name="parentBrandId"
          value={formData.parentBrandId}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
            handleInputChange('parentBrandId', evt.target.value)
          }
          options={getIndependentBrands()}
        />
      )}
      <Select
        label="Enterprise Level"
        name="enterpriseLevelId"
        value={formData.enterpriseLevelId}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
          handleInputChange('enterpriseLevelId', evt.target.value)
        }
        error={formErrors.enterpriseLevelId}
        options={enterpriseLevels}
        required
      />

      <ButtonSubmit
        text="Submit"
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading}
      />
    </form>
  )
}

export default BrandOnboardingForm
