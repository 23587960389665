import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { Discount } from 'types'
import { showNotification } from 'slices/notification'

export interface DiscountState {
  data: Discount | null
  loading: RequestStatus
  error: RequestError
}

const initialState: DiscountState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type DiscountParams = {
  expand?: string
  with_related?: string
}

export type DiscountCustomerParams = {
  discountId: string | number
  customers: { customer_id: number }[]
}

export const fetchDiscount = createAsyncThunk<
  Discount,
  { discountId: string | number; params?: DiscountParams },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/fetchDiscount',
  async ({ discountId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `discounts/${discountId}${queryParams}`
      const resp = (await api?.request(endpoint)) as Discount
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createDiscount = createAsyncThunk<
  Discount,
  { data: Discount; navigate: NavigateFunction; path: string },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/createDiscount',
  async ({ data, navigate, path }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('discounts', 'POST', data)) as Discount
      dispatch(showNotification('Discount successfully created!'))
      const pathname = `${path}/${resp.discount_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateDiscount = createAsyncThunk<
  Discount,
  {
    discountId: string | number
    data: Discount
  },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/updateDiscount',
  async ({ discountId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `discounts/${discountId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Discount
      dispatch(showNotification('Discount successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const addDiscountCustomer = createAsyncThunk<
  Discount,
  DiscountCustomerParams,
  { state: RootState; rejectValue: RequestError }
>(
  'discount/addDiscountCustomer',
  async (
    { discountId, customers },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `discounts/${discountId}/customers`
      const resp = (await api?.request(endpoint, 'POST', customers)) as Discount
      dispatch(showNotification('Customer added to reward.'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteDiscountCustomer = createAsyncThunk<
  Discount,
  DiscountCustomerParams,
  { state: RootState; rejectValue: RequestError }
>(
  'discount/deleteDiscountCustomer',
  async (
    { discountId, customers },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `discounts/${discountId}/customers`
      const resp = (await api?.request(
        endpoint,
        'DELETE',
        customers
      )) as Discount
      dispatch(showNotification('Customer removed from reward.'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    resetDiscount: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchDiscount.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchDiscount.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchDiscount.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createDiscount.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createDiscount.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createDiscount.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateDiscount.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateDiscount.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateDiscount.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(addDiscountCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addDiscountCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(addDiscountCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteDiscountCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteDiscountCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteDiscountCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetDiscount } = discountSlice.actions

export const selectDiscount = (state: RootState) => state.discount

export const selectDiscountQrCodesData = (state: RootState) => {
  return {
    data: state.discount.data?.qrcodes,
    loading: state.discount.loading,
    error: state.discount.error
  }
}

export const selectDiscountEmails = (state: RootState) => {
  return {
    data: state.discount.data?.emails,
    loading: state.discount.loading,
    error: state.discount.error
  }
}

export const selectDiscountCustomers = (state: RootState) => {
  return {
    data: state.discount.data?.customers,
    loading: state.discount.loading,
    error: state.discount.error
  }
}

export default discountSlice.reducer
