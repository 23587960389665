import styled from '@emotion/styled'

interface ModalContainerProps extends React.ComponentProps<'div'> {
  alignItems: string
}

const ModalContainer = styled.div<ModalContainerProps>`
  label: ModalContainer;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: center;
  overflow-y: auto;
  padding: ${props => props.theme.layout.desktop.padding};

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media only screen and (max-width: 600px) {
    padding: ${props => props.theme.layout.mobile.margin};
  }
`

export default ModalContainer
