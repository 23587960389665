import { MenuCategory } from '@open-tender/types'
import { Img } from 'components'
import {
  MenuCategoriesList,
  MenuCategoriesView,
  MenuCategoryHeader,
  MenuCategoryHeaderText,
  MenuCategoryTitle,
  MenuItemId,
  MenuItemImage,
  MenuItemInfo,
  MenuItemTitle,
  MenuItemView
} from './Menu.styled'

const MenuCategories = ({
  menu,
  scrollTo
}: {
  menu: MenuCategory[]
  scrollTo: (slug: string) => void
}) => {
  if (!menu.length) return null

  return (
    <MenuCategoriesView>
      <MenuCategoryHeader>
        <MenuCategoryHeaderText>
          <MenuCategoryTitle as="h2" size="h1">
            Menu Categories
          </MenuCategoryTitle>
        </MenuCategoryHeaderText>
      </MenuCategoryHeader>
      <MenuCategoriesList>
        {menu.map(category => (
          <MenuItemView onClick={() => scrollTo(category.slug)}>
            <MenuItemImage>
              <Img
                src={category.small_image_url}
                title={category.name}
                fontSize="small"
              />
            </MenuItemImage>
            <MenuItemInfo>
              <MenuItemId size="xsmall" color="tertiary">
                {category.id}
              </MenuItemId>
              <MenuItemTitle as="p" size="medium">
                {category.name}
              </MenuItemTitle>
            </MenuItemInfo>
          </MenuItemView>
        ))}
      </MenuCategoriesList>
    </MenuCategoriesView>
  )
}

export default MenuCategories
