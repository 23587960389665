import { Minus, Plus } from 'iconoir-react'
import Button from 'components/Button'
import {
  MenuItemQuantityButton,
  MenuItemQuantityInput,
  MenuItemQuantityView
} from './MenuItemQuantity.styled'
import { Input } from 'components/Forms'
import { ChangeEvent } from 'react'

const MenuItemQuantity = ({
  quantity,
  adjust,
  increaseQuantity,
  decreaseQuantity,
  incrementDisabled = false,
  decrementDisabled = false
}: {
  quantity: number
  adjust?: (quentity: number) => void
  decreaseQuantity: () => void
  increaseQuantity: () => void
  incrementDisabled?: boolean
  decrementDisabled?: boolean
}) => {
  const handleAdjust = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!adjust) return
    const value = parseInt(evt.target.value)
    const quantity = isNaN(value) || value < 1 ? 0 : value
    adjust(quantity)
  }

  return (
    <MenuItemQuantityView>
      <MenuItemQuantityButton>
        <Button
          color="secondary"
          onClick={decreaseQuantity}
          disabled={decrementDisabled}
        >
          <Minus width={18} height={18} />
        </Button>
      </MenuItemQuantityButton>
      <MenuItemQuantityInput>
        <Input
          fieldType="Input"
          type="number"
          name="quantity"
          value={quantity === 0 ? '' : quantity}
          onChange={handleAdjust}
          placeholder="0"
        />
      </MenuItemQuantityInput>
      <MenuItemQuantityButton>
        <Button
          color="secondary"
          onClick={increaseQuantity}
          disabled={incrementDisabled}
        >
          <Plus width={18} height={18} />
        </Button>
      </MenuItemQuantityButton>
    </MenuItemQuantityView>
  )
}

export default MenuItemQuantity
