import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTask, ProductMixReports } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface ProductMixReportState {
  data: ProductMixReports
  loading: RequestStatus
  error: RequestError
}

const initialState: ProductMixReportState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export type FetchProductMixReportParams = {
  store_id?: string
  revenue_center_id?: string
  order_type?: string
  service_type?: string
  entity_type?: string
  start_date: string
  end_date: string
}

export const fetchProductMixReport = createAsyncThunk<
  ProductMixReports,
  FetchProductMixReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'productMixReport/fetchProductMixReport',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/product-mix${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as ProductMixReports
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createProductMixReportCSV = createAsyncThunk<
  BackgroundTask,
  FetchProductMixReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'productMixReport/createProductMixReportCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/product-mix/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as BackgroundTask
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createProductMixByGroupReportCSV = createAsyncThunk<
  BackgroundTask,
  FetchProductMixReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'productMixReport/createProductMixByGroupReportCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/product-mix-by-group/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as BackgroundTask
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const productMixReportSlice = createSlice({
  name: 'productMixReport',
  initialState,
  reducers: {
    resetProductMixReport: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchProductMixReport.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchProductMixReport.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchProductMixReport.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetProductMixReport } = productMixReportSlice.actions

export const selectProductMixReport = (state: RootState) =>
  state.productMixReport

export default productMixReportSlice.reducer
