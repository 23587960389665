import { TextBody, TextHeading } from 'components'
import {
  CheckmateCurrency,
  CheckmateMenuLocation,
  CheckmateMenuMeta
} from 'types'
import { snakeCaseToCapitalized } from 'utils'
import {
  CheckmateMenuHoursView,
  CheckmateMenuHoursWeekday,
  CheckmateMenuInfoSectionHeader
} from './CheckmateMenu.styled'

interface CheckmateMenuHeaderProps {
  currency: CheckmateCurrency
  meta: CheckmateMenuMeta
  location: CheckmateMenuLocation
}

interface MetaListItem {
  name: string
  value: string
}

const CheckmateMenuDetails = ({
  currency,
  meta,
  location
}: CheckmateMenuHeaderProps) => {
  const metaList = Object.entries(meta).reduce((arr, [key, value]) => {
    return [
      ...arr,
      { name: snakeCaseToCapitalized(key), value: value ? 'Yes' : 'No' }
    ]
  }, [] as MetaListItem[])

  return (
    <CheckmateMenuHoursView>
      <CheckmateMenuInfoSectionHeader>
        <TextHeading size="large">Other Details</TextHeading>
      </CheckmateMenuInfoSectionHeader>
      <CheckmateMenuHoursWeekday>
        <TextBody size="small">Location</TextBody>
        <TextBody size="small">
          {location.name} ({location.id})
        </TextBody>
      </CheckmateMenuHoursWeekday>
      <CheckmateMenuHoursWeekday>
        <TextBody size="small">Currency</TextBody>
        <TextBody size="small">{currency}</TextBody>
      </CheckmateMenuHoursWeekday>
      {metaList.map(({ name, value }) => (
        <CheckmateMenuHoursWeekday key={name}>
          <TextBody size="small">{name}</TextBody>
          <TextBody size="small">{value}</TextBody>
        </CheckmateMenuHoursWeekday>
      ))}
    </CheckmateMenuHoursView>
  )
}

export default CheckmateMenuDetails
