import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { DeliverySettings, StoreSettingsFrom } from 'types'

export interface RevenueCenterDeliveryState {
  data: DeliverySettings | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError | null
}

export interface RevenueCenterDeliveryApi {
  data: DeliverySettings
  from: StoreSettingsFrom
}

const initialState: RevenueCenterDeliveryState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRevenueCenterDelivery = createAsyncThunk<
  RevenueCenterDeliveryApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterDelivery/fetchRevenueCenterDelivery',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `revenue-centers/${revenueCenterId}/settings/delivery`
      )) as RevenueCenterDeliveryApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertRevenueCenterDelivery = createAsyncThunk<
  RevenueCenterDeliveryApi,
  { revenueCenterId: string | number; data: DeliverySettings },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterDelivery/upsertRevenueCenterDelivery',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `revenue-centers/${revenueCenterId}/settings/delivery`,
        'PUT',
        data
      )) as RevenueCenterDeliveryApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterDelivery = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterDelivery/deleteRevenueCenterDelivery',
  async (revenueCenterId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request<void>(
        `revenue-centers/${revenueCenterId}/settings/delivery`,
        'DELETE'
      )
      dispatch(showNotification('Successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterDeliverySlice = createSlice({
  name: 'revenueCenterDelivery',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchRevenueCenterDelivery.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload?.data || null
        state.from = payload?.from || null
        state.error = null
      }
    )
    builder.addCase(
      fetchRevenueCenterDelivery.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(upsertRevenueCenterDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      upsertRevenueCenterDelivery.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload.data
        state.from = payload.from
        state.error = null
      }
    )
    builder.addCase(
      upsertRevenueCenterDelivery.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteRevenueCenterDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterDelivery.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(
      deleteRevenueCenterDelivery.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const selectRevenueCenterDelivery = (state: RootState) =>
  state.revenueCenterDelivery

export default revenueCenterDeliverySlice.reducer
