import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Brand } from 'types'
import { showNotification } from 'slices/notification'
import { fetchBrands } from './brands'
import { closeModal } from './modal'

export interface BrandState {
  data: Brand | null
  loading: RequestStatus
  error: RequestError
}

const initialState: BrandState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBrand = createAsyncThunk<
  Brand,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('brand/fetchBrand', async (brandId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `super/brands/${brandId}`
    const resp = (await api?.request(endpoint)) as Brand
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const updateBrand = createAsyncThunk<
  Brand,
  {
    brandId: string | number
    data: Brand
  },
  { state: RootState; rejectValue: RequestError }
>(
  'brand/updateBrand',
  async ({ brandId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `super/brands/${brandId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Brand
      dispatch(showNotification('Brand successfully updated!'))
      dispatch(fetchBrands())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteBrand = createAsyncThunk<
  void,
  {
    brandId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'brand/deleteBrand',
  async ({ brandId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `super/brands/${brandId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Brand removed!'))
      dispatch(closeModal())
      navigate({ pathname: '/super/brands' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    resetBrand: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBrand.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBrand.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchBrand.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateBrand.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateBrand.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateBrand.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteBrand.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteBrand.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteBrand.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBrand } = brandSlice.actions

export const selectBrand = (state: RootState) => state.brand

export default brandSlice.reducer
