import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  BulkOrder,
  BulkOrders,
  OrderEdit,
  OrderTypeOld,
  PrepStatus,
  ReceiptStatus,
  ServiceType
} from 'types'
import {
  makeLocalDateStr,
  makeQueryParams,
  RequestError,
  RequestStatus
} from 'utils'
import { BulkOrdersAPI, BulkOrdersParams } from './bulkOrders'
import { showNotification } from './notification'

export interface OrderMgmtState {
  data: BulkOrders
  orderId: number | null
  orderPrint: BulkOrder | null
  statusTypes: ReceiptStatus[]
  orderTypes: OrderTypeOld[]
  serviceTypes: ServiceType[]
  loading: RequestStatus
  error: RequestError
}

const initialState: OrderMgmtState = {
  data: [],
  orderId: null,
  orderPrint: null,
  statusTypes: ['OPEN'],
  orderTypes: [],
  serviceTypes: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchOrderMgmt = createAsyncThunk<
  BulkOrders,
  BulkOrdersParams | void,
  { state: RootState; rejectValue: RequestError }
>('orderMgmt/fetchOrderMgmt', async (params, { getState, rejectWithValue }) => {
  try {
    const defaultParams: BulkOrdersParams = {
      replica: false,
      exclude_in_store_orders: true,
      receipt_type: 'SALE',
      expand: 'true',
      with_related: 'tenders,items',
      'sort-by': 'requested_at',
      'sort-direction': 'ASC',
      limit: 1000
    }
    const queryParams = makeQueryParams({ ...defaultParams, ...params })
    const endpoint = `bulk-orders${queryParams}`
    const today = makeLocalDateStr()
    const yesterday = makeLocalDateStr(-1)
    const openEndpoint = `${endpoint}&receipt_status=OPEN&end_date=${yesterday}`
    const futureEndpoint = `${endpoint}&start_date=${today}`
    const api = getState().authUser.api
    const openRequest = api?.request(openEndpoint) as Promise<BulkOrdersAPI>
    const futureRequest = api?.request(futureEndpoint) as Promise<BulkOrdersAPI>
    const [open, future] = await Promise.all([openRequest, futureRequest])
    return [...open.data, ...future.data]
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const editOrder = createAsyncThunk<
  number | void,
  { orderId: number; data: OrderEdit },
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/editOrder',
  async ({ orderId, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      await api?.request(`orders/${orderId}/edit`, 'PUT', data)
      dispatch(showNotification('Order updated!'))
      // dispatch(fetchOrderMgmt())
      if (data.revenue_center_id) {
        return orderId
      }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePrepStatus = createAsyncThunk<
  void,
  { orderId: number; prepStatus: PrepStatus },
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/updatePrepStatus',
  async ({ orderId, prepStatus }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      const data = { prep_status: prepStatus }
      await api?.request(`orders/${orderId}/update-prep-status`, 'PUT', data)
      dispatch(showNotification('Prep status updated!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const closeOrder = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/closeOrder',
  async (orderId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      await api?.request(`orders/${orderId}/close`, 'POST', null)
      dispatch(showNotification('Order successfully closed!'))
      return orderId
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const cancelOrder = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/cancelOrder',
  async (orderId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      await api?.request(`orders/${orderId}/cancel`, 'POST', null)
      dispatch(showNotification('Order successfully cancelled!'))
      return orderId
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const markReviewed = createAsyncThunk<
  void,
  number,
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/markReviewed',
  async (orderId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      await api?.request(`orders/${orderId}/mark-reviewed`, 'POST', null)
      dispatch(showNotification('Order marked reviewed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const resendEmail = createAsyncThunk<
  void,
  number | string,
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/resendEmail',
  async (orderId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      await api?.request(`orders/${orderId}/resend-email`, 'POST', null)
      dispatch(showNotification('Email resent to customer!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const printOrder = createAsyncThunk<
  BulkOrder,
  number | string,
  { state: RootState; rejectValue: RequestError }
>(
  'orderMgmt/printOrder',
  async (orderId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      dispatch(setOrderMgmtOrder(orderId))
      const queryParams = makeQueryParams({
        expand: 'true',
        with_related: 'true'
      })
      const endpoint = `bulk-orders/${orderId}${queryParams}`
      const printOrder = await api?.request(endpoint, 'GET')
      dispatch(resetOrderMgmtOrder())
      return printOrder as BulkOrder
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const OrderMgmtSlice = createSlice({
  name: 'orderMgmt',
  initialState,
  reducers: {
    resetOrderMgmt: () => initialState,
    resetOrderMgmtOrder: state => {
      state.orderId = null
    },
    setOrderMgmtOrder: (state, action) => {
      state.orderId = action.payload
    },
    resetOrderPrint: state => {
      state.orderPrint = null
    },
    toggleStatusType: (state, action) => {
      const { payload } = action
      state.statusTypes = state.statusTypes.includes(payload)
        ? state.statusTypes.filter(i => i !== payload)
        : [payload]
    },
    toggleOrderType: (state, action) => {
      const { payload } = action
      state.orderTypes = state.orderTypes.includes(payload)
        ? state.orderTypes.filter(i => i !== payload)
        : [payload]
    },
    toggleServiceType: (state, action) => {
      const { payload } = action
      state.serviceTypes = state.serviceTypes.includes(payload)
        ? state.serviceTypes.filter(i => i !== payload)
        : [payload]
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderMgmt.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderMgmt.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOrderMgmt.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(editOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(editOrder.fulfilled, (state, { payload }) => {
      state.data = state.data.filter(i => i.receipt_id !== payload)
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(editOrder.rejected, (state, { payload }) => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePrepStatus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updatePrepStatus.fulfilled, state => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updatePrepStatus.rejected, (state, { payload }) => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(closeOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(closeOrder.fulfilled, (state, { payload }) => {
      state.data = state.data.filter(i => i.receipt_id !== payload)
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(closeOrder.rejected, (state, { payload }) => {
      state.data = state.data.filter(i => i.receipt_id !== state.orderId)
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(cancelOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(cancelOrder.fulfilled, (state, { payload }) => {
      state.data = state.data.filter(i => i.receipt_id !== payload)
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(cancelOrder.rejected, (state, { payload }) => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(markReviewed.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(markReviewed.fulfilled, state => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(markReviewed.rejected, (state, { payload }) => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(resendEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(resendEmail.fulfilled, (state, { payload }) => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(resendEmail.rejected, (state, { payload }) => {
      state.orderId = null
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(printOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(printOrder.fulfilled, (state, { payload }) => {
      state.orderPrint = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(printOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const {
  resetOrderMgmt,
  resetOrderMgmtOrder,
  resetOrderPrint,
  setOrderMgmtOrder,
  toggleStatusType,
  toggleOrderType,
  toggleServiceType
} = OrderMgmtSlice.actions

export const selectOrderMgmt = (state: RootState) => state.orderMgmt

export const selectOrderMgmtOrders = createSelector(
  (state: RootState) => {
    const { data, statusTypes, orderTypes, serviceTypes, loading, error } =
      state.orderMgmt
    return { data, statusTypes, orderTypes, serviceTypes, loading, error }
  },
  ({ data, statusTypes, orderTypes, serviceTypes, loading, error }) => {
    let orders = statusTypes?.length
      ? data.filter(i => statusTypes.includes(i.receipt_status))
      : data
    orders = orderTypes?.length
      ? orders.filter(i => orderTypes.includes(i.order_type))
      : orders
    orders = serviceTypes?.length
      ? orders.filter(i => serviceTypes.includes(i.service_type))
      : orders
    return { orders, statusTypes, orderTypes, serviceTypes, loading, error }
  }
)

export const selectOrderMgmtOrder = (state: RootState) =>
  state.orderMgmt.orderId

export const selectOrderMgmtOrderPrint = (state: RootState) =>
  state.orderMgmt.orderPrint

export const selectOrderMgmtStatusTypes = (state: RootState) =>
  state.orderMgmt.statusTypes

export const selectOrderMgmtOrderTypes = (state: RootState) =>
  state.orderMgmt.orderTypes

export const selectOrderMgmtServiceTypes = (state: RootState) =>
  state.orderMgmt.serviceTypes

export const selectOrderMgmtIsFiltered = createSelector(
  (state: RootState) => {
    const { statusTypes, orderTypes, serviceTypes } = state.orderMgmt
    return { statusTypes, orderTypes, serviceTypes }
  },
  ({ statusTypes, orderTypes, serviceTypes }) => {
    return statusTypes.length || orderTypes.length || serviceTypes.length
      ? true
      : false
  }
)

export default OrderMgmtSlice.reducer
