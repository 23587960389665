import React from 'react'
import { InputInterface } from 'types'
import { Label } from '..'
import {
  InputClockIcon,
  InputCalendarIcon,
  InputColorContainer,
  InputColorHash,
  InputColorInput,
  InputColorView,
  InputColorWrapper,
  InputContainer,
  InputDeleteIcon,
  InputIconContainer,
  InputRangeContainer,
  InputRangeInput,
  InputRangeSliderView,
  InputView
} from './Input.styled'
import { useForwardRef } from 'hooks'
import { DesignLabel } from 'components/DesignInputs'

const Input = React.forwardRef<HTMLInputElement, InputInterface>(
  (
    {
      label,
      hideIcon,
      parenthetical,
      tooltip,
      name,
      type,
      value,
      onChange,
      onKeyDown,
      onBlur,
      setValue,
      error,
      placeholder,
      required = false,
      nullable = false,
      disabled = false,
      readOnly = false,
      autoComplete,
      pattern,
      id,
      min,
      max,
      step,
      children,
      icon
    },
    ref
  ) => {
    const inputRef = useForwardRef<HTMLInputElement>(ref)

    const handleClear = () => {
      setValue && setValue(name, null)
    }

    const handleCalendarClick = () => {
      inputRef.current?.showPicker()
    }

    const input = (
      <InputView
        aria-label={label}
        id={id || name}
        name={name}
        type={type}
        pattern={pattern}
        min={min}
        max={max}
        autoComplete={autoComplete}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        hideIcon={hideIcon}
        required={required}
        nullable={nullable}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={inputRef}
        step={step}
      />
    )

    return (
      <Label
        htmlFor={id || name}
        label={label}
        parenthetical={parenthetical}
        required={required}
        errMsg={error}
        tooltip={tooltip}
      >
        {type === 'color' ? (
          <InputColorContainer>
            <InputColorView>
              <InputColorWrapper>
                <InputColorInput
                  type="color"
                  value={`#${value}`}
                  onChange={onChange}
                />
              </InputColorWrapper>
              <InputColorHash>#</InputColorHash>
              <InputView
                aria-label={label}
                id={name}
                name={name}
                type="text"
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
              />
            </InputColorView>
          </InputColorContainer>
        ) : type === 'range' ? (
          <>
            <InputRangeContainer>
              <InputRangeSliderView>
                <DesignLabel htmlFor={name}>{min}</DesignLabel>
                <InputRangeInput
                  type="range"
                  value={value}
                  onChange={onChange}
                  min={min}
                  max={max}
                  step={1}
                  disabled={disabled}
                />
                <DesignLabel htmlFor={name}>{max}</DesignLabel>
              </InputRangeSliderView>
              <Input
                type="number"
                name={name}
                value={value}
                setValue={setValue}
                min={min}
                max={max}
                step={1}
                disabled={disabled}
                onChange={onChange}
              />
            </InputRangeContainer>
          </>
        ) : ['time', 'date', 'datetime-local'].includes(type) ? (
          <InputContainer>
            {input}
            {!hideIcon && !readOnly && !disabled ? (
              <InputIconContainer>
                {type === 'time' ? (
                  <InputClockIcon onClick={handleCalendarClick} />
                ) : (
                  <InputCalendarIcon onClick={handleCalendarClick} />
                )}
                {nullable && <InputDeleteIcon onClick={handleClear} />}
              </InputIconContainer>
            ) : (
              icon
            )}
          </InputContainer>
        ) : (
          <InputContainer>
            {input}
            {icon}
          </InputContainer>
        )}
        {children}
      </Label>
    )
  }
)

export default Input
