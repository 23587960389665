import { NavArrowDown } from 'iconoir-react'
import {
  TableRefreshPerPageCurrent,
  TableRefreshPerPageIncrement,
  TableRefreshPerPageIncrements,
  TableRefreshPerPageView
} from './TableRefresh.styled'
import TextBody from 'components/TextBody'
import { useEffect, useRef, useState } from 'react'

const TableRefreshPerPage = ({
  increments,
  perPage,
  setPerPage
}: {
  increments: number[]
  perPage: number
  setPerPage: (perPage: number) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setShow])

  return (
    <TableRefreshPerPageView>
      <TableRefreshPerPageCurrent onClick={() => setShow(!show)}>
        <TextBody size="small">{perPage} per page</TextBody>
        <NavArrowDown width={18} height={18} strokeWidth={1.5} />
      </TableRefreshPerPageCurrent>
      <TableRefreshPerPageIncrements ref={ref} show={show}>
        {increments.map(increment => (
          <TableRefreshPerPageIncrement
            key={increment}
            onClick={() => setPerPage(increment)}
          >
            <TextBody size="small">{increment} per page</TextBody>
          </TableRefreshPerPageIncrement>
        ))}
      </TableRefreshPerPageIncrements>
    </TableRefreshPerPageView>
  )
}

export default TableRefreshPerPage
