import { useAppDispatch } from 'app/hooks'
import { Button } from 'components'
import { ModalWindow } from 'types'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

const TemplateChange = ({
  args
}: {
  args: {
    channel: string
    handleChange: () => void
  }
  modalWindow: ModalWindow
}) => {
  const { channel, handleChange } = args
  const dispatch = useAppDispatch()

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Confirmation" />
      <ModalContent>
        This selection will overwrite any {channel} content that was previously
        saved. Are you sure you want to start over?
      </ModalContent>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" size="medium" onClick={handleChange}>
          Yes
        </Button>
      </ModalFooter>
    </>
  )
}

export default TemplateChange
