import { gql } from 'graphql-request'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ONBOARDING_BRAND_FRAGMENT, RequestError, RequestStatus } from 'utils'
import {
  OnboardingBrandsParams,
  OnboardingBrandsState
} from './onboardingBrands'
import { OnboardingBrands } from 'types'

interface OnboardingBrandsAPI {
  onboardingBrands: OnboardingBrands | null
}

const initialState: OnboardingBrandsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_ONBOARDING_BRANDS = gql`
  query UseOnboardingBrands(
    $searchTerm: String
    $page: Int
    $perPage: Int
    $brandIds: [ID!]
    $corporateOnly: [Boolean!]
    $filters: [JSON!]
    $filterByArchiveStatus: Boolean
    $appendArchiveInName: Boolean
  ) {
    onboardingBrands(
      searchTerm: $searchTerm
      page: $page
      perPage: $perPage
      brandIds: $brandIds
      corporateOnly: $corporateOnly
      filters: $filters
      filterByArchiveStatus: $filterByArchiveStatus
      appendArchiveInName: $appendArchiveInName
    ) {
      page
      totalPageCount
      totalRecordsCount
      results {
        ...OnboardingBrandFragment
      }
    }
  }
  ${ONBOARDING_BRAND_FRAGMENT}
`

export const fetchCorporateOnlyOnboardingBrands = createAsyncThunk<
  OnboardingBrandsAPI,
  OnboardingBrandsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingBrands/fetchCorporateOnboardingBrands',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_ONBOARDING_BRANDS,
        params
      )) as OnboardingBrandsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const corporateBrandsSlice = createSlice({
  name: 'corporateOnboardingBrands',
  initialState,
  reducers: {
    resetCoporateOnboardingBrands: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCorporateOnlyOnboardingBrands.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchCorporateOnlyOnboardingBrands.fulfilled,
      (state, { payload }) => {
        state.data = payload.onboardingBrands
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchCorporateOnlyOnboardingBrands.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetCoporateOnboardingBrands } = corporateBrandsSlice.actions

export const selectCorporateOnboardingBrands = (state: RootState) =>
  state.onboardingBrandsCorporate

export default corporateBrandsSlice.reducer
