import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { EmailDesign } from 'types'
import { showNotification } from 'slices/notification'

export interface EmailDesignState {
  data: EmailDesign | null
  loading: RequestStatus
  error: RequestError
}

const initialState: EmailDesignState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchEmailDesign = createAsyncThunk<
  EmailDesign | null,
  void,
  { state: RootState; rejectValue: RequestError }
>('emailDesign/fetchEmailDesign', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const resp = (await api?.request(`email-design-settings`)) as EmailDesign
    return resp || null
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertEmailDesign = createAsyncThunk<
  EmailDesign,
  { data: EmailDesign },
  { state: RootState; rejectValue: RequestError }
>(
  'emailDesign/upsertEmailDesign',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `email-design-settings`,
        'PUT',
        data
      )) as EmailDesign
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchEmailPreview = createAsyncThunk<
  string,
  string,
  { state: RootState; rejectValue: RequestError }
>(
  'emailDesign/upsertEmailDesign',
  async (preview, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      if (preview === 'confirmation') preview = ''
      else preview = preview + '-'
      return (await api?.request(`${preview}email-preview`)) as string
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const emailDesignSlice = createSlice({
  name: 'EmailDesign',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEmailDesign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchEmailDesign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchEmailDesign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertEmailDesign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertEmailDesign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertEmailDesign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectEmailDesign = (state: RootState) => state.emailDesign

export default emailDesignSlice.reducer
