import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { UserRole, UserPermissions } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface UserPermissionsState {
  data: UserPermissions
  loading: RequestStatus
  error: RequestError
}

interface UpdateObj {
  tag: string
  role: UserRole
}

export type UpdatePermissionsReq = UpdateObj[]

const initialState: UserPermissionsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchUserPermissions = createAsyncThunk<
  UserPermissions,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'UserPermissions/fetchUserPermissions',
  async (_, { getState, rejectWithValue }) => {
    try {
      const endpoint = `permissions`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as UserPermissions
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateUserPermissions = createAsyncThunk<
  UserPermissions,
  UpdatePermissionsReq,
  { state: RootState; rejectValue: RequestError }
>(
  'userPermissions/updateUserPermissions',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `permissions`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as UserPermissions
      dispatch(showNotification('Permissions successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    resetUserPermissions: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUserPermissions.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchUserPermissions.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchUserPermissions.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateUserPermissions.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateUserPermissions.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateUserPermissions.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetUserPermissions } = userPermissionsSlice.actions

export const selectUserPermissions = (state: RootState) => state.userPermissions

export default userPermissionsSlice.reducer
