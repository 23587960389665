import { CardTypeName } from '@open-tender/types'
import { BackgroundTask } from './backgroundTask'

export type ColorScheme = 'LIGHT' | 'DARK'

export type NumberString = `${number}`

export type Decimal = `${number}.${number}`

export type Money = `${number}.${number}`

export type NegativeMoney = `-${number}.${number}`

export type Percentage = `${number}.${number}%`

export type IpAddress = `${number}.${number}.${number}.${number}`

export type Url = `https://${string}`

export interface PicklistOption {
  name: string
  value: string | number
  disabled?: boolean
}

export type PicklistOptions = PicklistOption[]

export enum ProcessingState {
  Inactive = 'inactive',
  Processing = 'processing'
}

export interface FileType {
  entity_type: string
  file_id: number
  file_source: string
  file_type: string
  file_url: string
}

export type Files = FileType[]

export type Weekday =
  | 'SUNDAY'
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'HOLIDAY'

export type WeekdayLower =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

export type OrderTypeOld = 'MAIN_MENU' | 'CATERING' | 'MERCH'

export type OrderTypeFilter = OrderTypeOld | 'ALL'

export type OrderType = 'OLO' | 'CATERING' | 'MERCH'

export type FullOrderType = OrderType | 'MAIN_MENU' | 'ECOMMERCE'

export type OrderTypeMap = {
  [Property in OrderType]: string
}

export type FullOrderTypeMap = {
  [Property in FullOrderType]: string
}

export type ServiceType = 'WALKIN' | 'PICKUP' | 'DELIVERY'

export type ServiceTypeFilter = ServiceType | 'ALL'

export type ServiceTypeMap = {
  [Property in ServiceType]: string
}

export type TenderType =
  | 'CASH'
  | 'CREDIT'
  | 'HOUSE_ACCOUNT'
  | 'GIFT_CARD'
  | 'COMP'
  | 'APPLE_PAY'
  | 'GOOGLE_PAY'
  | 'EXTERNAL'

export type TenderTypeMap = {
  [Property in TenderType]: string
}

export type CardType = 'VISA' | 'MC' | 'DISC' | 'AMEX' | 'OTHER'

export type DiscountType = 'DOLLAR' | 'PERCENTAGE' | 'LOYALTY'

export type SurchargeType = 'DOLLAR' | 'PERCENTAGE' | 'DISTANCE' | 'TRAVEL_TIME'

export type TaxType = 'DOLLAR' | 'PERCENTAGE'

export type TaxTypeInt = 1 | 2

export type Temperature = 'HOT' | 'COLD'

export type ReceiptType = 'SALE' | 'REFUND'

export type ChannelType =
  | 'POS'
  | 'ONLINE'
  | 'APP'
  | 'PHONE'
  | 'KIOSK'
  | 'PORTAL'

export type ChannelTypeFilter = ChannelType | 'ALL'

export type OrderGroupFilter = 'DATE' | 'STORE'

export type OrderStatus = 'OPEN' | 'HELD' | 'CLOSED' | 'VOID' | 'PENDING'

export type OrderStatusFilter = OrderStatus | 'ALL'

export type TenderStatus =
  | 'AUTHORIZED'
  | 'PAID'
  | 'REFUNDED'
  | 'VOID'
  | 'FAILED'

export type TenderStatusFilter = TenderStatus | 'ALL'

export type EntityType =
  | 'TIME_PUNCH'
  | 'CASH_EVENT'
  | 'ORDER'
  | 'DEPARTMENT'
  | 'DEVICES'
  | 'DISCOUNT'
  | 'EMPLOYEE'
  | 'HOUSE_ACCOUNT'
  | 'LOCATION'
  | 'MENU'
  | 'MENU_PAGE'
  | 'SELECT_OPTION'
  | 'SURCHARGE'
  | 'TAX'
  | 'STORE'
  | 'REVENUE_CENTER'
  | 'ITEM_TYPE'
  | 'QRCODE'
  | 'KIOSK_CONFIG'

export type PrepStatus =
  | 'TODO'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'COMPLETED'
  | 'FULFILLED'

export type PrepType = 'EAT_HERE' | 'TAKE_OUT'

export type ActiveStatus = 'ACTIVE' | 'INACTIVE' | 'none' | null

export type ThresholdField = 'NAME' | 'AMOUNT'

export type PerPageFilter = 10 | 25 | 50 | 100 | 200 | 500 | 1000

export type SaleType = 'ALL' | 'SALE' | 'REFUND'

export type PrintReceipt = 'ALWAYS' | 'PROMPT' | 'NEVER'

export type POSStage = 'PLACED' | 'FIRED' | 'CONFIRMED'

export type DeviceType = 'DESKTOP' | 'MOBILE' | 'TABLET' | 'KIOSK' | 'NONE'

export type OrderSource = 'BRANDIBBLE' | 'MEDIAWORKS' | 'EATSA' | 'CHOWLY'

export type ReceiptStatus = 'OPEN' | 'HELD' | 'CLOSED' | 'VOID' | 'PENDING'

export type InitialReceiptStatus = 'OPEN' | 'PENDING'

export type CheckmateToosEnv = 'PRODUCTION' | 'SANDBOX' | 'STAGING' | null

export type EmployeeStatus =
  | 'INACTIVE'
  | 'ACTIVE'
  | 'TERMINATED'
  | 'ON_LEAVE'
  | 'RETIRED'
export type SortDirection = 'ASC' | 'DESC'

export interface FormattedAddress {
  street: string
  city: string
  state: string
  postal_code: string
}

export interface CsvAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export interface CreditCard {
  card_type_id: number
  customer_card_id: number
  card_type: CardType
  card_type_name: CardTypeName
  has_profile?: boolean
  is_default: boolean
  is_levelup: boolean
  last4: string
  masked: string
}

export type CreditCards = CreditCard[]

export interface SearchResult {
  id: number | undefined
  name: string
}

export type ExternalAPI =
  | 'CHECKMATE_TOOS'
  | 'CHOWLY_TOOS'
  | 'COMO'
  | 'DOORDASH'
  | 'FIREBASE'
  | 'MAILCHIMP'
  | 'NMI'
  | 'ONFLEET'
  | 'PUNCHH'
  | 'SPARKFLY'
  | 'RELAY'
  | 'THANX'
  | 'TWILIO'
  | 'UBER_DIRECT'
  | 'VANTIV'

export interface SimplifiedOrderItem {
  line_no: number
  full_name: string
  price: string
  quantity: number
  price_total: string
  modifier_groups: SimplifiedModifierGroup[]
}

export interface SimplifiedModifierGroup {
  line_no: number
  full_name: string
  modifiers: SimplifiedModifier[]
}

export interface SimplifiedModifier {
  groups: SimplifiedModifierGroup[]
  option_group_id: number
  line_no: number
  full_name: string
  quantity: number
  price: string
  price_total: string
}

export type PaidStatus = 'PAID' | 'UNPAID' | 'none' | null

export interface TableHeaderConfig {
  text: string
  value: string
  sorter?: boolean
}

export interface TableSort {
  order: 'asc' | 'desc'
  orderBy: string | null
}

export type LoginMethod =
  | 'EMAIL_PW'
  | 'SMS_OTP'
  | 'EMAIL_OTP'
  | 'EMAIL_OR_SMS_OTP'
  | 'EMAIL_AND_SMS_PW_OTP'
  | 'EMAIL_AND_SMS_OTP'
