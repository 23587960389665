import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { IacmAuthHeaders, IacmAuthUser, IacmLoginError } from 'types'
import { RequestStatus, RequestError } from 'utils/api'
import IacmAPI, { iacmSignInRequest } from 'utils/iacmApi'

export interface iacmUserState {
  user: IacmAuthUser | null
  headers: IacmAuthHeaders | null
  api: IacmAPI | null
  loading: RequestStatus
  error: RequestError | null
}

const initialState: iacmUserState = {
  user: null,
  headers: null,
  api: null,
  loading: RequestStatus.Idle,
  error: null
}

export const signInIacmUser = createAsyncThunk<
  { headers: IacmAuthHeaders; user: IacmAuthUser },
  { email: string; password: string },
  { state: RootState; rejectValue: IacmLoginError }
>('iacmUser/signInIacmUser', async (auth, { getState, rejectWithValue }) => {
  try {
    return await iacmSignInRequest(auth)
  } catch (err) {
    return rejectWithValue(err as IacmLoginError)
  }
})

export const iacmUserSlice = createSlice({
  name: 'iacmUser',
  initialState,
  reducers: {
    resetIacmUser: () => initialState,
    setIacmApi: (
      state: iacmUserState,
      { payload }: { payload: { headers: IacmAuthHeaders; user: IacmAuthUser } }
    ) => {
      state.user = payload.user
      state.headers = payload.headers
      state.api = new IacmAPI({ ...payload.headers })
    }
  },
  extraReducers: builder => {
    builder.addCase(signInIacmUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(signInIacmUser.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.headers = payload.headers
      state.user = payload.user
      state.api = new IacmAPI({ ...payload.headers })
    })
    builder.addCase(signInIacmUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      const error: RequestError = {
        code: 'IACM_LOGIN_ERROR',
        title: 'Checkmate Login Error',
        detail: payload?.errors[0] || 'Unknown IACM login error',
        status: 401
      }
      state.error = error
    })
  }
})

export const { resetIacmUser, setIacmApi } = iacmUserSlice.actions

export const selectIacm = (state: RootState) => state.iacmUser
export const selectIacmApi = (state: RootState) => state.iacmUser.api
export const selectIacmUser = (state: RootState) => state.iacmUser.user

export default iacmUserSlice.reducer
