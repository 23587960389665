import { Button, TextBody } from 'components'
import { ModalWindow } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

interface SavedContentArchiveModalProps {
  args: { onSubmit: () => void }
  modalWindow: ModalWindow
}

const SavedContentArchive = ({
  args: { onSubmit },
  modalWindow
}: SavedContentArchiveModalProps) => {
  const dispatch = useAppDispatch()

  const handleArchive = () => {
    onSubmit()
    dispatch(closeModal())
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Archive Saved Content" />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        Are you sure you want to archive this saved content? It will be hidden
        elsewhere in the application.
      </TextBody>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" size="medium" onClick={() => handleArchive()}>
          Archive
        </Button>
      </ModalFooter>
    </>
  )
}

export default SavedContentArchive
