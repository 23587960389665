import styled from '@emotion/styled'
import { Cart, CartWithErrors } from '@open-tender/types'

export const InvalidItemsView = styled.div`
  margin: 0 0 3rem;

  > p {
    font-size: ${props => props.theme.sizes.small};
  }

  ul {
    list-style: disc inside;
    margin: 1.5rem 0 0;

    li {
      margin: 1rem 0;

      span:first-of-type {
        position: relative;
        left: -0.5rem;
        color: ${props => props.theme.colors.text.primary};
      }

      span + span {
        font-size: ${props => props.theme.sizes.small};
      }
    }
  }
`

const InvalidItems = ({
  missingItems,
  invalidItems
}: {
  missingItems: Cart
  invalidItems: CartWithErrors
}) => {
  const unavailable = [...missingItems, ...invalidItems]
  return unavailable.length ? (
    <InvalidItemsView>
      <p>The following items will need to be removed from your cart:</p>
      <ul>
        {missingItems.map((item, index) => {
          return (
            <li key={`${item.id}-${index}`}>
              <span>{item.name}</span>
            </li>
          )
        })}
        {invalidItems.map((item, index) => {
          const missingOptions =
            item.missingOptions && item.missingOptions.length
              ? item.missingOptions.map(option => option.name).join(', ')
              : null
          return (
            <li key={`${item.id}-${index}`}>
              <span>{item.name}</span>
              {missingOptions ? (
                <span> (unavailable modifiers: {missingOptions})</span>
              ) : null}
            </li>
          )
        })}
      </ul>
    </InvalidItemsView>
  ) : null
}

export default InvalidItems
