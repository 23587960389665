import styled from '@emotion/styled'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const ErrMsgView = styled.span`
  display: block;
  width: 100%;
  margin: 0 0 1.6rem;
  padding: ${props => props.theme.messages.padding};
  border-radius: ${props => props.theme.messages.borderRadius};
  line-height: ${props => props.theme.messages.lineHeight};
  color: ${props => props.theme.colors.error.color};
  background-color: ${props => props.theme.colors.error.background};
  border-color: ${props => props.theme.colors.error.border};
`

interface ErrMsgListProps {
  errMessages: string[]
}

const ErrMsgList = ({ errMessages }: ErrMsgListProps) => {
  return (
    <TransitionGroup component={null}>
      {errMessages.map((errMsg, index) => (
        <CSSTransition
          key={`${errMsg}-${index}`}
          classNames="fade-up"
          timeout={{ enter: 250, exit: 250 }}
        >
          <ErrMsgView>
            <p>{errMsg}</p>
          </ErrMsgView>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default ErrMsgList
