import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import ClipLoader from 'react-spinners/ClipLoader'

interface FileProgressProps {
  progPercent: number
  iconSize?: number
}

const FileProgressView = styled.div<FileProgressProps>`
  position: absolute;
  z-index: 3;
  width: ${props => `${props.progPercent + '%'}` || 0};
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background-color: ${props => props.theme.overlay};
  background-color: ${props => props.theme.colors.background.secondary};
  transition: all 0.15s ease;

  span:not(.dz-icon) {
    line-height: 1;
    padding-bottom: 0.2rem;
  }
`

interface FileProgressIconProps {
  iconSize: number
}

const FileProgressIcon = styled.span<FileProgressIconProps>`
  width: ${props => (props.iconSize / 10).toFixed(2)}rem;
  height: ${props => (props.iconSize / 10).toFixed(2)}rem;
  margin: 0 0 ${props => (props.iconSize / 30).toFixed(2)}rem;
`

const FileProgressText = styled(TextBody)`
  margin-left: 0.8rem;
`

const FileProgress = ({ progPercent, iconSize = 32 }: FileProgressProps) => {
  const theme = useTheme()

  return (
    <FileProgressView progPercent={progPercent}>
      <FileProgressIcon iconSize={iconSize}>
        <ClipLoader color={theme.colors.text.primary} size={iconSize} />
      </FileProgressIcon>
      <FileProgressText size={iconSize < 32 ? 'xsmall' : 'small'}>
        Processing...
      </FileProgressText>
    </FileProgressView>
  )
}

export default FileProgress
