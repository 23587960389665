import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { WaitTimesDelivery } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface WaitTimesDeliverysAPI {
  data: WaitTimesDelivery
  links: {
    next: string
  }
}

export interface WaitTimesDeliverysState {
  data: WaitTimesDelivery
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: WaitTimesDeliverysState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchWaitTimesDelivery = createAsyncThunk<
  WaitTimesDeliverysAPI,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimesDelivery/fetchWaitTimesDelivery',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      return (await api?.request(
        'delivery-wait-times'
      )) as WaitTimesDeliverysAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const waitTimesDeliverySlice = createSlice({
  name: 'waitTimesDelivery',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWaitTimesDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchWaitTimesDelivery.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchWaitTimesDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectWaitTimesDelivery = (state: RootState) =>
  state.waitTimesDelivery

export default waitTimesDeliverySlice.reducer
