import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus } from 'utils'
import { Creds, FormFieldType } from 'types'
import { authToken, selectAuth } from 'slices/auth'
import { closeModal } from 'slices/modal'
import { refreshAuthUser, selectAuthUser } from 'slices/authUser'
import { ErrMsg, Form } from 'components'
import { ModalHeader } from 'components/Modal'

const Login = () => {
  const dispatch = useAppDispatch()
  const [didSubmit, setDidSubmit] = useState(false)
  const { token, loading, error } = useAppSelector(selectAuth)
  const { brand } = useAppSelector(selectAuthUser)
  const brandId = brand?.brand_id || null
  const isLoading = loading === RequestStatus.Pending
  const success = didSubmit && !error && !isLoading
  const [initialState] = useState({ email: '', password: '' })

  const loginFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      label: 'Email',
      placeholder: 'email address',
      name: 'email',
      type: 'email',
      id: 'email',
      error: 'Please enter an email'
    },
    {
      fieldType: 'Input',
      label: 'Password',
      placeholder: 'password',
      name: 'password',
      type: 'password',
      id: 'password',
      error: 'Please enter a password'
    }
  ]

  const handleLogin = (values: Creds) => {
    dispatch(authToken(values)).then(() => setDidSubmit(true))
  }

  useEffect(() => {
    if (success && token) {
      dispatch(closeModal())
      dispatch(refreshAuthUser(token))
    }
  }, [token, brandId, success, dispatch])

  return (
    <>
      <ModalHeader
        title="Your session has expired"
        subtitle="Please reenter your email and password to pick up where you left off."
      />
      {error && <ErrMsg errMsg={error.error_description as string} />}
      <Form
        fields={loginFields}
        data={initialState}
        submit={handleLogin}
        required={['email', 'password']}
        isLoading={isLoading}
      />
    </>
  )
}

export default Login
