import { ThemeFontSizes } from 'types'
import CardRow from './CardRow'
import { CardRowsView } from './Card.styled'

export type CardDataItem = (string | number | boolean | React.ReactNode)[]

const CardRows = ({
  data,
  subRows,
  size = 'small'
}: {
  data: CardDataItem[]
  subRows?: CardDataItem[][]
  size?: keyof ThemeFontSizes
}) => {
  return (
    <CardRowsView>
      {data.map((item, index) => (
        <CardRow
          key={index}
          rowData={item}
          size={size}
          subRows={subRows && subRows[index]}
        />
      ))}
    </CardRowsView>
  )
}

export default CardRows
