import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { ReceiptNote } from 'types'

export interface ReceiptNoteState {
  data: ReceiptNote | null
  loading: RequestStatus
  error: RequestError
}

const initialState: ReceiptNoteState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export interface ReceiptNoteReq {
  receipt_id: number
  note: string
}

export const addReceiptNote = createAsyncThunk<
  ReceiptNote,
  ReceiptNoteReq,
  { state: RootState; rejectValue: RequestError }
>(
  'receiptNote/addReceiptNote',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `receipt-notes`
      const resp = (await api?.request(endpoint, 'POST', data)) as ReceiptNote
      dispatch(showNotification('Order Note added successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateReceiptNote = createAsyncThunk<
  ReceiptNote,
  { data: ReceiptNoteReq; receiptNoteId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'receiptNote/updateReceiptNote',
  async ({ data, receiptNoteId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `receipt-notes/${receiptNoteId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as ReceiptNote
      dispatch(showNotification('Order Note updated successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchReceiptNote = createAsyncThunk<
  ReceiptNote,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'receiptNote/fetchReceiptNote',
  async (receiptNoteId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `receipt-notes/${receiptNoteId}`
      const resp = (await api?.request(endpoint)) as ReceiptNote
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteReceiptNote = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'receiptNote/deleteReceiptNote',
  async (receiptNoteId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `receipt-notes/${receiptNoteId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Order note removed.'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const receiptNoteSlice = createSlice({
  name: 'receiptNote',
  initialState,
  reducers: {
    resetReceiptNote: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addReceiptNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addReceiptNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addReceiptNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateReceiptNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateReceiptNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateReceiptNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchReceiptNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchReceiptNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchReceiptNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteReceiptNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteReceiptNote.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteReceiptNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetReceiptNote } = receiptNoteSlice.actions

export const selectReceiptNote = (state: RootState) => state.receiptNote

export default receiptNoteSlice.reducer
