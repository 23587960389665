import { Form } from 'components'
import { FormFieldType, ModalWindow, SavedContent } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

export interface SavedTextSnippetSettingsProps {
  readOnly?: boolean
  value: Pick<SavedContent, 'title' | 'subject' | 'preview_text'>
  onSubmit: (
    value: Pick<SavedContent, 'title' | 'subject' | 'preview_text'>
  ) => void
  modalWindow: ModalWindow
}

const SavedTextSnippetSettings = ({
  args: { readOnly = false, value, onSubmit }
}: {
  args: SavedTextSnippetSettingsProps
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()

  const submit = (
    value: Pick<SavedContent, 'title' | 'subject' | 'preview_text'>
  ) => {
    if (!readOnly) onSubmit(value)
    dispatch(closeModal())
  }

  const formFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      name: 'subject',
      label: 'Display Name',
      placeholder: value.title,
      disabled: readOnly
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'preview_text',
      label: 'Preview Text',
      placeholder: value.subject || value.title,
      disabled: readOnly
    }
  ]

  return (
    <>
      <ModalHeader title="Merge Tag Settings" />
      <Form
        fields={formFields}
        data={value}
        isLoading={false}
        submit={submit}
        submitText={readOnly ? 'Close' : 'Submit'}
      />
    </>
  )
}

export default SavedTextSnippetSettings
