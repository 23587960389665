import styled from '@emotion/styled'
import { Calendar, Clock, XmarkCircle } from 'iconoir-react'

export const InputView = styled.input<{
  disabled?: boolean
  readonly?: boolean
  hideIcon?: boolean
  nullable?: boolean
}>`
  width: 100%;
  outline: none;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-radius: ${props => props.theme.inputs.borderRadius};
  padding: ${props => props.theme.inputs.padding};
  font-size: ${props => props.theme.inputs.fontSize};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  line-height: ${props => props.theme.inputs.lineHeight};
  transition: ${props => props.theme.transition};

  background-color: ${props => props.theme.inputs.backgroundColor};

  color: ${props => props.theme.colors.text.primary};
  border-color: ${props => props.theme.colors.border.primary};

  &:active,
  &:focus {
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.text.primary};
  }

  &:disabled,
  &:read-only {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.colors.text.primary};
  }

  &::placeholder {
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.border.primary};
    opacity: 0.75;
  }

  &::selection {
    color: ${props => props.theme.colors.background.primary};
    background-color: ${props => props.theme.colors.text.primary};
  }

  &[type='date'],
  &[type='time'],
  &[type='datetime-local'],
  &[type='month'],
  &[type='week'] {
    ::-webkit-calendar-picker-indicator {
      display: none;
    }
    margin-right: ${props => {
      const showIcons = !props.disabled && !props.readonly && !props.hideIcon
      const iconCount = props.nullable ? 2 : 1
      return showIcons ? iconCount * 2.2 + 'rem' : '0'
    }};
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }
`

export const InputColorContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0;
`

export const InputColorView = styled.span`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex: 0 0 100%;

  input[type='text'] {
    flex: 1 1 auto;
    padding-left: 6rem;
  }
`

export const InputColorWrapper = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  aspect-ratio: 1;
  overflow: hidden;
  border-top-left-radius: ${props => props.theme.inputs.borderRadius};
  border-bottom-left-radius: ${props => props.theme.inputs.borderRadius};
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
`

export const InputColorHash = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4.6rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.sizes.medium};
  opacity: 0.5;
`

export const InputColorInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
`

export const InputContainer = styled.span`
  display: block;
  width: 100%;
  position: relative;
  font-size: ${props => props.theme.inputs.fontSize};
`

export const InputIconContainer = styled.span`
  position: absolute;
  top: 0;
  right: 1.2rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const InputDeleteIcon = styled(XmarkCircle)`
  cursor: pointer;
  color: ${props => props.theme.inputs.color};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`

export const InputCalendarIcon = styled(Calendar)`
  cursor: pointer;
  color: ${props => props.theme.inputs.color};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`

export const InputClockIcon = styled(Clock)`
  cursor: pointer;
  color: ${props => props.theme.inputs.color};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`

export const InputRangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & label {
    margin: 0;
  }
`
export const InputRangeSliderView = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: calc(100% - 200px);
`

export const InputRangeInput = styled.input`
  flex: 0 0 10rem;
  min-width: 100%;
  height: 0.2rem;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-color: ${props => props.theme.colors.border.primary};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.accent.secondary};
  }

  &::-moz-range-thumb {
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.accent.secondary};
  }
`

export const InputRangeView = styled.div`
  display: flex;
  flex-direction: row;
`
