import { TenderStatus as TenderStatusType } from 'types'
import { getTenderStatusColor } from 'utils'
import Badge from './Badge'

const TenderStatus = ({
  tenderStatus
}: {
  tenderStatus?: TenderStatusType | null
}) => {
  const receiptStatusColor = getTenderStatusColor(tenderStatus)
  return tenderStatus ? (
    <Badge color={receiptStatusColor}>{tenderStatus}</Badge>
  ) : (
    <Badge color="default">NONE</Badge>
  )
}

export default TenderStatus
