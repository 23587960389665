import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CheckmateMenus } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface CheckmateState {
  menus: CheckmateMenus
  updates: any
  loading: RequestStatus
  error: RequestError
}

const initialState: CheckmateState = {
  menus: [],
  updates: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCheckmateMenus = createAsyncThunk<
  any[],
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'checkmate/fetchCheckmateMenus',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `integrations/checkmate/${storeId}/menus`
      return (await api?.request(endpoint)) as any[]
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCheckmateMenus = createAsyncThunk<
  void,
  { storeId: number | string; revenueCenterIds: number[] },
  { state: RootState; rejectValue: RequestError }
>(
  'checkmate/updateCheckmateMenus',
  async (
    { storeId, revenueCenterIds },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `integrations/checkmate/${storeId}/update-menus`
      const resp = await api?.request<ReadableStream<Uint8Array>>(
        endpoint,
        'POST',
        revenueCenterIds
      )
      if (!resp) return
      // const reader = resp.getReader()
      const reader = resp.pipeThrough(new TextDecoderStream()).getReader()
      while (true) {
        const { done } = await reader.read()
        if (done) break
        // console.log('Received', value)
      }
      dispatch(showNotification('Menus successfully updated!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const checkmateSlice = createSlice({
  name: 'checkmate',
  initialState,
  reducers: {
    resetCheckmate: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCheckmateMenus.pending, state => {
      state.menus = []
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCheckmateMenus.fulfilled, (state, { payload }) => {
      state.menus = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchCheckmateMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCheckmateMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCheckmateMenus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateCheckmateMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCheckmate } = checkmateSlice.actions

export const selectCheckmate = (state: RootState) => state.checkmate
export const selectCheckmateMenus = (state: RootState) => {
  const { menus, loading, error } = state.checkmate
  return { menus, loading, error }
}
export const selectCheckmateUpdates = (state: RootState) => {
  const { updates, loading, error } = state.checkmate
  return { updates, loading, error }
}

export default checkmateSlice.reducer
