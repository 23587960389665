import styled from '@emotion/styled'

export const OnboardingStepView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  flex-wrap: wrap;
  min-height: 36rem;
`

export const OnboardingStepContent = styled.div`
  flex: 1 1 48rem;

  p {
    margin: 1em 0;
    // font-size: ${props => props.theme.sizes.small};
    line-height: ${props => props.theme.layout.desktop.lineHeight};
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      line-height: ${props => props.theme.layout.mobile.lineHeight};
    }
  }

  ul {
    margin: 1rem 0 0;
    list-style: disc inside;
  }

  li {
    margin: 0.5em 0;
    font-size: ${props => props.theme.sizes.small};
  }
`

export const OnboardingStepVideo = styled.iframe`
  flex: 0 0 560px;
  width: 560px;
  height: 315px;
  border: 0;
`
