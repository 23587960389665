import styled from '@emotion/styled'
import { ThemeFontSizes } from 'types'
import Container from './Container'
import TextHeading from './TextHeading'

const ListTitleView = styled.div`
  margin: ${props => props.theme.layout.desktop.padding} 0 0;
  margin: 4.5rem 0 0;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: ${props => props.theme.layout.mobile.padding} 0 0;
  }
`

const ListTitleText = styled(TextHeading)`
  margin-left: -0.1rem;
`

interface ListTitleProps {
  children: React.ReactNode
  size?: keyof ThemeFontSizes
  style?: object
}

const ListTitle = ({ children, size = 'mega', style = {} }: ListTitleProps) => {
  return (
    <ListTitleView style={style}>
      <Container>
        <ListTitleText as="h1" size={size}>
          {children}
        </ListTitleText>
      </Container>
    </ListTitleView>
  )
}

export default ListTitle
