import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import {
  createItemAllergens,
  fetchItemAllergens,
  selectItemAllergens
} from 'slices/itemAllergens'
import { selectAllergens } from 'slices/allergens'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'

const ItemAllergen = ({
  args,
  modalWindow
}: {
  args: { itemId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { itemId } = args
  const { data, loading, error } = useAppSelector(selectItemAllergens)
  const allergens = useAppSelector(selectAllergens)
  const current = data.map(i => i.allergen_id)
  const filtered = allergens.data.filter(i => !current.includes(i.allergen_id))
  const isLoading =
    loading === RequestStatus.Pending ||
    allergens.loading === RequestStatus.Pending

  const allergenOptions = useMemo(() => {
    return filtered.map(i => {
      return {
        value: i.allergen_id,
        text: `${i.name}`
      }
    })
  }, [filtered])

  const submit = async (values: { allergen_id: number }) => {
    if (itemId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(createItemAllergens({ itemId, data }))
      dispatch(fetchItemAllergens(itemId))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add New Allergen"
        subtitle="Choose a allergen from the options below"
      />
      <Form
        fields={itemAllergenFields(allergenOptions)}
        data={itemAllergenEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const itemAllergenEmpty = {
  allergen_id: null
}

const itemAllergenFields = (
  allergenOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'allergen_id',
    options: allergenOptions,
    label: 'Allergen',
    error: 'Please select a allergen'
  }
]

export default ItemAllergen
