import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Store } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface StoresState {
  data: Array<Store>
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface StoresAPI {
  data: Array<Store>
  links: {
    next: string
  }
}

const initialState: StoresState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type StoreParams = {
  closed?: string
  expand?: string
  limit?: number
  store_group_id?: number
  with_related?: string
  with_settings?: string
}

export const fetchStores = createAsyncThunk<
  StoresAPI,
  StoreParams | void,
  { state: RootState; rejectValue: RequestError }
>('stores/fetchStores', async (params, { getState, rejectWithValue }) => {
  try {
    const withMaster = { ...params, is_master: false, limit: 1000 }
    const queryParams = makeQueryParams(withMaster)
    const endpoint = `stores${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as StoresAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStores.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStores.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchStores.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStores = (state: RootState) => state.stores
export const selectStoresData = (state: RootState) => state.stores.data
export const selectStoresLinks = (state: RootState) => state.stores.links
export const selectLoading = (state: RootState) => state.stores.loading

export const selectStoresOptions = createSelector(
  (state: RootState) => {
    return state.stores.data
  },
  stores => {
    return (stores?.map(
      ({ store_id, full_name }: { store_id: number; full_name: string }) => ({
        text: full_name,
        value: store_id
      })
    ) || []) as { text: string; value: number }[]
  }
)

export default storesSlice.reducer
