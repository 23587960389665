import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { HouseAccountRevenueCenters } from 'types'

export interface HouseAccountRevenueCentersState {
  data: HouseAccountRevenueCenters
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountRevenueCentersState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchHouseAccountRevenueCenters = createAsyncThunk<
  HouseAccountRevenueCenters,
  {
    houseAccountId: string
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountRevenueCenters/fetchHouseAccountRevenueCenters',
  async ({ houseAccountId }, { getState, rejectWithValue }) => {
    try {
      const endpoint = `house-accounts/${houseAccountId}/revenue-centers`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as HouseAccountRevenueCenters
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateHouseAccountRevenueCenters = createAsyncThunk<
  HouseAccountRevenueCenters,
  {
    houseAccountId: string
    data: HouseAccountRevenueCenters
  },
  { state: RootState; rejectValue: RequestError }
>(
  'disocuntRevenueCenters/updateHouseAccountRevenueCenters',
  async ({ houseAccountId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const endpoint = `house-accounts/${houseAccountId}/revenue-centers`
      const api = getState().authUser.api
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as HouseAccountRevenueCenters
      dispatch(showNotification('Revenue centers successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountRevenueCentersSlice = createSlice({
  name: 'houseAccountRevenueCenters',
  initialState,
  reducers: {
    resetHouseAccountRevenueCenters: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchHouseAccountRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchHouseAccountRevenueCenters.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchHouseAccountRevenueCenters.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateHouseAccountRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateHouseAccountRevenueCenters.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      updateHouseAccountRevenueCenters.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetHouseAccountRevenueCenters } =
  houseAccountRevenueCentersSlice.actions

export const selectHouseAccountRevenueCenters = (state: RootState) =>
  state.houseAccountRevenueCenters

export default houseAccountRevenueCentersSlice.reducer
