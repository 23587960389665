import styled from '@emotion/styled'
import { Button } from 'components'
import { ThemeButtonColors, ThemeButtonSizes } from 'types'
import { capitalize } from 'utils'

const ButtonToggleWrapper = styled.div`
  & > button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0.1rem;
  }
`

const ButtonToggle = <T extends string>({
  options,
  current,
  size = 'small',
  color = 'primary',
  onToggle,
  style
}: {
  options: T[]
  current: T
  color?: keyof ThemeButtonColors
  size?: keyof ThemeButtonSizes
  onToggle: (current: T) => void
  style?: React.CSSProperties
}) => {
  return (
    <ButtonToggleWrapper style={style}>
      {options.map(option => (
        <Button
          key={option}
          size={size}
          color={color}
          disabled={option === current}
          onClick={() => onToggle(option)}
        >
          {capitalize(option)}
        </Button>
      ))}
    </ButtonToggleWrapper>
  )
}

export default ButtonToggle
