import styled from '@emotion/styled'

const Container = styled.div`
  label: Container;
  padding: 0 ${props => props.theme.layout.desktop.padding};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 0 ${props => props.theme.layout.mobile.padding};
  }
`

export default Container
