import React from 'react'

const Campaign = React.lazy(() => import('./Campaigns/Campaign'))
const CampaignOutlet = React.lazy(() => import('./Campaigns/CampaignOutlet'))
const Campaigns = React.lazy(() => import('./Campaigns/Campaigns'))

const SegmentOutlet = React.lazy(() => import('./Segments/SegmentOutlet'))
const Segment = React.lazy(() => import('./Segments/Segment'))

const Marketing = React.lazy(() => import('./Marketing'))
const MarketingRoutes = React.lazy(() => import('./MarketingRoutes'))
const MarketingUpsellMailTo = React.lazy(
  () => import('./MarketingUpsellMailTo')
)

export {
  Campaign,
  CampaignOutlet,
  Campaigns,
  SegmentOutlet,
  Segment,
  Marketing,
  MarketingRoutes,
  MarketingUpsellMailTo
}
