import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Oauth2Client } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface Oauth2ClientsState {
  data: Array<Oauth2Client>
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface Oauth2ClientsAPI {
  data: Array<Oauth2Client>
  links: {
    next: string
  }
}

const initialState: Oauth2ClientsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchOauth2Clients = createAsyncThunk<
  Oauth2ClientsAPI,
  void,
  { state: RootState; rejectValue: RequestError }
>('stores/fetchOauth2Clients', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request(`oauth2-clients`)) as Oauth2ClientsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const oauth2ClientsSlice = createSlice({
  name: 'oauth2Clients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchOauth2Clients.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOauth2Clients.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOauth2Clients.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

// selectors
export const selectOauth2Clients = (state: RootState) => state.oauth2Clients
export const selectOauth2ClientsData = (state: RootState) =>
  state.oauth2Clients.data
export const selectOauth2ClientsLinks = (state: RootState) =>
  state.oauth2Clients.links

export default oauth2ClientsSlice.reducer
