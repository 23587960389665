import { gql } from 'graphql-request'

export const ONBOARDING_COLLABORATOR_FRAGMENT = gql`
  fragment OnboardingCollaboratorFragment on Collaborator {
    id
    email
    fullName
    imageUrl
    role
    active
  }
`

export const ONBOARDING_BRAND_STATE_FRAGMENT = gql`
  fragment OnboardingBrandStateFragment on OnboardingBrandState {
    id
    brandId
    status
    dueDate
    assignedTo
    assignedId
    eventData
    eventAttributes
    completeTask
    completePercent
  }
`

export const ONBOARDING_BRAND_FRAGMENT = gql`
  fragment OnboardingBrandFragment on OnboardingBrand {
    id
    brandId
    userId
    name
    status
    brandType
    locationsInOnboardingCount
    locationsInLiveCount
    featureStates
    creator {
      ...OnboardingCollaboratorFragment
    }
    createdAt
    collaborators {
      ...OnboardingCollaboratorFragment
    }
    locationsCount
    accessVia
    state {
      ...OnboardingBrandStateFragment
    }
    enterpriseLevel {
      id
      name
    }
    newestAttachment {
      id
      name
      url
      uploadedAt
    }
    parentBrand {
      id
      name
    }
  }
  ${ONBOARDING_BRAND_STATE_FRAGMENT}
  ${ONBOARDING_COLLABORATOR_FRAGMENT}
`
