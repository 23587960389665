import styled from '@emotion/styled'
import { ErrMsgView } from './ErrMsg'
import Transition from './Transition'

export const SuccessMsgView = styled(ErrMsgView)`
  color: ${props => props.theme.colors.success.color};
  background-color: ${props => props.theme.colors.success.background};
  border-color: ${props => props.theme.colors.success.border};
`

const SuccessMsg = ({ successMsg }: { successMsg?: string | null }) => {
  return (
    <Transition
      showTransition={!!successMsg}
      classNames="reveal"
      transitionElements={[
        <SuccessMsgView>
          <p>{successMsg}</p>
        </SuccessMsgView>
      ]}
    />
  )
}

export default SuccessMsg
