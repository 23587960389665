import styled from '@emotion/styled'
import { ThemeBadgeColors } from 'types'

interface BadgeViewProps {
  color: keyof ThemeBadgeColors
}

export const BadgeView = styled.span<BadgeViewProps>`
  display: inline-block;
  padding: 0.4rem 0.7rem 0.4rem;
  margin: -0.4rem 0 -0.4rem;
  line-height: 1;
  font-size: ${props => props.theme.sizes.small};
  background-color: ${props => props.theme.badges[props.color].background};
  color: ${props => props.theme.badges[props.color].text} !important;
  border-radius: ${props => props.theme.borderRadius.large};
`

const Badge = ({
  children,
  color = 'default',
  style
}: {
  children: React.ReactNode
  color?: keyof ThemeBadgeColors
  style?: React.CSSProperties
}) => {
  return (
    <BadgeView color={color} style={style}>
      {children}
    </BadgeView>
  )
}

export default Badge
