import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BlockedPhone } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { fetchBlockedPhones } from './blockedPhones'

export interface BlockedPhoneState {
  data: BlockedPhone | null
  loading: RequestStatus
  error: RequestError
}

const initialState: BlockedPhoneState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBlockedPhone = createAsyncThunk<
  BlockedPhone,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedPhones/fetchBlockedPhone',
  async (blockedPhoneId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-phones/${blockedPhoneId}`
      return (await api?.request(endpoint)) as BlockedPhone
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createBlockedPhone = createAsyncThunk<
  BlockedPhone,
  BlockedPhone,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedPhones/createBlockedPhone',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-phones`
      const resp = (await api?.request(endpoint, 'POST', data)) as BlockedPhone
      dispatch(showNotification('Phone number successfully blocked!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const unblockPhone = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedPhones/unblockPhone',
  async (blockedPhoneId, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-phones/${blockedPhoneId}`
      await api?.request(endpoint, 'DELETE', null)
      dispatch(showNotification('Phone number successfully unblocked!'))
      dispatch(fetchBlockedPhones())
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const blockedPhoneSlice = createSlice({
  name: 'blockedPhone',
  initialState,
  reducers: {
    resetBlockedPhone: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBlockedPhone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBlockedPhone.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchBlockedPhone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createBlockedPhone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createBlockedPhone.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(createBlockedPhone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(unblockPhone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(unblockPhone.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(unblockPhone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBlockedPhone } = blockedPhoneSlice.actions

export const selectBlockedPhone = (state: RootState) => state.blockedPhone

export default blockedPhoneSlice.reducer
