import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { UserCount } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface CcpaCustomersState {
  count: number | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CcpaCustomersState = {
  count: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCcpaCustomers = createAsyncThunk<
  number,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'ccpaCustomers/fetchCcpaCustomers',
  async (_, { getState, rejectWithValue }) => {
    try {
      const endpoint = 'super/ccpa-customers'
      const api = getState().authUser.api
      const resp = (await api?.request(endpoint)) as UserCount
      return resp.count
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const ccpaCustomersSlice = createSlice({
  name: 'ccpaCustomers',
  initialState,
  reducers: {
    resetCcpaCustomers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCcpaCustomers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCcpaCustomers.fulfilled, (state, { payload }) => {
      state.count = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchCcpaCustomers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCcpaCustomers } = ccpaCustomersSlice.actions

export const selectCcpaCustomers = (state: RootState) => state.ccpaCustomers

export default ccpaCustomersSlice.reducer
