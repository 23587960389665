import { CheckboxInterface } from 'types'
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxText,
  CheckboxView
} from './Checkbox.styled'

const Checkbox = ({
  label,
  name,
  checked,
  onChange,
  disabled,
  className
}: CheckboxInterface) => {
  return (
    <CheckboxLabel className={className} htmlFor={name}>
      <CheckboxInput
        type="checkbox"
        id={name}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <CheckboxView />
      {label && <CheckboxText disabled={disabled}>{label}</CheckboxText>}
    </CheckboxLabel>
  )
}

export default Checkbox
