export type ConfigKeys =
  | 'activationUrl'
  | 'authUrl'
  | 'apiUrl'
  | 'clientId'
  | 'tinymceApiKey'
  | 'googleMapsApiKey'
  | 'beeFreeUrl'
  | 'beeFreeEmailClientId'
  | 'beeFreeEmailSecret'
  | 'beeFreePageClientId'
  | 'beeFreePageSecret'

const configs: Record<ConfigKeys, string> = {
  activationUrl:
    process.env.REACT_APP_ACTIVATION_URL || 'https://mm.opentender.io',
  authUrl:
    process.env.REACT_APP_AUTH_URL || 'https://api.sandbox.opentender.io',
  apiUrl:
    process.env.REACT_APP_API_URL ||
    'https://api.sandbox.opentender.io/admin-api',
  clientId:
    process.env.REACT_APP_CLIENT_ID ||
    '2xIPfnGGMdmVAgf6VOgsH4dyAomMeccuDWpImREJAkI0GBxn',
  tinymceApiKey:
    process.env.REACT_APP_TINY_MCE_API_KEY ||
    'cnks4gyrbqcpwo8utcz83v7k4f84hm95nv6m8rwriwhe7ny4',
  googleMapsApiKey:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyCkllc7M-cYNzSRXO7KE-ZZKTPW59RroDk',
  beeFreeUrl:
    process.env.REACT_APP_BEE_PLUGIN_URL ||
    'https://app-rsrc.getbee.io/plugin/BeePlugin.js?uid=',
  beeFreeEmailClientId:
    process.env.REACT_APP_BEE_EMAIL_CLIENT_ID ||
    '6b5676a3-1b4d-4ab3-aae1-036a7e8be300',
  beeFreeEmailSecret:
    process.env.REACT_APP_BEE_EMAIL_SECRET ||
    'r9bcwLyP0LI9l58H11zoVBDDM4fezRTKU9K2L0hKVdu5plAeXSQ8',
  beeFreePageClientId:
    process.env.REACT_APP_BEE_WEB_CLIENT_ID ||
    '1f334597-3cff-47fa-bef1-507be8a5f5f8',
  beeFreePageSecret:
    process.env.REACT_APP_BEE_WEB_SECRET ||
    'ETJUGF0FrdmGA7ouO4G8slCg2PiO1Qd6RypPc86h99LssiS7UJMM'
}

export default configs
