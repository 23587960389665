import styled from '@emotion/styled'
import TextHeading from 'components/TextHeading'

export const MenuItemHeader = styled.div`
  margin: 0 0 ${props => props.theme.layout.desktop.gap};

  span {
    display: block;
  }

  span + span {
    margin: 0.7rem 0 0;
    line-height: 1.3;
  }
`

export const MenuItemGroups = styled.div``

export const MenuItemGroup = styled.div`
  margin: 0 0 ${props => props.theme.layout.desktop.gap};
`

export const MenuItemGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 0 0.7rem;
  border-style: solid;
  border-width: 0;
  border-bottom-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  margin: 0 0 1.2rem;
`

export const MenuItemGroupName = styled(TextHeading)`
  display: block;
  flex: 0 0 auto;
`

export const MenuItemGroupCounts = styled.span`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`

export const MenuItemInput = styled.div`
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1.5rem;
  }

  input {
    padding: 0.8rem 1rem;
    font-size: ${props => props.theme.sizes.small};
  }

  label span:first-of-type {
    flex: 0 0 8rem;
  }
`

export const MenuItemIncomplete = styled.div`
  margin-bottom: 2rem;
`
