import { TextAreaInterface } from 'types'
import Label from '../Label/Label'
import { TextAreaView } from './TextArea.styled'

const TextArea = ({
  label,
  parenthetical,
  tooltip,
  name,
  value,
  onChange,
  onBlur,
  error,
  required,
  disabled
}: TextAreaInterface) => {
  return (
    <Label
      htmlFor={name}
      label={label}
      parenthetical={parenthetical}
      tooltip={tooltip}
      required={required}
      errMsg={error}
    >
      <TextAreaView
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />
    </Label>
  )
}

export default TextArea
