import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BulkOrder, ModalWindow, PrepStatus } from 'types'
import { RequestStatus } from 'utils'
import { prepStatusOptions } from 'config'
import { ButtonList } from 'components'
import { ModalCancel, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import {
  fetchOrderMgmt,
  selectOrderMgmt,
  updatePrepStatus
} from 'slices/orderMgmt'

export interface OrderPrepStatusArgs {
  order: BulkOrder
  storeId: string
}

const OrderPrepStatus = ({
  args,
  modalWindow
}: {
  args: OrderPrepStatusArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { order, storeId } = args
  const { receipt_id: orderId, prep_status } = order
  const preface = orderId ? `Order #${orderId}` : null
  const { loading, error } = useAppSelector(selectOrderMgmt)
  const [submitted, setSubmitted] = useState(false)
  const isLoading = loading === RequestStatus.Pending

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      dispatch(fetchOrderMgmt({ store_id: storeId }))
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error, storeId])

  const change = (prepStatus: PrepStatus) => {
    setSubmitted(true)
    dispatch(updatePrepStatus({ orderId, prepStatus }))
  }

  const buttons = prepStatusOptions.map(i => ({
    title: i.text,
    onClick: () => change(i.value as PrepStatus),
    disabled: i.value === prep_status || isLoading
  }))

  return (
    <>
      <ModalHeader preface={preface} title="Update prep status" />
      <ButtonList buttons={buttons} />
      <ModalCancel>Nevermind, keep current prep status</ModalCancel>
    </>
  )
}

export default OrderPrepStatus
