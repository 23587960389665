import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { StorePosSettings, StoreSettingsFrom } from 'types'

export interface RevenueCenterPosSettingsState {
  data: StorePosSettings | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError | null
}

export interface RevenueCenterPosSettingsApi {
  data: StorePosSettings
  from: StoreSettingsFrom
}

const initialState: RevenueCenterPosSettingsState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRevenueCenterPosSettings = createAsyncThunk<
  RevenueCenterPosSettingsApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterPosSettings/fetchRevenueCenterPosSettings',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `revenue-centers/${revenueCenterId}/settings/pos`
      )) as RevenueCenterPosSettingsApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertRevenueCenterPosSettings = createAsyncThunk<
  RevenueCenterPosSettingsApi,
  { revenueCenterId: string | number; data: StorePosSettings },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterPosSettings/upsertRevenueCenterPosSettings',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `revenue-centers/${revenueCenterId}/settings/pos`,
        'PUT',
        data
      )) as RevenueCenterPosSettingsApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterPosSettings = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterPosSettings/deleteRevenueCenterPosSettings',
  async (revenueCenterId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request<void>(
        `revenue-centers/${revenueCenterId}/settings/pos`,
        'DELETE'
      )
      dispatch(showNotification('Successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterPosSettingsSlice = createSlice({
  name: 'revenueCenterPosSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterPosSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchRevenueCenterPosSettings.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload?.data || null
        state.from = payload?.from || null
        state.error = null
      }
    )
    builder.addCase(
      fetchRevenueCenterPosSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(upsertRevenueCenterPosSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      upsertRevenueCenterPosSettings.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload.data
        state.from = payload.from
        state.error = null
      }
    )
    builder.addCase(
      upsertRevenueCenterPosSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteRevenueCenterPosSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterPosSettings.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(
      deleteRevenueCenterPosSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const selectRevenueCenterPosSettings = (state: RootState) =>
  state.revenueCenterPosSettings

export default revenueCenterPosSettingsSlice.reducer
