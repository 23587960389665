import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreBrief } from 'types'
import { fetchStores } from 'slices/stores'
import { showNotification } from 'slices/notification'

export interface StoreState {
  loading: RequestStatus
  data: StoreBrief | null
  error: RequestError
}

const initialState: StoreState = {
  loading: RequestStatus.Idle,
  data: null,
  error: null
}

export const fetchStore = createAsyncThunk<
  StoreBrief | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('store/fetchStore', async (storeId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const resp = (await api?.request(`stores/${storeId}`)) as StoreBrief | null
    return resp || null
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createStore = createAsyncThunk<
  StoreBrief,
  { data: StoreBrief; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'store/createStore',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('stores', 'POST', data)) as StoreBrief
      dispatch(showNotification('Store successfully created!'))
      dispatch(fetchStores())
      navigate({
        pathname: `/stores/stores/${resp.store_id}`
      })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStore = createAsyncThunk<
  StoreBrief,
  { storeId: string | number; data: StoreBrief },
  { state: RootState; rejectValue: RequestError }
>(
  'store/upsertStore',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}`,
        'PUT',
        data
      )) as StoreBrief
      dispatch(showNotification('Store successfully updated!'))
      dispatch(fetchStores())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    resetStore: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStore.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStore.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchStore.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createStore.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createStore.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createStore.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStore.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStore.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(upsertStore.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetStore } = storeSlice.actions

export const selectStore = (state: RootState) => state.store

export default storeSlice.reducer
