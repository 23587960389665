import { Form } from 'components'
import {
  CampaignChannel,
  CampaignContentType,
  FormFieldType,
  ModalWindow
} from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { selectIsSuper } from 'slices/authUser'

export interface SavedContentDetailsProps {
  readOnly?: boolean
  value: {
    title: string
    description: string
    contentType?: CampaignContentType
    channel?: CampaignChannel
  }
  onSubmit: (title: string, description: string, is_locked?: boolean) => void
  modalWindow: ModalWindow
  modalTitle: string
}

const SavedContentDetails = ({
  args: { readOnly = false, value, onSubmit, modalTitle }
}: {
  args: SavedContentDetailsProps
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const isSuper = useAppSelector(selectIsSuper)

  const savedContentTitleFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      required: true,
      placeholder: 'Name your content',
      name: 'title',
      label: 'Name',
      disabled: readOnly
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'description',
      label: 'Description',
      disabled: readOnly
    }
  ]

  if (isSuper) {
    savedContentTitleFields.push({
      fieldType: 'Checkbox',
      name: 'is_locked',
      label: 'Locked'
    })
  }

  return (
    <>
      <ModalHeader title={modalTitle} />
      <Form
        fields={savedContentTitleFields}
        data={value}
        isLoading={false}
        submit={value => {
          if (!readOnly)
            onSubmit(value.title, value.description, value?.is_locked)
          dispatch(closeModal())
        }}
        submitText={readOnly ? 'Close' : 'Save'}
      />
    </>
  )
}

export default SavedContentDetails
