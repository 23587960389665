import { useAppDispatch, useAppSelector } from 'app/hooks'
import iacmConfigs from 'config/iacm'
import { useState } from 'react'
import { selectIacm } from 'slices/iacmUser'
import { showNotification } from 'slices/notification'
import {
  IacmCSVExportSeparator,
  IacmCSVExportView
} from './IacmCSVExport.styled'
import ButtonLink from 'components/ButtonLink'

const IacmCSVExport = ({
  endpoint,
  disabled = false,
  isLast = true
}: {
  endpoint: string
  disabled?: boolean
  isLast?: boolean
}) => {
  const dispatch = useAppDispatch()
  const [isExportLoading, setIsExportLoading] = useState(false)
  const { headers } = useAppSelector(selectIacm)

  const downloadCSV = async (email?: boolean) => {
    setIsExportLoading(!isExportLoading)
    const url = iacmConfigs.exportApiUrl + '/' + endpoint
    const params = { filters: [], emails: email, order: 'desc' }
    const requestHeaders = {
      'Access-Token': `${headers?.accessToken}`,
      Client: `${headers?.client}`,
      Uid: `${headers?.uid}`,
      Expiry: `${headers?.expiry}`
    }
    try {
      await fetch(url, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(params)
      })
      setIsExportLoading(!isExportLoading)
      dispatch(showNotification('Export successful'))
    } catch (error) {
      setIsExportLoading(!isExportLoading)
      dispatch(showNotification('An error occurred while exporting'))
    }
  }

  return (
    <IacmCSVExportView isLast={isLast}>
      <ButtonLink
        size="small"
        onClick={() => downloadCSV()}
        disabled={disabled || isExportLoading}
      >
        Download CSV
      </ButtonLink>
      <IacmCSVExportSeparator>|</IacmCSVExportSeparator>
      <ButtonLink
        size="small"
        onClick={() => downloadCSV(true)}
        disabled={disabled || isExportLoading}
      >
        Export via Email
      </ButtonLink>
    </IacmCSVExportView>
  )
}

export default IacmCSVExport
