import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { AppTheme } from 'types'
import { showNotification } from 'slices/notification'

export interface AppThemeState {
  data: AppTheme | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AppThemeState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppTheme = createAsyncThunk<
  AppTheme,
  void,
  { state: RootState; rejectValue: RequestError }
>('appTheme/fetchAppTheme', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('order-app-theme')) as AppTheme
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertAppTheme = createAsyncThunk<
  AppTheme,
  { data: AppTheme },
  { state: RootState; rejectValue: RequestError }
>(
  'appTheme/upsertAppTheme',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'order-app-theme',
        'PUT',
        data
      )) as AppTheme
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const appThemeSlice = createSlice({
  name: 'appTheme',
  initialState,
  reducers: {
    resetAppTheme: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAppTheme.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppTheme.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(fetchAppTheme.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertAppTheme.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertAppTheme.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(upsertAppTheme.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAppTheme } = appThemeSlice.actions

export const selectAppTheme = (state: RootState) => state.appTheme

export const selectAppThemeData = (state: RootState) => state.appTheme.data

export const selectAppThemeGlobal = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.global.sections, loading, error }
}

export const selectAppThemeLayout = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.global.sections.layout, loading, error }
}

export const selectAppThemeFonts = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.fonts.sections, loading, error }
}

export const selectAppThemeFontStylesheets = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  const { body, headings } = data.fonts.sections
  return {
    data: { body: body.url, headings: headings.url },
    loading,
    error
  }
}

export const selectAppThemeFontSizeMain = (state: RootState) => {
  const { data } = state.appTheme
  if (!data) return []
  return data.fonts.sections.sizes.main
}

export const selectAppThemeColors = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.colors.sections, loading, error }
}

export const selectAppThemeBackgroundColor = (state: RootState) => {
  const { data } = state.appTheme
  // if (!data) return null
  return data ? `#${data.colors.sections.bgColors.primary}` : null
}

export const selectAppThemePadding = (state: RootState) => {
  const { data } = state.appTheme
  if (!data) return { DESKTOP: '0', MOBILE: '0' }
  const { padding, paddingMobile } = data.global.sections.layout
  return { DESKTOP: `${padding}px`, MOBILE: `${paddingMobile}px` }
}

export const selectAppThemeFontUrls = (state: RootState) => {
  const { data } = state.appTheme
  if (!data) return []
  const { body, headings } = data.fonts.sections
  return [body.url, headings.url].filter(i => i)
}

export const selectPreviewSettings = createSelector(
  (state: RootState) => {
    const { data } = state.appTheme
    return data
  },
  data => {
    if (!data) return {}
    const bgColor = `#${data.colors.sections.bgColors.primary}`
    const { layout } = data.global.sections
    const padding = {
      DESKTOP: `${layout.padding}px`,
      KIOSK: `${layout.paddingMobile}px`,
      MOBILE: `${layout.paddingMobile}px`
    }
    const { body, headings } = data.fonts.sections
    const urls = [body.url, headings.url].filter(i => i)
    return { bgColor, padding, urls }
  }
)

export const selectAppThemeLinks = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.links, loading, error }
}

export const selectAppThemeButtonStyles = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.buttonSizes.sections, loading, error }
}

export const selectAppThemeButtonColors = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.buttonColors.sections, loading, error }
}

export const selectAppThemeInputs = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.inputs, loading, error }
}

export const selectAppThemeCards = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.cards.sections, loading, error }
}

export const selectAppThemeMenuItemCards = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  const sections = { cards: data.cards.sections, items: data.items.sections }
  return { data: sections, loading, error }
}

export const selectAppThemeItemBuilder = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.item.sections, loading, error }
}

export const selectAppThemeTags = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.tags.sections, loading, error }
}

export const selectAppThemeMenuItems = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.menuItems.sections, loading, error }
}

export const selectAppThemeModifiers = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.modifiers.sections, loading, error }
}

export const selectAppThemeCounts = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.counts.sections, loading, error }
}

export const selectAppThemeWelcome = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.welcome.sections, loading, error }
}

export const selectAppThemeCategories = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.categories.sections, loading, error }
}

export const selectAppThemeBottomTabs = (state: RootState) => {
  const { data, loading, error } = state.appTheme
  if (!data) return { data: null, loading, error }
  return { data: data.bottomTabs.sections, loading, error }
}

export default appThemeSlice.reducer
