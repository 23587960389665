import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { Button } from 'components'
import { resetOrder, selectOrder } from 'slices/order'
import { closeModal } from 'slices/modal'
import { useNavigate } from 'react-router-dom'

const OrderCancel = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { orderId } = useAppSelector(selectOrder)
  const title = orderId ? 'Cancel changes?' : 'Cancel order?'

  const cancel = () => {
    dispatch(resetOrder())
    dispatch(closeModal())
    if (orderId) navigate(`/orders/${orderId}`)
  }

  return (
    <>
      <ModalHeader title={title} />
      <ModalContent>
        {orderId ? (
          <p>Cancel edits? This will leave the order unchanged.</p>
        ) : (
          <p>
            Are you sure you want to cancel this order? This will delete the
            order forever. There's no going back.
          </p>
        )}
      </ModalContent>
      <ModalFooter>
        <Button color="delete" onClick={cancel}>
          {orderId ? 'Cancel Edits' : 'Cancel Order'}
        </Button>
        <Button color="secondary" onClick={() => dispatch(closeModal())}>
          Nevermind
        </Button>
      </ModalFooter>
    </>
  )
}

export default OrderCancel
