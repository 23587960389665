import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { OrderRating } from 'types'
import { showNotification } from 'slices/notification'

export interface OrderRatingState {
  data: OrderRating | null
  loading: RequestStatus
  error: RequestError
}

const initialState: OrderRatingState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchOrderRating = createAsyncThunk<
  OrderRating | null,
  void,
  { state: RootState; rejectValue: RequestError }
>('OrderRating/fetchOrderRating', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const resp = (await api?.request(`order-rating-settings`)) as OrderRating

    return resp || null
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertOrderRating = createAsyncThunk<
  OrderRating,
  { data: OrderRating },
  { state: RootState; rejectValue: RequestError }
>(
  'OrderRating/upsertOrderRating',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `order-rating-settings`,
        'PUT',
        data
      )) as OrderRating
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const orderRatingSlice = createSlice({
  name: 'OrderRating',
  initialState,
  reducers: {
    resetOrderRating: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderRating.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderRating.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchOrderRating.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertOrderRating.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertOrderRating.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertOrderRating.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOrderRating } = orderRatingSlice.actions

export const selectOrderRating = (state: RootState) => state.orderRating

export default orderRatingSlice.reducer
