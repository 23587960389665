import React, { Children } from 'react'
import styled from '@emotion/styled'

const ButtonGroupView = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonGroupButton = styled.div`
  border: 0.1rem solid ${props => props.theme.colors.border.primary};
  border-bottom-width: 0;

  &:last-of-type {
    border-bottom-width: 0.1rem;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items center;
    width: 100%;
    padding: 1.2rem 1.5rem;
    font-size: ${props => props.theme.sizes.small};
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      font-size: ${props => props.theme.sizes.small};
    }
  }

  button > span {
    display: block;
    color: ${props => props.theme.colors.text.primary};
    transition: color 0.4s ease;
  }

  button > span:first-of-type {
    text-align: left;
  }

  button > span:last-of-type {
    text-align: right;
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      font-size: ${props => props.theme.sizes.xsmall};
    }
  }

  button > span span {
    transition: color 0.4s ease;
  }

  button:hover {
    color: ${props => props.theme.colors.background.primary};
    background-color: ${props => props.theme.colors.text.primary};
  }

  button:hover > span {
    color: ${props => props.theme.colors.background.primary};
  }

  button:hover > span span {
    color: ${props => props.theme.colors.background.primary};
  }
`

const ButtonGroup = ({ children }: React.ComponentProps<'div'>) => {
  const arrayChildren = Children.toArray(children)
  return (
    <ButtonGroupView>
      {Children.map(arrayChildren, child => (
        <ButtonGroupButton>{child}</ButtonGroupButton>
      ))}
    </ButtonGroupView>
  )
}

export default ButtonGroup
