import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus, handleError } from 'utils'
import { FormFieldType, NewPassword } from 'types'
import { selectAuthUser } from 'slices/authUser'
import { Form, ErrMsg, AuthPage, AuthForm, TextBody } from 'components'
import { Link, useNavigate } from 'react-router-dom'
import { selectUser, setNewPassword } from 'slices/user'

const Reset = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const url = window.location.href
  const [, token] = url.split('#')
  const { loading, error } = useAppSelector(selectUser)
  const { user } = useAppSelector(selectAuthUser)
  const isLoading = loading === RequestStatus.Pending
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [initialState] = useState({ password: '', new_password: '' })
  const formError = handleError(error)
  const errorMsg =
    formError.new_password || formError.link_url || formError.form

  const resetFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'password',
      name: 'password',
      required: true,
      label: 'New Password',
      // placeholder: '8 characters minimum, no special characters',
      error: 'Please enter a password'
    },
    {
      fieldType: 'Input',
      type: 'password',
      name: 'new_password',
      required: true,
      label: 'Reenter New Password',
      error: 'Please enter a password'
    }
  ]

  const submit = (values: NewPassword) => {
    const { password, new_password } = values
    if (password !== new_password) {
      setErrMsg('Passwords do not match')
    } else {
      setErrMsg(null)
      setSubmitting(true)
      dispatch(setNewPassword({ new_password, token }))
    }
  }

  useEffect(() => {
    if (user) navigate('/')
  }, [navigate, user])

  useEffect(() => {
    if (!isLoading && submitting) {
      if (errorMsg) {
        setSubmitting(false)
        setErrMsg(errorMsg as string)
      } else {
        setSubmitting(false)
        navigate('/')
      }
    }
  }, [errorMsg, isLoading, submitting, navigate])

  return (
    <>
      <AuthPage src="https://assets.opentender.io/images/heroes/ARTD-C01-Device-005-3000x1956.jpg">
        <AuthForm
          title="Reset your password."
          subtitle="Please enter & re-enter a new password. 8 characters minimum."
          footer={
            <TextBody as="p" size="small">
              Remember your current password? <Link to="/">Sign in here.</Link>
            </TextBody>
          }
        >
          {errMsg && <ErrMsg errMsg={errMsg} />}
          <Form
            fields={resetFields}
            data={initialState}
            isLoading={isLoading}
            submit={submit}
            submitText="Reset Your Password"
            submitColor="secondary"
          />
        </AuthForm>
      </AuthPage>
    </>
  )
}

export default Reset
