import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'
import { OrderNotification } from 'types'

export interface OrderNotificationState {
  data: OrderNotification | null
  loading: RequestStatus
  error: RequestError
}

const initialState: OrderNotificationState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const addOrderNotification = createAsyncThunk<
  OrderNotification,
  { data: OrderNotification },
  { state: RootState; rejectValue: RequestError }
>(
  'orderNotification/addOrderNotification',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `notification-settings`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as OrderNotification
      dispatch(showNotification('Order Notification added successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateOrderNotification = createAsyncThunk<
  OrderNotification,
  { data: OrderNotification; orderNotificationId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'orderNotification/updateOrderNotification',
  async (
    { data, orderNotificationId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `notification-settings/${orderNotificationId}`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as OrderNotification
      dispatch(showNotification('Order Notification updated successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchOrderNotification = createAsyncThunk<
  OrderNotification,
  {
    orderNotificationId: string | number
    params?: { with_related?: boolean }
  },
  { state: RootState; rejectValue: RequestError }
>(
  'orderNotification/fetchOrderNotification',
  async ({ orderNotificationId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `notification-settings/${orderNotificationId}${queryParams}`
      return (await api?.request(endpoint)) as OrderNotification
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const orderNotificationSlice = createSlice({
  name: 'orderNotification',
  initialState,
  reducers: {
    resetOrderNotification: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addOrderNotification.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addOrderNotification.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addOrderNotification.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateOrderNotification.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateOrderNotification.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateOrderNotification.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchOrderNotification.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderNotification.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchOrderNotification.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOrderNotification } = orderNotificationSlice.actions

export const selectOrderNotification = (state: RootState) =>
  state.orderNotification

export default orderNotificationSlice.reducer
