import styled from '@emotion/styled'
import DesignInput from './DesignInput'

const DesignRangeView = styled.input`
  flex-grow: 1;
  flex-shrink: 1;

  & + input {
    flex: 0 0 10rem;
    margin: 0 0 0 1rem;
  }
`

interface DesignRangeProps {
  name: string
  value: string | number
  setValue: (value: string | number | null) => void
  required?: boolean
  min?: number
  max?: number
  step?: number
  disabled?: boolean
}

const DesignRange = ({
  name,
  value,
  setValue,
  min,
  max,
  step,
  disabled = false
}: DesignRangeProps) => {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(evt.target.value))
  }

  return (
    <>
      <DesignRangeView
        type="range"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
      />
      <DesignInput
        type="number"
        name={name}
        value={value}
        setValue={setValue}
        disabled={disabled}
      />
    </>
  )
}

export default DesignRange
