import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { NutritionalInfo } from 'types'
import { showNotification } from 'slices/notification'
import { NavigateFunction } from 'react-router-dom'

export interface NutritionalInfoState {
  data: NutritionalInfo | null
  loading: RequestStatus
  error: RequestError
}

const initialState: NutritionalInfoState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchNutritionalInfo = createAsyncThunk<
  NutritionalInfo,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'nutritionalInfo/fetchNutritionalInfo',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/nutritional-info`
      const resp = (await api?.request(endpoint)) as NutritionalInfo
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateNutritionalInfo = createAsyncThunk<
  NutritionalInfo,
  {
    itemId: string | number
    data: NutritionalInfo
  },
  { state: RootState; rejectValue: RequestError }
>(
  'nutritionalInfo/updateNutritionalInfo',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/nutritional-info`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as NutritionalInfo
      dispatch(showNotification('Nutritional info successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const deleteNutritionalInfo = createAsyncThunk<
  void,
  {
    itemId: string | number
    menuId?: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
    navigate?: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'nutritionalInfo/deleteNutritionalInfo',
  async (
    { itemId, menuId, categoryId, modifierGroupId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/nutritional-info`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Nutritional info removed!'))
      if (navigate && menuId && (categoryId || modifierGroupId)) {
        if (categoryId) {
          const pathname = `/menus/${menuId}/categories/${categoryId}/items/${itemId}`
          navigate({ pathname })
        } else if (modifierGroupId) {
          const pathname = `/menus/${menuId}/modifier-groups/${modifierGroupId}/items/${itemId}`
          navigate({ pathname })
        }
      }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const nutritionalInfoSlice = createSlice({
  name: 'nutritionalInfo',
  initialState,
  reducers: {
    resetNutritionalInfo: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchNutritionalInfo.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchNutritionalInfo.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchNutritionalInfo.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateNutritionalInfo.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateNutritionalInfo.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateNutritionalInfo.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteNutritionalInfo.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteNutritionalInfo.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteNutritionalInfo.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetNutritionalInfo } = nutritionalInfoSlice.actions

export const selectNutritionalInfo = (state: RootState) => state.nutritionalInfo

export default nutritionalInfoSlice.reducer
