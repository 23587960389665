import { useEffect, useState } from 'react'

const useLoading = (
  entityId: string | number | undefined,
  isLoading: boolean
) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (entityId !== 'new') setLoading(true)
  }, [entityId])

  useEffect(() => {
    if (!isLoading) setLoading(false)
  }, [isLoading])

  return loading
}

export default useLoading
