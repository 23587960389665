import styled from '@emotion/styled'

export const BeeEditorView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  iframe {
    min-width: unset !important;
  }
`

export const BeeFileManagerView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export default BeeEditorView
