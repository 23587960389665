import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const CheckmateMenuView = styled.div`
  margin: 3rem 0;
`

export const CheckmateMenuHeader = styled.div`
  padding: 0 0 2rem;
  border-bottom: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  margin: 0 0 2rem;
`

export const CheckmateMenuInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4rem;
  margin: ${props => props.theme.layout.desktop.padding} 0;
`

export const CheckmateMenuInfoSection = styled.div`
  flex: 1;
`

export const CheckmateMenuHoursView = styled.div``

export const CheckmateSpecialHoursView = styled.div`
  margin: 4rem 0 0;
`

export const CheckmateMenuInfoSectionHeader = styled.div`
  padding: 0 0 1rem;
  border-bottom: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  margin: 0 0 2.2rem;
`

export const CheckmateMenuHoursWeekday = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1.2rem;
  border-bottom: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  margin: 0 0 1.2rem;

  &:last-of-type {
    padding: 0;
    border: 0;
    margin: 0;
  }

  & > span {
    display: block;
  }
`

export const CheckmateMenuDetailsView = styled.div``

export const CheckmateMenuSections = styled.div`
  margin: ${props => props.theme.layout.desktop.padding} 0;
`

export const CheckmateMenuSectionsLinks = styled.ul`
  list-style: disc inside;
  margin: 1rem 0 0;
`

export const CheckmateMenuSectionsLink = styled.li`
  padding: 0.5rem 0;
`

export const CheckmateMenuSection = styled.div`
  margin: 0 0 ${props => props.theme.layout.desktop.padding};
`

export const CheckmateMenuSectionHeader = styled.div`
  margin: 0 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const CheckmateMenuItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  @media (max-width: 1480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const CheckmateMenuItemView = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: 0.1rem solid ${props => props.theme.colors.border.primary};
  transition: all 0.4s ease;

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.background.secondary};
  }
`

export const CheckmateMenuItemInfo = styled.div`
  flex: 1 1 auto;
  padding: 1.5rem;
  text-align: left;
`

export const CheckmateMenuItemDescription = styled(TextBody)`
  line-height: 1.4;
`

export const CheckmateMenuItemDetails = styled.div`
  p {
    margin: 1.2rem 0 0;
  }
`

export const CheckmateMenuItemDefault = styled.span`
  color: ${props => props.theme.colors.error.background};
`

export const CheckmateMenuItemSoldOut = styled(TextHeading)`
  color: ${props => props.theme.colors.error.background};
`

export const CheckmateMenuModifiersGroupEmpty = styled(TextHeading)`
  color: ${props => props.theme.colors.error.background};
  margin: 1rem 0 0;
`

export const CheckmateMenuItemImage = styled.div`
  flex: 0 0 12rem;
  min-height: 12rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.tertiary};
  border-left: 0.1rem solid ${props => props.theme.colors.border.primary};

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const CheckmateMenuItemImageError = styled.div`
  padding: 2rem;

  & > span {
    color: ${props => props.theme.colors.error.background};
  }
`

export const CheckmateMenuItemImageCount = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.background.primary};
  background-color: ${props => props.theme.colors.text.primary};

  & > span {
    display: block;
    font-size: ${props => props.theme.sizes.xsmall};
  }
`

export const CheckmateMenuModifiersView = styled.div``

export const CheckmateMenuModifiersGroup = styled.div`
  margin: 0 0 2.5rem;
`

export const CheckmateMenuModifiersGroupHeader = styled.div``

export const CheckmateMenuModifiersGroupTitle = styled.div`
  display: flex;

  span {
    display: block;
  }

  span + span {
    margin-left: 0.7rem;
  }
`

export const CheckmateMenuModifiersGroupModifiers = styled.ul`
  list-style: disc outside;
  margin: 1rem 0 0 2rem;
`

export const CheckmateMenuModifiersGroupModifier = styled.li`
  margin: 0.75rem 0 0;
`

export const CheckmateMenuModifierView = styled.div``

export const CheckmateMenuModifierGroups = styled.div`
  margin: 1rem 0 0;
  padding: 0 0 0 1rem;
`
