import { useEffect, useRef } from 'react'
import { QuickLinksMenuView } from './QuickLinks.styled'

const QuickLinksMenu = ({
  id,
  actions,
  show,
  setShow
}: {
  id: number | string
  actions?: {
    icon: React.ReactNode
    text: string
    callback: React.MouseEventHandler<HTMLButtonElement>
  }[]
  show: boolean
  setShow: (value: boolean) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setShow])

  return (
    <QuickLinksMenuView ref={ref} show={show}>
      {actions?.map(action => (
        <button key={action.text} onClick={action.callback}>
          <span>{action.icon}</span>
          <span>{action.text}</span>
        </button>
      ))}
    </QuickLinksMenuView>
  )
}

export default QuickLinksMenu
