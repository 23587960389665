import styled from '@emotion/styled'

interface ColorSwatchViewProps {
  color: string
}

const ColorSwatchView = styled.div<ColorSwatchViewProps>`
  display: block;
  width: 100%;
  padding: ${props => props.theme.messages.padding};
  border-radius: ${props => props.theme.messages.borderRadius};
  line-height: ${props => props.theme.messages.lineHeight};
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.color};
`

interface ColorSwatchProps extends React.ComponentProps<'button'> {
  color: string
}

const ColorSwatch = ({ children, color, style }: ColorSwatchProps) => {
  return (
    <ColorSwatchView color={color} style={style}>
      {children}
    </ColorSwatchView>
  )
}

export default ColorSwatch
