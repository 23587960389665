import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const DeliveryFeeSubsidiesView = styled.div`
  max-width: ${props => props.theme.layout.desktop.pageWidth};
  padding: 1.5rem;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  border-radius: ${props => props.theme.inputs.borderRadius};
  margin: 0 0 3rem;
`

export const DeliveryFeeSubsidyHeader = styled.div`
  margin: 0 0 2rem;
`

export const DeliveryFeeSubsidyTitle = styled(TextHeading)`
  margin: 0 0 0.7rem;
`

export const DeliveryFeeSubsidySubtitle = styled(TextBody)`
  line-height: 1.3;
`

export const DeliveryFeeSubsidyView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin: 0 0 1rem;

  label {
    flex: 1;
    margin: 0;

    span {
      margin: 0;
    }
  }

  input {
    padding: 0.8rem 1rem;
    font-size: ${props => props.theme.sizes.small};
  }
`

export const DeliveryFeeSubsidyFooter = styled.div`
  margin: 2rem 0 0;
`

export const DeliveryFeeSubsidySpacer = styled.div`
  width: 2rem;
`

export const DeliveryFeeSubsidyRemove = styled.button`
  color: ${props => props.theme.colors.error.background};

  &:hover {
    color: ${props => props.theme.colors.text.primary};
  }
`
