import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Users } from 'types/user'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface UsersState {
  data: Users
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface UsersAPI {
  data: Users
  links: {
    next: string
  }
}

export type UsersParams = {
  is_active?: boolean
  role?: string
  search?: string
}

const initialState: UsersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchUsers = createAsyncThunk<
  UsersAPI,
  UsersParams | void,
  { state: RootState; rejectValue: RequestError }
>('users/fetchUsers', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `users${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as UsersAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUsers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchUsers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetUsers } = usersSlice.actions

export const selectUsers = (state: RootState) => state.users

export default usersSlice.reducer
