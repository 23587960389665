import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SavedContent, CampaignContentType, CampaignContent } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { RootState } from 'app/store'
import defaultEmailTemplate from 'json/email-template.json'

export interface SavedContentsState {
  data: SavedContent[]
  savedUrls: SavedContent[]
  savedRows: SavedContent[]
  textSnippets: SavedContent[]
  htmlTemplates: SavedContent[]
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface SavedContentsAPI {
  data: SavedContent[]
  links: {
    next: string
  }
}

export type FetchSavedContentsParams = {
  // @todo: add more filter options like tags
  is_archived?: string
  query?: string
  content_type?: CampaignContentType | string
  limit?: number
  with_related?: boolean
  search?: string
  'sort-by'?: string
  'sort-direction'?: string
  is_generic?: string
}

const initialState: SavedContentsState = {
  data: [],
  savedUrls: [],
  savedRows: [],
  textSnippets: [],
  htmlTemplates: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

const blankTemplate = {
  subject: '',
  preview_text: '',
  sender_email: '',
  sender_name: '',
  reply_to: '',
  body: '',
  editor_config: JSON.stringify(defaultEmailTemplate),
  content_type: 'HTML',
  campaign_content_id: 0
} as CampaignContent

export const fetchSavedContents = createAsyncThunk<
  SavedContentsAPI,
  FetchSavedContentsParams | null,
  { state: RootState; rejectValue: RequestError }
>(
  'savedContents/fetchSavedContents',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = makeQueryParams({
        with_related: true,
        campaign_id: 'null',
        ...params
      })
      const endpoint = `campaign-content${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as SavedContentsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchHtmlTemplates = createAsyncThunk<
  SavedContentsAPI,
  FetchSavedContentsParams,
  { state: RootState; rejectValue: RequestError }
>(
  'savedContents/fetchHtmlTemplates',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = makeQueryParams({
        with_related: true,
        content_type: 'HTML',
        limit: 1000,
        campaign_id: 'null',
        ...params
      })
      const endpoint = `campaign-content${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as SavedContentsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const savedContentsSlice = createSlice({
  name: 'savedContents',
  initialState,
  reducers: {
    resetSavedContents: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSavedContents.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(fetchSavedContents.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Succeeded
        state.data = payload.data
        state.error = null
        state.links = payload.links
      })
      .addCase(fetchSavedContents.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Failed
        state.error = payload
      })
      .addCase(fetchHtmlTemplates.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(fetchHtmlTemplates.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Succeeded
        state.error = null
        state.htmlTemplates = payload.data
      })
      .addCase(fetchHtmlTemplates.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Failed
        state.error = payload
      })
  }
})

export const { resetSavedContents } = savedContentsSlice.actions

export const selectSavedContents = (state: RootState) => state.savedContents
export const selectDefaultEmailTemplate = (state: RootState) => {
  const { htmlTemplates } = state.savedContents
  return (
    htmlTemplates.find(
      template => template.title?.toLowerCase() === 'default email template'
    ) || blankTemplate
  )
}

export default savedContentsSlice.reducer
