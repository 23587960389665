import inside from 'point-in-polygon'
import { isEmpty } from '@open-tender/utils'
import { RADIUS_KM, RADIUS_MILES } from 'config'
import {
  CustomerAddress,
  RevenueCenter,
  RevenueCenterDeliveryZones,
  RevenueCenterWithZone,
  RevenueCenters,
  RevenueCentersWithAddress,
  RevenueCentersWithZone
} from 'types'
import { sortBy } from 'utils'

export const getLatLng = (address: CustomerAddress) => {
  try {
    return [parseFloat(address.latitude), parseFloat(address.longitude)]
  } catch {
    return null
  }
}

interface SortType {
  sortBy: keyof RevenueCenter
  sortType: string
  sortArray?: string[]
  desc?: boolean
}

export const sortRevenueCenters = (
  revenueCenters: RevenueCenters,
  latLng: number[] | null
): RevenueCentersWithZone => {
  const open = revenueCenters
    .filter(i => !i.closed)
    .filter(i => !i.is_outpost)
    .filter(i => !i.store?.is_master)
    .filter(i => i.address) as RevenueCentersWithAddress
  if (!latLng) {
    const rcs = sortBy(open, 'full_name').map(i => ({
      ...i,
      distance: 0,
      zone: null,
      priority: null,
      inZone: false
    })) as RevenueCentersWithZone
    return rcs
  }
  const rcs = open.map(i => {
    const rcLatLng = [
      parseFloat(i.address.latitude),
      parseFloat(i.address.longitude)
    ]
    const distance = getDistance(latLng, rcLatLng)
    const rc = { ...i, distance } as RevenueCenterWithZone
    if (i.delivery_zones) {
      const [zone, priority] = parseDeliveryZone(i.delivery_zones)
      rc.zone = zone
      rc.priority = priority
      rc.inZone = zone !== null ? inZone(latLng, zone) : false
    } else {
      rc.zone = null
      rc.priority = null
      rc.inZone = false
    }
    return rc
  })
  const priorityRcs = sortBy(
    rcs.filter(i => i.inZone && i.priority),
    'distance'
  )
  const inZoneRcs = sortBy(
    rcs.filter(i => i.inZone && !i.priority),
    'distance'
  )
  const outOfZoneRcs = sortBy(
    rcs.filter(i => !i.inZone),
    'distance'
  )
  return [
    ...priorityRcs,
    ...inZoneRcs,
    ...outOfZoneRcs
  ] as RevenueCentersWithZone
}

export const sortItems = (items: RevenueCenters, sorting: SortType) => {
  if (!sorting || isEmpty(sorting)) return items
  const { sortBy, sortType, sortArray } = sorting

  if (sortArray)
    return sortByArray(items, sortBy as keyof RevenueCenter, sortArray)
  const mappedItems = items.map(i => {
    if (sortBy.includes('.')) {
      const [entity, field] = sortBy.split('.')
      const entityValue = (i as Record<string, any>)[entity]
      if (typeof entityValue === 'object' && field in entityValue) {
        i.sortBy = entityValue[field]
      } else {
        i.sortBy = ''
      }
    } else {
      i.sortBy = i[sortBy as keyof RevenueCenter] as string
    }
    return i
  })

  let sorted
  switch (sortType) {
    case 'alpha':
      sorted = mappedItems.sort((a, b) => sortAlpha(a.sortBy, b.sortBy))
      break
    case 'order':
      sorted = mappedItems.sort(
        (a, b) => parseFloat(a.sortBy) - parseFloat(b.sortBy)
      )
      break
    case 'alphaTime':
      mappedItems.forEach(i => {
        i.sortBy = i.sortBy.padStart(8, '0')
      })
      sorted = mappedItems.sort((a, b) => sortAlpha(a.sortBy, b.sortBy))
      break
    case 'datetime':
      mappedItems.forEach(i => {
        i.sortBy = new Date(i.sortBy).toString()
      })
      sorted = mappedItems.sort(
        (a, b) => parseFloat(a.sortBy) - parseFloat(b.sortBy)
      )
      break
    default:
      sorted = mappedItems || []
  }
  return sorting.desc ? sorted.reverse() : sorted
}

export const sortAlpha = (a: string, b: string) => {
  const first = a ? a.toLowerCase() : ''
  const second = b ? b.toLowerCase() : ''
  if (first < second) return -1
  if (first > second) return 1
  return 0
}

export const sortByArray = (
  items: RevenueCenters,
  sortBy: keyof RevenueCenter,
  sortArray: string[]
) => {
  const itemsLookup = items.reduce(
    (obj, i) => {
      const key = i[sortBy] as string
      obj[key] = i
      return obj
    },
    {} as Record<string, RevenueCenter>
  )

  return sortArray
    .filter(i => Object.keys(itemsLookup).includes(i))
    .map(i => itemsLookup[i])
}

// https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
export const getDistance = (
  pointA: number[],
  pointB: number[],
  inMiles = true
) => {
  const [lat1, lng1] = pointA
  const [lat2, lng2] = pointB
  const R = inMiles ? RADIUS_MILES : RADIUS_KM
  const dLat = deg2rad(lat2 - lat1)
  const dLng = deg2rad(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const inZone = (point: number[], polygon: number[]) => {
  return inside(point, polygon)
}

export const parseDeliveryZone = (
  delivery_zones: RevenueCenterDeliveryZones
) => {
  try {
    const { coordinates, priority } = delivery_zones[0].delivery_zone
    return [JSON.parse(coordinates), priority]
  } catch {
    return [null, 0]
  }
}
