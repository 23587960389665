import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreEmail, StoreSettingsFrom } from 'types'
import { showNotification } from 'slices/notification'

export interface RevenueCenterEmailApi {
  data: StoreEmail
  from: StoreSettingsFrom
}

export interface RevenueCenterEmailState {
  data: StoreEmail | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterEmailState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRevenueCenterEmail = createAsyncThunk<
  RevenueCenterEmailApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterEmail/fetchRevenueCenterEmail',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `revenue-centers/${revenueCenterId}/settings/email`
      )) as RevenueCenterEmailApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertRevenueCenterEmail = createAsyncThunk<
  RevenueCenterEmailApi,
  { revenueCenterId: string | number; data: StoreEmail },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterEmail/upsertRevenueCenterEmail',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `revenue-centers/${revenueCenterId}/settings/email`,
        'PUT',
        data
      )) as RevenueCenterEmailApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterEmail = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterEmail/deleteRevenueCenterEmail',
  async (revenueCenterId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/settings/email`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Email settings removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

const revenueCenterEmailSlice = createSlice({
  name: 'revenueCenterEmail',
  initialState,
  reducers: {
    resetRevenueCenterEmail: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRevenueCenterEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload?.data || null
      state.from = payload?.from || null
      state.error = null
    })
    builder.addCase(fetchRevenueCenterEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertRevenueCenterEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      upsertRevenueCenterEmail.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload.data
        state.from = payload.from
        state.error = null
      }
    )
    builder.addCase(upsertRevenueCenterEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteRevenueCenterEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterEmail.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(deleteRevenueCenterEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetRevenueCenterEmail } = revenueCenterEmailSlice.actions

export const selectRevenueCenterEmail = (state: RootState) =>
  state.revenueCenterEmail

export default revenueCenterEmailSlice.reducer
