import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { PosIdentityRequest } from 'types'
import { showNotification } from 'slices/notification'
import { fetchPosIdentityRequests } from 'slices/posIdentityRequests'

export interface PosIdentityRequestState {
  data: PosIdentityRequest | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PosIdentityRequestState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type PosIdentityRequestParams = {
  expand?: string
  with_related?: string
}

export const fetchPosIdentityRequest = createAsyncThunk<
  PosIdentityRequest | null,
  { posIdentityRequestId: string | number; params?: PosIdentityRequestParams },
  { state: RootState; rejectValue: RequestError }
>(
  'posIdentityRequest/fetchPosIdentityRequest',
  async ({ posIdentityRequestId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/identity-requests/${posIdentityRequestId}${queryParams}`
      )) as PosIdentityRequest
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createPosIdentityRequest = createAsyncThunk<
  PosIdentityRequest,
  { data: PosIdentityRequest; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'posIdentityRequest/createPosIdentityRequest',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/identity-requests',
        'POST',
        data
      )) as PosIdentityRequest
      dispatch(showNotification('POS IdentityRequest successfully created!'))
      dispatch(fetchPosIdentityRequests())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePosIdentityRequest = createAsyncThunk<
  PosIdentityRequest,
  { posIdentityRequestId: string | number; data: PosIdentityRequest },
  { state: RootState; rejectValue: RequestError }
>(
  'posIdentityRequest/updatePosIdentityRequest',
  async (
    { posIdentityRequestId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/identity-requests/${posIdentityRequestId}`,
        'PUT',
        data
      )) as PosIdentityRequest
      dispatch(showNotification('POS IdentityRequest successfully updated!'))
      dispatch(fetchPosIdentityRequests())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deletePosIdentityRequest = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'posIdentityRequest/deletePosIdentityRequest',
  async (posIdentityRequestId, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(
        `pos-v2/identity-requests/${posIdentityRequestId}`,
        'DELETE'
      )
      dispatch(showNotification('POS Identity Request successfully deleted!'))
      dispatch(fetchPosIdentityRequests())
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posIdentityRequestSlice = createSlice({
  name: 'posIdentityRequest',
  initialState,
  reducers: {
    resetPosIdentityRequest: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPosIdentityRequest.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPosIdentityRequest.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchPosIdentityRequest.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createPosIdentityRequest.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      createPosIdentityRequest.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload || initialState.data
        state.error = null
      }
    )
    builder.addCase(createPosIdentityRequest.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePosIdentityRequest.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updatePosIdentityRequest.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload || initialState.data
        state.error = null
      }
    )
    builder.addCase(updatePosIdentityRequest.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deletePosIdentityRequest.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deletePosIdentityRequest.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deletePosIdentityRequest.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPosIdentityRequest } = posIdentityRequestSlice.actions

export const selectPosIdentityRequest = (state: RootState) =>
  state.posIdentityRequest

export default posIdentityRequestSlice.reducer
