import styled from '@emotion/styled'
import { Chip, TextField } from '@mui/material'

export const OptionText = styled.span`
  font-size: ${props => props.theme.sizes.small};
  color: ${props => props.theme.colors.text.primary};
`

export const StyledChip = styled(Chip)`
  && {
    background-color: ${props => props.theme.colors.accent.quarternary};
    border-radius: 2rem;
    margin-right: 4px;
    font-size: ${props => props.theme.sizes.small};
    cursor: pointer;
    font-size: ${props => props.theme.inputs.fontSize};
    font-family: ${props => props.theme.inputs.fontFamily};
    font-weight: ${props => props.theme.inputs.fontWeight};
    font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
    letter-spacing: ${props => props.theme.inputs.letterSpacing};
    line-height: ${props => props.theme.inputs.lineHeight};
    color: ${props => props.theme.colors.text.primary};

    span {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
`

export const ReadOnlyChip = styled(Chip)`
  && {
    background-color: ${props => props.theme.colors.accent.quarternary};
    border-radius: 2rem;
    margin-right: 4px;
    font-size: ${props => props.theme.sizes.small};
    font-family: ${props => props.theme.inputs.fontFamily};
    font-weight: ${props => props.theme.inputs.fontWeight};
    font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
    letter-spacing: ${props => props.theme.inputs.letterSpacing};
    line-height: ${props => props.theme.inputs.lineHeight};

    color: ${props => props.theme.colors.text.primary};

    svg {
      display: none;
    }
  }
`

export const StyledTextField = styled(TextField)`
  && {
    font-size: ${props => props.theme.inputs.fontSize};
    font-family: ${props => props.theme.inputs.fontFamily};
    font-weight: ${props => props.theme.inputs.fontWeight};
    font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
    letter-spacing: ${props => props.theme.inputs.letterSpacing};
    line-height: ${props => props.theme.inputs.lineHeight};
    color: ${props => props.theme.colors.text.primary};
  }

  input {
    font-size: ${props => props.theme.inputs.fontSize};
    font-family: ${props => props.theme.inputs.fontFamily};
    font-weight: ${props => props.theme.inputs.fontWeight};
    font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
    letter-spacing: ${props => props.theme.inputs.letterSpacing};
    line-height: ${props => props.theme.inputs.lineHeight};
    color: ${props => props.theme.colors.text.primary};
  }
`

export const AutoCompleteWrapper = styled.span`
  input,
  select,
  textarea,
  .MuiAutocomplete-inputRoot {
    background-color: ${props => props.theme.colors.background.secondary};
    border-radius: 0.8rem;
  }

  .MuiAutocomplete-inputRoot {
    border-style: solid;
    border-width: ${props => props.theme.inputs.borderWidth};
    border-color: ${props => props.theme.colors.border.primary};
  }

  fieldset {
    border: none;
  }
`
