import styled from '@emotion/styled'
import { RequestError, RequestStatus } from 'utils'
import ErrMsg from './ErrMsg'
import Loader from './Loader'

const ListView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  // margin: -1rem;
  gap: 1.5rem;
`

interface ListItemProps {
  itemWidth: string
}

export const ListItem = styled.div<ListItemProps>`
  width: ${props => props.itemWidth};
  // flex-grow: 1;
  // flex-shrink: 1;
  // min-width: 64rem;
  // flex: 1 1 ${props => props.itemWidth};
  // padding: 1rem;
`

export const ListItemContainer = styled.div`
  display: flex;
  overflow: hidden;
  background-color: ${props => props.theme.colors.background.primary};
  border-radius: ${props => props.theme.borderRadius.medium};
`

const ListLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.tertiary};
  padding: ${props => props.theme.layout.desktop.padding};
  border-radius: ${props => props.theme.borderRadius.medium};
`

const List = <T extends {}>({
  items,
  itemKey,
  renderItem,
  perRow = 1,
  loading,
  error
}: {
  items: T[]
  itemKey: keyof T
  renderItem: (props: T) => JSX.Element
  perRow?: number
  loading?: RequestStatus
  error?: RequestError
}) => {
  const itemWidth = `${((1 / perRow) * 100).toFixed(3)}%`
  const isLoading = loading === RequestStatus.Pending
  const hasItems = items.length > 0
  return hasItems ? (
    <ListView>
      {items.map(item => (
        <ListItem key={item[itemKey] as number | string} itemWidth={itemWidth}>
          <ListItemContainer>{renderItem({ ...item })}</ListItemContainer>
        </ListItem>
      ))}
    </ListView>
  ) : isLoading ? (
    <ListLoading>
      <Loader text="Retrieving data..." />
    </ListLoading>
  ) : error ? (
    <ErrMsg errMsg={error.detail} />
  ) : null
}

export default List
