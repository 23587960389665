import { useTheme } from '@emotion/react'
import { ClipLoader } from 'react-spinners'
import { useImage } from 'hooks'
import {
  AuthPageContent,
  AuthPageFooter,
  AuthPageHeader,
  AuthPageImage,
  AuthPageLogo,
  AuthPageMain,
  AuthPageView
} from './AuthPage.styled'
import { Logo, TextBody } from 'components'

const AuthPage = ({
  src,
  cta,
  children
}: {
  src: string
  children: React.ReactNode
  cta?: React.ReactNode
}) => {
  const theme = useTheme()
  const { hasLoaded } = useImage(src)

  return (
    <AuthPageView>
      <AuthPageMain>
        <AuthPageHeader>
          <AuthPageLogo>
            <a
              href="https://www.itsacheckmate.com"
              target="_blank"
              rel="noreferrer"
            >
              <Logo />
            </a>
          </AuthPageLogo>
        </AuthPageHeader>
        <AuthPageContent>{children}</AuthPageContent>
        <AuthPageFooter>
          <TextBody size="xsmall">© 2024 Checkmate.</TextBody>
          <span>&middot;</span>
          <a
            href="https://www.itsacheckmate.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <TextBody size="xsmall">Privacy Policy</TextBody>
          </a>
          <span>&middot;</span>
          <a
            href="http://itsacheckmate.com/legal/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            <TextBody size="xsmall">Terms of Use</TextBody>
          </a>
        </AuthPageFooter>
      </AuthPageMain>
      <AuthPageImage style={{ backgroundImage: `url(${src})` }}>
        {!hasLoaded && (
          <ClipLoader size={28} color={theme.colors.text.primary} />
        )}
      </AuthPageImage>
    </AuthPageView>
  )
}

export default AuthPage
