import { gql } from 'graphql-request'
import { ONBOARDING_COLLABORATOR_FRAGMENT } from './onboardingBrandsFragments'

export const ONBOARDING_ALL_BRAND_FRAGMENT = gql`
  fragment OnboardingAllBrandFragment on OnboardingAllBrand {
    id
    brandId
    userId
    name
    status
    brandType
    locationsInOnboardingCount
    locationsInLiveCount
    creator {
      ...OnboardingCollaboratorFragment
    }
    createdAt
    state
    parentBrand {
      id
      name
    }
    enterpriseLevel {
      id
      name
    }
    featureStates
    isEssentialsDashboard
    subBrands
    completedAt
  }
  ${ONBOARDING_COLLABORATOR_FRAGMENT}
`

export const LOCATION_ORDERING_PLATFORM = gql`
  fragment LocationOrderingPlatformFragment on LocationOrderingPlatform {
    id
    status
    qualityCheckComment
    goLiveRequestedAt
    goneLiveAt
    createdAt
    onboardingStatus
    posType {
      id
      name
      displayName
    }
    orderingPlatform {
      id
      name
      displayName
    }
    location {
      id
      brandId
      userBrandId
      brandName
      parentBrandName
      name
      address
      onboardingStatus
      enterpriseLevel {
        id
        name
        description
        mailbox
      }
      state {
        id
        menuMappingSpecialist {
          id
          email
          fullName
          imageUrl
        }
        onboardingSpecialist {
          id
          email
          fullName
          imageUrl
        }
        goLiveSpecialist {
          id
          email
          fullName
          imageUrl
        }
        qualityControlSpecialist {
          id
          email
          fullName
          imageUrl
        }
      }
    }
    qualityControlReport {
      id
      status
    }
    salesforceId
    storeLocation
    storeAddress
    qualityCheckCompleteDate
    timeFromWhenOpGotAdded
    timeInCurrentStep
    goLiveAt
    orderingPlatformStep
    canAddQualityControlReport
    progress
    menuMappingSpecialist {
      id
      fullName
      email
      imageUrl
    }
    onboardingSpecialist {
      id
      fullName
      email
      imageUrl
    }
    qualityControlSpecialist {
      id
      fullName
      email
      imageUrl
    }
    goLiveSpecialist {
      id
      fullName
      email
      imageUrl
    }
  }
`

export const POS_TYPE_FRAGMENT = gql`
  fragment PosTypeFragment on PosType {
    id
    name
    displayName
    supported
    onboardingSupport
    directSync
    supportsOauth
    oauthUrlEnvVar
    fullOauthLoginUrl
    supportsImageImport
    supportsItemSort
  }
`

export const ONBOARDING_ALL_LOCATION_FRAGMENT = gql`
  fragment OnboardingAllLocationFragment on OnboardingAllLocation {
    id
    name
    address
    posType {
      ...PosTypeFragment
    }
    brandId
    brandName
    parentBrandName
    enterpriseLevel {
      id
      name
      description
      mailbox
    }
    brandEnterpriseLevelId
    active
    isPosToOpSync
    featureStates
    locationStatus {
      id
      status
      statusReason
      statusAdditionalInformation {
        body
      }
      statusCustomerEmail
      ticketInfo
    }
    storeType
    integratedWithGrubhubV1
    setLiveOnce
    state
    userBrandId
    baseMenuId
  }
  ${POS_TYPE_FRAGMENT}
`

export const ONBOARDING_LOCATION_STATE_FRAGMENT = gql`
  fragment OnboardingLocationStateFragment on OnboardingLocationState {
    id
    locationId
    brandId
    userBrandId
    status
    dueDate
    assignedTo
    assignedId
    eventData
    eventAttributes
    completeTask
    completePercent
    currentStepName
    menuMappingStatus
    salesforceSyncErrors
    orderingPlatforms {
      id
      name
      displayName
    }
    onboardingSpecialist {
      id
      fullName
      imageUrl
    }
    menuMappingSpecialist {
      id
      fullName
      imageUrl
    }
    goLiveSpecialist {
      id
      fullName
      imageUrl
    }
    archived
    starred
    qualityControlSpecialist {
      id
      email
      fullName
    }
  }
`

export const ONBOARDING_ORDERING_PLATFORM_WITH_STATUS = gql`
  fragment OnboardingOrderingPlatformWithStatusFragment on InProgressOrderingPlatform {
    id
    orderingPlatform {
      id
      name
      displayName
    }
    status
    qualityCheckComment
    goLiveAt
    provisionLocations {
      id
      name
    }
    estimateConfig
    waitingOnDoordash
    doordashTargetDate
  }
`

export const ONBOARDING_LOCATION_FRAGMENT_WITHOUT_APP = gql`
  fragment OnboardingLocationFragment on OnboardingLocation {
    id
    name
    posType {
      ...PosTypeFragment
    }
    createdAt
    posUuid
    posOauthUrl
    address
    addressCity
    addressState
    addressZipcode
    brandId
    brandName
    active
    isPosToOpSync
    creator {
      ...OnboardingCollaboratorFragment
    }
    collaborators {
      ...OnboardingCollaboratorFragment
    }
    featureStates
    includeThirdPartyTips
    paymentAndDiningOptionsInternal
    canModifyOrderingPlatforms
    locationStatus {
      id
      status
      statusReason
      statusAdditionalInformation {
        body
      }
      statusCustomerEmail
      ticketInfo
    }
    state {
      ...OnboardingLocationStateFragment
    }
    salesforceId
    setLiveOnce
    userBrandId
    allOrderingPlatforms {
      ...OnboardingOrderingPlatformWithStatusFragment
    }
    liveOrderingPlatforms {
      id
      name
      displayName
    }
    inProgressOrderingPlatforms {
      ...OnboardingOrderingPlatformWithStatusFragment
    }
    operationalContact {
      name
      email
      phone
      contactType
    }
    supportsJuicer
    supportsPaymentAndDiningOptions
    baseMenuId
    uberStoreUrl
    onboardingStatus
    enterpriseLevel {
      id
      name
      description
      mailbox
    }
    supportsJuicer
  }
  ${ONBOARDING_LOCATION_STATE_FRAGMENT}
  ${POS_TYPE_FRAGMENT}
  ${ONBOARDING_COLLABORATOR_FRAGMENT}
  ${ONBOARDING_ORDERING_PLATFORM_WITH_STATUS}
`

export const ONBOARDING_LOCATION_SUBMIT_OPTIONS_FRAGMENT = gql`
  fragment OnboardingLocationSubmitOptionsFragment on OnboardingLocation {
    submitOptions {
      clientType
      paymentOptions {
        guid
        entityType
        externalId
        name
        varName
      }
      diningOptions {
        guid
        entityType
        externalId
        name
        behavior
        varName
      }
    }
  }
`
