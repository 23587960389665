import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { DiscountQrCode } from 'types'
import { showNotification } from 'slices/notification'
import { fetchDiscount } from './discount'

export interface QrCodeState {
  data: DiscountQrCode | null
  loading: RequestStatus
  error: RequestError
}

const initialState: QrCodeState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const createQrCode = createAsyncThunk<
  DiscountQrCode,
  { discount_id: number; is_active: boolean },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/createQrCode',
  async (
    { discount_id, is_active },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('qrcodes', 'POST', {
        discount_id,
        is_active
      })) as DiscountQrCode
      dispatch(showNotification('Qr Code successfully created!'))
      const params = { with_related: 'qrcodes' }
      dispatch(fetchDiscount({ discountId: discount_id, params }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateQrCode = createAsyncThunk<
  DiscountQrCode,
  { qrCodeId: number; discount_id: number; is_active: boolean },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/updateQrCode',
  async (
    { qrCodeId, discount_id, is_active },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `qrcodes/${qrCodeId}`
      const resp = (await api?.request(endpoint, 'PUT', {
        discount_id,
        is_active
      })) as DiscountQrCode
      dispatch(showNotification('Qr Code successfully updated!'))
      const params = { with_related: 'qrcodes' }
      dispatch(fetchDiscount({ discountId: discount_id, params }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const QrCodeSlice = createSlice({
  name: 'QrCode',
  initialState,
  reducers: {
    resetQrCode: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createQrCode.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createQrCode.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createQrCode.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateQrCode.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateQrCode.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateQrCode.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetQrCode } = QrCodeSlice.actions

export const selectQrCode = (state: RootState) => state.qrCode

export default QrCodeSlice.reducer
