import React from 'react'
import {
  AvatarContainer,
  AvatarImage,
  AvatarLabelView,
  AvatarText,
  AvatarView
} from './Avatar.styled'

export interface AvatarProps {
  name?: string
  image?: string
  icon?: React.ReactNode
  size?: number
  style?: React.CSSProperties
  showLabel?: boolean
  showInitials?: boolean
}

const Avatar: React.FC<AvatarProps> = ({
  name,
  image,
  icon,
  size = 25,
  showLabel = true,
  showInitials = true,
  ...props
}) => {
  const initials = (name || 'U')
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
  return (
    <AvatarView>
      <AvatarContainer size={size} image={image}>
        {image ? (
          <AvatarImage src={image} alt={name} {...props} />
        ) : icon ? (
          icon
        ) : (
          <AvatarText>{showInitials ? initials : name}</AvatarText>
        )}
      </AvatarContainer>
      {showLabel && <AvatarLabelView>{name || 'Unassigned'}</AvatarLabelView>}
    </AvatarView>
  )
}

export default Avatar
