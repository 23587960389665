import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { AppConfig } from 'types'
import { showNotification } from 'slices/notification'

export interface AppConfigState {
  data: AppConfig | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AppConfigState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppConfig = createAsyncThunk<
  AppConfig,
  void,
  { state: RootState; rejectValue: RequestError }
>('appConfig/fetchAppConfig', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('order-app-config')) as AppConfig
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertAppConfig = createAsyncThunk<
  AppConfig,
  { data: AppConfig },
  { state: RootState; rejectValue: RequestError }
>(
  'appConfig/upsertAppConfig',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'order-app-config',
        'PUT',
        data
      )) as AppConfig
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAppConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppConfig.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(fetchAppConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertAppConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertAppConfig.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(upsertAppConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectAppConfig = (state: RootState) => state.appConfig

export default appConfigSlice.reducer
