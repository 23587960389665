import styled from '@emotion/styled'

export const RefundView = styled.div`
  max-width: 110rem;
  margin-top: ${props => props.theme.layout.desktop.margin};
`

export const RefundHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`

export const RefundHeader = styled.div`
  width: 15rem;
  padding-right: 4rem;
  text-align: right;
  font-weight: 700;
  font-size: ${props => props.theme.sizes.small};

  &:first-of-type {
    width: 30rem;
    padding-right: 0;
    text-align: left;
  }

  :nth-of-type(5) {
    width: 14rem;
    display: flex;
    justify-content: center;
    padding-right: 0;
  }

  :nth-of-type(6) {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
  }
`

export const RefundTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
  padding: 1.2rem 0 0.3rem;
  border-top: 0.1rem solid ${props => props.theme.colors.border.primary};
`

export const RefundTableCell = styled.div`
  width: 15rem;
  text-align: right;
  padding-right: 4rem;
  gap: 1rem;

  button {
    padding: 0;
    min-width: 2.8rem;
    height: 2.8rem;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  label {
    margin-bottom: 0;

    input {
      padding: 0.4rem 0;
      text-align: center;
    }
  }

  &:first-of-type {
    width: 30rem;
    text-align: left;
    padding-right: 0;
  }

  &:nth-of-type(5) {
    width: 14rem;
    display: flex;
    justify-content: center;
    padding-right: 0;
  }

  &:nth-of-type(6) {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
  }
`

export const RefundTableModifiers = styled.fieldset``

export const RefundTableSubRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0;
  font-size: ${props => props.theme.sizes.small};
`

export const RefundTableSubCell = styled(RefundTableCell)`
  &:first-of-type {
    padding-left: 2rem;
  }
`

export const RefundTableTotals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1rem solid ${props => props.theme.colors.border.secondary};
  padding: 1.8rem 0;
  margin-top: 1rem;
`

export const RefundTableButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  button {
    padding: 0 1rem;
    border-radius: 0.8rem;
    font-size: ${props => props.theme.sizes.xsmall};
  }
`

export const RefundInfos = styled.div`
  & > div {
    margin: 0 0 1.5rem;
  }
`

export const RefundInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0rem;

  :last-of-type {
    border: none;
  }
`

export const RefundButtons = styled.div`
  display: flex;
  gap: 2rem;
  width: 35rem;
  margin-top: 4rem;
`

export const RefundTableConfirm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
  padding: 1.2rem 0 0.3rem;
  border-top: 0.1rem solid ${props => props.theme.colors.border.primary};

  & > div {
    padding: 0;
  }
`

export const RefundHeadersConfirm = styled(RefundTableConfirm)`
  border: 0;
  margin-top: 3rem;
`

export const RefundTableCellConfirm = styled.div`
  width: 25%;
  text-align: right;

  &:first-of-type {
    text-align: left;
  }

  label {
    margin: 0;
  }

  input {
    width: 12rem;
    padding: 0.6rem 0;
    text-align: right;
  }
`

export const RefundHeaderConfirm = styled(RefundTableCellConfirm)`
  font-weight: 700;
  font-size: ${props => props.theme.sizes.small};
`
