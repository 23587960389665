import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { HouseAccountNotes } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface HouseAccountNotesState {
  data: HouseAccountNotes
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountNotesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface HouseAccountNotesAPI {
  data: HouseAccountNotes
  links: {
    next: string
  }
}

export type HouseAccountNotesParams = {
  cursor?: string
  limit?: number
  house_account_id?: number
}

export const fetchHouseAccountNotes = createAsyncThunk<
  HouseAccountNotesAPI,
  HouseAccountNotesParams,
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountNotes/fetchHouseAccountNotes',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `house-account-notes${queryParams}`
      const resp = (await api?.request(endpoint)) as HouseAccountNotesAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountNotesSlice = createSlice({
  name: 'houseAccountNotes',
  initialState,
  reducers: {
    resetHouseAccountNotes: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchHouseAccountNotes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchHouseAccountNotes.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.error = null
    })
    builder.addCase(fetchHouseAccountNotes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHouseAccountNotes } = houseAccountNotesSlice.actions

export const selectHouseAccountNotes = (state: RootState) =>
  state.houseAccountNotes

export default houseAccountNotesSlice.reducer
