import { ReceiptStatus } from 'types'
import { getOrderStatusColor } from 'utils'
import Badge from './Badge'

const OrderStatus = ({ orderStatus }: { orderStatus?: ReceiptStatus }) => {
  const receiptStatusColor = getOrderStatusColor(orderStatus)
  return orderStatus ? (
    <Badge color={receiptStatusColor}>{orderStatus}</Badge>
  ) : null
}

export default OrderStatus
