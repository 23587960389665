import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ModifierGroups, ModifierGroup } from 'types'
import { makeQueryParams, RequestError, RequestStatus, sortBy } from 'utils'

export interface ModifierGroupsState {
  data: ModifierGroups
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface ModifierGroupsAPI {
  data: ModifierGroup[]
  links: {
    next: string
  }
}

export type ModifierGroupsParams = {
  cursor?: string
  limit?: number
  with_related?: string
  is_active?: boolean
  menu_id?: number
  search?: string
  not_assigned_only?: boolean
}

const initialState: ModifierGroupsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchModifierGroups = createAsyncThunk<
  ModifierGroupsAPI,
  ModifierGroupsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'modifier-groups/fetchModifierGroups',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `modifier-groups${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as ModifierGroupsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const modifierGroupsSlice = createSlice({
  name: 'modifier-groups',
  initialState,
  reducers: {
    resetModifierGroups: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchModifierGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchModifierGroups.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchModifierGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetModifierGroups } = modifierGroupsSlice.actions

export const selectModifierGroups = (state: RootState) => state.modifierGroups

export const selectModifierGroupsSorted = (state: RootState) => {
  const { data, loading, error } = state.modifierGroups
  const sorted = sortBy(data, 'short_name')
  return { data: sorted, loading, error }
}

export default modifierGroupsSlice.reducer
