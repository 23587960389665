import styled from '@emotion/styled'

export const MenuItemWarningBelow = styled.strong`
  color: ${props => props.theme.colors.error.background};
  font-size: ${props => props.theme.sizes.xsmall};
  text-transform: uppercase;
  line-height: 1;
`

const MenuItemWarningDone = styled(MenuItemWarningBelow)`
  color: ${props => props.theme.colors.success.background};
`

const MenuItemWarning = ({
  quantity,
  min,
  max
}: {
  quantity: number
  min: number
  max: number
}) => {
  const isRadio = min === 1 && max === 1
  if (quantity < min) {
    return <MenuItemWarningBelow>{quantity} selected</MenuItemWarningBelow>
  } else if (quantity === max && max !== 0 && !isRadio) {
    return <MenuItemWarningDone>{quantity} selected</MenuItemWarningDone>
  } else {
    return null
  }
}

export default MenuItemWarning
