import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { HouseAccountNote } from 'types'

export interface HouseAccountNoteState {
  data: HouseAccountNote | null
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountNoteState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export interface HouseAccountNoteReq {
  house_account_id: number
  note: string
}

export const addHouseAccountNote = createAsyncThunk<
  HouseAccountNote,
  HouseAccountNoteReq,
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountNote/addHouseAccountNote',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-account-notes`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as HouseAccountNote
      dispatch(showNotification('House Account Note added successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateHouseAccountNote = createAsyncThunk<
  HouseAccountNote,
  { data: HouseAccountNoteReq; houseAccountNoteId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountNote/updateHouseAccountNote',
  async (
    { data, houseAccountNoteId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-account-notes/${houseAccountNoteId}`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as HouseAccountNote
      dispatch(showNotification('House Account Note updated successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchHouseAccountNote = createAsyncThunk<
  HouseAccountNote,
  {
    houseAccountNoteId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'HouseAccountNote/fetchHouseAccountNote',
  async (houseAccountNoteId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-account-notes/${houseAccountNoteId}`
      const resp = (await api?.request(endpoint)) as HouseAccountNote
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteHouseAccountNote = createAsyncThunk<
  void,
  {
    houseAccountNoteId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountNote/deleteHouseAccountNote',
  async ({ houseAccountNoteId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-account-notes/${houseAccountNoteId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('House Account note removed.'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountNoteSlice = createSlice({
  name: 'houseAccountNote',
  initialState,
  reducers: {
    resetHouseAccountNote: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addHouseAccountNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addHouseAccountNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addHouseAccountNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateHouseAccountNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateHouseAccountNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateHouseAccountNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchHouseAccountNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchHouseAccountNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchHouseAccountNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteHouseAccountNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteHouseAccountNote.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteHouseAccountNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHouseAccountNote } = houseAccountNoteSlice.actions

export const selectHouseAccountNote = (state: RootState) =>
  state.houseAccountNote

export default houseAccountNoteSlice.reducer
