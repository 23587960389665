import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { AppElements, AppPage, AppPages } from 'types'
import { showNotification } from 'slices/notification'

export interface AppPageState {
  data: AppElements | null
  loading: RequestStatus
  error: RequestError
}

export interface AppPageAPI {
  data: AppPages
  links: {
    next: string | null
    prev?: string
  }
}

const initialState: AppPageState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppPage = createAsyncThunk<
  AppPage | null,
  { pageType: string },
  { state: RootState; rejectValue: RequestError }
>(
  'appPage/fetchAppPage',
  async ({ pageType }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const params = `?page_type=${pageType}&is_latest=true`
      const { data } = (await api?.request(
        `order-app-pages${params}`
      )) as AppPageAPI
      return data.find(i => i.is_latest) || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertAppPage = createAsyncThunk<
  AppPage,
  { pageType: string; data: AppElements },
  { state: RootState; rejectValue: RequestError }
>(
  'appPage/upsertAppPage',
  async ({ pageType, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(`order-app-pages`, 'POST', {
        page_type: pageType,
        page_content: data
      })) as AppPage
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const publishPages = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'appPage/publishPages',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(`order-app-bundles/publish-latest-pages`, 'POST', {})
      dispatch(showNotification('Published successfully!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const AppPageSlice = createSlice({
  name: 'appPage',
  initialState,
  reducers: {
    resetAppPage: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAppPage.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppPage.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload?.page_content || null
    })
    builder.addCase(fetchAppPage.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertAppPage.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertAppPage.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload.page_content
    })
    builder.addCase(upsertAppPage.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(publishPages.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(publishPages.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(publishPages.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAppPage } = AppPageSlice.actions

export const selectAppPage = (state: RootState) => state.appPage

export const selectAppPageData = (state: RootState) => state.appPage.data

export default AppPageSlice.reducer
