import { useEffect, useState } from 'react'
import { ModalWindow } from 'types'
import { ModalHeader } from 'components/Modal'
import { ButtonSubmit, ErrMsg, TextArea } from 'components'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { editOrder, fetchOrderMgmt, selectOrderMgmt } from 'slices/orderMgmt'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'

export interface AdjustNotesInternalArgs {
  orderId: number
  notesInternal: string
  storeId: string
}

const AdjustNotesInternal = ({
  args,
  modalWindow
}: {
  args: AdjustNotesInternalArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectOrderMgmt)
  const { orderId, notesInternal, storeId } = args
  const [submitted, setSubmitted] = useState(false)
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const [notes, setNotes] = useState<string>(notesInternal || '')
  const preface = orderId ? `Order #${orderId}` : null
  const isLoading = loading === RequestStatus.Pending

  const submit = () => {
    setSubmitted(true)
    setErrMsg(null)
    dispatch(editOrder({ orderId, data: { notes_internal: notes } }))
  }

  const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(evt.target.value)
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (error) {
        setErrMsg(error.detail)
      } else {
        dispatch(fetchOrderMgmt({ store_id: storeId }))
        dispatch(closeModal())
      }
    }
  }, [dispatch, submitted, isLoading, error, storeId])

  return (
    <>
      <ModalHeader preface={preface} title="Update Internal Notes" />
      <ErrMsg errMsg={errMsg} />
      <TextArea
        name="notes_internal"
        value={notes}
        label="Internal Notes"
        required={true}
        onChange={onChange}
      />
      <ButtonSubmit onClick={submit} disabled={isLoading} />
    </>
  )
}

export default AdjustNotesInternal
