import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { MenuRegens, RevenueCenterType } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface MenuRegensState {
  data: MenuRegens
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export type MenusPublishRevenueCenter = {
  revenue_center_id: number
}

export interface MenuRegensAPI {
  data: MenuRegens
  links: {
    next: string
  }
}

const initialState: MenuRegensState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type MenuRegensParams = {
  limit?: number
  cursor?: string
  expand?: string
  revenue_center_type?: RevenueCenterType
  region_id?: number
  store_group_id?: number
  closed?: string
  is_outpost?: string
}

export const fetchMenuRegens = createAsyncThunk<
  MenuRegensAPI,
  MenuRegensParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'regenerate/fetchMenuRegens',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const limitParams = queryParams ? '&limit=1000' : '?limit=1000'
      const endpoint = `regenerate-menus${queryParams}${limitParams}`
      return (await api?.request(endpoint)) as MenuRegensAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const publishMenus = createAsyncThunk<
  void,
  { revenueCenterIds: number[]; callback?: () => void },
  { state: RootState; rejectValue: RequestError }
>(
  'regenerate/publishMenus',
  async (
    { revenueCenterIds, callback },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `regenerate-menus`
      const data = revenueCenterIds.reduce((arr, id) => {
        return [...arr, { revenue_center_id: id }]
      }, [] as MenusPublishRevenueCenter[])
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Menus added to publish queue!'))
      if (callback) callback()
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const regenerateSlice = createSlice({
  name: 'regenerate',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMenuRegens.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchMenuRegens.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchMenuRegens.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(publishMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(publishMenus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(publishMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectMenuRegens = (state: RootState) => state.regenerate

export const selectMenuRegensMap = (state: RootState) => {
  const { data, loading, error } = state.regenerate
  const menuRegens = data.reduce((obj, i) => {
    return { ...obj, [`${i.revenue_center_id}`]: i }
  }, {})
  return { menuRegens, loading, error }
}

export default regenerateSlice.reducer
