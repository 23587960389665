import styled from '@emotion/styled'

const ModalFooter = styled.div`
  margin: 3rem 0 0;

  button + button {
    margin-left: 1rem;
  }
`

export default ModalFooter
