import { useEffect, useState } from 'react'
import { HouseAccount } from '@open-tender/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { addTender, selectAmountRemaining } from 'slices/order'
import { closeModal } from 'slices/modal'
import { ModalWindow } from 'types'
import { Button, Input } from 'components'
import { ModalHeader } from 'components/Modal'
import { formatDollars } from '@open-tender/utils'

const OrderHouseAccount = ({
  args,
  modalWindow
}: {
  args: {
    houseAccount: HouseAccount
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { houseAccount } = args
  const [submitted, setSubmitted] = useState(false)
  const [amount, setAmount] = useState<string | null>(null)
  const remaining = useAppSelector(selectAmountRemaining)
  const { name } = houseAccount
  const amountRemaining = formatDollars(remaining.toString())
  const subtitle = `Enter amount less than or equal to amount remaining of ${amountRemaining}`
  const current = amount ? Number(amount) : 0.0
  const isAmountValid = current > 0 && current <= remaining ? true : false
  const error = !submitted && current > remaining ? subtitle : undefined

  const add = () => {
    if (!isAmountValid) return
    setSubmitted(true)
    const adjusted = current.toFixed(2)
    setAmount(adjusted)
    const tender = {
      tender_type: 'HOUSE_ACCOUNT',
      amount: adjusted,
      ...houseAccount
    }
    dispatch(addTender(tender))
    dispatch(closeModal())
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(evt.target.value)
  }

  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') add()
  }

  useEffect(() => {
    if (amount === null) setAmount(remaining.toFixed(2))
  }, [amount, remaining])

  return (
    <>
      <ModalHeader title={name} subtitle={subtitle} />
      <Input
        type="number"
        name="amount"
        label="Tender Amount"
        min={0}
        value={amount || ''}
        error={error}
        onChange={onChange}
        onKeyDown={onKeyPress}
      />
      <Button onClick={add} disabled={!isAmountValid}>
        Apply Payment
      </Button>
    </>
  )
}

export default OrderHouseAccount
