import { useState } from 'react'
import { FormFieldType, ModalWindow } from 'types'
import { RequestError } from 'utils'
import Form from 'components/Forms/Form/Form'
import { ModalHeader } from 'components/Modal'

interface TestLongProps {
  modalWindow: ModalWindow
}

const TestLong = ({ modalWindow }: TestLongProps) => {
  const [error, setError] = useState<RequestError>(null)

  const [initialState] = useState({
    test1: '',
    test2: '',
    test3: '',
    test4: '',
    test5: '',
    test6: '',
    test7: '',
    test8: ''
  })

  const testConfig: FormFieldType[] = [
    {
      fieldType: 'Input',
      label: 'Test 1',
      placeholder: 'test 1',
      name: 'test1',
      type: 'text',
      id: 'test1',
      error: 'Please enter a test 1'
    },
    {
      fieldType: 'Input',
      label: 'Test 2',
      placeholder: 'test 2',
      name: 'test2',
      type: 'text',
      id: 'test2',
      error: 'Please enter a test 2'
    },
    {
      fieldType: 'Input',
      label: 'Test 3',
      placeholder: 'test 3',
      name: 'test3',
      type: 'text',
      id: 'test3',
      error: 'Please enter a test 3'
    },
    {
      fieldType: 'Input',
      label: 'Test 4',
      placeholder: 'test 4',
      name: 'test4',
      type: 'text',
      id: 'test4',
      error: 'Please enter a test 4'
    },
    {
      fieldType: 'Input',
      label: 'Test 5',
      placeholder: 'test 5',
      name: 'test5',
      type: 'text',
      id: 'test5',
      error: 'Please enter a test 5'
    },
    {
      fieldType: 'Input',
      label: 'Test 6',
      placeholder: 'test 6',
      name: 'test6',
      type: 'text',
      id: 'test6',
      error: 'Please enter a test 6'
    },
    {
      fieldType: 'Input',
      label: 'Test 7',
      placeholder: 'test 7',
      name: 'test7',
      type: 'text',
      id: 'test7',
      error: 'Please enter a test 7'
    },
    {
      fieldType: 'Input',
      label: 'Test 8',
      placeholder: 'test 8',
      name: 'test8',
      type: 'text',
      id: 'test8',
      error: 'Please enter a test 8'
    }
  ]

  const handleSubmit = () => {
    const err = {
      code: 'TEST_ERROR',
      title: 'Invalid Parameters',
      detail: 'The parameters of your request are invalid',
      status: 400
    }
    setError(err)
  }

  return (
    <>
      <ModalHeader
        title="Test Long Modal Dialog"
        subtitle="This is a long modal that overflows the user's browser window"
      />
      <Form
        fields={testConfig}
        data={initialState}
        error={error}
        submit={handleSubmit}
        isLoading={false}
        scrollWindow={modalWindow}
      />
    </>
  )
}

export default TestLong
