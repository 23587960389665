import styled from '@emotion/styled'

export const QuickLinksView = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  button {
    display: block;
    line-height: 0;
    padding: 0 0.5rem;
  }

  button {
    color: ${props => props.theme.colors.text.primary};

    &:hover {
      color: ${props => props.theme.colors.accent.primary};
    }
  }
`

interface QuickLinksMenuViewProps {
  show: boolean
}

export const QuickLinksMenuView = styled.span<QuickLinksMenuViewProps>`
  display: block;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  padding: 0 0.5rem;
  min-width: 20rem;
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  font-size: ${props => props.theme.sizes.small};
  background-color: ${props => props.theme.colors.background.secondary};
  border: 0.1rem solid ${props => props.theme.colors.border.primary};
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

  a,
  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1.5rem;
    border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
    &:hover {
      color: ${props => props.theme.colors.accent.primary};
    }
    &:last-of-type {
      border: 0;
    }
  }

  a > span,
  button > span {
    display: block;
    line-height: 0;
  }
`
