import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { StoreGroups } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface StoreGroupsApi {
  data: StoreGroups
  links: Record<string, string>
}

export interface StoreGroupsState {
  data: StoreGroups
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: StoreGroupsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreGroups = createAsyncThunk<
  StoreGroupsApi,
  void,
  { state: RootState; rejectValue: RequestError }
>('storeGroups/fetchStoreGroups', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('store-groups')) as StoreGroupsApi
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const storeSlice = createSlice({
  name: 'storeGroups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreGroups.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchStoreGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStoreGroups = (state: RootState) => state.storeGroups
export const selectStoreGroupsData = (state: RootState) =>
  state.storeGroups.data

export const selectStoreGroupsOptions = createSelector(
  (state: RootState) => {
    return state.storeGroups.data
  },
  storeGroups => {
    if (!storeGroups) return []
    return storeGroups.map(i => ({
      value: i.store_group_id,
      text: i.short_name
    }))
  }
)

export default storeSlice.reducer
