import { useEffect, useState } from 'react'
import { useAppDispatch } from 'app/hooks'
import { ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { ModalHeader } from 'components/Modal'
import { TextArea } from 'components/Forms'
import { showNotification } from 'slices/notification'
import Button from 'components/Button'

const Notes = ({
  args,
  modalWindow
}: {
  args: {
    title?: string
    subtitle?: string
    note?: string
    note_id?: number
    saveNote: (note: string, note_id?: number) => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { title, subtitle, note_id, saveNote } = args
  const [note, setNote] = useState<string>(args.note || '')
  const [isNoteUpdated, setIsNoteUpdated] = useState(false)

  useEffect(() => {
    setIsNoteUpdated(false)
  }, [])

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!isNoteUpdated) setIsNoteUpdated(true)
    setNote(event.target.value)
  }

  const submit = () => {
    if (!isNoteUpdated) {
      dispatch(showNotification('Please update/enter the note first.'))
      return
    }

    if (note_id) saveNote(note, note_id)
    else saveNote(note)
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader
        title={title || 'Notes'}
        subtitle={subtitle || 'Add/edit a note.'}
      />
      <TextArea
        fieldType="TextArea"
        name="note"
        value={note}
        onChange={handleNoteChange}
      />
      <Button onClick={submit}>Save Note</Button>
    </>
  )
}

export default Notes
