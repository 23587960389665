import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import {
  createKdsTerminalPrepStations,
  fetchKdsTerminalPrepStations,
  selectKdsTerminalPrepStations
} from 'slices/kdsTerminalPrepStations'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { selectPrepStations } from 'slices/prepStations'

const KdsTerminalPrepStation = ({
  args,
  modalWindow
}: {
  args: { kdsTerminalId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { kdsTerminalId } = args
  const { data, loading, error } = useAppSelector(selectKdsTerminalPrepStations)
  const prepStations = useAppSelector(selectPrepStations)
  const current = data.map(i => i.prep_station_id)
  const filtered = prepStations.data.filter(
    i => !current.includes(i.prep_station_id)
  )
  const isLoading =
    loading === RequestStatus.Pending ||
    prepStations.loading === RequestStatus.Pending

  const prepStationOptions = useMemo(() => {
    return filtered.map(i => {
      return {
        value: i.prep_station_id,
        text: i.name
      }
    })
  }, [filtered])

  const submit = async (values: { prep_station_id: number }) => {
    if (kdsTerminalId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(createKdsTerminalPrepStations({ kdsTerminalId, data }))
      dispatch(fetchKdsTerminalPrepStations(kdsTerminalId))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add New Prep Station"
        subtitle="Choose a prep station from the options below"
      />
      <Form
        fields={kdsTerminalPrepStationFields(prepStationOptions)}
        data={kdsTerminalPrepStationEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const kdsTerminalPrepStationEmpty = {
  prep_station_id: null
}

const kdsTerminalPrepStationFields = (
  prepStationOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'prep_station_id',
    options: prepStationOptions,
    label: 'Prep Station',
    error: 'Please select an prep station'
  }
]

export default KdsTerminalPrepStation
