import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { selectLoyaltyTiers } from 'slices/loyaltyTiers'
import {
  createItemLoyaltyTier,
  fetchItemLoyaltyTier,
  selectItemLoyaltyTier
} from 'slices/itemLoyaltyTier'

const ItemLoyaltyTier = ({
  args,
  modalWindow
}: {
  args: { itemId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { itemId } = args
  const { data, loading, error } = useAppSelector(selectItemLoyaltyTier)
  const loyaltyTiers = useAppSelector(selectLoyaltyTiers)
  const current = data.map(i => i.loyalty_tier_id)
  const filtered = loyaltyTiers.data.filter(
    i => !current.includes(i.loyalty_tier_id)
  )
  const isLoading =
    loading === RequestStatus.Pending ||
    loyaltyTiers.loading === RequestStatus.Pending

  const loyaltyTierOptions = useMemo(() => {
    return filtered.map(i => {
      return {
        value: i.loyalty_tier_id,
        text: `${i.name}`
      }
    })
  }, [filtered])

  const submit = async (values: { loyalty_tier_id: number }) => {
    if (itemId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(createItemLoyaltyTier({ itemId, data }))
      dispatch(fetchItemLoyaltyTier(itemId))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add New Loyalty Tier"
        subtitle="Choose a loyalty tier from the options below"
      />
      <Form
        fields={itemLoyaltyTierFields(loyaltyTierOptions)}
        data={itemLoyaltyTierEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const itemLoyaltyTierEmpty = {
  loyalty_tier_id: null
}

const itemLoyaltyTierFields = (
  loyaltyTierOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'loyalty_tier_id',
    options: loyaltyTierOptions,
    label: 'Loyalty Tier',
    error: 'Please select a loyalty tier'
  }
]

export default ItemLoyaltyTier
