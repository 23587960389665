import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalWindow, SearchResult } from 'types'
import { closeModal } from 'slices/modal'
import { ModalHeader } from 'components/Modal'
import { SearchResults } from 'components'
import { fetchUsers, resetUsers, selectUsers } from 'slices/users'
import { RequestStatus } from 'utils'

const UserSearch = ({
  args,
  modalWindow
}: {
  args: {
    title?: string
    subtitle?: string
    chooseUser: (user_id: string | number) => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data, loading } = useAppSelector(selectUsers)
  const isLoading = loading === RequestStatus.Pending
  const { title, subtitle, chooseUser } = args

  const searchResults: SearchResult[] = data.map(user => ({
    id: user.user_id,
    name: `${user.first_name} ${user.last_name} (${user.email})`
  }))

  const handleUser = (searchResult: SearchResult) => {
    if (searchResult.id) {
      chooseUser(searchResult.id)
      dispatch(closeModal())
    }
  }

  useEffect(() => {
    dispatch(resetUsers())
  }, [dispatch])

  const handleSearchUsers = (search: string) => {
    const params = {
      search: search
    }
    dispatch(fetchUsers(params))
  }

  return (
    <>
      <ModalHeader
        title={title || 'User'}
        subtitle={subtitle || 'Start typing & hit enter to search.'}
      />
      <SearchResults
        isLoading={isLoading}
        searchResults={searchResults}
        handleSearch={handleSearchUsers}
        handleSelect={handleUser}
      />
    </>
  )
}

export default UserSearch
