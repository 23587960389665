import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useAppDispatch } from 'app/hooks'
import { revokeToken } from 'slices/auth'

const Logout = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(revokeToken()).then(() => {
      dispatch({ type: 'clearState' })
      navigate('/')
    })
  }, [dispatch, navigate])

  return null
}

export default Logout
