import { Link } from 'react-router-dom'
import Highlight from 'components/Highlight'
import { NavCardAlert as NavCardAlertType } from 'types'
import { NavCardAlertView } from './NavCards.styled'

const NavCardAlert = ({
  text,
  color = 'alert',
  redirect
}: NavCardAlertType) => {
  return (
    <NavCardAlertView>
      {redirect ? (
        <Link to={redirect}>
          <Highlight color={color}>{text}</Highlight>
        </Link>
      ) : (
        <Highlight color={color}>{text}</Highlight>
      )}
    </NavCardAlertView>
  )
}

export default NavCardAlert
