import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { AppComponentsConfig } from 'types'

export interface AppComponentsState {
  data: AppComponentsConfig | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AppComponentsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppComponents = createAsyncThunk<
  AppComponentsConfig,
  string[],
  { state: RootState; rejectValue: RequestError }
>(
  'appComponent/fetchAppComponents',
  async (componentTypes, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const params = `?pages=${componentTypes.join(',')}`
      return (await api?.request(
        `order-app-pages/build${params}`
      )) as AppComponentsConfig
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const AppComponentsSlice = createSlice({
  name: 'appComponent',
  initialState,
  reducers: {
    resetAppComponents: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAppComponents.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppComponents.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(fetchAppComponents.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAppComponents } = AppComponentsSlice.actions

export const selectAppComponents = (state: RootState) => state.appComponents

export default AppComponentsSlice.reducer
