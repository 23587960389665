import { useEffect, useState } from 'react'
import { ModalWindow } from 'types'
import { ModalHeader } from 'components/Modal'
import { ButtonSubmit, Checkbox, ErrMsg, Input } from 'components'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { editOrder, fetchOrderMgmt, selectOrderMgmt } from 'slices/orderMgmt'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'

export interface AdjustTaxExemptArgs {
  isTaxExempt: boolean
  orderId: number
  storeId: string
  taxExemptId: string
}

const AdjustTaxExempt = ({
  args,
  modalWindow
}: {
  args: AdjustTaxExemptArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectOrderMgmt)
  const { isTaxExempt, orderId, storeId, taxExemptId } = args
  const [submitted, setSubmitted] = useState(false)
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const [taxExempt, setTaxExempt] = useState(isTaxExempt)
  const [taxId, setTaxId] = useState<string>(taxExemptId || '')
  const preface = orderId ? `Order #${orderId}` : null
  const isLoading = loading === RequestStatus.Pending

  const submit = () => {
    setSubmitted(true)
    setErrMsg(null)
    const data = { is_tax_exempt: taxExempt, tax_exempt_id: taxId }
    dispatch(editOrder({ orderId, data }))
  }

  const handleCheckbox = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTaxExempt(evt.target.checked)
  }

  const handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTaxId(evt.target.value)
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (error) {
        setErrMsg(error.detail)
      } else {
        dispatch(fetchOrderMgmt({ store_id: storeId }))
        dispatch(closeModal())
      }
    }
  }, [dispatch, submitted, isLoading, error, storeId])

  return (
    <>
      <ModalHeader
        preface={preface}
        title="Adjust Tax Exempt Status"
        subtitle="Tax Exempt ID is required for tax exempt orders"
      />
      <ErrMsg errMsg={errMsg} />
      <Checkbox
        fieldType="Checkbox"
        label="Is Tax Exempt"
        name="is_tax_exempt"
        checked={taxExempt}
        onChange={handleCheckbox}
      />
      <Input
        name="tax_exempt_id"
        type="text"
        value={taxId}
        label="Tax Exempt ID"
        required={taxExempt}
        onChange={handleInput}
        min={0}
      />
      <ButtonSubmit onClick={submit} disabled={isLoading} />
    </>
  )
}

export default AdjustTaxExempt
