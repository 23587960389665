import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  FormFieldType,
  ModalWindow,
  RevenueCenterBlockedHour as RevenueCenterBlockedHourType
} from 'types'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { weekdayOptions } from 'config'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import {
  fetchRevenueCenterBlockedHours,
  createRevenueCenterBlockedHours,
  resetRevenueCenterBlockedHours,
  selectRevenueCenterBlockedHours
} from 'slices/revenueCenterBlockedHours'

const RevenueCenterBlockedHour = ({
  args,
  modalWindow
}: {
  args: { revenueCenterId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectRevenueCenterBlockedHours)
  const isLoading = loading === RequestStatus.Pending
  const [submitted, setSubmitted] = useState(false)
  const { revenueCenterId } = args

  const submit = async (values: RevenueCenterBlockedHourType) => {
    if (revenueCenterId) {
      values.revenue_center_id = Number(revenueCenterId)
      dispatch(createRevenueCenterBlockedHours({ data: [values] }))
      setSubmitted(true)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetRevenueCenterBlockedHours())
    }
  }, [dispatch])

  useEffect(() => {
    if (submitted && !error && !isLoading) {
      const params = { revenue_center_id: Number(revenueCenterId) }
      dispatch(fetchRevenueCenterBlockedHours(params))
      dispatch(closeModal())
    }
  }, [dispatch, submitted, error, revenueCenterId, isLoading])

  return (
    <div>
      <ModalHeader
        title="Add New Blocked Hours"
        subtitle="Please fill in all of the fields below"
      />
      <Form
        fields={revenueCenterBlockedHoursFields}
        data={revenueCenterBlockedHoursEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const revenueCenterBlockedHoursEmpty = {
  weekday: 'SUNDAY',
  start_time: '00:00:00',
  end_time: '00:00:00'
}

const revenueCenterBlockedHoursFields: FormFieldType[] = [
  {
    fieldType: 'Select',
    type: 'text',
    name: 'weekday',
    options: weekdayOptions,
    label: 'Weekday',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'start_time',
    label: 'Start Time',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'end_time',
    label: 'End Time',
    required: true
  }
]

export default RevenueCenterBlockedHour
