import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import Tilt from 'hamburger-react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectGlobal, setShowSidebar } from 'slices/global'
import { isMobile } from 'react-device-detect'

const HamburgerView = styled.div`
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: ${props => props.theme.layout.mobile.header.height};
  background-color: ${props => props.theme.colors.background.primary};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`

const ReactHamburger = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { colors } = useTheme()
  const { showSidebar } = useAppSelector(selectGlobal)

  const handleClick = () => {
    dispatch(setShowSidebar(!showSidebar))
  }

  useEffect(() => {
    if (isMobile) {
      dispatch(setShowSidebar(false))
    }
  }, [dispatch, pathname])

  return (
    <HamburgerView>
      <Tilt
        size={20}
        color={colors.text.primary}
        toggle={handleClick}
        toggled={showSidebar}
        direction="left"
      />
    </HamburgerView>
  )
}

export default ReactHamburger
