import { Link } from 'react-router-dom'
import { useAppDispatch } from 'app/hooks'
import { ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { Button, TextBody } from 'components'
import { ModalFooter, ModalHeader } from 'components/Modal'
import styled from '@emotion/styled'

export const LinksList = styled.span`
  margin-right: 0.5rem;
`

const PaymentConfirmation = ({
  args,
  modalWindow
}: {
  args: {
    invoiceId: number
    orderIds: number[]
    handlePaymentUpdate: (invoiceId: number, orderIds: number[]) => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { invoiceId, orderIds, handlePaymentUpdate } = args

  const markPaid = () => {
    if (invoiceId) {
      handlePaymentUpdate(invoiceId, orderIds)
      dispatch(closeModal())
    }
  }

  return (
    <>
      <ModalHeader title="Are you sure?" />
      <TextBody as="p" size="large" style={{ lineHeight: 1.5 }}>
        Invoice #1637 includes orders: &nbsp;
        {orderIds &&
          orderIds.map((order, index) => (
            <LinksList key={order}>
              {index > 0 && ', '}
              <Link
                to={`/orders/${order}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {order}
              </Link>
            </LinksList>
          ))}
      </TextBody>
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        Are you sure you want to mark it as paid?
      </TextBody>
      <TextBody as="p" size="large">
        This action cannot be undone.
      </TextBody>
      <ModalFooter>
        <Button color="primary" size="medium" onClick={() => markPaid()}>
          Mark Paid
        </Button>
      </ModalFooter>
    </>
  )
}

export default PaymentConfirmation
