import styled from '@emotion/styled'

const TableButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 2rem;
  }
`

export default TableButtons
