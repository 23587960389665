import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CustomerCard, CustomerCardCreate } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface CustomerCardState {
  data: CustomerCard | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CustomerCardState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const createCustomerCard = createAsyncThunk<
  CustomerCard,
  { customerId: number; data: CustomerCardCreate },
  { state: RootState; rejectValue: RequestError }
>(
  'customerCard/createCustomerCard',
  async ({ customerId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customers/${customerId}/credit-cards`
      const resp = (await api?.request(endpoint, 'POST', data)) as CustomerCard
      dispatch(showNotification('Credit card successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const customerCardSlice = createSlice({
  name: 'customerCard',
  initialState,
  reducers: {
    resetCustomerCard: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createCustomerCard.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createCustomerCard.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(createCustomerCard.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCustomerCard } = customerCardSlice.actions

export const selectCustomerCard = (state: RootState) => state.customerCard

export default customerCardSlice.reducer
