import styled from '@emotion/styled'

interface PageContainerProps {
  maxWidth?: string
}

const PageContainer = styled.div<PageContainerProps>`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.125s forwards;
`

export default PageContainer
