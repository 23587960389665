import styled from '@emotion/styled'
import TextBody from './TextBody'

const ProgressBarView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4rem;
`

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.2rem;
  background-color: ${props => props.theme.colors.background.tertiary};
`

const ProgressFill = styled.div<{ value: number }>`
  height: 100%;
  background-color: ${props => props.theme.colors.text.primary};
  width: ${props => props.value}%;
`

const ProgressBarValueText = styled(TextBody)`
  margin-bottom: 0.6rem;
`

const ProgressBar = ({ value }: { value: number }) => {
  return (
    <ProgressBarView>
      <ProgressBarValueText size="xsmall">{`${value}%`}</ProgressBarValueText>
      <ProgressBarContainer>
        <ProgressFill value={value} />
      </ProgressBarContainer>
    </ProgressBarView>
  )
}

export default ProgressBar
