import { useEffect } from 'react'
import { useAppDispatch } from 'app/hooks'
import { ModifierGroup, ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { fetchModifierGroup } from 'slices/modifierGroup'
import { Files, MenuModifierGroupForm } from 'components'
import {
  PreviewForm,
  PreviewImages,
  PreviewSectionTitle,
  PreviewTitle,
  PreviewView,
  PreviewWrapper
} from './Preview.styled'

const PreviewModifierGroup = ({
  args,
  modalWindow
}: {
  args: { menuId: number; data: ModifierGroup; callback: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { menuId, data, callback } = args
  const modifierGroupId = data.modifier_group_id

  const previewCallback = () => {
    dispatch(closeModal())
  }

  useEffect(() => {
    const params = { with_related: 'items,files' }
    dispatch(fetchModifierGroup({ modifierGroupId, params }))
  }, [dispatch, modifierGroupId])

  useEffect(() => {
    return () => {
      if (callback) callback()
    }
  }, [callback])

  return (
    <PreviewWrapper>
      <PreviewTitle as="p" size="xxxlarge">
        {data.full_name}
      </PreviewTitle>
      <PreviewView>
        <PreviewForm>
          <PreviewSectionTitle as="p" size="xlarge">
            Settings
          </PreviewSectionTitle>
          <MenuModifierGroupForm
            menuId={menuId}
            modifierGroupId={modifierGroupId}
            scrollWindow={modalWindow}
            callback={previewCallback}
          />
        </PreviewForm>
        <PreviewImages>
          <PreviewSectionTitle as="p" size="xlarge">
            Images
          </PreviewSectionTitle>
          <Files
            endpoint={`modifier-groups/${modifierGroupId}/files`}
            config={[
              { name: 'Raw Image', type: 'RAW' },
              { name: 'Small Image', type: 'SMALL_IMAGE' },
              { name: 'Large Image', type: 'LARGE_IMAGE' },
              { name: 'App Image', type: 'APP_IMAGE' }
            ]}
          />
        </PreviewImages>
      </PreviewView>
    </PreviewWrapper>
  )
}

export default PreviewModifierGroup
