import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { RevenueCenterSurcharges } from 'types'
import { showNotification } from 'slices/notification'

export interface RevenueCenterSurchargesState {
  data: RevenueCenterSurcharges
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterSurchargesState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRevenueCenterSurcharges = createAsyncThunk<
  RevenueCenterSurcharges,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterSurcharges/fetchRevenueCenterSurcharges',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/surcharges`
      const resp = (await api?.request(endpoint)) as RevenueCenterSurcharges
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createRevenueCenterSurcharges = createAsyncThunk<
  RevenueCenterSurcharges,
  { revenueCenterId: string | number; data: RevenueCenterSurcharges },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterSurcharges/createRevenueCenterSurcharges',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/surcharges`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as RevenueCenterSurcharges
      dispatch(showNotification('Surcharges successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateRevenueCenterSurcharges = createAsyncThunk<
  RevenueCenterSurcharges,
  { revenueCenterId: string | number; data: RevenueCenterSurcharges },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterSurcharges/updateRevenueCenterSurcharges',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/surcharges`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as RevenueCenterSurcharges
      dispatch(showNotification('Surcharges successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterSurcharges = createAsyncThunk<
  void,
  { revenueCenterId: string | number; data: RevenueCenterSurcharges },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterSurcharges/deleteRevenueCenterSurcharges',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/surcharges`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Surcharges successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterSurchargesSlice = createSlice({
  name: 'revenueCenterSurcharges',
  initialState,
  reducers: {
    resetRevenueCenterSurcharges: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterSurcharges.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchRevenueCenterSurcharges.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      fetchRevenueCenterSurcharges.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(createRevenueCenterSurcharges.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createRevenueCenterSurcharges.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      createRevenueCenterSurcharges.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateRevenueCenterSurcharges.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateRevenueCenterSurcharges.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      updateRevenueCenterSurcharges.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteRevenueCenterSurcharges.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterSurcharges.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      deleteRevenueCenterSurcharges.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetRevenueCenterSurcharges } =
  revenueCenterSurchargesSlice.actions

export const selectRevenueCenterSurcharges = (state: RootState) =>
  state.revenueCenterSurcharges

export default revenueCenterSurchargesSlice.reducer
