import { useEffect, useMemo, useState } from 'react'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { RequestStatus } from 'utils'
import { selectMenuModifierGroups } from 'slices/menu'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { closeModal } from 'slices/modal'
import { copyItems, selectItem } from 'slices/item'

interface ItemModifierGroupArgs {
  modifierGroupId: number
  itemIds: number[]
}

interface ItemModifierGroupProps {
  args: ItemModifierGroupArgs
  modalWindow: ModalWindow
}

interface CopyModifiersType {
  modifier_group_id: number
}

const CopyModifiers = ({ args, modalWindow }: ItemModifierGroupProps) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { modifierGroupId, itemIds } = args || {}
  const modifierGroups = useAppSelector(selectMenuModifierGroups)
  const modGroups = modifierGroups.modifierGroups
  const filteredGroups = modGroups.filter(
    i => i.modifier_group_id !== modifierGroupId
  )
  const { loading, error } = useAppSelector(selectItem)
  const isLoading =
    loading === RequestStatus.Pending ||
    modifierGroups.loading === RequestStatus.Pending

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  const modifierGroupOptions = useMemo(() => {
    return filteredGroups.map(i => {
      return {
        value: i.modifier_group_id,
        text: `${i.full_name} (${i.short_name})`
      }
    })
  }, [filteredGroups])

  const submit = (values: CopyModifiersType) => {
    setSubmitted(true)
    const modifierGroupId = values.modifier_group_id
    dispatch(copyItems({ itemIds, modifierGroupId }))
  }

  return (
    <>
      <ModalHeader
        title="Copy Modifiers"
        subtitle="Choose a modifier group to which you'd like to copy these modifiers."
      />
      <Form
        fields={copyModifiersFields(modifierGroupOptions)}
        data={copyModifiersEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </>
  )
}

const copyModifiersEmpty = {
  modifier_group_id: null
}

const copyModifiersFields = (
  modifierGroupOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'modifier_group_id',
    options: modifierGroupOptions,
    label: 'Modifier Group',
    error: 'Please select a modifier group'
  }
]

export default CopyModifiers
