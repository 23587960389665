import { useAppDispatch } from 'app/hooks'
import { useCallback } from 'react'
import { openModal } from 'slices/modal'
import { OnboardingBrand } from 'types'

const useOnboardingBrandsOptions = (fetchBrands: () => void) => {
  const dispatch = useAppDispatch()

  const archiveBrand = useCallback(
    (onboardingBrand: OnboardingBrand) => {
      const args = { onboardingBrand, fetchBrands }
      dispatch(openModal({ type: 'archiveBrand', align: 'center', args }))
    },
    [dispatch, fetchBrands]
  )

  const editBrand = useCallback(
    (onboardingBrand: OnboardingBrand) => {
      const args = { onboardingBrand, fetchBrands }
      dispatch(openModal({ type: 'editBrand', align: 'center', args }))
    },
    [dispatch, fetchBrands]
  )

  return {
    archiveBrand,
    editBrand
  }
}

export default useOnboardingBrandsOptions
