import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Announcements } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface AnnouncementsState {
  data: Announcements
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface AnnouncementsAPI {
  data: Announcements
  links: {
    next: string
  }
}

export type AnnouncementsParams = {
  cursor?: string
  limit?: number
  with_related?: string
  is_active?: boolean
}

const initialState: AnnouncementsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAnnouncements = createAsyncThunk<
  AnnouncementsAPI,
  AnnouncementsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'announcements/fetchAnnouncements',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `announcements${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as AnnouncementsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    resetAnnouncements: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAnnouncements.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAnnouncements.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchAnnouncements.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAnnouncements } = announcementsSlice.actions

export const selectAnnouncements = (state: RootState) => state.announcements

export default announcementsSlice.reducer
