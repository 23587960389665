import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ExternalAPI, RevenueCenters, RevenueCenterType } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface RevenueCentersState {
  data: RevenueCenters
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface RevenueCentersAPI {
  data: RevenueCenters
  links: {
    next: string
  }
}

export type RevenueCentersParams = {
  limit?: number
  expand?: string
  with_related?: string
  with_settings?: string
  revenue_center_type?: RevenueCenterType
  pos_external_api?: ExternalAPI
  store_id?: number
  store_group_id?: number
  region_id?: number
  closed?: boolean
  is_outpost?: boolean
}

const initialState: RevenueCentersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRevenueCenters = createAsyncThunk<
  RevenueCentersAPI,
  RevenueCentersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenters/fetchRevenueCenters',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `revenue-centers${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as RevenueCentersAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCentersSlice = createSlice({
  name: 'revenueCenters',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRevenueCenters.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchRevenueCenters.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectRevenueCenters = createSelector(
  (state: RootState) => {
    const { data, links, loading, error } = state.revenueCenters
    return { data, links, loading, error }
  },
  ({ data, links, loading, error }) => {
    const filtered = data.filter(i => !i.full_name.includes('Master'))
    return { data: filtered, links, loading, error }
  }
)

export const selectRevenueCentersByType =
  (revenueCenterType: RevenueCenterType) => (state: RootState) => {
    return state.revenueCenters.data.filter(
      i => i.revenue_center_type === revenueCenterType
    )
  }

export const selectRevenueCentersLookup = (state: RootState) => {
  const { data, loading, error } = state.revenueCenters
  if (!data.length) {
    return { lookup: null, loading, error }
  }
  const lookup = data.reduce(
    (obj, i) => ({ ...obj, [i.revenue_center_id]: i }),
    {}
  )
  return { lookup, loading, error }
}

export default revenueCentersSlice.reducer
