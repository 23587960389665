import styled from '@emotion/styled'

export const TooltipView = styled.span`
  display: block;
  padding-top: 0.3rem;
  cursor: pointer;
  position: relative;
  text-align: center;
  font-size: ${props => props.theme.labels.fontSize};
  font-weight: ${props => props.theme.inputs.fontWeight};

  &:hover .tooltip__content {
    visibility: visible;
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
`

export const TooltipContent = styled.div<{
  icon: React.ReactNode
  position: string | undefined
}>`
  position: absolute;
  z-index: 9;
  min-width: 30rem;
  width: 100%;
  ${props =>
    !props.icon || props.position === 'left' ? 'right:0;' : 'left:0;'}
  text-align: center;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  transform: translate3D(0, 1rem, 0);
  padding: ${props => props.theme.messages.padding};
  border-radius: ${props => props.theme.inputs.borderRadius};
  font-size: ${props => props.theme.sizes.small};
  line-height: ${props => props.theme.layout.desktop.lineHeight};
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.secondary};

  &:hover {
    visibility: visible;
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
`
