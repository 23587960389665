import styled from '@emotion/styled'

const PageColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${props => props.theme.layout.desktop.gap};

  & > div {
    flex: 1;
    min-width: 40rem;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      min-width: 100%;
    }
  }
`

export default PageColumns
