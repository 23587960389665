import { gql } from 'graphql-request'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  ONBOARDING_ALL_LOCATION_FRAGMENT,
  RequestError,
  RequestStatus
} from 'utils'
import { OnboardingAllLocation } from 'types'

export interface AllLocationsState {
  data: OnboardingAllLocation | null
  loading: RequestStatus
  error: RequestError
}

export interface AllLocationsAPI {
  onboardingAllLocations: OnboardingAllLocation
}

const initialState: AllLocationsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_ONBOARDING_ALL_LOCATIONS = gql`
  query UseOnboardingAllLocations(
    $filters: [JSON!]
    $page: Int
    $perPage: Int
    $order: String
    $orderBy: String
    $filterByArchiveStatus: Boolean
  ) {
    onboardingAllLocations(
      filters: $filters
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
      filterByArchiveStatus: $filterByArchiveStatus
    ) {
      page
      totalPageCount
      totalRecordsCount
      results {
        ...OnboardingAllLocationFragment
      }
    }
  }
  ${ONBOARDING_ALL_LOCATION_FRAGMENT}
`

interface ListFilterAllLocations {
  category: string | null
  end_date: string | null
  keyword: string | number | null
  logical_operator: string | null
  operator: string | null
  start_date: string | null
  type: string | null
}

export type AllLocationsParams = {
  searchTerm?: string
  page: number
  perPage: number
  brandIds?: string[]
  filters?: ListFilterAllLocations[]
  filterByArchiveStatus?: string | boolean
  order?: string
  orderBy?: string | null
}

export const fetchAllLocations = createAsyncThunk<
  AllLocationsAPI,
  AllLocationsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingAllLocations/fetchAllLocations',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_ONBOARDING_ALL_LOCATIONS,
        params
      )) as AllLocationsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingAllLocationsSlice = createSlice({
  name: 'onboardingAllLocations',
  initialState,
  reducers: {
    resetonboardingAllLocations: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAllLocations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAllLocations.fulfilled, (state, { payload }) => {
      state.data = payload.onboardingAllLocations
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchAllLocations.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetonboardingAllLocations } =
  onboardingAllLocationsSlice.actions

export const selectonboardingAllLocations = (state: RootState) =>
  state.onboardingAllLocations

export default onboardingAllLocationsSlice.reducer
