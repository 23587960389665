import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Customer } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'

export interface CustomerState {
  data: Customer | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CustomerState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const addCustomer = createAsyncThunk<
  Customer,
  { data: Customer },
  { state: RootState; rejectValue: RequestError }
>(
  'customer/addCustomer',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customers`
      const resp = (await api?.request(endpoint, 'POST', data)) as Customer
      dispatch(showNotification('Customer added successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCustomer = createAsyncThunk<
  Customer,
  { data: Customer; customerId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'customer/updateCustomer',
  async ({ data, customerId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customers/${customerId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Customer
      dispatch(showNotification('Customer updated successfully'))
      dispatch(fetchCustomer({ customerId }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchCustomer = createAsyncThunk<
  Customer,
  {
    customerId: string | number
    params?: { with_related?: boolean }
  },
  { state: RootState; rejectValue: RequestError }
>(
  'customer/fetchCustomer',
  async ({ customerId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `customers/${customerId}${queryParams}`
      const resp = (await api?.request(endpoint)) as Customer
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCustomerBlockedStatus = createAsyncThunk<
  Customer,
  { customerId: string | number; action: string },
  { state: RootState; rejectValue: RequestError }
>(
  'customer/updateBlockedStatus',
  async ({ customerId, action }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customers/${customerId}/${action}`
      const resp = (await api?.request(endpoint, 'POST', null)) as Customer
      dispatch(
        showNotification(
          action === 'block' ? 'Customer Blocked!' : 'Customer Unblocked'
        )
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer: () => initialState,
    setCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.data = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(addCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(addCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCustomerBlockedStatus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCustomerBlockedStatus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      if (state.data) state.data.is_blocked = !state.data?.is_blocked
      state.error = null
    })
    builder.addCase(
      updateCustomerBlockedStatus.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetCustomer, setCustomer } = customerSlice.actions

export const selectCustomer = (state: RootState) => state.customer

export default customerSlice.reducer
