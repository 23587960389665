import { BarLoader } from 'react-spinners'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import TextBody from './TextBody'
import { ThemeFontSizes } from 'types'

const LoaderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoaderText = styled(TextBody)`
  display: block;
  margin: 1rem 0 0;
  color: ${props => props.theme.colors.text.primary};
`

const Loader = ({
  text,
  width = 100,
  height = 2,
  size = 'small',
  style
}: {
  text?: string | null
  width?: number
  height?: number
  size?: keyof ThemeFontSizes
  style?: React.CSSProperties
}) => {
  const { colors } = useTheme()

  return (
    <LoaderView style={style}>
      <BarLoader width={width} height={height} color={colors.text.primary} />
      {text && <LoaderText size={size}>{text}</LoaderText>}
    </LoaderView>
  )
}

export default Loader
