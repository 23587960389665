import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'
import { HouseAccount } from 'types'
import { NavigateFunction } from 'react-router-dom'

export interface HouseAccountState {
  data: HouseAccount | null
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const addHouseAccount = createAsyncThunk<
  HouseAccount,
  { data: HouseAccount; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccount/addHouseAccount',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-accounts`
      const resp = (await api?.request(endpoint, 'POST', data)) as HouseAccount
      dispatch(showNotification('House Account added successfully'))
      const pathname = `/customers/house-accounts/${resp.house_account_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateHouseAccount = createAsyncThunk<
  HouseAccount,
  { data: HouseAccount; houseAccountId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccount/updateHouseAccount',
  async ({ data, houseAccountId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-accounts/${houseAccountId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as HouseAccount
      dispatch(showNotification('House Account updated successfully'))
      dispatch(fetchHouseAccount({ houseAccountId }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchHouseAccount = createAsyncThunk<
  HouseAccount,
  {
    houseAccountId: string | number
    params?: { with_related?: boolean }
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccount/fetchHouseAccount',
  async ({ houseAccountId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `house-accounts/${houseAccountId}${queryParams}`
      const resp = (await api?.request(endpoint)) as HouseAccount
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountSlice = createSlice({
  name: 'houseAccount',
  initialState,
  reducers: {
    resetHouseAccount: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addHouseAccount.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addHouseAccount.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addHouseAccount.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateHouseAccount.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateHouseAccount.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateHouseAccount.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchHouseAccount.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchHouseAccount.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchHouseAccount.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHouseAccount } = houseAccountSlice.actions

export const selectHouseAccount = (state: RootState) => state.houseAccount

export default houseAccountSlice.reducer
