import { ThemeFontSizes } from 'types'
import { CardDataItem } from './CardRows'
import { CardRowItem, CardRowValue, CardSubRowItem } from './Card.styled'

const CardRow = ({
  rowData,
  subRows,
  size
}: {
  rowData: CardDataItem
  subRows?: CardDataItem[]
  size?: keyof ThemeFontSizes
}) => {
  const subRowItems = subRows && subRows[0]

  return (
    <>
      <CardRowItem size={size} hasSubRows={subRowItems?.length ? true : false}>
        {Array.isArray(rowData) ? (
          rowData.map((columnValue, index) => {
            return (
              <CardRowValue
                align={
                  index === 0
                    ? 'left'
                    : index === rowData.length - 1
                      ? 'right'
                      : undefined
                }
                key={index}
              >
                {columnValue || ''}
              </CardRowValue>
            )
          })
        ) : (
          <CardRowValue>{rowData || ''}</CardRowValue>
        )}
      </CardRowItem>
      {subRowItems &&
        subRowItems.map((subRow, rowIndex) => (
          <CardSubRowItem
            key={rowIndex}
            size={size}
            showBorderBottom={rowIndex === subRowItems?.length - 1}
          >
            {Array.isArray(subRow) &&
              subRow.map((subColumnValue, subIndex) => (
                <CardRowValue
                  align={
                    subIndex === 0
                      ? 'left'
                      : subIndex === subRow.length - 1
                        ? 'right'
                        : undefined
                  }
                  key={subIndex}
                >
                  {subColumnValue || ''}
                </CardRowValue>
              ))}
          </CardSubRowItem>
        ))}
    </>
  )
}

export default CardRow
