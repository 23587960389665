import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { CurbsidePickup } from 'types'
import { showNotification } from 'slices/notification'

export interface CurbsidePickupState {
  data: CurbsidePickup | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CurbsidePickupState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCurbsidePickup = createAsyncThunk<
  CurbsidePickup | null,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'curbsidePickup/fetchCurbsidePickup',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `order-fulfillment-settings`
      )) as CurbsidePickup

      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertCurbsidePickup = createAsyncThunk<
  CurbsidePickup,
  { data: CurbsidePickup },
  { state: RootState; rejectValue: RequestError }
>(
  'curbsidePickup/upsertCurbsidePickup',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `order-fulfillment-settings`,
        'PUT',
        data
      )) as CurbsidePickup
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const curbsidePickupSlice = createSlice({
  name: 'CurbsidePickup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCurbsidePickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCurbsidePickup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchCurbsidePickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertCurbsidePickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertCurbsidePickup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertCurbsidePickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectCurbsidePickup = (state: RootState) => state.curbsidePickup

export default curbsidePickupSlice.reducer
