import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Discount, Discounts } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface DiscountsState {
  data: Discounts
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface DiscountsAPI {
  data: Discounts
  links: Record<string, string>
}

const initialState: DiscountsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type DiscountFilterParams = {
  [key: string]: any
}

export type DiscountsParams = {
  limit?: number
  cursor?: string
  with_related?: string
  is_active?: boolean
  search?: string
  is_reward?: boolean
  is_deal?: boolean
  is_promo_code?: boolean
}

export const fetchDiscounts = createAsyncThunk<
  DiscountsAPI,
  DiscountsParams | void,
  { state: RootState; rejectValue: RequestError }
>('discounts/fetchDiscounts', async (params, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `discounts${queryParams}`
    return (await api?.request(endpoint)) as DiscountsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    resetDiscounts: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchDiscounts.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchDiscounts.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchDiscounts.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetDiscounts } = discountsSlice.actions

export const selectDiscounts = (state: RootState) => state.discounts

export const selectDiscountOptions = createSelector(
  (state: RootState) => {
    return state.discounts.data
  },
  discounts => {
    return discounts.map(i => ({
      value: i.discount_id?.toString(),
      text: `${i.name} (${i.discount_id})`
    }))
  }
)

export const selectDiscountsByType =
  (type: 'PROMO_CODE' | 'DEAL' | 'REWARD') => (state: RootState) => {
    let filteredData: Discount[] = []

    switch (type) {
      case 'PROMO_CODE':
        filteredData = state.discounts.data.filter(
          discount => discount.is_promo_code === true
        )
        break
      case 'DEAL':
        filteredData = state.discounts.data.filter(
          discount => discount.is_deal === true
        )
        break
      case 'REWARD':
        filteredData = state.discounts.data.filter(
          discount => discount.is_reward === true
        )
        break
      default:
        break
    }

    return {
      data: filteredData,
      loading: state.discounts.loading,
      error: state.discounts.error
    }
  }

export default discountsSlice.reducer
