import styled from '@emotion/styled'
import {
  CheckCircle,
  XmarkCircle,
  IconoirProvider,
  InfoCircle,
  WarningCircle
} from 'iconoir-react'
import { AlertType, ThemeElementColors, ThemeFontSizes } from 'types'
import React from 'react'

const AlertView = styled.div<{ color: keyof ThemeElementColors }>`
  display: flex;
  align-items: stretch;
  min-height: 3.6rem;
  margin: 2rem 0;
  border-radius: ${props => props.theme.borderRadius.small};
  overflow: hidden;
`

const AlertIcon = styled.div<{ color: keyof ThemeElementColors }>`
  flex: 0 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors[props.color].background};
  color: ${props => props.theme.colors[props.color].color};
  filter: brightness(85%);
`

const AlertMsg = styled.div<{ color: keyof ThemeElementColors }>`
  display: block;
  padding: 1rem 1.5rem;
  flex: 1 1 auto;
  background-color: ${props => props.theme.colors[props.color].background};
`

const AlertMsgText = styled.p<{
  color: keyof ThemeElementColors
  size: keyof ThemeFontSizes
}>`
  color: ${props => props.theme.colors[props.color].color};
  font-size: ${props => props.theme.sizes[props.size]};
  line-height: 1.4;
  font-weight: 530;
  font-variation-settings: 'wght' 530;
`

const iconMap: Record<AlertType, JSX.Element> = {
  INFO: <InfoCircle />,
  WARNING: <WarningCircle />,
  ERROR: <XmarkCircle />,
  SUCCESS: <CheckCircle />
}

const colorMap: Record<AlertType, keyof ThemeElementColors> = {
  INFO: 'info',
  WARNING: 'alert',
  ERROR: 'error',
  SUCCESS: 'success'
}

const Alert = ({
  children,
  alertType = 'INFO',
  size = 'small'
}: {
  children: React.ReactNode
  alertType?: AlertType
  size?: keyof ThemeFontSizes
}) => {
  return (
    <AlertView color={colorMap[alertType]}>
      <IconoirProvider
        iconProps={{
          width: 18,
          height: 18,
          strokeWidth: 1.5
        }}
      >
        <AlertIcon color={colorMap[alertType]}>{iconMap[alertType]}</AlertIcon>
      </IconoirProvider>
      <AlertMsg color={colorMap[alertType]}>
        <AlertMsgText size={size} color={colorMap[alertType]}>
          {children}
        </AlertMsgText>
      </AlertMsg>
    </AlertView>
  )
}

export default Alert
