export const downloadTXT = (payload: string, filename: string) => {
  const file = new Blob([payload], { type: 'text/plain' })
  const fileUrl = URL.createObjectURL(file)

  const link = document.createElement('a')
  link.href = fileUrl
  link.download = filename
  link.style.display = 'none'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  URL.revokeObjectURL(fileUrl)
}

// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
export const downloadPDF = (blob: Blob, fileType: string, filename: string) => {
  const newBlob = new Blob([blob], { type: fileType })
  const fileUrl = URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = fileUrl
  link.download = filename
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // URL.revokeObjectURL(fileUrl)
  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => URL.revokeObjectURL(fileUrl), 100)
  return
}
