import { PlusCircle } from 'iconoir-react'
import Button from './Button'
import { ThemeButtonColors, ThemeButtonSizes } from 'types'
import { makeIconSize } from 'utils'

const ButtonNew = ({
  children,
  onClick,
  disabled,
  color = 'primary',
  size = 'medium'
}: {
  children: React.ReactNode
  onClick: () => void
  disabled?: boolean
  size?: keyof ThemeButtonSizes
  color?: keyof ThemeButtonColors
}) => {
  const iconSize = makeIconSize(size)

  return (
    <Button color={color} size={size} onClick={onClick} disabled={disabled}>
      <span className="icon" style={{ marginLeft: -3 }}>
        <PlusCircle width={iconSize} height={iconSize} />
      </span>
      <span>{children}</span>
    </Button>
  )
}

export default ButtonNew
