import { ButtonSubmit } from 'components'
import { useEffect, useState } from 'react'
import {
  Categories,
  Category,
  Item,
  RecommendedItems,
  RecommendedType
} from 'types'
import MenuCheckboxesOptions from './MenuCheckboxesOptions'
import {
  MenuCheckboxesSection,
  MenuCheckboxesView
} from './MenuCheckboxes.styled'

const MenuCheckboxes = ({
  upsellList,
  similarList,
  categories,
  submit
}: {
  upsellList: RecommendedItems | null
  similarList: RecommendedItems | null
  categories: Categories
  submit: (
    upsells: RecommendedItems | null,
    similars: RecommendedItems | null
  ) => void
}) => {
  const [upsells, setUpsells] = useState<RecommendedItems | null>(null)
  const [similars, setSimilars] = useState<RecommendedItems | null>(null)
  const [selectedUpsells, setSelectedUpsells] = useState<number[] | null>(null)
  const [selectedSimilars, setSelectedSimilars] = useState<number[] | null>(
    null
  )

  useEffect(() => {
    if (!upsellList && !similarList) return

    setUpsells(upsellList)
    setSimilars(similarList)
  }, [upsellList, similarList])

  useEffect(() => {
    if (!upsells && !similars) return
    const selectedUpsells = upsells ? upsells.map(i => i.item_id) : []
    const selectedSimilars = similars ? similars.map(i => i.item_id) : []

    setSelectedUpsells(selectedUpsells)
    setSelectedSimilars(selectedSimilars)
  }, [similars, upsells])

  const updateSimilars = (item: Item, categoryId: number) => {
    const value = {
      category_id: categoryId,
      display_order: similars ? similars.length + 3 : 1,
      item_id: item.item_id,
      recommendation_type: 'SIMILAR' as RecommendedType
    }

    const isChecked = selectedSimilars?.includes(item.item_id)
    let newValues
    if (similars) {
      newValues = isChecked
        ? similars.filter(i => i.item_id !== item.item_id)
        : [...similars, value]
    } else newValues = [value]
    setSimilars(newValues)
  }

  const updateUpsells = (item: Item, categoryId: number) => {
    const value = {
      category_id: categoryId,
      display_order: upsells ? upsells.length + 3 : 1,
      item_id: item.item_id,
      recommendation_type: 'UPSELL' as RecommendedType
    }

    const isChecked = selectedUpsells?.includes(item.item_id)
    let newValues
    if (upsells) {
      newValues = isChecked
        ? upsells.filter(i => i.item_id !== item.item_id)
        : [...upsells, value]
    } else newValues = [value]
    setUpsells(newValues)
  }

  const selectMenuCategory = (category: Category, type: RecommendedType) => {
    if (!category || !category.items) return
    let updated
    if (type === 'UPSELL') {
      const allItemsSelected = category.items.every(item =>
        selectedUpsells?.includes(item.item.item_id)
      )

      if (allItemsSelected) {
        updated = upsells?.filter(
          upsell =>
            !category.items?.some(item => item.item.item_id === upsell.item_id)
        )
      } else {
        const newValues = category.items.map(item => {
          return {
            category_id: category.category_id,
            display_order: upsells ? upsells.length + 3 : 1,
            item_id: item.item.item_id,
            recommendation_type: type
          }
        })

        updated = upsells ? [...upsells, ...newValues] : newValues
      }
    } else if (type === 'SIMILAR') {
      const allItemsSelected = category.items.every(item =>
        selectedSimilars?.includes(item.item.item_id)
      )

      if (allItemsSelected) {
        updated = similars?.filter(
          similar =>
            !category.items?.some(item => item.item.item_id === similar.item_id)
        )
      } else {
        const newValues = category.items.map(item => {
          return {
            category_id: category.category_id,
            display_order: similars ? similars.length + 3 : 1,
            item_id: item.item.item_id,
            recommendation_type: type
          }
        })

        updated = similars ? [...similars, ...newValues] : newValues
      }
    }

    if (updated && type === 'UPSELL') setUpsells(updated)
    else if (updated && type === 'SIMILAR') setSimilars(updated)
  }

  return (
    <>
      <MenuCheckboxesView>
        <MenuCheckboxesSection>
          <MenuCheckboxesOptions
            title="Upsell Items"
            type="UPSELL"
            categories={categories}
            selectedIds={selectedUpsells}
            selectCategory={selectMenuCategory}
            updateValues={updateUpsells}
          />
        </MenuCheckboxesSection>
        <MenuCheckboxesSection>
          <MenuCheckboxesOptions
            title="Similar Items"
            type="SIMILAR"
            categories={categories}
            selectedIds={selectedSimilars}
            selectCategory={selectMenuCategory}
            updateValues={updateSimilars}
          />
        </MenuCheckboxesSection>
      </MenuCheckboxesView>
      <ButtonSubmit
        text="Submit"
        color="primary"
        onClick={() => submit(upsells, similars)}
      />
    </>
  )
}

export default MenuCheckboxes
