import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { BuiltMenu, RequestedAt, ServiceType } from 'types'

export interface BuiltMenuState {
  data: BuiltMenu | null
  loading: RequestStatus
  error: RequestError
}

const initialState: BuiltMenuState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBuiltMenu = createAsyncThunk<
  BuiltMenu | null,
  {
    revenueCenterId: number | string
    serviceType: ServiceType
    requestedAt: RequestedAt
  },
  { state: RootState; rejectValue: RequestError }
>('BuiltMenu/fetchBuiltMenu', async (params, { getState, rejectWithValue }) => {
  try {
    const {
      revenueCenterId: revenue_center_id,
      serviceType: service_type,
      requestedAt
    } = params
    const requested_at =
      requestedAt === 'asap' ? new Date().toISOString() : requestedAt
    const adjusted = { revenue_center_id, service_type, requested_at }
    const queryParams = makeQueryParams(adjusted)
    const api = getState().authUser.api
    return (await api?.request(`built-menus${queryParams}`)) as BuiltMenu
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const builtMenuSlice = createSlice({
  name: 'builtMenu',
  initialState,
  reducers: {
    resetBuiltMenu: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBuiltMenu.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBuiltMenu.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBuiltMenu.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBuiltMenu } = builtMenuSlice.actions

export const selectBuiltMenu = (state: RootState) => state.builtMenu

export default builtMenuSlice.reducer
