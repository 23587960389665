import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BlockedPhones } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface BlockedPhonesState {
  data: BlockedPhones
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface BlockedPhonesAPI {
  data: BlockedPhones
  links: {
    next: string
  }
}

export type BlockedPhonesParams = {
  limit?: number
  cursor?: string
  with_related?: string
  customer_id?: string
}

const initialState: BlockedPhonesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBlockedPhones = createAsyncThunk<
  BlockedPhonesAPI,
  BlockedPhonesParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedPhones/fetchBlockedPhones',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `blocked-phones${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as BlockedPhonesAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const blockedPhonesSlice = createSlice({
  name: 'blockedPhones',
  initialState,
  reducers: {
    resetBlockedPhones: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBlockedPhones.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBlockedPhones.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBlockedPhones.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBlockedPhones } = blockedPhonesSlice.actions

export const selectBlockedPhones = (state: RootState) => state.blockedPhones

export default blockedPhonesSlice.reducer
