import Radio from './Radio'
import { RadioGroupOptionInterface } from 'types'
import { RadioGroupContainer } from './Radio.styled'
import { Label } from '..'

interface RadioGroupProps {
  options: RadioGroupOptionInterface[]
  name: string
  label?: string
  value: string
  variant?: 'bordered' | 'default'
  button?: 'hidden' | 'default'
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioGroup = ({
  options,
  name,
  label,
  value,
  variant = 'default',
  button = 'default',
  onChange
}: RadioGroupProps) => {
  return (
    <Label label={label}>
      <RadioGroupContainer>
        {options.map(option => (
          <Radio
            key={option.value}
            label={option.text}
            name={name}
            value={option.value}
            checked={option.value === value}
            variant={variant}
            onChange={onChange}
            button={button}
            content={option.content}
          />
        ))}
      </RadioGroupContainer>
    </Label>
  )
}

export default RadioGroup
