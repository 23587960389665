import { IacmStatusView } from 'components/IacmRadioButtons/IacmRadio.styled'
import IacmRadioButton from 'components/IacmRadioButtons/IacmRadioButton'
import { archiveStatusOptions } from 'config'

const IacmStatusFilter = ({
  archiveStatus,
  changeStatus
}: {
  archiveStatus: string
  changeStatus: (status: string) => void
}) => {
  return (
    <IacmStatusView>
      {archiveStatusOptions.map(i => (
        <IacmRadioButton
          key={i.value}
          label={i.text}
          status={i.value}
          name="status"
          archiveStatus={archiveStatus}
          setArchiveStatus={changeStatus}
        />
      ))}
    </IacmStatusView>
  )
}

export default IacmStatusFilter
