import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { HouseAccountCustomer, HouseAccountCustomers } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface HouseAccountCustomerState {
  data: HouseAccountCustomer | null
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountCustomerState = {
  data: null,
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface HouseAccountCustomerAPI {
  data: HouseAccountCustomer
  links: {
    next: string
  }
}

export const addHouseAccountCustomer = createAsyncThunk<
  HouseAccountCustomer,
  {
    data: [{ customer_id: number; is_owner: boolean }]
    houseAccountId: number | string
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountCustomer/addHouseAccountCustomer',
  async ({ data, houseAccountId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `house-accounts/${houseAccountId}/customers`,
        'POST',
        data
      )) as HouseAccountCustomer
      dispatch(showNotification('User successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateHouseAccountCustomerOwnership = createAsyncThunk<
  HouseAccountCustomers,
  {
    data: [{ customer_id: number; is_owner: boolean }]
    houseAccountId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountCustomer/updateHouseAccountCustomers',
  async ({ data, houseAccountId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-accounts/${houseAccountId}/customers`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as HouseAccountCustomers
      dispatch(
        showNotification(
          'House Account Customer Ownership updated successfully'
        )
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteHouseAccountCustomer = createAsyncThunk<
  void,
  {
    houseAccountId: number | string
    data: [{ customer_id: number }]
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountCustomer/deleteHouseAccountCustomer',
  async ({ houseAccountId, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `house-accounts/${houseAccountId}/customers`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('House Account customer removed.'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountCustomerSlice = createSlice({
  name: 'houseAccountCustomer',
  initialState,
  reducers: {
    resetHouseAccountCustomer: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addHouseAccountCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addHouseAccountCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addHouseAccountCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateHouseAccountCustomerOwnership.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateHouseAccountCustomerOwnership.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      updateHouseAccountCustomerOwnership.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteHouseAccountCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteHouseAccountCustomer.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(
      deleteHouseAccountCustomer.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetHouseAccountCustomer } = houseAccountCustomerSlice.actions

export const selectHouseAccountCustomer = (state: RootState) =>
  state.houseAccountCustomer

export default houseAccountCustomerSlice.reducer
