import { Calendar } from 'iconoir-react'
import { ButtonNew, QuickLinks } from 'components'
import { convertTimestamp } from 'utils'
import {
  ContentCardQuicklinkSection,
  ContentCardFooterContainer
} from './ContentCard.styled'

interface ContentCardFooterProps {
  isComplete?: boolean
  isDisabled?: boolean
  lastUpdated?: string
  isSelected?: boolean
  isLoading?: boolean
  title: string
  onClick: () => void
  linksActions?: {
    icon: React.ReactNode
    text: string
    callback: React.MouseEventHandler<HTMLButtonElement>
  }[]
}

const ContentCardFooter = ({
  isComplete,
  isDisabled,
  lastUpdated,
  isSelected,
  isLoading,
  title,
  onClick,
  linksActions
}: ContentCardFooterProps) => {
  return (
    <ContentCardFooterContainer>
      {!isComplete && !isDisabled && (
        <ButtonNew onClick={onClick} disabled={isLoading}>
          Create Content
        </ButtonNew>
      )}

      {lastUpdated && (
        <>
          <Calendar fontSize={12} />
          {convertTimestamp(lastUpdated)}
        </>
      )}

      <ContentCardQuicklinkSection>
        {isSelected && linksActions?.length && (
          <QuickLinks id={title} actions={linksActions} />
        )}
      </ContentCardQuicklinkSection>
    </ContentCardFooterContainer>
  )
}

export default ContentCardFooter
