import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import { OrderTypeOld, ServiceType } from 'types'

export const OrderCardView = styled.div<{
  isOverdue: boolean
  isClosed: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${props =>
    props.isOverdue
      ? props.theme.colors.error.background
      : props.isClosed
        ? props.theme.colors.background.primary
        : props.theme.colors.background.tertiary};
`

export const OrderCardBackground = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  background-color: ${props => props.theme.colors.error.background};
`

export const OrderCardBanner = styled.div<{
  orderType: OrderTypeOld
  needsReview: boolean
}>`
  position: relative;
  z-index: 2;
  height: 0.7rem;
  background-color: ${props =>
    props.needsReview
      ? props.theme.colors.error.background
      : props.orderType === 'CATERING'
        ? props.theme.colors.info.background
        : props.theme.colors.text.secondary};
`

export const OrderCardContainer = styled.div<{
  isOverdue: boolean
  isClosed: boolean
  needsReview: boolean
}>`
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.4rem 1.2rem 1.2rem;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props =>
    props.needsReview
      ? props.theme.colors.error.background
      : props.isOverdue
        ? props.theme.colors.error.background
        : props.theme.colors.border.secondary};
  border-top: 0;
  color: ${props =>
    props.isOverdue
      ? props.theme.colors.background.primary
      : props.theme.colors.text.primary};

  a,
  span {
    ${props =>
      props.isOverdue ? `color: ${props.theme.colors.background.primary}` : ''}
  }

  button {
    ${props =>
      props.isOverdue
        ? `background-color: ${props.theme.colors.error.background} !important;`
        : ''}
  }

  button:enabled:hover {
    ${props => (props.isOverdue ? `filter: brightness(85%) !important;` : '')}
  }
`

export const OrderCardHeader = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.2rem;
`

export const OrderCardContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`

export const OrderCardFooter = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

export const OrderCardTitle = styled.span<{
  isOverdue: boolean
  needsReview: boolean
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  a {
    color: ${props =>
      props.isOverdue
        ? props.theme.colors.background.primary
        : props.needsReview
          ? props.theme.colors.error.background
          : props.theme.colors.text.primary};
  }

  a:hover {
    color: ${props => props.theme.colors.accent.primary};
  }
`

export const OrderCardOverdue = styled(TextBody)<{ needsReview?: boolean }>`
  display: inline-block;
  padding: 0.1rem 0 0;
  line-height: 1;
  text-transform: uppercase;
  color: ${props =>
    props.needsReview
      ? props.theme.colors.error.background
      : props.theme.colors.error.color};
`

export const OrderCardAlert = styled.span`
  display: inline-block;
  line-height: 0;
  color: ${props => props.theme.colors.error.background};
`

export const OrderCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.5rem 0 0;
`

export const OrderCardGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const OrderCardCustomer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
`

export const OrderCardOrderType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.8rem;
  // margin: -0.3rem 0 0;
`

export const OrderCardOrderTypeName = styled.span<{ orderType: OrderTypeOld }>`
  color: ${props =>
    props.orderType === 'CATERING'
      ? props.theme.colors.info.background
      : 'inhert'};
`

export const OrderCardServiceTypeName = styled.span<{
  serviceType: ServiceType
}>`
  color: ${props =>
    props.serviceType === 'DELIVERY'
      ? props.theme.colors.error.background
      : 'inhert'};
`

export const OrderCardStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  & > span:first-of-type {
    position: relative;
    top: 0.1rem;
  }
`

export const OrderCardHighlight = styled.span`
  & > span {
    padding: 0.4rem 0.5rem 0.4rem;
    margin: -0.4rem 0 -0.4rem;
  }
`

export const OrderCardAddressView = styled.div`
  margin: 0.5rem 0 0;
`

export const OrderCardAddressLine = styled(TextBody)`
  display: block;
  margin: 0.7rem 0 0;
  font-size: ${props => props.theme.sizes.small};
  color: ${props => props.theme.colors.text.secondary};

  &:first-of-type {
    margin: 0;
  }
`

export const OrderCardTaxExempt = styled(TextBody)`
  color: ${props => props.theme.colors.error.background};
`

export const OrderCardTenders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.5rem;
`

export const OrderCardTenderView = styled.div``

export const OrderCardButtonsView = styled.div`
  position: relative;
  width: 100%;
  margin: 2rem 0 0;
`

export const OrderCardButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.layout.desktop.border};
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.secondary};
  background-color: ${props => props.theme.colors.border.secondary};

  button {
    position: relative;
    padding: 0.6rem 0;
    border: 0;
    border-radius: 0;
    transition: all 0.15s ease;
    background-color: ${props => props.theme.colors.background.secondary};
  }

  button:disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.background.primary};

    span {
      opacity: 0.25;
    }
  }

  button > span {
    transition: all 0.15s ease;
  }

  button:enabled:hover {
    // background-color: ${props => props.theme.colors.background.tertiary};
    filter: brightness(100%);
  }

  button:enabled:nth-of-type(1):hover {
    background-color: ${props => props.theme.colors.success.background};

    & > span {
      color: ${props => props.theme.colors.background.primary};
    }
  }

  button:enabled:nth-of-type(3):hover {
    background-color: ${props => props.theme.colors.error.background};

    & > span {
      color: ${props => props.theme.colors.background.primary};
    }
  }
`

export const OrderCardButtonsOverlay = styled.div`
  position: absolute;
  z-index: 2;
  inset: 0;
  background-color: ${props => props.theme.colors.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
`

export const OrderCardButtonsWorking = styled.div`
  position: absolute;
  z-index: 2;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
