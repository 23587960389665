import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectApi } from 'slices/authUser'
import { showNotification } from 'slices/notification'
import { RequestError } from 'utils/api'
import { Button, ButtonGroup, ErrMsg, Page, PageHeader } from 'components'

const CheckmateButtons = styled.div`
  width: 100%;
  max-width: 48rem;
  margin: 3rem 0;
`

const CheckmateShortName = styled.span`
  color: ${props => props.theme.colors.text.primary};
`

const CheckmateError = styled.div`
  width: 100%;
  max-width: 48rem;
`

const Checkmate = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const code = query.get('code')
  const api = useAppSelector(selectApi)
  const [revenueCenters, setRevenueCenters] = useState<any[]>([])
  const [revenueCenterId, setRevenueCenterId] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [error, setError] = useState<RequestError>(null)

  useEffect(() => {
    if (!code) navigate('/dashboard')
  }, [navigate, code])

  useEffect(() => {
    if (isAuthorized) {
      dispatch(showNotification('Successfully authorized!'))
      navigate('/dashboard')
    }
  }, [dispatch, navigate, isAuthorized])

  useEffect(() => {
    let isCancelled = false
    const fetchRevenueCenters = async () => {
      try {
        const endpoint = 'revenue-centers?revenue_center_type=OLO'
        const response = (await api?.request(endpoint)) as any
        if (!isCancelled) setRevenueCenters(response.data)
      } catch (err) {
        if (!isCancelled) {
          setError(err as RequestError)
        }
      }
    }
    if (code) fetchRevenueCenters()
    return () => {
      isCancelled = true
    }
  }, [api, code])

  useEffect(() => {
    let isCancelled = false
    const authRevenueCenter = async () => {
      try {
        const endpoint = 'integrations/checkmate/oauth'
        const data = { code, revenue_center_id: revenueCenterId }
        await api?.request(endpoint, 'POST', data)
        if (!isCancelled) setIsAuthorized(true)
      } catch (err) {
        if (!isCancelled) {
          setError(err as RequestError)
        }
      }
    }
    if (code && revenueCenterId) authRevenueCenter()
    return () => {
      isCancelled = true
    }
  }, [api, code, revenueCenterId])

  return (
    <Page>
      <PageHeader
        title="Checkmate Authorization"
        subtitle="Please choose a location to authorize"
      />
      {error ? (
        <CheckmateError>
          <ErrMsg errMsg={error.detail} />
          <Button onClick={() => setError(null)} size="medium" color="primary">
            Try Again
          </Button>
        </CheckmateError>
      ) : (
        <CheckmateButtons>
          <ButtonGroup>
            {revenueCenters.map(revenueCenter => {
              const { full_name, revenue_center_id, short_name } = revenueCenter
              return (
                <button
                  key={revenue_center_id}
                  onClick={() => setRevenueCenterId(revenue_center_id)}
                >
                  <span>
                    {full_name}{' '}
                    <CheckmateShortName>({short_name})</CheckmateShortName>
                  </span>
                  <span>{revenue_center_id}</span>
                </button>
              )
            })}
          </ButtonGroup>
        </CheckmateButtons>
      )}
    </Page>
  )
}

export default Checkmate
