import { ModalContent } from 'components/Modal'
import Loader from 'components/Loader'

const Working = ({ args }: { args: { msg: string } }) => {
  return (
    <>
      <ModalContent>
        <Loader text={args.msg} />
      </ModalContent>
    </>
  )
}

export default Working
