import { useEffect, useState } from 'react'
import { formatDollars } from '@open-tender/utils'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { GiftCard, ModalWindow } from 'types'
import { RequestStatus, makeErrMsg, makePreparedOrder } from 'utils'
import { closeModal } from 'slices/modal'
import {
  GiftCardsParams,
  fetchGiftCards,
  resetGiftCards,
  selectGiftCards
} from 'slices/giftCards'
import { resetGiftCard, selectGiftCard, updateGiftCard } from 'slices/giftCard'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { Button, CardRows, ErrMsg, Input, TextStatus } from 'components'
import { selectOrder, validateOrder } from 'slices/order'

const makeGiftCardData = (giftCard: GiftCard | null) => {
  if (!giftCard) return []
  const { is_active, card_number, balance, expiration, customer_id } = giftCard
  return [
    ['Card Number', card_number],
    [
      'Status',
      <TextStatus isActive={is_active}>
        {is_active ? 'Active' : 'Inactive'}
      </TextStatus>
    ],
    ['Current Balance', formatDollars(balance)],
    ['Expiration', expiration || 'no expiration'],
    ['Customer', customer_id || 'not assigned']
  ]
}

const OrderGiftCard = ({
  args,
  modalWindow
}: {
  args: {
    customerId: number
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { customerId } = args
  const [search, setSearch] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const order = useAppSelector(selectOrder)
  const preparedOrder = makePreparedOrder(order)
  const { data, loading, error } = useAppSelector(selectGiftCards)
  const isLoading = loading === RequestStatus.Pending
  const giftCard = data.length > 0 ? data[0] : null
  const gc = useAppSelector(selectGiftCard)
  const isAdding = gc.loading === RequestStatus.Pending
  const giftCardRows = makeGiftCardData(giftCard)
  const errMsg = makeErrMsg(error || gc.error)

  const find = () => {
    if (!search) return
    const params: GiftCardsParams = { search }
    dispatch(fetchGiftCards(params))
  }

  const add = () => {
    if (!giftCard) return
    const giftCardId = giftCard.gift_card_id
    const { employee_id, expiration, is_active, pin, store_id } = giftCard
    const values = {
      customer_id: customerId,
      employee_id,
      expiration,
      is_active,
      pin,
      store_id
    }
    dispatch(updateGiftCard({ giftCardId, data: values }))
    setSubmitting(true)
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value)
  }

  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') find()
  }

  useEffect(() => {
    if (submitting && !isAdding) {
      setSubmitting(false)
      if (!gc.error) {
        if (preparedOrder) dispatch(validateOrder(preparedOrder))
        dispatch(closeModal())
      }
    }
  }, [submitting, isAdding, gc.error, preparedOrder, dispatch])

  useEffect(() => {
    dispatch(resetGiftCard())
    dispatch(resetGiftCards())
    return () => {
      dispatch(resetGiftCard())
      dispatch(resetGiftCards())
    }
  }, [dispatch])

  return (
    <>
      <ModalHeader title="Enter gift card number" />
      <ErrMsg errMsg={errMsg} />
      {giftCard ? (
        <>
          <CardRows data={giftCardRows} />
          <ModalFooter>
            <Button onClick={add} disabled={isAdding}>
              Assign Gift Card to Customer
            </Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <Input
            type="text"
            name="search"
            label=""
            value={search}
            onChange={onChange}
            onKeyDown={onKeyPress}
            placeholder="enter full gift card number"
          />
          <Button onClick={find} disabled={isLoading || !search}>
            Find Gift Card
          </Button>
        </>
      )}
    </>
  )
}

export default OrderGiftCard
