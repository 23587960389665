import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { PosTerminal } from 'types'
import { showNotification } from 'slices/notification'
import { fetchPosTerminals } from 'slices/posTerminals'

export interface PosTerminalState {
  data: PosTerminal | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PosTerminalState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type PosTerminalParams = {
  expand?: string
  with_related?: string
}

export const fetchPosTerminal = createAsyncThunk<
  PosTerminal | null,
  { posTerminalId: string | number; params?: PosTerminalParams },
  { state: RootState; rejectValue: RequestError }
>(
  'posTerminal/fetchPosTerminal',
  async ({ posTerminalId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/pos-terminals/${posTerminalId}${queryParams}`
      )) as PosTerminal
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createPosTerminal = createAsyncThunk<
  PosTerminal,
  { data: PosTerminal },
  { state: RootState; rejectValue: RequestError }
>(
  'posTerminal/createPosTerminal',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/pos-terminals',
        'POST',
        data
      )) as PosTerminal
      dispatch(showNotification('Kiosk terminal successfully created!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPosTerminals({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePosTerminal = createAsyncThunk<
  PosTerminal,
  { posTerminalId: string | number; data: PosTerminal },
  { state: RootState; rejectValue: RequestError }
>(
  'posTerminal/updatePosTerminal',
  async ({ posTerminalId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/pos-terminals/${posTerminalId}`,
        'PUT',
        data
      )) as PosTerminal
      dispatch(showNotification('Kiosk terminal successfully updated!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPosTerminals({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posTerminalSlice = createSlice({
  name: 'posTerminal',
  initialState,
  reducers: {
    resetPosTerminal: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPosTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPosTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchPosTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createPosTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createPosTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createPosTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePosTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updatePosTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updatePosTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPosTerminal } = posTerminalSlice.actions

export const selectPosTerminal = (state: RootState) => state.posTerminal

export default posTerminalSlice.reducer
