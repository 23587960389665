import OnboardingStep from './OnboardingStep'

const content = [
  {
    index: 1,
    content: (
      <OnboardingStep
        src="https://www.youtube.com/embed/ZegZtqDdhn4?si=LiLyYqHXXnfHnhiT"
        title="Let's Get Started"
      >
        <p>
          Well, hello there! We are thrilled that you have chosen Checkmate to
          improve your online ordering business with greater accuracy, better
          turnaround times, and of course, a heck of a lot less stress for you
          and your hardworking staff. Let's get started!
        </p>
        <p>This is your first step in our quick and easy onboarding process.</p>
        <div>
          We'll begin by collecting the following:
          <ul>
            <li>Your Brand</li>
            <li>Your Location(s)</li>
            <li>Your Onboarding Form & Checklist</li>
          </ul>
        </div>
        <p>
          Be sure to check out the helpful video tutorials and guides to make
          the process as smooth as possible. Get started with the video below!
        </p>
      </OnboardingStep>
    )
  },
  {
    index: 2,
    content: (
      <OnboardingStep
        src="https://www.youtube.com/embed/QLynna1ZSWA?si=i5sxO62T1-ynff1z"
        title="Add Your Brand"
      >
        <p>
          Its time to create your brand. Whether you're a stand-alone business,
          a corporation, or a franchisee, we've got you covered.
        </p>
        <p>
          <strong>Note:</strong> If you are a stand-alone (non-corporate)
          restaurant, your brand is simply your restaurant name.
        </p>
        <p>
          If your restaurant is part of a chain or corporation, please list the
          corporation name as your brand.
        </p>
        <p>Click on the video below for more guidance.</p>
      </OnboardingStep>
    )
  },
  {
    index: 3,
    content: (
      <OnboardingStep
        src="https://www.youtube.com/embed/7uF-maK4QH0?si=wkrT-ovXuFXmqfBm"
        title="Add Your Location(s)"
      >
        <p>
          Its time to add your location info. If you have more than one location
          to onboard, no problem--we'll take care of that here.
        </p>
        <p>
          You'll be prompted to create a <strong>Unique Location ID</strong> to
          identify each location. This is usually your town/city or street +
          town/city.
        </p>
        <p>Check out the video below for a quick overview.</p>
      </OnboardingStep>
    )
  },
  {
    index: 4,
    content: (
      <OnboardingStep
        src="https://www.youtube.com/embed/knpzpP6_zCA?si=IVNY-ezv3yXOOuna"
        title="Onboarding Form & Checklist"
      >
        <p>
          Here, you'll provide important information about your POS and the
          Ordering Platforms you'd like to integrate. There may be some simple
          action items for you to complete, depending on your POS and Ordering
          Platforms.
        </p>
        <p>Click on the video below for more guidance.</p>
      </OnboardingStep>
    )
  }
]
const OnboardingSteps = ({ currentStep }: { currentStep: number }) => {
  return <div>{content[currentStep - 1].content}</div>
}

export default OnboardingSteps
