import { TransitionGroup, CSSTransition } from 'react-transition-group'

interface TransitionProps {
  showTransition: boolean
  classNames: string
  timeout?: number
  onEntered?: () => void
  onExited?: () => void
  transitionElements: Array<React.ReactNode>
}

const Transition = ({
  showTransition,
  classNames,
  timeout = 250,
  onEntered,
  onExited,
  transitionElements
}: TransitionProps) => {
  return (
    <TransitionGroup component={null}>
      {showTransition
        ? transitionElements.map((element, index) => (
            <CSSTransition
              key={index}
              classNames={classNames}
              timeout={{ enter: timeout, exit: timeout }}
              onEntered={onEntered}
              onExited={onExited}
            >
              {element}
            </CSSTransition>
          ))
        : null}
    </TransitionGroup>
  )
}

Transition.displayName = 'Transition'

export default Transition
