import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

export const AuthBrands = styled.div`
  width: 100%;

  p {
    margin: 0 0 4rem;
    color: ${props => props.theme.landing.text.primary};
  }
`

export const AuthBrandId = styled(TextBody)`
  padding: 0 0 0 0.6rem;
  color: ${props => props.theme.landing.text.secondary};
`
