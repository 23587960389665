import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { ItemModifierGroups } from 'types'
import { showNotification } from 'slices/notification'

export interface ItemModifierGroupsState {
  data: ItemModifierGroups
  loading: RequestStatus
  error: RequestError
}

const initialState: ItemModifierGroupsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItemModifierGroups = createAsyncThunk<
  ItemModifierGroups,
  {
    itemId: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'itemModifierGroups/fetchItemModifierGroups',
  async (
    { itemId, categoryId, modifierGroupId },
    { getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = categoryId
        ? `categories/${categoryId}/items/${itemId}/modifier-groups?expand=true`
        : `modifier-groups/${modifierGroupId}/items/${itemId}/modifier-groups?expand=true`
      return (await api?.request(endpoint)) as ItemModifierGroups
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createItemModifierGroups = createAsyncThunk<
  void,
  {
    itemId: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
    data: Omit<ItemModifierGroups, 'modifier_group'>
  },
  { state: RootState; rejectValue: RequestError }
>(
  'itemModifierGroups/createItemModifierGroups',
  async (
    { itemId, categoryId, modifierGroupId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = categoryId
        ? `categories/${categoryId}/items/${itemId}/modifier-groups`
        : `modifier-groups/${modifierGroupId}/items/${itemId}/modifier-groups`
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Modifier group successfully added!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItemModifierGroups = createAsyncThunk<
  void,
  {
    itemId: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
    data: ItemModifierGroups
  },
  { state: RootState; rejectValue: RequestError }
>(
  'itemModifierGroups/updateItemModifierGroups',
  async (
    { itemId, categoryId, modifierGroupId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = categoryId
        ? `categories/${categoryId}/items/${itemId}/modifier-groups`
        : `modifier-groups/${modifierGroupId}/items/${itemId}/modifier-groups`
      await api?.request(endpoint, 'PUT', data)
      dispatch(showNotification('Modifier groups successfully updated!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteItemModifierGroups = createAsyncThunk<
  void,
  {
    itemId: string | number
    categoryId?: string | number
    modifierGroupId?: string | number
    data: ItemModifierGroups
  },
  { state: RootState; rejectValue: RequestError }
>(
  'itemModifierGroups/deleteItemModifierGroups',
  async (
    { itemId, categoryId, modifierGroupId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = categoryId
        ? `categories/${categoryId}/items/${itemId}/modifier-groups`
        : `modifier-groups/${modifierGroupId}/items/${itemId}/modifier-groups`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Modifier group successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemModifierGroupsSlice = createSlice({
  name: 'itemModifierGroups',
  initialState,
  reducers: {
    resetItemModifierGroups: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemModifierGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemModifierGroups.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchItemModifierGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createItemModifierGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createItemModifierGroups.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createItemModifierGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItemModifierGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItemModifierGroups.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateItemModifierGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteItemModifierGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteItemModifierGroups.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteItemModifierGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItemModifierGroups } = itemModifierGroupsSlice.actions

export const selectItemModifierGroups = (state: RootState) =>
  state.itemModifierGroups
// export const selectItemModifierGroupsFlat = (state: RootState) => {
//   const { data, loading, error } = state.itemModifierGroups
//   const itemModifierGroups = data.reduce((arr, itemModifierGroup) => {
//     const { modifier_group } = itemModifierGroup
//     return [...arr, { ...modifier_group, ...itemModifierGroup }]
//   }, [] as FlatItemModifierGroups)
//   return { itemModifierGroups, loading, error }
// }

export default itemModifierGroupsSlice.reducer
