import { ReceiptType } from 'types'
import { capitalize } from 'utils'
import TextBody from './TextBody'

const ReceiptTypeView = ({
  receiptType
}: {
  receiptType?: ReceiptType | null
}) => {
  const text = receiptType ? capitalize(receiptType) : 'NONE'
  return receiptType === 'REFUND' ? (
    <TextBody size={'small'} variant={'error'}>
      {text}
    </TextBody>
  ) : (
    <TextBody size={'small'} color={'primary'}>
      {text}
    </TextBody>
  )
}

export default ReceiptTypeView
