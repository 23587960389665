import styled from '@emotion/styled'

const ModalOverlay = styled.div`
  label: ModalOverlay;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.overlay};
`
export default ModalOverlay
