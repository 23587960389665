import styled from '@emotion/styled'
import Tooltip from './Tooltip'

interface TableHeaderTooltipProps {
  label: string
  tooltipContent: string | React.ReactNode
}

const TableHeaderTooltipView = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

const TableHeaderTooltip = ({
  label,
  tooltipContent
}: TableHeaderTooltipProps) => {
  return (
    <TableHeaderTooltipView>
      {label}
      <Tooltip content={tooltipContent} />
    </TableHeaderTooltipView>
  )
}

export default TableHeaderTooltip
