import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Category, Items } from 'types'
import { showNotification } from 'slices/notification'
import { fetchMenu } from './menu'

export interface CategoryState {
  data: Category | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CategoryState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type CategoryParams = {
  expand?: string
  with_related?: string
}

export const fetchCategory = createAsyncThunk<
  Category | null,
  { categoryId: string | number; params?: CategoryParams },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/fetchCategory',
  async ({ categoryId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `categories/${categoryId}${queryParams}`
      return (await api?.request(endpoint)) as Category
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createCategory = createAsyncThunk<
  Category,
  { menuId: string | number; data: Category; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/createCategory',
  async (
    { menuId, data, navigate },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('categories', 'POST', data)) as Category
      const menuData = [{ category_id: resp.category_id, menu_position: 99 }]
      await api?.request(`menus/${menuId}/categories`, 'POST', menuData)
      dispatch(showNotification('Category successfully created!'))
      const params = { with_related: 'categories,modifier_groups' }
      dispatch(fetchMenu({ menuId, params }))
      const pathname = `/menus/menus/${menuId}/categories/${resp.category_id}/settings`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCategory = createAsyncThunk<
  Category,
  { menuId: string | number; categoryId: string | number; data: Category },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/updateCategory',
  async (
    { menuId, categoryId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      await api?.request(`categories/${categoryId}`, 'PUT', data)
      dispatch(showNotification('Category successfully updated!'))
      dispatch(
        fetchMenu({
          menuId,
          params: { with_related: 'categories,modifier_groups' }
        })
      )
      return (await api?.request(
        `categories/${categoryId}?with_related=items,files`
      )) as Category
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetCategory: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCategory.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCategory.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchCategory.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createCategory.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createCategory.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createCategory.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCategory.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCategory.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateCategory.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCategory } = categorySlice.actions

export const selectCategory = (state: RootState) => state.category

export const selectCategoryItems = (state: RootState) => {
  const { data, loading, error } = state.category
  if (!data?.items) {
    return { items: [], loading, error }
  }
  const items = data.items.reduce((arr, categoryItem) => {
    const { item, menu_position } = categoryItem
    return [...arr, { ...item, menu_position }]
  }, [] as Items)

  return { items, loading, error }
}

export default categorySlice.reducer
