import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus, sortBy } from 'utils'
import { UserBrand, Creds, FormFieldType } from 'types'
import { authToken, selectAuth } from 'slices/auth'
import { fetchAuthUser, selectAuthUser, setBrand } from 'slices/authUser'
import {
  AuthButtonGroup,
  AuthForm,
  AuthPage,
  ErrMsg,
  Form,
  TextBody
} from 'components'
import { AuthBrandId, AuthBrands } from './Auth.styled'
import { Link } from 'react-router-dom'

const Auth = () => {
  const dispatch = useAppDispatch()
  const { token, loading, error } = useAppSelector(selectAuth)
  const { user, brands } = useAppSelector(selectAuthUser)
  const isLoading = loading === RequestStatus.Pending
  const notUser = !!(token && !isLoading && !user)
  const [initialState] = useState({
    email: '',
    password: ''
  })
  const sorted = sortBy(brands, 'full_name')

  const handleLogin = (values: Creds) => {
    dispatch(authToken(values))
  }

  const chooseBrand = (brand: UserBrand) => {
    dispatch(setBrand(brand))
  }

  useEffect(() => {
    if (notUser) dispatch(fetchAuthUser())
  }, [dispatch, notUser])

  return (
    <AuthPage src="https://assets.opentender.io/admin/images/ARTD-C02-Device-011-04-3000x2070.jpg">
      {brands.length ? (
        <AuthBrands>
          {user ? (
            <p>Hi, {user.first_name}! Please choose a brand to get started.</p>
          ) : null}
          <AuthButtonGroup>
            {sorted.map(brand => (
              <button key={brand.brand_id} onClick={() => chooseBrand(brand)}>
                <span>
                  {brand.full_name}
                  <AuthBrandId size="xsmall">{brand.brand_id}</AuthBrandId>
                </span>
                <span>{brand.domain_name}</span>
              </button>
            ))}
          </AuthButtonGroup>
        </AuthBrands>
      ) : (
        <AuthForm
          title="Hi again."
          subtitle="We've been eagerly awaiting your return."
          footer={
            <>
              {/* <TextBody as="p" size="small">
                Don't have an account? <Link to="signup">Sign up here.</Link>
              </TextBody> */}
              <TextBody as="p" size="small">
                Forget your password?{' '}
                <Link to="send-reset">Reset it here.</Link>
              </TextBody>
            </>
          }
        >
          {error && <ErrMsg errMsg={error.error_description as string} />}
          <Form
            fields={loginFields}
            data={initialState}
            submit={handleLogin}
            isLoading={isLoading}
            submitText="Log Into Your Account"
            // submitColor="secondary"
          />
        </AuthForm>
      )}
    </AuthPage>
  )
}

const loginFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'email',
    name: 'email',
    label: 'Email',
    error: 'Please enter a valid email address'
  },
  {
    fieldType: 'Input',
    type: 'password',
    name: 'password',
    label: 'Password',
    error: 'Please enter a password'
  }
]

export default Auth
