import { useCallback, useEffect, useState } from 'react'
import { useCreditCardForm } from '@open-tender/utils'
import {
  CreditCardData,
  CreditCardValidate,
  RequestError,
  RequestStatus as RequestStatusType
} from '@open-tender/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button, CreditCard } from 'components'
import { ModalHeader } from 'components/Modal'
import { ModalWindow } from 'types'
import { selectOrder, validateOrder } from 'slices/order'
import { RequestStatus, makePreparedOrder } from 'utils'
import {
  createCustomerCard,
  resetCustomerCard,
  selectCustomerCard
} from 'slices/customerCard'
import { closeModal } from 'slices/modal'

const OrderCreditCardNew = ({ modalWindow }: { modalWindow: ModalWindow }) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectCustomerCard)
  const isLoading = loading === RequestStatus.Pending
  const order = useAppSelector(selectOrder)
  const preparedOrder = makePreparedOrder(order)
  const { customer, revenueCenter } = order
  const { customer_id: customerId = null } = customer || {}
  const { revenue_center_id = null } = revenueCenter || {}
  const [submitting, setSubmitting] = useState(false)
  const [newCard, setNewCard] = useState<
    (CreditCardValidate & { isComplete: boolean }) | null
  >(null)
  const { card: cardData = null, cardType = null } = newCard || {}
  const recaptchaKey = null

  const callback = () => setSubmitting(true)

  const addCreditCard = useCallback(
    (data: CreditCardData, callback: () => void) => {
      if (customerId) {
        const creditCard = { ...data, save: true }
        dispatch(createCustomerCard({ customerId, data: creditCard }))
        callback()
      }
    },
    [dispatch, customerId]
  )

  const { errors, handleSubmit } = useCreditCardForm(
    loading as RequestStatusType,
    error as RequestError,
    cardData,
    cardType,
    addCreditCard,
    callback,
    recaptchaKey,
    revenue_center_id
  )

  useEffect(() => {
    if (errors) modalWindow?.scroll(0, 0)
  }, [errors, modalWindow])

  useEffect(() => {
    if (submitting && !isLoading) {
      setSubmitting(false)
      if (!error) {
        if (preparedOrder) dispatch(validateOrder(preparedOrder))
        dispatch(closeModal())
      }
    }
  }, [submitting, isLoading, error, preparedOrder, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCustomerCard())
    }
  }, [dispatch])

  return (
    <>
      <ModalHeader
        title="Add a new credit card"
        subtitle="Card will be saved to the customer's account for future use"
      />
      <CreditCard setCard={setNewCard} formErrors={errors} />
      <Button type="submit" onClick={handleSubmit} disabled={isLoading}>
        Save Credit Card
      </Button>
    </>
  )
}

export default OrderCreditCardNew
