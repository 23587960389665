import { RequestError } from '@open-tender/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTask, OrdersRatingsReport, ServiceType } from 'types'
import { RequestStatus, makeQueryParams } from 'utils'

export interface OrderRatingsReportState {
  data: OrdersRatingsReport
  loading: RequestStatus
  error: RequestError
}

const initialState: OrderRatingsReportState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export interface OrderRatingsReportCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export type FetchOrderRatingsReportParams = {
  start_date: string
  end_date: string
  store_id?: number
  service_type?: ServiceType
  ratings_gte?: number
  ratings_lte?: number
}

export const createOrderRatingsReportCSV = createAsyncThunk<
  OrderRatingsReportCSVAPI,
  FetchOrderRatingsReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'orderRatingsReport/creatOrderRatingsReportCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/ratings/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as OrderRatingsReportCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchOrderRatingsReport = createAsyncThunk<
  OrdersRatingsReport,
  FetchOrderRatingsReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'orderRatingsReport/fetchOrderRatingsReport',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/ratings${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as OrdersRatingsReport
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const orderRatingsReportSlice = createSlice({
  name: 'orderRatingsReport',
  initialState,
  reducers: {
    resetOrderRatingsReport: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderRatingsReport.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderRatingsReport.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOrderRatingsReport.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOrderRatingsReport } = orderRatingsReportSlice.actions

export const selectOrderRatingsReport = (state: RootState) =>
  state.orderRatingsReport

export default orderRatingsReportSlice.reducer
