import styled from '@emotion/styled'
import DesignInput from './DesignInput'

const InputBoxView = styled.div`
  position: relative;
  flex: 1;
  // border-style: solid;
  // border-width: ${props => props.theme.inputs.borderWidth};
  // border-color: ${props => props.theme.inputs.borderColor};
  // border-radius: ${props => props.theme.inputs.borderRadius};

  input {
    width: 3rem;
    height: 3rem;
    padding: 0;
    // border: 0;
    text-align: center;
    // background-color: pink;
  }
`

const InputBoxWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0.4rem;
  left: 0.4rem;
  width: 2.2rem;
  height: 2.2rem;
  // top: 0;
  // left: 0;
  // width: 3rem;
  // height: 3rem;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`

const InputBoxDirection = styled.div<{ isActive: boolean }>`
  position: absolute;
  z-index: 1;
  background-color: ${props =>
    props.isActive
      ? props.theme.colors.text.primary
      : props.theme.inputs.borderColor};
`

const InputBoxTop = styled(InputBoxDirection)`
  top: 0;
  left: 0.5rem;
  right: 0.5rem;
  height: 0.15rem;
`

const InputBoxBottom = styled(InputBoxDirection)`
  bottom: 0;
  left: 0.5rem;
  right: 0.5rem;
  height: 0.15rem;
`

const InputBoxLeft = styled(InputBoxDirection)`
  left: 0;
  top: 0.5rem;
  bottom: 0.5rem;
  width: 0.15rem;
`

const InputBoxRight = styled(InputBoxDirection)`
  right: 0;
  top: 0.5rem;
  bottom: 0.5rem;
  width: 0.15rem;
`

const InputBox = ({
  label,
  required,
  name,
  value,
  setValue,
  direction,
  min,
  max,
  step,
  disabled,
  suffix,
  inherited,
  showZero,
  placeholder
}: {
  label: string
  name: string
  value: string | number | undefined
  setValue: (value: string | number | null) => void
  direction: 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT'
  required?: boolean
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  suffix?: string
  inherited?: boolean
  showZero?: boolean
  placeholder?: string
}) => {
  const setInputValue = (value: string | number | null) => {
    const val = value ? parseFloat(value.toString()) : 0
    setValue(val)
  }

  return (
    <InputBoxView>
      <InputBoxWrapper>
        <div>
          <InputBoxTop isActive={direction === 'TOP'} />
          <InputBoxRight isActive={direction === 'RIGHT'} />
          <InputBoxBottom isActive={direction === 'BOTTOM'} />
          <InputBoxLeft isActive={direction === 'LEFT'} />
        </div>
      </InputBoxWrapper>
      <DesignInput
        type="number"
        aria-label={label}
        name={name}
        value={value}
        setValue={setInputValue}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        suffix={suffix}
        inherited={inherited}
        showZero={showZero}
        placeholder={placeholder}
      />
    </InputBoxView>
  )
}

export default InputBox
