import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Font } from 'types'
import { showNotification } from 'slices/notification'

export interface FontState {
  data: Font | null
  loading: RequestStatus
  error: RequestError
}

const initialState: FontState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchFont = createAsyncThunk<
  Font,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('font/fetchFont', async (fontId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `fonts/${fontId}`
    const resp = (await api?.request(endpoint)) as Font
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createFont = createAsyncThunk<
  Font,
  { data: Font; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'font/createFont',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('fonts', 'POST', data)) as Font
      dispatch(showNotification('Font successfully created!'))
      const pathname = `/settings/ux/fonts/${resp.font_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateFont = createAsyncThunk<
  Font,
  {
    fontId: string | number
    data: Font
  },
  { state: RootState; rejectValue: RequestError }
>(
  'font/updateFont',
  async ({ fontId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `fonts/${fontId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Font
      dispatch(showNotification('Font successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fontSlice = createSlice({
  name: 'font',
  initialState,
  reducers: {
    resetFont: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchFont.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchFont.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchFont.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createFont.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createFont.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createFont.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateFont.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateFont.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateFont.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetFont } = fontSlice.actions

export const selectFont = (state: RootState) => state.font

export default fontSlice.reducer
