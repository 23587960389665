import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  Categories,
  Category,
  CategoryItemPosition,
  OrderTypeOld,
  ReorderCategories
} from 'types'
import {
  assembleCategories,
  makeQueryParams,
  RequestError,
  RequestStatus
} from 'utils'
import { showNotification } from './notification'

export interface CategoriesState {
  data: Categories
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface CategoriesAPI {
  data: Array<Category>
  links: {
    next: string
  }
}

export type CategoriesParams = {
  cursor?: string
  is_active?: boolean
  limit?: number
  menu_id?: number
  not_assigned_only?: boolean
  order_type?: OrderTypeOld
  search?: string
  with_related?: string
}

const initialState: CategoriesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCategories = createAsyncThunk<
  CategoriesAPI,
  CategoriesParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'categories/fetchCategories',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `categories${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as CategoriesAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const reorderCategories = createAsyncThunk<
  void,
  { menuId: string | number; data: ReorderCategories },
  { state: RootState; rejectValue: RequestError }
>(
  'categories/reorderCategories',
  async ({ menuId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `menus/${menuId}/categories/reorder`
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Categories reordered!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const reorderCategoryItems = createAsyncThunk<
  void,
  { categoryId: number | string; items: CategoryItemPosition[] },
  { state: RootState; rejectValue: RequestError }
>(
  'categories/reorderCategoryItems',
  async ({ items, categoryId }, { getState, rejectWithValue, dispatch }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `categories/${categoryId}/items`
      await api?.request(endpoint, 'PUT', items)
      dispatch(showNotification('Category items reordered!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    resetCategories: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCategories.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchCategories.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(reorderCategories.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(reorderCategories.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(reorderCategories.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(reorderCategoryItems.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(reorderCategoryItems.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(reorderCategoryItems.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCategories } = categoriesSlice.actions

export const selectCategories = (state: RootState) => state.categories

export const selectCategoriesSorted =
  (menuId: number) => (state: RootState) => {
    const { data, loading, error } = state.categories
    const expanded = data.map(category => {
      const { menus = [] } = category
      const menu = menus.find(i => i.menu.menu_id === menuId)
      const menu_position = menu ? menu.menu_position : 0
      return { ...category, menu_position }
    })
    const sorted = assembleCategories(expanded)
    return { data: sorted, loading, error }
  }

export default categoriesSlice.reducer
