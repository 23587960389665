import styled from '@emotion/styled'
import DesignInput from './DesignInput'
import DesignLabel from './DesignLabel'

const InputNumberView = styled.div`
  position: relative;
  flex: 0 0 ${props => props.theme.layout.desktop.inputWidth};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex: 0 0 ${props => props.theme.layout.mobile.inputWidth};
  }
`

const InputNumber = ({
  label,
  required,
  name,
  value,
  setValue,
  min,
  max,
  step,
  disabled,
  suffix,
  inherited,
  showZero,
  placeholder
}: {
  label: string
  name: string
  value: string | number | undefined
  setValue: (value: string | number | null) => void
  required?: boolean
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  suffix?: string
  inherited?: boolean
  showZero?: boolean
  placeholder?: string
}) => {
  const setInputValue = (value: string | number | null) => {
    const val = value ? parseFloat(value.toString()) : 0
    setValue(val)
  }

  return (
    <DesignLabel
      label={label}
      required={required}
      htmlFor={name}
      inherited={inherited}
    >
      <InputNumberView>
        <DesignInput
          type="number"
          name={name}
          value={value}
          setValue={setInputValue}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          suffix={suffix}
          inherited={inherited}
          showZero={showZero}
          placeholder={placeholder}
        />
      </InputNumberView>
    </DesignLabel>
  )
}

export default InputNumber
