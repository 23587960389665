import {
  ClipboardCheck,
  Community,
  DesignPencil,
  /*EditPencil,*/
  Heart,
  Internet,
  Lock,
  LogOut,
  OpenBook,
  Reports,
  SendMail,
  Settings,
  ShopFourTiles,
  ShoppingBag
} from 'iconoir-react'

export const links: {
  title: string
  path: string
  icon: React.ReactElement
}[] = [
  {
    icon: <ShoppingBag />,
    title: 'Orders',
    path: '/orders'
  },
  {
    icon: <Reports />,
    title: 'Reporting',
    path: '/reporting'
  },
  {
    icon: <Community />,
    title: 'Customers',
    path: '/customers'
  },
  {
    icon: <Heart />,
    title: 'Loyalty',
    path: '/loyalty'
  },
  {
    icon: <SendMail />,
    title: 'Marketing',
    path: '/marketing'
  },
  {
    icon: <ShopFourTiles />,
    title: 'Stores',
    path: '/stores'
  },
  {
    icon: <OpenBook />,
    title: 'Menus',
    path: '/menus'
  },
  {
    icon: <Internet />,
    title: 'Pages',
    path: '/pages'
  },
  {
    icon: <DesignPencil />,
    title: 'Design',
    path: '/design'
  },
  {
    icon: <Settings />,
    title: 'Settings',
    path: '/settings'
  },
  {
    icon: <Lock />,
    title: 'Super',
    path: '/super'
  },
  /* {
    icon: <EditPencil />,
    title: 'Editor',
    path: '/editor'
  },*/
  {
    icon: <ClipboardCheck />,
    title: 'Onboarding',
    path: '/onboarding'
  }
]

export const footerLinks: {
  title: string
  path: string
  icon: React.ReactElement
}[] = [
  {
    icon: <LogOut />,
    title: 'Logout',
    path: '/logout'
  }
]
