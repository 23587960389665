import { useEffect, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow } from 'types'
import { RequestStatus, downloadTXT } from 'utils'
import { closeModal } from 'slices/modal'
import { showNotification } from 'slices/notification'
import {
  createGiftCardBatch,
  fetchGiftCardBatch,
  selectGiftCardBatch
} from 'slices/giftCardBatch'
import {
  createGiftCardBatchCSV,
  createGiftCardBatchTXT,
  fetchGiftCardBatches
} from 'slices/giftCardBatches'
import { Button, Form } from 'components'
import { ModalFooter, ModalHeader } from 'components/Modal'

const GiftCardBatch = ({
  args,
  modalWindow
}: {
  args: { batch_id: number; navigate: NavigateFunction }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data, loading, error } = useAppSelector(selectGiftCardBatch)
  const [submitted, setSubmitted] = useState(false)
  const isLoading = loading === RequestStatus.Pending
  const { batch_id, navigate } = args

  const submit = async (values: { count: number }) => {
    setSubmitted(true)
    const data = { count: values.count }
    await dispatch(createGiftCardBatch({ data, navigate: navigate }))
    dispatch(fetchGiftCardBatches())
  }

  useEffect(() => {
    if (batch_id && (!data || batch_id !== data.batch_id)) {
      dispatch(fetchGiftCardBatch(batch_id))
    }
  }, [batch_id, data, dispatch, submitted])

  useEffect(() => {
    if (submitted) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted])

  const handleTXTDownload = async () => {
    const params = {
      batch_id
    }

    const response = await dispatch(createGiftCardBatchTXT(params))
    if (typeof response.payload === 'string') {
      downloadTXT(response.payload, `batch_${batch_id}.txt`)
    } else
      dispatch(showNotification('Failed to download, please try again later.'))
  }

  const handleCSVCreate = async () => {
    const params = {
      batch_id
    }

    await dispatch(createGiftCardBatchCSV(params))
    dispatch(closeModal())
    navigate('/reporting/downloads')
  }

  return (
    <>
      <ModalHeader
        title={batch_id ? `Gift Card Batch ${batch_id}` : 'New Gift Card Batch'}
        subtitle={
          batch_id
            ? `${data?.count} gift cards contained in this batch. Choose a download format below.`
            : 'Enter the number of gift cards that should be created.'
        }
      />
      {batch_id ? (
        <ModalFooter>
          <Button color="primary" size="medium" onClick={handleCSVCreate}>
            Download CSV
          </Button>
          <Button color="primary" size="medium" onClick={handleTXTDownload}>
            Download TXT
          </Button>
        </ModalFooter>
      ) : (
        <Form
          fields={giftCardBatchFields}
          data={giftCardBatchEmpty}
          error={error}
          isLoading={isLoading}
          submit={submit}
          scrollWindow={modalWindow}
          isRelation={true}
        />
      )}
    </>
  )
}

const giftCardBatchEmpty = {
  count: null
}

const giftCardBatchFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'number',
    name: 'count',
    label: 'Count',
    error: 'Please enter a count for gift cards.'
  }
]

export default GiftCardBatch
