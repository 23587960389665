import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { EmailRecipients } from 'types'
import { showNotification } from 'slices/notification'

export interface EmailRecipientsState {
  data: EmailRecipients | null
  loading: RequestStatus
  error: RequestError
}

const initialState: EmailRecipientsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchEmailRecipients = createAsyncThunk<
  EmailRecipients | null,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'emailRecipients/fetchEmailRecipients',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(`email-recipients`)) as EmailRecipients

      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertEmailRecipients = createAsyncThunk<
  EmailRecipients,
  { data: EmailRecipients },
  { state: RootState; rejectValue: RequestError }
>(
  'emailRecipients/upsertEmailRecipients',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `email-recipients`,
        'PUT',
        data
      )) as EmailRecipients
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const emailRecipientsSlice = createSlice({
  name: 'EmailRecipients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEmailRecipients.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchEmailRecipients.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchEmailRecipients.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertEmailRecipients.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertEmailRecipients.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertEmailRecipients.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectEmailRecipients = (state: RootState) => state.emailRecipients

export default emailRecipientsSlice.reducer
