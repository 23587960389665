import { useAppSelector } from 'app/hooks'
import { CustomerAddress, ModalWindow } from 'types'
import { ButtonGroup, TextBody } from 'components'
import { ModalHeader } from 'components/Modal'
import { selectCustomer } from 'slices/order'
import { sortAddresses } from 'utils'

const OrderAddress = ({
  args,
  modalWindow
}: {
  args: {
    customerId: string | number
    chooseAddress: (customerAddress: CustomerAddress) => void
  }
  modalWindow: ModalWindow
}) => {
  const { data } = useAppSelector(selectCustomer)
  const addresses = data?.customer_addresses || []
  const validAddresses = addresses.filter(
    i => i.street && i.city && i.state && i.postal_code
  )
  const sorted = sortAddresses(validAddresses)

  return (
    <>
      <ModalHeader
        title="Address"
        subtitle="Choose one of this customer's addresses or add a new one"
      />
      {sorted.length > 0 ? (
        <ButtonGroup>
          {sorted.map(address => (
            <button
              key={address.customer_address_id}
              onClick={() => args.chooseAddress(address)}
            >
              <span>{address.formatted_address}</span>
            </button>
          ))}
        </ButtonGroup>
      ) : (
        <TextBody>No address found</TextBody>
      )}
    </>
  )
}

export default OrderAddress
