import { RefundableItem, RefundableModifier } from 'types'
import { RefundTableSubCell, RefundTableSubRow } from './RefundCard.styled'
import { IconoirProvider, Minus, Plus } from 'iconoir-react'
import Button from 'components/Button'
import { Input } from 'components/Forms'
import { formatDollars } from 'utils'

const RefundModifierWrapper = ({
  modifier,
  item,
  level,
  increaseQty,
  decreaseQty,
  getQuantity,
  getModifierAmount
}: {
  modifier: RefundableModifier
  item: RefundableItem
  level: number
  decreaseQty: (item: RefundableItem | RefundableModifier) => void
  increaseQty: (item: RefundableItem | RefundableModifier) => void
  getQuantity: (lineNo: number) => number
  getModifierAmount: (lineNo: number, itemId: number) => number
}) => {
  const hasNestedModifiers = Boolean(modifier.groups)
  return (
    <>
      <RefundTableSubRow>
        <RefundTableSubCell style={{ paddingLeft: 15 * level }}>
          {modifier.name ?? modifier.short_name}
        </RefundTableSubCell>
        <RefundTableSubCell>
          {formatDollars(
            modifier.quantity
              ? parseFloat(
                  hasNestedModifiers ? modifier.price_total : modifier.price
                ) / modifier.quantity
              : 0
          )}
        </RefundTableSubCell>
        <RefundTableSubCell>{modifier.quantity}</RefundTableSubCell>
        <RefundTableSubCell>
          ${hasNestedModifiers ? modifier.price_total : modifier.price}
        </RefundTableSubCell>
        <RefundTableSubCell>
          {level === 1 && (
            <IconoirProvider
              iconProps={{
                width: '2rem',
                height: '2rem',
                strokeWidth: '1'
              }}
            >
              <Button
                onClick={() => decreaseQty(modifier)}
                disabled={getQuantity(modifier.line_no) === 0}
              >
                <Minus />
              </Button>
              <Input
                id={`refund_amount_${modifier.line_no}`}
                type="number"
                name="refund_amount"
                value={getQuantity(modifier.line_no)}
                readOnly
              ></Input>
              <Button
                onClick={() => increaseQty(modifier)}
                disabled={getQuantity(modifier.line_no) === modifier.quantity}
              >
                <Plus />
              </Button>
            </IconoirProvider>
          )}
        </RefundTableSubCell>
        <RefundTableSubCell>
          {level === 1 &&
            formatDollars(-getModifierAmount(modifier.line_no, item.item_id))}
        </RefundTableSubCell>
      </RefundTableSubRow>
      {modifier.groups?.map(g => {
        return g.modifiers.map(m => (
          <RefundModifierWrapper
            key={m.short_name}
            modifier={m}
            item={item}
            level={level + 1}
            increaseQty={increaseQty}
            decreaseQty={decreaseQty}
            getQuantity={getQuantity}
            getModifierAmount={getModifierAmount}
          />
        ))
      })}
    </>
  )
}
export default RefundModifierWrapper
