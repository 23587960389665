import { useState } from 'react'
import { listFilterEmptyCondition } from 'config'
import { TableSort, ListFilter as ListFilterType } from 'types'

interface OnboardingTableOptionsProps {
  defaultSortOrderBy?: string | null
}

const useOnboardingTableOptions = ({
  defaultSortOrderBy
}: OnboardingTableOptionsProps) => {
  const [listFilters, setListFilters] = useState<ListFilterType[]>([
    listFilterEmptyCondition
  ])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [sort, setSort] = useState<TableSort>({
    order: 'desc',
    orderBy: defaultSortOrderBy || null
  })
  const [hasStatusChange, setHasStatusChange] = useState(true)
  const [hasSortChange, setHasSortChange] = useState(false)
  const [archiveStatus, setArchiveStatus] = useState('all')

  const changePage = (page: number) => {
    setPage(page)
    setHasStatusChange(true)
  }

  const changePerPage = (perPage: number) => {
    setPage(1)
    setPerPage(perPage)
    setHasStatusChange(true)
  }

  const changeSort = (fieldKey: string) => {
    if (sort.orderBy === fieldKey) {
      const newOrder = sort.order === 'asc' ? 'desc' : 'asc'
      setSort({ order: newOrder, orderBy: fieldKey })
    } else {
      setSort({ order: 'asc', orderBy: fieldKey })
    }
    setHasSortChange(true)
  }

  const changeStatus = (status: string) => {
    setArchiveStatus(status)
    setHasStatusChange(true)
  }

  const clearListFilters = () => {
    setListFilters([listFilterEmptyCondition])
    setHasStatusChange(true)
  }

  return {
    page,
    changePage,
    perPage,
    changePerPage,
    sort,
    changeSort,
    hasSortChange,
    setHasSortChange,
    hasStatusChange,
    setHasStatusChange,
    archiveStatus,
    changeStatus,
    listFilters,
    setListFilters,
    clearListFilters
  }
}

export default useOnboardingTableOptions
