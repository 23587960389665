import { Location } from 'types'

export const getActiveStatus = (location: Location) => {
  switch (location?.state?.completePercent) {
    case 16:
      return 'Active Pending Customer'
    case 66:
      return location?.isPosToOpSync
        ? 'Active Pending Customer'
        : 'Active Pending Checkmate'
    case 33:
    case 50:
    case 83:
      return 'Active Pending Checkmate'
    default:
      return 'Active'
  }
}

export const decapitalizeTest = (text: string) => {
  return (text && text[0].toLowerCase() + text.slice(1)) || text
}

export const camelToUnderscore = (key: string) => {
  var result = (key || '').replace(/([A-Z])/g, ' $1')
  return result.split(' ').join('_').toLowerCase()
}

export const getStatusValue = (val: string, location: Location) => {
  switch (camelToUnderscore(decapitalizeTest(val))) {
    case 'active':
      return getActiveStatus(location)
    case 'churned_in_onboarding':
      return 'Churned-InOnboarding'
    case 'blocked_internal':
      return 'Blocked pending internal ticket'
    case 'blocked_client':
      return 'Blocked pending client correspondence'
    case 'on_hold':
      return 'On hold client request'
    case 'live':
      return 'Live'
    case 'churned_cancelled':
      return 'Churned-Cancelled'
    case 'test':
      return 'Test'
    case 'lab':
      return 'Lab'
    case 'archived':
      return 'Archived'
    case 'dashboard_onboarding':
      return 'Dashboard-Onboarding'
    case 'dashboard_blocked':
      return 'Dashboard-Blocked'
    case 'dashboard_live':
      return 'Dashboard-Live'
    case 'dashboard_churned':
      return 'Dashboard-Churned'
    case 'dashboard_test':
      return 'Dashboard-Test'
    case 'dashboard_archived':
      return 'Dashboard-Archived'
    default:
      return 'Active'
  }
}

export function getEnterpriseLevelStatus(enterpriseLevel: number) {
  if (enterpriseLevel > 5) {
    return 'SMB'
  } else {
    return 'Enterprise'
  }
}
