import styled from '@emotion/styled'
import { useWindowSize } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

// https://blog.logrocket.com/best-practices-react-iframes/

const interUrl = `https://fonts.googleapis.com/css2?family=Inter&display=swap`

interface IframeViewProps {
  bgColor?: string | null
}

const IframeView = styled.iframe<IframeViewProps>`
  border: 0;
  margin: 0 auto;
  background-color: ${props =>
    props.bgColor || props.theme.colors.background.tertiary};
`

const Iframe = ({
  title,
  width = '100%',
  height = '100%',
  scale = 1,
  padding,
  bgColor,
  stylesheets,
  children,
  ...props
}: {
  title: string
  children: React.ReactNode
  width?: string
  height?: string
  scale?: number | null
  padding?: string
  bgColor?: string | null
  stylesheets?: string[]
}) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null)
  const mountNode = contentRef?.contentWindow?.document?.body
  const head = contentRef?.contentWindow?.document?.head
  const links = useMemo(() => stylesheets, [stylesheets])
  const window = useWindowSize()
  const iframeWidth = `${window[0] - 80 - 320 - 150}px`
  const iframeHeight = `${window[1] - 60}px`
  // const style =
  //   scale === 1
  //     ? {}
  //     : {
  //         position: 'relative',
  //         overflow: 'hidden',
  //         pointerEvents: 'auto',
  //         display: 'inline',
  //         minHeight: '100%',
  //         maxHeight: '100%',
  //         width: 1560,
  //         minWidth: 1560,
  //         maxWidth: 1560,
  //         transform: `scale(${scale})`,
  //         transformOrigin: 0,
  //         transformStyle: 'preserve-3d',
  //         border: 0
  //       }

  useEffect(() => {
    if (hasLoaded) return
    if (head && links) {
      links.forEach(href => {
        let cssLink = document.createElement('link')
        cssLink.href = href
        cssLink.rel = 'stylesheet'
        head.appendChild(cssLink)
      })
      // load Inter URL for labels within the Preview area
      const interLink = document.createElement('link')
      interLink.href = interUrl
      interLink.rel = 'stylesheet'
      head.appendChild(interLink)
      setHasLoaded(true)
    }
  }, [head, links, hasLoaded])

  useEffect(() => {
    if (head) {
      const tags = head.getElementsByTagName('style')
      for (let index = tags.length - 1; index >= 0; index--) {
        head.removeChild(tags[index])
      }
      const styles = document.createElement('style')
      let bodyStyles = `margin: 0;`
      if (padding) bodyStyles += ` padding: ${padding};`
      // if (scale) bodyStyles += ` transform: scale(${scale});`
      styles.innerHTML = `body {${bodyStyles}}`
      head.appendChild(styles)
    }
  }, [head, padding, scale])

  return (
    <IframeView
      {...props}
      title={title}
      width={iframeWidth}
      height={iframeHeight}
      bgColor={bgColor}
      ref={setContentRef}
      // style={{
      //   position: 'relative',
      //   overflow: 'hidden',
      //   pointerEvents: 'auto',
      //   display: 'inline',
      //   minHeight: '100%',
      //   maxHeight: '100%',
      //   width: 1560,
      //   minWidth: 1560,
      //   maxWidth: 1560,
      //   transform: `scale(${scale})`,
      //   transformOrigin: 0,
      //   transformStyle: 'preserve-3d',
      //   border: 0
      // }}
    >
      {mountNode && createPortal(children, mountNode)}
    </IframeView>
  )
}

export default Iframe
