import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { PrepStations } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface PrepStationsState {
  data: PrepStations
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface PrepStationsAPI {
  data: PrepStations
  links: {
    next: string
  }
}

export type PrepStationsParams = {
  cursor?: string
  limit?: number
  expand?: string
  with_related?: string
  store_id?: number | string
  is_active?: boolean
  search?: string
}

const initialState: PrepStationsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchPrepStations = createAsyncThunk<
  PrepStationsAPI,
  PrepStationsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'prepStations/fetchPrepStations',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `prep-stations${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as PrepStationsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const prepStationsSlice = createSlice({
  name: 'prepStations',
  initialState,
  reducers: {
    resetPrepStations: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPrepStations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPrepStations.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchPrepStations.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPrepStations } = prepStationsSlice.actions

export const selectPrepStations = (state: RootState) => state.prepStations

export default prepStationsSlice.reducer
