import styled from '@emotion/styled'

export const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StepContainer = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  align-items: center;
  justify-items: center;
  display: inline-flex;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`
export const StepNumber = styled.div<{
  disabled?: boolean
  completed?: boolean
}>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.completed
      ? props.theme.buttons.colors.primary.default.text
      : props.disabled
        ? props.theme.buttons.colors.secondary.default.text
        : props.theme.buttons.colors.primary.default.background};
  background-color: ${props =>
    props.completed
      ? props.theme.buttons.colors.primary.default.background
      : props.theme.buttons.colors.secondary.default.background};
  border: ${props =>
      props.completed || props.disabled
        ? 'none'
        : props.theme.layout.desktop.border}
    solid ${props => props.theme.buttons.colors.primary.default.background};
`

export const StepName = styled.span`
  margin-left: 1rem;
  color: ${props => props.theme.buttons.colors.secondary.default.text};
  align-items: center;
  text-transform: capitalize;
`
export const SeparatorLine = styled.div`
  width: 5rem;
  height: 0.2rem;
  background-color: ${props => props.theme.colors.border.primary};
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;

  &:last-child {
    display: none;
  }
`
