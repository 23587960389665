import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Surcharge } from 'types'
import { showNotification } from 'slices/notification'

export interface SurchargeState {
  data: Surcharge | null
  loading: RequestStatus
  error: RequestError
}

const initialState: SurchargeState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchSurcharge = createAsyncThunk<
  Surcharge,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'surcharge/fetchSurcharge',
  async (surchargeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `surcharges/${surchargeId}`
      const resp = (await api?.request(endpoint)) as Surcharge
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createSurcharge = createAsyncThunk<
  Surcharge,
  { data: Surcharge; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'surcharge/createSurcharge',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('surcharges', 'POST', data)) as Surcharge
      dispatch(showNotification('Surcharge successfully created!'))
      const pathname = `/settings/ops/surcharges/${resp.surcharge_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateSurcharge = createAsyncThunk<
  Surcharge,
  {
    surchargeId: string | number
    data: Surcharge
  },
  { state: RootState; rejectValue: RequestError }
>(
  'surcharge/updateSurcharge',
  async ({ surchargeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `surcharges/${surchargeId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Surcharge
      dispatch(showNotification('Surcharge successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteSurcharge = createAsyncThunk<
  void,
  {
    surchargeId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'surcharge/deleteSurcharge',
  async (
    { surchargeId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `surcharges/${surchargeId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Surcharge removed!'))
      navigate({ pathname: '/settings/ops/surcharges' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const surchargeSlice = createSlice({
  name: 'surcharge',
  initialState,
  reducers: {
    resetSurcharge: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchSurcharge.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchSurcharge.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchSurcharge.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createSurcharge.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createSurcharge.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createSurcharge.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateSurcharge.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateSurcharge.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateSurcharge.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteSurcharge.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteSurcharge.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteSurcharge.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetSurcharge } = surchargeSlice.actions

export const selectSurcharge = (state: RootState) => state.surcharge

export default surchargeSlice.reducer
