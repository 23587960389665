import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Discount, DiscountEmails } from 'types'
import { showNotification } from 'slices/notification'
import fileRequest from '../utils/fileRequest'

export interface DiscountState {
  data: Discount | null
  loading: RequestStatus
  error: RequestError
}

const initialState: DiscountState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}
type UploadCSVArgs = {
  endpoint: string
  csv: File
  token: string
  brandId: number
  fetchFiles?: () => void
}

export const addDiscountEmails = createAsyncThunk<
  DiscountEmails,
  { data: DiscountEmails; discount_id: Number },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/addDiscountEmails',
  async ({ discount_id, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `discounts/${discount_id}/emails`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as DiscountEmails
      dispatch(showNotification('Email added successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteDiscountEmail = createAsyncThunk<
  void,
  { discount_id: number; data: DiscountEmails },
  { state: RootState; rejectValue: RequestError }
>(
  'discount/deleteDiscountEmail',
  async ({ discount_id, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `discounts/${discount_id}/emails`

      await api?.request(endpoint, 'DELETE', data)

      dispatch(showNotification('Email deleted successfully!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const uploadCSV = createAsyncThunk<
  void,
  UploadCSVArgs,
  { state: RootState; rejectValue: RequestError }
>(
  'discount/uploadCSV',
  async (
    { endpoint, csv, brandId, token, fetchFiles },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await fileRequest({
        onProgress: () => {},
        url: endpoint,
        token: token,
        brandId: brandId,
        dispatch,
        fileType: 'csv',
        file: csv,
        fileName: csv.name
      })
      dispatch(showNotification('CSV file uploaded successfully!'))
      if (fetchFiles) {
        fetchFiles()
      }
    } catch (error) {
      return rejectWithValue(error as RequestError)
    }
  }
)

export const discountEmailSlice = createSlice({
  name: 'discountEmails',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addDiscountEmails.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addDiscountEmails.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(addDiscountEmails.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteDiscountEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteDiscountEmail.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteDiscountEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(uploadCSV.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(uploadCSV.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(uploadCSV.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectDiscountEmails = (state: RootState) => state.discountEmail

export default discountEmailSlice.reducer
