import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'app/hooks'
import { ModalWindow, OnboardingBrand } from 'types'
import { closeModal } from 'slices/modal'
import { ModalHeader } from 'components/Modal'
import BrandOnboardingForm from 'components/IacmBrandOnboarding/BrandOnboardingForm'
import { resetOnboardingBrand } from 'slices/iacm/onboardingBrand'

const EditBrand = ({
  args,
  modalWindow
}: {
  args: { onboardingBrand: OnboardingBrand; fetchBrands: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { onboardingBrand, fetchBrands } = args

  const callback = useCallback(() => {
    fetchBrands()
    dispatch(closeModal())
  }, [fetchBrands, dispatch])

  useEffect(() => {
    dispatch(resetOnboardingBrand())
    return () => {
      dispatch(resetOnboardingBrand())
    }
  }, [dispatch])

  return (
    <>
      <ModalHeader title="Edit Brand" />
      <BrandOnboardingForm brand={onboardingBrand} callback={callback} />
    </>
  )
}

export default EditBrand
