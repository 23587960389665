import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { RequestError } from 'utils'
import { ButtonReload, ErrMsg, Loader, TextBody } from 'components'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import { TableContainer, TableView, TableWrapper } from './Table.styled'
import {
  TableHeaderConfig,
  TableRows,
  TableSort,
  ThemeColor,
  ThemeFontSizes
} from 'types'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

interface TableProps {
  headers: (string | React.ReactNode | TableHeaderConfig)[]
  // contents: Array<Array<string | JSX.Element>>
  contents: TableRows
  isLoading?: boolean
  reload?: (() => void) | null
  error?: RequestError
  headBg?: keyof ThemeColor
  size?: keyof ThemeFontSizes
  isDraggable?: boolean
  handleReorder?: (reorderedRows: TableRows) => void
  onSort?: (key: string) => void
  sort?: TableSort
  skipMobileView?: boolean
}

const Table = ({
  headers,
  contents,
  isLoading,
  reload,
  error,
  headBg = 'secondary',
  size = 'medium',
  isDraggable = false,
  sort,
  onSort,
  handleReorder,
  skipMobileView = false
}: TableProps) => {
  const [tableData, setTableData] = useState<TableRows>(contents)

  useEffect(() => {
    if (contents.length) setTableData(contents)
  }, [contents])

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return

    // Reorder the rows based on the source and destination indexes
    const reorderedRows = Array.from(tableData)
    const [removedRow] = reorderedRows.splice(source.index, 1)
    reorderedRows.splice(destination.index, 0, removedRow)
    setTableData(reorderedRows)

    handleReorder && handleReorder(reorderedRows)
  }

  const hasImage = tableData.filter(i => i.image).length > 0
  const mobileHeaders = hasImage ? headers.slice(1) : headers
  const headerLabels = useMemo(
    () =>
      mobileHeaders.map(header => {
        if (React.isValidElement(header)) {
          return header
        } else if (header && typeof header === 'object' && 'text' in header) {
          return `${header.text}:`
        } else if (typeof header === 'string' && header.trim()) {
          return `${header}:`
        } else {
          return ''
        }
      }),
    [mobileHeaders]
  )

  return (
    <>
      {error && <ErrMsg errMsg={error.detail} />}
      <TableWrapper className="table-wrapper">
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableView
            headBg={headBg}
            size={size}
            className={skipMobileView ? 'skip-mobile-view' : ''}
          >
            <TableHeader headers={headers} sort={sort} onSort={onSort} />
            {contents.length && !isLoading && !reload ? (
              <TableBody
                contents={tableData}
                isDraggable={isDraggable}
                headers={headerLabels}
              />
            ) : null}
          </TableView>
          {isLoading ? (
            <TableContainer>
              <Loader text="Retrieving data..." />
            </TableContainer>
          ) : reload ? (
            <TableContainer>
              <ButtonReload reload={reload} />
            </TableContainer>
          ) : (
            !contents.length && (
              <TableContainer>
                <TextBody as="div">No results found.</TextBody>
              </TableContainer>
            )
          )}
        </DragDropContext>
      </TableWrapper>
    </>
  )
}

export default Table
