import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'app/hooks'
import { Location, ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { ModalHeader } from 'components/Modal'
import LocationForm from 'components/IacmOnboardingLocations/LocationForm'
import { resetOnboardingLocation } from 'slices/iacm/onboardingLocation'

const EditLocation = ({
  args,
  modalWindow
}: {
  args: { location: Location; fetchLocations: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { location, fetchLocations } = args

  const callback = useCallback(() => {
    fetchLocations()
    dispatch(closeModal())
  }, [fetchLocations, dispatch])

  useEffect(() => {
    dispatch(resetOnboardingLocation())
    return () => {
      dispatch(resetOnboardingLocation())
    }
  }, [dispatch])

  return (
    <>
      <ModalHeader title="Edit Location" />
      <LocationForm location={location} callback={callback} />
    </>
  )
}

export default EditLocation
