import styled from '@emotion/styled'

export const ButtonLinksWrap = styled.div`
  margin-bottom: 2rem;
  display: flex;
`

export const ButtonSeperation = styled.div`
  margin: 0 1.5rem;
`
