import { ReactNode } from 'react'
import { TextHeading } from 'components'
import { PagesImagesView } from './PagesImages.styled'
import {
  PagesSection,
  PagesSectionHeader,
  PagesSections
} from '../../pages/Pages/Pages.styled'

interface PagesImagesViewProps {
  children: ReactNode
}

const PagesImages = ({ children }: PagesImagesViewProps) => {
  return (
    <PagesSections>
      <PagesSection>
        <PagesSectionHeader>
          <TextHeading as="h2" size="h4">
            Images
          </TextHeading>
        </PagesSectionHeader>
        <PagesImagesView>{children}</PagesImagesView>
      </PagesSection>
    </PagesSections>
  )
}

export default PagesImages
