import { Button } from 'components'
import { ThemeButtonColors, ThemeButtonSizes } from 'types'

interface ButtonSubmitProps extends React.ComponentProps<'button'> {
  text?: string
  color?: keyof ThemeButtonColors
  size?: keyof ThemeButtonSizes
}

const ButtonSubmit = ({
  text = 'Submit',
  color = 'primary',
  size = 'medium',
  onClick,
  disabled,
  children
}: ButtonSubmitProps) => {
  return (
    <Button
      color={color}
      size={size}
      type="submit"
      disabled={disabled}
      onClick={onClick}
    >
      {children || text}
    </Button>
  )
}

export default ButtonSubmit
