import { selectAuthUser } from 'slices/authUser'
import {
  HomeBrandLink,
  HomeBrandLogo,
  HomeBrandTitle,
  HomeBrandView
} from './Home.styled'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectColorScheme } from 'slices/global'
import { useEffect } from 'react'
import { fetchWebsite, selectWebsite } from 'slices/website'
import { OpenNewWindow } from 'iconoir-react'

const HomeBrand = () => {
  const dispatch = useAppDispatch()
  const colorScheme = useAppSelector(selectColorScheme)
  const { user, logo, logoLight, brand } = useAppSelector(selectAuthUser)
  const logoUrl = colorScheme === 'DARK' ? logoLight : logo
  const { data } = useAppSelector(selectWebsite)
  const { origin } = data || {}

  useEffect(() => {
    dispatch(fetchWebsite())
  }, [dispatch])

  return (
    <HomeBrandView>
      {origin && (
        <HomeBrandLink href={origin} target="_blank" rel="noreferrer">
          <span>{origin}</span>
          <span>
            <OpenNewWindow width="1.1rem" height="1.1rem" />
          </span>
        </HomeBrandLink>
      )}
      {logoUrl && (
        <HomeBrandLogo>
          <img src={logoUrl} alt={brand?.short_name} />
        </HomeBrandLogo>
      )}
      {user && (
        <HomeBrandTitle>Welcome back, {user.first_name}.</HomeBrandTitle>
      )}
    </HomeBrandView>
  )
}

export default HomeBrand
