import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { CustomerNote } from 'types'

export interface CustomerNoteState {
  data: CustomerNote | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CustomerNoteState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export interface CustomerNoteReq {
  customer_id: number
  note: string
}

export const addCustomerNote = createAsyncThunk<
  CustomerNote,
  CustomerNoteReq,
  { state: RootState; rejectValue: RequestError }
>(
  'customerNote/addCustomerNote',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-notes`
      const resp = (await api?.request(endpoint, 'POST', data)) as CustomerNote
      dispatch(showNotification('Customer Note added successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCustomerNote = createAsyncThunk<
  CustomerNote,
  { data: CustomerNoteReq; customerNoteId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'customerNote/updateCustomerNote',
  async ({ data, customerNoteId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-notes/${customerNoteId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as CustomerNote
      dispatch(showNotification('Customer Note updated successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchCustomerNote = createAsyncThunk<
  CustomerNote,
  {
    customerNoteId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'CustomerNote/fetchCustomerNote',
  async (customerNoteId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-notes/${customerNoteId}`
      const resp = (await api?.request(endpoint)) as CustomerNote
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteCustomerNote = createAsyncThunk<
  void,
  {
    customerNoteId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'customerNote/deleteCustomerNote',
  async ({ customerNoteId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-notes/${customerNoteId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Customer note removed.'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const customerNoteSlice = createSlice({
  name: 'customerNote',
  initialState,
  reducers: {
    resetCustomerNote: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addCustomerNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addCustomerNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addCustomerNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCustomerNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCustomerNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateCustomerNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchCustomerNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCustomerNote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchCustomerNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteCustomerNote.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteCustomerNote.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteCustomerNote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCustomerNote } = customerNoteSlice.actions

export const selectCustomerNote = (state: RootState) => state.customerNote

export default customerNoteSlice.reducer
