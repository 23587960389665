import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

export const NavCardsView = styled.div``

export const NavCardsItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -1rem;
`

export const NavCardsItem = styled.div`
  width: 33.33333%;
  padding: 1rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 50%;
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

export const NavCardView = styled.div`
  positiion: relative;
  width: 100%;
  height: 100%;

  & > a {
    width: 100%;
    height: 100%;
    padding: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border-style: solid;
    transition: all 250ms ease;
    border-width: ${props => props.theme.layout.desktop.border};
    border-color: ${props => props.theme.colors.border.primary};
    background-color: ${props => props.theme.colors.background.secondary};
  }

  & > a:hover {
    opacity: 1;
    background-color: ${props => props.theme.colors.background.secondary};
    border-color: ${props => props.theme.colors.border.secondary};
`

export const NavCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const NavCardContent = styled.div`
  flex: 1 1 auto;
  padding: 0 0 2.5rem;
`

export const NavCardTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const NavCardIcon = styled.span`
  display: block;
  margin: 0 0.7rem 0 0;
  line-height: 0;
`

export const NavCardDescription = styled(TextBody)`
  line-height: 1.3;
  margin: 1.5rem 0 0;
`

export const NavCardFooter = styled.div`
  flex: 0 0 auto;
`

export const NavCardCta = styled.span`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  transition: all 250ms ease;

  a:hover & {
    gap: 1.2rem;
  }
`

export const NavCardCtaText = styled(TextBody)`
  display: block;
  // margin: 0 1rem 0 0;
`

export const NavCardAlertView = styled.div`
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 1rem;

  a:hover {
    opacity: 0.75;
  }
`
