import { gql } from 'graphql-request'

export const QUALITY_CONTROL_REPORT_SECTION_QUESTION_WITH_ANSWER_FRAGMENT = gql`
  fragment QualityControlReportSectionQuestionWithAnswerFragment on QualityControlReportSectionQuestionWithAnswer {
    questionId
    question
    questionType
    answerOptions
    answerId
    answer
  }
`

export const QUALITY_CONTROL_REPORT_SECTION_FRAGMENT = gql`
  fragment QualityControlReportSectionFragment on QualityControlReportSection {
    id
    qualityControlReportId
    title
    questionsWithAnswers {
      ...QualityControlReportSectionQuestionWithAnswerFragment
    }
  }
  ${QUALITY_CONTROL_REPORT_SECTION_QUESTION_WITH_ANSWER_FRAGMENT}
`

export const QUALITY_CONTROL_REPORT_FRAGMENT = gql`
  fragment QualityControlReportFragment on QualityControlReport {
    id
    status
    subStatus
    description
    qualityControlReportSections {
      ...QualityControlReportSectionFragment
    }
    createdBy {
      id
      email
      initials
      fullName
    }
    submittedBy {
      id
      email
      initials
      fullName
    }
    updatedBy {
      id
      email
      initials
      fullName
    }
    submittedAt
    createdAt
    updatedAt
  }
  ${QUALITY_CONTROL_REPORT_SECTION_FRAGMENT}
`
