import styled from '@emotion/styled'

const ChannelIndicatorContainer = styled.span`
  display: inline-flex;
  gap: 0.8rem;
`

const ChannelIndicatorColor = styled.div<{ color: string }>`
  border-radius: 50%;
  background-color: ${props => props.color};
  width: 2rem;
  height: 2rem;
`
interface ChannelIndicatorProps {
  color: string
  label: string
}

const ChannelIndicator = ({ color, label }: ChannelIndicatorProps) => {
  return (
    <ChannelIndicatorContainer>
      <ChannelIndicatorColor color={color} />
      {label}
    </ChannelIndicatorContainer>
  )
}

export default ChannelIndicator
