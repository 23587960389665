import { useAppSelector } from 'app/hooks'
import { PageCrumbs } from 'components'
import { useParams } from 'react-router-dom'

import { makeCrumbsTitle } from 'utils'
import storeLinks from './Region.links'

import { selectRegion } from 'slices/region'

const RegionCrumbs = () => {
  const { regionId, ...rest } = useParams()
  const { data: region } = useAppSelector(selectRegion)

  let path = '/stores'
  let links = [{ title: 'Stores', path }]
  path = `${path}/regions`
  links.push({ title: 'Regions', path })

  if (regionId && region) {
    path = `${path}/${regionId}`
    links.push({ title: region.name, path })
  }

  const pageTitle = makeCrumbsTitle(storeLinks, rest['*'])

  return <PageCrumbs links={links} title={pageTitle || 'New'} />
}

export default RegionCrumbs
