import { useState } from 'react'
import { XmarkCircle } from 'iconoir-react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  PreviewSectionTitle,
  PreviewTag,
  PreviewTagsAdd,
  PreviewTagsAddItem,
  PreviewTagsFooter,
  PreviewTagsItems,
  PreviewTagsView
} from './Preview.styled'
import { fetchItem, selectItem } from 'slices/item'
import { createItemAllergens, deleteItemAllergens } from 'slices/itemAllergens'
import { selectAllergens } from 'slices/allergens'
import { RequestStatus, sortBy } from 'utils'
import Button from 'components/Button'
import TextBody from 'components/TextBody'

const PreviewAllergens = ({ itemId }: { itemId: number }) => {
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(false)
  const { data: allergens } = useAppSelector(selectAllergens)
  const { data, loading } = useAppSelector(selectItem)
  const isLoading = loading === RequestStatus.Pending
  const itemAllergens = data?.allergens?.length
    ? sortBy(
        data?.allergens.map(i => ({ ...i.allergen })),
        'name'
      )
    : null
  const currentAllergens = itemAllergens
    ? itemAllergens.map(i => i.allergen_id)
    : []
  const availableAllergens = allergens.filter(
    i => !currentAllergens.includes(i.allergen_id)
  )

  const addAllergen = async (allergenId: number) => {
    setShow(false)
    const data = [{ allergen_id: allergenId }]
    await dispatch(createItemAllergens({ itemId, data }))
    const params = { with_related: 'tags,allergens' }
    dispatch(fetchItem({ itemId, params }))
  }

  const removeAllergen = async (allergenId: number) => {
    const data = [{ allergen_id: allergenId }]
    await dispatch(deleteItemAllergens({ itemId, data }))
    const params = { with_related: 'tags,allergens' }
    dispatch(fetchItem({ itemId, params }))
  }

  return (
    <PreviewTagsView>
      <PreviewSectionTitle as="p" size="xlarge">
        Allergens
      </PreviewSectionTitle>
      {itemAllergens ? (
        <PreviewTagsItems>
          {itemAllergens.map(allergen => (
            <PreviewTag
              key={allergen.allergen_id}
              size="xsmall"
              as="button"
              onClick={() => removeAllergen(allergen.allergen_id)}
            >
              <span>{allergen.name}</span>
              <XmarkCircle width="1.4rem" height="1.4rem" strokeWidth={1} />
            </PreviewTag>
          ))}
        </PreviewTagsItems>
      ) : (
        <TextBody as="p" size="xsmall">
          This item doesn't have any allergens.
        </TextBody>
      )}

      <PreviewTagsFooter>
        <Button
          size="small"
          color={show ? 'delete' : 'primary'}
          onClick={() => setShow(!show)}
          disabled={availableAllergens.length === 0}
        >
          {show ? 'Cancel add allergen' : 'Add allergen'}
        </Button>
        {availableAllergens.length > 0 && (
          <PreviewTagsAdd show={show}>
            {availableAllergens.map(i => (
              <PreviewTagsAddItem
                key={i.allergen_id}
                onClick={() => addAllergen(i.allergen_id)}
                disabled={isLoading}
              >
                {i.name}
              </PreviewTagsAddItem>
            ))}
          </PreviewTagsAdd>
        )}
      </PreviewTagsFooter>
    </PreviewTagsView>
  )
}

export default PreviewAllergens
