import styled from '@emotion/styled'
import ScrollHide from 'components/ScrollHide'
import TextBody from 'components/TextBody'

interface SidebarViewProps {
  showSidebar: boolean
}

export const SidebarView = styled(ScrollHide)<SidebarViewProps>`
  label: Sidebar;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 8rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  overflow-y: auto;
  overflow-x: hidden;
  // transform: translateX(0);
  transform: translateX(${props => (props.showSidebar ? '0' : '-8rem')});
  background-color: ${props => props.theme.colors.sidebar.background};
  border-right: 0.1rem solid ${props => props.theme.colors.sidebar.border};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 20rem;
    height: 100%;
    height: -webkit-fill-available;
    transform: translateX(${props => (props.showSidebar ? '0' : '-20rem')});
  }

  &:hover {
    width: 20rem;
  }
`

export const SidebarHeader = styled.div`
  label: SidebarHeader;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  flex: 0 0 ${props => props.theme.layout.desktop.header.height};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex: 0 0 ${props => props.theme.layout.mobile.header.height};
    padding: 5rem 1rem 0;
  }

  a {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
    color: ${props => props.theme.colors.sidebar.color};
    transition: all 0.2s cubic-bezier(0.175, 0.685, 0.32, 1);
  }

  a:hover,
  a:active {
    background-color: ${props => props.theme.colors.sidebar.hoverBackground};
  }
`

export const SidebarLogo = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${props => props.theme.colors.sidebar.hoverBackground};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const SidebarHeaderText = styled(TextBody)`
  display: block;
  line-height: 1;
  flex-grow: 1;
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  opacity: 0;
  // max-width: 100%;
  white-space: nowrap;
  color: ${props => props.theme.colors.sidebar.color};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    opacity: 1;
    max-width: 100%;
  }

  .icon-bar:hover & {
    opacity: 1;
    max-width: 100%;
  }
`

export const SidebarContent = styled.div`
  label: SidebarContent;
  flex-grow: 1;
  // background-color: lightblue;
`

export const SidebarFooter = styled.div`
  label: SidebarFooter;
  // padding: 6rem 0 0;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding-bottom: env(safe-area-inset-bottom);
  }
`

export const SidebarLinks = styled.ul`
  label: SidebarLinks;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`

export const SidebarLinkView = styled.li`
  label: SidebarLink;
  display: block;
  width: 100%;
  margin: 0.5rem 0;
  font-size: ${props => props.theme.sizes.medium};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 0.2rem 0;
  }

  a {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    color: ${props => props.theme.colors.sidebar.color};
    transition: all 0.2s cubic-bezier(0.175, 0.685, 0.32, 1);
  }

  a:hover,
  a:active {
    background-color: ${props => props.theme.colors.sidebar.hoverBackground};
  }

  a.active {
    color: ${props => props.theme.colors.sidebar.hoverColor};
  }
`

export const SidebarLinkIcon = styled.span`
  label: SidebarIcon;
  position: relative;
  display: flex;
  justify-content: center;
  line-height: 0;
  flex: 0 0 6rem;
  width: 6rem;
`

export const SidebarLinkText = styled.span`
  display: block;
  line-height: 1;
  flex-grow: 1;
  letter-spacing: 0;
  font-weight: 425;
  font-variation-settings: 'wght' 425;
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  opacity: 0;
  max-width: 0;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    opacity: 1;
    max-width: 100%;
  }

  .icon-bar:hover & {
    opacity: 1;
    max-width: 100%;
  }
`

export const SidebarToggle = styled.div`
  padding: 0.5rem 0 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const SidebarToggleButton = styled.div`
  flex: 0 0 8rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SidebarToggleText = styled(TextBody)`
  display: block;
  line-height: 1;
  flex-grow: 1;
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  opacity: 0;
  max-width: 0;
  color: ${props => props.theme.colors.sidebar.color};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    opacity: 1;
    max-width: 100%;
  }

  .icon-bar:hover & {
    opacity: 1;
    max-width: 100%;
  }
`
