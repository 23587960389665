import { useEffect, useMemo, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import { ColumnsInterface } from 'types'
import { capitalize } from 'utils'
import {
  ColumnsColumn,
  ColumnsItem,
  ColumnsItems,
  ColumnsView
} from './Columns.styled'
import Label from '../Label'
import TextHeading from 'components/TextHeading'

interface DisplayColumns extends Record<string, string[]> {
  HIDDEN: string[]
  DISPLAYED: string[]
}

const onDragEnd = (
  result: DropResult,
  columns: DisplayColumns,
  setColumns: (columns: DisplayColumns) => void
) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceItems = columns[source.droppableId]
    const destItems = columns[destination.droppableId]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: sourceItems,
      [destination.droppableId]: destItems
    })
  } else {
    const items = columns[source.droppableId]
    const copiedItems = [...items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: copiedItems
    })
  }
}

const Columns = ({
  label,
  name,
  value,
  options,
  onChange,
  error,
  required
}: ColumnsInterface) => {
  const [columns, setColumns] = useState<DisplayColumns>({
    HIDDEN: [],
    DISPLAYED: []
  })
  const stringified = JSON.stringify(columns.DISPLAYED)

  const cleanedValue = useMemo(
    () => value?.filter(key => options.some(({ value }) => value === key)),
    [value, options]
  )

  useEffect(() => {
    if (cleanedValue) {
      const newColumns = {
        HIDDEN: options
          .filter(i => !cleanedValue.includes(i.value))
          .map(i => i.value),
        DISPLAYED: [...cleanedValue]
      }
      setColumns(newColumns)
    }
  }, [options, cleanedValue])

  useEffect(() => {
    onChange(columns.DISPLAYED)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringified])

  return (
    <Label htmlFor={name} label={label} required={required} errMsg={error}>
      <ColumnsView>
        <DragDropContext
          onDragEnd={result => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, items], index) => {
            return (
              <ColumnsColumn key={columnId}>
                <TextHeading>{capitalize(columnId)}</TextHeading>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <ColumnsItems
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={snapshot.isDraggingOver ? '-active' : ''}
                      >
                        {items.map((item, index) => {
                          return (
                            <Draggable
                              key={item}
                              draggableId={item}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <ColumnsItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={
                                      snapshot.isDragging ? '-active' : ''
                                    }
                                    style={{ ...provided.draggableProps.style }}
                                  >
                                    {options.find(i => item === i.value)?.text}
                                  </ColumnsItem>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </ColumnsItems>
                    )
                  }}
                </Droppable>
              </ColumnsColumn>
            )
          })}
        </DragDropContext>
      </ColumnsView>
    </Label>
  )
}

export default Columns
