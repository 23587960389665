import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Form } from 'components'
import { FormFieldType, Customer } from 'types'
import { RequestStatus, omit } from 'utils'
import { addCustomer, updateCustomer, selectCustomer } from 'slices/customer'
import { useEffect, useState } from 'react'

const CustomerForm = ({
  customerId,
  children,
  callback
}: {
  customerId: string | number
  children?: React.ReactNode
  callback?: (data: Customer) => void
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { data, error, loading } = useAppSelector(selectCustomer)
  const isLoading = loading === RequestStatus.Pending

  const formData = data
    ? omit<Customer, 'customer_id'>(data, 'customer_id')
    : customerEmpty

  const submit = (values: Customer) => {
    setSubmitted(true)
    let augmented = { ...values }
    delete augmented.blocked_cards
    delete augmented.blocked_emails
    delete augmented.blocked_phones
    delete augmented.created
    delete augmented.customer_addresses
    delete augmented.customer_notification_preferences
    delete augmented.is_notification_set
    delete augmented.is_verified
    delete augmented.last_ordered
    delete augmented.last_ordered_business_date
    delete augmented.orders
    delete augmented.updated
    delete augmented.loyalty_programs
    delete augmented.notes
    if (data) {
      dispatch(updateCustomer({ data: augmented, customerId }))
    } else {
      dispatch(addCustomer({ data: values }))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (!error && data && callback) {
        callback(data)
      }
    }
  }, [submitted, isLoading, error, data, callback])

  return (
    <Form
      fields={customerFields}
      data={formData || customerEmpty}
      error={error}
      isLoading={isLoading}
      submit={submit}
    >
      {children}
    </Form>
  )
}

const customerEmpty = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  phone: '',
  company: '',
  birth_date: null
}

const customerFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    label: 'First Name',
    name: 'first_name'
  },
  {
    fieldType: 'Input',
    type: 'text',
    label: 'Last Name',
    name: 'last_name'
  },
  {
    fieldType: 'Input',
    type: 'email',
    label: 'Email',
    name: 'email',
    autoComplete: 'off',
    required: true,
    error: 'Please enter an email'
  },
  {
    fieldType: 'Input',
    type: 'password',
    label: 'Password',
    name: 'password',
    autoComplete: 'off',
    required: true,
    error: 'Please enter password'
  },
  {
    fieldType: 'Input',
    type: 'text',
    label: 'Phone Number',
    name: 'phone'
  },
  {
    fieldType: 'Input',
    type: 'text',
    label: 'Company',
    name: 'company'
  }
]

export default CustomerForm
