import React from 'react'

const Allergen = React.lazy(() => import('./Allergens/Allergen'))
const AllergensOutlet = React.lazy(() => import('./Allergens/AllergensOutlet'))

const Announcement = React.lazy(() => import('./Announcements/Announcement'))
const AnnouncementsOutlet = React.lazy(
  () => import('./Announcements/AnnouncementsOutlet')
)

const Discount = React.lazy(() => import('./Discounts/Discount'))
const DiscountsOutlet = React.lazy(() => import('./Discounts/DiscountsOutlet'))

const Font = React.lazy(() => import('./Fonts/Font'))
const FontsOutlet = React.lazy(() => import('./Fonts/FontsOutlet'))

const ItemType = React.lazy(() => import('./ItemTypes/ItemType'))
const ItemTypesOutlet = React.lazy(() => import('./ItemTypes/ItemTypesOutlet'))

const Oauth2Client = React.lazy(() => import('./Oauth2Clients/Oauth2Client'))
const Oauth2ClientsOutlet = React.lazy(
  () => import('./Oauth2Clients/Oauth2ClientsOutlet')
)

const Settings = React.lazy(() => import('./Settings'))
const SettingsRoutes = React.lazy(() => import('./SettingsRoutes'))

const Surcharge = React.lazy(() => import('./Surcharges/Surcharge'))
const SurchargesOutlet = React.lazy(
  () => import('./Surcharges/SurchargesOutlet')
)

const Tag = React.lazy(() => import('./Tags/Tag'))
const TagsOutlet = React.lazy(() => import('./Tags/TagsOutlet'))

const Tax = React.lazy(() => import('./Taxes/Tax'))
const TaxesOutlet = React.lazy(() => import('./Taxes/TaxesOutlet'))

const User = React.lazy(() => import('./Users/User'))
const UsersOutlet = React.lazy(() => import('./Users/UsersOutlet'))

export {
  Allergen,
  AllergensOutlet,
  Announcement,
  AnnouncementsOutlet,
  Discount,
  DiscountsOutlet,
  Font,
  FontsOutlet,
  ItemType,
  ItemTypesOutlet,
  Oauth2Client,
  Oauth2ClientsOutlet,
  Settings,
  SettingsRoutes,
  Surcharge,
  SurchargesOutlet,
  Tag,
  TagsOutlet,
  Tax,
  TaxesOutlet,
  User,
  UsersOutlet
}
