import styled from '@emotion/styled'
import DesignInput from './DesignInput'
import DesignLabel from './DesignLabel'

const InputTextView = styled.span`
  position: relative;
  flex: 0 0 ${props => props.theme.layout.desktop.inputWidth};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex: 0 0 ${props => props.theme.layout.mobile.inputWidth};
  }
`

const InputText = ({
  label,
  required,
  name,
  value,
  setValue,
  inherited = false
}: {
  label: string
  name: string
  value: string
  setValue: (value: string | number | null) => void
  required?: boolean
  inherited?: boolean
}) => {
  return (
    <DesignLabel
      label={label}
      required={required}
      htmlFor={name}
      inherited={inherited}
    >
      <InputTextView>
        <DesignInput
          type="text"
          name={name}
          value={value}
          setValue={setValue}
          inherited={inherited}
        />
      </InputTextView>
    </DesignLabel>
  )
}

export default InputText
