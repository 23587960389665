import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const LegendView = styled.div`
  display: block;
  padding: 0 0 1rem;
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
  margin: 3rem 0 3rem;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 2rem 0;
  }
`

export const LegendTitle = styled(TextHeading)``

export const LegendSubtitle = styled(TextBody)`
  margin: 1rem 0 0;
  line-height: 1.4;
`
