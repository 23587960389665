import styled from '@emotion/styled'
import { ThemeFontSizes } from 'types'

export const OrderCartItems = styled.div``

export const OrderCartItemWrapperView = styled.span`
  display: block;
  border-bottom: 1px solid ${props => props.theme.colors.border.primary};
`

export const OrderCartModifierWrapperView = styled.span`
  display: block;
`

export const OrderCartTotal = styled.div<{
  size?: keyof ThemeFontSizes
}>`
  display: flex;
  justify-content: space-between;
  align-items: flex;
  padding: 1.2rem 0;
  font-size: ${props => props.theme.sizes[props.size || 'small']};
`

export const OrderCartItemView = styled.div<{
  size?: keyof ThemeFontSizes
}>`
  // display: flex;
  // justify-content: space-between;
  // align-items: flex;
  padding: 1.2rem 0;
  font-size: ${props => props.theme.sizes[props.size || 'small']};
`

export const OrderCartModifierView = styled.div<{
  size?: keyof ThemeFontSizes
}>`
  padding: 0rem 0 1.2rem;
  font-size: ${props => props.theme.sizes[props.size || 'small']};
`

export const OrderCartItemName = styled.span`
  display: inline-block;
  width: 52.5%;
`

export const OrderCartItemPrice = styled.span`
  display: inline-block;
  width: 17.5%;
  text-align: right;
`

export const OrderCartItemQuantity = styled.span`
  display: inline-block;
  width: 12.5%;
  text-align: right;
  // background-color: pink;
`

export const OrderCartItemGroups = styled.span`
  display: block;
  // padding-left: 2rem;
`
