import styled from '@emotion/styled'
import TextHeading from 'components/TextHeading'

interface DesignTitleViewProps {
  required?: boolean
}

const DesignTitleView = styled.div<DesignTitleViewProps>`
  padding: 2rem 0 1rem;
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
  margin: 0 0 1rem;

  &:first-of-type {
    padding: 1rem 0 1rem;
  }
`

interface DesignTitleProps {
  label: string
  required?: boolean
}

const DesignTitle = ({ label, required }: DesignTitleProps) => {
  return (
    <DesignTitleView required={required}>
      <TextHeading as="p" size="small">
        {label}
      </TextHeading>
    </DesignTitleView>
  )
}

export default DesignTitle
