import { Fragment } from 'react'
import { MenuCategory } from '@open-tender/types'
import { ButtonLink, TextBody } from 'components'
import { MenuNavSeparator, MenuNavButton, MenuNavView } from './Menu.styled'

const MenuNav = ({
  menu,
  scrollTo
}: {
  menu: MenuCategory[]
  scrollTo: (slug: string) => void
}) => {
  return (
    <MenuNavView>
      {menu.map((category, index) => {
        const isLast = index === menu.length - 1
        return (
          <Fragment key={category.id}>
            <MenuNavButton>
              <ButtonLink onClick={() => scrollTo(category.slug)} size="medium">
                {category.name}
              </ButtonLink>
            </MenuNavButton>
            {!isLast && (
              <MenuNavSeparator>
                <TextBody size="small">/</TextBody>
              </MenuNavSeparator>
            )}
          </Fragment>
        )
      })}
    </MenuNavView>
  )
}

export default MenuNav
