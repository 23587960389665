import ButtonLink from 'components/ButtonLink'
import { ButtonLinksWrap, ButtonSeperation } from './ButtonLinks.styled'

interface ButtonLinksProps {
  disableClearAll?: boolean
  disableSelectAll?: boolean
  clearAll?: () => void
  selectAll?: () => void
}

const Link = ({
  disableClearAll,
  disableSelectAll,
  clearAll,
  selectAll
}: ButtonLinksProps) => {
  return (
    <ButtonLinksWrap>
      {clearAll && (
        <ButtonLink disabled={disableClearAll} onClick={clearAll}>
          Clear All
        </ButtonLink>
      )}
      {clearAll && selectAll && <ButtonSeperation>|</ButtonSeperation>}
      {selectAll && (
        <ButtonLink onClick={selectAll} disabled={disableSelectAll}>
          Select All
        </ButtonLink>
      )}
    </ButtonLinksWrap>
  )
}

export default Link
