import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { RevenueCenterBlockedHours } from 'types'
import { showNotification } from './notification'

export interface RevenueCenterBlockedHoursState {
  data: RevenueCenterBlockedHours | null
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterBlockedHoursState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface RevenueCenterBlockedHoursAPI {
  data: RevenueCenterBlockedHours
  links: {
    next?: string
  }
}

export type RevenueCenterBlockedHoursParams = {
  cursor?: string
  limit?: number
  revenue_center_id?: number
}

export const fetchRevenueCenterBlockedHours = createAsyncThunk<
  RevenueCenterBlockedHoursAPI,
  RevenueCenterBlockedHoursParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterBlockedHours/fetchRevenueCenterBlockedHours',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `blocked-hours${queryParams}`
      const api = getState().authUser.api
      const resp = (await api?.request(
        endpoint
      )) as RevenueCenterBlockedHoursAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const createRevenueCenterBlockedHours = createAsyncThunk<
  RevenueCenterBlockedHours,
  { data: RevenueCenterBlockedHours },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterBlockedHour/createRevenueCenterBlockedHour',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-hours`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as RevenueCenterBlockedHours
      dispatch(showNotification('Blocked Hours successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterBlockedHours = createAsyncThunk<
  void,
  { data: RevenueCenterBlockedHours },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterBlockedHour/deletedRevenueCenterBlockedHour',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-hours`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Blocked Hours successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterBlockedHoursSlice = createSlice({
  name: 'revenueCenterBlockedHours',
  initialState,
  reducers: {
    resetRevenueCenterBlockedHours: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterBlockedHours.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchRevenueCenterBlockedHours.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload.data || initialState.data
        state.error = null
      }
    )
    builder.addCase(
      fetchRevenueCenterBlockedHours.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(createRevenueCenterBlockedHours.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      createRevenueCenterBlockedHours.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      createRevenueCenterBlockedHours.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteRevenueCenterBlockedHours.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterBlockedHours.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      deleteRevenueCenterBlockedHours.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetRevenueCenterBlockedHours } =
  revenueCenterBlockedHoursSlice.actions

export const selectRevenueCenterBlockedHours = (state: RootState) =>
  state.revenueCenterBlockedHours

export default revenueCenterBlockedHoursSlice.reducer
