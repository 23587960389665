import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { stateKey } from 'config/constants'
import rootReducer from 'slices'
import { throttle } from 'utils/helpers'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from 'utils/localStorage'

const preloadedState = getLocalStorageItem<Record<string, unknown>>(stateKey)

const appReducer = (state: any, action: any) => {
  if (action.type === 'clearState') {
    removeLocalStorageItem(stateKey)
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

export const store = configureStore({
  reducer: appReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }),
  ...(preloadedState ? { preloadedState } : {})
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const setLocalStorageState = (state: RootState) => {
  const { notifications, modal, auth, authUser, iacmUser, ...rest } = state
  const authWithoutLoading = { ...auth, loading: false }
  const authUserWithoutApi = { ...authUser, api: null }
  const iacmUserWithoutApi = { ...iacmUser, api: null }
  const saveableState = {
    ...rest,
    auth: authWithoutLoading,
    authUser: authUserWithoutApi,
    iacmUser: iacmUserWithoutApi
  }
  setLocalStorageItem(JSON.stringify(saveableState), stateKey)
}

store.subscribe(
  throttle(() => {
    setLocalStorageState(store.getState())
  }, 3000)
)
