import { useEffect, useState } from 'react'
import { ModalWindow, Location } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal } from 'slices/modal'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { Button, ErrMsg } from 'components'
import { RequestStatus, capitalize, makeErrMsg } from 'utils'
import {
  selectOnboardingLocation,
  updateLocation
} from 'slices/iacm/onboardingLocation'

const ArchiveLocation = ({
  args,
  modalWindow
}: {
  args: {
    location: Location
    fetchLocations: () => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { location, fetchLocations } = args
  const { name, id } = location
  const isArchived = location.state.archived
  const { loading, error } = useAppSelector(selectOnboardingLocation)
  const isLoading = loading === RequestStatus.Pending
  const [submitting, setSubmitting] = useState(false)
  const errMsg = makeErrMsg(error)
  const verb = isArchived ? 'unarchive' : 'archive'
  const archive = () => {
    setSubmitting(true)

    const params = { archived: !isArchived, id: id }
    dispatch(updateLocation(params))
  }

  useEffect(() => {
    if (submitting && !isLoading) {
      setSubmitting(false)
      if (!errMsg) {
        fetchLocations()
        dispatch(closeModal())
      }
    }
  }, [submitting, isLoading, errMsg, dispatch, fetchLocations])

  return (
    <>
      <ModalHeader title={name} />
      <ModalContent>
        {error && <ErrMsg errMsg={error.detail} />}
        <p>
          Are you sure you want to <strong>{verb}</strong> this location?
        </p>
        {!isArchived && (
          <p>
            Archiving this location will hide it from view in the Onboarding and
            Partner Portals. This should be done for locations that were
            mistakenly created, are duplicates or are no longer needed for other
            reasons. Please do not archive churned locations.
          </p>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          color={isArchived ? 'primary' : 'delete'}
          onClick={archive}
          disabled={loading === RequestStatus.Pending}
        >
          {capitalize(verb)}
        </Button>
        <Button color="secondary" onClick={() => dispatch(closeModal())}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  )
}

export default ArchiveLocation
