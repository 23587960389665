import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { RevenueCenterTenderTypes } from 'types'
import { showNotification } from 'slices/notification'

export interface TenderTypesState {
  data: RevenueCenterTenderTypes | null
  loading: RequestStatus
  error: RequestError
}

const initialState: TenderTypesState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchTenderTypes = createAsyncThunk<
  RevenueCenterTenderTypes,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'tenderTypes/fetchTenderTypes',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/tender-types`
      const resp = (await api?.request(endpoint)) as RevenueCenterTenderTypes
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateTenderTypes = createAsyncThunk<
  RevenueCenterTenderTypes,
  { revenueCenterId: string | number; data: RevenueCenterTenderTypes },
  { state: RootState; rejectValue: RequestError }
>(
  'tenderTypes/updateTenderTypes',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/tender-types`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as RevenueCenterTenderTypes
      dispatch(showNotification('Tender types successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const tenderTypesSlice = createSlice({
  name: 'tenderTypes',
  initialState,
  reducers: {
    resetTenderTypes: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTenderTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTenderTypes.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchTenderTypes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateTenderTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateTenderTypes.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateTenderTypes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTenderTypes } = tenderTypesSlice.actions

export const selectTenderTypes = (state: RootState) => state.tenderTypes

export default tenderTypesSlice.reducer
