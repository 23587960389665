import { useEffect, useState } from 'react'
import { OrderCustomerGiftCard } from '@open-tender/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { addTender, selectAmountRemaining } from 'slices/order'
import { closeModal } from 'slices/modal'
import { ModalWindow } from 'types'
import { Button, Input } from 'components'
import { ModalHeader } from 'components/Modal'
import { formatDollars } from 'utils'

const OrderGiftCard = ({
  args,
  modalWindow
}: {
  args: {
    giftCard: OrderCustomerGiftCard
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { giftCard } = args
  const [submitted, setSubmitted] = useState(false)
  const [amount, setAmount] = useState<string | null>(null)
  const remaining = useAppSelector(selectAmountRemaining)
  const { card_number, balance } = giftCard
  const cardBalance = parseFloat(balance)
  const maxAmount = Math.min(remaining, cardBalance)
  const amountRemaining = formatDollars(maxAmount.toString())
  const amountType =
    cardBalance < remaining ? 'card balance' : 'amount remaining'
  const title = `Gift Card ${card_number}`
  const subtitle = `Enter amount less than or equal to ${amountType} of ${amountRemaining}`
  const current = amount ? Number(amount) : 0.0
  const isAmountValid = current > 0 && current <= maxAmount ? true : false
  const error = !submitted && current > maxAmount ? subtitle : undefined

  const add = () => {
    if (!isAmountValid) return
    setSubmitted(true)
    const adjusted = current.toFixed(2)
    const tender = {
      tender_type: 'GIFT_CARD',
      amount: adjusted,
      card_number
    }
    dispatch(addTender(tender))
    dispatch(closeModal())
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(evt.target.value)
  }

  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') add()
  }

  useEffect(() => {
    if (amount === null) {
      setAmount(maxAmount.toFixed(2))
    }
  }, [amount, maxAmount])

  return (
    <>
      <ModalHeader title={title} subtitle={subtitle} />
      <Input
        type="number"
        name="amount"
        label="Tender Amount"
        value={amount || ''}
        error={error}
        onChange={onChange}
        onKeyDown={onKeyPress}
      />
      <Button onClick={add} disabled={!isAmountValid}>
        Apply Payment
      </Button>
    </>
  )
}

export default OrderGiftCard
