import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LoyaltyTierReward } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface LoyaltyTierRewardState {
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyTierRewardState = {
  loading: RequestStatus.Idle,
  error: null
}

export const createLoyaltyTierReward = createAsyncThunk<
  void,
  { data: LoyaltyTierReward; loyalty_tier_id: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyTierReward/createLoyaltyTierReward',
  async (
    { data, loyalty_tier_id },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-tiers/${loyalty_tier_id}/grant-discount`
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Reward Granted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyTierRewardSlice = createSlice({
  name: 'loyaltyTierReward',
  initialState,
  reducers: {
    resetLoyaltyTierReward: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createLoyaltyTierReward.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyTierReward.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createLoyaltyTierReward.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyTierReward } = loyaltyTierRewardSlice.actions

export const selectLoyaltyTierReward = (state: RootState) =>
  state.loyaltyTierReward

export default loyaltyTierRewardSlice.reducer
