import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Items, Item, MenuGroupType } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface ItemsState {
  data: Items
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface ItemsAPI {
  data: Items
  links: {
    next: string
  }
}

export type ItemsParams = {
  cursor?: string
  limit?: number
  expand?: number
  with_related?: string
  is_active?: boolean
  category_id?: number
  modifier_group_id?: number
  search?: string
  not_assigned_only?: boolean
}

const initialState: ItemsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItems = createAsyncThunk<
  ItemsAPI,
  ItemsParams | void,
  { state: RootState; rejectValue: RequestError }
>('items/fetchItems', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `items${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as ItemsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const fetchItemsForGroups = createAsyncThunk<
  Items,
  { groupType: MenuGroupType; ids: number[] },
  { state: RootState; rejectValue: RequestError }
>(
  'items/fetchItemsForGroups',
  async ({ groupType, ids }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const groupId =
        groupType === 'CATEGORY' ? 'category_id' : 'modifier_group_id'
      const promises = ids.map(id => {
        const endpoint = `items?${groupId}=${id}&with_related=true&expand=true`
        return api?.request(endpoint)
      })
      const resp = (await Promise.all(promises)) as ItemsAPI[]
      return resp.reduce((arr, category) => {
        return [...arr, ...category.data]
      }, [] as Item[])
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItemForGroups = createAsyncThunk<
  Items,
  number | string,
  { state: RootState; rejectValue: RequestError }
>(
  'items/updateItemForGroups',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const item_id = parseInt(itemId.toString())
      const endpoint = `items/${item_id}?with_related=true&expand=true`
      const resp = (await api?.request(endpoint)) as Item
      const other = getState().items.data.filter(i => i.item_id !== item_id)
      return [...other, resp] as Item[]
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    resetItems: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItems.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItems.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchItems.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchItemsForGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemsForGroups.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchItemsForGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItemForGroups.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItemForGroups.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateItemForGroups.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItems } = itemsSlice.actions

export const selectItems = (state: RootState) => state.items

export const selectItemsForGroups = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.items
    return { data, loading, error }
  },
  ({ data, loading, error }) => {
    const lookup = data.reduce(
      (obj, item) => {
        return { ...obj, [item.item_id]: item }
      },
      {} as Record<string, Item>
    )
    return { lookup, loading, error }
  }
)

export default itemsSlice.reducer
