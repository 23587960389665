import { useAppDispatch, useAppSelector } from 'app/hooks'
import React, { useEffect, useRef } from 'react'
import {
  resetOrderPrint,
  selectOrderMgmtOrderPrint,
  selectOrderMgmtOrders
} from 'slices/orderMgmt'
import { useReactToPrint } from 'react-to-print'
import { BulkOrder, BulkOrders } from 'types'
import {
  PrintOrderContainer,
  PrintOrderContent,
  PrintOrderDesktop,
  PrintOrderDesktopLeft,
  PrintOrderDesktopRight,
  PrintOrderDetailsSection,
  PrintOrderHeader
} from './PrintOrder.styled'
import { ReceiptRow, makeFulfillment, makeReceipt } from './PrintOrder.utils'
import PrintOrderItems from './PrintOrderItems'
import {
  PackedBySection,
  PrintSectionRow,
  PrintSectionTitle,
  ReceiptSection,
  SignatureSection
} from './PrintSections'
import { replaceAmPm, zonedDateStrToDateStr } from 'utils'

const makePrinterType = (orderPrint: BulkOrder | null, orders: BulkOrders) => {
  if (!orderPrint) return 'DESKTOP'
  const order = orders.find(i => i.receipt_id === orderPrint.receipt_id)
  return order ? order.revenue_center?.printer_type : 'DESKTOP'
}

const PrintOrder = () => {
  const dispatch = useAppDispatch()
  const orders = useAppSelector(selectOrderMgmtOrders)
  const order = useAppSelector(selectOrderMgmtOrderPrint)
  const printOrderRef = useRef<HTMLDivElement>(null)
  const printerType = makePrinterType(order, orders.orders)

  const handlePrint = useReactToPrint({
    content: () => printOrderRef.current,
    onAfterPrint: () => dispatch(resetOrderPrint())
  })

  useEffect(() => {
    handlePrint()
  }, [handlePrint])

  if (!order) return
  const orderType = order.order_type === 'MAIN_MENU' ? 'OLO' : order.order_type
  const isCatering = orderType === 'CATERING'
  const isDelivery = order.service_type === 'DELIVERY'

  const receipt = makeReceipt(order)
  const fulfillment = makeFulfillment(order.order_fulfillment)
  let addressSectionPickUpMsg: ReceiptRow[] = []
  if (fulfillment) {
    addressSectionPickUpMsg = [{ label: 'Curbside', value: fulfillment }]
  }
  const addressSectionContent = isDelivery
    ? receipt.addressSection
    : addressSectionPickUpMsg

  const requestedAt = replaceAmPm(
    zonedDateStrToDateStr(
      order.requested_at,
      order.timezone,
      "EEE, MMM dd '@' h:mma"
    )
  )
  const isReceipt = printerType === 'RECEIPT'
  const isDesktop = printerType === 'DESKTOP'

  return (
    <PrintOrderContainer>
      <PrintOrderContent isReceipt={isReceipt} ref={printOrderRef}>
        <PrintOrderHeader isReceipt={isReceipt}>
          <p>{order.revenue_center?.short_name}</p>
          <h2>
            <span>
              {orderType} Order #{order.receipt_id}
            </span>
            {isDesktop && <span> -- </span>}
            <span>{order.service_type}</span>
            {isDesktop && <span> -- </span>}
            <span>{requestedAt}</span>
          </h2>
        </PrintOrderHeader>
        {isDesktop ? (
          <PrintOrderDesktop>
            <PrintOrderDesktopLeft>
              <ReceiptSection
                title="ORDER PLACED BY"
                content={receipt.customerSection}
              />
              <ReceiptSection
                title={
                  isDelivery
                    ? 'Delivery Address & Contact Info'
                    : 'Pickup order - address not needed'
                }
                content={addressSectionContent}
              />
              <ReceiptSection title="Totals" content={receipt.totalsSection} />
              {isCatering && <SignatureSection />}
            </PrintOrderDesktopLeft>
            <PrintOrderDesktopRight>
              <>
                <PrintSectionTitle title="Order Details" />
                <PrintOrderDetailsSection>
                  {receipt.orderDetailsSection?.map((row, i) => (
                    <PrintSectionRow key={i} {...row} />
                  ))}
                </PrintOrderDetailsSection>
              </>
              <ReceiptSection
                title="Customer Notes"
                content={receipt.customerNotesSection}
              />
              <ReceiptSection
                title="Internal Notes"
                content={receipt.internalNotesSection}
              />
              {isCatering && <PackedBySection />}
              <PrintOrderItems order={order} />
            </PrintOrderDesktopRight>
          </PrintOrderDesktop>
        ) : (
          <div>
            <ReceiptSection
              title="ORDER PLACED BY"
              content={receipt.customerSection}
              isReceipt={isReceipt}
            />
            <PrintOrderItems order={order} isReceipt={isReceipt} />
            <ReceiptSection
              title="Order Details"
              content={receipt.orderDetailsSection}
              isReceipt={isReceipt}
            />
            <ReceiptSection
              title="Customer Notes"
              content={receipt.customerNotesSection}
              isReceipt={isReceipt}
            />
            <ReceiptSection
              title="Internal Notes"
              content={receipt.internalNotesSection}
              isReceipt={isReceipt}
            />
            {isCatering && <PackedBySection />}
            <ReceiptSection
              title={
                isDelivery
                  ? 'Delivery Address & Contact Info'
                  : 'Pickup order - address not needed'
              }
              content={addressSectionContent}
              isReceipt={isReceipt}
            />
            <ReceiptSection
              title="Totals"
              content={receipt.totalsSection}
              isReceipt={isReceipt}
            />
            {isCatering && <SignatureSection />}
          </div>
        )}
      </PrintOrderContent>
    </PrintOrderContainer>
  )
}

export default PrintOrder
