import styled from '@emotion/styled'

export const CheckboxLabel = styled.label`
  margin: 0 0 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
`

export const CheckboxInput = styled.input`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
`

export const CheckboxView = styled.span`
  content: '';
  flex-shrink: 0;
  display: block;
  position: relative;
  width: 1.7rem;
  height: 1.7rem;
  padding: 0;
  margin: 0;
  border-radius: 0.3rem;
  border: 0.1rem solid ${props => props.theme.inputs.checkboxBorderColor};
  background-color: ${props => props.theme.inputs.backgroundColor};
  background-color: inherit;

  &:before {
    content: '';
    position: absolute;
    width: 1.1rem;
    height: 0.6rem;
    background: transparent;
    top: 0.3rem;
    left: 0.2rem;
    border-width: 0.2rem;
    border-style: solid;
    border-top: none;
    border-right: none;
    transform: scale(1) rotate(-45deg);
    opacity: 0;
    color: ${props => props.theme.colors.text.primary};
  }
  input:checked + & {
    opacity: 1;
  }
  input:checked + &:before {
    opacity: 1;
  }

  input:checked:disabled + & {
    opacity: 0.5; // You might want to adjust this based on your design
  }

  input:disabled + & {
    opacity: 0.5;
  }
`

export const CheckboxText = styled.span<{
  disabled?: boolean
  checked?: boolean
}>`
  display: block;
  margin: 0 0 0 1rem;
  color: ${props => props.theme.colors.text.primary};
  opacity: ${props => (props.disabled && !props.checked ? '0.5' : '1')};
  font-size: ${props => props.theme.inputs.fontSize};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  line-height: ${props => props.theme.inputs.lineHeight};
`
