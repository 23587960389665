import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { BulkOrder, OrderRemainingRefund } from 'types'
import { showNotification } from './notification'
import { NavigateFunction } from 'react-router-dom'

export interface PartialRefundState {
  data: BulkOrder | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PartialRefundState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const calculatePartialRefund = createAsyncThunk<
  BulkOrder,
  { orderId: string | number; data: OrderRemainingRefund },
  { state: RootState; rejectValue: RequestError }
>(
  'partialRefund/calculatePartialRefund',
  async ({ orderId, data }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `orders/${orderId}/calculate-partial-refund`
      const resp = (await api?.request(endpoint, 'POST', data)) as BulkOrder
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const applyPartialRefund = createAsyncThunk<
  BulkOrder,
  {
    orderId: string | number
    data: OrderRemainingRefund
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'partialRefund/applyPartialRefund',
  async (
    { orderId, data, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `orders/${orderId}/apply-partial-refund`
      const resp = (await api?.request(endpoint, 'POST', data)) as BulkOrder
      dispatch(showNotification('Refund applied successfully'))
      const pathname = `/orders/${orderId}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const partialRefundSlice = createSlice({
  name: 'partialRefund',
  initialState,
  reducers: {
    resetPartialRefund: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(calculatePartialRefund.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(calculatePartialRefund.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(calculatePartialRefund.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(applyPartialRefund.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(applyPartialRefund.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(applyPartialRefund.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPartialRefund } = partialRefundSlice.actions

export const selectPartialRefund = (state: RootState) => state.partialRefund

export default partialRefundSlice.reducer
