import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { MarketingPush, MarketingPushSummary } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

interface MarketingPushState {
  data: MarketingPushSummary | null
  loading: RequestStatus
  error: RequestError
}

const initialState: MarketingPushState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const sendMarketingPush = createAsyncThunk<
  MarketingPushSummary,
  { data: MarketingPush },
  { state: RootState; rejectValue: RequestError }
>(
  'marketingPush/sendMarketingPush',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'marketing-push',
        'POST',
        data
      )) as MarketingPushSummary
      dispatch(
        showNotification(`${resp.num_customers_sent} push notifications sent!`)
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const marketingPushSlice = createSlice({
  name: 'marketingPush',
  initialState,
  reducers: {
    resetMarketingPush: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(sendMarketingPush.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(sendMarketingPush.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(sendMarketingPush.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetMarketingPush } = marketingPushSlice.actions

export const selectMarketingPush = (state: RootState) => state.marketingPush

export default marketingPushSlice.reducer
