import styled from '@emotion/styled'
import React from 'react'
import Loader from './Loader'

interface PageViewProps {
  sidebars?: number
}

const PageView = styled.div<PageViewProps>`
  label: PageView;
  position: relative;
  flex-grow: 1;
  padding-bottom: ${props => props.theme.layout.desktop.margin};
`

interface PageProps {
  children: React.ReactNode
  loading?: boolean
  sidebars?: number
}

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ children, loading, sidebars }, ref) => {
    return (
      <PageView ref={ref} id="page" sidebars={sidebars}>
        {loading ? <Loader /> : children}
      </PageView>
    )
  }
)

export default Page
