import { useContext } from 'react'
import { Select } from 'components'
import { ListFilterContext } from './ListFilter'

const ListFilterCategory = ({
  category,
  setCategory
}: {
  category: string | null
  setCategory: (category: string) => void
}) => {
  const { categories } = useContext(ListFilterContext) || {}

  const handleCategory = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(evt.target.value)
  }

  if (!categories) return null

  return (
    <Select
      // label="Category"
      name="category"
      value={category}
      onChange={handleCategory}
      options={categories}
    />
  )
}

export default ListFilterCategory
