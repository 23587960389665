import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Printers } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface PrintersState {
  data: Printers
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface PrintersAPI {
  data: Printers
  links: {
    next: string
  }
}

const initialState: PrintersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type PrintersParams = {
  cursor?: string
  is_active?: boolean
  limit?: number
  revenue_center_id?: number
  search?: string
  with_related?: string
}

export const fetchPrinters = createAsyncThunk<
  PrintersAPI,
  PrintersParams | void,
  { state: RootState; rejectValue: RequestError }
>('printers/fetchPrinters', async (params, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `pos-v2/pos-printers${queryParams}`
    return (await api?.request(endpoint)) as PrintersAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const printersSlice = createSlice({
  name: 'printers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPrinters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPrinters.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchPrinters.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectPrinters = (state: RootState) => state.printers

export default printersSlice.reducer
