import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreGroup } from 'types'
import { showNotification } from 'slices/notification'
import { fetchStoreGroups } from './storeGroups'

export interface StoreGroupState {
  data: StoreGroup | null
  loading: RequestStatus
  error: RequestError
}

const initialState: StoreGroupState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreGroup = createAsyncThunk<
  StoreGroup,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeGroups/fetchStoreGroup',
  async (storeGroupId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `store-groups/${storeGroupId}`
      const resp = (await api?.request(endpoint)) as StoreGroup
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createStoreGroup = createAsyncThunk<
  StoreGroup,
  { data: StoreGroup; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'storeGroups/createStoreGroup',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'store-groups',
        'POST',
        data
      )) as StoreGroup
      dispatch(showNotification('Store group successfully created!'))
      const pathname = `/stores/groups/${resp.store_group_id}`
      dispatch(fetchStoreGroups())
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateStoreGroup = createAsyncThunk<
  StoreGroup,
  {
    storeGroupId: string | number
    data: StoreGroup
  },
  { state: RootState; rejectValue: RequestError }
>(
  'storeGroups/updateStoreGroup',
  async ({ storeGroupId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `store-groups/${storeGroupId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as StoreGroup
      dispatch(showNotification('Store group successfully updated!'))
      dispatch(fetchStoreGroups())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreGroup = createAsyncThunk<
  void,
  {
    storeGroupId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'storeGroups/deleteStoreGroup',
  async (
    { storeGroupId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `store-groups/${storeGroupId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Store group removed!'))
      dispatch(fetchStoreGroups())
      navigate({ pathname: '/stores/groups' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeGroupSlice = createSlice({
  name: 'storeGroup',
  initialState,
  reducers: {
    resetStoreGroup: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStoreGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreGroup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchStoreGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createStoreGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createStoreGroup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createStoreGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateStoreGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateStoreGroup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateStoreGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreGroup.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteStoreGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetStoreGroup } = storeGroupSlice.actions

export const selectStoreGroup = (state: RootState) => state.storeGroup

export default storeGroupSlice.reducer
