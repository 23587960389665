import styled from '@emotion/styled'

export const AvatarView = styled.div`
  display: inline-flex;
  align-items: center;
`

export const AvatarContainer = styled.div<{
  size: number
  image: string | undefined
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background-color: ${({ image, theme }) =>
    image ? 'transparent' : theme.colors.background.tertiary}};
  color: ${({ theme }) => theme.colors.text.primary};
  
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export const AvatarLabelView = styled.div`
  padding-left: 1rem;
  width: 10rem;
  text-align: left;
`

export const AvatarText = styled.span`
  font-size: 1rem;
  font-weight: bold;
`
export const AvatarGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface StyledAvatarContainerProps {
  zIndex: number
  marginLeft: string
}

export const StyledAvatarContainer = styled.div<StyledAvatarContainerProps>`
  position: relative;
  z-index: ${({ zIndex }) => zIndex};
  margin-left: ${({ marginLeft }) => marginLeft};
`
