import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BlockedEmail, BlockedPhone, FormFieldType, ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { createBlockedEmail, selectBlockedEmail } from 'slices/blockedEmail'
import { RequestStatus, capitalize } from 'utils'
import { useEffect, useState } from 'react'
import { fetchBlockedEmails } from 'slices/blockedEmails'
import { createBlockedPhone, selectBlockedPhone } from 'slices/blockedPhone'
import { fetchBlockedPhones } from 'slices/blockedPhones'

export interface BlockedModalProps {
  title: string
  subtitle?: string
  type: 'PHONE' | 'EMAIL' | 'CARD'
  handleBlock?: (values: BlockValues) => void
}

type BlockValues = {
  email?: string
  phone?: string
  card?: string
}

const AddBlocked = ({
  args,
  modalWindow
}: {
  args: BlockedModalProps
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { error: emailError, loading: emailLoading } =
    useAppSelector(selectBlockedEmail)
  const { error: phoneError, loading: phoneLoading } =
    useAppSelector(selectBlockedPhone)
  const { title, subtitle, type, handleBlock } = args
  const [submitted, setSubmitted] = useState(false)
  const loading = type === 'EMAIL' ? emailLoading : phoneLoading
  const error = type === 'EMAIL' ? emailError : phoneError
  const isLoading = loading === RequestStatus.Pending
  const input = type.toLowerCase()

  const field: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      name: input,
      required: true,
      label: capitalize(type),
      placeholder: `Please add the ${input}, which you wish to block.`
    }
  ]

  const empty = {
    [input]: ''
  }

  const submit = (values: BlockValues) => {
    if (handleBlock) {
      handleBlock(values)
    } else if (values.email) {
      const data: BlockedEmail = {
        customer_id: null,
        email: values.email
      }
      dispatch(createBlockedEmail(data))
      setSubmitted(true)
    } else if (values.phone) {
      const data: BlockedPhone = {
        customer_id: null,
        phone: values.phone
      }
      dispatch(createBlockedPhone(data))
      setSubmitted(true)
    }
  }

  useEffect(() => {
    if (submitted && !error && !isLoading) {
      if (type === 'EMAIL') dispatch(fetchBlockedEmails())
      else if (type === 'PHONE') dispatch(fetchBlockedPhones())
      dispatch(closeModal())
    }
  }, [dispatch, submitted, error, isLoading, type])

  return (
    <>
      <ModalHeader title={title} subtitle={subtitle} />
      <Form
        fields={field}
        data={empty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
      />
    </>
  )
}

export default AddBlocked
