import { useState, useCallback } from 'react'
import { TableSort } from 'types'

interface UseSortProps {
  defaultSortOrderBy: string
  defaultSortOrder: 'asc' | 'desc'
}

const useSort = ({ defaultSortOrderBy, defaultSortOrder }: UseSortProps) => {
  const [sort, setSort] = useState<TableSort>({
    order: defaultSortOrder,
    orderBy: defaultSortOrderBy || null
  })

  const onSort = useCallback(
    (fieldKey: string) => {
      if (sort.orderBy === fieldKey) {
        const newOrder = sort.order === 'asc' ? 'desc' : 'asc'
        setSort({ order: newOrder, orderBy: fieldKey })
      } else {
        setSort({ order: 'asc', orderBy: fieldKey })
      }
    },
    [sort, setSort]
  )

  return { sort, onSort }
}

export default useSort
