import styled from '@emotion/styled'
import TextBody from './TextBody'
import TextHeading from './TextHeading'

const PageHeaderView = styled.div`
  label: PageHeader;
  padding: 0.5rem 0 2.5rem;
  border-bottom: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 1.5rem 0 1rem;
    border-bottom: ${props => props.theme.layout.mobile.border} solid
      ${props => props.theme.colors.border.primary};
  }
`

const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const PageHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const PageHeaderTitle = styled(TextHeading)`
  margin-left: -0.2rem;
  font-size: ${props => props.theme.sizes.h1};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-left: 0;
    font-size: ${props => props.theme.sizes.h3};
  }
`

const PageHeaderSubtitle = styled(TextBody)`
  max-width: 64rem;
  margin: 1rem 0 0;
  line-height: 1.4;
`

const PageHeader = ({
  title,
  subtitle,
  icon,
  children
}: {
  title: React.ReactNode
  subtitle?: React.ReactNode
  icon?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <PageHeaderView className="page-header">
      <PageHeaderContainer>
        <div className="page-header-title">
          <PageHeaderTitleWrapper>
            {icon}
            <PageHeaderTitle as="h1">{title}</PageHeaderTitle>
          </PageHeaderTitleWrapper>
          {subtitle && (
            <PageHeaderSubtitle as="p" size="medium" color="secondary">
              {subtitle}
            </PageHeaderSubtitle>
          )}
        </div>
        {children}
      </PageHeaderContainer>
    </PageHeaderView>
  )
}

export default PageHeader
