import styled from '@emotion/styled'
import TextHeading from 'components/TextHeading'

export const DiscountSelectMenuView = styled.form`
  max-width: ${props => props.theme.layout.desktop.pageWidth};
`

export const DiscountedItemsGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${props => props.theme.layout.desktop.padding};
  margin-bottom: ${props => props.theme.layout.desktop.padding};
`
export const DiscountedItemsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const DiscountedItemsGroup = styled.div`
  flex: 1 1 36rem;
  min-width: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  label {
    margin: 0 0 1.5rem;
  }
`

export const DiscountedItemsGroupTitle = styled(TextHeading)`
  padding: 1.5rem;
  border-bottom: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
`

export const DiscountedItemsGroupList = styled.div`
  padding: 1.5rem 1.5rem 0;

  input[type='checkbox'] ~ span {
    margin-top: 0.1rem;
    font-size: ${props => props.theme.sizes.small};
  }
`

export const DiscountedItemCategoryLabel = styled(TextHeading)`
  margin: 3rem 0 1.5rem;
`
export const DiscountedItemButtonWrap = styled.div`
  button + button {
    margin-left: 2rem;
  }
`
