import { forwardRef } from 'react'
import { MenuCategory as MenuCategoryType } from '@open-tender/types'
import MenuItem from './MenuItem'
import {
  MenuCategoryHeader,
  MenuCategoryHeaderText,
  MenuCategoryItems,
  MenuCategorySubtitle,
  MenuCategoryTitle,
  MenuCategoryView
} from './Menu.styled'
// import Img from 'components/Img'

const MenuCategory = forwardRef(
  (
    {
      category,
      isChild = false
    }: { category: MenuCategoryType; isChild?: boolean },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const fontSize = isChild ? 'h3' : 'h2'

    return (
      <MenuCategoryView isChild={isChild} ref={ref}>
        <MenuCategoryHeader>
          <MenuCategoryHeaderText>
            <MenuCategoryTitle as={fontSize} size={fontSize}>
              {category.name}
            </MenuCategoryTitle>
            <MenuCategorySubtitle as="p" size="small" color="secondary">
              {category.description}
            </MenuCategorySubtitle>
          </MenuCategoryHeaderText>
          {/* <MenuCategoryImage>
            <Img
              src={category.small_image_url}
              title={category.name}
              fontSize="small"
            />
          </MenuCategoryImage> */}
        </MenuCategoryHeader>
        <MenuCategoryItems>
          {category.items.map((item, index) => (
            <MenuItem key={`${item.id}-${index}`} item={item} />
          ))}
        </MenuCategoryItems>
      </MenuCategoryView>
    )
  }
)

export default MenuCategory
