import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { ItemAllergens } from 'types'
import { showNotification } from 'slices/notification'

export interface ItemAllergensState {
  data: ItemAllergens
  loading: RequestStatus
  error: RequestError
}

const initialState: ItemAllergensState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItemAllergens = createAsyncThunk<
  ItemAllergens,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'itemAllergens/fetchItemAllergens',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/allergens`
      const resp = (await api?.request(endpoint)) as ItemAllergens
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createItemAllergens = createAsyncThunk<
  ItemAllergens,
  { itemId: string | number; data: ItemAllergens },
  { state: RootState; rejectValue: RequestError }
>(
  'itemAllergens/createItemAllergens',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/allergens`
      const resp = (await api?.request(endpoint, 'POST', data)) as ItemAllergens
      dispatch(showNotification('Allergens successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItemAllergens = createAsyncThunk<
  ItemAllergens,
  { itemId: string | number; data: ItemAllergens },
  { state: RootState; rejectValue: RequestError }
>(
  'itemAllergens/updateItemAllergens',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/allergens`
      const resp = (await api?.request(endpoint, 'PUT', data)) as ItemAllergens
      dispatch(showNotification('Allergens successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteItemAllergens = createAsyncThunk<
  void,
  { itemId: string | number; data: ItemAllergens },
  { state: RootState; rejectValue: RequestError }
>(
  'itemAllergens/deleteItemAllergens',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/allergens`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Allergens successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemAllergensSlice = createSlice({
  name: 'itemAllergens',
  initialState,
  reducers: {
    resetItemAllergens: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemAllergens.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemAllergens.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchItemAllergens.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createItemAllergens.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createItemAllergens.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createItemAllergens.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItemAllergens.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItemAllergens.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateItemAllergens.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteItemAllergens.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteItemAllergens.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteItemAllergens.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItemAllergens } = itemAllergensSlice.actions

export const selectItemAllergens = (state: RootState) => state.itemAllergens

export default itemAllergensSlice.reducer
