import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTasks } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface BackgroundTasksState {
  data: BackgroundTasks
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface BackgroundTasksAPI {
  data: BackgroundTasks
  links: {
    next: string
  }
}

export type BackgroundTasksParams = {
  cursor?: string
  limit?: number
  expand?: string
  status?: string
}

const initialState: BackgroundTasksState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBackgroundTasks = createAsyncThunk<
  BackgroundTasksAPI,
  BackgroundTasksParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'backgroundTasks/fetchBackgroundTasks',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `background-tasks${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as BackgroundTasksAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const backgroundTasksSlice = createSlice({
  name: 'background-tasks',
  initialState,
  reducers: {
    resetBackgroundTasks: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBackgroundTasks.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBackgroundTasks.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBackgroundTasks.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBackgroundTasks } = backgroundTasksSlice.actions

export const selectBackgroundTasks = (state: RootState) => state.backgroundTasks

export default backgroundTasksSlice.reducer
