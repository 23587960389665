import { Form } from 'components'
import { CampaignContentEmailMetadata, FormFieldType, ModalWindow } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import {
  patchCampaignContent,
  selectCampaignContent
} from 'slices/campaignContent'
import { useMemo } from 'react'
import { selectMarketingBrandSettings } from 'slices/marketingSettings'
import { showNotification } from 'slices/notification'
import { RequestStatus } from 'utils'
import { fetchCampaign } from 'slices/campaign'

const CampaignContentEmail = ({
  args: { content, campaign_id, readOnly },
  modalWindow
}: {
  args: {
    campaign_id: number
    content: CampaignContentEmailMetadata
    readOnly: boolean
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { sender_email_address, default_sender_name, default_reply_email } =
    useAppSelector(selectMarketingBrandSettings)
  const { loading } = useAppSelector(selectCampaignContent)
  const formData = {
    ...content,
    sender_name: content.sender_name || default_sender_name,
    sender_email: content.sender_email || sender_email_address,
    reply_to: content.reply_to || default_reply_email
  }

  const submit = (values: {
    subject: string
    preview_text: string
    sender_email: string
    sender_name: string
    reply_to: string
  }) => {
    if (readOnly) {
      dispatch(closeModal())
      return
    }
    dispatch(
      patchCampaignContent({
        subject: values.subject,
        preview_text: values.preview_text,
        sender_email: values.sender_email,
        sender_name: values.sender_name,
        reply_to: values.reply_to
      })
    )
      .unwrap()
      .then(() => {
        dispatch(showNotification('Email settings updated.'))
        dispatch(closeModal())
        if (campaign_id) {
          dispatch(
            fetchCampaign({
              campaign_id,
              with_related: 'true'
            })
          )
        }
      })
      .catch(() => {
        dispatch(showNotification('Failed to update email settings.'))
      })
  }

  const campaignContentWebFields: FormFieldType[] = useMemo(
    () => [
      {
        fieldType: 'Input',
        type: 'text',
        name: 'subject',
        label: 'Subject',
        disabled: readOnly
      },
      {
        fieldType: 'Input',
        type: 'text',
        name: 'preview_text',
        label: 'Preview Text',
        disabled: readOnly
      },
      {
        fieldType: 'Input',
        type: 'text',
        name: 'sender_name',
        label: 'Sender Name',
        disabled: readOnly
      },
      {
        fieldType: 'Input',
        type: 'email',
        name: 'sender_email',
        label: 'Sender Email',
        disabled: !!sender_email_address
      },
      {
        fieldType: 'Input',
        type: 'email',
        name: 'reply_to',
        label: 'Reply to Email',
        disabled: readOnly
      }
    ],
    [readOnly, sender_email_address]
  )

  return (
    <>
      <ModalHeader title="Email Settings" />
      <Form
        fields={campaignContentWebFields}
        data={formData}
        isLoading={loading === RequestStatus.Pending}
        submit={submit}
        submitText={readOnly ? 'Close' : 'Save'}
      />
    </>
  )
}

export default CampaignContentEmail
