import styled from '@emotion/styled'

export const EntityView = styled.div`
  label: Entity;
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  // background-color: palegreen;
`

interface EntityRoutesProps {
  hasNav: boolean
}

export const EntityRoutes = styled.div<EntityRoutesProps>`
  label: EntityRoutes;
  flex-grow: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  & div.page-header {
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      margin-top: 5.8rem;
    }
  }
`
