import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import {
  AppClassesLookup,
  AppClassesSection,
  AppDesignSystem,
  AppSystemPage,
  AppSystemPages
} from 'types'
import { showNotification } from 'slices/notification'

export interface AppSystemState {
  data: AppDesignSystem | null
  lookup: AppClassesLookup | null
  loading: RequestStatus
  error: RequestError
}

export interface AppSystemAPI {
  data: AppSystemPages
  links: {
    next: string | null
    prev?: string
  }
}

const makeClassesLookup = (system: AppDesignSystem) => {
  return Object.values(system).reduce(
    (obj: AppClassesLookup, section: AppClassesSection) => {
      const sectionLookup = section.elements.reduce((sectionObj, element) => {
        return {
          ...sectionObj,
          [element.className]: { ...element, section: section.name }
        }
      }, {} as AppClassesLookup)
      return { ...obj, ...sectionLookup }
    },
    {}
  )
}

const initialState: AppSystemState = {
  data: null,
  lookup: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppSystem = createAsyncThunk<
  AppSystemPage | null,
  void,
  { state: RootState; rejectValue: RequestError }
>('appSystem/fetchAppSystem', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const params = `?page_type=classes&is_latest=true`
    const { data } = (await api?.request(
      `order-app-pages${params}`
    )) as AppSystemAPI
    return data.find(i => i.is_latest) || null
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertAppSystem = createAsyncThunk<
  AppSystemPage,
  { data: AppDesignSystem },
  { state: RootState; rejectValue: RequestError }
>(
  'appSystem/upsertAppSystem',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(`order-app-pages`, 'POST', {
        page_type: 'classes',
        page_content: [data]
      })) as AppSystemPage
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const AppSystemSlice = createSlice({
  name: 'appSystem',
  initialState,
  reducers: {
    resetAppSystem: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAppSystem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppSystem.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      const data = payload?.page_content[0] || null
      state.data = data
      state.lookup = data ? makeClassesLookup(data) : null
    })
    builder.addCase(fetchAppSystem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertAppSystem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertAppSystem.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      const data = payload.page_content[0]
      state.data = data
      state.lookup = data ? makeClassesLookup(data) : null
    })
    builder.addCase(upsertAppSystem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAppSystem } = AppSystemSlice.actions

export const selectAppSystem = (state: RootState) => state.appSystem

export const selectAppSystemData = (state: RootState) => state.appSystem.data

export default AppSystemSlice.reducer
