import styled from '@emotion/styled'

export const SelectWrapper = styled.span`
  display: block;
  position: relative;
`

export const SelectView = styled.select<{ inline?: boolean }>`
  width: ${props => (props.inline ? 'fit-content' : '100%')};
  outline: none;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-radius: ${props => props.theme.inputs.borderRadius};
  padding: ${props => props.theme.inputs.padding};
  font-size: ${props => props.theme.inputs.fontSize};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  line-height: ${props => props.theme.inputs.lineHeight};
  transition: ${props => props.theme.transition};

  background-color: ${props => props.theme.inputs.backgroundColor};

  color: ${props => props.theme.colors.text.primary};
  border-color: ${props => props.theme.colors.border.primary};

  &:active,
  &:focus {
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.text.primary};
  }

  optgroup {
    color: ${props => props.theme.inputs.backgroundColor};
    background-color: ${props => props.theme.inputs.color};
  }

  option {
    color: ${props => props.theme.inputs.backgroundColor};
    background-color: ${props => props.theme.inputs.color};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.colors.text.primary};
  }
`

export const SelectDropdown = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4rem;
  border-left: 0.1rem solid ${props => props.theme.inputs.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

export const SelectArrow = styled.span`
  display: block;
  width: 0;
  height: 0;
  margin: 0.3rem 0 0;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-top: 0.7rem solid ${props => props.theme.colors.text.primary};
`
