import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LocationBrand } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface BrandByLocationState {
  data: LocationBrand | null
  loading: RequestStatus
  error: RequestError
}

const initialState: BrandByLocationState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBrandByLocation = createAsyncThunk<
  LocationBrand,
  number,
  { state: RootState; rejectValue: RequestError }
>(
  'brandByLocation/fetchBrandByLocation',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const endpoint = `super/revenue-centers/${revenueCenterId}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as LocationBrand
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const brandByLocationSlice = createSlice({
  name: 'brandByLocation',
  initialState,
  reducers: {
    resetBrandByLocation: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBrandByLocation.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBrandByLocation.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBrandByLocation.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBrandByLocation } = brandByLocationSlice.actions

export const selectBrandByLocation = (state: RootState) => state.brandByLocation

export default brandByLocationSlice.reducer
