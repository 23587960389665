import React, { useRef, useState } from 'react'
import { File } from 'react-feather'
import { Image, PDF, Raw } from '.'
import { Input } from 'components'
import { DropZoneView, FileIconWrapper, FileNameView } from './Files.styled'

interface DropZoneProps {
  isLoading: boolean
  onFilesAdded: (file?: File | null) => void
  progress: {
    state: string
    percentage: number
  }
  file?: any
  name: string
  deleteFile?: (fileId: number) => void
  fileType: string
  processingFileType: string
}

const DropZone = (props: DropZoneProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>()
  const [highlight, setHighlight] = useState(false)
  const openFileDialog = () => {
    if (props.isLoading) return
    fileInputRef?.current?.click()
  }

  const onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (props.isLoading) return
    const files = evt.target.files
    if (props.onFilesAdded) {
      props.onFilesAdded(files?.item(0))
    }
  }

  const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault()
    if (props.isLoading) return
    setHighlight(true)
  }

  const onDragLeave = () => {
    setHighlight(false)
  }

  const onDrop = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault()
    if (props.isLoading) return
    const files = evt.dataTransfer.files
    if (props.onFilesAdded) {
      props.onFilesAdded(files.item(0))
    }
    setHighlight(false)
  }

  const {
    progress: { state: progState, percentage: progPercent }
  } = props

  const isProcessing =
    props.isLoading && props.fileType === props.processingFileType

  return (
    <>
      {props?.file?.name ? (
        <FileNameView>
          <FileIconWrapper>
            <File size={18} />
          </FileIconWrapper>
          <span>{props.file.name}</span>
        </FileNameView>
      ) : null}
      <DropZoneView
        highlight={highlight}
        progState={progState}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={openFileDialog}
        hasFile={!!props.file}
        isProcessing={isProcessing}
      >
        <Input
          ref={fileInputRef as React.RefObject<HTMLInputElement>}
          type="file"
          name={props.name}
          multiple
          onChange={onFilesAdded}
          disabled={props.isLoading}
        />
        {props.name === 'PDF' ||
        props.name === 'woff' ||
        props.name === 'woff2' ? (
          <PDF
            {...props}
            progState={progState}
            progPercent={progPercent}
            isProcessing={isProcessing}
          />
        ) : props.name === 'Raw Image' ? (
          <Raw
            {...props}
            progState={progState}
            progPercent={progPercent}
            isProcessing={isProcessing}
          />
        ) : (
          <Image
            {...props}
            progState={progState}
            progPercent={progPercent}
            isProcessing={isProcessing}
          />
        )}
      </DropZoneView>
    </>
  )
}

export default DropZone
