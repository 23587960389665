import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { getRandomNumberString } from 'utils'
import { Notifications } from 'types'

const initialState: Notifications = []

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification: (state: Notifications, action: { payload: string }) => {
      return [
        ...state,
        {
          id: getRandomNumberString(),
          message: action.payload
        }
      ]
    },
    hideNotification: (
      state: Notifications,
      action: { payload: string | number }
    ) => {
      return state.filter(
        (notification: { id: string }) => notification.id !== action.payload
      )
    }
  }
})

export const { showNotification, hideNotification } = notifications.actions

export const selectNotifications = (state: RootState) => state.notifications

export default notifications.reducer
