import styled from '@emotion/styled'

interface UploadDisplayType {
  displayType?: 'DEFAULT' | 'SMALL'
}

export const FilesView = styled.div<UploadDisplayType>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${props => (props.displayType === 'SMALL' ? '2rem' : '3rem')};
  width: 100%;
  max-width: ${props => props.theme.layout.desktop.pageWidth};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    max-width: ${props => props.theme.layout.mobile.pageWidth};
  }
`

export const UploadFileView = styled.div``

export const UploadFileLabel = styled.p`
  font-size: ${props => props.theme.sizes.small};
  margin: 0 0 1rem;
`

export const FileNameView = styled.div`
  label: FileNameView;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius.small};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;

  span:last-child {
    line-height: 1;
    padding-bottom: 0.2rem;
  }
`

export const FileIconWrapper = styled.span`
  label: FileIconWrapper;
  width: 4rem;
  padding: 0.6rem 0.8rem 0 0;
  text-align: right;
`

export const UploadView = styled.div<UploadDisplayType>`
  position: relative;
  // margin-bottom: ${props =>
    props.displayType === 'SMALL' ? '1.5rem' : '3rem'};
  overflow: hidden;
`

export const UploadDetails = styled.div<UploadDisplayType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1rem;
  border-bottom: 0;

  a {
    // display: ${props =>
      props.displayType === 'SMALL' ? 'none' : 'inline-block'};
    font-size: ${props => props.theme.sizes.xsmall};
    ${props => (props.displayType === 'SMALL' ? `padding: 0; border: 0;` : '')}

    &:active, &:focus {
      color: ${props => props.theme.colors.text.primary};
      border-color: ${props => props.theme.colors.text.primary};
    }
  }

  button {
    display: inline-block;
    padding: 0.2rem 0 0;
    margin: 0 0 0 1.1rem;
    line-height: 1;
    color: ${props => props.theme.colors.error.background};
    font-size: ${props =>
      props.displayType === 'SMALL'
        ? props.theme.labels.fontSizeSmall
        : props.theme.labels.fontSize};
  }

  button:hover {
    color: ${props => props.theme.colors.text.primary};
  }

  p {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: ${props =>
      props.displayType === 'SMALL'
        ? props.theme.labels.fontSizeSmall
        : props.theme.labels.fontSize};
  }
`

export const UploadLabel = styled.span<UploadDisplayType>`
  display: block;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props =>
    props.displayType === 'SMALL'
      ? props.theme.labels.fontSizeSmall
      : props.theme.labels.fontSize};
`

interface DropZoneViewProps {
  hasFile: boolean
  progState: string
  highlight: boolean
  isProcessing: boolean
}

export const DropZoneView = styled.div<DropZoneViewProps>`
  label: DropZone;
  position: relative;
  width: 100%;
  min-height: auto;
  transition: all 0.15s ease;
  border-radius: ${props => props.theme.borderRadius.medium};
  overflow: hidden;
  border: ${props =>
    props.hasFile ? '0' : `0.1rem dashed ${props.theme.colors.border.primary}`};
  background: ${props =>
    props.hasFile
      ? `${props.theme.colors.background.tertiary}`
      : 'transparent'};
  color: ${props => props.theme.colors.text.primary};
  cursor: ${props => (props.isProcessing ? 'default' : 'pointer')};

  label {
    margin: 0;
  }

  input {
    display: none;
  }

  &:hover {
    background-color: ${props => props.theme.colors.background.secondary};

    button {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const RawView = styled.div`
  min-height: 5rem;
  display: flex;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }
`

interface ImageViewProps {
  isProcessing: boolean
  hasFile: boolean
}

export const ImageView = styled.div<ImageViewProps>`
  label: Image;
  min-height: 12rem;
`

export const ImageFileUpload = styled.div<ImageViewProps>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.15s ease;
  opacity: ${props => (props.isProcessing ? 0 : 1)};
  visibility: ${props => (props.isProcessing ? 'hidden' : 'visible')};

  span {
    position: relative;
    z-index: 3;
    display: ${props => (props.hasFile ? 'none' : 'inline-block')};
    padding: 0 3rem;
    text-align: center;
    color: ${props => props.theme.colors.text.primary};
    transition: all 0.15s ease;
  }

  &:hover > div {
    background-color: ${props => props.theme.colors.background.secondary};
    opacity: ${props => (props.hasFile ? '0.75' : '1')};
  }

  &:hover button {
    visibility: visible;
    opacity: 1;
  }

  &:hover span {
    display: inline-block;
  }
`

export const ImageUploadIcon = styled.span`
  margin: 0 0 1rem;
`

export const ImageFileUploadCover = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.15s ease;
`

export const ImageElement = styled.img`
  display: block;
  width: 100%;
  height: auto;
  // background-color: ${props => props.theme.colors.background.secondary};
`
export const UploadCsvInput = styled.div`
  margin-right: 0.5rem;
`
