import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { Loader, Table, Button, ButtonLink } from 'components'
import { Checkbox } from 'components/Forms'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'

import { fetchModifierGroup, selectModifierGroup } from 'slices/modifierGroup'
import {
  addModifier,
  bulkUpdateModifiers,
  deleteModifier,
  fetchModifierGroupModifiers,
  selectModifierGroupModifiers,
  updateModifier,
  updateModifierGroupModifiers
} from 'slices/modifiers'
import { closeModal } from 'slices/modal'

import { ItemModifierGroup, ModalWindow, TableRows } from 'types'
import { RequestStatus } from 'utils'

import { ModifierCheckBoxContainer } from './MenuItemModifierGroupsEdit.styled'

const MenuItemModifierGroupEdit = ({
  args: { categoryId, modifierGroupId, itemId, itemModifierGroup }
}: {
  args: {
    categoryId?: number
    modifierGroupId?: number
    itemId: number
    itemModifierGroup: ItemModifierGroup
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useAppSelector(selectModifierGroup)
  const { data: modifiers, loading: modifiersLoading } = useAppSelector(
    selectModifierGroupModifiers
  )

  const isLoading =
    loading === RequestStatus.Pending ||
    modifiersLoading === RequestStatus.Pending

  const { modifier_group, modifier_group_id: itemModifierGroupId } =
    itemModifierGroup

  const { full_name } = modifier_group || {}
  // const shortName = short_name ? ` (${short_name})` : ''
  // const name = `${full_name}${shortName}`

  useEffect(() => {
    dispatch(
      fetchModifierGroup({
        modifierGroupId: itemModifierGroupId,
        params: { with_related: 'items' }
      })
    )
    dispatch(
      fetchModifierGroupModifiers({
        categoryId,
        modifierGroupId,
        itemId,
        itemModifierGroupId
      })
    )
  }, [dispatch, categoryId, modifierGroupId, itemId, itemModifierGroupId])

  const handleUpdateModifiers = () => {
    dispatch(
      updateModifierGroupModifiers({
        categoryId,
        modifierGroupId,
        itemId,
        itemModifierGroupId
      })
    )
    dispatch(closeModal())
  }

  const handleSelectAll = () => {
    const isAllSelected =
      data?.items?.length === modifiers.length &&
      modifiers.every(m => m.is_default)

    if (isAllSelected) {
      dispatch(bulkUpdateModifiers([]))
    } else {
      const newModifiers = data?.items?.map(item => {
        return {
          item_id: item.item.item_id,
          is_default: true
        }
      })
      dispatch(bulkUpdateModifiers(newModifiers || []))
    }
  }

  const handleDisplayAll = () => {
    const isAllDisplayed = data?.items?.length === modifiers.length

    if (isAllDisplayed) {
      dispatch(bulkUpdateModifiers([]))
    } else {
      const newModifiers = data?.items?.map(item => {
        const modifier = modifiers.find(
          mod => mod.item_id === item.item.item_id
        )
        return {
          item_id: item.item.item_id,
          is_default: modifier ? modifier.is_default : false
        }
      })
      dispatch(bulkUpdateModifiers(newModifiers || []))
    }
  }

  const handleDefaultAll = () => {
    const isAllDefaulted =
      data?.items?.length === modifiers.length &&
      modifiers.every(m => m.is_default)
    if (isAllDefaulted) {
      const newModifiers = modifiers.map(mod => ({ ...mod, is_default: false }))
      dispatch(bulkUpdateModifiers(newModifiers))
    } else {
      handleSelectAll()
    }
  }

  const tableHeaders = [
    <ButtonLink onClick={handleSelectAll}>Select All</ButtonLink>,
    <ButtonLink onClick={handleDisplayAll}>Displayed</ButtonLink>,
    <ButtonLink onClick={handleDefaultAll}>Default</ButtonLink>
  ]

  const tableContent: TableRows =
    data?.items?.map(item => {
      const modifier = modifiers.find(mod => mod.item_id === item.item.item_id)

      const handleDisplayedChange = (
        e: React.ChangeEvent<HTMLInputElement>
      ) => {
        if (modifier) {
          dispatch(deleteModifier(modifier))
        } else {
          dispatch(
            addModifier({
              item_id: item.item.item_id,
              is_default: false
            })
          )
        }
      }

      const handleDefaultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (modifier) {
          dispatch(
            updateModifier({
              ...modifier,
              is_default: e.target.checked
            })
          )
        } else {
          dispatch(
            addModifier({
              item_id: item.item.item_id,
              is_default: e.target.checked
            })
          )
        }
      }

      const handleSelectModifier = () => {
        if (modifier) {
          if (modifier.is_default) {
            dispatch(deleteModifier(modifier))
          } else {
            dispatch(updateModifier({ ...modifier, is_default: true }))
          }
        } else {
          dispatch(
            addModifier({
              item_id: item.item.item_id,
              is_default: true
            })
          )
        }
      }

      return {
        items: [
          <ButtonLink onClick={handleSelectModifier}>
            {item.item.short_name}
          </ButtonLink>,
          <ModifierCheckBoxContainer>
            <Checkbox
              checked={modifier ? true : false}
              name={`DISPLAYED-${item.item.short_name}`}
              onChange={handleDisplayedChange}
            />
          </ModifierCheckBoxContainer>,
          <ModifierCheckBoxContainer>
            <Checkbox
              checked={modifier?.is_default ?? false}
              name={`DEFAULT-${item.item.short_name}`}
              onChange={handleDefaultChange}
            />
          </ModifierCheckBoxContainer>
        ]
      }
    }) || []

  return (
    <>
      <ModalHeader
        title={full_name || 'Modifier Group'}
        subtitle="Edit displayed and default modifiers for this group"
      />
      <ModalContent>
        {isLoading ? (
          <Loader />
        ) : (
          <Table headers={tableHeaders} contents={tableContent} size="small" />
        )}
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleUpdateModifiers}>Update Availablities</Button>
      </ModalFooter>
    </>
  )
}

export default MenuItemModifierGroupEdit
