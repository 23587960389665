import styled from '@emotion/styled'
import DesignInputNumberArrows from './DesignInputNumberArrows'
import DesignInputFontSize from './DesignInputFontSize'
import DesignInputClear from './DesignInputClear'

const DesignInputView = styled.input<{ inherited?: boolean }>`
  width: 100%;
  outline: none;
  background-color: inherit;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
  border-radius: ${props => props.theme.inputs.borderRadius};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  // line-height: ${props => props.theme.inputs.lineHeight};
  // padding: 0.7rem 0.9rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0 0.9rem;
  color: ${props =>
    props.inherited
      ? props.theme.colors.text.secondary
      : props.theme.inputs.color};
  font-size: ${props => props.theme.sizes.xsmall};

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.inputs.color};
  }

  &::placeholder {
    color: ${props => props.theme.inputs.placeholderColor};
    opacity: ${props => props.theme.inputs.placeholderOpacity};
  }

  &::selection {
    color: ${props => props.theme.colors.background.primary};
    background-color: ${props => props.theme.colors.text.primary};
  }

  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

const DesignInputSuffix = styled.span`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 2rem;
  bottom: 0;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: ${props => props.theme.sizes.xxsmall};
`

const DesignInput = ({
  type,
  name,
  value,
  setValue,
  min,
  max,
  step,
  disabled = false,
  inherited,
  isFontSize = false,
  suffix,
  showZero = false,
  placeholder
}: {
  type: string
  name: string
  value: string | number | undefined
  setValue: (value: string | number | null) => void
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  inherited?: boolean
  isFontSize?: boolean
  suffix?: string
  showZero?: boolean
  placeholder?: string
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val =
      type === 'number' ? parseFloat(evt.target.value) : evt.target.value
    setValue(val)
  }

  const valueNumber =
    value === undefined ? '' : value || (showZero ? value : '')

  return (
    <>
      <DesignInputView
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        inherited={inherited}
        placeholder={placeholder}
      />
      {type === 'number' && step ? (
        <DesignInputNumberArrows
          value={valueNumber}
          step={step}
          min={min}
          max={max}
          setValue={setValue}
        />
      ) : null}
      {suffix && <DesignInputSuffix>{suffix}</DesignInputSuffix>}
      {isFontSize && <DesignInputFontSize setValue={setValue} />}
      {inherited === false && <DesignInputClear setValue={setValue} />}
    </>
  )
}

export default DesignInput
