import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useAppDispatch } from 'app/hooks'
import { hideNotification } from 'slices/notification'

const NotificationView = styled.li`
  margin: 1.5rem 0 0;
  padding: ${props => props.theme.messages.padding};
  border-radius: ${props => props.theme.messages.borderRadius};
  line-height: ${props => props.theme.messages.lineHeight};
  font-size: ${props => props.theme.sizes.small};
  color: ${props => props.theme.colors.background.primary};
  background-color: ${props => props.theme.colors.accent.primary};
  transition: all 500ms ease;
`

export interface NotificationProps {
  message: string
  id: number | string
}

const Notification = ({ message, id }: NotificationProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(hideNotification(id))
    }, 2500)
    return () => clearTimeout(timer)
  }, [dispatch, id])

  return <NotificationView>{message}</NotificationView>
}

export default Notification
