import { SwitchInput, SwitchTick, SwitchView } from './Switch.styled'

const Switch = ({
  name,
  value,
  setValue,
  disabled = false
}: {
  name: string
  value: boolean
  setValue: (val: boolean) => void
  disabled?: boolean
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
  }

  return (
    <SwitchView htmlFor={name} checked={value} disabled={disabled}>
      <SwitchInput
        type="checkbox"
        id={name}
        name={name}
        onChange={onChange}
        checked={value}
        disabled={disabled}
      />
      <SwitchTick />
    </SwitchView>
  )
}

export default Switch
