import { Tender, TenderStatus } from 'types'
import { OrderCardHighlight, OrderCardTenderView } from './OrderCard.styled'
import Highlight from 'components/Highlight'
import { makeTenderName } from 'utils'

const makeTenderStatusColor = (tenderStatus: TenderStatus) => {
  switch (tenderStatus) {
    case 'PAID':
      return 'success'
    case 'VOID':
      return 'alert'
    case 'FAILED':
      return 'error'
    case 'REFUNDED':
      return 'info'
    default:
      return 'default'
  }
}

const OrderCardTender = ({ tender }: { tender: Tender }) => {
  const name = makeTenderName(tender)
  const statusColor = makeTenderStatusColor(tender.tender_status)
  return (
    <OrderCardTenderView>
      {/* <TextBody size="small">{name}</TextBody> */}
      <OrderCardHighlight>
        <Highlight color={statusColor}>{name}</Highlight>
      </OrderCardHighlight>
    </OrderCardTenderView>
  )
}

export default OrderCardTender
