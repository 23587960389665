import { useState } from 'react'
import { ButtonGroup, Input, Loader } from 'components'
import { SearchResult } from 'types'

const SearchResults = ({
  searchResults,
  isLoading,
  handleSelect,
  handleSearch
}: {
  searchResults: SearchResult[]
  isLoading: boolean
  handleSelect: (searchResult: SearchResult) => void
  handleSearch: (search: string) => void
}) => {
  const [search, setSearch] = useState<string>('')

  const onSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value)
  }

  const onSearchKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      handleSearch(search)
    }
  }

  return (
    <>
      <Input
        type="text"
        name="search"
        fieldType="Input"
        label=""
        value={search ?? ''}
        onChange={onSearchChange}
        onKeyDown={onSearchKeyPress}
        placeholder="enter part of search"
      />
      {isLoading ? (
        <Loader text="Retrieving results..." />
      ) : searchResults ? (
        <ButtonGroup>
          {searchResults.map(searchResult => (
            <button
              key={searchResult.id}
              onClick={() => handleSelect(searchResult)}
            >
              <span>{searchResult.name}</span>
            </button>
          ))}
        </ButtonGroup>
      ) : null}
    </>
  )
}

export default SearchResults
