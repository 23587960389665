import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ColorScheme } from 'types'

export type GlobalState = {
  colorScheme: ColorScheme
  showSidebar: boolean
}

const initialState: GlobalState = {
  colorScheme: 'DARK',
  showSidebar: false
}

export const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setColorScheme: (state, { payload }) => {
      state.colorScheme = payload
    },
    setShowSidebar: (state, { payload }: { payload: boolean }) => {
      state.showSidebar = payload
    }
  }
})

export const { setColorScheme, setShowSidebar } = global.actions

export const selectGlobal = (state: RootState) => state.global
export const selectColorScheme = (state: RootState) => state.global.colorScheme
export const selectShowSidebar = (state: RootState) => state.global.showSidebar

export default global.reducer
