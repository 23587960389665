import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Taxes } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface TaxesState {
  data: Taxes
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface TaxesAPI {
  data: Taxes
  links: {
    next: string
  }
}

export type TaxesParams = {
  cursor?: string
  limit?: number
  with_related?: string
}

const initialState: TaxesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchTaxes = createAsyncThunk<
  TaxesAPI,
  TaxesParams | void,
  { state: RootState; rejectValue: RequestError }
>('taxes/fetchTaxes', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `taxes${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as TaxesAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const taxesSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    resetTaxes: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTaxes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTaxes.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchTaxes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTaxes } = taxesSlice.actions

export const selectTaxes = (state: RootState) => state.taxes

export default taxesSlice.reducer
