import { Button, TextBody } from 'components'
import { ModalWindow, Segment } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

interface SegmentArchiveModalProps {
  args: {
    segment: Segment
    onSubmit: (segment: Segment) => void
  }
  modalWindow: ModalWindow
}

const SegmentArchive = ({
  args: { segment, onSubmit },
  modalWindow
}: SegmentArchiveModalProps) => {
  const dispatch = useAppDispatch()

  const handleArchive = (segment: Segment) => {
    onSubmit(segment)
    dispatch(closeModal())
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Archive Segment" />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        Are you sure you want to archive this segment? It will be hidden
        elsewhere in the application.
      </TextBody>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={() => handleArchive(segment)}
        >
          Archive
        </Button>
      </ModalFooter>
    </>
  )
}

export default SegmentArchive
