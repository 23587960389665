import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { CircularZone, DeliveryZone } from 'types'
import { showNotification } from 'slices/notification'

export interface DeliveryZoneState {
  data: DeliveryZone | null
  loading: RequestStatus
  error: RequestError
}

const initialState: DeliveryZoneState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type CircularZoneParams = {
  longitude: number
  latitude: number
  radius: number
}

export const fetchDeliveryZone = createAsyncThunk<
  DeliveryZone,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryZone/fetchDeliveryZone',
  async (deliveryZoneId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `delivery-zones/${deliveryZoneId}`
      const resp = (await api?.request(endpoint)) as DeliveryZone
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createDeliveryZone = createAsyncThunk<
  DeliveryZone,
  { data: DeliveryZone },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryZone/createDeliveryZone',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'delivery-zones',
        'POST',
        data
      )) as DeliveryZone
      dispatch(showNotification('Delivery zone successfully created!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateDeliveryZone = createAsyncThunk<
  DeliveryZone,
  {
    deliveryZoneId: string | number
    data: DeliveryZone
  },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryZone/updateDeliveryZone',
  async ({ deliveryZoneId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `delivery-zones/${deliveryZoneId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as DeliveryZone
      dispatch(showNotification('Delivery zone successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteDeliveryZone = createAsyncThunk<
  void,
  {
    deliveryZoneId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryZone/deleteDeliveryZone',
  async ({ deliveryZoneId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `delivery-zones/${deliveryZoneId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Delivery zone removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createCircularZone = createAsyncThunk<
  CircularZone,
  CircularZoneParams,
  { state: RootState; rejectValue: RequestError }
>(
  'deliveryZone/createCircularZone',
  async (data, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'delivery-zones/calculate-circle-coordinates',
        'POST',
        data
      )) as CircularZone
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deliveryZoneSlice = createSlice({
  name: 'deliveryZone',
  initialState,
  reducers: {
    resetDeliveryZone: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchDeliveryZone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchDeliveryZone.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchDeliveryZone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createDeliveryZone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createDeliveryZone.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createDeliveryZone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateDeliveryZone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateDeliveryZone.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateDeliveryZone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteDeliveryZone.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteDeliveryZone.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteDeliveryZone.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetDeliveryZone } = deliveryZoneSlice.actions

export const selectDeliveryZone = (state: RootState) => state.deliveryZone

export default deliveryZoneSlice.reducer
