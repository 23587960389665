import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BlockedEmails } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface BlockedEmailsState {
  data: BlockedEmails
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface BlockedEmailsAPI {
  data: BlockedEmails
  links: {
    next: string
  }
}

export type BlockedEmailsParams = {
  limit?: number
  cursor?: string
  with_related?: string
  customer_id?: string
}

const initialState: BlockedEmailsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBlockedEmails = createAsyncThunk<
  BlockedEmailsAPI,
  BlockedEmailsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedEmails/fetchBlockedEmails',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `blocked-emails${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as BlockedEmailsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const blockedEmailsSlice = createSlice({
  name: 'blockedEmails',
  initialState,
  reducers: {
    resetBlockedEmails: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBlockedEmails.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBlockedEmails.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBlockedEmails.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBlockedEmails } = blockedEmailsSlice.actions

export const selectBlockedEmails = (state: RootState) => state.blockedEmails

export default blockedEmailsSlice.reducer
