import styled from '@emotion/styled'
import { useAppSelector } from 'app/hooks'
import { adminSections } from 'utils'
import { AccessError } from 'pages'
import { useLocation } from 'react-router-dom'
import { selectAuthUser, selectCanAccess } from 'slices/authUser'
import packageJson from '../../package.json'

const MainView = styled.div`
  label: Main;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
`

const MainVersion = styled.div`
  label: MainVersion;
  position: fixed;
  z-index: 1;
  bottom: 0.5rem;
  right: 0.5rem;
  opacity: 0.15;
  font-size: 1rem;
  transition: all 0.25s ease-in-out;
  cursor: default;

  &:hover {
    opacity: 1;
  }
`

const MainBrandId = styled.span`
  padding-right: 1rem;
`

const Main = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const parts = pathname.split('/')
  const adminSection = parts.length >= 2 ? parts[1] : null
  const section = adminSection ? adminSections[adminSection] : null
  const canAccess = useAppSelector(selectCanAccess(section))
  const { brand } = useAppSelector(selectAuthUser)
  const brandId = brand?.brand_id || null

  return (
    <MainView>
      {canAccess ? children : <AccessError />}
      <MainVersion aria-hidden="true">
        {brandId && <MainBrandId>{brandId}</MainBrandId>}v{packageJson.version}
      </MainVersion>
    </MainView>
  )
}

export default Main
