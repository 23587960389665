import { AlertCircle, RefreshCcw } from 'react-feather'
import { TextBody } from 'components'
import { RequestError, RequestStatus } from 'utils'
import ButtonLink from '../ButtonLink'
import {
  TableRefreshError,
  TableRefreshIcon,
  TableRefreshPager,
  TableRefreshPagerArrows,
  TableRefreshView,
  TableResetFilters
} from './TableRefresh.styled'
import { ArrowLeftCircle, ArrowRightCircle } from 'iconoir-react'
import TableRefreshPerPage from './TableRefreshPerPage'

const TableRefresh = ({
  count,
  totalCount,
  loading,
  error,
  refresh,
  children,
  perPage,
  setPage,
  setPerPage,
  next,
  onNext,
  onClear
}: {
  count: number
  totalCount?: number
  loading?: RequestStatus
  error?: RequestError
  refresh: () => void
  children?: React.ReactNode
  perPage?: number
  setPage?: (page: number) => void
  setPerPage?: (perPage: number) => void
  next?: string
  onNext?: () => void
  onClear?: () => void
}) => {
  const errMsg = error?.detail
  const isLoading = loading === RequestStatus.Pending
  const end = perPage ? perPage * count : null
  const maxEnd = end && totalCount ? Math.min(end, totalCount) : null
  const start = perPage && end ? end - perPage + 1 : null

  return (
    <TableRefreshView>
      {errMsg ? (
        <TableRefreshError>
          <TableRefreshIcon>
            <AlertCircle size={14} strokeWidth={2} />
          </TableRefreshIcon>
          <p>{errMsg}</p>
        </TableRefreshError>
      ) : isLoading ? (
        <>
          <TableRefreshIcon className="spin">
            <RefreshCcw size={14} strokeWidth={2} />
          </TableRefreshIcon>
          <p>{count ? 'Refreshing...' : 'Loading results'}</p>
        </>
      ) : (
        <>
          <ButtonLink
            onClick={refresh}
            style={{ lineHeight: 0, borderBottom: 0 }}
          >
            <TableRefreshIcon>
              <RefreshCcw size={14} strokeWidth={2} />
            </TableRefreshIcon>
          </ButtonLink>
          {perPage && setPage && setPerPage ? (
            <TableRefreshPager>
              {totalCount ? (
                <span>
                  Displaying results {start}-{maxEnd}
                  {totalCount ? ` of ${totalCount}` : ''}.
                </span>
              ) : (
                <p>Displaying 0 results.</p>
              )}
              <TableRefreshPagerArrows>
                <button
                  onClick={() => setPage(count - 1)}
                  disabled={count === 1 || !totalCount}
                >
                  <ArrowLeftCircle width={18} height={18} strokeWidth={1} />
                </button>
                <button
                  onClick={() => setPage(count + 1)}
                  disabled={maxEnd === totalCount || !totalCount}
                >
                  <ArrowRightCircle width={18} height={18} strokeWidth={1} />
                </button>
              </TableRefreshPagerArrows>
              <TableRefreshPerPage
                perPage={perPage}
                increments={[5, 10, 25, 50, 100]}
                setPerPage={setPerPage}
              />
            </TableRefreshPager>
          ) : (
            <>
              <p>Displaying {count} results.</p>
              {next && onNext && (
                <>
                  <ButtonLink
                    size="small"
                    onClick={onNext}
                    style={{ margin: '0 5px' }}
                  >
                    See next results
                  </ButtonLink>
                </>
              )}
              {onClear && (
                <>
                  {next && <TextBody size="small">or</TextBody>}
                  <ButtonLink
                    size="small"
                    onClick={onClear}
                    style={{ margin: '0 5px' }}
                  >
                    Reset all filters.
                  </ButtonLink>
                </>
              )}
              {children && <TableResetFilters>{children}</TableResetFilters>}
            </>
          )}
        </>
      )}
    </TableRefreshView>
  )
}

export default TableRefresh
