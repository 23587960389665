import styled from '@emotion/styled'
import ScrollHide from '../ScrollHide'

export const EntityNavView = styled.div`
  label: EntityNav;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  align-self: flex-start;
  height: 100vh;
  flex: 0 0 ${props => props.theme.layout.desktop.sidebar.width};
  width: ${props => props.theme.layout.desktop.sidebar.width};
  border-right: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${props => props.theme.colors.background.secondary};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    position: fixed;
    z-index: 4;
    top: ${props => props.theme.layout.mobile.header.height};
    left: ${props => props.theme.layout.mobile.padding};
    right: ${props => props.theme.layout.mobile.padding};
    width: auto;
    height: auto;
    border: ${props => props.theme.layout.desktop.border} solid
      ${props => props.theme.colors.border.secondary};
  }
`

export const EntityNavHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 ${props => props.theme.layout.desktop.header.height};
  padding: 0 1rem 0 ${props => props.theme.layout.desktop.sidebar.padding};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: none;
  }
`

export const EntityNavToggle = styled.div`
  width: 100%;
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: block;
  }

  button {
    width: 100%;
    height: 4.4rem;
    padding: 0 1rem 0 1.5rem;
    border: 0;
    background-color: transparent;
    // background-color: pink;
    color: ${props => props.theme.colors.text.primary};
  }

  button > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const EntityNavToggleIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0 0;
  // background-color: gray;
`

export const EntityNavLinks = styled(ScrollHide)<{ showLinks?: boolean }>`
  margin-right: -${props => props.theme.layout.desktop.border};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    max-height: ${props => (props.showLinks ? '100%' : '0')};
  }
`

interface EntityNavLinkViewProps {
  hasSubsections: boolean
}

export const EntityNavLinkView = styled.li<EntityNavLinkViewProps>`
  label: EntityNavLink;
  display: block;
  font-size: ${props => props.theme.sizes.small};

  &:last-of-type {
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      margin-bottom: 1rem;
    }
  }

  a {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    padding: 0.9rem 1rem 0.8rem
      ${props => props.theme.layout.desktop.sidebar.padding};
    color: ${props => props.theme.colors.text.primary};
    font-weight: 500;
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      padding-left: 1.5rem;
    }
  }

  a:hover,
  a:active {
    background-color: ${props => props.theme.colors.background.tertiary};
  }

  a.active {
    // color: ${props => props.theme.colors.accent.primary};
    background-color: ${props => props.theme.colors.background.tertiary};
    // font-weight: 700;

    &:after {
      position: absolute;
      content: ' ';
      top: 0;
      bottom: 0;
      right: 0;
      width: 0.3rem;
      background-color: ${props => props.theme.colors.text.primary};
    }
  }

  & > a.active:after {
    width: ${props => (props.hasSubsections ? '0' : '0.2rem')};
  }

  a.active ~ ul {
    display: flex;
    padding-bottom: 0.5rem;
  }

  a.active ~ ul li a {
    padding: 0.7rem 1rem 0.6rem
      ${props => props.theme.layout.desktop.sidebar.padding};
  }
`

export const EntityNavLinkText = styled.span`
  label: EntityNavLinkText;
  display: block;
  line-height: 1;
`

export const EntityNavLinkSubsection = styled.ul`
  label: EntityNavLinkSubsection;
  display: none;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.tertiary};
`

export const EntityNavLinkSubsectionLink = styled.li`
  label: EntityNavLinkSubsectionLink;
  display: block;
  padding-left: 1.5rem;
  font-size: ${props => props.theme.sizes.small};
`
