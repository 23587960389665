import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { PosTypes } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface PosTypesState {
  data: PosTypes | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PosTypesState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type PosTypesParams = {
  supported: boolean
}

export const fetchPosTypes = createAsyncThunk<
  PosTypes,
  PosTypesParams | void,
  { state: RootState; rejectValue: RequestError }
>('posTypes/fetchPosTypes', async (params, { getState, rejectWithValue }) => {
  try {
    const iacmUser = getState().iacmUser
    const { headers } = iacmUser
    const endpoint =
      'https://staging-api.itsacheckmate.com/token_api/pos_types?supported=true'
    const requestHeaders = {
      'Access-Token': `${headers?.accessToken}`,
      Client: `${headers?.client}`,
      Uid: `${headers?.uid}`,
      Expiry: `${headers?.expiry}`
    }
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: requestHeaders
    })
    return (await response.json()) as PosTypes
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const posTypesSlice = createSlice({
  name: 'posTypes',
  initialState,
  reducers: {
    resetPosTypes: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPosTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPosTypes.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchPosTypes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectPosTypeOptions = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.posTypes
    return { data, loading, error }
  },
  ({ data }) => {
    if (!data) return []
    const posTypeOptions = data.map(({ name, display_name }) => ({
      value: name,
      text: display_name
    }))
    return posTypeOptions
  }
)

export const { resetPosTypes } = posTypesSlice.actions

export const selectPosTypes = (state: RootState) => state.posTypes

export default posTypesSlice.reducer
