import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IconoirProvider, MoreVertCircle } from 'iconoir-react'
import { QuickLinksView } from './QuickLinks.styled'
import QuickLinksMenu from './QuickLinksMenu'

export interface QuickLinksProps {
  id: number | string
  links?: { path: string; icon: React.ReactNode; title: string }[]
  actions?: {
    icon: React.ReactNode
    text: string
    callback: React.MouseEventHandler<HTMLButtonElement>
  }[]
}

const QuickLinks = ({ id, links, actions }: QuickLinksProps) => {
  const [show, setShow] = useState(false)

  const handleDropdownClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation() // Stop propagation of click event to prevent triggering parent click event
    setShow(!show)
  }

  return (
    <IconoirProvider
      iconProps={{ width: '2rem', height: '2rem', strokeWidth: '1.5' }}
    >
      <QuickLinksView className="quick-links-view">
        {links?.map(link => (
          <Link key={link.path} to={`${id}/${link.path}`} title={link.title}>
            {link.icon}
          </Link>
        ))}
        {actions && (
          <button onClick={handleDropdownClick}>
            <MoreVertCircle />
          </button>
        )}
        {actions && show && (
          <QuickLinksMenu
            id={id}
            actions={actions}
            show={show}
            setShow={setShow}
          />
        )}
      </QuickLinksView>
    </IconoirProvider>
  )
}

export default QuickLinks
