import { useEffect, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { omit, RequestStatus } from 'utils'
import { FormFieldType, ModifierGroup } from 'types'
import { Form } from 'components'
import {
  createModifierGroup,
  fetchModifierGroup,
  selectModifierGroup,
  upsertModifierGroup
} from 'slices/modifierGroup'
import { selectMenu } from 'slices/menu'

const MenuModifierGroupSettings = ({
  menuId,
  modifierGroupId,
  scrollWindow,
  navigate,
  callback
}: {
  menuId: string | number
  modifierGroupId: string | number
  scrollWindow?: HTMLDivElement | null
  navigate?: NavigateFunction
  callback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { data: menuData } = useAppSelector(selectMenu)
  const isExternal = menuData?.is_external ? true : false
  const { data, loading, error } = useAppSelector(selectModifierGroup)
  const hasError = error ? true : false
  const isLoading = loading === RequestStatus.Pending
  const formData =
    modifierGroupId !== 'new' && data?.modifier_group_id
      ? omit<ModifierGroup, 'modifier_group_id'>(data, 'modifier_group_id')
      : modifierGroupEmpty

  const handleModifierGroupSubmit = (values: ModifierGroup) => {
    if (!menuId) return
    setSubmitted(true)
    let augmented = { ...values, ...modifierGroupDefaults }
    delete augmented.items
    delete augmented.files
    if (modifierGroupId === 'new' && navigate) {
      dispatch(createModifierGroup({ menuId, data: augmented, navigate }))
    } else if (modifierGroupId) {
      dispatch(
        upsertModifierGroup({ menuId, modifierGroupId, data: augmented })
      )
    }
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      if (callback && !error) callback()
      setSubmitted(false)
    }
  }, [dispatch, submitted, isLoading, error, callback])

  useEffect(() => {
    return () => {
      if (hasError && modifierGroupId) {
        const params = { with_related: 'items' }
        dispatch(fetchModifierGroup({ modifierGroupId, params }))
      }
    }
  }, [hasError, modifierGroupId, dispatch])

  return (
    <Form
      fields={modifierGroupFields(isExternal)}
      data={formData}
      error={error}
      isLoading={isLoading}
      submit={handleModifierGroupSubmit}
      scrollWindow={scrollWindow}
    />
  )
}

const modifierGroupEmpty = {
  full_name: '',
  short_name: '',
  description: '',
  pos_display_color: null,
  is_size: false,
  is_combo: false,
  is_active: true
}

const modifierGroupDefaults = {
  is_editable: true,
  is_pos_addl_name: false,
  is_prefixed: false,
  is_upsell: false,
  pos_display_color: null,
  pos_ext_id: '',
  pos_ext_name: '',
  prefix_add: '',
  prefix_remove: '',
  prefix_sub: ''
}

const modifierGroupFields = (isExternal: boolean): FormFieldType[] => [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'full_name',
    required: true,
    label: 'Name',
    error: 'Please enter a name'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'short_name',
    required: isExternal ? false : true,
    readOnly: isExternal ? true : false,
    label: 'Internal Name',
    error: 'Please enter an internal name'
  },
  {
    fieldType: 'TextArea',
    name: 'description',
    label: 'Description',
    error: 'Please enter a description'
  },
  {
    fieldType: 'Checkbox',
    label: 'Is Size',
    name: 'is_size'
  },
  {
    fieldType: 'Checkbox',
    label: 'Is Pizza',
    name: 'is_combo'
  },
  {
    fieldType: 'Checkbox',
    label: 'Active',
    name: 'is_active'
  }
]

export default MenuModifierGroupSettings
