import { createContext } from 'react'
import { Button, Label } from 'components'
import { listFilterEmptyCondition } from 'config'
import { CategoryOption, ListFilter as ListFilterType } from 'types'
import ListFilterCondition from './ListFilterCondition'
import ListFilters from './ListFilters'
import {
  ListFilterButtons,
  ListFilterFooter,
  ListFilterView
} from './ListFilter.styled'

interface ListFilterContextType {
  categories: CategoryOption[]
}

export const ListFilterContext = createContext<ListFilterContextType | null>(
  null
)

const ListFilter = ({
  categories,
  listFilters,
  setListFilters,
  applyListFilters,
  clearListFilters,
  children
}: {
  categories: CategoryOption[]
  listFilters: ListFilterType[]
  setListFilters: (setListFilters: ListFilterType[]) => void
  applyListFilters: () => void
  clearListFilters: () => void
  children?: React.ReactNode
}) => {
  const isMany = listFilters.length > 1

  const addCondition = () => {
    setListFilters([...listFilters, listFilterEmptyCondition])
  }

  const updateCondition = (index: number, values: ListFilterType) => {
    const updated = listFilters.map((i, idx) => {
      return index === idx ? values : i
    })
    setListFilters(updated)
  }

  const removeCondition = () => {
    if (isMany) {
      const updated = [...listFilters]
      updated.pop() // Remove the last element
      setListFilters(updated)
    }
  }

  const isDisabled = listFilters.some(
    listFilter =>
      !listFilter.category ||
      !listFilter.value ||
      (listFilter.operator === 'range' && !listFilter.secondValue)
  )

  return (
    <ListFilterContext.Provider value={{ categories }}>
      <ListFilterView>
        <ListFilters>
          <Label label="Category" />
          <Label label="Operator" />
          <Label label="Keyword" />
          <Label label={isMany ? 'And/Or' : ' '} />
        </ListFilters>
        {listFilters.map((listFilter, index) => (
          <ListFilterCondition
            listFilter={listFilter}
            key={index}
            totalConditions={listFilters.length}
            index={index}
            addCondition={addCondition}
            updateCondition={updateCondition}
            removeCondition={removeCondition}
          />
        ))}
        {listFilters.length ? (
          <ListFilterFooter>
            <ListFilterButtons>
              <Button
                size="small"
                onClick={applyListFilters}
                disabled={isDisabled}
              >
                Apply Filters
              </Button>
              <Button size="small" color="secondary" onClick={clearListFilters}>
                Clear all
              </Button>
            </ListFilterButtons>
            {children}
          </ListFilterFooter>
        ) : null}
      </ListFilterView>
    </ListFilterContext.Provider>
  )
}

export default ListFilter
