import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button, ErrMsg, TextBody } from 'components'
import { ModalWindow } from 'types'
import { RequestStatus } from 'utils'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { deleteSegment, resetSegment, selectSegment } from 'slices/segment'
import { showNotification } from 'slices/notification'
import { useEffect } from 'react'

const DeleteSegmentConfirmation = ({
  args
}: {
  args: {
    segmentId: number
    handleFetchSegments: () => void
  }
  modalWindow: ModalWindow
}) => {
  const { segmentId, handleFetchSegments } = args
  const dispatch = useAppDispatch()
  const { error, loading } = useAppSelector(selectSegment)
  const isLoading = loading === RequestStatus.Pending

  const handleDelete = () => {
    dispatch(
      deleteSegment({
        segmentId: segmentId
      })
    )
      .unwrap()
      .then(() => {
        dispatch(closeModal())
        dispatch(showNotification('Segment deleted successfully.'))
      })
      .finally(() => {
        handleFetchSegments()
      })
  }

  useEffect(() => {
    return () => {
      dispatch(resetSegment())
    }
  }, [dispatch])

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Confirmation" />
      <ErrMsg errMsg={error?.detail} />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        Are you sure you want to delete this segment?
      </TextBody>
      <ModalFooter>
        <Button
          color="secondary"
          size="medium"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </ModalFooter>
    </>
  )
}

export default DeleteSegmentConfirmation
