import { Avatar } from 'components/Avatar'
import { EditPencil } from 'iconoir-react'
import {
  EditableAvatarIconWrapper,
  EditableAvatarView
} from './EditableAvatar.styled'

interface EditableAvatarProps {
  name: string
  onEdit: () => void
}

const EditableAvatar = ({ name, onEdit }: EditableAvatarProps) => {
  return (
    <EditableAvatarView onClick={onEdit}>
      <Avatar name={name} />
      <EditableAvatarIconWrapper className="edit-pencil">
        <EditPencil />
      </EditableAvatarIconWrapper>
    </EditableAvatarView>
  )
}

export default EditableAvatar
