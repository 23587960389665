import { NavLink } from 'react-router-dom'
import { SidebarLinkProps } from 'types'
import {
  EntityNavLinkSubsection,
  EntityNavLinkSubsectionLink,
  EntityNavLinkText,
  EntityNavLinkView
} from './EntityNav.styled'

const EntityNavLink = ({
  path,
  title,
  end = false,
  subsections
}: SidebarLinkProps) => {
  return (
    <EntityNavLinkView hasSubsections={!!subsections}>
      <NavLink to={path} end={end}>
        <EntityNavLinkText>{title}</EntityNavLinkText>
      </NavLink>
      {subsections && (
        <EntityNavLinkSubsection>
          {subsections.map(subsection => {
            const subsectionPath = `${path}/${subsection.path}`
            return (
              <EntityNavLinkSubsectionLink key={subsectionPath}>
                <NavLink to={subsectionPath}>
                  <span>{subsection.title}</span>
                </NavLink>
                {subsection.subsections && (
                  <EntityNavLinkSubsection>
                    {subsection.subsections?.map(sub => {
                      const subPath = `${subsectionPath}/${sub.path}`
                      return (
                        <EntityNavLinkSubsectionLink key={subPath}>
                          <NavLink to={subPath}>
                            <span>{sub.title}</span>
                          </NavLink>
                        </EntityNavLinkSubsectionLink>
                      )
                    })}
                  </EntityNavLinkSubsection>
                )}
              </EntityNavLinkSubsectionLink>
            )
          })}
        </EntityNavLinkSubsection>
      )}
    </EntityNavLinkView>
  )
}

export default EntityNavLink
