import { Button } from 'components'
import { ModalWindow } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { selectCampaign } from 'slices/campaign'
import {
  addCommas,
  getCampaignChannels,
  getCampaignTriggerSummary,
  getScheduleSummary
} from 'utils'
import { selectCampaignRecipients } from 'slices/campaignRecipients'

const CampaignReview = ({
  args: { isSendNow, onSubmit },
  modalWindow
}: {
  args: { isSendNow: boolean; onSubmit: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data: campaign } = useAppSelector(selectCampaign)
  const { data: campaignRecipients } = useAppSelector(selectCampaignRecipients)

  const submit = () => {
    onSubmit()
    dispatch(closeModal())
  }

  const renderSummary = () => {
    if (!campaign) return 'Loading...'
    if (!campaign.campaign_content?.length) return 'No content'
    const isSingleSend = campaign?.campaign_type === 'SINGLE_SEND'
    const targetedChannels = getCampaignChannels(campaign).filter(
      channel => channel !== 'WEB'
    )
    const recipientsPerChannel = targetedChannels.map(
      channel =>
        campaignRecipients.find(r => r.channel === channel) ?? {
          channel,
          quantity: 0
        }
    )
    const recipientTotal = addCommas(
      recipientsPerChannel.reduce((acc, curr) => acc + curr.quantity, 0),
      0
    )

    const scheduleSummary = getScheduleSummary(campaign)
    const triggerSummary = getCampaignTriggerSummary(campaign)

    const channelCounts =
      recipientsPerChannel.length > 1
        ? `, ${recipientsPerChannel.map(r => `${r.channel} (${r.quantity})`).join(', ')}`
        : ''

    if (isSingleSend) {
      if (isSendNow)
        return `This campaign will be sent to ${recipientTotal} customers${channelCounts}. Are you sure you want to send this campaign now?`
      return `This campaign will be ${scheduleSummary} and sent to ${recipientTotal} customers${channelCounts}. Are you sure you want to schedule this campaign?`
    } else {
      return `This campaign will be ${scheduleSummary} and ${triggerSummary}. Are you sure you want to ${isSendNow ? 'start' : 'schedule'} this campaign?`
    }
  }

  const renderTitle = () => {
    if (campaign?.campaign_type === 'SINGLE_SEND') {
      return `${isSendNow ? 'send' : 'schedule'}`
    } else if (campaign?.campaign_type === 'RECURRING') {
      return `${isSendNow ? 'start' : 'schedule'}`
    }
  }

  const renderButtonText = () => {
    if (campaign?.campaign_type === 'SINGLE_SEND') {
      return isSendNow ? 'Send now' : 'Schedule'
    } else if (campaign?.campaign_type === 'RECURRING') {
      return isSendNow ? 'Start' : 'Schedule'
    }
  }

  return (
    <>
      <ModalHeader
        title={`Ready to ${renderTitle()}?`}
        subtitle={renderSummary()}
      />
      <Button onClick={submit}>{renderButtonText()}</Button>
    </>
  )
}

export default CampaignReview
