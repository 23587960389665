import Table from './Table'
import TableHeader from './TableHeader'
import TableHeaderSortable from './TableHeaderSortable'
import TableTotals from './TableTotals'
import { TableActions, TableButton } from './Table.styled'

export {
  TableActions,
  TableButton,
  TableHeader,
  TableHeaderSortable,
  TableTotals
}

export default Table
