import styled from '@emotion/styled'

export const PagesImageView = styled.div`
  label: PagesImage;
  flex: 1 1 32rem;
  position: relative;
`

export const PagesImageLink = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: 1rem 0 0;

  a {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    transition: ${props => props.theme.transition};
    font-size: ${props => props.theme.sizes.xxsmall};

    &:hover,
    &:active {
      color: ${props => props.theme.colors.accent.primary};
    }
  }
`
