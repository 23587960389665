import styled from '@emotion/styled'

export const OrderTotalsMainItemView = styled.div`
  display: flex;
  flex-direction: row;
`

export const OrderTotalsSubItemView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7rem;

  span {
    font-size: ${props => props.theme.sizes.xsmall};
  }

  button {
    padding: 0;
    border-radius: ${props => props.theme.borderRadius.large};
    margin-left: 0.8rem;
  }
`

export const OrderTotalsItemLabel = styled.span``

export const OrderTotalsItemValue = styled.span`
  margin-left: auto;
`
