import { gql } from 'graphql-request'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ListFilter, OnboardingAllBrands } from 'types'
import {
  ONBOARDING_ALL_BRAND_FRAGMENT,
  RequestError,
  RequestStatus
} from 'utils'

export interface OnboardingBrandsState {
  data: OnboardingAllBrands | null
  loading: RequestStatus
  error: RequestError
}

export interface OnboardingBrandsAPI {
  onboardingAllBrands: OnboardingAllBrands
}

const initialState: OnboardingBrandsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const LOAD_ONBOARDING_ALL_BRANDS = gql`
  query UseOnboardingAllBrands(
    $searchTerm: String
    $page: Int
    $perPage: Int
    $brandIds: [ID!]
    $filters: [JSON!]
    $filterByArchiveStatus: Boolean
  ) {
    onboardingAllBrands(
      searchTerm: $searchTerm
      page: $page
      perPage: $perPage
      brandIds: $brandIds
      filters: $filters
      filterByArchiveStatus: $filterByArchiveStatus
    ) {
      page
      totalPageCount
      totalRecordsCount
      results {
        ...OnboardingAllBrandFragment
      }
    }
  }
  ${ONBOARDING_ALL_BRAND_FRAGMENT}
`

export type OnboardingBrandsParams = {
  searchTerm?: string
  page: number
  perPage: number
  brandIds?: string[]
  filters?: ListFilter[]
  filterByArchiveStatus?: string | boolean
  corporateOnly?: boolean
  appendArchiveInName?: boolean
}

export const fetchOnboardingBrands = createAsyncThunk<
  OnboardingBrandsAPI,
  OnboardingBrandsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingBrands/fetchOnboardingBrands',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_ONBOARDING_ALL_BRANDS,
        params
      )) as OnboardingBrandsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingBrandsSlice = createSlice({
  name: 'onboardingBrands',
  initialState,
  reducers: {
    resetOnboardingBrands: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOnboardingBrands.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOnboardingBrands.fulfilled, (state, { payload }) => {
      state.data = payload.onboardingAllBrands
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOnboardingBrands.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOnboardingBrands } = onboardingBrandsSlice.actions

export const selectOnboardingBrands = (state: RootState) =>
  state.onboardingBrands

export default onboardingBrandsSlice.reducer
