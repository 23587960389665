import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreGratuity } from 'types'
import { showNotification } from 'slices/notification'

export interface StoreGratuityState {
  data: StoreGratuity | null
  loading: RequestStatus
  error: RequestError
}

export interface StoreGratuityApi {
  data: StoreGratuity
  from: {
    store_id: number
  }
}

const initialState: StoreGratuityState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreGratuity = createAsyncThunk<
  StoreGratuity | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeGratuity/fetchStoreGratuity',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/gratuity`
      )) as StoreGratuityApi
      return resp ? resp.data : null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreGratuity = createAsyncThunk<
  StoreGratuity,
  { storeId: string | number; data: StoreGratuity },
  { state: RootState; rejectValue: RequestError }
>(
  'storeGratuity/upsertStoreGratuity',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/gratuity`,
        'PUT',
        data
      )) as StoreGratuityApi
      dispatch(showNotification('Updated successfully!'))
      return resp.data
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreGratuity = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeGratuity/deleteStoreGratuity',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/settings/gratuity`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Gratuity settings removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeSlice = createSlice({
  name: 'storeGratuity',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreGratuity.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreGratuity.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchStoreGratuity.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreGratuity.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreGratuity.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertStoreGratuity.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreGratuity.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreGratuity.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteStoreGratuity.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

// selectors
export const selectStoreGratuity = (state: RootState) => state.storeGratuity

export default storeSlice.reducer
