import React from 'react'

const Orders = React.lazy(() => import('./Orders'))

const BulkOrder = React.lazy(() => import('./BulkOrders/BulkOrder'))
const BulkOrders = React.lazy(() => import('./BulkOrders/BulkOrders'))
const HeldOrders = React.lazy(() => import('./HeldOrders/HeldOrders'))
const Order = React.lazy(() => import('./Order/Order'))
const OrderMgmt = React.lazy(() => import('./OrderMgmt/OrderMgmt'))
const OrderMgmtStore = React.lazy(() => import('./OrderMgmt/OrderMgmtStore'))
const PendingOrders = React.lazy(() => import('./PendingOrders/PendingOrders'))
const UnchargedOrders = React.lazy(
  () => import('./UnchargedOrders/UnchargedOrders')
)
const UpcomingOrders = React.lazy(
  () => import('./UpcomingOrders/UpcomingOrders')
)

export {
  BulkOrder,
  BulkOrders,
  HeldOrders,
  Order,
  OrderMgmt,
  OrderMgmtStore,
  Orders,
  PendingOrders,
  UnchargedOrders,
  UpcomingOrders
}
