import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LoyaltyTier } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface LoyaltyTierItemState {
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyTierItemState = {
  loading: RequestStatus.Idle,
  error: null
}

export const createLoyaltyTierItem = createAsyncThunk<
  LoyaltyTier,
  {
    itemIds: number[]
    loyaltyTierId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyTierItem/createLoyaltyTierItem',
  async (
    { itemIds, loyaltyTierId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const promises = itemIds.map(async itemId => {
        const response = await api?.request(
          `items/${itemId}/loyalty-tiers`,
          'POST',
          [{ loyalty_tier_id: loyaltyTierId }]
        )
        return response as LoyaltyTier
      })
      const results = await Promise.all(promises)
      const resp = results[0] as LoyaltyTier
      dispatch(showNotification('Items successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const deleteLoyaltyTierItem = createAsyncThunk<
  void,
  { itemId: string | number; data: { loyalty_tier_id: number }[] },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyTierItem/deleteLoyaltyTierItem',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-tiers`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Item successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyTierItemSlice = createSlice({
  name: 'loyaltyTierItem',
  initialState,
  reducers: {
    resetLoyaltyTierItem: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createLoyaltyTierItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyTierItem.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createLoyaltyTierItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteLoyaltyTierItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteLoyaltyTierItem.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteLoyaltyTierItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyTierItem } = loyaltyTierItemSlice.actions

export const selectLoyaltyTierItem = (state: RootState) => state.loyaltyTierItem

export default loyaltyTierItemSlice.reducer
