import styled from '@emotion/styled'
import { XmarkCircle } from 'iconoir-react'

const DesignInputClearView = styled.button`
  position: absolute;
  z-index: 6;
  top: -0.8rem;
  right: -0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.text.primary};
`

const DesignInputClear = ({
  setValue
}: {
  setValue: (value: string | number | null) => void
}) => {
  return (
    <DesignInputClearView onClick={() => setValue(null)}>
      <XmarkCircle width={12} height={12} />
    </DesignInputClearView>
  )
}

export default DesignInputClear
