import { Card } from 'components'
import {
  CheckCircle,
  CheckCircleSolid,
  Circle,
  IconoirProvider
} from 'iconoir-react'
import {
  ContentCardBodyWrapper,
  ContentCardCheckWrapper,
  ContentCardContainer,
  ContentCardDescription,
  ContentCardHeader,
  ContentCardIconWrapper
} from './ContentCard.styled'
import ContentCardFooter from './ContentCardFooter'

interface ContentCardProps {
  title: string
  icon: React.ReactNode
  description?: React.ReactNode
  content?: React.ReactNode
  isDisabled?: boolean
  isSelected?: boolean
  isActive?: boolean
  isComplete?: boolean
  isLoading?: boolean
  lastUpdated?: string
  onClick: () => void
  linksActions?: {
    icon: React.ReactNode
    text: string
    callback: React.MouseEventHandler<HTMLButtonElement>
  }[]
}

const ContentCard = ({
  title,
  icon,
  isDisabled,
  isSelected,
  isComplete,
  isLoading,
  lastUpdated,
  onClick,
  linksActions,
  description,
  content
}: ContentCardProps) => {
  return (
    <ContentCardContainer
      disabled={isDisabled}
      isLoading={isLoading}
      selected={isSelected}
      onClick={onClick}
    >
      <ContentCardBodyWrapper>
        <Card
          titleCta={
            <ContentCardHeader>
              <>
                <ContentCardIconWrapper>{icon}</ContentCardIconWrapper>
                <strong>{title}</strong>
                <ContentCardDescription>{description}</ContentCardDescription>
              </>
              <ContentCardCheckWrapper selected={isSelected}>
                <IconoirProvider iconProps={{ fontSize: 12 }}>
                  {isComplete ? (
                    <CheckCircleSolid />
                  ) : isSelected ? (
                    <CheckCircle />
                  ) : (
                    <Circle />
                  )}
                </IconoirProvider>
              </ContentCardCheckWrapper>
            </ContentCardHeader>
          }
          footer={
            <ContentCardFooter
              isComplete={isComplete}
              isDisabled={isDisabled}
              lastUpdated={lastUpdated}
              isSelected={isSelected}
              isLoading={isLoading}
              title={title}
              onClick={onClick}
              linksActions={linksActions}
            />
          }
        >
          {content}
        </Card>
      </ContentCardBodyWrapper>
    </ContentCardContainer>
  )
}

export default ContentCard
