import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { KdsTerminal } from 'types'
import { showNotification } from 'slices/notification'
import { fetchKdsTerminals } from 'slices/kdsTerminals'

export interface KdsTerminalState {
  data: KdsTerminal | null
  loading: RequestStatus
  error: RequestError
}

const initialState: KdsTerminalState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type KdsTerminalParams = {
  expand?: string
  with_related?: string
}

export const fetchKdsTerminal = createAsyncThunk<
  KdsTerminal | null,
  { kdsTerminalId: string | number; params?: KdsTerminalParams },
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminal/fetchKdsTerminal',
  async ({ kdsTerminalId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/kds-terminals/${kdsTerminalId}${queryParams}`
      )) as KdsTerminal
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createKdsTerminal = createAsyncThunk<
  KdsTerminal,
  { data: KdsTerminal },
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminal/createKdsTerminal',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/kds-terminals',
        'POST',
        data
      )) as KdsTerminal
      dispatch(showNotification('KDS terminal successfully created!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchKdsTerminals({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateKdsTerminal = createAsyncThunk<
  KdsTerminal,
  { kdsTerminalId: string | number; data: KdsTerminal },
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminal/updateKdsTerminal',
  async ({ kdsTerminalId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/kds-terminals/${kdsTerminalId}`,
        'PUT',
        data
      )) as KdsTerminal
      dispatch(showNotification('KDS terminal successfully updated!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchKdsTerminals({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const kdsTerminalSlice = createSlice({
  name: 'kdsTerminal',
  initialState,
  reducers: {
    resetKdsTerminal: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchKdsTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchKdsTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchKdsTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createKdsTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createKdsTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createKdsTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateKdsTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateKdsTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateKdsTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetKdsTerminal } = kdsTerminalSlice.actions

export const selectKdsTerminal = (state: RootState) => state.kdsTerminal

export default kdsTerminalSlice.reducer
