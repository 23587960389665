import { TextBody } from 'components'
import {
  RadioContent,
  RadioButton,
  RadioInput,
  RadioLabel,
  RadioInputContainer
} from './Radio.styled'

interface RadioProps {
  label: string
  name?: string
  value: string
  checked: boolean
  variant?: 'bordered' | 'default'
  button?: 'hidden' | 'default'
  content?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Radio = ({
  label,
  name,
  value,
  checked,
  variant = 'default',
  button = 'default',
  content = '',
  onChange
}: RadioProps) => {
  return (
    <RadioLabel variant={variant} checked={checked} htmlFor={value}>
      <RadioInputContainer>
        <RadioInput
          type="radio"
          id={value}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {button === 'default' && <RadioButton />}
        <TextBody size="small">
          <strong>{label}</strong>
        </TextBody>
      </RadioInputContainer>
      {content && <RadioContent>{content}</RadioContent>}
    </RadioLabel>
  )
}

export default Radio
