import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const MenuNavView = styled.div`
  position: sticky;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colors.background.primary};
  height: 6rem;
  top: 5.6rem;
`

export const MenuNavSeparator = styled.div`
  padding: 0 1rem;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 0 0.5rem;
  }

  & > span {
    color: ${props => props.theme.colors.border.secondary};
  }
`

export const MenuNavButton = styled.div`
  padding: 0.8rem 0;

  button {
    padding: 0;
    border: 0;
  }
`

export const MenuCategoriesView = styled.div`
  margin: 6rem 0;
`

export const MenuCategoriesList = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.5rem;
`

export const MenuCategoriesCategory = styled.button`
  position: relative;
  text-align: left;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  background-color: transparent;
`

export const MenuCategoryView = styled.div<{ isChild: boolean }>`
  margin: ${props => (props.isChild ? '3rem 0 0' : '6rem 0 0')};
`

export const MenuCategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 3rem;
`

export const MenuCategoryHeaderText = styled.div`
  flex: 1 1 ${props => props.theme.layout.desktop.pageWidth};
  max-width: ${props => props.theme.layout.desktop.pageWidth};
`

export const MenuCategoryTitle = styled(TextHeading)``

export const MenuCategorySubtitle = styled(TextBody)`
  margin: 0.5rem 0 0;
  line-height: 1.4;
`

export const MenuCategoryImage = styled.div`
  flex: 0 0 16rem;
  position: relative;
  aspect-ratio: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.tertiary};

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const MenuCategoryItems = styled.div`
  width: 100%;
  margin: 3rem 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.5rem;
`

export const MenuItemView = styled.button`
  position: relative;
  text-align: left;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  background-color: transparent;

  &:enabled:hover {
    border-color: ${props => props.theme.colors.text.primary};
  }

  &:disabled > div:last-of-type {
    opacity: 0.25;
  }
`

export const MenuItemCount = styled.div`
  position: absolute;
  z-index: 1;
  top: -1rem;
  right: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 1.2rem;
  padding: 0;
  background-color: ${props => props.theme.colors.error.background};

  span {
    color: ${props => props.theme.colors.background.primary};
  }
`

export const MenuItemImage = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.tertiary};

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const MenuItemSoldOut = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  gap: 0.6rem;
  // color: ${props => props.theme.colors.error.background};
  color: ${props => props.theme.colors.text.primary};
`

export const MenuItemSoldOutText = styled(TextBody)`
  // color: ${props => props.theme.colors.error.background};
  text-transform: uppercase;
`

export const MenuItemInfo = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1.3rem 1.2rem;
  border-top: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
`

export const MenuItemTitle = styled(TextHeading)``

export const MenuItemPrice = styled(TextHeading)`
  margin-top: 1rem;
`

export const MenuItemDescription = styled(TextBody)`
  margin: 0.5rem 0 0;
  line-height: 1.4;
`

export const MenuItemId = styled(TextBody)`
  position: absolute;
  z-index: 1;
  bottom: 0.5rem;
  right: 0.5rem;
`
