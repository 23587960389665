import { MenuItem as MenuItemType } from '@open-tender/types'
import TextBody from 'components/TextBody'
import {
  MenuItemCount,
  MenuItemId,
  MenuItemImage,
  MenuItemInfo,
  MenuItemPrice,
  MenuItemSoldOut,
  MenuItemSoldOutText,
  MenuItemTitle,
  MenuItemView
} from './Menu.styled'
import Img from 'components/Img'
import { WarningTriangle } from 'iconoir-react'

const MenuItem = ({
  item,
  count,
  isSoldOut = false,
  onClick
}: {
  item: MenuItemType
  count?: number
  isSoldOut?: boolean
  onClick?: () => void
}) => {
  return (
    <MenuItemView onClick={onClick} disabled={isSoldOut}>
      {count && (
        <MenuItemCount>
          <TextBody size="small">
            <strong>{count}</strong>
          </TextBody>
        </MenuItemCount>
      )}
      <MenuItemImage>
        <Img src={item.small_image_url} title={item.name} fontSize="small" />
        {isSoldOut && (
          <MenuItemSoldOut>
            <WarningTriangle width={16} height={16} strokeWidth={1.5} />
            <MenuItemSoldOutText>Sold Out</MenuItemSoldOutText>
          </MenuItemSoldOut>
        )}
      </MenuItemImage>
      <MenuItemInfo>
        <MenuItemId size="xsmall" color="tertiary">
          {item.id}
        </MenuItemId>
        <MenuItemTitle as="p" size="medium">
          {item.name}
        </MenuItemTitle>
        <MenuItemPrice as="p" size="small">
          ${item.price}
        </MenuItemPrice>
      </MenuItemInfo>
    </MenuItemView>
  )
}

export default MenuItem
