export const getLocalStorageItem = <T>(key: string): T | undefined => {
  const item = localStorage.getItem(key)

  if (item) {
    return JSON.parse(item)
  }

  return undefined
}

export const setLocalStorageItem = (value: string, key: string): void => {
  localStorage.setItem(key, value)
}

export const removeLocalStorageItem = (key: string): void => {
  localStorage.removeItem(key)
}
