import { ModalWindow } from 'types'
import { CartItem } from '@open-tender/types'
import { useBuilder, makeModifierGroups, useMenuItem } from '@open-tender/utils'
import { useAppDispatch } from 'app/hooks'
import { showNotification } from 'slices/notification'
import { closeModal } from 'slices/modal'
import { addItemToCart } from 'slices/order'
import { Button, Input, TextBody, TextHeading } from 'components'
import {
  MenuItemGroup,
  MenuItemGroupCounts,
  MenuItemGroupHeader,
  MenuItemGroupName,
  MenuItemGroups,
  MenuItemHeader,
  MenuItemIncomplete,
  MenuItemInput
} from './MenuItem.styled'
import MenuItemOption, {
  MenuItemOptionName,
  MenuItemOptionPrice,
  MenuItemOptionQuantity,
  MenuItemOptionView
} from './MenuItemOption'
import MenuItemQuantity from './MenuItemQuantity'
import MenuItemGroupWarning, { MenuItemWarningBelow } from './MenuItemWarning'
import { formatDollars } from 'utils'

interface MenuItemClickModalProps {
  args: {
    orderItem: CartItem
  }
  modalWindow: ModalWindow
}
const MenuItem = ({ args, modalWindow }: MenuItemClickModalProps) => {
  const { orderItem } = args
  const dispatch = useAppDispatch()
  const builder = useBuilder(orderItem)
  const {
    item: builtItem,
    increment,
    decrement,
    setQuantity,
    setMadeFor,
    setNotes,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity
  } = builder
  const [, levels] = makeModifierGroups(builtItem.groups)
  const {
    name,
    description,
    price,
    quantity,
    displayPrice,
    nonSizeGroups,
    isIncomplete,
    sizeSelected,
    notes,
    madeFor
  } = useMenuItem(builtItem, null, {}, null, undefined)

  const incompleteMessage = !quantity
    ? null
    : sizeSelected
      ? 'Certain groups require selections before you can add this item to your order.'
      : 'Please choose a size above before you can add this item to your order.'

  const addItem = (builtItem: CartItem) => {
    const cartItem = { ...builtItem }
    if (cartItem.index === -1) delete cartItem.index
    dispatch(addItemToCart(cartItem))
    dispatch(showNotification(`${cartItem.name} added to cart`))
    dispatch(closeModal())
  }

  const onChangeMadeFor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMadeFor(event.target.value)
  }

  const onChangetNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value)
  }

  return (
    <>
      <MenuItemHeader>
        <TextHeading size="xxxlarge">{name}</TextHeading>
        {description ? <TextBody size="small">{description}</TextBody> : null}
      </MenuItemHeader>
      <MenuItemGroups>
        {nonSizeGroups?.map(group => (
          <MenuItemGroup key={group.id}>
            <MenuItemGroupHeader>
              <MenuItemGroupName size="large">{group.name}</MenuItemGroupName>
              <MenuItemGroupCounts>
                <MenuItemGroupWarning {...group} />
                <TextBody size="xsmall">
                  {group.min} min, {group?.max} max, {group.included} included
                </TextBody>
              </MenuItemGroupCounts>
            </MenuItemGroupHeader>
            {group.options?.map((option, index) => (
              <MenuItemOption
                key={option.id}
                group={group}
                option={option}
                levels={levels}
                toggleOption={toggleOption}
                incrementOption={incrementOption}
                decrementOption={decrementOption}
                setOptionQuantity={setOptionQuantity}
              />
            ))}
          </MenuItemGroup>
        ))}
      </MenuItemGroups>
      <MenuItemGroup>
        <MenuItemInput>
          <Input
            type="text"
            name="Made For"
            label="Made For"
            value={madeFor || ''}
            onChange={onChangeMadeFor}
            placeholder="Who is this order for?"
          />
        </MenuItemInput>
        <MenuItemInput>
          <Input
            type="text"
            name="Notes"
            label="Notes"
            value={notes || ''}
            onChange={onChangetNotes}
            placeholder="Any other notes?"
          />
        </MenuItemInput>
      </MenuItemGroup>
      {isIncomplete && (
        <MenuItemIncomplete>
          <MenuItemWarningBelow>{incompleteMessage}</MenuItemWarningBelow>
        </MenuItemIncomplete>
      )}
      <MenuItemOptionView>
        <MenuItemOptionName>
          <Button
            color="primary"
            disabled={isIncomplete}
            onClick={() => addItem(builtItem)}
          >
            Add to Cart
          </Button>
        </MenuItemOptionName>
        <MenuItemOptionPrice size="small">
          {formatDollars(price.toString())}
        </MenuItemOptionPrice>
        <MenuItemOptionQuantity>
          <MenuItemQuantity
            quantity={quantity}
            adjust={setQuantity}
            increaseQuantity={increment}
            decreaseQuantity={decrement}
          />
        </MenuItemOptionQuantity>
        <MenuItemOptionPrice size="small">{displayPrice}</MenuItemOptionPrice>
      </MenuItemOptionView>
    </>
  )
}

export default MenuItem
