import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BulkOrders, BackgroundTask } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface BulkOrdersState {
  data: BulkOrders
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface BulkOrdersAPI {
  data: BulkOrders
  links: {
    next: string
  }
}

export type BulkOrdersParams = {
  channel_type?: string
  cursor?: string
  device_type?: string
  end_date?: string
  exclude_in_store_orders?: boolean
  expand?: string
  item_id?: number[]
  limit?: number
  order_type?: string
  parent_receipt_id?: string
  receipt_status?: string
  receipt_type?: string
  replica?: boolean
  revenue_center_id?: string
  tender_type?: string
  tender_status?: string
  service_type?: string
  'sort-by'?: string
  'sort-direction'?: string
  start_date?: string
  store_id?: string
  updated_after?: string
  with_related?: string
}

const initialState: BulkOrdersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface OrdersCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export const fetchBulkOrders = createAsyncThunk<
  BulkOrdersAPI,
  BulkOrdersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrders/fetchBulkOrders',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `bulk-orders${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as BulkOrdersAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createBulkOrdersCSV = createAsyncThunk<
  OrdersCSVAPI,
  BulkOrdersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'bulkOrders/createBulkOrdersCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `bulk-orders/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as OrdersCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const BulkOrdersSlice = createSlice({
  name: 'bulkOrders',
  initialState,
  reducers: {
    resetBulkOrders: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBulkOrders.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBulkOrders.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBulkOrders.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBulkOrders } = BulkOrdersSlice.actions

export const selectBulkOrders = (state: RootState) => state.bulkOrders

export default BulkOrdersSlice.reducer
