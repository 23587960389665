import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { DeliverySettings, StoreSettingsFrom } from 'types'

export interface StoreDeliveryState {
  data: DeliverySettings | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError | null
}

export interface StoreDeliveryApi {
  data: DeliverySettings
  from: StoreSettingsFrom
}

const initialState: StoreDeliveryState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreDelivery = createAsyncThunk<
  StoreDeliveryApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeDelivery/fetchStoreDelivery',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/delivery`
      )) as StoreDeliveryApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreDelivery = createAsyncThunk<
  StoreDeliveryApi,
  { storeId: string | number; data: DeliverySettings },
  { state: RootState; rejectValue: RequestError }
>(
  'storeDelivery/upsertStoreDelivery',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/delivery`,
        'PUT',
        data
      )) as StoreDeliveryApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreDelivery = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeDelivery/deleteStoreDelivery',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request<void>(`stores/${storeId}/settings/delivery`, 'DELETE')
      dispatch(showNotification('Successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeDeliverySlice = createSlice({
  name: 'storeDelivery',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreDelivery.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload?.data || null
      state.from = payload?.from || null
      state.error = null
    })
    builder.addCase(fetchStoreDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreDelivery.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.from = payload.from
      state.error = null
    })
    builder.addCase(upsertStoreDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreDelivery.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(deleteStoreDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStoreDelivery = (state: RootState) => state.storeDelivery

export default storeDeliverySlice.reducer
