import styled from '@emotion/styled'
import { HalfMoon, SunLight } from 'iconoir-react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectColorScheme, setColorScheme } from 'slices/global'

export const ToggleColorSchemeView = styled.button`
  position: relative;
  display: block;
  width: 4.8rem;
  height: 2.8rem;
  border-radius: 2.8rem;
  background-color: ${props =>
    props.theme.colors.sidebar.colorSchemeBackground};
`

interface ToggleColorSchemeTickProps {
  translateX: string
}

export const ToggleColorSchemeTick = styled.div<ToggleColorSchemeTickProps>`
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  width: 2.2rem;
  height: 2.2rem;
  padding: 0.2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.text.primary};
  transition: all 0.2s cubic-bezier(0.175, 0.685, 0.32, 1);
  transform: translate3D(${props => props.translateX}, 0, 0);
`

const ToggleColorScheme = () => {
  const dispatch = useAppDispatch()
  const colorScheme = useAppSelector(selectColorScheme)

  const toggleColorScheme = () => {
    const newScheme = colorScheme === 'DARK' ? 'LIGHT' : 'DARK'
    dispatch(setColorScheme(newScheme))
  }

  return (
    <ToggleColorSchemeView onClick={toggleColorScheme}>
      <ToggleColorSchemeTick translateX={colorScheme === 'DARK' ? '0' : '2rem'}>
        {colorScheme === 'DARK' ? <HalfMoon /> : <SunLight />}
      </ToggleColorSchemeTick>
    </ToggleColorSchemeView>
  )
}

export default ToggleColorScheme
