import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export type ModalAlignment = 'bottom' | 'top' | 'center'

export type ModalState = {
  type: string | null
  align?: ModalAlignment
  loading?: boolean
  disableClose?: boolean
  args?: Record<string, unknown> | null
}

const initialState: ModalState = {
  type: null,
  align: 'center',
  loading: false,
  disableClose: false,
  args: null
}

export const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      document.body.classList.add('has-modal')
      const { type, align, loading, disableClose, args } = payload
      state.type = type
      state.align = align || 'center'
      state.loading = loading || false
      state.disableClose = disableClose || false
      state.args = args || null
    },
    closeModal: state => {
      document.body.classList.remove('has-modal')
      state.type = initialState.type
      state.align = initialState.align
      state.loading = initialState.loading
      state.disableClose = initialState.disableClose
      state.args = initialState.args
    }
  }
})

export const { openModal, closeModal } = modal.actions

export const selectModal = (state: RootState) => state.modal

export default modal.reducer
