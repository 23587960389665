import styled from '@emotion/styled'
import { ThemeColor, ThemeFontSizes } from 'types'

interface ButtonLinkViewProps {
  color: keyof ThemeColor
  size: keyof ThemeFontSizes
}

const ButtonLinkView = styled.button<ButtonLinkViewProps>`
  cursor: pointer;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.sizes[props.size]};
  padding: 0.2rem 0 0.1rem;
  border-bottom: 0.05rem solid ${props => props.theme.colors.text.primary};

  &:enabled:hover,
  &:enabled:active {
    color: ${props => props.theme.colors.accent.primary};
    border-color: ${props => props.theme.colors.accent.primary};
  }

  &:disabled {
    opacity: 0.5;
  }

  table & {
    padding: 0;
    border: 0;
  }
`

interface ButtonLinkProps extends React.ComponentProps<'button'> {
  color?: keyof ThemeColor
  size?: keyof ThemeFontSizes
}

const ButtonLink = ({
  onClick,
  children,
  color = 'primary',
  size = 'medium',
  disabled = false,
  style
}: ButtonLinkProps) => {
  return (
    <ButtonLinkView
      onClick={onClick}
      color={color}
      size={size}
      disabled={disabled}
      style={style}
    >
      {children}
    </ButtonLinkView>
  )
}

export default ButtonLink
