import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { UserExperienceSettings } from 'types'

export interface UserExperienceSettingsState {
  data: UserExperienceSettings | null
  loading: RequestStatus
  error: RequestError
}

const initialState: UserExperienceSettingsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchUserExperienceSettings = createAsyncThunk<
  UserExperienceSettings,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'userExperienceSettings/fetchUserExperienceSettings',
  async (_, { getState, rejectWithValue }) => {
    try {
      const endpoint = `general-settings`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as UserExperienceSettings
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateUserExperienceSettings = createAsyncThunk<
  UserExperienceSettings,
  UserExperienceSettings,
  { state: RootState; rejectValue: RequestError }
>(
  'userExperienceSettings/updateUserExperienceSettings',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const endpoint = `general-settings`
      const api = getState().authUser.api
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as UserExperienceSettings
      dispatch(
        showNotification('User experience settings successfully updated!')
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const userExperienceSettingsSlice = createSlice({
  name: 'userExperienceSettings',
  initialState,
  reducers: {
    resetUserExperienceSettings: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUserExperienceSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchUserExperienceSettings.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchUserExperienceSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateUserExperienceSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateUserExperienceSettings.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      updateUserExperienceSettings.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetUserExperienceSettings } =
  userExperienceSettingsSlice.actions

export const selectUserExperienceSettings = (state: RootState) =>
  state.userExperienceSettings

export default userExperienceSettingsSlice.reducer
