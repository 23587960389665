import { useState, useEffect, useMemo } from 'react'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { useAppDispatch } from 'app/hooks'
import { makeLocalDateStr } from 'utils'
import { AllReportsFilters, AllReportsFiltersUnion } from 'types'

interface UseReportProps<T> {
  resetReport: ActionCreatorWithoutPayload<string>
  handleFetchReport: (params: AllReportsFilters) => void
  initialFilters?: T
}

const useReport = <T extends AllReportsFiltersUnion>({
  handleFetchReport,
  resetReport
}: UseReportProps<T>) => {
  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const dispatch = useAppDispatch()

  const currentDate = makeLocalDateStr()
  const futureDate = makeLocalDateStr(30)
  const initialFilters = useMemo(
    () => ({
      start_date: currentDate,
      end_date: futureDate
    }),
    [currentDate, futureDate]
  )

  const [filters, setFilters] = useState(initialFilters)

  useEffect(() => {
    handleFetchReport(initialFilters)
  }, [handleFetchReport, initialFilters])

  const reload = () => {
    handleFetchReport(filters)
    setIsFilterChanged(false)
  }

  const clearAll = () => {
    if (filters) {
      setFilters(initialFilters)
      setIsFilterChanged(false)
      handleFetchReport(initialFilters)
    }
  }

  const setDates = (startDate: string, endDate: string) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      start_date: startDate,
      end_date: endDate
    }))
    setIsFilterChanged(true)
  }

  const handleFilterChange = (
    propertyName: string,
    value: string | number | undefined
  ) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [propertyName]: value
    }))
    setIsFilterChanged(true)
  }

  useEffect(() => {
    resetReport()
  }, [dispatch, resetReport])

  return {
    filters,
    isFilterChanged,
    initialFilters,
    reload,
    clearAll,
    setDates,
    handleFilterChange
  }
}

export default useReport
