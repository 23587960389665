import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { KdsTerminals } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface KdsTerminalsState {
  data: KdsTerminals
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface KdsTerminalsAPI {
  data: KdsTerminals
  links: {
    next: string
  }
}

const initialState: KdsTerminalsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type KdsTerminalsParams = {
  cursor?: string
  is_active?: boolean
  limit?: number
  revenue_center_id?: number
  search?: string
  with_related?: string
}

export const fetchKdsTerminals = createAsyncThunk<
  KdsTerminalsAPI,
  KdsTerminalsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminals/fetchKdsTerminals',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `pos-v2/kds-terminals${queryParams}`
      return (await api?.request(endpoint)) as KdsTerminalsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const kdsTerminalsSlice = createSlice({
  name: 'kdsTerminals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchKdsTerminals.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchKdsTerminals.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchKdsTerminals.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectKdsTerminals = (state: RootState) => state.kdsTerminals

export default kdsTerminalsSlice.reducer
