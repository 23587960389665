import styled from '@emotion/styled'
import { Content } from 'components'
import { Link } from 'react-router-dom'

const AccessErrorView = styled.div`
  p {
    margin: 0 0 2rem;
  }
`

const AccessError = () => {
  return (
    <Content
      title="Invalid Access"
      subtitle="Sorry, but your permissions do not currently include access to this page."
      maxWidth="128rem"
    >
      <AccessErrorView>
        <p>
          Please your brand's system administrator if you think this is a
          mistake.
        </p>
        <p>
          <Link to="/">Head back to the homepage.</Link>
        </p>
      </AccessErrorView>
    </Content>
  )
}

export default AccessError
