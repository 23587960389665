import styled from '@emotion/styled'

export const CheckboxListView = styled.div`
  margin: ${props => props.theme.layout.desktop.margin} 0;

  label {
    margin: 0 0 2rem;
  }
`

const CheckboxList = ({ children }: { children: React.ReactNode }) => {
  return <CheckboxListView>{children}</CheckboxListView>
}

export default CheckboxList
