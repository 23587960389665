import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const AuthFormView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  label span {
    color: ${props => props.theme.landing.text.secondary};
    font-weight: 700;
  }

  input {
    border-radius: 0.8rem;
    color: ${props => props.theme.landing.text.secondary};
    border-color: ${props => props.theme.landing.border.primary};
    background-color: ${props => props.theme.landing.background.secondary};

    &:active,
    &:focus {
      color: ${props => props.theme.landing.text.primary};
      border-color: ${props => props.theme.landing.text.primary};
      background-color: ${props => props.theme.landing.background.secondary};
    }

    &::selection {
      background-color: lightblue;
    }
  }

  button {
    color: ${props => props.theme.landing.background.primary};
    background-color: ${props => props.theme.landing.text.primary};
    border-color: ${props => props.theme.landing.text.primary};

    // &:enabled:hover,
    // &:enabled:active,
    // &:enabled:focus {
    //   filter: brightness(100%);
    //   opacity: 0.75;
    // }
  }
`

export const AuthFormHeader = styled.div`
  margin: 0 0 4rem;
`

export const AuthFormTitle = styled(TextHeading)`
  margin-left: -0.6rem;
  line-height: 0.9;
  color: ${props => props.theme.landing.text.primary};
  font-size: ${props => props.theme.sizes.tera};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: ${props => props.theme.sizes.mega};
  }
`

export const AuthFormSubtitle = styled(TextBody)`
  margin: 2rem 0 0;
  color: ${props => props.theme.landing.text.primary};
`

export const AuthFormContent = styled.div`
  width: 100%;
  max-width: 40rem;

  button[type='submit'] {
    width: 100%;
    height: 4.2rem;
    border-radius: 0.8rem;
    margin: 0.5rem 0 0;

    border-radius: 0.8rem;
    color: #ffffff;
    background-color: ${props => props.theme.landing.accent.primary};
    border-color: ${props => props.theme.landing.accent.primary};

    &:enabled:hover,
    &:enabled:active,
    &:enabled:focus {
      color: #f0f9ff;
      background-color: ${props => props.theme.landing.accent.secondary};
      border-color: ${props => props.theme.landing.accent.secondary};
    }
  }
`

export const AuthFormFooter = styled.div`
  margin: 4rem 0 0;

  p {
    color: ${props => props.theme.landing.text.primary};
  }

  p a {
    color: ${props => props.theme.landing.accent.primary};
    padding: 0;
    border: 0;
  }

  p a:hover {
    color: ${props => props.theme.landing.accent.secondary};
  }
`
