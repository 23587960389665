import styled from '@emotion/styled'

export const IacmStatusView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
`

export const IacmRadioView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const IacmRadioLabel = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
`

export const IacmRadioInput = styled.input`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
`

export const IacmRadio = styled.span`
  content: '';
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 100%;
  border-width: 0.1rem;
  border-style: solid;
  transition: all 0.15s ease;
  background-color: ${props => props.theme.colors.background.primary};
  border-color: ${props => props.theme.colors.border.primary};

  input:focus + &,
  input:checked + & {
    border-color: ${props => props.theme.colors.text.primary};
  }

  input:checked + &:before {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.text.primary};
  }
`
