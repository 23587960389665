import { useCallback, useEffect, useState } from 'react'
import { DeliveryFee, DeliveryFees } from 'types'
import { Button, ErrMsg, Label } from 'components'
import {
  DeliveryFeeSubsidiesView,
  DeliveryFeeSubsidyFooter,
  DeliveryFeeSubsidyHeader,
  DeliveryFeeSubsidySpacer,
  DeliveryFeeSubsidySubtitle,
  DeliveryFeeSubsidyTitle,
  DeliveryFeeSubsidyView
} from './DeliveryFeeSubsidies.styled'
import DeliveryFeeSubsidy from './DeliveryFeeSubsidy'

const DeliveryFeeSubsidies = ({
  deliveryFees,
  setDeliveryFees,
  errMsg
}: {
  deliveryFees: DeliveryFees
  setDeliveryFees: (fees: DeliveryFees) => void
  errMsg?: string
}) => {
  const [fees, setFees] = useState<DeliveryFees>(deliveryFees)

  const add = () => {
    setFees([...fees, { fee: '', minimum: '', maximum: '' }])
  }

  const remove = (evt: React.MouseEvent<HTMLButtonElement>, index: number) => {
    evt.preventDefault()
    const updated = fees.filter((i, idx) => idx !== index)
    setFees(updated)
    setDeliveryFees(updated)
  }

  const update = useCallback(
    (index: number, fee: DeliveryFee) => {
      const updated = fees.map((i, idx) => (idx === index ? fee : i))
      setFees(updated)
      setDeliveryFees(updated)
    },
    [fees, setDeliveryFees]
  )

  useEffect(() => {
    setFees(deliveryFees)
  }, [deliveryFees])

  return (
    <DeliveryFeeSubsidiesView>
      <DeliveryFeeSubsidyHeader>
        <DeliveryFeeSubsidyTitle as="p" size="large">
          Delivery Fee Subsidies
        </DeliveryFeeSubsidyTitle>
        <DeliveryFeeSubsidySubtitle as="p" size="small">
          Choose a range of fees from the delivery provider and the resulting
          fee paid by the customer.
        </DeliveryFeeSubsidySubtitle>
        <ErrMsg errMsg={errMsg} />
      </DeliveryFeeSubsidyHeader>
      {fees.length > 0 && (
        <DeliveryFeeSubsidyView>
          <Label label="Minimum" />
          <Label label="Maximum" />
          <Label label="Customer Fee" />
          <DeliveryFeeSubsidySpacer />
        </DeliveryFeeSubsidyView>
      )}
      {fees.map((fee, index) => (
        <DeliveryFeeSubsidy
          key={index}
          index={index}
          deliveryFee={fee}
          update={update}
          remove={remove}
        />
      ))}
      <DeliveryFeeSubsidyFooter>
        <Button onClick={add} color="secondary" size="small">
          Add Subsidy
        </Button>
      </DeliveryFeeSubsidyFooter>
    </DeliveryFeeSubsidiesView>
  )
}

export default DeliveryFeeSubsidies
