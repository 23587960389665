import styled from '@emotion/styled'

const InputsSmall = styled.div`
  label {
    margin: 0 0 2rem;
  }

  input,
  select {
    padding: 0.8rem 1rem;
    font-size: ${props => props.theme.sizes.small};
  }

  select + span {
    width: 3.6rem;
  }

  input[type='checkbox'] ~ span {
    font-size: ${props => props.theme.sizes.small};
  }
`

export default InputsSmall
