import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { ModifierGroup, Items, CategoryItemPosition } from 'types'
import { showNotification } from 'slices/notification'
import { fetchMenu } from './menu'

export interface ModifierGroupState {
  data: ModifierGroup | null
  loading: RequestStatus
  error: RequestError
}

const initialState: ModifierGroupState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type ModifierGroupParams = {
  expand?: string
  with_related?: string
}

export const fetchModifierGroup = createAsyncThunk<
  ModifierGroup | null,
  { modifierGroupId: string | number; params?: ModifierGroupParams },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/fetchModifierGroup',
  async ({ modifierGroupId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `modifier-groups/${modifierGroupId}${queryParams}`
      return (await api?.request(endpoint)) as ModifierGroup
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createModifierGroup = createAsyncThunk<
  ModifierGroup,
  { menuId: string | number; data: ModifierGroup; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/createModifierGroup',
  async (
    { menuId, data, navigate },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'modifier-groups',
        'POST',
        data
      )) as ModifierGroup
      const modifierGroupId = resp.modifier_group_id
      const menuData = [{ menu_id: parseInt(menuId.toString()) }]
      await api?.request(
        `modifier-groups/${modifierGroupId}/menus`,
        'POST',
        menuData
      )
      dispatch(showNotification('Modifier group successfully created!'))
      dispatch(
        fetchMenu({
          menuId,
          params: { with_related: 'categories,modifier_groups' }
        })
      )
      const pathname = `/menus/menus/${menuId}/modifier-groups/${modifierGroupId}/settings`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertModifierGroup = createAsyncThunk<
  ModifierGroup,
  {
    menuId: string | number
    modifierGroupId: string | number
    data: ModifierGroup
  },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/upsertModifierGroup',
  async (
    { menuId, modifierGroupId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      await api?.request(`modifier-groups/${modifierGroupId}`, 'PUT', data)
      dispatch(showNotification('Modifier group successfully updated!'))
      dispatch(
        fetchMenu({
          menuId,
          params: { with_related: 'categories,modifier_groups' }
        })
      )
      return (await api?.request(
        `modifier-groups/${modifierGroupId}?with_related=items,files`
      )) as ModifierGroup
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const reorderModifierGroupItems = createAsyncThunk<
  void,
  { modifierGroupId: number | string; items: CategoryItemPosition[] },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/reorderModifierGroupsItems',
  async (
    { items, modifierGroupId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `modifier-groups/${modifierGroupId}/items`
      await api?.request(endpoint, 'PUT', items)
      dispatch(showNotification('Modifier items reordered!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const modifierGroupSlice = createSlice({
  name: 'modifierGroup',
  initialState,
  reducers: {
    resetModifierGroup: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchModifierGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchModifierGroup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchModifierGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createModifierGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createModifierGroup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createModifierGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertModifierGroup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertModifierGroup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(upsertModifierGroup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(reorderModifierGroupItems.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(reorderModifierGroupItems.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      reorderModifierGroupItems.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetModifierGroup } = modifierGroupSlice.actions

export const selectModifierGroup = (state: RootState) => state.modifierGroup
export const selectModifierGroupItems = (state: RootState) => {
  const { data, loading, error } = state.modifierGroup
  if (!data?.items) {
    return { items: [], loading, error }
  }
  const items = data.items.reduce((arr, modifierGroupItem) => {
    const { item, menu_position } = modifierGroupItem
    return [...arr, { ...item, menu_position }]
  }, [] as Items)

  return { items, loading, error }
}

export default modifierGroupSlice.reducer
