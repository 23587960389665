import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { StorePosSettings, StoreSettingsFrom } from 'types'

export interface StorePosSettingsState {
  data: StorePosSettings | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError | null
}

export interface StorePosSettingsApi {
  data: StorePosSettings
  from: StoreSettingsFrom
}

const initialState: StorePosSettingsState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStorePosSettings = createAsyncThunk<
  StorePosSettingsApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storePosSettings/fetchStorePosSettings',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/pos`
      )) as StorePosSettingsApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStorePosSettings = createAsyncThunk<
  StorePosSettingsApi,
  { storeId: string | number; data: StorePosSettings },
  { state: RootState; rejectValue: RequestError }
>(
  'storePosSettings/upsertStorePosSettings',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/pos`,
        'PUT',
        data
      )) as StorePosSettingsApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStorePosSettings = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storePosSettings/deleteStorePosSettings',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request<void>(`stores/${storeId}/settings/pos`, 'DELETE')
      dispatch(showNotification('Successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storePosSettingsSlice = createSlice({
  name: 'storePosSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStorePosSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStorePosSettings.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload?.data || null
      state.from = payload?.from || null
      state.error = null
    })
    builder.addCase(fetchStorePosSettings.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStorePosSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStorePosSettings.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.from = payload.from
      state.error = null
    })
    builder.addCase(upsertStorePosSettings.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStorePosSettings.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStorePosSettings.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(deleteStorePosSettings.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStorePosSettings = (state: RootState) =>
  state.storePosSettings

export default storePosSettingsSlice.reducer
