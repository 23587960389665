import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Tags } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface TagsState {
  data: Tags
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface TagsAPI {
  data: Tags
  links: {
    next: string
  }
}

export type TagsParams = {
  cursor?: string
  limit?: number
  with_related?: string
}

const initialState: TagsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchTags = createAsyncThunk<
  TagsAPI,
  TagsParams | void,
  { state: RootState; rejectValue: RequestError }
>('tags/fetchTags', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `tags${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as TagsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    resetTags: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTags.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchTags.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTags } = tagsSlice.actions

export const selectTags = (state: RootState) => state.tags

export default tagsSlice.reducer
