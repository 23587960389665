import { ExternalApiRequests, PosStatus, ReceiptStatus } from 'types'
import { sortBy } from './helpers'

const makeNoRequestsStatus = (receipt_status: ReceiptStatus): PosStatus => {
  switch (receipt_status) {
    case 'CLOSED':
      return 'UNKNOWN'
    case 'VOID':
      return 'NOT SENT'
    default:
      return 'QUEUED'
  }
}

export const getCheckmateOrderStatus = (
  receipt_status: ReceiptStatus,
  ext_api_requests?: ExternalApiRequests
): PosStatus => {
  if (!ext_api_requests || !ext_api_requests.length) {
    return makeNoRequestsStatus(receipt_status)
  }
  const extApiRequests = ext_api_requests?.filter(
    i => i.external_api === 'CHECKMATE_TOOS'
  )
  if (!extApiRequests.length) {
    return makeNoRequestsStatus(receipt_status)
  }
  const hasSuccess = extApiRequests.filter(i => i.is_success).length > 0
  const lastRequest = sortBy(extApiRequests, 'ext_api_request_id', 'desc')[0]
  return lastRequest.is_error
    ? hasSuccess
      ? 'POS FAILED'
      : 'FAILED'
    : lastRequest.is_success
      ? 'SUCCESS'
      : 'UNKNOWN'
}

export const getCheckmateOrderStatusColor = (checkmateStatus: PosStatus) => {
  switch (checkmateStatus) {
    case 'FAILED':
    case 'POS FAILED':
    case 'UNKNOWN':
      return 'default'
    case 'SUCCESS':
      return 'success'
    case 'NOT SENT':
      return 'default'
    default:
      return 'info'
  }
}
