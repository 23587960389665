import styled from '@emotion/styled'

export const IacmCSVExportView = styled.div<{ isLast: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => (props.isLast ? '0' : '3rem')};
  gap: 0.75rem;
`

export const IacmCSVExportButton = styled.button`
  padding: 0;
  margin: 0 !important;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.sizes.small};

  &:enabled:hover {
    color: ${props => props.theme.colors.accent.primary};
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const IacmCSVExportSeparator = styled.span`
  display: block;
  color: ${props => props.theme.colors.text.primary};
`
