import styled from '@emotion/styled'

export const FormView = styled.form`
  max-width: ${props => props.theme.layout.desktop.pageWidth};
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
`

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button:not(:first-of-type) {
    margin-left: 1rem;
  }
`
