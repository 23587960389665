import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import {
  AppVariable,
  AppVariables,
  AppVariablesElement,
  AppVariablesLookup,
  AppVariablesPage,
  AppVariablesPages,
  AppVariablesSection,
  AppVariablesSections
} from 'types'
import { showNotification } from 'slices/notification'

export interface AppVariablesState {
  data: AppVariablesSections | null
  lookup: AppVariablesLookup | null
  loading: RequestStatus
  error: RequestError
}

export interface AppVariablesAPI {
  data: AppVariablesPages
  links: {
    next: string | null
    prev?: string
  }
}

const makeVariableLookup = (data: AppVariablesSections) => {
  if (!data) return null
  const flatVariables = Object.values(data).reduce(
    (arr, section: AppVariablesSection) => {
      const variables = section.elements.reduce(
        (varArr, element: AppVariablesElement) => {
          return [...varArr, ...element.variables]
        },
        [] as AppVariables
      )
      return [...arr, ...variables]
    },
    [] as AppVariables
  )
  return flatVariables.reduce(
    (obj: AppVariablesLookup, variable: AppVariable) => {
      return { ...obj, [variable.className]: variable.values }
    },
    {}
  )
}

const initialState: AppVariablesState = {
  data: null,
  lookup: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppVariables = createAsyncThunk<
  AppVariablesPage | null,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'appVariables/fetchAppVariables',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const params = `?page_type=theme&is_latest=true`
      const { data } = (await api?.request(
        `order-app-pages${params}`
      )) as AppVariablesAPI
      return data.find(i => i.is_latest) || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertAppVariables = createAsyncThunk<
  AppVariablesPage,
  { data: AppVariablesSections },
  { state: RootState; rejectValue: RequestError }
>(
  'appVariables/upsertAppVariables',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(`order-app-pages/theme`, 'POST', {
        page_content: [data]
      })) as AppVariablesPage
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const AppVariablesSlice = createSlice({
  name: 'appVariables',
  initialState,
  reducers: {
    resetAppVariables: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAppVariables.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppVariables.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      const data = payload?.page_content[0] || null
      state.data = data
      state.lookup = data ? makeVariableLookup(data) : null
    })
    builder.addCase(fetchAppVariables.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertAppVariables.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertAppVariables.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      const data = payload.page_content[0]
      state.data = data
      state.lookup = data ? makeVariableLookup(data) : null
    })
    builder.addCase(upsertAppVariables.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAppVariables } = AppVariablesSlice.actions

export const selectAppVariables = (state: RootState) => state.appVariables

export const selectAppVariablesData = (state: RootState) =>
  state.appVariables.data

export const selectAppVariablesColors = (state: RootState) => {
  return state.appVariables.data ? state.appVariables.data.colors : null
}

export const selectAppVariablesSizes = (state: RootState) => {
  return state.appVariables.data ? state.appVariables.data.sizes : null
}

export const selectAppVariablesLookup = (state: RootState) =>
  state.appVariables.lookup

export default AppVariablesSlice.reducer
