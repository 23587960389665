import styled from '@emotion/styled'
import { useAppSelector } from 'app/hooks'
import { selectNotifications } from 'slices/notification'
import Transition from 'components/Transition'
import Notification from './Notification'

const NotificationsView = styled('ul')`
  position: fixed;
  z-index: 501;
  right: 3rem;
  bottom: 3rem;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    right: 1.5rem;
    bottom: 1.5rem;
  }
`

const Notifications = () => {
  const messages = useAppSelector(selectNotifications)

  return (
    <NotificationsView>
      <Transition
        showTransition={true}
        classNames="flash"
        timeout={500}
        transitionElements={messages.map(message => (
          <Notification key={message.id} {...message} />
        ))}
      />
    </NotificationsView>
  )
}

export default Notifications
