import { useAppDispatch } from 'app/hooks'
import { useCallback } from 'react'
import { openModal } from 'slices/modal'
import { Location } from 'types'
import { fetchCorporateOnlyOnboardingBrands } from 'slices/iacm/onboardingBrandsCorporateOnly'
import { fetchExternalUsers } from 'slices/iacm/externalUsers'

const useOnboardingLocationOptions = (fetchLocations: () => void) => {
  const dispatch = useAppDispatch()

  const archiveLocation = useCallback(
    (location: Location) => {
      const args = { location, fetchLocations }
      dispatch(openModal({ type: 'archiveLocation', align: 'center', args }))
    },
    [dispatch, fetchLocations]
  )

  const editLocation = useCallback(
    (location: Location) => {
      const args = { location, fetchLocations }
      dispatch(fetchCorporateOnlyOnboardingBrands({ page: 1, perPage: 25 }))
      dispatch(
        fetchExternalUsers({
          page: 1,
          perPage: 100,
          filters: [
            {
              category: 'role',
              operator: 'equal',
              value: 'onboarding_specialist',
              AndOr: ''
            }
          ]
        })
      )
      dispatch(openModal({ type: 'editLocation', align: 'center', args }))
    },
    [dispatch, fetchLocations]
  )

  return {
    archiveLocation,
    editLocation
  }
}

export default useOnboardingLocationOptions
