import styled from '@emotion/styled'

export const TableRefreshView = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-left: 0.5rem;
    font-size: ${props => props.theme.sizes.small};
  }
`

export const TableRefreshError = styled.span`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.error.background};
`

export const TableRefreshIcon = styled.span`
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TableResetFilters = styled.span`
  display: block;
  padding: 0 0 0.1rem 0.7rem;
`

export const TableRefreshPager = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: 0.5rem;
  font-size: ${props => props.theme.sizes.small};
  color: ${props => props.theme.colors.text.primary};
`

export const TableRefreshPagerArrows = styled.span`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  button {
    line-height: 0;
    color: ${props => props.theme.colors.text.primary};

    &:enabled:hover {
      color: ${props => props.theme.colors.accent.primary};
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`

export const TableRefreshPerPageView = styled.div`
  position: relative;
`

export const TableRefreshPerPageCurrent = styled.button`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: ${props => props.theme.colors.text.primary};
`

export const TableRefreshPerPageIncrements = styled.span<{ show: boolean }>`
  position: absolute;
  z-index: 1;
  top: calc(100% + 0.5rem);
  left: -1rem;
  right: -1rem;
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.secondary};
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  border-bottom-width: 0;
  transition: all 0.15s ease;
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`

export const TableRefreshPerPageIncrement = styled.button`
  display: block;
  width: 100%;
  padding: 0.6rem 1rem 0.7rem;
  text-align: left;
  color: ${props => props.theme.colors.text.primary};
  border-style: solid;
  border-color: ${props => props.theme.colors.border.primary};
  border-width: 0;
  border-bottom-width: ${props => props.theme.layout.desktop.border};

  &:hover {
    background-color: ${props => props.theme.colors.background.tertiary};
  }
`
