import { useAppSelector } from 'app/hooks'
import { selectAuthUser } from 'slices/authUser'
import { Camera, LightBulb, Lock } from 'iconoir-react'
import IphoneBody from '../IPhone'
import {
  IPhoneLockScreenBottomBar,
  IPhoneLockScreenContainer,
  IPhoneLockScreenDate,
  IPhoneLockScreenLockIconContainer,
  IPhoneLockScreenMessage,
  IPhoneLockScreenBrandLogo,
  IPhoneLockScreenNotification,
  IPhoneLockScreenQuickActionIcon,
  IPhoneLockScreenQuickActionsContainer,
  IPhoneLockScreenTime
} from './IPhoneLockScreen.styled'

interface IPhoneLockScreenProps {
  pushContent: string
}

const IPhoneLockScreen = ({ pushContent }: IPhoneLockScreenProps) => {
  const { icon } = useAppSelector(selectAuthUser)
  return (
    <IphoneBody>
      <IPhoneLockScreenContainer>
        <IPhoneLockScreenLockIconContainer>
          <Lock />
        </IPhoneLockScreenLockIconContainer>
        <IPhoneLockScreenTime>12:00</IPhoneLockScreenTime>
        <IPhoneLockScreenDate>Monday, 12th February</IPhoneLockScreenDate>
        <IPhoneLockScreenNotification>
          <IPhoneLockScreenBrandLogo>
            <img src={icon || undefined} alt="" />
          </IPhoneLockScreenBrandLogo>
          <IPhoneLockScreenMessage
            dangerouslySetInnerHTML={{ __html: pushContent }}
          />
        </IPhoneLockScreenNotification>
        <IPhoneLockScreenQuickActionsContainer>
          <IPhoneLockScreenQuickActionIcon>
            <LightBulb />
          </IPhoneLockScreenQuickActionIcon>
          <IPhoneLockScreenQuickActionIcon>
            <Camera />
          </IPhoneLockScreenQuickActionIcon>
        </IPhoneLockScreenQuickActionsContainer>
        <IPhoneLockScreenBottomBar />
      </IPhoneLockScreenContainer>
    </IphoneBody>
  )
}

export default IPhoneLockScreen
