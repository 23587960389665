import styled from '@emotion/styled'
import DesignInput from './DesignInput'
import DesignLabel from './DesignLabel'

const InputFontSizeView = styled.div`
  position: relative;
  flex: 0 0 7.2rem;
`

const InputFontSize = ({
  label,
  required,
  name,
  value,
  setValue,
  min,
  max,
  step,
  disabled,
  suffix,
  inherited
}: {
  label: string
  name: string
  value: string | number
  setValue: (value: string | number | null) => void
  required?: boolean
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  suffix?: string
  inherited?: boolean
}) => {
  const setInputValue = (value: string | number | null) => {
    const val = value ? parseFloat(value.toString()) : ''
    // const capped = max && val > max ? max : min && val < min ? min : val
    setValue(val)
  }

  return (
    <DesignLabel
      label={label}
      required={required}
      htmlFor={name}
      inherited={inherited}
    >
      <InputFontSizeView>
        <DesignInput
          type="number"
          name={name}
          value={value || ''}
          setValue={setInputValue}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          isFontSize={true}
          suffix={suffix}
          inherited={inherited}
        />
      </InputFontSizeView>
    </DesignLabel>
  )
}

export default InputFontSize
