import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import {
  createRevenueCenterSurcharges,
  fetchRevenueCenterSurcharges,
  selectRevenueCenterSurcharges
} from 'slices/revenueCenterSurcharges'
import { selectSurcharges } from 'slices/surcharges'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'

const RevenueCenterSurcharge = ({
  args,
  modalWindow
}: {
  args: { revenueCenterId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { revenueCenterId } = args
  const { data, loading, error } = useAppSelector(selectRevenueCenterSurcharges)
  const surcharges = useAppSelector(selectSurcharges)
  const current = data.map(i => i.surcharge_id)
  const filtered = surcharges.data.filter(
    i => !current.includes(i.surcharge_id)
  )
  const isLoading =
    loading === RequestStatus.Pending ||
    surcharges.loading === RequestStatus.Pending

  const surchargeOptions = useMemo(() => {
    return filtered.map(i => {
      const amount =
        i.surcharge_type === 'PERCENTAGE' ? `${i.amount}%` : `$${i.amount}`
      return {
        value: i.surcharge_id,
        text: `${i.name} (${amount})`
      }
    })
  }, [filtered])

  const submit = async (values: { surcharge_id: number }) => {
    if (revenueCenterId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(createRevenueCenterSurcharges({ revenueCenterId, data }))
      dispatch(fetchRevenueCenterSurcharges(revenueCenterId))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add New Surcharge"
        subtitle="Choose a surcharge from the options below"
      />
      <Form
        fields={revenueCenterSurchargeFields(surchargeOptions)}
        data={revenueCenterSurchargeEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const revenueCenterSurchargeEmpty = {
  surcharge_id: null
}

const revenueCenterSurchargeFields = (
  surchargeOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'surcharge_id',
    options: surchargeOptions,
    label: 'Surcharge',
    error: 'Please select a surcharge'
  }
]

export default RevenueCenterSurcharge
