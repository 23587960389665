import styled from '@emotion/styled'

interface SwitchViewProps {
  checked: boolean
  disabled: boolean
}

export const SwitchView = styled.label<SwitchViewProps>`
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: block;
  width: 3.6rem;
  height: 2.2rem;
  border-radius: 2.8rem;
  background-color: ${props =>
    props.checked
      ? props.theme.colors.text.primary
      : props.theme.colors.background.tertiary};
`

export const SwitchInput = styled.input`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
`

export const SwitchTick = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.text.primary};
  transition: all 0.2s cubic-bezier(0.175, 0.685, 0.32, 1);
  transform: translate3D(0, 0, 0);

  input:checked + & {
    transform: translate3D(1.4rem, 0, 0);
  }
`
export const SwitchLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const SwitchLabel = styled.div`
  margin-top: 2.6rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`
