import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LoyaltyPrograms } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface LoyaltyProgramsState {
  data: LoyaltyPrograms
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface LoyaltyProgramsAPI {
  data: LoyaltyPrograms
  links: {
    next: string
  }
}

export type LoyaltyProgramsParams = {
  cursor?: string
  limit?: number
  with_related?: string
  is_active?: boolean
}

const initialState: LoyaltyProgramsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchLoyaltyPrograms = createAsyncThunk<
  LoyaltyProgramsAPI,
  LoyaltyProgramsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyPrograms/fetchLoyaltyPrograms',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `loyalty-programs${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as LoyaltyProgramsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyProgramsSlice = createSlice({
  name: 'loyaltyPrograms',
  initialState,
  reducers: {
    resetLoyaltyPrograms: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyPrograms.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyPrograms.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchLoyaltyPrograms.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyPrograms } = loyaltyProgramsSlice.actions

export const selectLoyaltyPrograms = (state: RootState) => state.loyaltyPrograms

export const selectLoyaltiesOptions = createSelector(
  (state: RootState) => state.loyaltyPrograms.data,
  loyalty_programs => {
    return (
      loyalty_programs?.map(({ loyalty_program_id, name }) => ({
        text: name || '',
        value: loyalty_program_id || 0
      })) || ([] as { text: string; value: number }[])
    )
  }
)

export default loyaltyProgramsSlice.reducer
