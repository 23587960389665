import Avatar from './Avatar'
import { AvatarGroupStyled, StyledAvatarContainer } from './Avatar.styled'

interface AvatarGroupProps {
  data: { id: number | string; name: string; image: string }[]
}

const AvatarGroup = ({ data }: AvatarGroupProps) => {
  const moreAvatars = {
    name: `+${data.length - 3}`,
    image: ''
  }
  const firstThreeAvatars = data.slice(0, 3)
  const allAvatars =
    data.length > 3 ? [...firstThreeAvatars, moreAvatars] : data
  return (
    <AvatarGroupStyled>
      {allAvatars.map((item, index) => (
        <StyledAvatarContainer
          zIndex={data.length - index}
          marginLeft={index > 0 ? '-0.5rem' : '0rem'}
        >
          <Avatar
            key={index}
            name={item.name}
            image={item.image}
            size={item.name.includes('+') ? 35 : 25}
            showLabel={false}
            showInitials={!item.name.includes('+')}
          />
        </StyledAvatarContainer>
      ))}
    </AvatarGroupStyled>
  )
}

export default AvatarGroup
