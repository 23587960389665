import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { confirmationEmailType } from 'config'
import { ConfirmationEmails, OrderTypeOld } from 'types'
import { RequestError, RequestStatus, createSortMap } from 'utils'

export interface ConfirmationEmailssAPI {
  data: ConfirmationEmails
  links: {
    next: string
  }
}

export interface ConfirmationEmailssState {
  data: ConfirmationEmails
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: ConfirmationEmailssState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchConfirmationEmails = createAsyncThunk<
  ConfirmationEmailssAPI,
  OrderTypeOld,
  { state: RootState; rejectValue: RequestError }
>(
  'confirmationEmails/fetchConfirmationEmails',
  async (orderType, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `confirmation-emails?order_type=${orderType}`
      return (await api?.request(endpoint)) as ConfirmationEmailssAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const confirmationEmailsSlice = createSlice({
  name: 'confirmationEmails',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchConfirmationEmails.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchConfirmationEmails.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchConfirmationEmails.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectConfirmationEmails = (state: RootState) =>
  state.confirmationEmails

export const selectSortedConfirmationEmails = (state: RootState) => {
  const { data } = state.confirmationEmails
  const emailTypeSortMap = createSortMap(confirmationEmailType)
  return [...data].sort(
    (a, b) => emailTypeSortMap[a.email_type] - emailTypeSortMap[b.email_type]
  )
}

export default confirmationEmailsSlice.reducer
