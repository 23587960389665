import styled from '@emotion/styled'

const TextTagContainer = styled.div<{ readonly: boolean | undefined }>`
  background-color: ${props => props.theme.colors.accent.tertiary};
  border-radius: 2rem;
  margin-right: 0.4rem;
  font-size: ${props => props.theme.sizes.small};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  line-height: ${props => props.theme.inputs.lineHeight};
  padding: 0.5rem 1rem;

  color: ${props => props.theme.colors.text.primary};

  span {
    margin-left: 0.5rem;
  }
`

type TextTagProps = {
  readonly?: boolean
  text: string
  onDelete?: () => void
}

const TextTag = ({ readonly, text, onDelete }: TextTagProps) => {
  return (
    <TextTagContainer readonly={readonly}>
      {text}

      {!readonly && <span onClick={onDelete}>X</span>}
    </TextTagContainer>
  )
}

export default TextTag
