import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { ModifierGroupModifier } from 'types'

export interface ModifierGroupModifiersState {
  data: ModifierGroupModifier[]
  loading: RequestStatus
  error: RequestError
}

const initialState: ModifierGroupModifiersState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchModifierGroupModifiers = createAsyncThunk<
  ModifierGroupModifier[],
  {
    categoryId?: number
    modifierGroupId?: number
    itemId: number
    itemModifierGroupId: number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/fetchModifierGroupModifiers',
  async (
    { categoryId, modifierGroupId, itemId, itemModifierGroupId },
    { getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = modifierGroupId
        ? `modifier-groups/${modifierGroupId}/items/${itemId}/modifier-groups/${itemModifierGroupId}/modifiers`
        : `categories/${categoryId}/items/${itemId}/modifier-groups/${itemModifierGroupId}/modifiers`

      return (await api?.request(endpoint)) as ModifierGroupModifier[]
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateModifierGroupModifiers = createAsyncThunk<
  ModifierGroupModifier[],
  {
    categoryId?: number
    modifierGroupId?: number
    itemId: number
    itemModifierGroupId: number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/updateModifierGroupModifiers',
  async (
    { categoryId, modifierGroupId, itemId, itemModifierGroupId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const api = getState().authUser.api
      const modifiers = getState().modifierGroupModifiers
      const endpoint = modifierGroupId
        ? `modifier-groups/${modifierGroupId}/items/${itemId}/modifier-groups/${itemModifierGroupId}/modifiers`
        : `categories/${categoryId}/items/${itemId}/modifier-groups/${itemModifierGroupId}/modifiers`

      const newModifiers = (await api?.request(
        endpoint,
        'PUT',
        modifiers.data
      )) as ModifierGroupModifier[]

      dispatch(showNotification('Updated Successfully!'))
      return newModifiers
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const modifierGroupModifiersSlice = createSlice({
  name: 'modifierGroupModifiers',
  initialState,
  reducers: {
    resetModifierGroupModifiers: () => initialState,
    addModifier: (
      state: ModifierGroupModifiersState,
      { payload }: { payload: ModifierGroupModifier }
    ) => {
      return {
        ...state,
        data: [payload, ...state.data]
      }
    },
    deleteModifier: (
      state: ModifierGroupModifiersState,
      { payload }: { payload: ModifierGroupModifier }
    ) => {
      const newModifiers = state.data.filter(
        mod => mod.item_id !== payload.item_id
      )
      return {
        ...state,
        data: newModifiers
      }
    },
    updateModifier: (
      state: ModifierGroupModifiersState,
      { payload }: { payload: ModifierGroupModifier }
    ) => {
      const newModifiers = state.data.map(mod =>
        mod.item_id === payload.item_id ? payload : mod
      )
      return {
        ...state,
        data: newModifiers
      }
    },
    bulkUpdateModifiers: (
      state: ModifierGroupModifiersState,
      { payload }: { payload: ModifierGroupModifier[] }
    ) => {
      return {
        ...state,
        data: payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchModifierGroupModifiers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchModifierGroupModifiers.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload || initialState.data
        state.error = null
      }
    )
    builder.addCase(
      fetchModifierGroupModifiers.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateModifierGroupModifiers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateModifierGroupModifiers.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload || initialState.data
        state.error = null
      }
    )
    builder.addCase(
      updateModifierGroupModifiers.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})
export const {
  updateModifier,
  addModifier,
  deleteModifier,
  bulkUpdateModifiers
} = modifierGroupModifiersSlice.actions
export const selectModifierGroupModifiers = (state: RootState) =>
  state.modifierGroupModifiers

export default modifierGroupModifiersSlice.reducer
