import { useEffect, useState } from 'react'
import DropZone from './DropZone'
import { ProcessingState } from 'types'
import fileRequest from 'utils/fileRequest'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectAuth } from 'slices/auth'
import { selectAuthUser } from 'slices/authUser'
import { UploadFileLabel, UploadFileView } from './Files.styled'
import { RequestError } from 'utils'
import ErrMsg from 'components/ErrMsg'

const UploadFile = ({
  endpoint,
  fileType,
  fileUrl,
  fileName,
  fetchFiles
}: {
  endpoint: string
  fileType: string
  fileUrl?: string
  fileName: string
  fetchFiles?: any
}) => {
  const dispatch = useAppDispatch()
  const { token } = useAppSelector(selectAuth)
  const { brand } = useAppSelector(selectAuthUser)
  const [progress, setProgress] = useState({
    state: ProcessingState.Inactive,
    percentage: 0
  })
  const [imageUrl, setImageUrl] = useState(fileUrl)
  const [errMsg, setErrMsg] = useState<string | null>(null)

  const handleProgress = (percentage: number) => {
    setProgress({
      state: ProcessingState.Processing,
      percentage
    })
  }

  const uploadFile = async (file: File | null | undefined) => {
    if (!file) return
    try {
      await fileRequest({
        onProgress: handleProgress,
        url: endpoint,
        token: token,
        brandId: brand?.brand_id,
        dispatch,
        fileType,
        file,
        fileName: file?.name
      })
      setProgress({ state: ProcessingState.Inactive, percentage: 0 })
      if (fetchFiles) {
        fetchFiles()
        setImageUrl(undefined)
      }
    } catch (e) {
      const error = e as RequestError
      if (error?.detail) setErrMsg(error.detail)
      setProgress({ state: ProcessingState.Inactive, percentage: 0 })
    }
  }

  useEffect(() => {
    setImageUrl(fileUrl)
  }, [fileUrl])

  return (
    <UploadFileView>
      <ErrMsg errMsg={errMsg} />
      <UploadFileLabel>
        <strong>{fileName}</strong>
      </UploadFileLabel>
      <DropZone
        name={fileName}
        onFilesAdded={uploadFile}
        isLoading={progress.state === ProcessingState.Processing}
        file={{ file_type: fileType, file_url: imageUrl }}
        fileType={fileType}
        // deleteFile={deleteFile}
        progress={{
          percentage: progress.percentage,
          state: progress.state
        }}
        processingFileType={fileType}
      />
    </UploadFileView>
  )
}

export default UploadFile
