import styled from '@emotion/styled'
import ScrollHide from '../ScrollHide'
import TextHeading from '../TextHeading'

export const EntitiesNavView = styled.div`
  position: sticky;
  top: 0;
  z-index: 4;
  height: 100vh;
  background-color: ${props => props.theme.colors.background.secondary};
  border-left: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  visibility: hidden;
  width: 0;
  min-width: 0;
  transition: all 0.4s cubic-bezier(0.86, 0, 0.12, 1) 0s;

  &.-show {
    visibility: visible;
    width: ${props => props.theme.layout.desktop.sidebar.width};
    min-width: ${props => props.theme.layout.desktop.sidebar.width};
    overflow-x: hidden;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: none;
  }
`

export const EntitiesNavContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: ${props => props.theme.layout.desktop.sidebar.width};
`

export const EntitiesNavHeader = styled(TextHeading)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 ${props => props.theme.layout.desktop.header.height};
  padding: 0 ${props => props.theme.layout.desktop.sidebar.padding};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex: 0 0 ${props => props.theme.layout.mobile.header.height};
    padding: 0 ${props => props.theme.layout.mobile.sidebar.padding};
  }
`

export const EntitiesNavOpen = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease-in-out 0.4s;

  &.-show {
    opacity: 0;
    visibility: hidden;
  }

  button {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    color: ${props => props.theme.colors.text.primary};
  }
`

export const EntitiesNavClose = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out 0.4s;

  &.-show {
    opacity: 1;
    visibility: visible;
  }

  button {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.text.primary};
  }
`

export const EntitiesNavContent = styled(ScrollHide)`
  label: EntitiesNavContent;
  margin-left: -${props => props.theme.layout.desktop.border};
`

export const EntitiesNavLinks = styled.ul`
  label: EntitiesNavLinks;
  display: flex;
  flex-direction: column;
  padding: 0 0 ${props => props.theme.layout.desktop.padding};
`

export const EntitiesNavLinkView = styled.li`
  label: EntitiesNavLink;
  display: block;
  font-size: ${props => props.theme.sizes.small};

  a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.9rem ${props => props.theme.layout.desktop.sidebar.padding};
    color: ${props => props.theme.colors.text.primary};
    font-weight: 500;
  }

  a:hover,
  a:active {
    opacity: 1;
    background-color: ${props => props.theme.colors.background.tertiary};
  }

  a.active {
    background-color: ${props => props.theme.colors.background.tertiary};
    // font-weight: 700;

    &:before {
      position: absolute;
      content: ' ';
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.3rem;
      background-color: ${props => props.theme.colors.text.primary};
    }
  }

  a.active ~ ul {
    display: flex;
  }
`

export const EntitiesNavLinkActive = styled.span`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1.9rem;
  width: 2rem;
  justify-content: center;
  align-items: center;
  line-height: 0;

  .active & {
    display: flex;
  }
`

export const EntitiesNavLinkText = styled.span`
  display: block;
  line-height: 1;
`

export const EntitiesNavLinkSubsection = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  display: none;
`

export const EntitiesNavLinkSubsectionLink = styled.li`
  label: EntitiesNavLinkSubsectionLink;
  display: block;
  padding: 0.1rem 0;
  font-size: ${props => props.theme.sizes.small};
`
