import { Input, Select } from 'components'
import { Keyword, KeywordConfig as KeywordConfigType } from 'types'
import { ListFilterKeywordView } from './ListFilter.styled'

const ListFilterKeyword = ({
  keywordConfig,
  keyword,
  setKeyword
}: {
  keywordConfig: KeywordConfigType | null
  keyword: Keyword
  setKeyword: (keyword: string) => void
}) => {
  const { type, options, disabled } = keywordConfig || {}

  return (
    <ListFilterKeywordView>
      {!keywordConfig && (
        <Input
          name="keyword"
          type="text"
          value={keyword || ''}
          onChange={evt => setKeyword(evt.target.value)}
        />
      )}
      {type === 'date' || type === 'text' ? (
        <Input
          name="keyword"
          disabled={disabled || false}
          type={type}
          value={keyword || ''}
          onChange={evt => setKeyword(evt.target.value)}
        />
      ) : type === 'select' ? (
        <Select
          name="keyword"
          disabled={disabled || false}
          value={keyword}
          options={options || []}
          onChange={evt => setKeyword(evt.target.value)}
        />
      ) : null}
    </ListFilterKeywordView>
  )
}

export default ListFilterKeyword
