import { useEffect } from 'react'
import { CustomerAddress as CustomerAddressType, ModalWindow } from 'types'
import { useAppSelector } from 'app/hooks'
import { selectCustomerAddress } from 'slices/customerAddress'
import { ModalHeader } from 'components/Modal'
import { CustomerAddressForm } from 'components'

const CustomerAddress = ({
  args,
  modalWindow
}: {
  args: {
    customerAddressId: string
    customerId: string
    callback: (data: CustomerAddressType) => void
  }
  modalWindow: ModalWindow
}) => {
  const { error } = useAppSelector(selectCustomerAddress)
  const { customerId, customerAddressId, callback } = args
  const isNew = customerAddressId === 'new'

  useEffect(() => {
    if (error) {
      modalWindow?.scroll(0, 0)
    }
  }, [error, modalWindow])

  return (
    <>
      <ModalHeader title={isNew ? 'Add a new address' : 'Update address'} />
      <CustomerAddressForm
        customerId={customerId}
        customerAddressId={customerAddressId}
        callback={callback}
      />
    </>
  )
}

export default CustomerAddress
