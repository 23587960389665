import { TextBody } from 'components'
import {
  IacmRadio,
  IacmRadioInput,
  IacmRadioLabel,
  IacmRadioView
} from './IacmRadio.styled'

const IacmRadioButton = ({
  label,
  name,
  status,
  archiveStatus,
  setArchiveStatus
}: {
  label: string
  name: string
  status: string
  archiveStatus: string
  setArchiveStatus: (status: string) => void
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setArchiveStatus(evt.target.value)
  }

  return (
    <IacmRadioView>
      <IacmRadioLabel htmlFor={status}>
        <IacmRadioInput
          type="radio"
          id={status}
          name={name}
          value={status}
          checked={status === archiveStatus}
          onChange={onChange}
        />
        <IacmRadio />
        <TextBody size="small">
          <strong>{label}</strong>
        </TextBody>
      </IacmRadioLabel>
    </IacmRadioView>
  )
}

export default IacmRadioButton
