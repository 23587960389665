import { Form } from 'components'
import { FormFieldType, ModalWindow } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { selectIsSuper } from 'slices/authUser'

export interface SegmentDetailsModalProps {
  args: {
    name: string
    description: string
    isTemplate: boolean
    onSubmit: (name: Omit<SegmentDetailsModalProps['args'], 'onSubmit'>) => void
  }
  modalWindow: ModalWindow
}

const SegmentName = ({
  args: { name, description, isTemplate, onSubmit },
  modalWindow
}: SegmentDetailsModalProps) => {
  const dispatch = useAppDispatch()
  const data = {
    name: name || '',
    isTemplate: isTemplate || false,
    description: description || ' '
  }
  const isSuper = useAppSelector(selectIsSuper)

  const submit = (
    values: Omit<SegmentDetailsModalProps['args'], 'onSubmit'>
  ) => {
    onSubmit(values)
    dispatch(closeModal())
  }

  const segmentNameFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      required: true,
      name: 'name',
      label: 'Name'
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'description',
      label: 'Description'
    }
  ]

  if (isSuper) {
    segmentNameFields.push({
      fieldType: 'Checkbox',
      name: 'isTemplate',
      label: 'Use as template.',
      type: 'checkbox'
    })
  }

  return (
    <>
      <ModalHeader title="Edit Segment Details" />
      <Form
        fields={segmentNameFields}
        data={data}
        isLoading={false}
        submit={submit}
        submitText="Save"
      />
    </>
  )
}

export default SegmentName
