import Button from 'components/Button'
import { Upload } from 'iconoir-react'
import { UploadCsvInput } from './Files.styled'
import { useRef } from 'react'

interface ProcessCsvProps {
  onFilesAdded: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ProcessCsv = ({ onFilesAdded }: ProcessCsvProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <UploadCsvInput>
      <Button size="small" color="primary" onClick={openFileDialog}>
        <span className="icon" style={{ marginLeft: -3 }}>
          <Upload height="1.6rem" width="1.6rem" />
        </span>
        Upload CSV
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={onFilesAdded}
        style={{ display: 'none' }}
      />
    </UploadCsvInput>
  )
}

export default ProcessCsv
