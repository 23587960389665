import { makeDates } from 'utils'
import { DateFiltersButton, DateFiltersView } from './DateFilters.styled'

export const DateFilters = ({
  startDate,
  endDate,
  setDates
}: {
  startDate?: string
  endDate?: string
  setDates: (startDate: string, endDate: string) => void
}) => {
  const current = [startDate, endDate].toString()
  const allDates = makeDates()

  return (
    <DateFiltersView>
      {allDates.map(date => {
        const { title, dates } = date
        return (
          <DateFiltersButton
            key={title}
            onClick={() => setDates(dates[0], dates[1])}
            isActive={[dates[0], dates[1]].toString() === current}
          >
            {title}
          </DateFiltersButton>
        )
      })}
    </DateFiltersView>
  )
}

export default DateFilters
