import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { RevenueCenterUsers } from 'types'

export interface RevenueCenterUsersState {
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterUsersState = {
  loading: RequestStatus.Idle,
  error: null
}

export const addRevenueCenterUser = createAsyncThunk<
  RevenueCenterUsers,
  { revenueCenterId: string | number; data: RevenueCenterUsers },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterUsers/addRevenueCenterUser',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/users`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as RevenueCenterUsers
      dispatch(showNotification('User added successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const deleteRevenueCenterUser = createAsyncThunk<
  void,
  { revenueCenterId: string | number; data: RevenueCenterUsers },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterUser/deleteRevenueCenterUser',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/users`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('User successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const revenueCenterUsersSlice = createSlice({
  name: 'revenueCenterUsers',
  initialState,
  reducers: {
    resetRevenueCenterUsers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addRevenueCenterUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addRevenueCenterUser.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(addRevenueCenterUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteRevenueCenterUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterUser.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteRevenueCenterUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetRevenueCenterUsers } = revenueCenterUsersSlice.actions

export const selectRevenueCenterUsers = (state: RootState) =>
  state.revenueCenterUsers

export default revenueCenterUsersSlice.reducer
