import { useEffect, useMemo, useState } from 'react'
import { Button, Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { RequestStatus } from 'utils'
import { fetchMenu, selectMenuModifierGroups } from 'slices/menu'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  FormFieldType,
  ItemModifierGroup as ItemModifierGroupType,
  ModalWindow,
  SelectOptions
} from 'types'
import {
  createItemModifierGroups,
  deleteItemModifierGroups,
  fetchItemModifierGroups,
  selectItemModifierGroups
} from 'slices/itemModifierGroups'
import { closeModal } from 'slices/modal'

interface ItemModifierGroupArgs {
  menuId: string | number
  categoryId?: string | number
  modifierGroupId?: string | number
  itemId: string | number
  data: ItemModifierGroupType | null
}

interface ItemModifierGroupProps {
  args: ItemModifierGroupArgs
  modalWindow: ModalWindow
}

const ItemModifierGroup = ({ args, modalWindow }: ItemModifierGroupProps) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { menuId, categoryId, modifierGroupId, itemId, data } = args || {}
  const formData = { ...data } || itemModifierGroupEmpty
  const {
    data: itemModifierGroups,
    loading,
    error
  } = useAppSelector(selectItemModifierGroups)
  const modifierGroups = useAppSelector(selectMenuModifierGroups)
  const modGroups = modifierGroups.modifierGroups
  const currentModifiers = itemModifierGroups.map(i => i.modifier_group_id)
  const filteredGroups = modGroups.filter(i =>
    data
      ? currentModifiers.includes(i.modifier_group_id)
      : !currentModifiers.includes(i.modifier_group_id)
  )
  const isLoading =
    loading === RequestStatus.Pending ||
    modifierGroups.loading === RequestStatus.Pending

  useEffect(() => {
    const params = { with_related: 'categories,modifier_groups' }
    menuId && dispatch(fetchMenu({ menuId, params }))
  }, [dispatch, menuId])

  useEffect(() => {
    return () => {
      const args = { itemId, categoryId, modifierGroupId }
      itemId && dispatch(fetchItemModifierGroups(args))
    }
  }, [dispatch, itemId, categoryId, modifierGroupId])

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  const modifierGroupOptions = useMemo(() => {
    const modId =
      typeof modifierGroupId === 'string' ? parseInt(modifierGroupId) : null
    return filteredGroups
      .filter(i => i.modifier_group_id !== modId)
      .map(i => {
        return {
          value: i.modifier_group_id,
          text: `${i.full_name} (${i.short_name})`
        }
      })
  }, [filteredGroups, modifierGroupId])

  const handleSubmit = (values: ItemModifierGroupType) => {
    if (!itemId) return
    const { menu_position = 99 } = values
    const payload = { ...values, menu_position }
    const args = {
      categoryId,
      modifierGroupId,
      itemId,
      data: [payload]
    }
    setSubmitted(true)
    dispatch(createItemModifierGroups(args))
  }

  const handleDelete = () => {
    if (!itemId || !data) return
    const args = {
      categoryId,
      modifierGroupId,
      itemId,
      data: [data]
    }
    setSubmitted(true)
    dispatch(deleteItemModifierGroups(args))
  }

  return (
    <>
      <ModalHeader
        title={args ? 'Update Modifier Group' : 'Add Modifier Group'}
        subtitle="Choose a modifier group and set its rules"
      />
      <Form
        fields={itemModifierGroupFields(modifierGroupOptions, !!data)}
        data={formData}
        error={error}
        isLoading={isLoading}
        submit={handleSubmit}
        scrollWindow={modalWindow}
        isRelation={true}
      >
        {data ? (
          <Button
            color="delete"
            type="button"
            onClick={handleDelete}
            disabled={isLoading}
          >
            Delete
          </Button>
        ) : null}
      </Form>
    </>
  )
}

const itemModifierGroupEmpty = {
  modifier_group_id: null,
  min_options: '',
  max_options: '',
  included_options: ''
}

const itemModifierGroupFields = (
  modifierGroupOptions: SelectOptions,
  readOnly = false
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'modifier_group_id',
    options: modifierGroupOptions,
    disabled: readOnly,
    label: 'Modifier Group',
    error: 'Please select a modifier group'
  },
  {
    fieldType: 'Input',
    type: 'number',
    min: 0,
    name: 'min_options',
    required: true,
    label: 'Minimum Selections',
    placeholder: 'enter integer or 0',
    error: 'Please enter an integer or 0'
  },
  {
    fieldType: 'Input',
    type: 'number',
    min: 0,
    name: 'max_options',
    required: true,
    label: 'Maximum Selections',
    placeholder: 'enter integer or 0',
    error: 'Please enter an integer or 0'
  },
  {
    fieldType: 'Input',
    type: 'number',
    min: 0,
    name: 'included_options',
    required: true,
    label: 'Included Selections',
    placeholder: 'enter integer or 0',
    error: 'Please enter an integer or 0',
    hint: 'How many modifiers in this group are included for free (before additional charges)'
  }
]

export default ItemModifierGroup
