import styled from '@emotion/styled'
import { ThemeElementColors } from 'types'

interface HighlightViewProps {
  color: keyof ThemeElementColors
}

export const HighlightView = styled.span<HighlightViewProps>`
  display: inline-block;
  padding: 0.4rem 0.7rem 0.4rem;
  margin: -0.4rem 0 -0.4rem;
  line-height: 1;
  font-size: ${props => props.theme.sizes.small};
  background-color: ${props => props.theme.colors[props.color].background};
  color: ${props => props.theme.colors[props.color].color} !important;
  border-radius: ${props => props.theme.borderRadius.small};
`

const Highlight = ({
  children,
  color = 'alert',
  style
}: {
  children: React.ReactNode
  color?: keyof ThemeElementColors
  style?: React.CSSProperties
}) => {
  return (
    <HighlightView color={color} style={style}>
      {children}
    </HighlightView>
  )
}

export default Highlight
