import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus, handleError } from 'utils'
import { FormFieldType } from 'types'
import { selectAuthUser } from 'slices/authUser'
import { Form, ErrMsg, AuthPage, AuthForm, TextBody } from 'components'
import { Link, useNavigate } from 'react-router-dom'
import { resetPassword, selectUser } from 'slices/user'
import { AuthPageSuccess } from 'components/AuthPage/AuthPage.styled'

const SendReset = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { loading, error } = useAppSelector(selectUser)
  const { user } = useAppSelector(selectAuthUser)
  const isLoading = loading === RequestStatus.Pending
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [initialState] = useState({ email: '' })
  const formError = handleError(error)
  const errorMsg = formError.email || formError.link_url || formError.form

  const sendResetFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'email',
      name: 'email',
      required: true,
      label: 'Email',
      error: 'Please enter your email address'
    }
  ]

  const submit = (values: { email: string }) => {
    const { email } = values
    setErrMsg(null)
    setSubmitting(true)
    dispatch(resetPassword(email))
  }

  useEffect(() => {
    if (user) navigate('/')
  }, [navigate, user])

  useEffect(() => {
    if (!isLoading && submitting) {
      if (errorMsg) {
        setSubmitting(false)
        setErrMsg(errorMsg as string)
      } else {
        setSubmitting(false)
        setSuccess(true)
      }
    }
  }, [errorMsg, isLoading, submitting, navigate])

  return (
    <>
      <AuthPage src="https://assets.opentender.io/images/heroes/ARTD-C01-Device-005-3000x1956.jpg">
        <AuthForm
          title="Reset your password."
          subtitle="Enter your email address and we'll send a link to reset your password."
          footer={
            <TextBody as="p" size="small">
              Remember your current password? <Link to="/">Sign in here.</Link>
            </TextBody>
          }
        >
          {errMsg && <ErrMsg errMsg={errMsg} />}
          {success ? (
            <AuthPageSuccess>
              Password reset email sent. Please check your email.
            </AuthPageSuccess>
          ) : (
            <Form
              fields={sendResetFields}
              data={initialState}
              isLoading={isLoading}
              submit={submit}
              submitText="Send Password Reset"
              submitColor="secondary"
            />
          )}
        </AuthForm>
      </AuthPage>
    </>
  )
}

export default SendReset
