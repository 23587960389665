export interface AlignModalTypes {
  top: string
  center: string
  bottom: string
}

export const alignModalTypes: AlignModalTypes = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
}
