import React, { Children } from 'react'
import { ListFiltersItem, ListFiltersView } from './ListFilter.styled'

const ListFilters = ({ children }: React.ComponentProps<'div'>) => {
  const arrayChildren = Children.toArray(children)
  return (
    <ListFiltersView>
      {Children.map(arrayChildren, child => (
        <ListFiltersItem>{child}</ListFiltersItem>
      ))}
    </ListFiltersView>
  )
}

export default ListFilters
