import React from 'react'

const Store = React.lazy(() => import('./Store'))
const StoreRevenueCenter = React.lazy(() => import('./StoreRevenueCenter'))
const StoreRevenueCentersOutlet = React.lazy(
  () => import('./StoreRevenueCenters/StoreRevenueCentersOutlet')
)
const PosServer = React.lazy(
  () => import('./StoreRevenueCenter/PosServers/PosServer')
)
const PosServerOutlet = React.lazy(
  () => import('./StoreRevenueCenter/PosServers/PosServerOutlet')
)
const PosTerminal = React.lazy(
  () => import('./StoreRevenueCenter/PosTerminals/PosTerminal')
)
const PosTerminalOutlet = React.lazy(
  () => import('./StoreRevenueCenter/PosTerminals/PosTerminalOutlet')
)
const PrepStation = React.lazy(() => import('./PrepStation'))
const PrepStationsOutlet = React.lazy(
  () => import('./PrepStations/PrepStationsOutlet')
)
const KdsTerminal = React.lazy(
  () => import('./StoreRevenueCenter/KdsTerminals/KdsTerminal')
)
const KdsTerminalOutlet = React.lazy(
  () => import('./StoreRevenueCenter/KdsTerminals/KdsTerminalOutlet')
)
const Printer = React.lazy(
  () => import('./StoreRevenueCenter/Printers/Printer')
)
const PrinterOutlet = React.lazy(
  () => import('./StoreRevenueCenter/Printers/PrinterOutlet')
)
const Scanner = React.lazy(
  () => import('./StoreRevenueCenter/Scanners/Scanner')
)
const ScannerOutlet = React.lazy(
  () => import('./StoreRevenueCenter/Scanners/ScannerOutlet')
)
const PaymentTerminal = React.lazy(
  () => import('./StoreRevenueCenter/PaymentTerminals/PaymentTerminal')
)
const PaymentTerminalOutlet = React.lazy(
  () => import('./StoreRevenueCenter/PaymentTerminals/PaymentTerminalOutlet')
)

export {
  KdsTerminal,
  KdsTerminalOutlet,
  PaymentTerminal,
  PaymentTerminalOutlet,
  PosServer,
  PosServerOutlet,
  PosTerminal,
  PosTerminalOutlet,
  PrepStation,
  PrepStationsOutlet,
  Printer,
  PrinterOutlet,
  Scanner,
  ScannerOutlet,
  Store,
  StoreRevenueCenter,
  StoreRevenueCentersOutlet
}
