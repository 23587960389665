import DesignCheckbox from './DesignCheckbox'
import DesignLabel from './DesignLabel'

interface InputCheckboxProps {
  label: string
  id?: string
  name: string
  value: boolean
  setValue: (value: boolean) => void
  required?: boolean
}

const InputCheckbox = ({
  label,
  required,
  id,
  name,
  value,
  setValue
}: InputCheckboxProps) => {
  return (
    <DesignLabel
      label={label}
      required={required}
      htmlFor={id || name}
      style={{ padding: '5px 0' }}
    >
      <DesignCheckbox
        id={id || name}
        name={name}
        value={value}
        setValue={setValue}
      />
    </DesignLabel>
  )
}

export default InputCheckbox
