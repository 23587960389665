import { Select } from 'components/Forms'

const ListFilterOperator = ({
  operator,
  setOperator,
  options,
  disabled
}: {
  operator: string | null
  setOperator: (operator: string) => void
  options: { text: string; value: string }[]
  disabled?: boolean
}) => {
  const handleOperator = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setOperator(evt.target.value)
  }

  return (
    <Select
      // label="Operator"
      name="operator"
      value={operator}
      onChange={handleOperator}
      options={options}
      disabled={disabled}
    />
  )
}

export default ListFilterOperator
