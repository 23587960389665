import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreIntegration } from 'types'
import { showNotification } from 'slices/notification'

export interface StoreIntegrationState {
  data: StoreIntegration
  loading: RequestStatus
  error: RequestError | null
}

export interface StoreIntegrationApi {
  data: StoreIntegration
  from: {
    store_id: number
  }
}

const initialState: StoreIntegrationState = {
  data: { levelup_location_id: 0, ctuit_ext_id: '' },
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreIntegration = createAsyncThunk<
  StoreIntegration | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeIntegration/fetchStoreIntegration',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/integrations`
      )) as StoreIntegrationApi

      return resp?.data || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreIntegration = createAsyncThunk<
  StoreIntegration,
  { storeId: string | number; data: StoreIntegration },
  { state: RootState; rejectValue: RequestError }
>(
  'storeIntegration/upsertStoreIntegration',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/integrations`,
        'PUT',
        data
      )) as StoreIntegrationApi
      dispatch(showNotification('Updated successfully!'))
      return resp.data
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeIntegrationSlice = createSlice({
  name: 'storeIntegration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreIntegration.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreIntegration.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchStoreIntegration.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreIntegration.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreIntegration.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(upsertStoreIntegration.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStoreIntegration = (state: RootState) =>
  state.storeIntegration

export default storeIntegrationSlice.reducer
