import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'
import { Invoice } from 'types'
import { NavigateFunction } from 'react-router-dom'

export interface HouseAccountInvoiceState {
  data: Invoice | null
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountInvoiceState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export interface HouseAccountInvoiceRequest {
  house_account_id: number
  is_paid: boolean
  order_ids: number[]
}

export const addHouseAccountInvoice = createAsyncThunk<
  Invoice,
  {
    data: HouseAccountInvoiceRequest
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountInvoice/addHouseAccountInvoice',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `invoices`
      const resp = (await api?.request(endpoint, 'POST', data)) as Invoice
      dispatch(
        showNotification(`Invoice #${resp.invoice_id} created successfully`)
      )
      const pathname = `/customers/house-accounts/${data.house_account_id}/invoices`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateHouseAccountInvoice = createAsyncThunk<
  Invoice,
  { data: HouseAccountInvoiceRequest; invoiceId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountInvoice/updateHouseAccountInvoice',
  async ({ data, invoiceId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `invoices/${invoiceId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Invoice
      dispatch(showNotification('Invoice updated successfully'))
      dispatch(fetchHouseAccountInvoice({ invoiceId }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchHouseAccountInvoice = createAsyncThunk<
  Invoice,
  {
    invoiceId: string | number
    params?: { with_related?: boolean }
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountInvoice/fetchHouseAccountInvoice',
  async ({ invoiceId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `invoices/${invoiceId}${queryParams}`
      const resp = (await api?.request(endpoint)) as Invoice
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountInvoiceSlice = createSlice({
  name: 'houseAccountInvoice',
  initialState,
  reducers: {
    resetHouseAccountInvoice: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addHouseAccountInvoice.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addHouseAccountInvoice.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addHouseAccountInvoice.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateHouseAccountInvoice.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateHouseAccountInvoice.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      updateHouseAccountInvoice.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(fetchHouseAccountInvoice.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchHouseAccountInvoice.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(fetchHouseAccountInvoice.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHouseAccountInvoice } = houseAccountInvoiceSlice.actions

export const selectHouseAccountInvoice = (state: RootState) =>
  state.houseAccountInvoice

export default houseAccountInvoiceSlice.reducer
