import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { gql } from 'graphql-request'
import { ListFilter, ExternalUsers } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface ExternalUsersState {
  data: ExternalUsers | null
  loading: RequestStatus
  error: RequestError
}

export interface ExternalUsersAPI {
  externalUsers: ExternalUsers
}

const initialState: ExternalUsersState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_EXTERNAL_USERS = gql`
  query UseExternalUsers(
    $page: Int!
    $perPage: Int
    $order: String
    $orderBy: String
    $searchTerm: String
    $filters: [JSON!]
  ) {
    externalUsers(
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
      searchTerm: $searchTerm
      filters: $filters
    ) {
      page
      totalPageCount
      totalCount
      results {
        id
        email
        fullName
        imageUrl
      }
    }
  }
`

export type ExternalUsersParams = {
  filters?: ListFilter[] | []
  order?: string
  orderBy?: string
  page?: number
  perPage?: number
  searchTerm?: string
  useQueryType?: string
}

export const fetchExternalUsers = createAsyncThunk<
  ExternalUsersAPI,
  ExternalUsersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'externalUsers/fetchExternalUsers',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_EXTERNAL_USERS,
        params
      )) as ExternalUsersAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const externalUsersSlice = createSlice({
  name: 'externalUsers',
  initialState,
  reducers: {
    resetExternalUsers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchExternalUsers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchExternalUsers.fulfilled, (state, { payload }) => {
      state.data = payload.externalUsers
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchExternalUsers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectExternalUsersOptions = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.externalUsers
    return { data, loading, error }
  },
  ({ data }) => {
    if (!data) return []
    const externalUsersOptions = data.results.map(({ id, fullName }) => ({
      value: id,
      text: fullName
    }))
    return externalUsersOptions
  }
)

export const { resetExternalUsers } = externalUsersSlice.actions

export const selectExternalUsers = (state: RootState) => state.externalUsers

export default externalUsersSlice.reducer
