import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BlockedEmail } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'
import { fetchBlockedEmails } from './blockedEmails'

export interface BlockedEmailState {
  data: BlockedEmail | null
  loading: RequestStatus
  error: RequestError
}

const initialState: BlockedEmailState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBlockedEmail = createAsyncThunk<
  BlockedEmail,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedEmails/fetchBlockedEmail',
  async (blockedEmailId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-emails/${blockedEmailId}`
      return (await api?.request(endpoint)) as BlockedEmail
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createBlockedEmail = createAsyncThunk<
  BlockedEmail,
  BlockedEmail,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedEmails/createBlockedEmail',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-emails`
      const resp = (await api?.request(endpoint, 'POST', data)) as BlockedEmail
      dispatch(showNotification('Email successfully blocked!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const unblockEmail = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'blockedEmails/unblockEmail',
  async (blockedEmailId, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `blocked-emails/${blockedEmailId}`
      await api?.request(endpoint, 'DELETE', null)
      dispatch(showNotification('Email successfully unblocked!'))
      dispatch(fetchBlockedEmails())
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const blockedEmailSlice = createSlice({
  name: 'blockedEmail',
  initialState,
  reducers: {
    resetBlockedEmail: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBlockedEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBlockedEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchBlockedEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createBlockedEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createBlockedEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(createBlockedEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(unblockEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(unblockEmail.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(unblockEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBlockedEmail } = blockedEmailSlice.actions

export const selectBlockedEmail = (state: RootState) => state.blockedEmail

export default blockedEmailSlice.reducer
