import {
  Address,
  CheckoutError,
  CheckoutErrorMessages,
  CheckoutPromoCodes,
  CheckoutTenders,
  MenuCategories,
  MenuItems,
  Order,
  OrderCreateDiscounts,
  OrderCreateSurcharges,
  OrderData,
  OrderDiscounts,
  OrderSurcharges,
  OrderTenders,
  isCreditTender,
  isHouseAccountTender
} from '@open-tender/types'
import {
  handleCheckoutError,
  makeSimpleCart,
  prepareOrder,
  rehydrateCart
} from '@open-tender/utils'
import { OrderState } from 'slices/order'
import { BuiltMenu, CustomerAddress } from 'types'

const makeOrderAddress = (
  customerAddress: CustomerAddress | null
): Address | null => {
  if (!customerAddress) return null
  const {
    city,
    company,
    contact,
    latitude,
    longitude,
    phone,
    postal_code,
    state,
    street,
    unit
  } = customerAddress
  const address: Address = {
    street,
    unit,
    city,
    state,
    postal_code,
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  }
  if (company) {
    address.company = company
  }
  if (contact) {
    address.contact = contact
  }
  if (phone) {
    address.phone = phone
  }
  return address
}

export const makePreparedOrder = (order: OrderState) => {
  const {
    cart,
    customer,
    customerAddress,
    deposit,
    details,
    deviceType,
    discounts,
    discountsInternal,
    orderId,
    promoCodes,
    revenueCenter,
    requestedAt,
    serviceType,
    surcharges,
    tenders,
    tip
  } = order
  if (!cart || !revenueCenter || !requestedAt || !serviceType) {
    return null
  }
  const data: OrderData = {
    address: makeOrderAddress(customerAddress),
    cart,
    cartId: null,
    customer: customer ? { customer_id: customer.customer_id } : null,
    deposit,
    details,
    deviceType: deviceType || null,
    discounts,
    discountsInternal,
    orderId,
    points: null,
    prepType: null,
    promoCodes,
    requestedAt,
    revenueCenterId: revenueCenter.revenue_center_id,
    serviceType,
    surcharges,
    table: null,
    tenders: tenders.filter(i => i.tender_status !== 'PAID'),
    tip
  }
  return prepareOrder(data)
}

interface CheckoutErrorMessage {
  field: string
  message: string
}

export const makeCheckoutErrors = (errors: CheckoutErrorMessages | null) => {
  if (!errors) return []
  return Object.entries(errors).reduce((arr, [key, value]) => {
    const val: CheckoutErrorMessage[] =
      typeof value === 'string'
        ? [{ field: key, message: value }]
        : makeCheckoutErrors(value)
    return key === 'form' ? arr : [...arr, ...val]
  }, [] as CheckoutErrorMessage[])
}

export const handleCheckoutErrors = (checkoutError: CheckoutError) => {
  const errors = handleCheckoutError(checkoutError)
  return makeCheckoutErrors(errors)
}

const makeMenuItemLookup = (menu: MenuCategories) => {
  return menu.reduce((arr, category) => {
    const { items, children } = category
    const childItems = children.reduce((childArr, cat) => {
      return [...childArr, ...cat.items]
    }, [] as MenuItems)
    return [...arr, ...items, ...childItems]
  }, [] as MenuItems)
}

const rehydrateSurcharges = (
  surcharges: OrderSurcharges
): OrderCreateSurcharges => {
  return surcharges.map(i => ({ id: i.id })) || []
}

const rehydrateDiscounts = (
  discounts: OrderDiscounts
): OrderCreateDiscounts => {
  return (
    discounts
      .filter(i => !i.is_promo_code)
      .map(i => ({ id: i.id, ext_id: i.ext_id })) || []
  )
}

const rehydratePromoCodes = (discounts: OrderDiscounts): CheckoutPromoCodes => {
  return discounts.filter(i => i.is_promo_code).map(i => i.name) || []
}

const rehydrateTenders = (tenders: OrderTenders) => {
  const other = tenders?.map(i => {
    if (isCreditTender(i)) {
      return { ...i, ...i.credit_card }
    } else if (isHouseAccountTender(i)) {
      return { ...i, ...i.house_account }
    } else {
      return i
    }
  })
  return other
}

export const rehydrateOrder = (order: Order, builtMenu: BuiltMenu) => {
  const simpleCart = makeSimpleCart(order.cart)
  const { menu, sold_out_items } = builtMenu
  const menuItems = makeMenuItemLookup(menu)
  const { cart } = rehydrateCart(menuItems, simpleCart, sold_out_items)
  const {
    is_editable,
    deposit,
    details,
    device_type: deviceType,
    order_id,
    order_type: orderType,
    requested_at,
    service_type: serviceType,
    totals
  } = order
  const surcharges = rehydrateSurcharges(order.surcharges)
  const discounts = rehydrateDiscounts(order.discounts)
  const promoCodes = rehydratePromoCodes(order.discounts)
  const tenders = is_editable
    ? (rehydrateTenders(order.tenders) as CheckoutTenders)
    : []
  const orderId = is_editable ? order_id : null
  const requestedAt = is_editable ? requested_at : null
  return {
    cart,
    deposit,
    details,
    deviceType,
    discounts,
    orderId,
    orderType,
    promoCodes,
    requestedAt,
    serviceType,
    surcharges,
    tenders,
    tip: totals.tip
  }
}
