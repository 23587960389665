import styled from '@emotion/styled'
import { ThemeButtonColors, ThemeButtonSizes } from 'types'

interface ButtonViewProps {
  size: keyof ThemeButtonSizes
  color: keyof ThemeButtonColors
}

const ButtonView = styled.button<ButtonViewProps>`
  text-align: center;
  line-height: 1.42857;
  transition: all 0.4s ease;
  border-width: 1px;
  border-style: solid;
  height: ${props => props.theme.buttons.sizes[props.size].height};
  padding: ${props => props.theme.buttons.sizes[props.size].padding};
  border-radius: ${props => props.theme.buttons.sizes[props.size].borderRadius};
  font-size: ${props => props.theme.buttons.sizes[props.size].fontSize};
  color: ${props => props.theme.buttons.colors[props.color].default.text};
  background-color: ${props =>
    props.theme.buttons.colors[props.color].default.background};
  border-color: ${props =>
    props.theme.buttons.colors[props.color].default.border};
  opacity: ${props => (props.disabled ? '0.5' : '1.0')};

  &:enabled:hover {
    // filter: brightness(85%);
    color: ${props => props.theme.buttons.colors[props.color].hover.text};
    background-color: ${props =>
      props.theme.buttons.colors[props.color].hover.background};
    border-color: ${props =>
      props.theme.buttons.colors[props.color].hover.border};
  }
`

interface ButtonSpanProps {
  color?: keyof ThemeButtonColors
  size?: keyof ThemeButtonSizes
}

const ButtonSpan = styled.span<ButtonSpanProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => (props.size === 'xsmall' ? '0.5rem' : '0.8rem')};

  & > span {
    display: block;
  }

  & > span.icon {
    line-height: 0;
  }
`

interface ButtonProps extends React.ComponentProps<'button'> {
  onClick?: any
  size?: keyof ThemeButtonSizes
  color?: keyof ThemeButtonColors
}

const Button = ({
  type,
  disabled,
  onClick,
  size = 'medium',
  color = 'primary',
  className = '',
  children,
  style
}: ButtonProps) => {
  const click = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!disabled && onClick) onClick()
  }

  return (
    <ButtonView
      type={type}
      disabled={disabled}
      onClick={click}
      size={size}
      color={color}
      style={style}
      className={className}
    >
      <ButtonSpan color={color} size={size}>
        {children}
      </ButtonSpan>
    </ButtonView>
  )
}

export default Button
