import styled from '@emotion/styled'
import TextHeading from 'components/TextHeading'
import { ThemeFontSizes } from 'types'

export const CardView = styled.div`
  min-width: 40rem;
  max-width: 100%;
  padding: 3rem;
  margin: 0 0 ${props => props.theme.layout.desktop.gap};
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  background-color: ${props => props.theme.colors.background.secondary};
  position: relative;
  color: ${props => props.theme.colors.text.primary};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    min-width: 100%;
    padding: ${props => props.theme.layout.mobile.padding};
  }

  .table-wrapper {
    min-height: 0;
  }

  table th:first-of-type,
  table td:first-of-type {
    padding-left: 0;
  }
`

export const CardHeader = styled.div`
  display: flex-column;
  padding: 0 0 1rem;
  border-style: solid;
  border-color: ${props => props.theme.colors.border.primary};
  border-width: 0;
  border-bottom-width: ${props => props.theme.layout.desktop.border};
  gap: 1rem;
  margin: 0 0 1rem;
`

export const CardHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardHeaderDescription = styled.div`
  margin: 1rem 0 1rem;
`

export const CardFooter = styled.div`
  position: relative;
  margin-top: auto;
  display: flex;
`

export const CardTitle = styled(TextHeading)`
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
`

export const CardCta = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

export const CardRowsView = styled.div``

export const CardRowItem = styled.div<{
  size?: keyof ThemeFontSizes
  hasSubRows?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0;
  border-bottom: ${props =>
    props.hasSubRows ? 0 : `1px solid ${props.theme.colors.border.primary}`};
  font-size: ${props => props.theme.sizes[props.size || 'small']};

  &:last-of-type {
    border: 0;
    padding-bottom: 0;
  }
`

export const CardSubRowItem = styled.div<{
  size?: keyof ThemeFontSizes
  showBorderBottom?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0.8rem;
  border-bottom: ${props =>
    props.showBorderBottom
      ? `1px solid ${props.theme.colors.border.primary}`
      : 0};
  font-size: ${props => props.theme.sizes[props.size || 'small']};
`

export const CardRowValue = styled.span<{
  align?: 'left' | 'right'
}>`
  flex: 1;
  text-align: ${props => props.align || 'center'};

  > span {
    display: inline-block;
  }

  a:hover {
    color: ${props => props.theme.colors.accent.primary};
  }
`

export const CardRowsSectionView = styled.div`
  padding: 0 0 4rem;

  &:last-of-type {
    padding-bottom: 0;
  }
`
