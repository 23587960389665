import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { ItemLoyaltyBonus, ItemLoyaltyBonuses } from 'types'
import { showNotification } from 'slices/notification'

export interface ItemLoyaltyBonusState {
  data: ItemLoyaltyBonuses
  loading: RequestStatus
  error: RequestError
}

const initialState: ItemLoyaltyBonusState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItemLoyaltyBonus = createAsyncThunk<
  ItemLoyaltyBonuses,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyBonus/fetchItemLoyaltyBonus',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-bonuses`
      const resp = (await api?.request(endpoint)) as ItemLoyaltyBonuses
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createItemLoyaltyBonus = createAsyncThunk<
  ItemLoyaltyBonuses,
  { itemId: string | number; data: ItemLoyaltyBonuses },
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyBonus/createItemLoyaltyBonus',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-bonuses`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as ItemLoyaltyBonuses
      dispatch(showNotification('Loyalty Bonus successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItemLoyaltyBonus = createAsyncThunk<
  ItemLoyaltyBonuses,
  { itemId: string | number; data: ItemLoyaltyBonus },
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyBonus/updateItemLoyaltyBonus',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-bonuses`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as ItemLoyaltyBonuses
      dispatch(showNotification('Loyalty Bonus successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteItemLoyaltyBonus = createAsyncThunk<
  void,
  { itemId: string | number; data: ItemLoyaltyBonuses },
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyBonus/deleteItemLoyaltyBonus',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-bonuses`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Loyalty Bonus successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemLoyaltyBonusSlice = createSlice({
  name: 'itemLoyaltyBonus',
  initialState,
  reducers: {
    resetItemLoyaltyBonus: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemLoyaltyBonus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchItemLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createItemLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createItemLoyaltyBonus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createItemLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItemLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItemLoyaltyBonus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateItemLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteItemLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteItemLoyaltyBonus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteItemLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItemLoyaltyBonus } = itemLoyaltyBonusSlice.actions

export const selectItemLoyaltyBonus = (state: RootState) =>
  state.itemLoyaltyBonus

export default itemLoyaltyBonusSlice.reducer
