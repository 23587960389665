import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Content, RefundCard } from 'components'
import {
  FetchBulkOrderParams,
  fetchBulkOrder,
  selectBulkOrder
} from 'slices/bulkOrder'
import {
  fetchOrderRemainingRefund,
  selectOrderRemainingRefund
} from 'slices/orderRefund'
import { RequestStatus } from 'utils'
import { OrderGiftCard, RefundableItem, RefundableModifiers } from 'types'
import { fetchOrder, selectOrder } from 'slices/order'

const BulkOrderRefund = () => {
  const dispatch = useAppDispatch()
  const { orderId } = useParams()
  const { data: order, loading } = useAppSelector(selectBulkOrder)
  const { data: orderDetail } = useAppSelector(selectOrder)
  const {
    data: remainingRefund,
    loading: itemsLoading,
    error: refundError
  } = useAppSelector(selectOrderRemainingRefund)
  const isLoading =
    loading === RequestStatus.Pending || itemsLoading === RequestStatus.Pending

  const handlefetchOrder = useCallback(() => {
    if (!orderId) return
    const params: FetchBulkOrderParams = {
      expand: 'true',
      with_related: 'true'
    }
    dispatch(fetchBulkOrder({ orderId, params }))
    dispatch(fetchOrder(orderId))
  }, [dispatch, orderId])

  const handleFetchRemainingRefund = useCallback(() => {
    dispatch(fetchOrderRemainingRefund(Number(orderId)))
  }, [dispatch, orderId])

  useEffect(() => {
    handlefetchOrder()
    handleFetchRemainingRefund()
  }, [handleFetchRemainingRefund, handlefetchOrder])

  const orderItems = useMemo(() => {
    if (!order || (!order.items.length && !order.gift_cards.length)) return null

    return order.items
      .map(item => {
        const refundModifiers: RefundableModifiers = item.modifier_groups
          .flatMap(modifierGroup =>
            modifierGroup.modifiers.map(modifier => {
              const unitPrice = modifier.quantity_total
                ? parseFloat(modifier.price_total) / modifier.quantity_total
                : 0
              const quantity =
                remainingRefund?.items.find(i => i.line_no === modifier.line_no)
                  ?.quantity || 0
              const quantityTotal = quantity
              const priceTotal = (unitPrice * quantityTotal).toFixed(2)
              return {
                item_id: modifier.item_id,
                line_no: modifier.line_no,
                name: modifier.full_name,
                short_name: modifier.short_name,
                price: modifier.price,
                quantity: quantity,
                price_total: priceTotal,
                groups: modifier.groups
              }
            })
          )
          .filter(modifier => modifier.quantity > 0)

        const quantity =
          remainingRefund?.items.find(i => i.line_no === item.line_no)
            ?.quantity || 0
        const quantity_total = quantity
        const price_total = (parseFloat(item.price) * quantity_total).toFixed(2)
        const refundItem: RefundableItem = {
          item_id: item.item_id,
          line_no: item.line_no,
          name: item.full_name,
          price: item.price,
          quantity: quantity_total,
          price_total: price_total,
          modifiers: refundModifiers
        }
        return refundItem
      })
      .filter(item => item.quantity > 0 || item.modifiers.length > 0)
  }, [order, remainingRefund?.items])

  const refundableGiftCards = useMemo(() => {
    if (
      !order ||
      !order.gift_cards.length ||
      !remainingRefund ||
      !remainingRefund.gift_cards.length
    )
      return [] as OrderGiftCard[]

    return order.gift_cards
      .filter(i =>
        remainingRefund.gift_cards.find(g => g.gift_card_id === i.gift_card_id)
      )
      .map(
        i =>
          ({
            ...i,
            amount: remainingRefund.gift_cards.find(
              g => g.gift_card_id === i.gift_card_id
            )?.amount
          }) as OrderGiftCard
      )
  }, [order, remainingRefund])

  const remainingTenders = useMemo(() => {
    const nonVoidedTenders = (order?.tenders || []).filter(
      t => t.tender_status.toUpperCase() === 'PAID'
    )
    return nonVoidedTenders.map(t => {
      const tender = (remainingRefund?.tenders || []).find(
        rt => rt.tender_id === t.tender_id
      )
      const amount = tender?.amount || '0.00'
      return { ...t, amount }
    })
  }, [order?.tenders, remainingRefund?.tenders])

  const remainingRefundTotal = useMemo(() => {
    const total = (remainingRefund?.tenders || [])
      .reduce((t, i) => (t += parseFloat(i.amount)), 0.0)
      .toFixed(2)
    return total
  }, [remainingRefund?.tenders])

  const remainingSurcharges = useMemo(() => {
    return (order?.surcharges || []).filter(s =>
      remainingRefund?.surcharges?.find(
        rs => rs.surcharge_id === s.surcharge_id
      )
    )
  }, [order?.surcharges, remainingRefund?.surcharges])

  const remainingOrder = useMemo(
    () =>
      orderDetail
        ? {
            ...orderDetail,
            gift_cards: refundableGiftCards,
            tip: remainingRefund?.tip || '0',
            tenders: remainingTenders,
            surcharges: remainingSurcharges,
            total: remainingRefundTotal
          }
        : null,
    [
      orderDetail,
      refundableGiftCards,
      remainingSurcharges,
      remainingTenders,
      remainingRefund?.tip,
      remainingRefundTotal
    ]
  )

  const headers = ['Item', 'Price', 'Quantity', 'Total', 'Refunded', 'Amount']

  if (!orderId) return null
  return (
    <Content
      title={`Refund Order #${orderId}`}
      subtitle={`Choose the items, etc. you'd like to refund below`}
    >
      <RefundCard
        error={refundError}
        headers={headers}
        items={orderItems}
        loading={isLoading}
        orderDetail={remainingOrder}
        orderId={orderId}
      />
    </Content>
  )
}

export default BulkOrderRefund
