import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { PrepStation } from 'types'
import { showNotification } from 'slices/notification'

export interface PrepStationState {
  data: PrepStation | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PrepStationState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchPrepStation = createAsyncThunk<
  PrepStation,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'prepStation/fetchPrepStation',
  async (prepStationId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `prep-stations/${prepStationId}`
      const resp = (await api?.request(endpoint)) as PrepStation
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createPrepStation = createAsyncThunk<
  PrepStation,
  { data: PrepStation; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'prepStation/createPrepStation',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'prep-stations',
        'POST',
        data
      )) as PrepStation
      dispatch(showNotification('Prep station successfully created!'))
      const pathname = `/stores/${resp.store_id}/prep-stations/${resp.prep_station_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePrepStation = createAsyncThunk<
  PrepStation,
  {
    prepStationId: string | number
    data: PrepStation
  },
  { state: RootState; rejectValue: RequestError }
>(
  'prepStation/updatePrepStation',
  async ({ prepStationId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `prep-stations/${prepStationId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as PrepStation
      dispatch(showNotification('Prep station successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const prepStationSlice = createSlice({
  name: 'prepStation',
  initialState,
  reducers: {
    resetPrepStation: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPrepStation.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPrepStation.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchPrepStation.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createPrepStation.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createPrepStation.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createPrepStation.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePrepStation.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updatePrepStation.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updatePrepStation.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPrepStation } = prepStationSlice.actions

export const selectPrepStation = (state: RootState) => state.prepStation

export default prepStationSlice.reducer
