import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CustomerAddress } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface CustomerAddressState {
  data: CustomerAddress | null
  loading: RequestStatus
  error: RequestError
}

const initialState: CustomerAddressState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCustomerAddress = createAsyncThunk<
  CustomerAddress,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'customerAddresses/fetchCustomerAddress',
  async (customerAddressId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-addresses/${customerAddressId}`
      const resp = (await api?.request(endpoint)) as CustomerAddress
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createCustomerAddress = createAsyncThunk<
  CustomerAddress,
  { data: CustomerAddress },
  { state: RootState; rejectValue: RequestError }
>(
  'customerAddress/createCustomerAddress',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-addresses`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as CustomerAddress
      dispatch(showNotification('Address successfully created!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCustomerAddress = createAsyncThunk<
  CustomerAddress,
  { customerAddressId: string | number; data: CustomerAddress },
  { state: RootState; rejectValue: RequestError }
>(
  'customerAddress/updateCustomerAddress',
  async (
    { customerAddressId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `customer-addresses/${customerAddressId}`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as CustomerAddress
      dispatch(showNotification('Address updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const customerAddressSlice = createSlice({
  name: 'customerAddress',
  initialState,
  reducers: {
    resetCustomerAddress: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCustomerAddress.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCustomerAddress.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchCustomerAddress.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createCustomerAddress.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createCustomerAddress.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(createCustomerAddress.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCustomerAddress.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCustomerAddress.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateCustomerAddress.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCustomerAddress } = customerAddressSlice.actions

export const selectCustomerAddress = (state: RootState) => state.customerAddress

export default customerAddressSlice.reducer
