import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { PosServer, PosServerUpdate } from 'types'
import { showNotification } from 'slices/notification'
import { fetchPosServers } from 'slices/posServers'

export interface PosServerState {
  data: PosServer | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PosServerState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type PosServerParams = {
  expand?: string
  with_related?: string
}

export const fetchPosServer = createAsyncThunk<
  PosServer | null,
  { posServerId: string | number; params?: PosServerParams },
  { state: RootState; rejectValue: RequestError }
>(
  'posServer/fetchPosServer',
  async ({ posServerId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/pos-servers/${posServerId}${queryParams}`
      )) as PosServer
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createPosServer = createAsyncThunk<
  PosServer,
  { data: PosServer; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'posServer/createPosServer',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/pos-servers',
        'POST',
        data
      )) as PosServer
      dispatch(showNotification('POS Server successfully created!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPosServers({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePosServer = createAsyncThunk<
  void,
  { posServerId: string | number; data: PosServerUpdate },
  { state: RootState; rejectValue: RequestError }
>(
  'posServer/updatePosServer',
  async ({ posServerId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(`pos-v2/pos-servers/${posServerId}`, 'PUT', data)
      dispatch(showNotification('POS Server successfully updated!'))
      const params = { with_related: 'true' }
      dispatch(fetchPosServer({ posServerId, params }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posServerSlice = createSlice({
  name: 'posServer',
  initialState,
  reducers: {
    resetPosServer: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPosServer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPosServer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchPosServer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createPosServer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createPosServer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createPosServer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePosServer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updatePosServer.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updatePosServer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPosServer } = posServerSlice.actions

export const selectPosServer = (state: RootState) => state.posServer

export default posServerSlice.reducer
