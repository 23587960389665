import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Brand, Brands } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface BrandsAPI {
  data: Brand[]
  links: {
    next: string
  }
}

export interface BrandsState {
  data: Brands
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: BrandsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type BrandParams = {
  limit?: number
  is_active?: boolean
}

export const fetchBrands = createAsyncThunk<
  BrandsAPI,
  BrandParams | void,
  { state: RootState; rejectValue: RequestError }
>('brands/fetchBrands', async (params, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `super/brands${queryParams}`
    return (await api?.request(endpoint)) as BrandsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBrands.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBrands.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBrands.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectBrands = (state: RootState) => state.brands

export default brandsSlice.reducer
