import styled from '@emotion/styled'
// import TextBody from 'components/TextBody'

const DesignCheckboxView = styled.span`
  label: DesignCheckboxView;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
`

// const DesignCheckboxInput = styled.input`
//   label: DesignCheckboxInput;
//   position: absolute;
//   border: 0;
//   clip: rect(0 0 0 0);
//   clip-path: inset(50%);
//   width: 1px;
//   height: 1px;
//   padding: 0;
//   margin: -1px;
//   overflow: hidden;
//   white-space: nowrap;
//   outline: none;
// `

// const DesignCheckboxBox = styled.span`
//   label: DesignCheckboxBox;
//   content: '';
//   flex-shrink: 0;
//   display: block;
//   position: relative;
//   width: 1.9rem;
//   height: 1.9rem;
//   padding: 0;
//   margin: 0;
//   border-radius: 0.2rem;
//   border: 0.1rem solid ${props => props.theme.colors.border.primary};

//   &:before {
//     content: '';
//     position: absolute;
//     width: 1.1rem;
//     height: 0.6rem;
//     background: transparent;
//     top: 0.4rem;
//     left: 0.3rem;
//     border-width: 0.2rem;
//     border-style: solid;
//     border-top: none;
//     border-right: none;
//     transform: scale(1) rotate(-45deg);
//     opacity: 0;
//     color: ${props => props.theme.colors.text.primary};
//   }

//   input:checked + &:before {
//     opacity: 1;
//   }

//   input:disabled + & {
//     opacity: 0.5;
//   }

//   input:disabled + &:before {
//     opacity: 0.5;
//   }
// `

// const DesignCheckboxText = styled(TextBody)`
//   label: DesignCheckboxText;
//   display: block;
//   margin: 0 0 0 1rem;
// `

interface DesignCheckboxProps {
  label?: string
  id?: string
  name: string
  value: boolean
  setValue: (value: boolean) => void
}

const DesignCheckbox = ({
  label,
  id,
  name,
  value,
  setValue
}: DesignCheckboxProps) => {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('this is happening')
    // console.log(evt.target.id)
    setValue(evt.target.checked)
    evt.target.blur()
  }

  return (
    <DesignCheckboxView>
      <input
        type="checkbox"
        id={id || name}
        name={name}
        onChange={onChange}
        checked={value}
      />
      {/* <DesignCheckboxInput
        type="checkbox"
        id={id || name}
        name={name}
        onChange={onChange}
        checked={value}
      />
      <DesignCheckboxBox />
      {label && (
        <DesignCheckboxText size="xsmall" color="secondary">
          {label}
        </DesignCheckboxText>
      )} */}
    </DesignCheckboxView>
  )
}

export default DesignCheckbox
