import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import { useModifier } from '@open-tender/utils'
import MenuItemQuantity from '../MenuItemQuantity'
import {
  MenuItemOptionName,
  MenuItemOptionPrice,
  MenuItemOptionQuantity,
  MenuItemOptionRadio,
  MenuItemOptionView
} from './MenuItemOption.styled'
import { formatDollars } from 'utils'
import TextBody from 'components/TextBody'

const MenuItemOption = ({
  group,
  option,
  levels,
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
}) => {
  const {
    id,
    name,
    price,
    totalPrice,
    isRadio,
    toggle,
    adjust,
    increment,
    decrement,
    incrementDisabled,
    decrementDisabled,
    // TODO: handle nested modifiers
    // updatedLevels,
    quantity
  } = useModifier(
    group,
    option,
    levels,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
    [],
    undefined
  )
  const isChecked = option.quantity === 1

  return isRadio ? (
    <MenuItemOptionView>
      <MenuItemOptionName size="small">
        {name}
        <TextBody size="xxsmall">{id}</TextBody>
      </MenuItemOptionName>
      <MenuItemOptionRadio
        isChecked={isChecked}
        onClick={isChecked ? decrement : toggle}
      >
        <span />
      </MenuItemOptionRadio>
    </MenuItemOptionView>
  ) : (
    <MenuItemOptionView>
      <MenuItemOptionName size="small">
        {name}
        <TextBody size="xxsmall">{id}</TextBody>
      </MenuItemOptionName>
      <MenuItemOptionPrice size="small">{price}</MenuItemOptionPrice>
      <MenuItemOptionQuantity>
        <MenuItemQuantity
          quantity={quantity}
          adjust={adjust}
          increaseQuantity={increment}
          decreaseQuantity={decrement}
          incrementDisabled={incrementDisabled}
          decrementDisabled={decrementDisabled}
        />
      </MenuItemOptionQuantity>
      <MenuItemOptionPrice size="small">
        {formatDollars(totalPrice?.toString() || '0.00')}
      </MenuItemOptionPrice>
    </MenuItemOptionView>
  )
}

export default MenuItemOption
