import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { NotificationPreference } from 'types'

export interface NotificationPreferenceState {
  data: NotificationPreference | null
  loading: RequestStatus
  error: RequestError
}

const initialState: NotificationPreferenceState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const addNotificationPreference = createAsyncThunk<
  NotificationPreference,
  { data: NotificationPreference },
  { state: RootState; rejectValue: RequestError }
>(
  'notificationPreference/addNotificationPreference',
  async ({ data }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `brand-notification-preferences`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as NotificationPreference
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchNotificationPreference = createAsyncThunk<
  NotificationPreference,
  {
    notificationPreferenceId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'notificationPreference/fetchNotificationPreference',
  async ({ notificationPreferenceId }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `brand-notification-preferences/${notificationPreferenceId}`
      const resp = (await api?.request(endpoint)) as NotificationPreference
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteNotificationPreference = createAsyncThunk<
  void,
  {
    notificationPreferenceId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'notificationPreference/deleteNotificationPreference',
  async ({ notificationPreferenceId }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `brand-notification-preferences/${notificationPreferenceId}`
      await api?.request(endpoint, 'DELETE')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const notificationPreferenceSlice = createSlice({
  name: 'notificationPreference',
  initialState,
  reducers: {
    resetNotificationPreference: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(addNotificationPreference.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      addNotificationPreference.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      addNotificationPreference.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(fetchNotificationPreference.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchNotificationPreference.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      fetchNotificationPreference.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteNotificationPreference.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteNotificationPreference.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(
      deleteNotificationPreference.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetNotificationPreference } =
  notificationPreferenceSlice.actions

export const selectNotificationPreference = (state: RootState) =>
  state.notificationPreference

export default notificationPreferenceSlice.reducer
