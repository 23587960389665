import { Highlight, Input } from 'components'
import { CampaignChannel } from 'types'
import {
  ChannelTimeSelectContainer,
  ChannelTimeSelectHeader,
  ChannelTimeSelectStatusContainer
} from './ChannelTimeSelect.styled'
import {
  getCampaignChannelLabel,
  getCampaignStatusColor,
  getCampaignChannelIcon
} from 'utils'

interface ChannelTimeSelectProps {
  channel: CampaignChannel
  value: string
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  min?: string
}

const ChannelTimeSelect = ({
  channel,
  value,
  onChange,
  min
}: ChannelTimeSelectProps) => {
  const status = null
  return (
    <ChannelTimeSelectContainer>
      <ChannelTimeSelectHeader>
        {getCampaignChannelIcon(channel)}
        <strong>{getCampaignChannelLabel(channel)}</strong>
        {status && (
          <ChannelTimeSelectStatusContainer>
            <Highlight color={getCampaignStatusColor(status)}>
              {status}
            </Highlight>
          </ChannelTimeSelectStatusContainer>
        )}
      </ChannelTimeSelectHeader>
      <Input
        onChange={onChange}
        value={value}
        name="schedule_date"
        label="Date"
        step={60}
        min={min}
        type="datetime-local"
      />
    </ChannelTimeSelectContainer>
  )
}

export default ChannelTimeSelect
