import { Form } from 'components'
import { FormFieldType, ModalWindow } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import {
  selectCampaignContent,
  testCampaignContent
} from 'slices/campaignContent'
import { showNotification } from 'slices/notification'
import { RequestStatus } from 'utils'

export interface SendTestEmailProps {
  args: {
    data: {
      campaignContentId: number
      recipients: string
    }
    onSubmit: (recipients: SendTestEmailProps['args']['data']) => void
  }
  modalWindow: ModalWindow
}

const CampaignContentTestEmail = ({
  args: { data },
  modalWindow
}: SendTestEmailProps) => {
  const dispatch = useAppDispatch()
  const { testing } = useAppSelector(selectCampaignContent)

  const submit = ({
    recipients,
    campaignContentId
  }: SendTestEmailProps['args']['data']) => {
    const recipientsArray = recipients.split(',').map(r => r.trim())
    dispatch(
      testCampaignContent({
        campaign_content_id: campaignContentId,
        recipients: recipientsArray
      })
    )
      .unwrap()
      .then(() => {
        dispatch(showNotification('Test email sent successfully.'))
        dispatch(closeModal())
      })
      .catch(() => {
        dispatch(showNotification('Failed to send test email.'))
      })
  }

  return (
    <>
      <ModalHeader title="Send Test Email" />
      <Form
        fields={fields}
        data={data}
        isLoading={testing === RequestStatus.Pending}
        submit={submit}
        submitText="Send Test"
      />
    </>
  )
}

const fields: FormFieldType[] = [
  {
    fieldType: 'TextArea',
    type: 'text',
    name: 'recipients',
    label: 'Recipients',
    parenthetical: 'Enter email addresses separated by commas'
  }
]

export default CampaignContentTestEmail
