import React from 'react'
import { X } from 'react-feather'
import styled from '@emotion/styled'
import { useAppDispatch } from 'app/hooks'
import { closeModal } from 'slices/modal'

const ModalCloseView = styled.button`
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  right: -0.5rem;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.text.primary};

  &:hover,
  &:active,
  &:focus {
    opacity: 0.5;
  }
`

const ModalClose = ({ disabled = false }) => {
  const dispatch = useAppDispatch()

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!disabled) dispatch(closeModal())
  }

  return (
    <ModalCloseView className="modal-close" onClick={onClick}>
      <X size={30} />
    </ModalCloseView>
  )
}

export default ModalClose
