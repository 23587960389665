import {
  DateString,
  FirstTime,
  ISOString,
  RequestedAt,
  TimeHuman,
  Weekday
} from '@open-tender/types'
// import { weekdays } from 'config'
import {
  add,
  fromUnixTime,
  isValid,
  lastDayOfMonth,
  parse,
  parseISO,
  startOfWeek,
  sub
} from 'date-fns'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import { ISOStringOffset, TimezoneMap, TimezonePython } from 'types'

export const DATE = 'yyyy-MM-dd'
export const HUMAN_DATE = 'MMM d, yyyy'
export const DATETIME = 'yyyy-MM-dd h:mm a'
export const DATETIME_FULL = 'yyyy-MM-dd h:mm:ss a'
export const HUMAN_DATETIME = 'MMM d, yyyy h:mma'
export const HUMAN_DATETIME_SECONDS = 'MMM dd @ h:mm:ssa'
export const TIME = 'h:mma'
export const HUMAN_DATETIME_SUCCINCT = 'MMM d @ h:mma'

const weekdays: Weekday[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]

export const timezoneMap: TimezoneMap = {
  'US/Eastern': 'America/New_York',
  'US/Central': 'America/Chicago',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles'
}

export const maybeConvertTz = (timezone: TimezonePython) => {
  return timezone in timezoneMap ? timezoneMap[timezone] : timezone
}

export const getTimezoneAbbreviation = (timezone: TimezonePython) => {
  const tz = maybeConvertTz(timezone)
  const date = new Date()
  return format(date, 'z', { timeZone: tz })
}

export const getCurrentWeekday = () => {
  return format(new Date(), 'EEEE').toUpperCase()
}

export const weekdayMinutestoISOString = (
  weekday: Weekday,
  minutes: number
) => {
  const currentWeekday = getCurrentWeekday()
  const index = weekdays.findIndex(i => i === currentWeekday)
  const start = weekdays.slice(index)
  const end = weekdays.slice(0, index)
  const reordered = [...start, ...end]
  const days = reordered.findIndex(i => i === weekday)
  const date = add(new Date(), { days })
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  date.setHours(hours)
  date.setMinutes(mins)
  date.setSeconds(0)
  date.setMilliseconds(0)
  const isoString = date.toISOString().replace('.000Z', 'Z') as ISOString
  return isoString
}

export const zonedDate = (timezone: TimezonePython, days = 0) => {
  const tz = maybeConvertTz(timezone)
  const utc = add(new Date(), { days: days }).toISOString()
  return utcToZonedTime(utc, tz)
}

export const setTimeForDate = (
  date: Date,
  hours: number,
  minutes: number,
  seconds = 0
): Date => {
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  date.setMilliseconds(0)
  return date
}

export const zonedDateMidnight = (timezone: TimezonePython, days = 0) => {
  const date = zonedDate(timezone, days)
  return setTimeForDate(date, 0, 0, 0)
}

// convert '2020-01-31T20:00:00-05:00' (local time of order)
// to '2020-01-31T20:00:00-06:00' (local time of user)
// same local time, different timezone
export const zonedDateStrToDate = (
  dateStr: ISOStringOffset,
  tz: TimezonePython
) => {
  const zonedDate = toDate(dateStr)
  return utcToZonedTime(zonedDate, tz)
}

// see comments on zonedDateStrToDate
export const zonedDateStrToDateStr = (
  dateStr: ISOStringOffset,
  tz: TimezonePython,
  fmt = DATETIME
) => {
  return format(zonedDateStrToDate(dateStr, tz), fmt)
}

export const formatAtDate = (
  dateStr: ISOStringOffset,
  tz: TimezonePython,
  fmt = HUMAN_DATETIME_SUCCINCT
): string => {
  return replaceAmPm(format(zonedDateStrToDate(dateStr, tz), fmt))
}

export const formatDateStr = (
  dateStr: ISOStringOffset,
  fmt = DATETIME_FULL
): string => {
  return format(toDate(dateStr), fmt)
}

export const zonedDateStrToDateRange = (
  dateStr: ISOStringOffset,
  tz: TimezonePython,
  range: number
) => {
  const startDate = zonedDateStrToDate(dateStr, tz)
  const startDateStr = formatAtDate(dateStr, tz)
  if (!range) return startDateStr
  const endDate = add(startDate, { minutes: range })
  const endDateStr = replaceAmPm(format(endDate, 'h:mma'))
  const startLast = startDateStr.slice(-2)
  const endLast = endDateStr.slice(-2)
  if (startLast === endLast) {
    return `${startDateStr.slice(0, -2)}-${endDateStr}`
  }
  return `${startDateStr}-${endDateStr}`
}

export const makeRequestedDate = (requestedAt: RequestedAt) => {
  return requestedAt === 'asap' ? new Date() : parseISO(requestedAt)
}

export const dateToFirstTime = (
  date: Date,
  offset = 0,
  hasAsap = false
): FirstTime => {
  const dt = sub(date, { days: offset })
  return {
    date: format(dt, DATE) as DateString,
    has_asap: hasAsap,
    minutes: dt.getHours() * 60 + date.getMinutes(),
    time: format(dt, 'h:mm a') as TimeHuman,
    utc: dt.toISOString().replace('.000Z', 'Z') as ISOString,
    wait_minutes: null,
    weekday: format(dt, 'EEEE').toUpperCase() as Weekday
  }
}

export const replaceAmPm = (dateStr: string) => {
  return dateStr ? dateStr.replace('AM', 'am').replace('PM', 'pm') : ''
}

export const timestampToDateStr = (
  timestamp: number,
  fmt: string = DATETIME
) => {
  const date = fromUnixTime(timestamp)
  return format(date, fmt)
}

export const time24ToHumanTime = (timeStr: string, fmt = 'h:mm a') => {
  return format(parse(timeStr, 'HH:mm', new Date()), fmt)
}

export const hoursMinutesToTime = (h: number, m: number | string) => {
  return `${h > 12 ? h - 12 : h || 12}:${m.toString().padStart(2, '0')} ${
    h >= 12 ? 'PM' : 'AM'
  }`
}

export const minutesToTime = (minutes: number) => {
  if (!minutes && minutes !== 0) return ''
  const h = Math.floor(minutes / 60)
  const m = (minutes % 60).toString().padStart(2, '0')
  return hoursMinutesToTime(h, m)
}

export const minutesToTime24 = (minutes: number) => {
  if (!minutes && minutes !== 0) return '00:00'
  const hour = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0')
  const minute = (minutes % 60).toString().padStart(2, '0')
  return `${hour}:${minute}`
}

export const time24ToMinutes = (timeStr: string) => {
  const [hours, minutes] = timeStr.split(':')
  return parseInt(hours) * 60 + parseInt(minutes)
}

export const timeToMinutes = (timeStr: string): number => {
  const [hourStr, minuteStr, period] = timeStr.split(/:| /)
  let hour = parseInt(hourStr)
  const minute = parseInt(minuteStr)

  if (period && period.toUpperCase() === 'PM' && hour !== 12) {
    hour += 12
  }

  return hour * 60 + minute
}

export const convertTimestamp = (
  timestamp: string | Date,
  fmt = HUMAN_DATETIME
) => {
  const date = new Date(timestamp)
  const formattedTimestamp = format(date, fmt)
  return replaceAmPm(formattedTimestamp) as string
}

export const getCurrentDate = () => {
  const currentDate = new Date()
  const formattedDate = format(currentDate, DATE)
  return formattedDate
}

export const makeLocalDateStr = (days = 0, fmt = DATE) => {
  return format(add(new Date(), { days: days }), fmt)
}

export const getLocalDatetime = (days = 0, fmt = HUMAN_DATETIME_SECONDS) => {
  return replaceAmPm(format(add(new Date(), { days: days }), fmt))
}

export const formatLongDate = (dateStr: string): string => {
  const date = parseISO(dateStr)
  if (!isValid(date)) {
    return 'Invalid Date'
  }
  const formattedDate = format(date, 'iiii, MMMM do').toUpperCase()
  return formattedDate
}

export const formatShortDate = (dateStr: string): string => {
  const date = parseISO(dateStr)
  if (!isValid(date)) {
    return 'Invalid Date'
  }
  const formattedDate = format(date, 'iii, MMM dd').toUpperCase()
  return formattedDate
}

export const makeDates = () => {
  const date = new Date()

  const today = makeLocalDateStr()
  const tomorrow = makeLocalDateStr(1)
  const firstDateOfMonth = format(date, 'yyyy-MM-01')
  const lastDateOfMonth = format(lastDayOfMonth(date), 'yyyy-MM-dd')
  const firstDateOfLastMonth = format(sub(date, { days: 30 }), 'yyyy-MM-01')
  const lastDateOfLastMonth = format(
    lastDayOfMonth(sub(date, { days: 30 })),
    'yyyy-MM-dd'
  )
  const firstDayOfWeek = format(startOfWeek(date), 'yyyy-MM-dd')
  const lastDayOfWeek = format(
    add(startOfWeek(date), { days: 6 }),
    'yyyy-MM-dd'
  )
  const firstDayOfLastWeek = format(
    startOfWeek(sub(date, { days: 7 })),
    'yyyy-MM-dd'
  )
  const lastDayOfLastWeek = format(
    sub(startOfWeek(date), { days: 1 }),
    'yyyy-MM-dd'
  )

  return [
    { title: 'Today', dates: [today, today] },
    { title: 'Tomorrow', dates: [tomorrow, tomorrow] },
    { title: 'This Month', dates: [firstDateOfMonth, lastDateOfMonth] },
    { title: 'Last Month', dates: [firstDateOfLastMonth, lastDateOfLastMonth] },
    { title: 'This Week', dates: [firstDayOfWeek, lastDayOfWeek] },
    { title: 'Last Week', dates: [firstDayOfLastWeek, lastDayOfLastWeek] }
  ]
}
