import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { StoreThrottle } from 'types'

export interface StoreThrottleApi {
  data: StoreThrottle
  from: {
    store_id: number
  }
}

export interface StoreThrottleState {
  data: StoreThrottle | null
  loading: RequestStatus
  error: RequestError | null
}

const initialState: StoreThrottleState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreThrottle = createAsyncThunk<
  StoreThrottle | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeThrottle/fetchStoreThrottle',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/throttle`
      )) as StoreThrottleApi
      return resp?.data || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreThrottle = createAsyncThunk<
  StoreThrottle,
  { storeId: string | number; data: StoreThrottle },
  { state: RootState; rejectValue: RequestError }
>(
  'storeThrottle/upsertStoreThrottle',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/throttle`,
        'PUT',
        data
      )) as StoreThrottleApi
      dispatch(showNotification('Updated successfully!'))
      return resp.data
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreThrottle = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeThrottle/deleteStoreThrottle',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/settings/throttle`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Order throttling settings removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

const storeThrottleSlice = createSlice({
  name: 'storeThrottle',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreThrottle.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreThrottle.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchStoreThrottle.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreThrottle.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreThrottle.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertStoreThrottle.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreThrottle.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreThrottle.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteStoreThrottle.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStoreThrottle = (state: RootState) => state.storeThrottle

export default storeThrottleSlice.reducer
