import { Files } from './global'
import { Segment } from './segment'

export interface CampaignContentEmailMetadata {
  preview_text: string
  subject: string
  sender_email: string
  sender_name: string
  reply_to: string
}

export interface CampaignContentWebMetadata {
  title?: string
  description?: string
}

export interface CampaignContent
  extends CampaignContentEmailMetadata,
    CampaignContentWebMetadata {
  title: string
  description: string
  body: string
  campaign_content_id: number
  channel: CampaignChannel
  campaign_id: number
  editor_config: string
  is_published: boolean
  is_archived: boolean
  created_at: string
  updated_at: string
  content_type: CampaignContentType
  files: Files
  is_locked: boolean
  is_generic: boolean
}

export type CampaignContentType = 'HTML' | 'TEXT' | 'LINK' | 'ROW'

export interface ICampaignSegment {
  is_excluded: boolean
  use_intersection: boolean
  marketing_segment_id: number
  segment?: Segment
}

export type CampaignSegments = ICampaignSegment[]

export interface CampaignTrigger {
  marketing_trigger_id: number
  campaign_id: number
  channel: CampaignChannel | ''
  created_at: string
  updated_at: string
  is_active: boolean
  start_date?: string | null
  timezone?: string | null
  send_time?: string
  end_date?: string
  event_type?: TriggerEventType
  offset_duration?: number
  offset_timeframe?: TriggerTimeFrame
  limit_messages?: number | null
  limit_timeframe?: TriggerTimeFrame
  limit_duration?: number
}

export type CampaignTriggers = CampaignTrigger[]
export enum TriggerEventType {
  Scheduled = 'SCHEDULED',
  Birthday = 'BIRTHDAY',
  Attrition = 'LAST_ORDER_DATE',
  OrderPlaced = 'ORDER_PLACED'
}
export type CampaignTriggerLimitMessages = 'limit_by_count' | 'limit_by_time'

export enum TriggerTimeFrame {
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Days = 'DAYS',
  Months = 'MONTHS'
}
export type TriggerTimeFrames = 'HOURS' | 'MINUTES' | 'DAYS' | 'MONTHS'

export type CampaignStatus =
  | 'DRAFT'
  | 'SENT'
  | 'SENDING'
  | 'SCHEDULED'
  | 'ACTIVE'
  | 'PAUSED'
  | 'INACTIVE'
  | 'FAILED'

export interface ICampaignIdentity {
  campaign_id: number
}
export interface ICampaignStatusFields {
  campaign_status: CampaignStatus
  is_archived: boolean
  created_at: string
  updated_at: string
  sent_at?: string
}

export type CampaignParams = {
  name: string
  description: string
  campaign_type: CampaignType
  is_template: boolean
  is_archived: boolean
}
export interface ICampaignPerformance {
  sent: number
  soft_bounces: number
  hard_bounces: number
  bounce_rate: number
  delivered: number
  delivery_rate: number
  opens: number
  open_rate: number
  clicks: number
  click_through_rate: number
  unsubscribes: number
  unsubscribe_rate: number
}
export interface ICampaign
  extends ICampaignIdentity,
    ICampaignStatusFields,
    CampaignParams {
  segments: CampaignSegments
  triggers: CampaignTriggers
  campaign_content: CampaignContent[]
}

export type Campaign = ICampaign

export type CampaignPerformance = ICampaignPerformance

export interface CampaignChannelRecipients {
  channel: CampaignChannel
  quantity: number
}

export type CampaignRecipients = CampaignChannelRecipients[]

export const CampaignSteps = [
  'content',
  'audience',
  'schedule',
  'review'
] as const
export type CampaignStep = (typeof CampaignSteps)[number]

export const CampaignChannels = ['EMAIL', 'PUSH', 'SMS', 'WEB'] as const
export type CampaignChannel = (typeof CampaignChannels)[number]

export const CampaignTypes = ['SINGLE_SEND', 'RECURRING', 'TRIGGERED'] as const
export type CampaignType = (typeof CampaignTypes)[number]

export type Campaigns = Campaign[]
