import { useEffect } from 'react'
import {
  DiscountRevenueCenters,
  HouseAccountRevenueCenters,
  RevenueCenters
} from 'types'
import {
  CheckboxItem,
  RevenueCentersGroup,
  RevenueCentersGroupList,
  RevenueCentersGroupTitle,
  RevenueCentersGroups
} from './RevenueCenterTypes.styled'
import { ButtonLinks } from 'components/ButtonLinks'

const RevenueCenterTypes = ({
  values,
  setValues,
  revenueCenters,
  selectedIds,
  updateValues
}: {
  values: DiscountRevenueCenters | null
  setValues: (
    value: DiscountRevenueCenters | HouseAccountRevenueCenters | null
  ) => void
  revenueCenters: RevenueCenters
  selectedIds: number[]
  updateValues: (id: number) => void
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const revenueCenterTypes = [
    ['Online Ordering', 'OLO'],
    ['Catering', 'CATERING'],
    ['Kiosk', 'POS'],
    ['Merch', 'MERCH']
  ]

  const clearAll = () => {
    if (values) {
      setValues([])
    }
  }

  const selectAll = () => {
    const allIds = revenueCenters.map(rc => rc.revenue_center_id)
    const allValues = allIds.map(id => ({ revenue_center_id: id }))
    setValues(allValues)
  }

  return (
    <>
      <ButtonLinks
        clearAll={clearAll}
        selectAll={selectAll}
        disableClearAll={!values || values.length === 0}
        disableSelectAll={values?.length === revenueCenters.length}
      />
      <RevenueCentersGroups>
        {revenueCenterTypes.map(([label, type]) => {
          const filteredRcs = revenueCenters.filter(
            i => i.revenue_center_type === type
          )
          if (filteredRcs.length === 0) return null
          return (
            <RevenueCentersGroup key={type}>
              <RevenueCentersGroupTitle as="div" size="large">
                {label}
              </RevenueCentersGroupTitle>
              <RevenueCentersGroupList>
                {filteredRcs.map(rc => (
                  <CheckboxItem
                    key={rc.revenue_center_id}
                    label={rc.full_name}
                    name={rc.slug}
                    checked={selectedIds.includes(rc.revenue_center_id)}
                    onChange={() => updateValues(rc.revenue_center_id)}
                  />
                ))}
              </RevenueCentersGroupList>
            </RevenueCentersGroup>
          )
        })}
      </RevenueCentersGroups>
    </>
  )
}

export default RevenueCenterTypes
