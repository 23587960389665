import styled from '@emotion/styled'
import TextBody from './TextBody'

const InfoItemView = styled.div`
  margin: 0 0 3rem;
`

const InfoItemLabel = styled.div`
  display: block;
  margin: 0 0 1rem;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.sizes.small};
`

const InfoItemValue = styled(TextBody)``

const InfoItem = ({
  label,
  children
}: {
  label: string
  children: React.ReactNode
}) => {
  return (
    <InfoItemView>
      <InfoItemLabel>
        <strong>{label}</strong>
      </InfoItemLabel>
      <InfoItemValue as="p">{children}</InfoItemValue>
    </InfoItemView>
  )
}

export default InfoItem
