import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Item, ModalWindow } from 'types'
import { ButtonGroup, ButtonSubmit, ErrMsg, Input, Loader } from 'components'
import { ModalHeader } from 'components/Modal'
import { RequestStatus, makeErrMsg, sortBy } from 'utils'
import { closeModal } from 'slices/modal'
import { fetchItems, selectItems } from 'slices/items'
import styled from '@emotion/styled'
import { Xmark } from 'iconoir-react'
import ScrollHide from 'components/ScrollHide'

const ItemSearchView = styled.div`
  margin: 0 0 3rem;
`

const ItemSearchSelectedItems = styled.div`
  margin: 0 0 3rem;
  display: flex;
  alignitems: center;
  flex-wrap: wrap;
  gap: 1rem;
`

const ItemSearchSelectedItem = styled.button`
  padding: 0.5rem 0.7rem 0.5rem 1rem;
  border: 0;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  line-height: 0;
  font-size: ${props => props.theme.sizes.small};
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.tertiary};

  &:hover {
    background-color: ${props => props.theme.colors.error.background};
  }
`

const ItemSearchSearchResults = styled(ScrollHide)`
  max-height: 22.5rem;
  background-color: ${props => props.theme.colors.background.secondary};
`

const ItemSearch = ({
  args,
  modalWindow
}: {
  args: {
    selectItems: (items: Item[]) => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data, loading, error } = useAppSelector(selectItems)
  const isLoading = loading === RequestStatus.Pending
  const errMsg = makeErrMsg(error)
  const [search, setSearch] = useState<string>('')
  const [selected, setSelected] = useState<Item[]>([])
  const filtered = search
    ? data.filter(
        i =>
          i.full_name.toLowerCase().includes(search.toLowerCase()) ||
          i.short_name.toLowerCase().includes(search.toLowerCase())
      )
    : data
  const items = filtered.map(i => {
    // const categories = i.categories?.map(c => c.short_name)
    return { ...i, isItem: i.categories?.length ? true : false }
  })
  const sorted = sortBy(items, 'short_name')

  const onSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value)
  }

  const addItem = (item: Item) => {
    const exists = selected.find(i => i.item_id === item.item_id)
    if (exists) return
    setSelected([...selected, item])
  }

  const removeItem = (itemId: number) => {
    setSelected(selected.filter(i => i.item_id !== itemId))
  }

  const submit = () => {
    args.selectItems(selected)
    dispatch(closeModal())
  }

  useEffect(() => {
    dispatch(fetchItems({ is_active: true, limit: 1000 }))
  }, [dispatch])

  return (
    <div>
      <ModalHeader
        title="Find items"
        subtitle="Start typing to filter list and click on an item to add it to the list."
      />
      <ErrMsg errMsg={errMsg} />
      <Input
        type="text"
        name="search"
        fieldType="Input"
        label=""
        value={search}
        onChange={onSearchChange}
        placeholder="enter part of search"
      />
      {isLoading ? (
        <Loader text="Retrieving items..." />
      ) : sorted ? (
        <ItemSearchView>
          {selected.length > 0 && (
            <ItemSearchSelectedItems>
              {selected.map(i => (
                <ItemSearchSelectedItem
                  key={i.item_id}
                  onClick={() => removeItem(i.item_id)}
                >
                  <span>{i.short_name}</span>
                  <span>
                    <Xmark
                      width="1.2rem"
                      height="1.2rem"
                      strokeWidth="0.2rem"
                    />
                  </span>
                </ItemSearchSelectedItem>
              ))}
            </ItemSearchSelectedItems>
          )}
          <ItemSearchSearchResults>
            <ButtonGroup>
              {sorted.map(item => (
                <button key={item.item_id} onClick={() => addItem(item)}>
                  <span>
                    {item.short_name}
                    {/* ({item.isItem ? 'item' : 'modifier'}) */}
                  </span>
                </button>
              ))}
            </ButtonGroup>
          </ItemSearchSearchResults>
        </ItemSearchView>
      ) : null}
      <ButtonSubmit text="Submit" color="primary" onClick={submit} />
    </div>
  )
}

export default ItemSearch
