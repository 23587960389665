import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import {
  associateAnnouncement,
  fetchAnnouncementPage,
  selectAnnouncementPage
} from 'slices/announcementPage'
import { fetchAnnouncements, selectAnnouncements } from 'slices/announcements'

const PageAnnouncement = ({
  args,
  modalWindow
}: {
  args: { announcementPageId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { announcementPageId } = args
  const { data, loading, error } = useAppSelector(selectAnnouncementPage)
  const announcements = useAppSelector(selectAnnouncements)
  const current =
    data?.announcements &&
    data?.announcements.map(i => i.announcement.announcement_id)
  const filtered =
    current &&
    announcements.data.filter(i => !current.includes(i.announcement_id))
  const isLoading =
    loading === RequestStatus.Pending ||
    announcements.loading === RequestStatus.Pending

  const announcementOptions = useMemo(() => {
    return filtered!.map(i => {
      return {
        value: i.announcement_id,
        text: i.title
      }
    })
  }, [filtered])

  const submit = async (values: { announcement_id: number }) => {
    if (announcementPageId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(associateAnnouncement({ announcementPageId, data }))
      const params = { with_related: 'true' }
      dispatch(fetchAnnouncementPage({ announcementPageId, params }))
    }
  }

  useEffect(() => {
    dispatch(fetchAnnouncements())
  }, [dispatch])

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add a new Announcement"
        subtitle="Choose an announcement from the options below"
      />
      <Form
        fields={pageAnnouncementFields(announcementOptions)}
        data={pageAnnouncementsEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const pageAnnouncementsEmpty = {
  announcement_id: null
}

const pageAnnouncementFields = (
  announcementOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'announcement_id',
    options: announcementOptions,
    label: 'Announcement',
    error: 'Please select an announcement'
  }
]

export default PageAnnouncement
