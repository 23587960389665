import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Invoices } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface HouseAccountInvoicesState {
  data: Invoices
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface HouseAccountInvoicesAPI {
  data: Invoices
  links: {
    next: string
  }
}

export type HouseAccountInvoicesParams = {
  limit?: number
  cursor?: string
  is_paid?: boolean
  house_account_id?: number
  expand?: string
}

const initialState: HouseAccountInvoicesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchHouseAccountInvoices = createAsyncThunk<
  HouseAccountInvoicesAPI,
  HouseAccountInvoicesParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountInvoices/fetchHouseAccountInvoices',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `invoices${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as HouseAccountInvoicesAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountInvoicesSlice = createSlice({
  name: 'houseAccountInvoices',
  initialState,
  reducers: {
    resetHouseAccountInvoices: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchHouseAccountInvoices.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchHouseAccountInvoices.fulfilled,
      (state, { payload }) => {
        state.data = payload.data
        state.links = payload.links
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchHouseAccountInvoices.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetHouseAccountInvoices } = houseAccountInvoicesSlice.actions

export const selectHouseAccountInvoices = (state: RootState) =>
  state.houseAccountInvoices

export default houseAccountInvoicesSlice.reducer
