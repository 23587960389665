import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalWindow, SearchResult } from 'types'
import { closeModal } from 'slices/modal'
import { ModalHeader } from 'components/Modal'
import { SearchResults } from 'components'
import {
  fetchCustomers,
  resetCustomers,
  selectCustomers
} from 'slices/customers'
import { RequestStatus } from 'utils'

const CustomerSearch = ({
  args,
  modalWindow
}: {
  args: {
    title?: string
    subtitle?: string
    chooseCustomer: (customer_id: string | number) => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { data, loading } = useAppSelector(selectCustomers)
  const isLoading = loading === RequestStatus.Pending
  const { title, subtitle, chooseCustomer } = args

  const searchResults: SearchResult[] = data.map(customer => ({
    id: customer.customer_id,
    name: `${customer.first_name} ${customer.last_name} (${customer.email})`
  }))

  const handleCustomer = (searchResult: SearchResult) => {
    if (searchResult.id) {
      chooseCustomer(searchResult.id)
      dispatch(closeModal())
    }
  }

  useEffect(() => {
    dispatch(resetCustomers())
  }, [dispatch])

  const handleSearchCustomers = (search: string) => {
    const params = {
      search: search
    }
    dispatch(fetchCustomers(params))
  }

  return (
    <>
      <ModalHeader
        title={title || 'Customer'}
        subtitle={
          subtitle ||
          'Start typing & hit enter to search your database of pre-existing customers.'
        }
      />
      <SearchResults
        isLoading={isLoading}
        searchResults={searchResults}
        handleSearch={handleSearchCustomers}
        handleSelect={handleCustomer}
      />
    </>
  )
}

export default CustomerSearch
