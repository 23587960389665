import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  createFile,
  selectFiles,
  deleteFile as deleteFileAction
} from 'slices/files'
import { FileType, ProcessingState } from 'types'
import { last } from 'utils'
import DropZone from './DropZone'
import { UploadDetails, UploadLabel, UploadView } from './Files.styled'
import { OpenNewWindow } from 'iconoir-react'

interface UploadProps {
  name: string
  endpoint: string
  fileObj?: FileType
  fileType: string
  hideDelete?: boolean
  displayType?: 'DEFAULT' | 'SMALL'
}

const Upload = ({
  name,
  endpoint,
  fileObj,
  fileType,
  hideDelete = false,
  displayType = 'DEFAULT'
}: UploadProps) => {
  const dispatch = useAppDispatch()
  const { percentage, state, processingFileType } = useAppSelector(selectFiles)
  const filename = fileObj ? (last(fileObj.file_url.split('/')) as string) : ''

  const deleteFile = () => {
    if (fileObj)
      dispatch(
        deleteFileAction({
          endpoint,
          fileId: fileObj?.file_id,
          fileType
        })
      )
  }

  const uploadFile = (file: File | null | undefined) => {
    dispatch(createFile({ file, endpoint, fileType }))
  }

  return (
    <UploadView displayType={displayType}>
      <UploadDetails displayType={displayType}>
        <UploadLabel displayType={displayType}>
          <strong>{name}</strong>
        </UploadLabel>
        {fileObj && (
          <p>
            <a
              href={fileObj.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {displayType === 'SMALL' ? (
                <OpenNewWindow width={14} height={14} />
              ) : (
                filename
              )}
            </a>
            {!hideDelete && <button onClick={deleteFile}>delete</button>}
          </p>
        )}
      </UploadDetails>
      <DropZone
        name={name}
        onFilesAdded={uploadFile}
        isLoading={state === ProcessingState.Processing}
        file={fileObj}
        fileType={fileType}
        deleteFile={deleteFile}
        progress={{
          percentage,
          state
        }}
        processingFileType={processingFileType}
      />
    </UploadView>
  )
}

export default Upload
