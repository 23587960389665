import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { CreateBrand } from 'types'
import { showNotification } from 'slices/notification'

export interface CreateBrandState {
  loading: RequestStatus
  data: CreateBrand | null
  error: RequestError
}

const initialState: CreateBrandState = {
  loading: RequestStatus.Idle,
  data: null,
  error: null
}

export const createBrand = createAsyncThunk<
  CreateBrand,
  { data: CreateBrand },
  { state: RootState; rejectValue: RequestError }
>(
  'super/createBrand',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'super/brands',
        'POST',
        data
      )) as CreateBrand
      dispatch(showNotification('Brand successfully created!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createBrandSlice = createSlice({
  name: 'createBrand',
  initialState,
  reducers: {
    resetCreateBrand: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createBrand.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createBrand.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(createBrand.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCreateBrand } = createBrandSlice.actions

export const selectCreateBrand = (state: RootState) => state.createBrand

export default createBrandSlice.reducer
