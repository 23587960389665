import styled from '@emotion/styled'

export const ColumnsView = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`

export const ColumnsColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  border-style: solid;
  border-color: ${props => props.theme.inputs.borderColor};
  border-width: ${props => props.theme.inputs.borderWidth};
  border-radius: ${props => props.theme.inputs.borderRadius};

  & > span {
    font-size: ${props => props.theme.sizes.large};
    flex: 0;
    padding: 1.2rem 1rem;
    border-style: solid;
    border-width: 0;
    border-bottom-width: ${props => props.theme.layout.desktop.border};
    border-color: ${props => props.theme.colors.border.primary};
  }
`

export const ColumnsItems = styled.div`
  flex: 1;
  width: 100%;
  padding: 1rem 1rem 0;
  transition: background-color ease 0.15s;
  background-color: ${props => props.theme.colors.background.primary};

  &.-active {
    background-color: ${props => props.theme.colors.background.tertiary};
  }
`

export const ColumnsItem = styled.div`
  top: auto !important;
  left: auto !important;
  user-select: none;
  padding: ${props => props.theme.inputs.padding};
  margin: 0 0 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.inputs.fontSize};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  font-variation-settings: 'wght' ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  line-height: ${props => props.theme.inputs.lineHeight};
  border-style: solid;
  border-color: ${props => props.theme.inputs.borderColor};
  border-width: ${props => props.theme.inputs.borderWidth};
  border-radius: ${props => props.theme.inputs.borderRadius};
  color: ${props => props.theme.inputs.color};
  background-color: ${props => props.theme.colors.background.secondary};
  // background-color: inherit;
  // transition: background-color ease 0.15s;

  // &.-active {
  //   background-color: ${props => props.theme.colors.background.secondary};
  // }
`
