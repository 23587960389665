import { NavLink } from 'react-router-dom'
import { NavCardProps } from 'types'
import { ArrowRight } from 'iconoir-react'
import TextHeading from 'components/TextHeading'
import NavCardAlert from './NavCardAlert'
import {
  NavCardContent,
  NavCardCta,
  NavCardCtaText,
  NavCardDescription,
  NavCardFooter,
  NavCardHeader,
  NavCardIcon,
  NavCardTitle,
  NavCardView
} from './NavCards.styled'

const NavCard = ({
  path,
  title,
  description,
  icon,
  cta,
  alert
}: NavCardProps) => {
  return (
    <NavCardView className="fadeUp">
      {alert && <NavCardAlert {...alert} />}
      <NavLink to={path}>
        <NavCardContent>
          <NavCardHeader>
            <NavCardTitle>
              {icon && <NavCardIcon>{icon}</NavCardIcon>}
              <TextHeading size="xlarge">{title}</TextHeading>
            </NavCardTitle>
          </NavCardHeader>
          <NavCardDescription as="p" size="small">
            {description}
          </NavCardDescription>
        </NavCardContent>
        <NavCardFooter>
          <NavCardCta>
            <NavCardCtaText size="small">
              <strong>{cta || 'Update'}</strong>
            </NavCardCtaText>
            <ArrowRight width={14} height={14} strokeWidth={2.5} />
          </NavCardCta>
        </NavCardFooter>
      </NavLink>
    </NavCardView>
  )
}

export default NavCard
