import styled from '@emotion/styled'
import { ThemeFontSizes } from 'types'

interface TextStatusViewProps {
  isActive: boolean
  fontSize?: keyof ThemeFontSizes
}

export const TextStatusView = styled.span<TextStatusViewProps>`
  display: inline-block;
  padding: 0.4rem 1rem 0.5rem;
  margin: -0.4rem 0 -0.5rem;
  line-height: 1;
  font-size: ${props => props.theme.sizes[props.fontSize || 'small']};
  border-radius: ${props => props.theme.borderRadius.small};
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.tertiary};
  opacity: ${props => (props.isActive ? '1' : '0.5')};
`

const TextStatus = ({
  isActive,
  fontSize = 'small',
  children
}: {
  isActive: boolean
  fontSize?: keyof ThemeFontSizes
  children?: React.ReactNode
}) => {
  return (
    <TextStatusView isActive={isActive} fontSize={fontSize}>
      {children || (isActive ? 'Active' : 'Inactive')}
    </TextStatusView>
  )
}

export default TextStatus
