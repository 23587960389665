import { useTheme } from '@emotion/react'
import { useAppDispatch } from 'app/hooks'
import TextBody from 'components/TextBody'
import { useImage } from 'hooks'
import { ClipLoader } from 'react-spinners'
import { openModal } from 'slices/modal'
import { CheckmateItem } from 'types'
import {
  CheckmateMenuItemImage,
  CheckmateMenuItemImageCount,
  CheckmateMenuItemImageError,
  CheckmateMenuItemInfo,
  CheckmateMenuItemView
} from './CheckmateMenu.styled'
import CheckmateMenuItemSummary from './CheckmateMenuItemSummary'

interface CheckmateMenuItemProps {
  item: CheckmateItem
}

const CheckmateMenuItem = ({ item }: CheckmateMenuItemProps) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const imageUrls = item.image_urls?.map(i => i.link)
  const [imageUrl, ...otherImageUrls] = imageUrls || []
  const otherImageCount = otherImageUrls ? otherImageUrls.length : 0
  const { hasLoaded, hasError } = useImage(imageUrl)

  const showDetails = () => {
    const args = { item }
    dispatch(openModal({ type: 'checkmateItem', align: 'top', args }))
  }

  return (
    <CheckmateMenuItemView onClick={showDetails}>
      <CheckmateMenuItemInfo>
        <CheckmateMenuItemSummary item={item} />
      </CheckmateMenuItemInfo>
      {imageUrl && (
        <CheckmateMenuItemImage>
          {hasError ? (
            <CheckmateMenuItemImageError>
              <TextBody size="small">Image URL not reachable</TextBody>
            </CheckmateMenuItemImageError>
          ) : hasLoaded ? (
            <img src={imageUrl} alt={item.name} />
          ) : (
            <ClipLoader size={16} color={theme.colors.text.primary} />
          )}
          {otherImageCount ? (
            <CheckmateMenuItemImageCount>
              <span>{otherImageCount}</span>
            </CheckmateMenuItemImageCount>
          ) : null}
        </CheckmateMenuItemImage>
      )}
    </CheckmateMenuItemView>
  )
}

export default CheckmateMenuItem
