import styled from '@emotion/styled'

export const DesignFormView = styled.div`
  label: DesignFormView;
  padding: 0;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
`

interface DesignFormPortalProps {
  previewHeight?: number
}

export const DesignFormPortal = styled.div<DesignFormPortalProps>`
  label: DesignFormPortal;
  flex-grow: 1;
  overflow: hidden;
`

export const DesignFormContent = styled.div`
  label: DesignFormContent;
  flex: 0 0 32rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const DesignFormHeader = styled.div`
  label: DesignFormHeader;
  flex-grow: 0;
  padding: 0 0 0.5rem;
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
  margin: 0 0 1rem;
`

export const DesignFormInputs = styled.div`
  label: DesignFormInputs;
  flex-grow: 1;
  // overflow-y: scroll;
`
