import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalContent } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { selectOrder } from 'slices/order'
import Loader from 'components/Loader'

const OrderSubmitting = () => {
  const dispatch = useAppDispatch()
  const { submitting } = useAppSelector(selectOrder)

  useEffect(() => {
    if (!submitting) {
      dispatch(closeModal())
    }
  }, [dispatch, submitting])

  return (
    <>
      <ModalContent>
        <Loader text="Working. Please sit tight..." />
      </ModalContent>
    </>
  )
}

export default OrderSubmitting
