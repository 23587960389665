import { useAppDispatch } from 'app/hooks'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { resetOrder } from 'slices/order'
import Button from 'components/Button'

const OrderOrderType = ({ args }: { args: null }) => {
  const dispatch = useAppDispatch()

  const change = () => {
    dispatch(resetOrder())
    dispatch(closeModal())
  }

  const cancel = () => dispatch(closeModal())

  return (
    <>
      <ModalHeader title="Change order type" />
      <ModalContent>
        <p>
          Changing your order type requires resetting your order and starting
          from scratch.
        </p>
        <p>
          <strong>
            Are you sure you want to change your current service type?
          </strong>
        </p>
      </ModalContent>
      <ModalFooter>
        <Button color="delete" onClick={change}>
          Change Order Type
        </Button>
        <Button color="secondary" onClick={cancel}>
          Keep Current
        </Button>
      </ModalFooter>
      {/* <ModalCancel>Nevermind, keep current order type</ModalCancel> */}
    </>
  )
}

export default OrderOrderType
