import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Segments } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { RootState } from 'app/store'

export interface SegmentsState {
  data: Segments
  templates: Segments
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface SegmentsAPI {
  data: Segments
  links: {
    next: string
  }
}

const initialState: SegmentsState = {
  data: [],
  templates: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type FetchSegmentsParams = {
  search?: string
  'sort-by'?: string
  'sort-direction'?: string
  is_archived?: string
  limit?: number
}

export const fetchSegments = createAsyncThunk<
  SegmentsAPI,
  FetchSegmentsParams | undefined,
  { state: RootState; rejectValue: RequestError }
>('segments/fetchSegments', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `segments${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as SegmentsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    resetSegments: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSegments.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(fetchSegments.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Succeeded
        state.data = payload.data
        state.templates = payload.data.filter(segment => segment.is_template)
        state.links = payload.links
        state.error = null
      })
      .addCase(fetchSegments.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      })
  }
})

export const { resetSegments } = segmentsSlice.actions

export const selectSegments = (state: RootState) => state.segments

export default segmentsSlice.reducer
