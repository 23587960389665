import { useAppDispatch } from 'app/hooks'
import { Button, TextBody } from 'components'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { ModalWindow } from 'types'

const DraftCampaignConfirmation = ({
  args
}: {
  args: {
    confirmationMessage: string
    handleConfirmation: () => void
  }
  modalWindow: ModalWindow
}) => {
  const { confirmationMessage, handleConfirmation } = args
  const dispatch = useAppDispatch()

  const handleConfirm = () => {
    handleConfirmation()
    dispatch(closeModal())
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Confirmation" />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        {confirmationMessage}
      </TextBody>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" size="medium" onClick={handleConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  )
}

export default DraftCampaignConfirmation
