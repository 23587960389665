import styled from '@emotion/styled'
import { NavArrowLeft, NavArrowRight } from 'iconoir-react'
import { Button } from 'components'
import { ThemeButtonColors } from 'types'

export const BackNextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
interface buttonProps {
  onClick?: () => void
  text?: string
  showArrow?: boolean
  color?: keyof ThemeButtonColors
  disabled?: boolean
}
interface BackNextButtonsProps {
  backButtonProps?: buttonProps
  nextButtonProps?: buttonProps
}

const BackNextButtons = ({
  backButtonProps,
  nextButtonProps
}: BackNextButtonsProps) => {
  const {
    onClick: onBack,
    text: backText = 'Back',
    showArrow: showBackArrow = true,
    color: backColor = 'primary',
    disabled: backDisabled = false
  } = backButtonProps || {}
  const {
    onClick: onNext,
    text: nextText = 'Next',
    showArrow: showNextArrow = true,
    color = 'primary',
    disabled: nextDisabled = false
  } = nextButtonProps || {}
  return (
    <BackNextContainer>
      <Button onClick={onBack} disabled={backDisabled} color={backColor}>
        {showBackArrow && <NavArrowLeft />}
        {backText}
      </Button>

      {onNext ? (
        <Button onClick={onNext} color={color} disabled={nextDisabled}>
          {nextText}
          {showNextArrow && <NavArrowRight />}
        </Button>
      ) : null}
    </BackNextContainer>
  )
}

export default BackNextButtons
