import { Xmark } from 'iconoir-react'
import { Button } from 'components'
import {
  OrderTotalsSubItemView,
  OrderTotalsItemLabel,
  OrderTotalsItemValue
} from './OrderTotalsSubItem.styled'

const OrderTotalsSubItem = ({
  label,
  value,
  onDelete
}: {
  label: string | number | React.ReactNode
  value: string
  onDelete?: () => void
}) => {
  return (
    <OrderTotalsSubItemView>
      <OrderTotalsItemLabel>{label}</OrderTotalsItemLabel>
      {onDelete && (
        <Button color="primary" size="xsmall" onClick={onDelete}>
          <Xmark width={12} height={12} />
        </Button>
      )}
      <OrderTotalsItemValue>{value}</OrderTotalsItemValue>
    </OrderTotalsSubItemView>
  )
}

export default OrderTotalsSubItem
