import React, { Children } from 'react'
import { FiltersItem, FiltersView } from './Filters.styled'

const Filters = ({ children }: React.ComponentProps<'div'>) => {
  const arrayChildren = Children.toArray(children)
  return (
    <FiltersView>
      {Children.map(arrayChildren, child => (
        <FiltersItem>{child}</FiltersItem>
      ))}
    </FiltersView>
  )
}

export default Filters
