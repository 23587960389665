import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { DoorDash } from 'types'
import { showNotification } from 'slices/notification'

export interface DoorDashState {
  data: DoorDash | null
  loading: RequestStatus
  error: RequestError
}

const initialState: DoorDashState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchDoorDash = createAsyncThunk<
  DoorDash,
  void,
  { state: RootState; rejectValue: RequestError }
>('doordash/fetchDoorDash', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('integrations/doordash')) as DoorDash
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertDoorDash = createAsyncThunk<
  DoorDash,
  { data: DoorDash },
  { state: RootState; rejectValue: RequestError }
>(
  'doordash/upsertDoorDash',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'integrations/doordash',
        'PUT',
        data
      )) as DoorDash
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const doordashSlice = createSlice({
  name: 'doordash',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDoorDash.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchDoorDash.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchDoorDash.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertDoorDash.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertDoorDash.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(upsertDoorDash.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectDoorDash = (state: RootState) => state.doordash

export default doordashSlice.reducer
