import TextBody from 'components/TextBody'
import { CheckmateHours, WeekdayLower } from 'types'
import { capitalize } from 'utils'
import { time24ToHumanTime } from 'utils/datetimes'
import {
  CheckmateMenuHoursView,
  CheckmateMenuHoursWeekday,
  CheckmateMenuInfoSectionHeader
} from './CheckmateMenu.styled'
import TextHeading from 'components/TextHeading'

interface CheckmateMenuHoursProps {
  hours: CheckmateHours
}

const weekdays: WeekdayLower[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

const CheckmateMenuHours = ({ hours }: CheckmateMenuHoursProps) => {
  if (!hours) return null
  return (
    <CheckmateMenuHoursView>
      <CheckmateMenuInfoSectionHeader>
        <TextHeading size="large">Regular Hours</TextHeading>
      </CheckmateMenuInfoSectionHeader>
      {weekdays.map(weekday => {
        const weekdayHours = hours[weekday]
        const hoursStr = weekdayHours
          ? weekdayHours
              .map(
                i =>
                  `${time24ToHumanTime(i.start_time)} - ${time24ToHumanTime(
                    i.end_time
                  )}`
              )
              .join(', ')
          : 'Closed'
        return (
          <CheckmateMenuHoursWeekday key={weekday}>
            <TextBody size="small">{capitalize(weekday)}</TextBody>
            <TextBody size="small">{hoursStr}</TextBody>
          </CheckmateMenuHoursWeekday>
        )
      })}
    </CheckmateMenuHoursView>
  )
}

export default CheckmateMenuHours
