import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  CsvAPI,
  HouseAccountOrders,
  OrderStatus,
  OrderType,
  TenderStatus
} from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface HouseAccountOrdersState {
  data: HouseAccountOrders
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: HouseAccountOrdersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface HouseAccountOrdersAPI {
  data: HouseAccountOrders
  links: {
    next: string
  }
}

export type HouseAccountOrdersParams = {
  cursor?: string
  limit?: number
  start_date?: string
  end_date?: string
  order_type?: OrderType
  order_status?: OrderStatus
  tender_status?: TenderStatus
}

export const fetchHouseAccountOrders = createAsyncThunk<
  HouseAccountOrdersAPI,
  {
    houseAccountId: string | number
    params?: HouseAccountOrdersParams | void
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountOrders/fetchHouseAccountOrders',
  async ({ houseAccountId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `house-accounts/${houseAccountId}/orders${queryParams}`
      const resp = (await api?.request(endpoint)) as HouseAccountOrdersAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountOrdersCSV = createAsyncThunk<
  CsvAPI,
  {
    houseAccountId: string | number
    params?: HouseAccountOrdersParams | void
  },
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccountOrders/houseAccountOrdersCSV',
  async ({ houseAccountId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `house-accounts/${houseAccountId}/orders/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as CsvAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountOrdersSlice = createSlice({
  name: 'houseAccountOrders',
  initialState,
  reducers: {
    resetHouseAccountOrders: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchHouseAccountOrders.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchHouseAccountOrders.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.links = payload.links
      state.data = payload.data
      state.error = null
    })
    builder.addCase(fetchHouseAccountOrders.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHouseAccountOrders } = houseAccountOrdersSlice.actions

export const selectHouseAccountOrders = (state: RootState) =>
  state.houseAccountOrders

export default houseAccountOrdersSlice.reducer
