import { Form } from 'components'
import { FormFieldType, ModalWindow, SavedContent } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

export interface SavedRowSettingsProps {
  readOnly?: boolean
  value: Pick<SavedContent, 'title' | 'subject' | 'preview_text'>
  onSubmit: (
    value: Pick<SavedContent, 'title' | 'subject' | 'preview_text'>
  ) => void
  modalWindow: ModalWindow
}

const SavedRowSettings = ({
  args: { readOnly = false, value, onSubmit }
}: {
  args: SavedRowSettingsProps
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()

  const submit = (
    value: Pick<SavedContent, 'title' | 'subject' | 'preview_text'>
  ) => {
    if (!readOnly) onSubmit(value)
    dispatch(closeModal())
  }

  const formFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      name: 'preview_text',
      label: 'Title',
      placeholder: value.title,
      disabled: readOnly
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'subject',
      label: 'Category',
      placeholder: 'Saved rows',
      disabled: readOnly
    }
  ]

  return (
    <>
      <ModalHeader title="Saved Row Settings" />
      <Form
        fields={formFields}
        data={value}
        isLoading={false}
        submit={submit}
        submitText={readOnly ? 'Close' : 'Submit'}
      />
    </>
  )
}

export default SavedRowSettings
