import { FormFieldType, AccountSections, FieldType, FieldConfig } from 'types'
import { capitalize } from 'utils'

export const makePagesFields = <T extends Record<string, {}>>(
  sections: T,
  labelMap: Record<keyof T, string>,
  config: Record<keyof T, FieldConfig[]>,
  subtitleMap?: Record<keyof T, string>
): { data: Record<string, string>; fields: FormFieldType[] } => {
  let data: Record<string, string> = {}
  const fields = Object.entries(sections).reduce((arr, [key, section]) => {
    const sectionFields = (config[key as keyof T] as FieldConfig[]).reduce(
      (sectionArr: FormFieldType[], fieldConfig) => {
        const { fieldName, required, fieldType, label } = fieldConfig
        const value = (section as Record<string, unknown>)[fieldName] as string
        const keyFieldName = `${key}.${fieldName}`
        data = { ...data, [keyFieldName]: value }
        const formField = {
          fieldType: fieldType
            ? (fieldType as FieldType)
            : ('Input' as FieldType),
          type: 'text',
          name: keyFieldName,
          required: required ? required : false,
          label: label ? label : capitalize(fieldName),
          error: `Please enter a ${keyFieldName}`
        }
        return [...sectionArr, formField]
      },
      []
    )

    const legend = {
      fieldType: 'Legend' as FieldType,
      label: labelMap[key as keyof T],
      subtitle: subtitleMap?.[key as keyof T],
      name: key
    }
    return [...arr, legend, ...sectionFields]
  }, [] as FormFieldType[])
  return { data, fields }
}

export const expandPagesValues = <T extends Record<string, {}>>(
  values: Record<string, string>
) => {
  const sections = Object.entries(values).reduce((obj, [field, value]) => {
    const [key, name] = field.split('.')
    const current = obj[key as keyof AccountSections] || {}
    const section = { ...current, [name]: value }
    return { ...obj, [key]: section }
  }, {} as T)
  return sections
}
