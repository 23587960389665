import { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal, selectModal } from 'slices/modal'
import { Loader } from 'components'
import { alignModalTypes } from './ModalTheme'
import ModalView from './ModalView'
import ModalOverlay from './ModalOverlay'
import ModalType from './ModalType'
import ModalContainer from './ModalContainer'
import ModalClose from './ModalClose'

const makeModalStyle = (modalType: string) => {
  switch (modalType) {
    case 'apiDetail':
      return { width: '100%', maxWidth: '128rem' }
    case 'checkmateItem':
      return { width: '96rem' }
    case 'onboardingWelcome':
      return { width: '120rem', maxWidth: '100%' }
    case 'orderMenuItem':
      return { width: '58rem' }
    case 'orderSubmitting':
      return { width: '32rem' }
    case 'orderRevenueCenter':
      return { width: '64rem' }
    case 'previewCategory':
      return { width: '96rem' }
    case 'previewItem':
      return { width: '100%', maxWidth: '128rem' }
    case 'previewModifierGroup':
      return { width: '96rem' }
    case 'refreshSession':
      return { width: '42rem' }
    default:
      return {}
  }
}

const Modal = () => {
  const modalRef = useRef<HTMLDivElement>(null)
  const modalWindowRef = useRef<HTMLDivElement & HTMLButtonElement>(null)
  const dispatch = useAppDispatch()
  const { type, align, loading, disableClose, args } =
    useAppSelector(selectModal)
  const showModal = !!type
  const style = type ? makeModalStyle(type) : {}
  const [initialMouseDownInside, setInitialMouseDownInside] = useState(false)
  const [elements, setElements] = useState<NodeListOf<Element> | []>([])
  const alignItems = align ? alignModalTypes[align] : 'center'

  const handleTabKey = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.keyCode === 9 && modalRef.current && elements.length) {
        const activeElements = Array.from(elements).filter(
          (i: any) => !i.disabled
        )
        const firstElement = activeElements[0] as HTMLElement
        const lastElement = activeElements[
          activeElements.length - 1
        ] as HTMLElement

        if (!evt.shiftKey && document.activeElement === lastElement) {
          firstElement.focus()
          evt.preventDefault()
        }

        if (evt.shiftKey && document.activeElement === firstElement) {
          lastElement.focus()
          evt.preventDefault()
        }
      } else if (evt.keyCode === 9) {
        evt.preventDefault()
      }
    },
    [elements]
  )

  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (modalRef.current?.contains(e.target as Node)) {
      setInitialMouseDownInside(true)
    } else {
      setInitialMouseDownInside(false)
    }
  }, [])

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      if (
        !disableClose &&
        !initialMouseDownInside &&
        !modalRef.current?.contains(e.target as Node)
      ) {
        dispatch(closeModal())
      }
    },
    [dispatch, disableClose, initialMouseDownInside]
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!disableClose && e.key === 'Escape') {
        dispatch(closeModal())
      }
      handleTabKey(e)
    },
    [handleTabKey, dispatch, disableClose]
  )

  const handleFocus = useCallback(() => {
    if (modalRef.current) {
      const allElements = modalRef.current.querySelectorAll(
        'input, select, textarea, button'
      )
      setElements(allElements)
      const firstElement = allElements[0] as HTMLElement
      const isClose =
        firstElement && firstElement.classList.contains('modal-close')
      const focusElement = isClose
        ? (allElements[1] as HTMLElement)
        : firstElement
      if (focusElement) focusElement.focus()
    }
  }, [])

  useEffect(() => {
    const modelViewContainer = modalWindowRef.current
    if (showModal) {
      modelViewContainer?.addEventListener('mousedown', handleMouseDown)
      modelViewContainer?.addEventListener('mouseup', handleMouseUp)
      modelViewContainer?.addEventListener('keydown', handleKeyDown)
    } else {
      modelViewContainer?.removeEventListener('mousedown', handleMouseDown)
      modelViewContainer?.removeEventListener('mouseup', handleMouseUp)
      modelViewContainer?.removeEventListener('keydown', handleKeyDown)
    }
    return () => {
      modelViewContainer?.removeEventListener('mousedown', handleMouseDown)
      modelViewContainer?.removeEventListener('mouseup', handleMouseUp)
      modelViewContainer?.removeEventListener('keydown', handleKeyDown)
    }
  }, [showModal, handleMouseDown, handleMouseUp, handleKeyDown])

  useEffect(() => {
    if (showModal) {
      handleFocus()
    }
  }, [showModal, handleFocus])

  return (
    <>
      <TransitionGroup component={null}>
        {showModal && (
          <CSSTransition
            key="modal"
            classNames="md"
            timeout={{ enter: 250, exit: 250 }}
            onEntered={handleFocus}
            // onExited={handleExit}
          >
            <ModalContainer alignItems={alignItems} ref={modalWindowRef}>
              <ModalOverlay />
              <ModalView ref={modalRef} style={style}>
                {!disableClose && <ModalClose />}
                {loading ? (
                  <Loader />
                ) : (
                  <ModalType
                    type={type}
                    modalWindow={modalWindowRef.current}
                    args={args}
                  />
                )}
              </ModalView>
            </ModalContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

export default Modal
