import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { TaxRevenueCenters } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface TaxRevenueCentersState {
  data: TaxRevenueCenters
  loading: RequestStatus
  error: RequestError
}

const initialState: TaxRevenueCentersState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchTaxRevenueCenters = createAsyncThunk<
  TaxRevenueCenters,
  number | string,
  { state: RootState; rejectValue: RequestError }
>(
  'taxRevenueCenters/fetchTaxRevenueCenters',
  async (taxId, { getState, rejectWithValue }) => {
    try {
      const endpoint = `taxes/${taxId}/revenue-centers`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as TaxRevenueCenters
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateTaxRevenueCenters = createAsyncThunk<
  TaxRevenueCenters,
  {
    taxId: string | number
    data: TaxRevenueCenters
  },
  { state: RootState; rejectValue: RequestError }
>(
  'taxRevenueCenters/updateTaxRevenueCenters',
  async ({ taxId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const endpoint = `taxes/${taxId}/revenue-centers`
      const api = getState().authUser.api
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as TaxRevenueCenters
      dispatch(showNotification('Tax revenue centers successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const taxRevenueCentersSlice = createSlice({
  name: 'taxRevenueCenters',
  initialState,
  reducers: {
    resetTaxRevenueCenters: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTaxRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTaxRevenueCenters.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchTaxRevenueCenters.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateTaxRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateTaxRevenueCenters.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateTaxRevenueCenters.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTaxRevenueCenters } = taxRevenueCentersSlice.actions

export const selectTaxRevenueCenters = (state: RootState) =>
  state.taxRevenueCenters

export default taxRevenueCentersSlice.reducer
