import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Form } from 'components'
import {
  brandTypes,
  enterpriseLevelOptions,
  formatIndependeBrands,
  locationStatusOptions
} from 'config'
import { FormFieldType, Location, SelectOptionInterface } from 'types'
import { RequestStatus, getStatusValue } from 'utils'
import { selectCorporateOnboardingBrands } from 'slices/iacm/onboardingBrandsCorporateOnly'
import {
  UpdateLocationParams,
  selectOnboardingLocation,
  updateLocation
} from 'slices/iacm/onboardingLocation'
import { selectExternalUsersOptions } from 'slices/iacm/externalUsers'

const LocationForm = ({
  location,
  children,
  callback
}: {
  location: Location
  children?: React.ReactNode
  callback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { error, loading } = useAppSelector(selectOnboardingLocation)
  const { data: corporateBrands } = useAppSelector(
    selectCorporateOnboardingBrands
  )
  const externalUsersOptions = useAppSelector(selectExternalUsersOptions)
  const isLoading = loading === RequestStatus.Pending

  const generateFormData = () => ({
    locationName: location.name,
    brandId: location.brandId,
    storeType: location.storeType,
    enterpriseLevelId: location.enterpriseLevel.id,
    locationStatus: location.state.status,
    onboardingSpecialistId: location.state.onboardingSpecialist?.id,
    menuMappingSpecialistId: location.state.menuMappingSpecialist?.id,
    goLiveSpecialistId: location.state.goLiveSpecialist?.id
  })

  const submit = (values: UpdateLocationParams) => {
    setSubmitted(true)
    const updatedValues = {
      ...values,
      locationStatus: getStatusValue(
        values.locationStatus,
        location
      ).toLowerCase(),
      id: location.id
    }
    dispatch(updateLocation(updatedValues))
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (!error && callback) {
        callback()
      }
    }
  }, [submitted, isLoading, error, callback])

  return (
    <Form
      fields={getLocationFormFields(
        formatIndependeBrands(corporateBrands?.results || []),
        externalUsersOptions
      )}
      data={generateFormData() || locationEmpty}
      error={error}
      isLoading={isLoading}
      submit={submit}
    >
      {children}
    </Form>
  )
}

const locationEmpty = {
  locationName: '',
  brandName: '',
  storeType: '',
  enterpriseLevelId: '',
  status: ''
}

const getLocationFormFields = (
  brands: SelectOptionInterface[],
  externalUsers: SelectOptionInterface[]
): FormFieldType[] => [
  {
    fieldType: 'Input',
    type: 'text',
    label: 'Location Name',
    name: 'locationName'
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: brands,
    label: 'Brand Name',
    name: 'brandId',
    nullable: true
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: brandTypes,
    label: 'Store Type',
    name: 'storeType',
    nullable: true
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: enterpriseLevelOptions,
    label: 'Enterprise Level',
    name: 'enterpriseLevelId',
    nullable: true
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: locationStatusOptions,
    label: 'Status',
    name: 'locationStatus',
    nullable: true
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: externalUsers,
    label: 'Onboarding Specialist',
    name: 'onboardingSpecialistId',
    nullable: true
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: externalUsers,
    label: 'Menu Mapping Specialist',
    name: 'menuMappingSpecialistId',
    nullable: true
  },
  {
    fieldType: 'Select',
    type: 'select',
    options: externalUsers,
    label: 'Go Live Specialist',
    name: 'goLiveSpecialistId',
    nullable: true
  }
]

export default LocationForm
