import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { HouseAccounts } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface HouseAccountsState {
  data: HouseAccounts
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface HouseAccountsAPI {
  data: HouseAccounts
  links: {
    next: string
  }
}

export type HouseAccountsParams = {
  limit?: number
  cursor?: string
  with_related?: string
  is_active?: boolean
  search?: string
  expand?: string
}

const initialState: HouseAccountsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchHouseAccounts = createAsyncThunk<
  HouseAccountsAPI,
  HouseAccountsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'houseAccounts/fetchHouseAccounts',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `house-accounts${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as HouseAccountsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const houseAccountsSlice = createSlice({
  name: 'houseAccounts',
  initialState,
  reducers: {
    resetHouseAccounts: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchHouseAccounts.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchHouseAccounts.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchHouseAccounts.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetHouseAccounts } = houseAccountsSlice.actions

export const selectHouseAccounts = (state: RootState) => state.houseAccounts

export default houseAccountsSlice.reducer
