import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Tax } from 'types'
import { showNotification } from 'slices/notification'

export interface TaxState {
  data: Tax | null
  loading: RequestStatus
  error: RequestError
}

const initialState: TaxState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type TaxParams = {
  expand?: string
  with_related?: string
}

export const fetchTax = createAsyncThunk<
  Tax,
  { taxId: string | number; params?: TaxParams },
  { state: RootState; rejectValue: RequestError }
>('tax/fetchTax', async ({ taxId, params }, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `taxes/${taxId}${queryParams}`
    const resp = (await api?.request(endpoint)) as Tax
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createTax = createAsyncThunk<
  Tax,
  { data: Tax; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'tax/createTax',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('taxes', 'POST', data)) as Tax
      dispatch(showNotification('Tax successfully created!'))
      const pathname = `/settings/ops/taxes/${resp.tax_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateTax = createAsyncThunk<
  Tax,
  {
    taxId: string | number
    data: Tax
  },
  { state: RootState; rejectValue: RequestError }
>(
  'tax/updateTax',
  async ({ taxId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `taxes/${taxId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Tax
      dispatch(showNotification('Tax successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteTax = createAsyncThunk<
  void,
  {
    taxId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'tax/deleteTax',
  async ({ taxId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `taxes/${taxId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Tax removed!'))
      navigate({ pathname: '/settings/ops/taxes' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    resetTax: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTax.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTax.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchTax.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createTax.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createTax.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createTax.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateTax.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateTax.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateTax.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteTax.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteTax.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteTax.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTax } = taxSlice.actions

export const selectTax = (state: RootState) => state.tax

export default taxSlice.reducer
