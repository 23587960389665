import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Twilio } from 'types'
import { showNotification } from 'slices/notification'

export interface TwilioState {
  data: Twilio | null
  loading: RequestStatus
  error: RequestError
}

const initialState: TwilioState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchTwilio = createAsyncThunk<
  Twilio,
  void,
  { state: RootState; rejectValue: RequestError }
>('twilio/fetchTwilio', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('integrations/twilio')) as Twilio
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertTwilio = createAsyncThunk<
  Twilio,
  { data: Twilio },
  { state: RootState; rejectValue: RequestError }
>(
  'twilio/upsertTwilio',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'integrations/twilio',
        'PUT',
        data
      )) as Twilio
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const twilioSlice = createSlice({
  name: 'twilio',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTwilio.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTwilio.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchTwilio.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertTwilio.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertTwilio.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(upsertTwilio.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectTwilio = (state: RootState) => state.twilio

export default twilioSlice.reducer
