import { useEffect, useMemo, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { omit, RequestStatus } from 'utils'
import {
  Category,
  CategoryAppearance,
  CategoryOrderType,
  FormFieldType,
  SelectOptions
} from 'types'
import { Form } from 'components'
import {
  createCategory,
  fetchCategory,
  selectCategory,
  updateCategory
} from 'slices/category'
import { selectMenu, selectMenuCategories } from 'slices/menu'

const MenuCategoryForm = ({
  menuId,
  categoryId,
  scrollWindow,
  navigate,
  callback
}: {
  menuId: string | number
  categoryId: string | number
  scrollWindow?: HTMLDivElement | null
  navigate?: NavigateFunction
  callback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { data: menuData } = useAppSelector(selectMenu)
  const isExternal = menuData?.is_external ? true : false
  const { data, loading, error } = useAppSelector(selectCategory)
  const hasError = error ? true : false
  const isLoading = loading === RequestStatus.Pending
  const formData =
    categoryId !== 'new' && data?.category_id
      ? omit<Category, 'category_id'>(data, 'category_id')
      : categoryEmpty
  const { categories } = useAppSelector(selectMenuCategories)

  const parentCategories = useMemo(() => {
    const catId = typeof categoryId === 'string' ? parseInt(categoryId) : null
    return categories
      .filter(i => !i.parent_category_id && i.category_id !== catId)
      .map(i => ({
        value: i.category_id,
        text: i.full_name
      }))
  }, [categories, categoryId])

  const handleCategorySubmit = (values: Category) => {
    if (!menuId) return
    setSubmitted(true)
    let augmented = { ...values, ...categoryDefaults }
    delete augmented.items
    delete augmented.files

    if (categoryId === 'new' && navigate) {
      dispatch(createCategory({ menuId, data: augmented, navigate }))
    } else if (categoryId) {
      dispatch(updateCategory({ menuId, categoryId, data: augmented }))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      if (callback && !error) callback()
      setSubmitted(false)
    }
  }, [dispatch, submitted, isLoading, error, callback])

  useEffect(() => {
    return () => {
      if (hasError && categoryId) {
        const params = { with_related: 'items' }
        dispatch(fetchCategory({ categoryId, params }))
      }
    }
  }, [hasError, categoryId, dispatch])

  return (
    <Form
      fields={categoryFields(isExternal, parentCategories)}
      data={formData}
      error={error}
      isLoading={isLoading}
      submit={handleCategorySubmit}
      scrollWindow={scrollWindow}
    />
  )
}

const categoryEmpty = {
  full_name: '',
  short_name: '',
  description: '',
  is_active: true,
  parent_category_id: null
}

const categoryDefaults = {
  appearance: 'MEDIUM' as CategoryAppearance,
  is_editable: true,
  order_type: 'UNKNOWN' as CategoryOrderType,
  pos_section_id: null,
  pos_display_color: null,
  pos_display_order: null
}

const categoryFields = (
  isExternal: boolean,
  parentCategories: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'full_name',
    required: true,
    label: 'Name',
    error: 'Please enter a name'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'short_name',
    required: isExternal ? false : true,
    readOnly: isExternal ? true : false,
    label: 'Internal Name',
    error: 'Please enter an internal name'
  },
  {
    fieldType: 'TextArea',
    name: 'description',
    label: 'Description',
    error: 'Please enter a description'
  },
  {
    fieldType: 'Checkbox',
    label: 'Active',
    name: 'is_active'
  },
  {
    fieldType: 'Select',
    name: 'parent_category_id',
    options: parentCategories,
    disabled: isExternal ? true : false,
    label: 'Parent Category'
  }
]

export default MenuCategoryForm
