import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const ContentView = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const ContentContainer = styled.div<{ hasCrumbs?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${props => props.theme.layout.desktop.padding};
  margin-top: ${props =>
    props.hasCrumbs ? '0' : props.theme.layout.desktop.header.height};
  margin-bottom: ${props => props.theme.layout.desktop.header.height};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 ${props => props.theme.layout.mobile.padding};
    margin-top: ${props =>
      props.hasCrumbs ? '0' : props.theme.layout.mobile.header.height};
    margin-bottom: ${props => props.theme.layout.mobile.header.height};
  }
`

export const ContentContent = styled.div<{
  maxWidth?: string
}>`
  width: 100%;
  max-width: ${props => props.maxWidth || '100%'};
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 3rem;
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
  margin: 0 0 3rem;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 0 0 1.5rem;
    margin: 0 0 1.5rem;
  }
`

export const ContentTitleView = styled.div`
  width: 100%;
`

export const ContentTitleText = styled(TextHeading)`
  margin-left: -0.2rem;
  font-size: ${props => props.theme.sizes.h1};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: ${props => props.theme.sizes.h2};
  }
`

export const ContentSubtitle = styled(TextBody)`
  max-width: 70rem;
  margin-top: 1rem;
  margin-left: 0.2rem;
  line-height: 1.4;
`
