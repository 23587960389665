import {
  AuthFormContent,
  AuthFormFooter,
  AuthFormHeader,
  AuthFormSubtitle,
  AuthFormTitle,
  AuthFormView
} from './AuthForm.styled'

const AuthForm = ({
  title,
  subtitle,
  children,
  footer
}: {
  title: string
  subtitle: string
  children: React.ReactNode
  footer?: React.ReactNode
}) => {
  return (
    <AuthFormView className="fadeUp">
      <AuthFormHeader>
        <AuthFormTitle as="h1">{title}</AuthFormTitle>
        <AuthFormSubtitle as="p">{subtitle}</AuthFormSubtitle>
      </AuthFormHeader>
      <AuthFormContent>{children}</AuthFormContent>
      <AuthFormFooter>{footer}</AuthFormFooter>
    </AuthFormView>
  )
}

export default AuthForm
