import styled from '@emotion/styled'

export const PagesImagesView = styled.div`
  label: PagesImages;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 3rem;
`
