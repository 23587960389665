import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const ListLinkView = styled.span`
  display: block;

  a {
    padding: 0.7rem 1.2rem 0.8rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${props => props.theme.colors.background.tertiary};
    background-color: transparent;

    &:hover {
      opacity: 1;
      background-color: ${props => props.theme.colors.background.tertiary};
    }
  }

  a > span {
    font-size: ${props => props.theme.sizes.small};
  }
`

const ListLink = ({
  to,
  icon,
  text
}: {
  to: string
  icon: JSX.Element
  text: string | JSX.Element
}) => {
  return (
    <ListLinkView>
      <Link to={to}>
        {icon}
        {typeof text === 'string' ? <span>{text}</span> : text}
      </Link>
    </ListLinkView>
  )
}

export default ListLink
