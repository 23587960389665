import { LabelInterface } from 'types'
import { ErrMsg } from '../..'
import {
  LabelParenthetical,
  LabelRequired,
  LabelText,
  LabelView
} from './Label.styled'
import LabelTooltip from './LabelTooltip'

const Label = ({
  htmlFor,
  label,
  parenthetical,
  tooltip,
  required,
  errMsg,
  children
}: LabelInterface) => {
  return (
    <LabelView htmlFor={htmlFor}>
      {label && (
        <LabelText>
          <strong>
            {label}
            {required ? <LabelRequired>&nbsp;*</LabelRequired> : null}
          </strong>
          {parenthetical ? (
            <LabelParenthetical> {parenthetical}</LabelParenthetical>
          ) : null}
          {tooltip ? <LabelTooltip>{tooltip}</LabelTooltip> : null}
        </LabelText>
      )}
      {children}
      <ErrMsg errMsg={errMsg} />
    </LabelView>
  )
}

export default Label
