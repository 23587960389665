import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { WaitTimePickup } from 'types'
import { showNotification } from 'slices/notification'

export interface WaitTimePickupState {
  data: WaitTimePickup | null
  loading: RequestStatus
  error: RequestError
}

const initialState: WaitTimePickupState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const navigatePath = '/settings/ops/wait-times/pickup'

export const fetchWaitTimePickup = createAsyncThunk<
  WaitTimePickup,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimePickup/fetchWaitTimePickup',
  async (pickupWaitTimeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `pickup-wait-times/${pickupWaitTimeId}`
      const resp = (await api?.request(endpoint)) as WaitTimePickup
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createWaitTimePickup = createAsyncThunk<
  WaitTimePickup,
  { data: WaitTimePickup; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimePickup/createWaitTimePickup',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pickup-wait-times',
        'POST',
        data
      )) as WaitTimePickup
      dispatch(showNotification('Pickup wait time successfully created!'))
      navigate({ pathname: navigatePath })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateWaitTimePickup = createAsyncThunk<
  WaitTimePickup,
  {
    pickupWaitTimeId: string | number
    data: WaitTimePickup
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimePickup/updateWaitTimePickup',
  async (
    { pickupWaitTimeId, data, navigate },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pickup-wait-times/${pickupWaitTimeId}`,
        'PUT',
        data
      )) as WaitTimePickup
      dispatch(showNotification('Pickup wait time successfully updated!'))
      navigate({ pathname: navigatePath })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteWaitTimePickup = createAsyncThunk<
  void,
  {
    pickupWaitTimeId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimePickup/deleteWaitTimePickup',
  async (
    { pickupWaitTimeId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `pickup-wait-times/${pickupWaitTimeId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Pickup wait time removed!'))
      navigate({ pathname: navigatePath })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const waitTimePickupSlice = createSlice({
  name: 'waitTimePickup',
  initialState,
  reducers: {
    resetWaitTimePickup: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchWaitTimePickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchWaitTimePickup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchWaitTimePickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createWaitTimePickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createWaitTimePickup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createWaitTimePickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateWaitTimePickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateWaitTimePickup.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateWaitTimePickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteWaitTimePickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteWaitTimePickup.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteWaitTimePickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetWaitTimePickup } = waitTimePickupSlice.actions

export const selectWaitTimePickup = (state: RootState) => state.waitTimePickup

export default waitTimePickupSlice.reducer
