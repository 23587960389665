import React from 'react'
import { AppTheme } from 'types'
import Iframe from '../Iframe'
import { useAppSelector } from 'app/hooks'
import {
  selectAppThemeBackgroundColor,
  selectAppThemeFontUrls
} from 'slices/appTheme'
import {
  DesignTitle,
  InputBoxShadow,
  InputCheckbox,
  InputColor,
  InputPadding,
  InputRange,
  InputSelect,
  InputText
} from '../DesignInputs'
import TextHeading from '../TextHeading'
import {
  DesignFormContent,
  DesignFormHeader,
  DesignFormInputs,
  DesignFormPortal,
  DesignFormView
} from './DesignForm.styled'

interface InputProps {
  name: string
  id?: string
  type: string
  label: string
  required?: boolean
  min?: number
  max?: number
  step?: number
  options?: {
    value: string | number | undefined
    text: string
  }[]
}

const makeField = (
  field: InputProps,
  value: string | number | boolean | number[],
  setValue: (
    name: string,
    value: string | number | boolean | number[] | null
  ) => void
) => {
  switch (field.type) {
    case 'title':
      return (
        <DesignTitle
          key={field.name}
          label={field.label}
          required={field.required}
        />
      )
    case 'color':
      return (
        <InputColor
          {...field}
          key={field.name}
          value={value as string}
          setValue={(val: string | number | null) => setValue(field.name, val)}
        />
      )
    case 'range':
      return (
        <InputRange
          {...field}
          key={field.name}
          value={value as number}
          setValue={(val: string | number | null) => setValue(field.name, val)}
        />
      )
    case 'text':
      return (
        <InputText
          {...field}
          key={field.name}
          value={value as string}
          setValue={(val: string | number | null) => setValue(field.name, val)}
        />
      )
    case 'select':
      return (
        <InputSelect
          {...field}
          key={field.name}
          value={value as string}
          setValue={(val: string | number | null) => setValue(field.name, val)}
        />
      )
    case 'checkbox':
      return (
        <InputCheckbox
          {...field}
          key={field.name}
          value={value as boolean}
          setValue={(val: boolean) => setValue(field.name, val)}
        />
      )
    case 'boxShadow':
      return (
        <InputBoxShadow
          {...field}
          key={field.name}
          id={field.id || field.name}
          value={value as string}
          setValue={(val: string) => setValue(field.name, val)}
        />
      )
    case 'padding':
      return (
        <InputPadding
          {...field}
          key={field.name}
          id={field.id || field.name}
          value={value as number[]}
          setValue={(val: number[]) => setValue(field.name, val)}
        />
      )
    default:
      return null
  }
}

interface DesignFormProps<T> {
  title: string
  fields: InputProps[]
  values: T
  setValues: (values: T) => void
  adjustTheme?: (values: T) => AppTheme
  component?: (theme: AppTheme) => React.ReactElement
  previewHeight?: number
}

const DesignForm = <
  T extends Record<string, string | number | boolean | number[]>
>({
  title,
  fields,
  values,
  setValues,
  adjustTheme,
  component,
  previewHeight
}: DesignFormProps<T>) => {
  const bgColor = useAppSelector(selectAppThemeBackgroundColor)
  const urls = useAppSelector(selectAppThemeFontUrls)
  const adjustedTheme = adjustTheme ? adjustTheme(values) : null

  const setValue = (
    name: string,
    value: string | number | boolean | number[] | null
  ) => {
    setValues({ ...values, [name]: value })
  }

  return (
    <DesignFormView>
      {component && adjustedTheme ? (
        <DesignFormPortal previewHeight={previewHeight}>
          <Iframe title={title} bgColor={bgColor} stylesheets={urls}>
            {component(adjustedTheme)}
          </Iframe>
        </DesignFormPortal>
      ) : null}
      <DesignFormContent>
        <DesignFormHeader>
          <TextHeading size="large">{title}</TextHeading>
        </DesignFormHeader>
        <DesignFormInputs>
          <div>
            {fields.map(field => {
              return makeField(field, values[field.name], setValue)
            })}
          </div>
        </DesignFormInputs>
      </DesignFormContent>
    </DesignFormView>
  )
}

export default DesignForm
