import styled from '@emotion/styled'

export const AuthPageView = styled.div`
  label: AuthPageView;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  background-color: ${props => props.theme.landing.background.primary};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 100%;
    height: -webkit-fill-available;
  }
`

export const AuthPageImage = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${props => props.theme.landing.background.secondary};
`

export const AuthPageMain = styled.div`
  label: AuthPageMain;
  flex: 1 0 58rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  padding: 0 4rem;
  overflow-y: scroll;
  color: ${props => props.theme.colors.text.primary};
`

export const AuthPageHeader = styled.div`
  flex: 0 0 8rem;
  height: 8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${props => props.theme.sizes.small};
`

export const AuthPageLogo = styled.div`
  width: 12rem;

  a {
    color: ${props => props.theme.landing.text.primary};
  }
`

export const AuthPageContent = styled.div`
  flex: 1 1 100%;
  width: 100%;
  max-width: 54rem;
  margin: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 0;
  }

  input {
    border-radius: 0;
    border-color: ${props => props.theme.colors.text.primary};
  }
`

export const AuthPageFooter = styled(AuthPageHeader)`
  flex: 0 0 6rem;
  height: 6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;

  span,
  a {
    display: block;
    color: ${props => props.theme.landing.text.primary};
  }

  a:hover span,
  a:active span {
    color: ${props => props.theme.landing.accent.primary};
  }
`

export const AuthPageSuccess = styled.span`
  display: block;
  margin: 3rem 0;
  color: ${props => props.theme.landing.text.primary};
`
