import { useTheme } from '@emotion/react'
import {
  BatteryEmpty,
  NavArrowLeft,
  ReportsSolid,
  VideoCamera,
  Wifi
} from 'iconoir-react'
import IphoneBody from '../IPhone'
import {
  IPhoneChatActionsView,
  IPhoneChatMessage,
  IPhoneChatMessagesContainer,
  IPhoneChatNetworkIcons,
  IPhoneChatTime,
  IPhoneChatTopSection,
  IPhoneChatTopSectionHeader,
  IPhoneChatUserName,
  IPhoneChatUserIcon
} from './IPhoneChat.styled'

interface IPhoneChatProps {
  message: string
}

const IPhoneChat = ({ message }: IPhoneChatProps) => {
  const { colors } = useTheme()
  return (
    <IphoneBody>
      <IPhoneChatTopSection>
        <IPhoneChatTopSectionHeader>
          <IPhoneChatTime>12:00</IPhoneChatTime>
          <IPhoneChatNetworkIcons>
            <ReportsSolid fontSize={8} strokeWidth={10} />
            <Wifi fontSize={8} strokeWidth={2} />
            <BatteryEmpty fontSize={8} fill={colors.text.primary} />
          </IPhoneChatNetworkIcons>
        </IPhoneChatTopSectionHeader>
        <IPhoneChatActionsView>
          <NavArrowLeft />
          <IPhoneChatUserIcon />
          <VideoCamera />
        </IPhoneChatActionsView>
        <IPhoneChatUserName>
          <strong>+13479059999</strong>
        </IPhoneChatUserName>
      </IPhoneChatTopSection>
      <IPhoneChatMessagesContainer>
        {message.length ? (
          <IPhoneChatMessage dangerouslySetInnerHTML={{ __html: message }} />
        ) : null}
      </IPhoneChatMessagesContainer>
    </IphoneBody>
  )
}

export default IPhoneChat
