import { useEffect, useMemo, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { omit, RequestStatus } from 'utils'
import { FormFieldType, Item, SelectOptions } from 'types'
import { temperatureOptions } from 'config'
import { Form } from 'components'
import { createItem, fetchItem, selectItem, updateItem } from 'slices/item'
import { fetchItemTypes, selectItemTypes } from 'slices/itemTypes'
import { selectMenu } from 'slices/menu'

const MenuItemForm = ({
  menuId,
  categoryId,
  modifierGroupId,
  itemId,
  scrollWindow,
  navigate,
  callback
}: {
  menuId: string | number
  categoryId?: string | number
  modifierGroupId?: string | number
  itemId: string | number
  scrollWindow?: HTMLDivElement | null
  navigate?: NavigateFunction
  callback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { data: menuData } = useAppSelector(selectMenu)
  const isExternal = menuData?.is_external ? true : false
  const { data: itemTypes } = useAppSelector(selectItemTypes)
  const { data, loading, error } = useAppSelector(selectItem)
  const hasError = error ? true : false
  const isLoading = loading === RequestStatus.Pending
  const formData =
    itemId !== 'new' && data?.item_id
      ? omit<Item, 'item_id'>(data, 'item_id')
      : itemEmpty

  const itemTypeOptions = useMemo(() => {
    const options = itemTypes.map(itemType => {
      return {
        value: itemType.item_type_id,
        text: itemType.name
      }
    })
    return [{ value: 'none', text: 'No item type' }, ...options]
  }, [itemTypes])

  const submit = (values: Item) => {
    if (!menuId) return
    setSubmitted(true)
    let augmented = { ...itemDefaults, ...values }
    delete augmented.modifier_groups
    delete augmented.tags
    delete augmented.allergens
    if (itemId === 'new') {
      dispatch(
        createItem({
          menuId,
          categoryId,
          modifierGroupId,
          data: augmented,
          navigate
        })
      )
    } else if (itemId) {
      dispatch(
        updateItem({ categoryId, modifierGroupId, itemId, data: augmented })
      )
    }
  }

  useEffect(() => {
    dispatch(fetchItemTypes())
  }, [dispatch])

  useEffect(() => {
    if (submitted && !isLoading) {
      if (callback && !error) callback()
      setSubmitted(false)
    }
  }, [dispatch, submitted, isLoading, error, callback])

  useEffect(() => {
    return () => {
      if (hasError && itemId) {
        const params = { with_related: 'modifier_groups' }
        dispatch(fetchItem({ itemId, params }))
      }
    }
  }, [hasError, itemId, dispatch])

  return (
    <Form
      fields={itemFields(isExternal, itemTypeOptions)}
      data={formData}
      error={error}
      isLoading={isLoading}
      submit={submit}
      scrollWindow={scrollWindow}
    />
  )
}

const itemEmpty = {
  full_name: '',
  short_name: '',
  description: '',
  short_description: '',
  ingredients: '',
  shorthand: '',
  featured_position: null,
  start_date: null,
  end_date: null,
  pos_display_color: null,
  sales_tax_override: null,
  item_type_id: null,
  temperature: 'COLD',
  is_alcohol: false
}

const itemDefaults = {
  is_active: true,
  plu: '',
  pos_display_color: null,
  service_restrictions: [],
  delivery_days: null,
  height: null,
  length: null,
  weight: null,
  width: null
}

const itemFields = (
  isExternal: boolean,
  itemTypeOptions: SelectOptions
): FormFieldType[] => {
  const fields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      name: 'full_name',
      required: true,
      label: 'Name',
      error: 'Please enter a name'
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'short_name',
      required: isExternal ? false : true,
      readOnly: isExternal ? true : false,
      label: 'Internal Name',
      error: 'Please enter an internal name'
    },
    {
      fieldType: 'Checkbox',
      label: 'Contains Alcohol',
      name: 'is_alcohol',
      disabled: isExternal,
      hint: 'Used to denote alcoholic items on the menu and optionally require age verification'
    },
    {
      fieldType: 'TextArea',
      name: 'description',
      label: 'Description',
      error: 'Please enter a description'
    },
    {
      fieldType: 'TextArea',
      name: 'short_description',
      label: 'Short Description',
      error: 'Please enter a short description',
      hint: 'An optional shorter description that can be displayed on the menu page itself in order to conserve space.'
    },
    {
      fieldType: 'TextArea',
      name: 'ingredients',
      label: 'Ingredients',
      placeholder: 'enter a comma-separated list of ingredients',
      error: 'Please enter a list of ingredients',
      tooltip: 'A comma-separated list of ingredients'
    },
    {
      fieldType: 'Input',
      name: 'shorthand',
      label: 'Shorthand',
      placeholder: '',
      error: 'Please enter ',
      tooltip: 'Some quick way to refer to this menu item.'
    },
    {
      fieldType: 'Select',
      name: 'temperature',
      options: temperatureOptions,
      label: 'Temperature'
    },
    {
      fieldType: 'Select',
      type: 'number',
      name: 'item_type_id',
      nullable: true,
      options: itemTypeOptions,
      label: 'Item Type'
    },
    {
      fieldType: 'Input',
      type: 'number',
      name: 'featured_position',
      required: false,
      nullable: true,
      min: 0,
      label: 'Featured Position',
      placeholder: 'enter number as in integer or leave blank',
      error: 'Please enter a number or leave blank'
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'start_date',
      required: false,
      nullable: true,
      label: 'Start Date',
      placeholder: 'enter date in YYYY-MM-DD format',
      error: 'Please enter a date in YYYY-MM-DD format',
      isInternal: true
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'end_date',
      required: false,
      nullable: true,
      label: 'End Date',
      placeholder: 'enter date in YYYY-MM-DD format',
      error: 'Please enter a date in YYYY-MM-DD format',
      isInternal: true
    },
    {
      fieldType: 'Input',
      type: 'text',
      name: 'sales_tax_override',
      required: false,
      nullable: true,
      label: 'Sales Tax Override',
      placeholder: 'enter number in 0.000 format',
      error:
        'Please enter a number greater or equal to 0 with or without decimal places',
      hint: "Use this only when an item has a sales tax rate that's different than the rest of the items at the store",
      isInternal: true
    }
    // {
    //   fieldType: 'Input',
    //   type: 'text',
    //   name: 'pos_display_color',
    //   required: false,
    //   nullable: true,
    //   label: 'KDS Display Color',
    //   placeholder: 'enter hexidecimal color code such as cccccc',
    //   error: 'Please enter a 6 character hexidecimal color code'
    // },
  ]
  return isExternal ? fields.filter(i => !i.isInternal) : fields
}

export default MenuItemForm
