import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, SelectOptions } from 'types'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { selectLoyaltyBonuses } from 'slices/loyaltyBonuses'
import {
  createItemLoyaltyBonus,
  fetchItemLoyaltyBonus,
  selectItemLoyaltyBonus
} from 'slices/itemLoyaltyBonus'

const ItemLoyaltyBonus = ({
  args,
  modalWindow
}: {
  args: { itemId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { itemId } = args
  const { data, loading, error } = useAppSelector(selectItemLoyaltyBonus)
  const loyaltyBonuses = useAppSelector(selectLoyaltyBonuses)
  const current = data.map(i => i.loyalty_bonus_id)
  const filtered = loyaltyBonuses.data.filter(
    i => !current.includes(i.loyalty_bonus_id)
  )
  const isLoading =
    loading === RequestStatus.Pending ||
    loyaltyBonuses.loading === RequestStatus.Pending

  const loyaltyBonusOptions = useMemo(() => {
    return filtered.map(i => {
      return {
        value: i.loyalty_bonus_id,
        text: `${i.name}`
      }
    })
  }, [filtered])

  const submit = async (values: { loyalty_bonus_id: number }) => {
    if (itemId) {
      setSubmitted(true)
      const data = [values]
      await dispatch(createItemLoyaltyBonus({ itemId, data }))
      dispatch(fetchItemLoyaltyBonus(itemId))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <div>
      <ModalHeader
        title="Add New Loyalty Bonus"
        subtitle="Choose a loyalty bonus from the options below"
      />
      <Form
        fields={itemLoyaltyBonusFields(loyaltyBonusOptions)}
        data={itemLoyaltyBonusEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const itemLoyaltyBonusEmpty = {
  loyalty_bonus_id: null
}

const itemLoyaltyBonusFields = (
  loyaltyBonusOptions: SelectOptions
): FormFieldType[] => [
  {
    fieldType: 'Select',
    type: 'number',
    name: 'loyalty_bonus_id',
    options: loyaltyBonusOptions,
    label: 'Loyalty Bonus',
    error: 'Please select a loyalty bonus'
  }
]

export default ItemLoyaltyBonus
