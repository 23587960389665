import { LegendInterface } from 'types'
import { LegendSubtitle, LegendTitle, LegendView } from './Legend.styled'

const Legend = ({ name, label, subtitle }: LegendInterface) => {
  return (
    <LegendView id={name}>
      <LegendTitle size="xlarge" as="p">
        {label}
      </LegendTitle>
      <LegendSubtitle size="small" as="p">
        {subtitle}
      </LegendSubtitle>
    </LegendView>
  )
}

export default Legend
