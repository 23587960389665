import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Categories, Menu, ModifierGroups } from 'types'
import { showNotification } from 'slices/notification'
import { fetchMenus } from 'slices/menus'

export interface MenuState {
  data: Menu | null
  loading: RequestStatus
  error: RequestError
}

const initialState: MenuState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type MenuParams = {
  expand?: string
  with_related?: string
}

export const fetchMenu = createAsyncThunk<
  Menu | null,
  { menuId: string | number; params?: MenuParams },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/fetchMenu',
  async ({ menuId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(`menus/${menuId}${queryParams}`)) as Menu
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createMenu = createAsyncThunk<
  Menu,
  { data: Menu; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/createMenu',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('menus', 'POST', data)) as Menu
      dispatch(showNotification('Menu successfully created!'))
      dispatch(fetchMenus())
      navigate({ pathname: `/menus/menus/${resp.menu_id}` })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertMenu = createAsyncThunk<
  Menu,
  { menuId: string | number; data: Menu },
  { state: RootState; rejectValue: RequestError }
>(
  'menu/upsertMenu',
  async ({ menuId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(`menus/${menuId}`, 'PUT', data)
      dispatch(showNotification('Menu successfully updated!'))
      dispatch(fetchMenus())
      const endpoint = `menus/${menuId}?with_related=categories`
      return (await api?.request(endpoint)) as Menu
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    resetMenu: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchMenu.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchMenu.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchMenu.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createMenu.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createMenu.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createMenu.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertMenu.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertMenu.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(upsertMenu.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetMenu } = menuSlice.actions

export const selectMenu = (state: RootState) => state.menu

export const selectMenuCategories = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.menu
    return { data, loading, error }
  },
  ({ data, loading, error }) => {
    if (!data?.categories) {
      return { categories: [], loading, error }
    }
    const categories = data.categories.reduce((arr, menuCategory) => {
      const { category, menu_position } = menuCategory
      return [...arr, { ...category, menu_position }]
    }, [] as Categories)

    return { categories, loading, error }
  }
)

export const selectMenuModifierGroups = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.menu
    return { data, loading, error }
  },
  ({ data, loading, error }) => {
    if (!data?.modifier_groups) {
      return { modifierGroups: [], loading, error }
    }
    const modifierGroups = data.modifier_groups.reduce(
      (arr, menuModifierGroup) => {
        const { modifier_group } = menuModifierGroup
        return [...arr, { ...modifier_group }]
      },
      [] as ModifierGroups
    )

    return { modifierGroups, loading, error }
  }
)

export default menuSlice.reducer
