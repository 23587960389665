import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { OrderAppContent, AppContentSections } from 'types'
import { showNotification } from 'slices/notification'

export interface AppContentState {
  data: OrderAppContent | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AppContentState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAppContent = createAsyncThunk<
  OrderAppContent,
  void,
  { state: RootState; rejectValue: RequestError }
>('appContent/fetchAppContent', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('order-app-content')) as OrderAppContent
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertAppContent = createAsyncThunk<
  OrderAppContent,
  { data: AppContentSections },
  { state: RootState; rejectValue: RequestError }
>(
  'appContent/upsertAppContent',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('order-app-content', 'PUT', {
        content: data
      })) as OrderAppContent
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const appContentSlice = createSlice({
  name: 'appContent',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAppContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAppContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(fetchAppContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertAppContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertAppContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = payload
    })
    builder.addCase(upsertAppContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectAppContent = (state: RootState) => {
  const { data, loading, error } = state.appContent
  const content = data?.content || null
  return { content, loading, error }
}

export default appContentSlice.reducer
