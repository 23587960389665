import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { fetchSegments, selectSegments } from 'slices/segments'

export const useMergeCodes = () => {
  const dispatch = useAppDispatch()
  const { data: segments, error, loading } = useAppSelector(selectSegments)

  useEffect(() => {
    dispatch(
      fetchSegments({
        is_archived: 'false'
      })
    )
  }, [dispatch])

  const displayConditions = useMemo(() => {
    return segments
      ?.filter(segment => {
        // Filter aschived segments
        if (segment.is_archived) return false
        return true
      })
      .map(segment => ({
        type: segment.name,
        label: segment.name,
        description: segment.description,
        before: `{% if segment[${segment.marketing_segment_id}] %}`,
        after: `{% endif %}`
      }))
  }, [segments])

  return {
    displayConditions,
    error,
    loading
  }
}
export default useMergeCodes
