import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CampaignTrigger, TriggerTimeFrame } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface TriggerState {
  data: CampaignTrigger[]
  loading: RequestStatus
  error: RequestError
}

const initialState: TriggerState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}
export type UpdateTriggerParams = Omit<
  CampaignTrigger,
  'created_at' | 'updated_at'
>
export type CreateTriggerParams = Omit<
  CampaignTrigger,
  'marketing_trigger_id' | 'created_at' | 'updated_at'
>

export const createTrigger = createAsyncThunk<
  CampaignTrigger,
  CreateTriggerParams,
  { state: RootState; rejectValue: RequestError }
>(
  'triggers/createTrigger',
  async (
    trigger,
    { getState, dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `triggers`
      const resp = (await api?.request(endpoint, 'POST', {
        campaign_id: trigger.campaign_id,
        channel: trigger.channel,
        is_active: trigger.is_active ?? false,
        event_type: trigger.event_type,
        send_time: trigger.send_time || null,
        start_date: trigger.start_date || null,
        timezone: trigger.timezone || null,
        end_date: trigger.end_date || null,
        offset_duration: trigger.offset_duration ?? null,
        offset_timeframe: trigger.offset_timeframe ?? TriggerTimeFrame.Days,
        limit_messages: trigger.limit_messages ?? null,
        limit_timeframe: trigger.limit_timeframe ?? TriggerTimeFrame.Days,
        limit_duration: trigger.limit_duration ?? null
      })) as CampaignTrigger
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateTrigger = createAsyncThunk<
  CampaignTrigger,
  UpdateTriggerParams,
  { state: RootState; rejectValue: RequestError }
>(
  'triggers/updateTrigger',
  async (
    trigger,
    { getState, dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `triggers/${trigger.marketing_trigger_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        campaign_id: trigger.campaign_id,
        channel: trigger.channel,
        is_active: trigger.is_active ?? false,
        event_type: trigger.event_type,
        send_time: trigger.send_time || null,
        start_date: trigger.start_date || null,
        timezone: trigger.timezone || null,
        end_date: trigger.end_date || null,
        offset_duration: trigger.offset_duration,
        offset_timeframe: trigger.offset_timeframe,
        limit_messages: trigger.limit_messages,
        limit_timeframe: trigger.limit_timeframe,
        limit_duration: trigger.limit_duration
      })) as CampaignTrigger
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

interface CampaingTriggersAPI {
  data: CampaignTrigger[]
}

export const fetchTriggers = createAsyncThunk<
  CampaingTriggersAPI,
  Pick<CampaignTrigger, 'campaign_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'triggers/fetchTrigger',
  async ({ campaign_id }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const params = makeQueryParams({ campaign_id })
      const endpoint = `triggers${params}`
      const resp = (await api?.request(endpoint)) as CampaingTriggersAPI
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteTrigger = createAsyncThunk<
  void,
  Pick<CampaignTrigger, 'marketing_trigger_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/deleteTrigger',
  async ({ marketing_trigger_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `triggers/${marketing_trigger_id}`
      await api?.request(endpoint, 'DELETE')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const triggerSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    resetTriggers: () => initialState,
    setTriggers: (state, action: PayloadAction<CampaignTrigger[]>) => {
      state.data = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(createTrigger.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createTrigger.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = [...state.data, payload]
      state.error = null
    })
    builder.addCase(createTrigger.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateTrigger.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateTrigger.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      const index = state.data.findIndex(
        trigger =>
          trigger.campaign_id === payload.campaign_id &&
          trigger.channel === payload.channel
      )
      state.data = [
        ...state.data.slice(0, index),
        payload,
        ...state.data.slice(index + 1)
      ]
      state.error = null
    })
    builder.addCase(updateTrigger.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchTriggers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTriggers.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.error = null
    })
    builder.addCase(fetchTriggers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteTrigger.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteTrigger.fulfilled, (state, action) => {
      state.loading = RequestStatus.Idle
      state.data = state.data?.filter(
        trigger =>
          trigger.marketing_trigger_id !== action.meta.arg.marketing_trigger_id
      )
      state.error = null
    })
    builder.addCase(deleteTrigger.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTriggers, setTriggers } = triggerSlice.actions

export const selectTriggers = (state: RootState) => state.triggers

export default triggerSlice.reducer
