import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from 'slices/notification'
import { AnnouncementPage, AssosicatedAnnouncements } from 'types'
import { fetchAnnouncementPages } from './announcementPages'

export interface AnnouncementPageState {
  data: AnnouncementPage | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AnnouncementPageState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type AnnouncementPageParams = {
  with_related?: string
}

export const fetchAnnouncementPage = createAsyncThunk<
  AnnouncementPage,
  { announcementPageId: string | number; params?: AnnouncementPageParams },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/fetchAnnouncementPage',
  async ({ announcementPageId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcement-pages/${announcementPageId}`
      const queryParams = params ? makeQueryParams(params) : ''
      const resp = await api?.request(endpoint + queryParams)
      return resp as AnnouncementPage
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createAnnouncementPage = createAsyncThunk<
  AnnouncementPage,
  { data: AnnouncementPage; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/createAnnouncementPage',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'announcement-pages',
        'POST',
        data
      )) as AnnouncementPage
      dispatch(showNotification('Announcement Page successfully created!'))
      dispatch(fetchAnnouncementPages())

      navigate({
        pathname: `/pages/${data.page.toLowerCase()}/announcements/${
          resp.announcement_page_id
        }`
      })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateAnnouncementPage = createAsyncThunk<
  AnnouncementPage,
  {
    announcementPageId: string | number
    data: AnnouncementPage
  },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/updateAnnouncementPage',
  async (
    { announcementPageId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcement-pages/${announcementPageId}`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as AnnouncementPage
      dispatch(showNotification('Announcement page successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteAnnouncementPage = createAsyncThunk<
  void,
  {
    announcementPageId: string | number
    page: string
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/deleteAnnouncementPage',
  async (
    { announcementPageId, page, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcement-pages/${announcementPageId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Announcement page removed!'))
      dispatch(fetchAnnouncementPages())

      navigate({ pathname: `/pages/${page}` })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const associateAnnouncement = createAsyncThunk<
  AssosicatedAnnouncements,
  { announcementPageId: string | number; data: AssosicatedAnnouncements },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/associateAnnouncement',
  async (
    { announcementPageId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcement-pages/${announcementPageId}/announcements`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as AssosicatedAnnouncements
      dispatch(showNotification('Announcement successfully associated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const removeAnnouncement = createAsyncThunk<
  void,
  { announcementPageId: string | number; data: AssosicatedAnnouncements },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/removeAnnouncement',
  async (
    { announcementPageId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcement-pages/${announcementPageId}/announcements`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Announcement removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const reorderAnnouncements = createAsyncThunk<
  AssosicatedAnnouncements,
  { announcementPageId: string | number; data: AssosicatedAnnouncements },
  { state: RootState; rejectValue: RequestError }
>(
  'announcementPage/reorderAnnouncements',
  async (
    { announcementPageId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcement-pages/${announcementPageId}/announcements`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as AssosicatedAnnouncements
      dispatch(showNotification('Announcements reordered!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const announcementPageSlice = createSlice({
  name: 'announcementPage',
  initialState,
  reducers: {
    resetAnnouncementPage: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAnnouncementPage.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAnnouncementPage.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchAnnouncementPage.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createAnnouncementPage.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createAnnouncementPage.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createAnnouncementPage.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateAnnouncementPage.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateAnnouncementPage.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateAnnouncementPage.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteAnnouncementPage.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteAnnouncementPage.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteAnnouncementPage.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAnnouncementPage } = announcementPageSlice.actions

export const selectAnnouncementPage = (state: RootState) =>
  state.announcementPage

export default announcementPageSlice.reducer
