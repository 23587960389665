import { SimplifiedModifier, SimplifiedOrderItem } from 'types'
import {
  OrderCartTotal,
  OrderCartItemName,
  OrderCartItemPrice,
  OrderCartItemQuantity,
  OrderCartItemView,
  OrderCartItemWrapperView,
  OrderCartItems,
  OrderCartModifierView,
  OrderCartModifierWrapperView,
  OrderCartItemGroups
} from './OrderCart.styled'
import { formatDollars, getModifierTotalAmount } from 'utils'

const OrderCartItem = ({ item }: { item: SimplifiedOrderItem }) => {
  const { full_name, price, quantity, price_total } = item
  return (
    <OrderCartItemView size="small">
      <OrderCartItemName>{full_name}</OrderCartItemName>
      <OrderCartItemPrice>{formatDollars(price)}</OrderCartItemPrice>
      <OrderCartItemQuantity>x {quantity}</OrderCartItemQuantity>
      <OrderCartItemPrice>{formatDollars(price_total)}</OrderCartItemPrice>
    </OrderCartItemView>
  )
}

const OrderItemModifier = ({
  modifier,
  level
}: {
  modifier: SimplifiedModifier
  level: number
}) => {
  const { full_name, quantity, price_total, price: modPrice, groups } = modifier
  const price = groups ? getModifierTotalAmount(modifier) : modPrice
  return (
    <OrderCartModifierView size="small">
      <OrderCartItemName style={{ paddingLeft: 15 * level }}>
        {full_name}
      </OrderCartItemName>
      <OrderCartItemPrice>{formatDollars(price)}</OrderCartItemPrice>
      <OrderCartItemQuantity>x {quantity}</OrderCartItemQuantity>
      <OrderCartItemPrice>
        {formatDollars(groups ? price_total : price)}
      </OrderCartItemPrice>
    </OrderCartModifierView>
  )
}
const OrderModifierWrapper = ({
  modifier,
  level
}: {
  modifier: SimplifiedModifier
  level: number
}) => {
  const { groups = [] } = modifier
  const modifiers = groups.reduce((arr, i) => {
    return [...arr, ...i.modifiers]
  }, [] as SimplifiedModifier[])
  return (
    <OrderCartModifierWrapperView>
      <OrderItemModifier modifier={modifier} level={level} />
      <OrderCartItemGroups>
        {modifiers.map(modifier => (
          <OrderModifierWrapper
            key={modifier.line_no}
            modifier={modifier}
            level={level + 1}
          />
        ))}
      </OrderCartItemGroups>
    </OrderCartModifierWrapperView>
  )
}

const OrderItemWrapper = ({ item }: { item: SimplifiedOrderItem }) => {
  const { modifier_groups } = item
  const modifiers = modifier_groups.reduce((arr, i) => {
    return [...arr, ...i.modifiers]
  }, [] as SimplifiedModifier[])
  return (
    <OrderCartItemWrapperView>
      <OrderCartItem item={item} />
      <OrderCartItemGroups>
        {modifiers.map(modifier => (
          <OrderModifierWrapper
            key={modifier.line_no}
            modifier={modifier}
            level={1}
          />
        ))}
      </OrderCartItemGroups>
    </OrderCartItemWrapperView>
  )
}

const OrderCart = ({
  items,
  subtotal
}: {
  items: SimplifiedOrderItem[]
  subtotal: string
}) => {
  return (
    <OrderCartItems>
      {items.map(item => (
        <OrderItemWrapper key={item.line_no} item={item} />
      ))}
      <OrderCartTotal>
        <span>Cart Total</span>
        <span>{formatDollars(subtotal)}</span>
      </OrderCartTotal>
    </OrderCartItems>
  )
}

export default OrderCart
