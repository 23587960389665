import { SelectInterface } from 'types'
import Label from '../Label/Label'
import {
  SelectArrow,
  SelectDropdown,
  SelectView,
  SelectWrapper
} from './Select.styled'

const Select = ({
  label,
  parenthetical,
  tooltip,
  name,
  value,
  onChange,
  error,
  options,
  required,
  disabled,
  inline,
  nullable = true,
  getGroupLabel,
  placeholder
}: SelectInterface) => {
  const showEmpty =
    !options.find(
      option =>
        option.value === null || option.value === 'none' || option.value === 0
    ) && nullable
  const selectedOption = options.find(option => option.value === value)
  const inlineWidth = (inline && selectedOption?.text.length) || 0
  const groups = getGroupLabel
    ? Object.keys(
        options.reduce(
          (agg, option) => ({ ...agg, [getGroupLabel(option)]: option }),
          {}
        )
      )
    : []

  return (
    <Label
      htmlFor={name}
      label={label}
      parenthetical={parenthetical}
      tooltip={tooltip}
      required={required}
      errMsg={error}
    >
      <SelectWrapper>
        <SelectView
          inline={inline}
          style={{
            minWidth: inlineWidth
              ? `calc(${inlineWidth * 0.9}ch + 6rem)`
              : undefined
          }}
          value={value === 0 ? 0 : value || 'no-option'}
          name={name}
          id={name}
          onChange={onChange}
          disabled={!!disabled}
        >
          {showEmpty && (
            <option disabled value="no-option">
              {placeholder ?? '-- select an option --'}
            </option>
          )}
          {getGroupLabel
            ? groups?.map(group => (
                <optgroup key={group} label={group}>
                  {options
                    .filter(option => getGroupLabel(option) === group)
                    .map(option => (
                      <option
                        key={option.value}
                        value={
                          option.value === 0 ? 0 : option.value ?? undefined
                        }
                      >
                        {option.text}
                      </option>
                    ))}
                </optgroup>
              ))
            : options.map(option => (
                <option
                  key={option.value}
                  value={option.value === 0 ? 0 : option.value ?? undefined}
                >
                  {option.text}
                </option>
              ))}
        </SelectView>
        {disabled ? (
          <input type="hidden" value={value || undefined} />
        ) : (
          <SelectDropdown>
            <SelectArrow />
          </SelectDropdown>
        )}
      </SelectWrapper>
    </Label>
  )
}

export default Select
