import {
  PrintPackingSection,
  PrintRow,
  PrintSection,
  PrintSeparator,
  PrintSignatureSection
} from './PrintOrder.styled'
import { ReceiptRow } from './PrintOrder.utils'

export const SignatureSection = () => {
  return (
    <PrintSignatureSection>
      <div>Additional Tip</div>
      <div>Print Name</div>
      <div>Signature</div>
    </PrintSignatureSection>
  )
}
export const PackedBySection = () => (
  <PrintPackingSection>
    <div>Packed By</div>
    <div>Date</div>
  </PrintPackingSection>
)

export const PrintSectionTitle = ({
  title,
  isReceipt
}: {
  title: string
  isReceipt?: boolean
}) => (
  <>
    <PrintRow isBold isReceipt={isReceipt}>
      {title.toUpperCase()}
    </PrintRow>
    <PrintSeparator />
  </>
)

export const PrintSectionRow = (row: ReceiptRow) => (
  <PrintRow indent={row.indent} isBold={row.bold}>
    {row.label && <p>{row.label}</p>}
    <p>{row.value ? row.value : '--'}</p>
  </PrintRow>
)

export const ReceiptSection = ({
  title,
  content,
  isReceipt
}: {
  title?: string
  content?: ReceiptRow[]
  isReceipt?: boolean
}) => {
  return (
    <PrintSection>
      {title && <PrintSectionTitle title={title} isReceipt={isReceipt} />}
      {content?.map((row, i) => (
        <div key={i}>
          {row.withSeparator && <PrintSeparator />}
          <PrintSectionRow {...row} />
          {row.withSeparator && <PrintSeparator height="2px" />}
        </div>
      ))}
    </PrintSection>
  )
}
