import styled from '@emotion/styled'

export const DateFiltersView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin: 1rem 0 3rem;
`

export const DateFiltersButton = styled.button<{ isActive?: boolean }>`
  padding: ${props => props.theme.inputs.padding};
  padding: 0.8rem 1rem;
  background-color: ${props =>
    props.isActive ? props.theme.colors.background.tertiary : 'inherit'};
  border-style: solid;
  border-color: ${props => props.theme.inputs.borderColor};
  border-width: ${props => props.theme.inputs.borderWidth};
  border-radius: ${props => props.theme.inputs.borderRadius};
  color: ${props => props.theme.inputs.color};

  &:hover {
    background-color: ${props => props.theme.colors.background.tertiary};
  }

  &:hover {
    background-color: ${props => props.theme.colors.background.tertiary};
  }
`
