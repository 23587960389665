import { InfoCircle } from 'iconoir-react'
import { TooltipView, TooltipContent } from './Tooltip.styled'

interface TooltipProps {
  content: React.ReactNode | string
  position?: string
  icon?: React.ReactNode
}

const Tooltip = ({ content, position = 'left', icon }: TooltipProps) => {
  return (
    <TooltipView>
      {icon ? (
        icon
      ) : (
        <InfoCircle fontSize={11} strokeWidth={1.5} style={{ lineHeight: 1 }} />
      )}
      <TooltipContent
        icon={icon}
        position={position}
        className="tooltip__content"
      >
        {content}
      </TooltipContent>
    </TooltipView>
  )
}

export default Tooltip
