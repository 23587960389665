import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'
import { Checkbox } from '../Forms'

export const MenuCheckboxesView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${props => props.theme.layout.desktop.padding};
  margin-bottom: ${props => props.theme.layout.desktop.padding};
`

export const MenuCheckboxesSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const MenuCheckboxesOptionsView = styled.div`
  flex: 1 1 36rem;
  min-width: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};
  margin-bottom: 1rem;
`

export const MenuCheckboxesOptionsHeader = styled(TextHeading)`
  padding: 1.5rem;
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
`

export const MenuCheckboxesOptionsContent = styled.div`
  padding: 1.5rem;
`

export const MenuCheckboxesOptionsCategory = styled.div`
  margin: 0 0 3rem;

  &:last-of-type {
    margin: 0;
  }
`

export const MenuCheckboxesOptionsTitle = styled.div`
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
`

export const MenuCheckboxesOptionsAllCheckbox = styled(Checkbox)`
  margin: 0 1rem 0 0;
`

export const MenuCheckboxesOptionsList = styled.div`
  padding: 0;

  label {
    margin: 0 0 1rem;
  }

  input[type='checkbox'] ~ span {
    margin-top: 0.1rem;
    font-size: ${props => props.theme.sizes.small};
  }
`

export const MenuCheckboxesOptionsNone = styled(TextBody)`
  display: block;
  padding: 0 0 1.5rem;
`
