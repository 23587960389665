import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Scanners } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface ScannersState {
  data: Scanners
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface ScannersAPI {
  data: Scanners
  links: {
    next: string
  }
}

const initialState: ScannersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type ScannersParams = {
  cursor?: string
  is_active?: boolean
  limit?: number
  revenue_center_id?: number
  search?: string
  with_related?: string
}

export const fetchScanners = createAsyncThunk<
  ScannersAPI,
  ScannersParams | void,
  { state: RootState; rejectValue: RequestError }
>('scanners/fetchScanners', async (params, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `pos-v2/pos-scanners${queryParams}`
    return (await api?.request(endpoint)) as ScannersAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const scannersSlice = createSlice({
  name: 'scanners',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchScanners.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchScanners.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchScanners.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectScanners = (state: RootState) => state.scanners

export default scannersSlice.reducer
