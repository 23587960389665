import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { UserVpnConfig } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface UserVpnConfigState {
  data: UserVpnConfig | null
  loading: RequestStatus
  error: RequestError
}

const initialState: UserVpnConfigState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchUserVpnConfig = createAsyncThunk<
  UserVpnConfig,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'userVpnConfig/fetchUserVpnConfig',
  async (_, { getState, rejectWithValue }) => {
    try {
      const endpoint = 'pos-v2/user-vpn-config'
      const api = getState().authUser.api
      const resp = (await api?.request(endpoint)) as UserVpnConfig
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const userVpnConfigSlice = createSlice({
  name: 'userVpnConfig',
  initialState,
  reducers: {
    resetUserVpnConfig: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUserVpnConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchUserVpnConfig.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchUserVpnConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetUserVpnConfig } = userVpnConfigSlice.actions

export const selectUserVpnConfig = (state: RootState) => state.userVpnConfig

export default userVpnConfigSlice.reducer
