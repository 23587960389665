import { MenuRegenErrors, ModalWindow } from 'types'
import { ModalHeader } from 'components/Modal'
import TextBody from 'components/TextBody'

const ReviewPublishMenuError = ({
  args,
  modalWindow
}: {
  args: {
    publishMenuError: { status: string; errorType: string | null }
    errors: MenuRegenErrors
  }
  modalWindow: ModalWindow
}) => {
  const { publishMenuError, errors } = args

  return (
    <>
      <ModalHeader
        title={`${publishMenuError.errorType} Errors`}
        subtitle="The errors below are preventing your menus from generating"
      />
      <TextBody size="medium">{errors?.errors[0]?.error}</TextBody>
    </>
  )
}

export default ReviewPublishMenuError
