import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  FormFieldType,
  ModalWindow,
  OrderNotification as Notification
} from 'types'
import { closeModal } from 'slices/modal'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import {
  addOrderNotification,
  resetOrderNotification,
  selectOrderNotification,
  updateOrderNotification
} from 'slices/orderNotification'
import { RequestStatus, omit } from 'utils'
import { notificationTypeOptions } from 'config'

const OrderNotification = ({
  args,
  modalWindow
}: {
  args: {
    notification: Notification
    handleFetchNotifications: () => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { loading, error } = useAppSelector(selectOrderNotification)
  const isLoading = loading === RequestStatus.Pending
  const { notification, handleFetchNotifications } = args
  const formData = notification
    ? omit<Notification, 'notification_settings_id'>(
        notification,
        'notification_settings_id'
      )
    : orderNotificationEmpty

  const submit = async (values: Notification) => {
    if (notification)
      await dispatch(
        updateOrderNotification({
          data: values,
          orderNotificationId: notification.notification_settings_id
        })
      )
    else await dispatch(addOrderNotification({ data: values }))

    setSubmitted(true)
  }

  useEffect(() => {
    if (!notification) dispatch(resetOrderNotification())
  }, [dispatch, notification])

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
      handleFetchNotifications()
    }
  }, [dispatch, submitted, error, isLoading, handleFetchNotifications])

  return (
    <>
      <ModalHeader
        title={notification?.notification_type || 'New Notification'}
        subtitle={
          notification
            ? 'Update the content and status of this notification type'
            : 'Enter details for a new notification type.'
        }
      />

      <Form
        fields={orderNotificationFields(notification)}
        data={formData}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </>
  )
}

const serviceTypeOptions = [
  { value: 'PICKUP', text: 'Pick-Up' },
  { value: 'DELIVERY', text: 'Delivery' }
]

const orderNotificationEmpty = {
  notification_type: 'IN_PROGRESS',
  service_type: 'PICKUP',
  subject: '',
  message: '',
  is_active: false
}

const orderNotificationFields = (
  notification?: Notification
): FormFieldType[] => [
  {
    fieldType: 'Select',
    name: 'notification_type',
    label: 'Notification Type',
    options: notificationTypeOptions,
    required: true,
    disabled: notification && true,
    error: 'Please enter the type of notification.'
  },
  {
    fieldType: 'Select',
    name: 'service_type',
    options: serviceTypeOptions,
    label: 'Service Type'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'subject',
    label: 'Email Subject',
    required: true,
    error: 'Please enter a subject for the email.'
  },
  {
    fieldType: 'TextArea',
    type: 'text-area',
    name: 'message',
    label: 'Message',
    error: 'Please specify a message for hte notification',
    required: true
  },
  {
    fieldType: 'Checkbox',
    name: 'is_active',
    label: 'Active'
  }
]

export default OrderNotification
