import { useEffect, useState } from 'react'
import iacmConfigs from 'config/iacm'
import { IacmAuthHeaders, IacmAuthUser } from 'types'
import { useAppSelector } from 'app/hooks'
import { selectIacm } from 'slices/iacmUser'

const useIacmSignIn = () => {
  const iacm = useAppSelector(selectIacm)
  const [headers, setHeaders] = useState<IacmAuthHeaders | null>(null)
  const [user, setUser] = useState<IacmAuthUser | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const auth = {
      email: 'jc@itsacheckmate.com',
      password: 'something'
    }
    const signIn = async () => {
      const url = iacmConfigs.authUrl
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Expose-Headers':
              '[access-token, expiry, token-type, uid, client]'
          },
          body: auth ? JSON.stringify(auth) : null
        })
        const headers: IacmAuthHeaders = {
          accessToken: res.headers.get('access-token'),
          client: res.headers.get('client'),
          uid: res.headers.get('uid'),
          expiry: res.headers.get('expiry')
        }
        const json = await res.json()
        setHeaders(headers)
        setUser(json.data)
        setLoading(false)
      } catch (err) {
        setError(err as Error)
        setLoading(false)
      }
    }
    setLoading(true)
    if (iacm?.user && iacm?.headers) {
      setHeaders(iacm.headers)
      setUser(iacm.user)
      setLoading(false)
      setError(null)
    } else {
      signIn()
    }
  }, [iacm])

  return {
    headers,
    user,
    isLoading: loading,
    error
  }
}

export default useIacmSignIn
