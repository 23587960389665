import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Fonts } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface FontsState {
  data: Fonts
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface FontsAPI {
  data: Fonts
  links: {
    next: string
  }
}

export type FontsParams = {
  cursor?: string
  limit?: number
  with_related?: string
}

const initialState: FontsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchFonts = createAsyncThunk<
  FontsAPI,
  FontsParams | void,
  { state: RootState; rejectValue: RequestError }
>('fonts/fetchFonts', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `fonts${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as FontsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const publishFonts = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: RequestError }
>('fonts/publishFonts', async (_, { getState, dispatch, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    await api?.request('fonts/publish', 'POST')
    dispatch(showNotification('Fonts successfully published!'))
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const fontsSlice = createSlice({
  name: 'fonts',
  initialState,
  reducers: {
    resetFonts: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchFonts.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchFonts.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchFonts.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(publishFonts.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(publishFonts.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(publishFonts.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetFonts } = fontsSlice.actions

export const selectFonts = (state: RootState) => state.fonts

export const selectFontFamilies = createSelector(
  (state: RootState) => {
    return state.fonts.data
  },
  fonts => {
    const active = fonts.filter(i => i.is_active)
    const families = active.map(i => ({
      family: i.font_family,
      fallback: i.fallback
    }))
    const unique = Array.from(
      new Map(families.map(item => [item['family'], item])).values()
    )
    return unique
  }
)

export default fontsSlice.reducer
