import { forwardRef, ForwardedRef, ChangeEvent } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextEditorInterface } from 'types'
import Label from './Label/Label'

const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  ['clean']
]

const formats = ['header', 'bold', 'italic', 'list', 'bullet', 'indent']

const TextEditor = forwardRef(
  (
    {
      label,
      parenthetical,
      name,
      value,
      onChange,
      error,
      required,
      hideToolbar = false
    }: TextEditorInterface,
    ref: ForwardedRef<ReactQuill>
  ) => {
    const modules = { toolbar: hideToolbar ? false : toolbar }

    const handleOnChange = (content: string) => {
      const event = {
        target: { name, value: content }
      } as ChangeEvent<HTMLTextAreaElement>
      onChange?.(event)
    }

    return (
      <Label
        htmlFor={name}
        label={label}
        parenthetical={parenthetical}
        required={required}
        errMsg={error}
      >
        <ReactQuill
          value={value}
          onChange={handleOnChange}
          modules={modules}
          formats={formats}
          ref={ref}
        />
      </Label>
    )
  }
)

export default TextEditor
