import { useState } from 'react'
import { XmarkCircle } from 'iconoir-react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  PreviewSectionTitle,
  PreviewTag,
  PreviewTagsAdd,
  PreviewTagsAddItem,
  PreviewTagsFooter,
  PreviewTagsItems,
  PreviewTagsView
} from './Preview.styled'
import { RequestStatus, sortBy } from 'utils'
import Button from 'components/Button'
import TextBody from 'components/TextBody'
import { fetchItem, selectItem } from 'slices/item'
import { createItemTags, deleteItemTags } from 'slices/itemTags'
import { selectTags } from 'slices/tags'

const PreviewTags = ({ itemId }: { itemId: number }) => {
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(false)
  const { data: tags } = useAppSelector(selectTags)
  const { data, loading } = useAppSelector(selectItem)
  const isLoading = loading === RequestStatus.Pending
  const itemTags = data?.tags?.length
    ? sortBy(
        data?.tags.map(i => ({ ...i.tag })),
        'name'
      )
    : null
  const currentTags = itemTags ? itemTags.map(i => i.tag_id) : []
  const availableTags = tags.filter(i => !currentTags.includes(i.tag_id))

  const addTag = async (tagId: number) => {
    setShow(false)
    const data = [{ tag_id: tagId }]
    await dispatch(createItemTags({ itemId, data }))
    const params = { with_related: 'tags,allergens' }
    dispatch(fetchItem({ itemId, params }))
  }

  const removeTag = async (tagId: number) => {
    const data = [{ tag_id: tagId }]
    await dispatch(deleteItemTags({ itemId, data }))
    const params = { with_related: 'tags,allergens' }
    dispatch(fetchItem({ itemId, params }))
  }

  return (
    <PreviewTagsView>
      <PreviewSectionTitle as="p" size="xlarge">
        Tags
      </PreviewSectionTitle>
      {itemTags ? (
        <PreviewTagsItems>
          {itemTags.map(tag => (
            <PreviewTag
              key={tag.tag_id}
              size="xsmall"
              as="button"
              onClick={() => removeTag(tag.tag_id)}
            >
              <span>{tag.name}</span>
              <XmarkCircle width="1.4rem" height="1.4rem" strokeWidth={1} />
            </PreviewTag>
          ))}
        </PreviewTagsItems>
      ) : (
        <TextBody as="p" size="xsmall">
          This item doesn't have any tags.
        </TextBody>
      )}

      <PreviewTagsFooter>
        <Button
          size="small"
          color={show ? 'delete' : 'primary'}
          onClick={() => setShow(!show)}
          disabled={availableTags.length === 0}
        >
          {show ? 'Cancel add tag' : 'Add tag'}
        </Button>
        {availableTags.length > 0 && (
          <PreviewTagsAdd show={show}>
            {availableTags.map(i => (
              <PreviewTagsAddItem
                key={i.tag_id}
                onClick={() => addTag(i.tag_id)}
                disabled={isLoading}
              >
                {i.name}
              </PreviewTagsAddItem>
            ))}
          </PreviewTagsAdd>
        )}
      </PreviewTagsFooter>
    </PreviewTagsView>
  )
}

export default PreviewTags
