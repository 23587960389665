import React from 'react'

const Customer = React.lazy(() => import('./CustomerManagement/Customer'))
const Customers = React.lazy(() => import('./Customers'))
const CustomersRoutes = React.lazy(() => import('./CustomersRoutes'))

const HouseAccount = React.lazy(() => import('./HouseAccounts/HouseAccount'))
const HouseAccounts = React.lazy(() => import('./HouseAccounts/HouseAccounts'))
const HouseAccountsOutlet = React.lazy(
  () => import('./HouseAccounts/HouseAccountsOutlet')
)

export {
  Customers,
  CustomersRoutes,
  Customer,
  HouseAccount,
  HouseAccounts,
  HouseAccountsOutlet
}
