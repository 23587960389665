import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Segment } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { RootState } from 'app/store'

export interface SegmentState {
  data: Segment | null
  loading: RequestStatus
  error: RequestError
}

export interface SegmentAPI {
  data: Segment
}

const initialState: SegmentState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type SegmentCreate = Omit<
  Segment,
  'created_at' | 'marketing_segment_id' | 'updated_at'
>

export const fetchSegment = createAsyncThunk<
  Segment,
  { segmentId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'segment/fetchSegment',
  async ({ segmentId }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const endpoint = `segments/${segmentId}`
      const api = getState().authUser.api
      const result = (await api?.request(endpoint)) as Segment
      return fulfillWithValue(result)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createSegment = createAsyncThunk<
  Segment,
  { data: SegmentCreate },
  { state: RootState; rejectValue: RequestError }
>(
  'segment/createSegment',
  async ({ data }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const endpoint = 'segments'
      const api = getState().authUser.api
      const result = (await api?.request(endpoint, 'POST', {
        name: data.name,
        criteria: data.criteria || null,
        description: data.description || ' ',
        is_archived: data.is_archived || false,
        is_template: data.is_template || false
      })) as Segment
      return fulfillWithValue(result)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateSegment = createAsyncThunk<
  Segment,
  { segment: SegmentCreate; segmentId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'segment/updateSegment',
  async (
    { segment, segmentId },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = `segments/${segmentId}`
      const api = getState().authUser.api
      const result = (await api?.request(endpoint, 'PUT', {
        name: segment.name,
        criteria: segment.criteria || null,
        description: segment.description || ' ',
        is_archived: segment.is_archived ?? false,
        is_template: segment.is_template ?? false
      })) as Segment
      return fulfillWithValue(result)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteSegment = createAsyncThunk<
  Segment,
  { segmentId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'segment/deleteSegment',
  async ({ segmentId }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const endpoint = `segments/${segmentId}`
      const api = getState().authUser.api
      const result = (await api?.request(
        endpoint,
        'DELETE',
        segmentId
      )) as Segment
      return fulfillWithValue(result)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const segmentSlice = createSlice({
  name: 'segment',
  initialState,
  reducers: {
    resetSegment: () => initialState,
    setSegmentCriteria: (state, { payload }) => {
      if (state.data) state.data.criteria = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSegment.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(fetchSegment.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
      })
      .addCase(fetchSegment.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      })
      .addCase(createSegment.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(createSegment.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
      })
      .addCase(createSegment.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      })
      .addCase(updateSegment.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(updateSegment.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
      })
      .addCase(updateSegment.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      })
      .addCase(deleteSegment.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(deleteSegment.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Idle
      })
      .addCase(deleteSegment.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      })
  }
})

export const { resetSegment, setSegmentCriteria } = segmentSlice.actions

export const selectSegment = (state: RootState) => state.segment

export default segmentSlice.reducer
