import { useEffect, useRef, useState } from 'react'
import { useOnScreen } from 'hooks'
import styled from '@emotion/styled'

// https://stackoverflow.com/questions/21103672/chrome-video-element-canplay-event-not-firing

const VideoView = styled.video`
  width: 336px;
  min-height: 720px;
  height: auto;
  border-style: solid;
  border-width: 0.2rem;
  border-color: ${props => props.theme.colors.border.primary};
  border-radius: 2.4rem;
  transform: translateZ(0);
  background-color: #f8f6eb;

  &.loading {
    background: #f8f6eb center center no-repeat;
    background-size: 44px auto;
  }
`

interface VideoProps {
  src: string
  preload?: 'auto' | 'none'
  autoplay?: boolean
  threshold?: number
  className?: string
  style?: React.CSSProperties
}

const Video = ({
  src,
  preload = 'auto',
  autoplay = false,
  threshold = 0.25,
  className,
  style
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { isOnScreen } = useOnScreen(videoRef, threshold)
  const [loaded, setLoaded] = useState(false)
  const loadingClass = loaded ? '' : 'loading'
  const klass = `${className} ${loadingClass}`
  const video = videoRef.current
  const isReady = video ? video.readyState >= video.HAVE_FUTURE_DATA : false

  const onCanPlay = () => {
    setLoaded(true)
  }

  useEffect(() => {
    if (isReady) {
      setLoaded(true)
    }
  }, [isReady])

  useEffect(() => {
    if (isOnScreen) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }
  }, [isOnScreen])

  return (
    <>
      <VideoView
        ref={videoRef}
        preload={preload}
        autoPlay={autoplay}
        muted={true}
        loop={true}
        playsInline={true}
        style={style}
        className={klass}
        onCanPlay={onCanPlay}
      >
        <source src={src} type="video/mp4" />
        <a href={src}>Download the video.</a>
      </VideoView>
    </>
  )
}

export default Video
