import React, { createContext } from 'react'
import { BeeEditorVariants } from 'types'
import Bee, { beeTypes } from '@mailupinc/bee-plugin'
import { IBeeFilePickerState } from './useBeeFileManager'

export interface IBeeEditorState extends IBeeFilePickerState {
  variant?: BeeEditorVariants
  editorInstance?: Bee
  previewStatus?: boolean
  pageJson?: string
  pageHtml?: string
  pageText?: string
  error?: beeTypes.BeePluginError
  isLoading?: boolean
  templateJson?: string
  editorRef?: React.RefObject<HTMLDivElement>
  editorConfig?: beeTypes.IBeeConfig
  autoSaveJson?: string
  ready?: boolean
  hasChanges?: boolean
  hasAutoSaveChanges?: boolean
  workspace?: beeTypes.BeePluginWorkspace
  save?: () => void
  togglePreview?: () => void
  toggleStructure?: () => void
  toggleStageMode?: () => void
  toggleStageModeDisplay?: () => void
}

export const BeeEditorContext = createContext<IBeeEditorState>({})

BeeEditorContext.displayName = 'BeeEditorContext'

export default BeeEditorContext
