import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { FullOrderType, Menus } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface MenusState {
  data: Menus
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface MenusAPI {
  data: Menus
  links: {
    next: string
  }
}

const initialState: MenusState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type MenusParams = {
  limit?: number
  cursor?: string
  with_related?: string
  order_type?: FullOrderType
  is_active?: boolean
}

export const fetchMenus = createAsyncThunk<
  MenusAPI,
  MenusParams | void,
  { state: RootState; rejectValue: RequestError }
>('menus/fetchMenus', async (params, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `menus${queryParams}`
    return (await api?.request(endpoint)) as MenusAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchMenus.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectMenus = (state: RootState) => state.menus
export const selectMenusByType =
  (orderType: FullOrderType) => (state: RootState) => {
    return state.menus.data.filter(i => i.order_type === orderType)
  }

export default menusSlice.reducer
