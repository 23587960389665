import styled from '@emotion/styled'
import { File, UploadCloud } from 'react-feather'
import { DeleteFile } from '.'
import FileProgress from './FileProgress'
import UploadHeader from './UploadHeader'

interface PDFProps {
  name: string
  file?: {
    file_id: string
    file_type: string
    file_url: string
  }
  deleteFile?: (fileId: number) => void
  progState: string
  progPercent: number
  fileType: string
  processingFileType: string
  isProcessing: boolean
}

interface PDFViewProps {
  isProcessing: boolean
  hasFile: boolean
}

interface PDFViewUploadProps {
  isProcessing: boolean
}

const PDFViewUpload = styled.div<PDFViewUploadProps>`
  padding: 0 2rem;
  width: 100%;
  height: 5rem;
  border-radius: ${props => props.theme.borderRadius.small};
  align-items: center;
  opacity: ${props => (props.isProcessing ? 0 : 1)};
  visibility: ${props => (props.isProcessing ? 'hidden' : 'visible')};

  span:first-of-type {
    width: 3.2rem;
  }
`

const PDFView = styled.div<PDFViewProps>`
  min-height: 5rem;
  display: flex;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }
`

const PDF = ({
  name,
  file,
  deleteFile,
  progPercent,
  isProcessing
}: PDFProps) => {
  const filename = file
    ? file.file_url.split('/')[file.file_url.split('/').length - 1]
    : ''

  const fileId = file && file.file_id ? parseInt(file.file_id) : null

  return (
    <PDFView hasFile={!!file} isProcessing={isProcessing}>
      {file ? (
        <UploadHeader icon={<File size={20} />} text={filename} />
      ) : (
        <PDFViewUpload isProcessing={isProcessing}>
          <span>
            <UploadCloud size={20} />
          </span>
          <span>Click to upload a {name}</span>
        </PDFViewUpload>
      )}
      {isProcessing && <FileProgress progPercent={progPercent} />}
      {fileId && deleteFile ? (
        <DeleteFile deleteFile={deleteFile} fileId={fileId} />
      ) : null}
    </PDFView>
  )
}

export default PDF
