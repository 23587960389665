import { useContext, useEffect, useMemo, useState } from 'react'
import { MinusCircle } from 'iconoir-react'
import { ButtonNew } from 'components'
import { listFilterEmptyCondition } from 'config'
import { Keyword, KeywordConfig as KeywordConfigType, ListFilter } from 'types'
import ListFilterCategory from './ListFilterCategory'
import ListFilterOperator from './ListFilterOperator'
import ListFilterAndOr from './ListFilterAndOr'
import ListFilterKeyword from './ListFilterKeyword'
import { ListFilterContext } from './ListFilter'
import ListFilters from './ListFilters'
import {
  ListFilterConditionView,
  ListFilterKeywords,
  ListFilterRemove,
  ListFilterRemoveButton
} from './ListFilter.styled'

interface ListFilterConditionProps {
  totalConditions: number
  listFilter: ListFilter
  index: number
  addCondition: () => void
  updateCondition: (index: number, values: ListFilter) => void
  removeCondition: (index: number) => void
}

const ListFilterCondition = ({
  totalConditions,
  listFilter,
  index,
  addCondition,
  updateCondition,
  removeCondition
}: ListFilterConditionProps) => {
  const { categories } = useContext(ListFilterContext) || {}
  const [disabled, setDisabled] = useState<boolean>(false)
  const [andOr, setAndOr] = useState<string | null>(index === 0 ? '' : 'AND')
  const [category, setCategory] = useState<string | null>(listFilter.category)
  const [operator, setOperator] = useState<string | null>(listFilter.operator)
  const [keyword, setKeyword] = useState<Keyword>(listFilter.value)
  const [keywordConfig, setKeywordConfig] = useState<KeywordConfigType | null>(
    null
  )
  const [secondKeyword, setSecondKeyword] = useState<Keyword>(
    listFilter.secondValue || ''
  )
  const [secondKeywordConfig, setSecondKeywordConfig] =
    useState<KeywordConfigType | null>(null)

  const operatorOptions = useMemo(
    () =>
      categories?.find(c => c.value === category)?.operatorConfig.options || [],
    [categories, category]
  )
  const lastIndex = totalConditions - 1
  const isLast = index === lastIndex
  const isFirst = index === 0

  useEffect(() => {
    updateCondition(index, {
      AndOr: andOr,
      category,
      operator,
      value: keyword,
      secondValue: operator === 'range' ? secondKeyword : ''
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, operator, keyword, andOr, index, secondKeyword])

  useEffect(() => {
    if (category) {
      setOperator(
        categories?.find(c => c.value === category)?.operatorConfig
          .defaultValue || ''
      )
      setKeywordConfig(
        categories?.find(c => c.value === category)?.keywordConfig || null
      )
      setSecondKeywordConfig(
        categories?.find(c => c.value === category)?.secondKeywordConfig || null
      )
      setKeyword(
        categories?.find(c => c.value === category)?.keywordConfig
          .defaultValue || ''
      )
      setDisabled(
        categories?.find(c => c.value === category)?.operatorConfig.disabled ||
          false
      )
    }
  }, [categories, category])

  useEffect(() => {
    if (totalConditions === 1 && listFilter === listFilterEmptyCondition) {
      setOperator(listFilterEmptyCondition.operator)
      setKeyword(listFilterEmptyCondition.value)
      setSecondKeyword(listFilterEmptyCondition.secondValue || null)
      setCategory(listFilterEmptyCondition.category)
      setAndOr(listFilterEmptyCondition.AndOr)
    }
  }, [listFilter, totalConditions])

  return (
    <ListFilterConditionView>
      <ListFilters>
        <ListFilterCategory category={category} setCategory={setCategory} />
        <ListFilterOperator
          operator={operator}
          setOperator={setOperator}
          options={operatorOptions}
          disabled={disabled}
        />
        <ListFilterKeywords>
          <ListFilterKeyword
            keywordConfig={keywordConfig}
            keyword={keyword}
            setKeyword={setKeyword}
          />
          {operator === 'range' ? (
            <ListFilterKeyword
              keywordConfig={secondKeywordConfig}
              keyword={secondKeyword}
              setKeyword={setSecondKeyword}
            />
          ) : null}
        </ListFilterKeywords>
        {isLast ? (
          <ButtonNew size="small" color="secondary" onClick={addCondition}>
            Add Filter
          </ButtonNew>
        ) : (
          <ListFilterAndOr andOr={andOr} setAndOr={setAndOr} />
        )}
      </ListFilters>
      {!isFirst && (
        <ListFilterRemove>
          <ListFilterRemoveButton onClick={() => removeCondition(index)}>
            <MinusCircle width={18} height={18} strokeWidth={1} />
          </ListFilterRemoveButton>
        </ListFilterRemove>
      )}
    </ListFilterConditionView>
  )
}

export default ListFilterCondition
