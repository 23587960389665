import styled from '@emotion/styled'

const ModalContent = styled.div`
  p {
    margin: 1em 0;
    line-height: ${props => props.theme.layout.desktop.lineHeight};
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      line-height: ${props => props.theme.layout.mobile.lineHeight};
    }
  }
`

export default ModalContent
