import { toDate } from 'date-fns-tz'
import { CustomerAddresses } from 'types'
import { sortBy } from './helpers'

export const sortAddresses = (addresses: CustomerAddresses) => {
  const withLastUsed = addresses
    .map(i => {
      const lastUsed = i.last_used_at ? toDate(i.last_used_at) : null
      return { ...i, lastUsed }
    })
    .filter(i => i.lastUsed !== null)
  return sortBy(withLastUsed, 'lastUsed', 'desc').slice(0, 25)
}
