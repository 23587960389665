import { NavLink } from 'react-router-dom'
import { SidebarLinkProps } from 'types'
import {
  SidebarLinkIcon,
  SidebarLinkText,
  SidebarLinkView
} from './Sidebar.styled'

const SidebarLink = ({ path, title, icon, end = false }: SidebarLinkProps) => {
  return (
    <SidebarLinkView>
      <NavLink to={path} end={end}>
        <SidebarLinkIcon>{icon}</SidebarLinkIcon>
        <SidebarLinkText>{title}</SidebarLinkText>
      </NavLink>
    </SidebarLinkView>
  )
}

export default SidebarLink
