import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Allergen } from 'types'
import { showNotification } from 'slices/notification'

export interface AllergenState {
  data: Allergen | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AllergenState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAllergen = createAsyncThunk<
  Allergen,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'allergen/fetchAllergen',
  async (allergenId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `allergens/${allergenId}`
      const resp = (await api?.request(endpoint)) as Allergen
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createAllergen = createAsyncThunk<
  Allergen,
  { data: Allergen; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'allergen/createAllergen',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('allergens', 'POST', data)) as Allergen
      dispatch(showNotification('Allergen successfully created!'))
      const pathname = `/settings/misc/allergens/${resp.allergen_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateAllergen = createAsyncThunk<
  Allergen,
  {
    allergenId: string | number
    data: Allergen
  },
  { state: RootState; rejectValue: RequestError }
>(
  'allergen/updateAllergen',
  async ({ allergenId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `allergens/${allergenId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Allergen
      dispatch(showNotification('Allergen successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteAllergen = createAsyncThunk<
  void,
  {
    allergenId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'allergen/deleteAllergen',
  async ({ allergenId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `allergens/${allergenId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Allergen removed!'))
      navigate({ pathname: '/settings/misc/allergens' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const allergenSlice = createSlice({
  name: 'allergen',
  initialState,
  reducers: {
    resetAllergen: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAllergen.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAllergen.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchAllergen.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createAllergen.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createAllergen.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createAllergen.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateAllergen.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateAllergen.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateAllergen.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteAllergen.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteAllergen.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteAllergen.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAllergen } = allergenSlice.actions

export const selectAllergen = (state: RootState) => state.allergen

export default allergenSlice.reducer
