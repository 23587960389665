import { ModalWindow, ServiceType } from 'types'
import { orderServiceTypeOptions } from 'config'
import { ButtonList } from 'components'
import { ModalCancel, ModalHeader } from 'components/Modal'

export interface OrderServiceTypeArgs {
  orderId?: number
  serviceType: ServiceType
  change: (serviceType: ServiceType) => void
}

const OrderServiceType = ({
  args,
  modalWindow
}: {
  args: OrderServiceTypeArgs
  modalWindow: ModalWindow
}) => {
  const { orderId, serviceType, change } = args
  const preface = orderId ? `Order #${orderId}` : null

  const buttons = orderServiceTypeOptions.map(i => ({
    title: i.text,
    onClick: () => change(i.value as ServiceType),
    disabled: i.value === serviceType
  }))

  return (
    <>
      <ModalHeader
        preface={preface}
        title="Adjust service type"
        subtitle="Choose a differen service type (or not)"
      />
      <ButtonList buttons={buttons} />
      <ModalCancel>Nevermind, keep current service type</ModalCancel>
    </>
  )
}

export default OrderServiceType
