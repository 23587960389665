import { InputHTMLAttributes } from 'react'
import ReactQuill from 'react-quill'

export type FieldType =
  | 'WebEditor'
  | 'EmailEditor'
  | 'Input'
  | 'Select'
  | 'TextArea'
  | 'TextEditor'
  | 'Checkbox'
  | 'Radio'
  | 'RadioGroup'
  | 'Legend'
  | 'Columns'
  | 'Switch'

export type FieldValue = string | number | boolean | null

export interface LabelInterface {
  htmlFor?: string
  label?: string
  parenthetical?: string
  tooltip?: string
  required?: boolean
  errMsg?: string
  children?: React.ReactNode
}

export interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
  fieldType?: FieldType
  type: string
  name: string
  label?: string
  parenthetical?: string
  tooltip?: string
  hint?: string
  error?: string
  nullable?: boolean
  isInternal?: boolean
  hideIcon?: boolean
  setValue?: (value: string | number, newValue: string | number | null) => void
  icon?: React.ReactNode
}

export interface TextAreaInterface {
  fieldType?: FieldType
  name: string
  label?: string
  parenthetical?: string
  tooltip?: string
  hint?: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
  error?: string
  value?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  isInternal?: boolean
}

export interface TextEditorInterface extends TextAreaInterface {
  onChange?: (value: any) => void
  hideToolbar?: boolean
  ref?: React.RefObject<ReactQuill>
}

export interface RadioGroupOptionInterface {
  value: string
  text: string
  content?: string
}

export type RadioGroupOptions = RadioGroupOptionInterface[]

export interface SelectOptionInterface {
  value: string | number | undefined | null
  text: string
}

export type SelectOptions = SelectOptionInterface[]

export interface SelectInterface {
  fieldType?: FieldType
  name: string
  options: SelectOptionInterface[]
  label?: string
  inline?: boolean
  parenthetical?: string
  tooltip?: string
  hint?: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
  error?: string
  value?: string | number | null | undefined
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined
  isInternal?: boolean
  getGroupLabel?: (option: SelectOptionInterface) => string
  placeholder?: string
}

export interface CheckboxInterface {
  fieldType?: FieldType
  label?: string
  parenthetical?: string
  tooltip?: string
  hint?: string
  name: string
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  error?: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
  isInternal?: boolean
  className?: string
}

export interface LegendInterface {
  fieldType?: FieldType
  label: string
  error?: string
  isInternal?: boolean
  name: string
  subtitle?: string
}

export interface ColumnOption {
  value: string
  text: string
}

export type ColumnOptions = ColumnOption[]

export interface SwitchInterface {
  fieldType?: FieldType
  label: string
  parenthetical?: string
  tooltip?: string
  hint?: string
  name: string
  value?: boolean
  onChange: (value: boolean) => void
  error?: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
}

export interface ColumnsInterface {
  fieldType?: FieldType
  label: string
  parenthetical?: string
  tooltip?: string
  hint?: string
  name: string
  value?: string[]
  options: ColumnOptions
  onChange: (value: string[]) => void
  error?: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
  isInternal?: boolean
}
export type BeeEditorVariants =
  | 'web'
  | 'email'
  | 'row'
  | 'file-manager'
  | 'text'

export interface RadioGroupInterface {
  fieldType?: FieldType
  name: string
  label?: string
  options: RadioGroupOptions
  value: string
  error?: string
  isInternal?: boolean
  variant?: 'bordered' | 'default'
  onChange: (value: string) => void
}

export type FormFieldType =
  | InputInterface
  | TextAreaInterface
  | TextEditorInterface
  | SelectInterface
  | CheckboxInterface
  | LegendInterface
  | ColumnsInterface
  | RadioGroupInterface

export const isInput = (field: FormFieldType): field is InputInterface => {
  return (field as InputInterface).fieldType === 'Input'
}

export const isSelect = (field: FormFieldType): field is SelectInterface => {
  return (field as SelectInterface).fieldType === 'Select'
}

export const isTextArea = (
  field: FormFieldType
): field is TextAreaInterface => {
  return (field as TextAreaInterface).fieldType === 'TextArea'
}

export const isTextEditor = (
  field: FormFieldType
): field is TextEditorInterface => {
  return (field as TextEditorInterface).fieldType === 'TextEditor'
}

export const isCheckbox = (
  field: FormFieldType
): field is CheckboxInterface => {
  return (field as CheckboxInterface).fieldType === 'Checkbox'
}

export const isLegend = (field: FormFieldType): field is LegendInterface => {
  return (field as LegendInterface).fieldType === 'Legend'
}

export const isColumns = (field: FormFieldType): field is ColumnsInterface => {
  return (field as ColumnsInterface).fieldType === 'Columns'
}

export const isSwitch = (field: FormFieldType): field is SwitchInterface => {
  return (field as SwitchInterface).fieldType === 'Switch'
}

export const isRadioGroup = (
  field: FormFieldType
): field is RadioGroupInterface => {
  return (field as RadioGroupInterface).fieldType === 'RadioGroup'
}
