import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RecommendedItems } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'

export interface RecommendedItemsState {
  data: RecommendedItems
  loading: RequestStatus
  error: RequestError
}

const initialState: RecommendedItemsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export type RecommendedItemsParams = {
  expand?: string
}

export const fetchCategoryRecommended = createAsyncThunk<
  RecommendedItems,
  { categoryId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'recommendedItems/fetchCategoryRecommended',
  async ({ categoryId }, { getState, rejectWithValue }) => {
    try {
      const endpoint = `categories/${categoryId}/recommended-items`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as RecommendedItems
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchItemRecommended = createAsyncThunk<
  RecommendedItems,
  {
    categoryId: string | number
    itemId: string | number
    params: RecommendedItemsParams | void
  },
  { state: RootState; rejectValue: RequestError }
>(
  'recommendedItems/fetchItemRecommended',
  async ({ categoryId, itemId, params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `categories/${categoryId}/items/${itemId}/recommended-items${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as RecommendedItems
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCategoryRecommended = createAsyncThunk<
  RecommendedItems,
  { data: RecommendedItems; categoryId: string | number },
  { state: RootState; rejectValue: RequestError }
>(
  'recommendedItems/updateCategoryRecommended',
  async ({ data, categoryId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `categories/${categoryId}/recommended-items`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as RecommendedItems
      dispatch(
        showNotification('Upsells saved successfully. Publish menu required.')
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItemRecommended = createAsyncThunk<
  RecommendedItems,
  {
    categoryId: string | number
    itemId: string | number
    data: RecommendedItems
  },
  { state: RootState; rejectValue: RequestError }
>(
  'recommendedItems/updateItemRecommended',
  async (
    { categoryId, itemId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `categories/${categoryId}/items/${itemId}/recommended-items`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as RecommendedItems
      dispatch(showNotification('Recommened Items updated successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteCategoryRecommended = createAsyncThunk<
  void,
  {
    categoryId: string | number
    data: RecommendedItems
  },
  { state: RootState; rejectValue: RequestError }
>(
  'recommendedItems/deleteCategoryRecommended',
  async ({ categoryId, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `categories/${categoryId}/recommended-items`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Recommened Items updated successfully'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteItemRecommended = createAsyncThunk<
  void,
  {
    categoryId: string | number
    itemId: string | number
    data: RecommendedItems
  },
  { state: RootState; rejectValue: RequestError }
>(
  'recommendedItems/deleteItemRecommended',
  async (
    { categoryId, itemId, data },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `categories/${categoryId}/items/${itemId}/recommended-items`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Recommened Items updated successfully'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const recommendedItemsSlice = createSlice({
  name: 'recommendedItems',
  initialState,
  reducers: {
    resetRecommendedItems: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemRecommended.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemRecommended.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchItemRecommended.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchCategoryRecommended.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchCategoryRecommended.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(fetchCategoryRecommended.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItemRecommended.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItemRecommended.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(updateItemRecommended.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCategoryRecommended.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateCategoryRecommended.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      updateCategoryRecommended.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteCategoryRecommended.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteCategoryRecommended.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(
      deleteCategoryRecommended.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteItemRecommended.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteItemRecommended.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteItemRecommended.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetRecommendedItems } = recommendedItemsSlice.actions

export const selectRecommendedItems = (state: RootState) =>
  state.recommendedItems

export default recommendedItemsSlice.reducer
