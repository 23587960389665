import { NavSectionSubsection, NavSections as NavSectionsType } from 'types'
import {
  NavSection,
  NavSectionHeader,
  NavSectionLinkContent,
  NavSectionLinkIcon,
  NavSectionLinkTitle,
  NavSectionLinkView,
  NavSectionLinks,
  NavSectionTitle,
  NavSectionsView
} from './NavSections.styled'
import { IconoirProvider } from 'iconoir-react'
import { Link } from 'react-router-dom'
import TextBody from 'components/TextBody'

const NavSectionLink = ({
  link,
  index
}: {
  link: NavSectionSubsection
  index: number
}) => {
  const { icon, path, title } = link
  const number = (index + 1).toString().padStart(2, '0')
  return (
    <NavSectionLinkView>
      <Link to={path}>
        <NavSectionLinkContent>
          <NavSectionLinkIcon>{icon}</NavSectionLinkIcon>
          <NavSectionLinkTitle>{title}</NavSectionLinkTitle>
        </NavSectionLinkContent>
        <TextBody>{number}</TextBody>
      </Link>
    </NavSectionLinkView>
  )
}

const NavSections = ({ sections }: { sections: NavSectionsType }) => {
  return (
    <NavSectionsView>
      <IconoirProvider
        iconProps={{
          width: 14,
          height: 14,
          strokeWidth: 1.5
        }}
      >
        {sections.map(section => (
          <NavSection key={section.title} className="fadeUp">
            <NavSectionHeader>
              <NavSectionTitle size="xlarge">{section.title}</NavSectionTitle>
            </NavSectionHeader>
            <NavSectionLinks>
              {section.subsections.map((link, index) => (
                <NavSectionLink key={link.path} link={link} index={index} />
              ))}
            </NavSectionLinks>
          </NavSection>
        ))}
      </IconoirProvider>
    </NavSectionsView>
  )
}

export default NavSections
