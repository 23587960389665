import { useEffect, useState } from 'react'
import { fromAddress, setKey } from 'react-geocode'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { formattedAddressFields, states } from 'config'
import configs from 'config/enviroment'
import { omit, RequestStatus } from 'utils'
import { CustomerAddress, FormFieldType, FormattedAddress } from 'types'
import {
  createCustomerAddress,
  fetchCustomerAddress,
  resetCustomerAddress,
  selectCustomerAddress,
  updateCustomerAddress
} from 'slices/customerAddress'
import { ErrMsg, Form } from 'components'

setKey(configs.googleMapsApiKey)

const makeAddress = (address: FormattedAddress) => {
  let formattedAddress = null,
    errorAddress = null
  const missing = formattedAddressFields.filter(i => !address[i])
  if (missing.length) {
    errorAddress = `${missing.join(', ')} are required`
  } else {
    const { street, city, state, postal_code } = address
    formattedAddress = `${street}, ${city}, ${state} ${postal_code}, USA`
  }
  return { formattedAddress, errorAddress }
}

const CustomerAddressForm = ({
  customerId,
  customerAddressId,
  callback
}: {
  customerId: string | number
  customerAddressId: string | number
  callback?: (address: CustomerAddress) => void
}) => {
  const dispatch = useAppDispatch()
  const [geocodeError, setGeocodeError] = useState<string | null>(null)
  const [submitted, setSubmitted] = useState(false)
  const { data, loading, error } = useAppSelector(selectCustomerAddress)
  const isLoading = loading === RequestStatus.Pending

  const formData =
    customerAddressId !== 'new' && data?.customer_address_id
      ? omit<CustomerAddress, 'customer_address_id'>(
          data,
          'customer_address_id'
        )
      : addressEmpty

  const submit = (values: CustomerAddress) => {
    delete values.last_used_at
    delete values.created_at
    values.customer_id = Number(customerId)
    values.country = ''
    const { formattedAddress, errorAddress } = makeAddress(values)
    if (errorAddress || !formattedAddress) {
      window.scrollTo(0, 0)
      setGeocodeError(errorAddress || 'Unable to format address')
    } else {
      fromAddress(formattedAddress).then(
        resp => {
          const { lat, lng } = resp.results[0].geometry.location
          const data: CustomerAddress = {
            ...values,
            formatted_address: formattedAddress,
            latitude: lat.toString(),
            longitude: lng.toString()
          }
          setGeocodeError(null)
          setSubmitted(true)
          if (customerAddressId === 'new') {
            dispatch(createCustomerAddress({ data }))
          } else if (customerAddressId) {
            dispatch(updateCustomerAddress({ customerAddressId, data }))
          }
        },
        err => {
          setGeocodeError(err)
        }
      )
    }
  }

  useEffect(() => {
    if (customerAddressId === 'new') {
      dispatch(resetCustomerAddress())
    } else {
      dispatch(fetchCustomerAddress(customerAddressId))
    }
  }, [customerAddressId, dispatch])

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (!error && data && callback) {
        callback(data)
      }
    }
  }, [submitted, isLoading, error, data, callback])

  if (!customerAddressId) return null

  return (
    <>
      {geocodeError && <ErrMsg errMsg={geocodeError} />}
      <Form
        fields={addressFields}
        data={formData || addressEmpty}
        error={error}
        isLoading={isLoading}
        submit={submit}
      />
    </>
  )
}

const addressEmpty = {
  street: '',
  street2: '',
  unit: '',
  city: '',
  state: '',
  postal_code: '',
  company: '',
  description: '',
  contact: '',
  phone: '',
  notes: '',
  is_active: true,
  is_default: false
}

const addressFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'street',
    required: true,
    label: 'Street',
    error: `Please add the street address.`
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'street2',
    label: 'Street Line 2'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'unit',
    label: 'Unit'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'city',
    required: true,
    label: 'City',
    error: `Please add the name of your city.`
  },
  {
    fieldType: 'Select',
    name: 'state',
    options: states,
    label: 'State'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'postal_code',
    required: true,
    label: 'Zip Code',
    error: `Please add the ZIP Code of your area.`
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'company',
    label: 'Company'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'description',
    label: 'Description'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'contact',
    label: 'Contact'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'phone',
    label: 'Phone'
  },
  {
    fieldType: 'TextArea',
    type: 'text-area',
    name: 'notes',
    label: 'Address Notes'
  },
  {
    fieldType: 'Checkbox',
    name: 'is_default',
    label: 'Default'
  },
  {
    fieldType: 'Checkbox',
    name: 'is_active',
    label: 'Active'
  }
]

export default CustomerAddressForm
