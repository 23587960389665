import styled from '@emotion/styled'

export const MenuItemQuantityView = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const MenuItemQuantityButton = styled.div`
  button {
    height: auto;
    padding: 0.3rem;
    border-radius: 50px;
    // border-radius: 100%;
  }

  button:disabled {
    opacity: 0.25;
  }
`

export const MenuItemQuantityInput = styled.div`
  label {
    margin: 0;
  }

  input {
    width: 5rem;
    padding: 0.5rem 0;
    text-align: center;
    font-size: ${props => props.theme.sizes.small};
    line-height: ${props => props.theme.inputs.lineHeight};

    appearance: textfield;
    -moz-appearance: textfield;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
`
