import { useEffect } from 'react'
import { ThemeFontSizes } from 'types'
import {
  ContentContainer,
  ContentContent,
  ContentHeader,
  ContentSubtitle,
  ContentView
} from './Content.styled'
import ContentTitle from './ContentTitle'

const Content = ({
  title,
  subtitle,
  size = 'h1',
  maxWidth,
  content,
  children,
  crumbs
}: {
  title?: string | React.ReactNode
  subtitle?: string
  size?: keyof ThemeFontSizes
  maxWidth?: string
  content?: React.ReactNode
  children: React.ReactNode
  crumbs?: React.ReactNode
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ContentView>
      {crumbs}
      <ContentContainer hasCrumbs={!!crumbs}>
        <ContentContent maxWidth={maxWidth}>
          {title || content ? (
            <ContentHeader>
              {title && (
                <ContentTitle size={size}>
                  {title}
                  {subtitle && (
                    <ContentSubtitle as="p">{subtitle}</ContentSubtitle>
                  )}
                </ContentTitle>
              )}
              {content}
            </ContentHeader>
          ) : null}
          {children}
        </ContentContent>
      </ContentContainer>
    </ContentView>
  )
}

export default Content
