import { useEffect } from 'react'
import { useAppDispatch } from 'app/hooks'
import { Category, ModalWindow } from 'types'
import { closeModal } from 'slices/modal'
import { Files, MenuCategoryForm } from 'components'
import { fetchCategory } from 'slices/category'
import {
  PreviewForm,
  PreviewImages,
  PreviewSectionTitle,
  PreviewTitle,
  PreviewView,
  PreviewWrapper
} from './Preview.styled'

const PreviewCategory = ({
  args,
  modalWindow
}: {
  args: { menuId: number; data: Category; callback: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { menuId, data, callback } = args
  const categoryId = data.category_id

  const previewCallback = () => {
    dispatch(closeModal())
  }

  useEffect(() => {
    const params = { with_related: 'items,files' }
    dispatch(fetchCategory({ categoryId, params }))
  }, [dispatch, categoryId])

  useEffect(() => {
    return () => {
      if (callback) callback()
    }
  }, [callback])

  return (
    <PreviewWrapper>
      <PreviewTitle as="p" size="xxxlarge">
        {data.full_name}
      </PreviewTitle>
      <PreviewView>
        <PreviewForm>
          <PreviewSectionTitle as="p" size="xlarge">
            Settings
          </PreviewSectionTitle>
          <MenuCategoryForm
            menuId={menuId}
            categoryId={categoryId}
            scrollWindow={modalWindow}
            callback={previewCallback}
          />
        </PreviewForm>
        <PreviewImages>
          <PreviewSectionTitle as="p" size="xlarge">
            Images
          </PreviewSectionTitle>
          <Files
            endpoint={`categories/${categoryId}/files`}
            displayType="SMALL"
            config={[
              { name: 'Raw Image', type: 'RAW' },
              { name: 'Small Image', type: 'SMALL_IMAGE' },
              { name: 'Large Image', type: 'LARGE_IMAGE' },
              { name: 'App Image', type: 'APP_IMAGE' }
            ]}
          />
        </PreviewImages>
      </PreviewView>
    </PreviewWrapper>
  )
}

export default PreviewCategory
