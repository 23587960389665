import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LoyaltyCustomers } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface LoyaltyCustomersState {
  data: LoyaltyCustomers
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface LoyaltyCustomersAPI {
  data: LoyaltyCustomers
  links: {
    next: string
  }
}

export type LoyaltyCustomersParams = {
  cursor?: string
  customer_id?: number
  expand?: string
  limit?: number
  loyalty_program_id?: number
  loyalty_tier_id?: number
}

const initialState: LoyaltyCustomersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchLoyaltyCustomers = createAsyncThunk<
  LoyaltyCustomersAPI,
  LoyaltyCustomersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyCustomers/fetchLoyaltyCustomers',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `loyalty-customers${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as LoyaltyCustomersAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCreditBalance = createAsyncThunk<
  void,
  { customerId: number; loyaltyProgramId: number; amount: string },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyCustomers/updateCreditBalance',
  async (
    { customerId, loyaltyProgramId, amount },
    { getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const data = {
        customer_id: customerId,
        loyalty_program_id: loyaltyProgramId,
        amount
      }
      const endpoint = `loyalty-customers/credit`
      await api?.request(endpoint, 'POST', data)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyCustomersSlice = createSlice({
  name: 'loyaltyCustomers',
  initialState,
  reducers: {
    resetLoyaltyCustomers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyCustomers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyCustomers.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchLoyaltyCustomers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCreditBalance.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCreditBalance.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateCreditBalance.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyCustomers } = loyaltyCustomersSlice.actions

export const selectLoyaltyCustomers = (state: RootState) =>
  state.loyaltyCustomers

export default loyaltyCustomersSlice.reducer
