import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { FileType, ProcessingState } from 'types'
import { RequestError } from 'utils/api'
import fileRequest from 'utils/fileRequest'

export interface FilesState {
  state: ProcessingState
  percentage: number
  data: Array<FileType>
  links: {
    next: number | null
  }
  error: RequestError | null
  processingFileType: string
}

export interface FilesApi {
  data: Array<FileType>
  links: {
    next: number | null
  }
}

const initialState: FilesState = {
  state: ProcessingState.Inactive,
  percentage: 0,
  data: [],
  links: {
    next: null
  },
  error: null,
  processingFileType: ''
}

export const fetchFiles = createAsyncThunk<
  FilesApi,
  string,
  { state: RootState; rejectValue: RequestError }
>('file/fetchFiles', async (endpoint, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request(endpoint, 'GET')) as FilesApi
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createFile = createAsyncThunk<
  null,
  { file: File | null | undefined; endpoint: string; fileType: string },
  { state: RootState; rejectValue: RequestError }
>(
  'file/createFile',
  async (
    { file, endpoint, fileType },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setprocessingFileType(fileType))
      const state = getState()

      const handleProgress = (percentage: number) => {
        dispatch(
          setProgress({
            state: 'pending',
            percentage
          })
        )
      }

      if (file) {
        await fileRequest({
          onProgress: handleProgress,
          url: endpoint,
          token: state.auth.token,
          brandId: state.authUser.brand?.brand_id,
          dispatch,
          fileType,
          file,
          fileName: file?.name
        })
        dispatch(fetchFiles(endpoint))
        return null
      }

      return null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteFile = createAsyncThunk<
  null,
  { endpoint: string; fileId: number; fileType: string },
  { state: RootState; rejectValue: RequestError }
>(
  'file/deleteFile',
  async (
    { endpoint, fileId, fileType },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setprocessingFileType(fileType))
      const api = getState().authUser.api
      ;(await api?.request(`${endpoint}/${fileId}`, 'DELETE')) as FilesApi

      dispatch(fetchFiles(endpoint))
      return null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    resetFiles: () => initialState,
    setProgress: (state, action) => {
      state.percentage = action.payload.percentage
      state.state = ProcessingState.Processing
    },
    setprocessingFileType: (state, action) => {
      state.processingFileType = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(createFile.pending, (state, action) => {
      state.percentage = 0
      state.state = ProcessingState.Processing
    })
    builder.addCase(createFile.fulfilled, (state, action) => {
      state.percentage = 0
      state.state = ProcessingState.Inactive
      state.error = null
      state.processingFileType = ''
    })
    builder.addCase(createFile.rejected, (state, action) => {
      state.percentage = 0
      state.state = ProcessingState.Inactive
      state.error = action.payload
      state.processingFileType = ''
    })
    builder.addCase(fetchFiles.pending, state => {
      state.state = ProcessingState.Processing
    })
    builder.addCase(fetchFiles.fulfilled, (state, { payload }) => {
      state.state = ProcessingState.Inactive
      state.data = payload.data || initialState.data
      state.error = null
    })
    builder.addCase(fetchFiles.rejected, (state, { payload }) => {
      state.state = ProcessingState.Inactive
      state.error = payload
    })
    builder.addCase(deleteFile.pending, state => {
      state.state = ProcessingState.Processing
    })
    builder.addCase(deleteFile.fulfilled, state => {
      state.error = null
      state.processingFileType = ''
    })
    builder.addCase(deleteFile.rejected, (state, { payload }) => {
      state.error = payload
      state.processingFileType = ''
    })
  }
})

export const { resetFiles, setProgress, setprocessingFileType } =
  filesSlice.actions

export const selectFiles = (state: RootState) => state.files

export default filesSlice.reducer
