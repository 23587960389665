import { RequestError } from '@open-tender/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTask, OrderSource, ReceiptType, ReceiptsReport } from 'types'
import { RequestStatus, makeQueryParams } from 'utils'

export interface ReceiptsReportState {
  data: ReceiptsReport
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: ReceiptsReportState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface ReceiptsReportAPI {
  data: ReceiptsReport
  links: {
    next: string
  }
}

export interface ReceiptsReportCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export type FetchReceiptsReportParams = {
  end_date: string
  start_date: string
  store_id?: number
  receipt_type?: ReceiptType
  order_source?: OrderSource
}

export const createReceiptsReportCSV = createAsyncThunk<
  ReceiptsReportCSVAPI,
  FetchReceiptsReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'receiptsReport/createReceiptsReportCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/receipts/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as ReceiptsReportCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchReceiptsReport = createAsyncThunk<
  ReceiptsReportAPI,
  FetchReceiptsReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'receiptsReport/fetchReceiptsReport',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/receipts${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as ReceiptsReportAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const ReceiptsReportSlice = createSlice({
  name: 'receiptsReport',
  initialState,
  reducers: {
    resetReceiptsReport: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchReceiptsReport.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchReceiptsReport.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchReceiptsReport.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetReceiptsReport } = ReceiptsReportSlice.actions

export const selectReceiptsReport = (state: RootState) => state.receiptsReport

export default ReceiptsReportSlice.reducer
