import styled from '@emotion/styled'

export const ModifierCheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;

  label {
    margin: 0;
  }
`
