import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { gql } from 'graphql-request'
import { ListFilter, MenuMappings } from 'types'
import {
  ONBOARDING_LOCATION_FRAGMENT_WITHOUT_APP,
  RequestError,
  RequestStatus
} from 'utils'

export interface OnboardingMenuMappingState {
  data: MenuMappings | null
  loading: RequestStatus
  error: RequestError
}

export interface OnboardingMenuMappingAPI {
  menuMappingRequests: MenuMappings
}

const initialState: OnboardingMenuMappingState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_MENU_MAPPING_REQUESTS = gql`
  query menuMappingRequests(
    $page: Int!
    $perPage: Int!
    $filters: [JSON!]
    $order: String
    $orderBy: String
  ) {
    menuMappingRequests(
      page: $page
      perPage: $perPage
      filters: $filters
      order: $order
      orderBy: $orderBy
    ) {
      page
      totalPageCount
      totalRecordsCount
      results {
        ...OnboardingLocationFragment
        enteredMenuMappingStageAt
      }
    }
  }
  ${ONBOARDING_LOCATION_FRAGMENT_WITHOUT_APP}
`

export type OnboardingMenuMappingParams = {
  filters?: ListFilter[]
  order?: string
  orderBy?: string
  page?: number
  perPage?: number
  pageType?: string
  brandIds?: string[]
  searchTerm?: string
}

export const fetchOnboardingMenuMapping = createAsyncThunk<
  OnboardingMenuMappingAPI,
  OnboardingMenuMappingParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingMenuMapping/fetchOnboardingMenuMapping',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_MENU_MAPPING_REQUESTS,
        params
      )) as OnboardingMenuMappingAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingMenuMappingSlice = createSlice({
  name: 'oboardingMenuMapping',
  initialState,
  reducers: {
    resetOnboardingMenuMapping: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOnboardingMenuMapping.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchOnboardingMenuMapping.fulfilled,
      (state, { payload }) => {
        state.data = payload.menuMappingRequests
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchOnboardingMenuMapping.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetOnboardingMenuMapping } = onboardingMenuMappingSlice.actions

export const selectOnboardingMenuMapping = (state: RootState) =>
  state.onboardingMenuMapping

export default onboardingMenuMappingSlice.reducer
