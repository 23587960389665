import { useState } from 'react'
import styled from '@emotion/styled'
import { Input } from 'components'
import { Search as SearchIcon } from 'iconoir-react'

const SearchView = styled.div<{ hasSearchValue: boolean }>`
  input {
    position: relative;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props =>
      props.theme.inputs[props.hasSearchValue ? 'color' : 'placeholderColor']};
    opacity: ${props =>
      props.hasSearchValue ? '1' : props.theme.inputs.placeholderOpacity};
  }
`

interface SearchProps {
  label?: string
  placeholder?: string
  type?: 'number' | 'text'
  onSearch: (search: string) => void
}

const Search = ({
  label = 'Search',
  placeholder = 'Enter value + hit enter',
  type = 'text',
  onSearch
}: SearchProps) => {
  const [search, setSearch] = useState('')
  const hasSearchValue = search.length > 0

  const onSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value)
  }

  const onSearchKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      onSearch(search)
    } else if (evt.key === 'Escape') {
      setSearch('')
      onSearch('')
    }
  }

  return (
    <SearchView hasSearchValue={hasSearchValue}>
      <Input
        type={type}
        name="search"
        fieldType="Input"
        label={label}
        value={search ?? ''}
        onChange={onSearchChange}
        onKeyDown={onSearchKeyPress}
        placeholder={placeholder}
        icon={
          <button onClick={() => onSearch(search)}>
            <SearchIcon width="1.4rem" height="1.4rem" strokeWidth={2} />
          </button>
        }
      />
    </SearchView>
  )
}

export default Search
