import React from 'react'

const Menu = React.lazy(() => import('./Menu'))
const MenuPreview = React.lazy(() => import('./MenuPreview'))
const MenuCategories = React.lazy(() => import('./MenuCategories'))
const MenuCategoriesOutlet = React.lazy(
  () => import('./MenuCategories/MenuCategoriesOutlet')
)
const MenuCategory = React.lazy(() => import('./MenuCategory'))
const MenuCategoryItem = React.lazy(() => import('./MenuItem/MenuCategoryItem'))
const MenuCategoryItemsOutlet = React.lazy(
  () => import('./MenuCategory/MenuCategoryItems/MenuCategoryItemsOutlet')
)
const MenuModifierGroup = React.lazy(() => import('./MenuModifierGroup'))
const MenuModifierGroups = React.lazy(() => import('./MenuModifierGroups'))
const MenuModifierGroupsOutlet = React.lazy(
  () => import('./MenuModifierGroups/MenuModifierGroupsOutlet')
)
const MenuModifierGroupItem = React.lazy(
  () => import('./MenuItem/MenuModifierGroupItem')
)
const MenuModifierGroupItemsOutlet = React.lazy(
  () =>
    import(
      './MenuModifierGroup/MenuModifierGroupItems/MenuModifierGroupItemsOutlet'
    )
)
const MenuAttributes = React.lazy(() => import('../../components/Attributes'))

export {
  Menu,
  MenuPreview,
  MenuCategories,
  MenuCategoriesOutlet,
  MenuCategory,
  MenuCategoryItem,
  MenuCategoryItemsOutlet,
  MenuModifierGroup,
  MenuModifierGroupItem,
  MenuModifierGroupItemsOutlet,
  MenuModifierGroups,
  MenuModifierGroupsOutlet,
  MenuAttributes
}
