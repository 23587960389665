import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { DiscountRevenueCenters } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface DicountRevenueCentersState {
  data: DiscountRevenueCenters
  loading: RequestStatus
  error: RequestError
}

const initialState: DicountRevenueCentersState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchDiscountRevenueCenters = createAsyncThunk<
  DiscountRevenueCenters,
  {
    discountId: string
  },
  { state: RootState; rejectValue: RequestError }
>(
  'discountRevenueCenters/fetchDiscountRevenueCenters',
  async ({ discountId }, { getState, rejectWithValue }) => {
    try {
      const endpoint = `discounts/${discountId}/revenue-centers`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as DiscountRevenueCenters
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateDiscountRevenueCenters = createAsyncThunk<
  DiscountRevenueCenters,
  {
    discountId: string
    data: DiscountRevenueCenters
  },
  { state: RootState; rejectValue: RequestError }
>(
  'disocuntRevenueCenters/updateDiscountRevenueCenters',
  async ({ discountId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const endpoint = `discounts/${discountId}/revenue-centers`
      const api = getState().authUser.api
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as DiscountRevenueCenters
      dispatch(showNotification('Revenue centers successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const discountRevenueCentersSlice = createSlice({
  name: 'discountRevenueCenters',
  initialState,
  reducers: {
    resetDiscountRevenueCenters: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchDiscountRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchDiscountRevenueCenters.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchDiscountRevenueCenters.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateDiscountRevenueCenters.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateDiscountRevenueCenters.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      updateDiscountRevenueCenters.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetDiscountRevenueCenters } =
  discountRevenueCentersSlice.actions

export const selectDiscountRevenueCenters = (state: RootState) =>
  state.discountRevenueCenters

export default discountRevenueCentersSlice.reducer
