import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Payments } from 'types'
import { showNotification } from 'slices/notification'

export interface PaymentsState {
  data: Payments | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PaymentsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchPayments = createAsyncThunk<
  Payments,
  void,
  { state: RootState; rejectValue: RequestError }
>('payments/fetchPayments', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('integrations/credit-cards')) as Payments
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertPayments = createAsyncThunk<
  Payments,
  { data: Payments },
  { state: RootState; rejectValue: RequestError }
>(
  'payments/upsertPayments',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'integrations/credit-cards',
        'PUT',
        data
      )) as Payments
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPayments.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPayments.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchPayments.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertPayments.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertPayments.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(upsertPayments.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectPayments = (state: RootState) => state.payments

export default paymentsSlice.reducer
