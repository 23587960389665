import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { RevenueCenter } from 'types'
import { showNotification } from 'slices/notification'
import { fetchRevenueCenters } from './revenueCenters'

export interface RevenueCenterState {
  data: RevenueCenter | null
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type RevenueCenterParams = {
  expand?: string
  with_related?: string
  with_settings?: string
}

export const fetchRevenueCenter = createAsyncThunk<
  RevenueCenter | null,
  { revenueCenterId: string | number; params?: RevenueCenterParams },
  { state: RootState; rejectValue: RequestError }
>(
  'revenue-centers/fetchRevenueCenter',
  async ({ revenueCenterId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `revenue-centers/${revenueCenterId}${queryParams}`
      return (await api?.request(endpoint)) as RevenueCenter
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createRevenueCenter = createAsyncThunk<
  RevenueCenter,
  { data: RevenueCenter; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'revenue-centers/createRevenueCenter',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'revenue-centers',
        'POST',
        data
      )) as RevenueCenter
      dispatch(showNotification('Revenue center successfully created!'))
      const pathname = `/stores/stores/${resp.store_id}/revenue-centers/${resp.revenue_center_id}`
      navigate({ pathname })
      dispatch(fetchRevenueCenters({ store_id: resp.store_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateRevenueCenter = createAsyncThunk<
  RevenueCenter,
  { revenueCenterId: string | number; data: RevenueCenter },
  { state: RootState; rejectValue: RequestError }
>(
  'revenue-centers/updateRevenueCenter',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as RevenueCenter
      dispatch(showNotification('RevenueCenter successfully updated!'))
      dispatch(fetchRevenueCenters({ store_id: resp.store_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterSlice = createSlice({
  name: 'revenueCenter',
  initialState,
  reducers: {
    resetRevenueCenter: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRevenueCenter.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchRevenueCenter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createRevenueCenter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createRevenueCenter.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createRevenueCenter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateRevenueCenter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateRevenueCenter.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateRevenueCenter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetRevenueCenter } = revenueCenterSlice.actions

export const selectRevenueCenter = (state: RootState) => state.revenueCenter

export default revenueCenterSlice.reducer
