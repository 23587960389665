import { SidebarLinkProps } from 'types'

const links: SidebarLinkProps[] = [
  {
    title: 'Settings',
    path: '',
    end: true
  }
]

export default links
