import { NavigateFunction } from 'react-router-dom'
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import {
  addItem,
  checkAmountRemaining,
  removeItem,
  timezoneMap
} from '@open-tender/utils'
import { RootState } from 'app/store'
import {
  downloadPDF,
  makeLocalDateStr,
  makePreparedOrder,
  makeQueryParams,
  rehydrateOrder,
  requestedAtToDate,
  RequestError,
  RequestStatus
} from 'utils'
import { showNotification } from './notification'
import {
  Cart,
  CartItem,
  CheckoutCheck,
  CheckoutError,
  CheckoutPromoCodes,
  CheckoutTenders,
  DeviceType,
  Money,
  Order,
  OrderCreate,
  OrderCreateDetails,
  OrderCreateDiscounts,
  OrderCreateSurcharges,
  OrderType,
  RequestedAt,
  RevenueCenter,
  ServiceType
} from '@open-tender/types'
import { BuiltMenu, BulkOrder, Customer, CustomerAddress } from 'types'

export interface OrderState {
  data: BulkOrder | null
  cart: Cart | null
  check: CheckoutCheck | null
  checkoutError: CheckoutError
  completedOrder: Order | null
  customer: Customer | null
  customerAddress: CustomerAddress | null
  currentCartItem: CartItem | null
  deposit: Money | null
  details: OrderCreateDetails | null
  deviceType?: DeviceType
  discounts: OrderCreateDiscounts
  discountsInternal: OrderCreateDiscounts
  orderId: number | null
  orderType: OrderType | null
  promoCodes: CheckoutPromoCodes
  requestedAt: RequestedAt | null
  revenueCenter: RevenueCenter | null
  serviceType: ServiceType | null
  surcharges: OrderCreateSurcharges
  tenders: CheckoutTenders
  tip: Money | null
  submitting: boolean
  loading: RequestStatus
  error: RequestError
  isCollect: boolean
}

type OrderEdit = Omit<
  OrderState,
  | 'data'
  | 'check'
  | 'checkoutError'
  | 'completedOrder'
  | 'currentCartItem'
  | 'discountsInternal'
  | 'submitting'
  | 'loading'
  | 'error'
>

const initialState: OrderState = {
  data: null,
  cart: [],
  check: null,
  checkoutError: null,
  completedOrder: null,
  customer: null,
  customerAddress: null,
  currentCartItem: null,
  deposit: null,
  details: null,
  deviceType: 'DESKTOP',
  discounts: [],
  discountsInternal: [],
  orderId: null,
  orderType: null,
  promoCodes: [],
  requestedAt: null,
  revenueCenter: null,
  serviceType: null,
  surcharges: [],
  tenders: [],
  tip: null,
  submitting: false,
  loading: RequestStatus.Idle,
  error: null,
  isCollect: false
}

export type FetchBuiltMenusParams = {
  revenue_center_id: string | number
  service_type: ServiceType
  requested_at: RequestedAt
}

export const fetchOrder = createAsyncThunk<
  BulkOrder,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('order/fetchOrder', async (orderId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `orders/${orderId}`
    const resp = (await api?.request(endpoint)) as BulkOrder
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const addCustomer = createAsyncThunk<
  Customer,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('order/addCustomer', async (customerId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `customers/${customerId}?with_related=true`
    return (await api?.request(endpoint)) as Customer
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const fetchOrderRevenueCenter = createAsyncThunk<
  RevenueCenter,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'order/fetchOrderRevenueCenter',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/order-attributes`
      return (await api?.request(endpoint)) as RevenueCenter
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const validateOrder = createAsyncThunk<
  CheckoutCheck,
  OrderCreate | undefined,
  { state: RootState; rejectValue: CheckoutError }
>(
  'order/validateOrder',
  async (preparedOrder, { getState, rejectWithValue }) => {
    try {
      const { order, authUser } = getState()
      const { api, user } = authUser
      const prepared = preparedOrder || makePreparedOrder(order)
      const { tenders, ...rest } = prepared || {}
      const data = { ...rest, user, validate: true }
      // on first request, get these from the backend
      if (!order.check && !order.orderId) {
        // delete data.surcharges
        // delete data.discounts
        // delete data.promo_codes
      }
      return (await api?.request(
        'orders/validate',
        'POST',
        data
      )) as CheckoutCheck
    } catch (err) {
      return rejectWithValue(err as CheckoutError)
    }
  }
)

export const saveOrder = createAsyncThunk<
  Order,
  void,
  { state: RootState; rejectValue: CheckoutError }
>('order/saveOrder', async (_, { getState, dispatch, rejectWithValue }) => {
  try {
    const { order, authUser } = getState()
    const { api, user } = authUser
    const preparedOrder = makePreparedOrder(order)
    const data = { ...preparedOrder, user, save: true }
    const savedOrder = (await api?.request(
      'orders/validate',
      'POST',
      data
    )) as Order
    dispatch(showNotification('Order saved!'))
    return savedOrder
  } catch (err) {
    return rejectWithValue(err as CheckoutError)
  }
})

export const submitOrder = createAsyncThunk<
  Order,
  { navigate: NavigateFunction } | void,
  { state: RootState; rejectValue: CheckoutError }
>('order/submitOrder', async (_, { getState, rejectWithValue }) => {
  try {
    const { order, authUser } = getState()
    const { api, user } = authUser
    const preparedOrder = makePreparedOrder(order)
    const data = { ...preparedOrder, user }
    return (await api?.request('orders/validate', 'POST', data)) as Order
  } catch (err) {
    return rejectWithValue(err as CheckoutError)
  }
})

export const fetchQuote = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: CheckoutError }
>('order/fetchQuote', async (_, { getState, dispatch, rejectWithValue }) => {
  try {
    const { order, authUser } = getState()
    const { api, user, brand } = authUser
    if (!api || !user || !brand) {
      dispatch(showNotification('Something went wrong.'))
      return
    }
    const preparedOrder = makePreparedOrder(order)
    const blob = (await api.request(
      'orders/quote-pdf',
      'POST',
      preparedOrder
    )) as Blob
    const currentDate = makeLocalDateStr(0, 'yyyy-MM-dd_hh-mm-ss')
    const filename = `${brand.full_name} Order Quote_${currentDate}.pdf`
    downloadPDF(blob, 'application/pdf', filename)
  } catch (err) {
    return rejectWithValue(err as CheckoutError)
  }
})

export const editBulkOrder = createAsyncThunk<
  OrderEdit,
  { bulkOrder: BulkOrder; isCollect?: boolean },
  { state: RootState; rejectValue: CheckoutError }
>(
  'order/editBulkOrder',
  async ({ bulkOrder, isCollect = false }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const {
        receipt_id: orderId,
        customer = null,
        customer_address: customerAddress = null
      } = bulkOrder
      const endpoint = `orders/edit/${orderId}`
      const order = (await api?.request(endpoint)) as Order
      const { revenue_center, service_type, requested_at } = order
      const revenue_center_id = revenue_center.revenue_center_id
      const params = { revenue_center_id, service_type, requested_at }
      const queryParams = makeQueryParams(params)
      const builtMenu = (await api?.request(
        `built-menus${queryParams}`
      )) as BuiltMenu
      const rcEndpoint = `revenue-centers/${revenue_center_id}/order-attributes`
      const revenueCenter = (await api?.request(rcEndpoint)) as RevenueCenter
      const rehydrated = rehydrateOrder(order, builtMenu)
      return {
        ...rehydrated,
        customer,
        customerAddress,
        revenueCenter,
        isCollect
      }
    } catch (err) {
      return rejectWithValue(err as CheckoutError)
    }
  }
)

export const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrder: () => initialState,
    resetOrderCheckout: state => {
      return {
        ...state,
        error: null,
        checkoutError: null,
        surcharges: [],
        discounts: [],
        discountsInternal: [],
        promoCodes: []
      }
    },
    setOrderType: (state, action: PayloadAction<OrderType>) => {
      if (state.orderType !== action.payload) {
        state.revenueCenter = null
        state.requestedAt = null
      }
      state.orderType = action.payload
    },
    setServiceType: (state, action: PayloadAction<ServiceType | null>) => {
      state.serviceType = action.payload
    },
    setRevenueCenter: (state, action: PayloadAction<RevenueCenter | null>) => {
      state.revenueCenter = action.payload
    },
    setRequestedAt: (state, action: PayloadAction<RequestedAt | null>) => {
      state.requestedAt = action.payload
    },
    removeCustomer: state => {
      state.customer = null
      state.customerAddress = null
    },
    addCustomerAddress: (state, action: PayloadAction<CustomerAddress>) => {
      state.customerAddress = action.payload
    },
    removeCustomerAddress: state => {
      state.customerAddress = null
    },
    setCustomerAddress: (
      state,
      action: PayloadAction<CustomerAddress | null>
    ) => {
      state.customerAddress = action.payload
    },
    addSurcharge: (state, action) => {
      state.surcharges = [...state.surcharges, action.payload]
    },
    removeSurcharge: (state, action) => {
      state.surcharges = state.surcharges.filter(
        surcharge => surcharge.id !== action.payload
      )
    },
    addDiscountInternal: (state, action) => {
      state.discountsInternal = [...state.discountsInternal, action.payload]
    },
    removeDiscountInternal: (state, action) => {
      state.discountsInternal = state.discountsInternal.filter(
        discount => discount.id !== action.payload
      )
    },
    addDiscount: (state, action) => {
      state.discounts = [...state.discounts, action.payload]
    },
    removeDiscount: (state, action) => {
      state.discounts = state.discounts.filter(
        discount => discount.id !== action.payload
      )
    },
    addPromoCode: (state, action) => {
      state.promoCodes = [...state.promoCodes, action.payload]
    },
    removePromoCode: (state, action) => {
      state.promoCodes = state.promoCodes.filter(i => i !== action.payload)
    },
    setCurrentCartItem: (state, action) => {
      state.currentCartItem = action.payload
    },
    setCart: (state, action) => {
      state.cart = action.payload
    },
    addItemToCart: (state, action: PayloadAction<CartItem>) => {
      const currentCart =
        state.cart !== undefined && state.cart !== null ? state.cart : []
      const { cart } = addItem([...currentCart], action.payload)
      state.cart = cart
    },
    removeItemFromCart: (state, action: PayloadAction<CartItem>) => {
      const { index } = action.payload
      const currentCart: Cart = state.cart !== null ? state.cart : []
      const { cart } = removeItem([...currentCart], index ?? 0)
      state.cart = cart
    },
    setDeviceType: (state, action: PayloadAction<DeviceType>) => {
      state.deviceType = action.payload
    },
    setTip: (state, action) => {
      state.tip = action.payload
    },
    setDeposit: (state, action) => {
      state.deposit = action.payload
    },
    removeDeposit: state => {
      state.deposit = null
    },
    setDetails: (state, action) => {
      state.details = action.payload
    },
    addTender: (state, action) => {
      state.tenders = [...(state.tenders || []), action.payload]
    },
    removeTender: (state, action) => {
      state.tenders = state.tenders?.filter(
        (val, idx) => action.payload !== idx
      )
    },
    replaceTenders: (state, action) => {
      state.tenders = action.payload
    },
    toggleCollect: state => {
      state.isCollect = !state.isCollect
    }
  },
  extraReducers: builder => {
    //fetch order
    builder.addCase(fetchOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrder.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    // addCustomer
    builder.addCase(addCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addCustomer.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
      const existingCustomerId = state.customer?.customer_id
      const customerId = payload.customer_id
      const isNewCustomer = existingCustomerId !== customerId
      state.customer = payload
      // state.customerAddress = isNewCustomer ? null : state.customerAddress
      if (isNewCustomer) state.customerAddress = null
    })
    builder.addCase(addCustomer.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    // fetchOrderRevenueCenter
    builder.addCase(fetchOrderRevenueCenter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderRevenueCenter.fulfilled, (state, { payload }) => {
      const { first_times } = payload
      const firstTime =
        first_times && state.serviceType ? first_times[state.serviceType] : null
      const requestedAt = firstTime
        ? firstTime.has_asap
          ? 'asap'
          : firstTime.utc
        : null
      const newDate = requestedAtToDate(firstTime?.utc || null)
      const currentDate = requestedAtToDate(state.requestedAt)
      if (!state.requestedAt) {
        state.requestedAt = requestedAt
      } else if (
        currentDate !== null &&
        newDate !== null &&
        newDate > currentDate &&
        firstTime
      ) {
        state.requestedAt = firstTime.utc
      }
      state.revenueCenter = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOrderRevenueCenter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    // validateOrder
    builder.addCase(validateOrder.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(validateOrder.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      // get these from the response in case any have errors
      state.surcharges = payload.surcharges.map(i => ({ id: i.id }))
      const validDiscountIds = payload.discounts.map(i => i.id)
      const discountsInternal = state.discountsInternal.filter(i =>
        validDiscountIds.includes(i.id)
      )
      const discountsInternalIds = discountsInternal.map(i => i.id)
      const discounts = payload.discounts.filter(
        i => !discountsInternalIds.includes(i.id)
      )
      state.discounts = discounts
        .filter(i => !i.is_promo_code)
        .map(i => ({ id: i.id }))
      state.promoCodes = discounts.filter(i => i.is_promo_code).map(i => i.name)
      state.discountsInternal = discountsInternal.map(i => ({ id: i.id }))
      state.check = payload || null
      state.checkoutError = null
      state.error = null
    })
    builder.addCase(validateOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      const discountNames = state.check?.discounts.map(i => i.name) || []
      state.promoCodes = state.promoCodes.filter(i => discountNames.includes(i))
      state.checkoutError = payload
      state.error = null
    })
    // saveOrder
    builder.addCase(saveOrder.pending, state => {
      state.loading = RequestStatus.Pending
      state.submitting = true
    })
    builder.addCase(saveOrder.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.completedOrder = payload
      state.error = null
    })
    builder.addCase(saveOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.checkoutError = payload
      state.error = null
    })
    // submitOrder
    builder.addCase(submitOrder.pending, state => {
      state.loading = RequestStatus.Pending
      state.submitting = true
    })
    builder.addCase(submitOrder.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.completedOrder = payload
      state.error = null
    })
    builder.addCase(submitOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.checkoutError = payload
      state.error = null
    })
    // fetchQuote
    builder.addCase(fetchQuote.pending, state => {
      state.loading = RequestStatus.Pending
      state.submitting = true
    })
    builder.addCase(fetchQuote.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.error = null
    })
    builder.addCase(fetchQuote.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.checkoutError = payload
      state.error = null
    })
    // editBulkOrder
    builder.addCase(editBulkOrder.pending, state => {
      state.loading = RequestStatus.Pending
      state.submitting = true
    })
    builder.addCase(editBulkOrder.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.error = null
      const {
        cart,
        customer,
        customerAddress,
        deposit,
        details,
        deviceType,
        discounts,
        orderId,
        orderType,
        promoCodes,
        requestedAt,
        revenueCenter,
        serviceType,
        surcharges,
        tenders,
        tip,
        isCollect
      } = payload
      state.cart = cart
      state.customer = customer
      state.customerAddress = customerAddress
      state.deposit = deposit
      state.details = details
      state.deviceType = deviceType
      state.discounts = discounts
      state.orderId = orderId
      state.orderType = orderType
      state.promoCodes = promoCodes
      state.requestedAt = requestedAt
      state.revenueCenter = revenueCenter
      state.serviceType = serviceType
      state.surcharges = surcharges
      state.tenders = tenders
      state.tip = tip
      state.isCollect = isCollect
    })
    builder.addCase(editBulkOrder.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.submitting = false
      state.error = null
    })
  }
})

export const {
  resetOrder,
  resetOrderCheckout,
  setOrderType,
  setServiceType,
  setRevenueCenter,
  setRequestedAt,
  removeCustomer,
  addCustomerAddress,
  removeCustomerAddress,
  setCustomerAddress,
  addSurcharge,
  removeSurcharge,
  addDiscount,
  removeDiscount,
  addDiscountInternal,
  removeDiscountInternal,
  addPromoCode,
  removePromoCode,
  setCurrentCartItem,
  setCart,
  addItemToCart,
  removeItemFromCart,
  setDeviceType,
  setTip,
  setDeposit,
  removeDeposit,
  setDetails,
  addTender,
  removeTender,
  replaceTenders,
  toggleCollect
} = order.actions

export const selectOrder = (state: RootState) => state.order

export const selectTimezone = (state: RootState) => {
  const { revenueCenter } = state.order
  if (!revenueCenter) return null
  return timezoneMap[revenueCenter.timezone]
}

export const selectCanOrder = (state: RootState) => {
  const { orderType, serviceType, requestedAt, revenueCenter } = state.order
  const required = [orderType, serviceType, requestedAt, revenueCenter]
  return required.every(i => i !== null)
}

export const selectCart = (state: RootState) => state.order.cart

export const selectHasCart = (state: RootState) => {
  const count = state.order.cart?.length || 0
  return count > 0
}

export const selectCustomer = (state: RootState) => ({
  data: state.order.customer,
  loading: state.order.loading,
  error: state.order.error
})

export const selectOrderType = (state: RootState) => state.order.orderType

export const selectServiceType = (state: RootState) => state.order.serviceType

export const selectRevenueCenter = (state: RootState) =>
  state.order.revenueCenter

export const selectRequestedAt = (state: RootState) => state.order.requestedAt

export const selectCartTotal = (state: RootState) => {
  return state.order.cart
    ? state.order.cart.reduce(
        (t: number, i: { totalPrice: number | null }) =>
          (t += i.totalPrice || 0),
        0.0
      )
    : 0.0
}

export const selectCartLookup = createSelector(
  (state: RootState) => {
    return state.order.cart
  },
  cart => {
    if (!cart) return {}
    return cart.reduce(
      (obj, i) => {
        const count = obj[i.id.toString()] || 0
        return { ...obj, [i.id.toString()]: count + i.quantity }
      },
      {} as Record<string, number>
    )
  }
)

export const selectPromoCodes = (state: RootState) => state.order.promoCodes

export const selectSurcharges = (state: RootState) => state.order.surcharges

export const selectTenders = (state: RootState) => state.order.tenders

export const selectAmountRemaining = (state: RootState) => {
  const { tenders, check, deposit, isCollect } = state.order
  if (!check) return 0.0
  const { total } = check.totals
  const depositAmt = deposit ? parseFloat(deposit) : 0.0
  const totalAmt = parseFloat(total)
  const collectAmt = (totalAmt - depositAmt).toFixed(2) as Money
  const amountDue = isCollect ? collectAmt : deposit || total
  const newTenders = tenders.filter(i => i.tender_status !== 'PAID')
  const remaining =
    newTenders.length > 0 ? checkAmountRemaining(amountDue, newTenders) : null
  return remaining === null ? Number(amountDue) : remaining
}

export const selectIsPaid = (state: RootState) => {
  const remaining = selectAmountRemaining(state)
  const canOrder = selectCanOrder(state)
  const hasCart = selectHasCart(state)
  const hasCheck = !!state.order.check
  return canOrder && hasCart && remaining === 0 && hasCheck
}

export default order.reducer
