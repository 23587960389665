import { useEffect } from 'react'
import {
  Community,
  DesignPencil,
  Heart,
  Internet,
  Lock,
  OpenBook,
  Reports,
  SendMail,
  Settings,
  ShopFourTiles,
  ShoppingBag
} from 'iconoir-react'
import { makeUserRoutes } from 'utils'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectAuthUser, selectUserSections } from 'slices/authUser'
import {
  fetchUnchargedOrders,
  selectUnchargedOrdersCount
} from 'slices/unchargedOrders'
import { Content, NavCards } from 'components'
import HomeBrand from './HomeBrand'
import { NavCardAlert } from 'types'
import { selectMarketingBrandSettings } from 'slices/marketingSettings'

const items = [
  {
    path: '/orders',
    icon: <ShoppingBag />,
    title: 'Orders',
    description:
      'Review and manage existing orders, as well as create new ones.',
    cta: 'Manage Orders'
  },
  {
    path: '/reporting',
    icon: <Reports />,
    title: 'Reporting',
    description: 'Monitor both real-time performance and historical trends.',
    cta: 'Review Reports'
  },
  {
    path: '/customers',
    icon: <Community />,
    title: 'Customers',
    description: 'Manage your customers and create new ones when needed.',
    cta: 'Manage Customers'
  },
  {
    path: '/loyalty',
    icon: <Heart />,
    title: 'Loyalty',
    description:
      'Manage your loyalty programs, rewards, deals, promo codes, and gift cards.',
    cta: 'Update Loyalty'
  },
  {
    path: '/marketing',
    icon: <SendMail />,
    title: 'Marketing',
    description: 'Create and manage campaigns, saved content and segments.',
    cta: 'Update Marketing'
  },
  {
    path: '/stores',
    icon: <ShopFourTiles />,
    title: 'Stores',
    description:
      'Address, images, gratuity, payment processing, and various other settings for each of your restaurants.',
    cta: 'Update Stores'
  },
  {
    path: '/menus',
    icon: <OpenBook />,
    title: 'Menus',
    description:
      'Manage images, allergens, tags, nutritional info, etc. for each of your menus.',
    cta: 'Update Menus'
  },
  {
    path: '/pages',
    icon: <Internet />,
    title: 'Pages',
    description:
      'Adjust the content and display settings for each of the pages that appear on your Direct Ordering website.',
    cta: 'Edit Pages'
  },
  {
    path: '/design',
    icon: <DesignPencil />,
    title: 'Design',
    description:
      'All of the styles and colors that dictate the aesthetic of your Direct Ordering site.',
    cta: 'Update Design'
  },
  {
    path: '/settings',
    icon: <Settings />,
    title: 'Settings',
    description:
      'General settings not directly related to your website. Things like surcharges, taxes, etc.',
    cta: 'Update Settings'
  },
  {
    path: '/super',
    icon: <Lock />,
    title: 'Super',
    description:
      'Create new brands and edit existing ones. Only available to Checkmate super admin users.',
    cta: 'Manage Brands'
  }
]

const Home = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectAuthUser)
  const userSections = useAppSelector(selectUserSections)
  const { is_marketing_enabled } = useAppSelector(selectMarketingBrandSettings)
  const userRoutes = makeUserRoutes(
    userSections,
    user?.is_super,
    is_marketing_enabled
  )
  let filtered = items.filter(i => userRoutes.includes(i.path))

  const unchargedOrdersCount = useAppSelector(selectUnchargedOrdersCount)
  useEffect(() => {
    dispatch(fetchUnchargedOrders())
  }, [dispatch])

  const updatedItems = filtered.map(item => {
    if (item.path === '/orders' && unchargedOrdersCount > 0) {
      const suffix = unchargedOrdersCount > 1 ? 's' : ''
      return {
        ...item,
        alert: {
          text: `${unchargedOrdersCount} Uncharged Order${suffix}`,
          redirect: '/orders/uncharged',
          color: 'error'
        } as NavCardAlert
      }
    }
    return item
  })

  return (
    <Content
      title="Checkmate Direct Ordering"
      subtitle="Manage your website, mobile app, and kiosk."
      maxWidth="128rem"
      content={<HomeBrand />}
    >
      <NavCards items={updatedItems} />
    </Content>
  )
}

export default Home
