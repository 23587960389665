import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from '@emotion/react'
import Bee, { beeTypes } from '@mailupinc/bee-plugin'
import { IBeeEditorState } from './BeeEditorContext'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BeeEditorVariants } from 'types'
import defaultConfig from 'json/bee-editor-config.json'
import emailEditorConfig from 'json/email-editor-config.json'
import emailReadOnlyConfig from 'json/email-readonly-config.json'
import savedRowEditorConfig from 'json/saved-row-editor-config.json'
import savedRowReadOnlyConfig from 'json/saved-row-readonly-config.json'
import textSnippetEditorConfig from 'json/saved-text-snippet-editor-config.json'
import textSnippetReadOnlyConfig from 'json/saved-text-snippet-readonly-config.json'
import { fetchCampaignContentToken } from 'slices/campaignContentToken'
import { selectAuthUser, selectBrand, selectIsSuper } from 'slices/authUser'
import useMergeCodes from './useMergeCodes'
import { RequestStatus } from 'utils'
import { getPageTextSnippet } from 'pages/Marketing/SavedContent/SavedContent.utils'
import useDisplayConditions from './useDisplayConditions'

export interface useBeeEditorProps {
  variant: BeeEditorVariants
  templateJson?: string
  readOnly?: boolean
  isTemplate?: boolean
  key: string
}

export const getEditorConfig = (
  variant?: BeeEditorVariants,
  readOnly?: boolean
) => {
  let variantConfig = {}

  if (variant === 'row')
    variantConfig = readOnly ? savedRowReadOnlyConfig : savedRowEditorConfig
  if (variant === 'text')
    variantConfig = readOnly
      ? textSnippetReadOnlyConfig
      : textSnippetEditorConfig
  if (variant === 'web')
    variantConfig = readOnly ? emailReadOnlyConfig : emailEditorConfig
  if (variant === 'email')
    variantConfig = readOnly ? emailReadOnlyConfig : emailEditorConfig

  return {
    ...defaultConfig,
    ...variantConfig
  }
}

export const useBeeEditor = (props: useBeeEditorProps) => {
  const { key, isTemplate, templateJson, readOnly, variant } = props
  const [state, setState] = useState<IBeeEditorState>(props)
  const {
    ready,
    editorInstance,
    workspace,
    pageJson,
    editorConfig: loadedConfig,
    templateJson: loadedTemplate
  } = state
  const { user } = useAppSelector(selectAuthUser)
  const { displayConditions } = useDisplayConditions()
  const isSuper = useAppSelector(selectIsSuper)
  const { mergeTags, specialLinks, loading, externalContentURLs, getRows } =
    useMergeCodes()
  const mergeCodesLoaded = loading === RequestStatus.Succeeded
  const { brand_id } = useAppSelector(selectBrand) || {}
  const theme = useTheme()
  const userColor = theme.colors.accent.primary
  const editorRef = useRef<HTMLDivElement>(null)
  const loadedTemplateRef = useRef<string | undefined>()
  const loadedConfigRef = useRef<string | undefined>()
  const appDispatch = useAppDispatch()

  useEffect(() => {
    if (loadedTemplateRef.current !== pageJson) {
      loadedTemplateRef.current = pageJson || loadedTemplate
    }
  }, [pageJson, loadedTemplate])

  useEffect(() => {
    if (loadedConfigRef.current !== loadedConfig) {
      loadedConfigRef.current = JSON.stringify(loadedConfig || {})
    }
  }, [loadedConfig])

  // Compose the editor configuration
  const editorConfig = useMemo<beeTypes.IBeeConfig>(
    () => ({
      ...getEditorConfig(variant, readOnly),
      rowsConfiguration: {
        externalContentURLs: isSuper ? externalContentURLs : [],
        defaultRows: true,
        emptyRows: true
      },
      uid: `${brand_id || 'user'}`,
      autosave: readOnly ? 0 : 0,
      container: key,
      username: [user?.first_name, user?.last_name].join(' ') || 'User',
      userColor,
      saveRows: false,
      roleHash:
        isSuper && isTemplate && !readOnly ? 'administrator' : 'readonly',
      rowDisplayConditions: displayConditions,
      userHandle: `${user?.user_id}`,
      commenting: true,
      hooks: {
        getRows: {
          handler: getRows
        }
      },
      mergeTags,
      specialLinks,
      onSave: (pageJson, pageHtml) => {
        const pageText =
          variant === 'text' ? getPageTextSnippet(pageHtml) : undefined
        setState(state => ({
          ...state,
          pageJson,
          pageHtml,
          pageText,
          isLoading: false,
          autoSaveJson: '',
          hasChanges: false,
          hasAutoSaveChanges: false
        }))
      },
      onAutoSave: autoSaveJson =>
        setState(state => {
          if (!state.hasAutoSaveChanges) return state
          return {
            ...state,
            autoSaveJson,
            hasAutoSaveChanges: false
          }
        }),
      onChange: () =>
        setState(state => ({
          ...state,
          hasChanges: true,
          hasAutoSaveChanges: true
        })),
      onLoad: () => setState(state => ({ ...state, ready: true })),
      onTogglePreview: previewStatus =>
        setState(state => ({ ...state, previewStatus })),
      onError: error =>
        setState(state => ({
          ...state,
          error
        }))
    }),
    [
      brand_id,
      user,
      readOnly,
      userColor,
      key,
      variant,
      mergeTags,
      specialLinks,
      getRows,
      isSuper,
      isTemplate,
      displayConditions,
      externalContentURLs
    ]
  )

  const { stage, displayHidden } = workspace || editorConfig?.workspace || {}

  useEffect(() => {
    if (!variant) return
    if (
      !['web', 'email', 'row', 'text'].includes(variant) ||
      !editorRef.current ||
      !templateJson ||
      !mergeCodesLoaded
    )
      return
    if (
      templateJson === loadedTemplateRef.current &&
      JSON.stringify(editorConfig) === loadedConfigRef.current
    )
      return
    loadedTemplateRef.current = templateJson
    loadedConfigRef.current = JSON.stringify(editorConfig)
    editorRef.current.id = editorConfig.container
    // Fetch the token for the editor
    appDispatch(
      fetchCampaignContentToken({
        variant: variant === 'web' ? 'web' : 'email'
      })
    )
      .unwrap()
      .then(token => {
        // Create a new editor instance from token
        const editorInstance = new Bee(token)
        // Start the editor instance
        editorInstance
          .start(editorConfig, JSON.parse(templateJson))
          .then(() => {
            // Set the editor instance
            setState({
              editorInstance,
              ready: false,
              pageHtml: undefined,
              pageJson: undefined,
              autoSaveJson: undefined,
              templateJson,
              hasChanges: false,
              workspace: editorConfig?.workspace,
              editorConfig
            })
          })
      })
      .catch(error => {
        loadedConfigRef.current = undefined
        loadedTemplateRef.current = undefined
        setState({
          error: {
            code: beeTypes.BeePluginErrorCodes.SERVICE_ERROR,
            message: 'Unable to load the editor. Please try again later.'
          }
        })
      })
  }, [
    variant,
    appDispatch,
    editorConfig,
    editorRef,
    templateJson,
    mergeCodesLoaded
  ])

  const save = useCallback(() => {
    editorInstance?.save()
  }, [editorInstance])

  const togglePreview = useCallback(() => {
    editorInstance?.togglePreview()
  }, [editorInstance])

  const toggleStructure = useCallback(() => {
    editorInstance?.toggleStructure()
  }, [editorInstance])

  const toggleMergeTagsPreview = useCallback(() => {
    editorInstance?.toggleMergeTagsPreview()
  }, [editorInstance])

  const toggleStageMode = useCallback(() => {
    if (!editorInstance || !ready) return
    const newMode =
      stage === 'desktop'
        ? beeTypes.StageModeOptions.MOBILE
        : beeTypes.StageModeOptions.DESKTOP
    // Toggle the stage mode, keep the current display
    editorInstance?.loadStageMode({
      mode: newMode,
      display:
        displayHidden === 'blur'
          ? beeTypes.StageDisplayOptions.BLUR
          : beeTypes.StageDisplayOptions.HIDE
    })
    setState(state => ({
      ...state,
      workspace: {
        ...state.workspace,
        stage:
          stage === 'desktop'
            ? beeTypes.WorkspaceStage.mobile
            : beeTypes.WorkspaceStage.desktop
      }
    }))
  }, [ready, editorInstance, stage, displayHidden])

  const toggleStageModeDisplay = useCallback(() => {
    if (!editorInstance || !ready) return
    // Toggle the stage mode display, keep the current mode
    const newDisplay =
      displayHidden === 'blur'
        ? beeTypes.StageDisplayOptions.HIDE
        : beeTypes.StageDisplayOptions.BLUR
    editorInstance?.loadStageMode({
      mode:
        stage === 'desktop'
          ? beeTypes.StageModeOptions.DESKTOP
          : beeTypes.StageModeOptions.MOBILE,
      display: newDisplay
    })
    setState(state => ({
      ...state,
      workspace: {
        ...state.workspace,
        displayHidden: newDisplay
      }
    }))
  }, [ready, editorInstance, stage, displayHidden])

  return {
    variant,
    ...state,
    editorInstance: state.ready ? state.editorInstance : undefined,
    togglePreview,
    toggleStageMode,
    toggleStageModeDisplay,
    toggleStructure,
    toggleMergeTagsPreview,
    save,
    editorRef,
    editorConfig
  }
}
export default useBeeEditor
