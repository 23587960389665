import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { GiftCard, GiftCardUpdate } from 'types'
import { showNotification } from './notification'

export interface GiftCardState {
  data: GiftCard | null
  loading: RequestStatus
  error: RequestError
}

const initialState: GiftCardState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchGiftCard = createAsyncThunk<
  GiftCard,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'giftCard/fetchGiftCard',
  async (giftCardId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `gift-cards/${giftCardId}`
      const resp = (await api?.request(endpoint)) as GiftCard
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateGiftCard = createAsyncThunk<
  GiftCard,
  {
    giftCardId: string | number
    data: GiftCardUpdate
  },
  { state: RootState; rejectValue: RequestError }
>(
  'giftCard/updateGiftCard',
  async ({ giftCardId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `gift-cards/${giftCardId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as GiftCard
      dispatch(showNotification('Gift Card Updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateGiftCardCredit = createAsyncThunk<
  GiftCard,
  {
    giftCardId: string | number
    data: { amount: string }
  },
  { state: RootState; rejectValue: RequestError }
>(
  'giftCard/updateCredit',
  async ({ giftCardId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `gift-cards/${giftCardId}`
      await api?.request(`${endpoint}/credit`, 'POST', data)
      const resp = (await api?.request(endpoint)) as GiftCard
      dispatch(showNotification('Credit Updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    resetGiftCard: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchGiftCard.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchGiftCard.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchGiftCard.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateGiftCard.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateGiftCard.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateGiftCard.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateGiftCardCredit.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateGiftCardCredit.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateGiftCardCredit.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetGiftCard } = giftCardSlice.actions

export const selectGiftCard = (state: RootState) => state.giftCard

export default giftCardSlice.reducer
