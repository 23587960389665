import styled from '@emotion/styled'
import TextBody from './TextBody'

interface TextBooleanViewProps {
  value: boolean
}

const TextBooleanView = styled(TextBody)<TextBooleanViewProps>`
  color: ${props =>
    props.value
      ? props.theme.colors.success.background
      : props.theme.colors.error.background};
`

const TextBoolean = ({
  value,
  flip = false
}: {
  value: boolean
  flip?: boolean
}) => {
  return (
    <TextBooleanView value={flip ? !value : value}>
      {value ? 'Yes' : 'No'}
    </TextBooleanView>
  )
}

export default TextBoolean
