import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { KdsTerminalPrepStations } from 'types'
import { showNotification } from 'slices/notification'

export interface KdsTerminalPrepStationsState {
  data: KdsTerminalPrepStations
  loading: RequestStatus
  error: RequestError
}

const initialState: KdsTerminalPrepStationsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchKdsTerminalPrepStations = createAsyncThunk<
  KdsTerminalPrepStations,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminalPrepStations/fetchKdsTerminalPrepStations',
  async (kdsTerminalId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `pos-v2/kds-terminals/${kdsTerminalId}/prep-stations`
      const resp = (await api?.request(endpoint)) as KdsTerminalPrepStations
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createKdsTerminalPrepStations = createAsyncThunk<
  KdsTerminalPrepStations,
  { kdsTerminalId: string | number; data: KdsTerminalPrepStations },
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminalPrepStations/createKdsTerminalPrepStations',
  async ({ kdsTerminalId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `pos-v2/kds-terminals/${kdsTerminalId}/prep-stations`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as KdsTerminalPrepStations
      dispatch(showNotification('Item types successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateKdsTerminalPrepStations = createAsyncThunk<
  KdsTerminalPrepStations,
  { kdsTerminalId: string | number; data: KdsTerminalPrepStations },
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminalPrepStations/updateKdsTerminalPrepStations',
  async ({ kdsTerminalId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `pos-v2/kds-terminals/${kdsTerminalId}/prep-stations`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as KdsTerminalPrepStations
      dispatch(showNotification('Item types successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteKdsTerminalPrepStations = createAsyncThunk<
  void,
  { kdsTerminalId: string | number; data: KdsTerminalPrepStations },
  { state: RootState; rejectValue: RequestError }
>(
  'kdsTerminalPrepStations/deleteKdsTerminalPrepStations',
  async ({ kdsTerminalId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `pos-v2/kds-terminals/${kdsTerminalId}/prep-stations`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Item types successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const kdsTerminalPrepStationsSlice = createSlice({
  name: 'kdsTerminalPrepStations',
  initialState,
  reducers: {
    resetKdsTerminalPrepStations: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchKdsTerminalPrepStations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchKdsTerminalPrepStations.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      fetchKdsTerminalPrepStations.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(createKdsTerminalPrepStations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createKdsTerminalPrepStations.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      createKdsTerminalPrepStations.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateKdsTerminalPrepStations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateKdsTerminalPrepStations.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      updateKdsTerminalPrepStations.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteKdsTerminalPrepStations.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteKdsTerminalPrepStations.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      deleteKdsTerminalPrepStations.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetKdsTerminalPrepStations } =
  kdsTerminalPrepStationsSlice.actions

export const selectKdsTerminalPrepStations = (state: RootState) =>
  state.kdsTerminalPrepStations

export default kdsTerminalPrepStationsSlice.reducer
