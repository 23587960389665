import configs from 'config/enviroment'
import { refreshSession } from 'utils/authAPI'
import { RequestError } from './api'

interface FileRequest {
  onProgress: (percentage: number) => void
  url: string
  token: string | null
  brandId?: number
  dispatch: any
  fileType: string
  file: File
  fileName: string
}

const fileRequest = ({
  onProgress,
  url,
  token,
  brandId,
  dispatch,
  fileType,
  file,
  fileName
}: FileRequest) => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()

    req.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        onProgress((event.loaded / event.total) * 100)
      }
    })

    req.upload.addEventListener('error', () => {
      reject(req.response)
    })

    // Call a function when the state changes.
    req.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        if (this.status === 204 || this.status === 200) {
          resolve(null)
        } else if (this.status === 201) {
          const resp = JSON.parse(req.responseText)
          resolve({
            url: resp.url,
            filename: resp.filename
          })
        } else if (this.status === 401) {
          dispatch(refreshSession())
        } else {
          const error = JSON.parse(req.responseText)

          let msg
          try {
            msg = error.params.file || error.detail
          } catch (e) {
            msg = error.detail
          }
          reject({
            code: '',
            title: 'Upload Error',
            detail: msg,
            status: 400
          } as RequestError)
          window.scroll(0, 0)
        }
      }
    }

    req.open('POST', `${configs.apiUrl}/${url}`)
    req.setRequestHeader('Authorization', `Bearer ${token}`)
    req.setRequestHeader('brand-id', `${brandId}`)
    req.setRequestHeader('Access-Control-Allow-Origin', '*')
    req.setRequestHeader('file-type', fileType)
    req.setRequestHeader('file-name', fileName)
    req.send(file)
  })
}

export default fileRequest
