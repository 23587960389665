import { ThemeFontSizes } from 'types'
import { ContentTitleText, ContentTitleView } from './Content.styled'

interface ContentTitleProps {
  children: string | React.ReactNode
  size?: keyof ThemeFontSizes
  style?: object
}

const ContentTitle = ({
  children,
  size = 'h1',
  style = {}
}: ContentTitleProps) => {
  return (
    <ContentTitleView style={style}>
      <ContentTitleText as="h1" size={size}>
        {children}
      </ContentTitleText>
    </ContentTitleView>
  )
}

export default ContentTitle
