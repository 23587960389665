import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTask, GiftCards } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface GiftCardsState {
  data: GiftCards
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface GiftCardsAPI {
  data: GiftCards
  links: {
    next: string
  }
}

export interface GiftCardsCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export type GiftCardsParams = {
  batch_id?: number
  store_id?: number
  is_active?: boolean
  search?: string
  expand?: string
  limit?: number
}

const initialState: GiftCardsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchGiftCards = createAsyncThunk<
  GiftCardsAPI,
  GiftCardsParams | void,
  { state: RootState; rejectValue: RequestError }
>('giftCards/fetchGiftCards', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `gift-cards${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as GiftCardsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createGiftCardsCSV = createAsyncThunk<
  GiftCardsCSVAPI,
  GiftCardsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'giftCards/createGiftCardsCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `gift-cards/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as GiftCardsCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const giftCardsSlice = createSlice({
  name: 'giftCards',
  initialState,
  reducers: {
    resetGiftCards: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchGiftCards.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchGiftCards.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchGiftCards.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetGiftCards } = giftCardsSlice.actions

export const selectGiftCards = (state: RootState) => state.giftCards

export default giftCardsSlice.reducer
