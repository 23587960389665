import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { ClipLoader } from 'react-spinners'

const PlaceholderView = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Placeholder = () => {
  const { colors } = useTheme()
  return (
    <PlaceholderView>
      <ClipLoader size={36} color={colors.text.primary} />
    </PlaceholderView>
  )
}

export default Placeholder
