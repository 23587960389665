import React from 'react'
import { Categories, DiscountItemCategory } from 'types'
import { CheckboxItem } from 'components/RevenueCenterTypes/RevenueCenterTypes.styled'
import {
  DiscountedItemCategoryLabel,
  DiscountedItemsGroup,
  DiscountedItemsGroupList,
  DiscountedItemsGroupTitle
} from '../DiscountedAndRequiredItems.styled'

const DiscountedItems = ({
  itemTitle,
  itemValues,
  submit,
  selectedIds,
  updateValues
}: {
  itemTitle: string
  itemValues?: Categories | null
  discountedValues?: Categories | null
  submit?: () => void
  selectedIds?: DiscountItemCategory[]
  updateValues?: (category_id: number, id: number) => void
}) => {
  return (
    <DiscountedItemsGroup>
      <DiscountedItemsGroupTitle as="div" size="large">
        {itemTitle}
      </DiscountedItemsGroupTitle>
      <DiscountedItemsGroupList>
        {itemValues?.map(item => (
          <React.Fragment key={`${itemTitle}-${item.category_id}`}>
            <DiscountedItemCategoryLabel as="div" size="large">
              {item.full_name}
            </DiscountedItemCategoryLabel>
            {item.items?.map(i => (
              <CheckboxItem
                key={`${itemTitle}-${item.category_id}-${i.item.item_id}`}
                label={i.item.full_name}
                name={`${itemTitle}-${item.category_id}-${i.item.item_id}`}
                checked={
                  selectedIds?.some(
                    selectedItem =>
                      selectedItem.category_id === item.category_id &&
                      selectedItem.item_id === i.item.item_id
                  ) ?? false
                }
                onChange={() =>
                  updateValues?.(item.category_id, i.item.item_id)
                }
              />
            ))}
          </React.Fragment>
        ))}
      </DiscountedItemsGroupList>
    </DiscountedItemsGroup>
  )
}

export default DiscountedItems
