import styled from '@emotion/styled'
import Img from './Img'

const ImgTableView = styled.span`
  display: block;
  width: 6rem;
  height: 4rem;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius.small};
`

const ImgTable = ({ src, title }: { src: string | null; title: string }) => {
  return (
    <ImgTableView>
      <Img src={src} title={title} fontSize="small" />
    </ImgTableView>
  )
}

export default ImgTable
