import { useEffect } from 'react'
import { OrderType, RequestedAt } from '@open-tender/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { orderTypeConvertMap } from 'config'
import { closeModal, openModal } from 'slices/modal'
import {
  editBulkOrder,
  fetchOrderRevenueCenter,
  resetOrder,
  selectOrder
} from 'slices/order'
import { editOrder, fetchOrderMgmt } from 'slices/orderMgmt'
import { BulkOrder, ServiceType } from 'types'
import { RequestStatus, dateToFirstTime, zonedDateStrToDate } from 'utils'
import { Button, ButtonList, TextBody } from 'components'
import { ModalCancel, ModalHeader } from 'components/Modal'
import {
  AdjustNotesInternalArgs,
  AdjustTaxExemptArgs,
  AdjustTipArgs,
  OrderRequestedAtArgs,
  OrderRevenueCenterArgs,
  OrderServiceTypeArgs
} from '..'
import { OrderEditFull, OrderEditWarning } from './OrderEdit.styled'

const OrderEdit = ({
  args
}: {
  args: { order: BulkOrder; storeId: string }
}) => {
  const dispatch = useAppDispatch()
  const { order, storeId } = args
  const { receipt_id: orderId, revenue_center_id, is_tax_exempt } = order
  const {
    revenueCenter: rc,
    submitting,
    loading,
    error
  } = useAppSelector(selectOrder)
  const isLoading = loading === RequestStatus.Pending
  const revenueCenter = !isLoading && !error ? rc : null

  useEffect(() => {
    dispatch(resetOrder())
    dispatch(fetchOrderRevenueCenter(revenue_center_id))
  }, [dispatch, revenue_center_id])

  const changeRevenueCenter = () => {
    const { order_type, customer_address: address = null } = order
    const orderType = orderTypeConvertMap[order_type]
    const choose = async (revenue_center_id: number) => {
      await dispatch(editOrder({ orderId, data: { revenue_center_id } }))
      dispatch(fetchOrderMgmt({ store_id: storeId }))
      dispatch(closeModal())
    }
    const newArgs: OrderRevenueCenterArgs = { orderType, address, choose }
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(
        openModal({ type: 'orderRevenueCenter', align: 'top', args: newArgs })
      )
    }, 250)
  }

  const changeServiceType = () => {
    const { service_type: serviceType } = order
    const change = async (service_type: ServiceType) => {
      await dispatch(editOrder({ orderId, data: { service_type } }))
      dispatch(fetchOrderMgmt({ store_id: storeId }))
      dispatch(closeModal())
    }
    const newArgs: OrderServiceTypeArgs = { orderId, serviceType, change }
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'orderServiceType', args: newArgs }))
    }, 250)
  }

  const changeDateTime = async () => {
    const { order_type, service_type: serviceType, requested_at } = order
    if (!revenueCenter) return

    const tz = revenueCenter.timezone
    const requestedDate = zonedDateStrToDate(requested_at, tz)
    const requestedAt = requestedDate.toISOString() as RequestedAt
    const offset = order_type === 'CATERING' ? 15 : 0
    const firstTime = dateToFirstTime(requestedDate, offset)

    const orderType = (
      order_type === 'MAIN_MENU' ? 'OLO' : order_type
    ) as OrderType

    const chooseTime = async (requested_at: RequestedAt) => {
      if (requested_at === 'asap') return
      await dispatch(editOrder({ orderId, data: { requested_at } }))
      dispatch(fetchOrderMgmt({ store_id: storeId }))
      dispatch(closeModal())
    }

    const newArgs: OrderRequestedAtArgs = {
      chooseTime,
      firstTime,
      orderType,
      requestedAt,
      revenueCenter,
      serviceType
    }
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'orderRequestedAt', args: newArgs }))
    }, 250)
  }

  const adjustTip = () => {
    const { tip } = order
    const newArgs: AdjustTipArgs = { orderId, tip, storeId }
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'adjustTip', args: newArgs }))
    }, 250)
  }

  const adjustTaxExempt = () => {
    const { is_tax_exempt: isTaxExempt, tax_exempt_id: taxExemptId } = order
    const newArgs: AdjustTaxExemptArgs = {
      orderId,
      isTaxExempt,
      taxExemptId,
      storeId
    }
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'adjustTaxExempt', args: newArgs }))
    }, 250)
  }

  const adjustNotesInternal = () => {
    const { notes_internal: notesInternal } = order
    const newArgs: AdjustNotesInternalArgs = {
      orderId,
      notesInternal,
      storeId
    }
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'adjustNotesInternal', args: newArgs }))
    }, 250)
  }

  const buttons = [
    { title: 'Change Revenue Center', onClick: changeRevenueCenter },
    { title: 'Change Service Type', onClick: changeServiceType },
    { title: 'Adjust Date and/or Time', onClick: changeDateTime },
    { title: 'Adjust Tip', onClick: adjustTip },
    {
      title: 'Make Tax Exempt',
      onClick: adjustTaxExempt,
      disabled: is_tax_exempt
    },
    {
      title: 'Add / Update Internal Notes',
      onClick: adjustNotesInternal
    }
  ]

  const edit = () => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(editBulkOrder({ bulkOrder: order }))
    }, 250)
  }

  useEffect(() => {
    if (submitting) {
      dispatch(openModal({ type: 'orderEditing', disableClose: true }))
    }
  }, [dispatch, submitting])

  return (
    <>
      <ModalHeader
        title="Edit order"
        subtitle="Please choose which type of change you'd like to make."
      />
      <ButtonList buttons={buttons} />
      <OrderEditFull>
        <TextBody as="p" size="small">
          Need to make some other adjustment?
        </TextBody>
        <Button onClick={edit} color="secondary">
          Perform a Full Edit
        </Button>
        {is_tax_exempt && (
          <OrderEditWarning size="small">
            <strong>
              If you need to reapply tax to this order, please perform a full
              edit.
            </strong>
          </OrderEditWarning>
        )}
      </OrderEditFull>
      <ModalCancel>Nevermind, leave order as is</ModalCancel>
    </>
  )
}

export default OrderEdit
