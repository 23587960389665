import { Select } from 'components/Forms'

const options = [
  { value: 'AND', text: 'AND' },
  { value: 'OR', text: 'OR' }
]

const ListFilterAndOr = ({
  andOr,
  setAndOr
}: {
  andOr: string | null
  setAndOr: (andOr: string) => void
}) => {
  const handleAndOr = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setAndOr(evt.target.value)
  }

  return (
    <Select
      // label="And/Or"
      name="andOr"
      value={andOr}
      onChange={handleAndOr}
      options={options}
    />
  )
}

export default ListFilterAndOr
