import { Store } from 'types'
import Highlight from './Highlight'

const StoreStatus = ({ store }: { store: Store }) => {
  const { closed } = store
  return closed ? (
    <Highlight color="error">CLOSED</Highlight>
  ) : (
    // ) : coming_soon ? (
    //   <Highlight color="error">COMING SOON</Highlight>
    <Highlight color="success">OPEN</Highlight>
  )
}

export default StoreStatus
