import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

export const HomeBrandView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 24rem;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    align-items: flex-start;
    margin: 0 0 0.5rem;
  }
`

export const HomeBrandLogo = styled.div`
  max-width: 14rem;
`

export const HomeBrandLink = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${props => props.theme.sizes.xsmall};
  gap: 0.6rem;
  margin: 0 0 1.5rem;

  span {
    block;
    line-height: 1;
  }

  &:hover {
    color: ${props => props.theme.colors.accent.primary};
  }
`

export const HomeBrandTitle = styled(TextBody)`
  margin: 0.5rem 0 0;
  text-align: right;
`
