import MenuItemOption from './MenuItemOption'
import {
  MenuItemOptionView,
  MenuItemOptionName,
  MenuItemOptionPrice,
  MenuItemOptionQuantity
} from './MenuItemOption.styled'

export {
  MenuItemOptionView,
  MenuItemOptionName,
  MenuItemOptionPrice,
  MenuItemOptionQuantity
}

export default MenuItemOption
