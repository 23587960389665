import styled from '@emotion/styled'
import { useAppDispatch } from 'app/hooks'
import ButtonLink from 'components/ButtonLink'
import { closeModal } from 'slices/modal'
import { ThemeFontSizes } from 'types'

export const ModalCancelView = styled.div`
  margin: 3rem 0 0;
  text-align: center;
`

const ModalCancel = ({
  children = 'Nevermind, close modal',
  size = 'small',
  style
}: {
  children?: React.ReactNode
  size?: keyof ThemeFontSizes
  style?: React.CSSProperties
}) => {
  const dispatch = useAppDispatch()
  const cancel = () => dispatch(closeModal())

  return (
    <ModalCancelView style={style}>
      <ButtonLink size={size} onClick={cancel}>
        {children}
      </ButtonLink>
    </ModalCancelView>
  )
}

export default ModalCancel
