import styled from '@emotion/styled'

export const EditableAvatarView = styled.div`
  min-height: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover .edit-pencil {
    visibility: visible;
    opacity: 1;
  }
`

export const EditableAvatarIconWrapper = styled.span`
  display: flex;
  visibility: hidden;
  opacity: 0;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
`
