import styled from '@emotion/styled'

export const IPhoneLockScreenContainer = styled.div`
  padding: 1.2rem;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const IPhoneLockScreenLockIconContainer = styled.span`
  margin-top: 3rem;
  color: white;
`

export const IPhoneLockScreenTime = styled.span`
  font-size: 6rem;
  color: white;
`

export const IPhoneLockScreenDate = styled.span`
  font-size: 1.5rem;
  color: white;
`

export const IPhoneLockScreenBottomBar = styled.span`
  height: 0.2rem;
  width: 10rem;
  background-color: white;
  margin-bottom: 0.3rem;
  border-radius: 0.2rem;
  opacity: 0.8;
`

export const IPhoneLockScreenNotification = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  font-size: ${props => props.theme.sizes.medium};
  border-radius: ${props => props.theme.borderRadius.large};
  width: 100%;
  min-height: 7rem;
  margin-top: 2rem;
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 5rem 1fr;
  place-items: center;
  align-items: left;
  justify-items: left;
  justify-content: center;
  align-content: center;
`

export const IPhoneLockScreenBrandLogo = styled.div`
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius.small};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const IPhoneLockScreenMessage = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`

export const IPhoneLockScreenQuickActionsContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  width: 100%;
`

export const IPhoneLockScreenQuickActionIcon = styled.span`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: ${props => props.theme.colors.background.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
`
