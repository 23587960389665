import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Tag } from 'types'
import { showNotification } from 'slices/notification'

export interface TagState {
  data: Tag | null
  loading: RequestStatus
  error: RequestError
}

const initialState: TagState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchTag = createAsyncThunk<
  Tag,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('tag/fetchTag', async (tagId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `tags/${tagId}`
    const resp = (await api?.request(endpoint)) as Tag
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createTag = createAsyncThunk<
  Tag,
  { data: Tag; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'tag/createTag',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('tags', 'POST', data)) as Tag
      dispatch(showNotification('Tag successfully created!'))
      const pathname = `/settings/misc/tags/${resp.tag_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateTag = createAsyncThunk<
  Tag,
  {
    tagId: string | number
    data: Tag
  },
  { state: RootState; rejectValue: RequestError }
>(
  'tag/updateTag',
  async ({ tagId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `tags/${tagId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Tag
      dispatch(showNotification('Tag successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteTag = createAsyncThunk<
  void,
  {
    tagId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'tag/deleteTag',
  async ({ tagId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `tags/${tagId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Tag removed!'))
      navigate({ pathname: '/settings/misc/tags' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    resetTag: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTag.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTag.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchTag.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createTag.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createTag.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateTag.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateTag.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteTag.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteTag.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteTag.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTag } = tagSlice.actions

export const selectTag = (state: RootState) => state.tag

export default tagSlice.reducer
