import styled from '@emotion/styled'

export const ListFilterView = styled.div`
  margin: 0 0 3rem;
`

export const ListFilterConditionView = styled.div`
  position: relative;
  margin: 0 0 1rem;
`

export const ListFiltersView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  // max-width: 96rem;
`

export const ListFiltersItem = styled.div`
  flex: 2;

  &:nth-of-type(1) {
    flex: 3;
  }

  &:nth-of-type(3) {
    flex: 4;
  }

  label {
    margin: 0;
  }

  input,
  select {
    padding: 0.8rem 1rem;
    font-size: ${props => props.theme.sizes.small};
  }

  select + span {
    width: 3.6rem;
  }
`

export const ListFilterKeywords = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

export const ListFilterKeywordView = styled.div`
  flex: 1;
`

export const ListFilterRemove = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: -2.8rem;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ListFilterRemoveButton = styled.button`
  padding: 0.2rem 0 0;
  color: ${props => props.theme.colors.text.primary};

  &:hover {
    color: ${props => props.theme.colors.accent.primary};
  }
`

export const ListFilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 0;
`

export const ListFilterButtons = styled.div`
  display: flex;
  justify-content: flex-start;

  button + button {
    margin-left: 1rem;
  }
`
