import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { RevenueCenterServiceTypes } from 'types'
import { showNotification } from 'slices/notification'

export interface RevenueCenterServiceTypesState {
  data: RevenueCenterServiceTypes
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterServiceTypesState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRevenueCenterServiceTypes = createAsyncThunk<
  RevenueCenterServiceTypes,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterServiceTypes/fetchRevenueCenterServiceTypes',
  async (revenueCenterId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/service-types`
      const resp = (await api?.request(endpoint)) as RevenueCenterServiceTypes
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createRevenueCenterServiceTypes = createAsyncThunk<
  RevenueCenterServiceTypes,
  { revenueCenterId: string | number; data: RevenueCenterServiceTypes },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterServiceTypes/createRevenueCenterServiceTypes',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/service-types`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as RevenueCenterServiceTypes
      dispatch(showNotification('Tags successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateRevenueCenterServiceTypes = createAsyncThunk<
  RevenueCenterServiceTypes,
  { revenueCenterId: string | number; data: RevenueCenterServiceTypes },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterServiceTypes/updateRevenueCenterServiceTypes',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/service-types`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as RevenueCenterServiceTypes
      dispatch(showNotification('Tags successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterServiceTypes = createAsyncThunk<
  void,
  { revenueCenterId: string | number; data: RevenueCenterServiceTypes },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterServiceTypes/deleteRevenueCenterServiceTypes',
  async (
    { revenueCenterId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `revenue-centers/${revenueCenterId}/service-types`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Tags successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterServiceTypesSlice = createSlice({
  name: 'revenueCenterServiceTypes',
  initialState,
  reducers: {
    resetRevenueCenterServiceTypes: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterServiceTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchRevenueCenterServiceTypes.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      fetchRevenueCenterServiceTypes.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(createRevenueCenterServiceTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createRevenueCenterServiceTypes.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      createRevenueCenterServiceTypes.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateRevenueCenterServiceTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateRevenueCenterServiceTypes.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      updateRevenueCenterServiceTypes.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteRevenueCenterServiceTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterServiceTypes.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      deleteRevenueCenterServiceTypes.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetRevenueCenterServiceTypes } =
  revenueCenterServiceTypesSlice.actions

export const selectRevenueCenterServiceTypes = (state: RootState) =>
  state.revenueCenterServiceTypes

export default revenueCenterServiceTypesSlice.reducer
