import { ListFilter, OnboardingBrand, SelectOptionInterface } from 'types'

export const listFilterEmptyCondition: ListFilter = {
  category: null,
  operator: null,
  value: null,
  secondValue: null,
  AndOr: ''
}

export const formatIndependeBrands = (
  brands: OnboardingBrand[]
): SelectOptionInterface[] => {
  return brands.map((brand: OnboardingBrand) => ({
    value: brand.brandId,
    text: brand.name
  }))
}

export const enterpriseLevelOptions = [
  { text: 'Superbrand', value: 1 },
  { text: 'Enterprise 1', value: 2 },
  { text: 'Enterprise 2', value: 3 },
  { text: 'Enterprise 3', value: 4 },
  { text: 'Enterprise 4', value: 5 },
  { text: 'SMB', value: 6 }
]

export const locationStatusOptions = [
  {
    text: 'Active',
    value: 'active'
  },

  {
    text: 'BlockedInternal',
    value: 'blocked_internal'
  },

  {
    text: 'BlockedClient',
    value: 'blocked_client'
  },

  {
    text: 'OnHold',
    value: 'on_hold'
  },

  {
    text: 'Churned-InOnboarding',
    value: 'churned_in_onboarding'
  },

  {
    text: 'Churned-Cancelled',
    value: 'churned_cancelled'
  },

  {
    text: 'Live',
    value: 'live'
  },

  {
    text: 'Test',
    value: 'test'
  },

  {
    text: 'Archived',
    value: 'archived'
  },

  {
    text: 'Lab',
    value: 'lab'
  }
]

export type IacmConfigKeys =
  | 'authUrl'
  | 'apiUrl'
  | 'exportApiUrl'
  | 'posTypesApiUrl'

const iacmConfigs: Record<IacmConfigKeys, string> = {
  authUrl:
    process.env.REACT_APP_IACM_AUTH_URL ||
    'https://staging-api.itsacheckmate.com/token_api/auth/sign_in',
  apiUrl:
    process.env.REACT_APP_IACM_API_URL ||
    'https://staging-api.itsacheckmate.com/token_api/graphql',
  exportApiUrl:
    process.env.REACT_APP_IACM_EXPORT_API_URL ||
    'https://staging-api.itsacheckmate.com/token_api/downloads',
  posTypesApiUrl:
    process.env.REACT_APP_POS_TYPES_AI_URL ||
    'https://staging-api.itsacheckmate.com/token_api/pos_types'
}

export default iacmConfigs
