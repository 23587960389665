import {
  CheckmateMenuHoursWeekday,
  CheckmateMenuInfoSectionHeader
} from './CheckmateMenu.styled'
import TextHeading from 'components/TextHeading'
import TextBody from 'components/TextBody'
import { formatDollars } from 'utils'
import { CheckmateMenuDynamicPrepTimes as CheckmateMenuDynamicPrepTimesType } from 'types'

const CheckmateMenuDynamicPrepTimes = ({
  dynamicPrepTimes
}: {
  dynamicPrepTimes: CheckmateMenuDynamicPrepTimesType
}) => {
  return (
    <>
      <CheckmateMenuInfoSectionHeader>
        <TextHeading size="large">Prep Times</TextHeading>
      </CheckmateMenuInfoSectionHeader>
      <CheckmateMenuHoursWeekday>
        <TextBody size="small">Min - Max</TextBody>
        <TextBody size="small">Additional Time</TextBody>
      </CheckmateMenuHoursWeekday>
      {dynamicPrepTimes.map(prepTime => {
        return (
          <CheckmateMenuHoursWeekday key={prepTime.additional_time}>
            <TextBody size="small">
              {formatDollars(prepTime.min)} - {formatDollars(prepTime.max)}
            </TextBody>
            <TextBody size="small">{prepTime.additional_time} Minutes</TextBody>
          </CheckmateMenuHoursWeekday>
        )
      })}
    </>
  )
}

export default CheckmateMenuDynamicPrepTimes
