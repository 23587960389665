import React from 'react'
import {
  StepperContainer,
  StepContainer,
  StepNumber,
  StepName,
  SeparatorLine
} from './Stepper.styled'

export type StepperProps = {
  steps: readonly string[]
  onClick: (step: string) => void
  isStepDisabled?: (step: string) => boolean
  isStepComplete?: (step: string) => boolean
  isStepActive?: (step: string) => boolean
}

const Stepper = ({
  steps,
  onClick,
  isStepDisabled,
  isStepActive,
  isStepComplete
}: StepperProps) => {
  return (
    <StepperContainer>
      {steps.flatMap((step, index) => {
        return [
          <StepContainer
            key={step}
            onClick={() => onClick(step)}
            disabled={isStepDisabled?.(step)}
          >
            <StepNumber
              completed={isStepComplete?.(step)}
              disabled={isStepDisabled?.(step)}
            >
              {index + 1}
            </StepNumber>
            <StepName color="secondary">
              {isStepActive?.(step) ? <strong>{step}</strong> : step}
            </StepName>
          </StepContainer>,
          <SeparatorLine key={`${step}-line`} />
        ]
      })}
    </StepperContainer>
  )
}

export default Stepper
