import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Firebase } from 'types'
import { showNotification } from 'slices/notification'

export interface FirebaseState {
  data: Firebase | null
  loading: RequestStatus
  error: RequestError
}

const initialState: FirebaseState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchFirebase = createAsyncThunk<
  Firebase,
  void,
  { state: RootState; rejectValue: RequestError }
>('firebase/fetchFirebase', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    return (await api?.request('integrations/firebase')) as Firebase
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertFirebase = createAsyncThunk<
  Firebase,
  { data: Firebase },
  { state: RootState; rejectValue: RequestError }
>(
  'firebase/upsertFirebase',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'integrations/firebase',
        'PUT',
        data
      )) as Firebase
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFirebase.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchFirebase.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchFirebase.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertFirebase.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertFirebase.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(upsertFirebase.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectFirebase = (state: RootState) => state.firebase

export default firebaseSlice.reducer
