import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreEmail, StoreSettingsFrom } from 'types'
import { showNotification } from 'slices/notification'

export interface StoreEmailApi {
  data: StoreEmail
  from: StoreSettingsFrom | null
}

export interface StoreEmailState {
  data: StoreEmail | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError
}

const initialState: StoreEmailState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreEmail = createAsyncThunk<
  StoreEmailApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeEmail/fetchStoreEmail',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/email`
      )) as StoreEmailApi
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreEmail = createAsyncThunk<
  StoreEmailApi,
  { storeId: string | number; data: StoreEmail },
  { state: RootState; rejectValue: RequestError }
>(
  'storeEmail/upsertStoreEmail',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/email`,
        'PUT',
        data
      )) as StoreEmailApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreEmail = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeEmail/deleteStoreEmail',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/settings/email`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Email settings removed!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

const storeEmailSlice = createSlice({
  name: 'storeEmail',
  initialState,
  reducers: {
    resetStoreEmail: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStoreEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload?.data || null
      state.from = payload?.from || null
      state.error = null
    })
    builder.addCase(fetchStoreEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.from = payload.from
      state.error = null
    })
    builder.addCase(upsertStoreEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreEmail.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(deleteStoreEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetStoreEmail } = storeEmailSlice.actions

export const selectStoreEmail = (state: RootState) => state.storeEmail

export default storeEmailSlice.reducer
