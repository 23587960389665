import styled from '@emotion/styled'
import Loader from './Loader'
import TextBody from './TextBody'
import TextHeading from './TextHeading'
import { useEffect } from 'react'
import { maybeRefreshVersion } from 'app/version'

const PageContentView = styled.div<{ maxWidth?: string }>`
  label: PageContentView;
  flex-grow: 1;
  margin: 3rem 0 0;
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}` : '')};
`

const PageContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4.5rem 0;
  gap: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 3rem 0;
    flex-direction: column;
    align-items: flex-start;
  }
`

const PageContentHeader = styled.div``

const PageContentTitle = styled(TextHeading)`
  margin-left: -0.1rem;
  font-size: ${props => props.theme.sizes.h3};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.sizes.h4};
  }
`

const PageContentSubtitle = styled(TextBody)`
  max-width: ${props => props.theme.layout.desktop.pageWidth};
  margin: 1rem 0 0;
  line-height: 1.4;
`

const PageContent = ({
  children,
  title,
  subtitle,
  aside,
  loading,
  maxWidth
}: {
  children: React.ReactNode
  title?: string
  subtitle?: string
  aside?: React.ReactNode
  loading?: boolean
  maxWidth?: string
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    maybeRefreshVersion()
  }, [])

  return (
    <PageContentView maxWidth={maxWidth}>
      {title || subtitle || aside ? (
        <PageContentContainer>
          {title || subtitle ? (
            <PageContentHeader>
              <PageContentTitle as="h2">{title}</PageContentTitle>
              {subtitle && (
                <PageContentSubtitle as="p" size="small" color="secondary">
                  {subtitle}
                </PageContentSubtitle>
              )}
            </PageContentHeader>
          ) : null}
          {aside}
        </PageContentContainer>
      ) : null}
      {loading ? <Loader /> : children}
    </PageContentView>
  )
}

export default PageContent
