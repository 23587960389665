import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const PreviewWrapper = styled.div`
  margin: -1.5rem;
`

export const PreviewView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // gap: ${props => props.theme.layout.desktop.gap};
  gap: 2.5rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const PreviewTitle = styled(TextHeading)`
  // margin-top: -2rem;
  margin-bottom: 2rem;
`

export const PreviewSection = styled.div`
  padding: 1.5rem;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  border-radius: ${props => props.theme.borderRadius.medium};
`

export const PreviewSectionTitle = styled(TextHeading)`
  padding: 0 0 0.8rem;
  margin: 0 0 2rem;
  border-bottom-style: solid;
  border-bottom-width: ${props => props.theme.layout.desktop.border};
  border-bottom-color: ${props => props.theme.colors.border.primary};
`

export const PreviewForm = styled(PreviewSection)`
  flex: 1 1 44rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }

  form {
    max-width: 100%;

    label {
      margin: 0 0 2rem;
    }

    label span {
      font-size: ${props => props.theme.labels.fontSizeSmall};
    }

    input,
    select,
    textarea {
      font-size: ${props => props.theme.inputs.fontSizeSmall};
    }

    button {
      font-size: ${props => props.theme.sizes.small};
    }
  }
`

export const PreviewImages = styled(PreviewSection)`
  flex: 0 0 32rem;
`

export const PreviewNutritionalInfoView = styled(PreviewSection)`
  width: 100%;

  form {
    max-width: 100%;

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 1rem;
    }

    label > span {
      flex: 1 1 auto;
      font-size: ${props => props.theme.labels.fontSizeSmall};
    }

    label > span + span {
      flex: 0 0 9rem;
    }

    label > span + span input {
      font-size: ${props => props.theme.inputs.fontSizeSmall};
      padding: 0.8rem 1.2rem;
    }

    button {
      font-size: ${props => props.theme.sizes.small};
    }
  }
`

export const PreviewTagsAllegens = styled.div`
  flex: 0 0 32rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2.5rem;
`

export const PreviewTagsView = styled(PreviewSection)`
  width: 100%;
`

export const PreviewTagsItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
`

export const PreviewTag = styled(TextBody)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.6rem 0.6rem 0.8rem;
  transition: all 0.4 ease;
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${props => props.theme.colors.background.tertiary};

  &:hover {
    color: ${props => props.theme.colors.error.background};
    background-color: ${props => props.theme.colors.background.secondary};
  }
`

export const PreviewTagsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom-style: solid;
  border-bottom-width: ${props => props.theme.layout.desktop.border};
  border-bottom-color: ${props => props.theme.colors.border.primary};

  &:last-of-type {
    border: 0;
  }

  & > span {
    display: block;
  }
`

export const PreviewTagsFooter = styled.div`
  margin: 1.5rem 0 0;
  position: relative;
`

interface PreviewTagsAddProps {
  show: boolean
}

export const PreviewTagsAdd = styled.div<PreviewTagsAddProps>`
  position: absolute;
  z-index: 2;
  top: 125%;
  left: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: ${props => props.theme.borderRadius.small};
  transition: all 0.35s cubic-bezier(0.175, 0.685, 0.32, 1);
  background-color: ${props => props.theme.colors.background.secondary};
  border: 0.1rem solid ${props => props.theme.colors.border.primary};
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
`

export const PreviewTagsAddItem = styled.button`
  width: 100%;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.sizes.small};
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${props => props.theme.colors.background.tertiary};
  border: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};

  &:hover {
    background-color: ${props => props.theme.colors.background.tertiary};
  }
`
