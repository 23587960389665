import React from 'react'
import { TableRows } from 'types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { LineSpace } from 'iconoir-react'
import { DraggableRow } from './TableDraggable.styled'
import { TableHeaderTabletView } from './Table.styled'

interface TableBodyProps {
  // contents: Array<Array<string | JSX.Element>>
  contents: TableRows
  isDraggable?: boolean
  headers: (string | React.ReactNode)[]
}

const TableBody = ({
  contents,
  isDraggable = false,
  headers
}: TableBodyProps) => {
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // Change background color and styles when dragging
    background: isDragging ? 'rgb(224, 224, 224, 0.2)' : 'transparent',
    ...draggableStyle,
    display: isDragging ? 'table' : 'table-row' //fixing the table row styling on drag
  })

  if (isDraggable) {
    return (
      <Droppable droppableId="table-body">
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            {contents.map(({ image, items }, index) => (
              <Draggable key={index} draggableId={`row-${index}`} index={index}>
                {(provided, snapshot) => (
                  <DraggableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {image && <td className="td-image">{image}</td>}
                    {items.map((item, idx) => (
                      <td key={idx}>{item}</td>
                    ))}
                    <td {...provided.dragHandleProps} className="drag-handle">
                      <LineSpace />
                    </td>
                  </DraggableRow>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    )
  }

  return (
    <tbody>
      {contents.map(({ image, items, className }, index) => (
        <tr key={index} className={className || ''}>
          {image && <td className="td-image">{image}</td>}
          {items.map((item, idx) => (
            <td key={idx}>
              <TableHeaderTabletView className="table-header-tablet-view">
                {headers[idx]}
              </TableHeaderTabletView>
              {item}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

export default React.memo(TableBody)
