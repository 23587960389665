import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus } from 'utils'
import { Creds, FormFieldType } from 'types'
import { authToken, selectAuth } from 'slices/auth'
import { fetchAuthUser, selectAuthUser } from 'slices/authUser'
import { Form, ErrMsg, AuthPage, AuthForm, TextBody } from 'components'
import { Link } from 'react-router-dom'

const SignUp = () => {
  const dispatch = useAppDispatch()
  const { token, loading, error } = useAppSelector(selectAuth)
  const { user } = useAppSelector(selectAuthUser)
  const isLoading = loading === RequestStatus.Pending
  const notUser = !!(token && !isLoading && !user)
  const [initialState] = useState({
    brand: '',
    domain: '',
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  })

  const signUpFields: FormFieldType[] = [
    {
      fieldType: 'Input',
      type: 'text',
      name: 'name',
      required: true,
      label: 'Full Name',
      // placeholder: 'Michael Scott',
      error: 'Please enter a first name'
    },
    {
      fieldType: 'Input',
      type: 'email',
      name: 'email',
      required: true,
      label: 'Email',
      // placeholder: 'michael.scott@dundermifflin.co',
      error: 'Please enter a valid email address'
    },
    {
      fieldType: 'Input',
      type: 'password',
      name: 'password',
      required: true,
      label: 'Password (8 characters minimum)',
      // placeholder: '8 characters minimum, no special characters',
      error: 'Please enter a password'
    }
    // {
    //   fieldType: 'Input',
    //   type: 'text',
    //   name: 'brand',
    //   required: true,
    //   label: 'Restaurant Brand',
    //   placeholder: 'Dunder Mifflin',
    //   error: "Please enter your brand's name"
    // },
    // {
    //   fieldType: 'Input',
    //   type: 'text',
    //   name: 'domain',
    //   required: true,
    //   label: 'Website Domain',
    //   placeholder: 'dundermifflin.co',
    //   error: "Please enter your brand's name"
    // }
  ]

  const handleSignUp = (values: Creds) => {
    dispatch(authToken(values))
  }

  useEffect(() => {
    if (notUser) dispatch(fetchAuthUser())
  }, [dispatch, notUser])

  return (
    <>
      <AuthPage src="https://assets.opentender.io/images/heroes/ARTD-C01-Device-005-3000x1956.jpg">
        <AuthForm
          title="Let's do this."
          subtitle="You're just minutes away from building your very own killer app."
          footer={
            <TextBody as="p" size="small">
              Already have an account? <Link to="/">Sign in here.</Link>
            </TextBody>
          }
        >
          {error && <ErrMsg errMsg={error.error_description as string} />}
          <Form
            fields={signUpFields}
            data={initialState}
            isLoading={isLoading}
            submit={handleSignUp}
            submitText="Sign Up For An Account"
            submitColor="secondary"
          />
        </AuthForm>
      </AuthPage>
    </>
  )
}

export default SignUp
