import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { PosIdentityRequests } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface PosIdentityRequestsState {
  data: PosIdentityRequests
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface PosIdentityRequestsAPI {
  data: PosIdentityRequests
  links: {
    next: string
  }
}

const initialState: PosIdentityRequestsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type PosIdentityRequestsParams = {
  cursor?: string
  limit?: number
}

export const fetchPosIdentityRequests = createAsyncThunk<
  PosIdentityRequestsAPI,
  PosIdentityRequestsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'posIdentityRequests/fetchPosIdentityRequests',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `pos-v2/identity-requests${queryParams}`
      return (await api?.request(endpoint)) as PosIdentityRequestsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posIdentityRequestsSlice = createSlice({
  name: 'posIdentityRequests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPosIdentityRequests.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchPosIdentityRequests.fulfilled,
      (state, { payload }) => {
        state.data = payload.data
        state.links = payload.links
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(fetchPosIdentityRequests.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectPosIdentityRequests = (state: RootState) =>
  state.posIdentityRequests

export default posIdentityRequestsSlice.reducer
