import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { ItemLoyaltyTiers } from 'types'
import { showNotification } from 'slices/notification'

export interface ItemLoyaltyTierState {
  data: ItemLoyaltyTiers
  loading: RequestStatus
  error: RequestError
}

const initialState: ItemLoyaltyTierState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItemLoyaltyTier = createAsyncThunk<
  ItemLoyaltyTiers,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyTier/fetchItemLoyaltyTier',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-tiers`
      const resp = (await api?.request(endpoint)) as ItemLoyaltyTiers
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createItemLoyaltyTier = createAsyncThunk<
  ItemLoyaltyTiers,
  { itemId: string | number; data: ItemLoyaltyTiers },
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyTier/createItemLoyaltyTier',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-tiers`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as ItemLoyaltyTiers
      dispatch(showNotification('Loyalty Tier successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteItemLoyaltyTier = createAsyncThunk<
  void,
  { itemId: string | number; data: ItemLoyaltyTiers },
  { state: RootState; rejectValue: RequestError }
>(
  'itemLoyaltyTier/deleteItemLoyaltyTier',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-tiers`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Loyalty Tiers successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemLoyaltyTierSlice = createSlice({
  name: 'itemLoyaltyTier',
  initialState,
  reducers: {
    resetItemLoyaltyTier: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemLoyaltyTier.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemLoyaltyTier.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchItemLoyaltyTier.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createItemLoyaltyTier.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createItemLoyaltyTier.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createItemLoyaltyTier.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteItemLoyaltyTier.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteItemLoyaltyTier.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteItemLoyaltyTier.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItemLoyaltyTier } = itemLoyaltyTierSlice.actions

export const selectItemLoyaltyTier = (state: RootState) => state.itemLoyaltyTier

export default itemLoyaltyTierSlice.reducer
