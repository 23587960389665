import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LoyaltyBonus } from 'types'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

interface LoyaltyBonusItemsState {
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyBonusItemsState = {
  loading: RequestStatus.Idle,
  error: null
}

export const createLoyaltyBonusItem = createAsyncThunk<
  void,
  {
    itemIds: number[]
    loyaltyBonusId: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyBonusItem/createLoyaltyBonusItem',
  async (
    { itemIds, loyaltyBonusId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const promises = itemIds.map(async itemId => {
        const response = await api?.request(
          `items/${itemId}/loyalty-bonuses`,
          'POST',
          [{ loyalty_bonus_id: loyaltyBonusId }]
        )
        dispatch(showNotification('Item(s) successfully added!'))
        return response as LoyaltyBonus
      })
      await Promise.all(promises)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteLoyaltyBonusItem = createAsyncThunk<
  void,
  { itemId: string | number; data: { loyalty_bonus_id: number }[] },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyBonusItem/deleteLoyaltyBonusItem',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/loyalty-bonuses`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Item(s) successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyBonusItemSlice = createSlice({
  name: 'loyaltyBonusItem',
  initialState,
  reducers: {
    resetLoyaltyBonusItem: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createLoyaltyBonusItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyBonusItem.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createLoyaltyBonusItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteLoyaltyBonusItem.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteLoyaltyBonusItem.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteLoyaltyBonusItem.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyBonusItem } = loyaltyBonusItemSlice.actions

export const selectLoyaltyBonusItem = (state: RootState) =>
  state.loyaltyBonusItem

export default loyaltyBonusItemSlice.reducer
