import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { makeMap } from 'utils'
import { selectFiles, fetchFiles } from 'slices/files'
import { ErrMsg } from 'components'
import { Upload } from '.'
import { FilesView } from './Files.styled'

interface FilesProps {
  endpoint: string
  config: { name: string; type: string; hideDelete?: boolean }[]
  displayType?: 'DEFAULT' | 'SMALL'
}

const Files = ({ endpoint, config, displayType }: FilesProps) => {
  const dispatch = useAppDispatch()
  const { data, error } = useAppSelector(selectFiles)
  const errMsg = error?.detail

  useEffect(() => {
    dispatch(fetchFiles(endpoint))
  }, [dispatch, endpoint])

  const files = makeMap(data, 'file_type')

  return config.length ? (
    <FilesView displayType={displayType}>
      {error && <ErrMsg errMsg={errMsg} />}
      {config.map(config => (
        <Upload
          key={config.type}
          name={config.name}
          endpoint={endpoint}
          fileObj={files[config.type]}
          fileType={config.type}
          hideDelete={config.hideDelete}
          displayType={displayType}
        />
      ))}
    </FilesView>
  ) : null
}

export default Files
