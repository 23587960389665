import { useState } from 'react'
import { DeliveryFee } from 'types'
import {
  DeliveryFeeSubsidyRemove,
  DeliveryFeeSubsidyView
} from './DeliveryFeeSubsidies.styled'
import { Input } from 'components/Forms'
import { MinusCircle } from 'iconoir-react'

const fields: { name: keyof DeliveryFee; label: string }[] = [
  { name: 'minimum', label: 'Minimum' },
  { name: 'maximum', label: 'Maximum' },
  { name: 'fee', label: 'Fee' }
]

const DeliveryFeeSubsidy = ({
  index,
  deliveryFee,
  update,
  remove
}: {
  index: number
  deliveryFee: DeliveryFee
  update: (index: number, fee: DeliveryFee) => void
  remove: (evt: React.MouseEvent<HTMLButtonElement>, index: number) => void
}) => {
  const [values, setValues] = useState(deliveryFee)

  const onChange = (name: string, evt: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFee = { ...values, [name]: evt.target.value }
    setValues(updatedFee)
    update(index, updatedFee)
  }

  return (
    <DeliveryFeeSubsidyView>
      {fields.map(field => (
        <Input
          type="number"
          key={field.name}
          name={`${index}-${field.name}`}
          value={deliveryFee[field.name] || ''}
          onChange={evt => onChange(field.name, evt)}
        />
      ))}
      <DeliveryFeeSubsidyRemove onClick={evt => remove(evt, index)}>
        <MinusCircle strokeWidth={1} />
      </DeliveryFeeSubsidyRemove>
    </DeliveryFeeSubsidyView>
  )
}

export default DeliveryFeeSubsidy
