import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus } from 'utils'
import { ModalCancel, ModalHeader } from 'components/Modal'
import { ButtonList, Loader } from 'components'
import { fetchDiscounts, selectDiscounts } from 'slices/discounts'
import { addDiscountInternal, selectOrder } from 'slices/order'
import { closeModal } from 'slices/modal'
import { DiscountsExisting } from 'types'

const OrderDiscount = () => {
  const dispatch = useAppDispatch()
  const { data, loading } = useAppSelector(selectDiscounts)
  const { check } = useAppSelector(selectOrder)
  const selectedIds = check?.discounts.map(i => i.id) || []
  const isLoading = loading === RequestStatus.Pending
  const validDiscounts = data.filter(
    i => i.discount_id && i.discount_type !== 'LOYALTY'
  ) as DiscountsExisting

  useEffect(() => {
    dispatch(
      fetchDiscounts({
        limit: 1000,
        is_active: true,
        is_deal: false,
        is_promo_code: false,
        is_reward: false
      })
    )
  }, [dispatch])

  const choose = (id: number) => {
    dispatch(addDiscountInternal({ id }))
    dispatch(closeModal())
  }

  const buttons = validDiscounts.map(i => ({
    title: i.name,
    onClick: () => choose(i.discount_id),
    disabled: selectedIds.includes(i.discount_id) || isLoading
  }))

  return (
    <>
      <ModalHeader title="Discounts" subtitle="Add one or more discounts" />
      {isLoading && <Loader />}
      <ButtonList buttons={buttons} />
      <ModalCancel>Nevermind, don't add a discount</ModalCancel>
    </>
  )
}

export default OrderDiscount
