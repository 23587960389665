import { request } from 'graphql-request'
import iacmConfigs from 'config/iacm'
import { errorsApi } from './errors'
import { IacmAuthHeaders, IacmAuthUser, IacmCreds, IacmLoginError } from 'types'

export const iacmSignInRequest = async (
  auth: IacmCreds
): Promise<{ headers: IacmAuthHeaders; user: IacmAuthUser }> => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = iacmConfigs.authUrl
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Expose-Headers':
            '[access-token, expiry, token-type, uid, client]'
        },
        body: auth ? JSON.stringify(auth) : null
      })
      if (!res.ok) {
        const error = (await res.json()) as IacmLoginError
        return reject(error)
      }
      const headers: IacmAuthHeaders = {
        accessToken: res.headers.get('access-token'),
        client: res.headers.get('client'),
        uid: res.headers.get('uid'),
        expiry: res.headers.get('expiry')
      }
      const json = await res.json()
      const user = json.data as IacmAuthUser
      return resolve({ headers, user })
    } catch (err: any) {
      return reject(errorsApi.internalServerError)
    }
  })
}

interface IacmConfig {
  accessToken: string | null
  client: string | null
  uid: string | null
  expiry: string | null
}

class IacmAPI {
  apiUrl: string
  accessToken: string | null
  client: string | null
  uid: string | null
  expiry: string | null

  constructor(config: IacmConfig) {
    this.apiUrl = iacmConfigs.apiUrl
    this.accessToken = config.accessToken
    this.client = config.client
    this.uid = config.uid
    this.expiry = config.expiry
  }

  request = async <T>(
    document: any,
    variables: any
  ): Promise<T | null | undefined> => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestHeaders = {
          'Access-Token': `${this.accessToken}`,
          Client: `${this.client}`,
          Uid: `${this.uid}`,
          Expiry: `${this.expiry}`
        }
        const res: T = await request({
          url: this.apiUrl,
          document,
          variables,
          requestHeaders
        })
        return resolve(res)
      } catch (err: any) {
        if (
          err.response?.errors &&
          err.response.errors.length > 0 &&
          err.response.errors[0].message
        ) {
          return reject({
            code: 'Not_allowed',
            title: 'Error Occured',
            detail: err.response.errors[0].message,
            status: 400
          })
        }
        return reject(errorsApi.internalServerError)
      }
    })
  }
}

export default IacmAPI
