import styled from '@emotion/styled'

const DesignTextareaView = styled.textarea`
  width: 100%;
  outline: none;
  background-color: inherit;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
  border-radius: ${props => props.theme.inputs.borderRadius};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  line-height: ${props => props.theme.inputs.lineHeight};
  color: ${props => props.theme.inputs.color};
  padding: 0.7rem 0.9rem;
  font-size: ${props => props.theme.sizes.xsmall};

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.inputs.color};
  }

  &::placeholder {
    color: ${props => props.theme.inputs.placeholderColor};
    opacity: ${props => props.theme.inputs.placeholderOpacity};
  }

  &::selection {
    color: ${props => props.theme.colors.background.primary};
    background-color: ${props => props.theme.colors.text.primary};
  }
`

interface DesignTextareaProps {
  name: string
  value: string | number
  setValue: (value: string | number) => void
  disabled?: boolean
}

const DesignTextarea = ({
  name,
  value,
  setValue,
  disabled = false
}: DesignTextareaProps) => {
  const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value)
  }

  return (
    <>
      <DesignTextareaView
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  )
}

export default DesignTextarea
