import styled from '@emotion/styled'
import { Checkbox } from 'components/Forms'
import TextHeading from 'components/TextHeading'

export const RevenueCentersGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: ${props => props.theme.layout.desktop.padding};
`

export const RevenueCentersGroup = styled.div`
  flex: 1 1 14.3rem;
  min-width: 14.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  label {
    margin: 0 0 1.5rem;
  }
`

export const RevenueCentersGroupTitle = styled(TextHeading)`
  padding: 1.5rem 0;
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
`

export const CheckboxItem = styled(Checkbox)`
  margin: 0 0 1rem;
`

export const RevenueCentersGroupList = styled.div`
  padding: 1.5rem 0 0;
`
