import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { WaitTimesPickup } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface WaitTimesPickupsAPI {
  data: WaitTimesPickup
  links: {
    next: string
  }
}

export interface WaitTimesPickupsState {
  data: WaitTimesPickup
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: WaitTimesPickupsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchWaitTimesPickup = createAsyncThunk<
  WaitTimesPickupsAPI,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimesPickup/fetchWaitTimesPickup',
  async (_, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      return (await api?.request('pickup-wait-times')) as WaitTimesPickupsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const waitTimesPickupSlice = createSlice({
  name: 'waitTimesPickup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWaitTimesPickup.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchWaitTimesPickup.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchWaitTimesPickup.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectWaitTimesPickup = (state: RootState) => state.waitTimesPickup

export default waitTimesPickupSlice.reducer
