import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { ThemeFontSizes } from 'types'
import TextBody from './TextBody'

const ImgView = styled.span`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.secondary};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.15s ease-in;
  }

  img.-show {
    opacity: 1;
  }

  img.-hide {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const ImgLoading = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    border-width: 0.1rem;
  }
`

const ImgErr = styled(TextBody)`
  display: block;
  text-align: center;
  padding: 1rem 1rem;
`

const Img = ({
  src,
  title,
  loaderSize = 16,
  fontSize = 'medium'
}: {
  src: string | null
  title: string
  loaderSize?: number
  fontSize?: keyof ThemeFontSizes
}) => {
  const { colors } = useTheme()
  const [hasLoaded, setHasLaoded] = useState(false)
  const [hasError, setHasError] = useState(false)

  if (!src) return <ImgView />

  return (
    <ImgView>
      {hasError ? (
        <ImgErr size={fontSize}>Image failed to load</ImgErr>
      ) : !hasLoaded ? (
        <ImgLoading>
          <ClipLoader size={loaderSize} color={colors.text.primary} />
        </ImgLoading>
      ) : null}
      <img
        src={src}
        alt={title}
        onLoad={() => setHasLaoded(true)}
        onError={() => setHasError(true)}
        className={hasError ? '-hide' : hasLoaded ? '-show' : ''}
      />
    </ImgView>
  )
}

export default Img
