import { Theme } from '@emotion/react'
import { ColorScheme, ThemeButtonSizes } from 'types'

export interface ThemeProps {
  theme: Theme
}

// green = 04a89b
// dark green = 01645D
// light green = F2FCFB
// text = 000000de
// border gray table = e0e0e0
// border gray subtle = eeeeee
// gray background = f8f8f8

const themes = {
  DARK: {
    background: {
      primary: '#131F2E',
      secondary: '#1D2E43',
      tertiary: '#606589'
    },
    text: {
      primary: '#D8DFF3',
      secondary: '#AAAED0',
      tertiary: '#AAAED0'
    },
    border: {
      primary: '#606589',
      secondary: '#606589',
      tertiary: '#606589'
    },
    accent: {
      primary: '#1E96E4',
      secondary: '#1E96E4',
      tertiary: '#1E96E4'
    },
    sidebar: {
      background: '#131F2E',
      border: '#606589',
      color: '#D8DFF3',
      hoverColor: '#1E96E4',
      hoverBackground: '#1D2E43',
      colorSchemeBackground: '#1D2E43'
    },
    editor: {
      inputColor: '#fdfaf6',
      labelColor: '#d3d3d3'
    },
    buttons: {
      primary: {
        default: {
          text: '#F0F9FF',
          border: '#1E96E4',
          background: '#1E96E4'
        },
        hover: {
          text: '#1E96E4',
          border: '#F0F9FF',
          background: '#F0F9FF'
        }
      },
      secondary: {
        default: {
          text: '#1E96E4',
          border: '#1E96E4',
          background: 'transparent'
        },
        hover: {
          text: '#F0F9FF',
          border: '#F0F9FF',
          background: 'transparent'
        }
      },
      tertiary: {
        default: {
          text: '#F8FAFF',
          border: '#606589',
          background: '#606589'
        },
        hover: {
          text: '#606589',
          border: '#D8DFF3',
          background: '#D8DFF3'
        }
      },
      delete: {
        default: {
          text: '#FFFFFF',
          border: '#DC2B2B',
          background: '#DC2B2B'
        },
        hover: {
          text: '#DC2B2B',
          border: '#FFEFF0',
          background: '#FFEFF0'
        }
      },
      success: {
        default: {
          text: '#FFFFFF',
          border: '#11845B',
          background: '#11845B'
        },
        hover: {
          text: '#11845B',
          border: '#DEF2E6',
          background: '#DEF2E6'
        }
      }
    },
    error: {
      text: '#741500',
      border: '#FFEFF0',
      background: '#FFEFF0'
    },
    success: {
      text: '#005F3D',
      border: '#DEF2E6',
      background: '#DEF2E6'
    },
    alert: {
      text: '#8E4B10',
      border: '#FFF6E4',
      background: '#FFF6E4'
    },
    info: {
      text: '#063E88',
      border: '#F0F9FF',
      background: '#F0F9FF'
    },
    default: {
      text: '#063E88',
      border: '#F0F9FF',
      background: '#F0F9FF'
    },
    badges: {
      primary: {
        text: '#F0F9FF',
        background: '#1E96E4'
      },
      secondary: {
        text: '#FFF5F2',
        background: '#E02419'
      },
      info: {
        text: '#F0F9FF',
        background: '#1E96E4'
      },
      error: {
        text: '#FFEFF0',
        background: '#DC2B2B'
      },
      warning: {
        text: '#8E4B10',
        background: '#FFE39B'
      },
      success: {
        text: '#DEF2E6',
        background: '#11845B'
      },
      default: {
        text: '#FFFFFF',
        background: '#606589'
      }
    }
  },
  LIGHT: {
    background: {
      primary: '#ffffff',
      secondary: '#F8FAFF',
      // tertiary: '#F1F5FF',
      tertiary: '#D8DFF3'
    },
    text: {
      primary: '#1D2E43',
      secondary: '#606589',
      tertiary: '#606589'
    },
    border: {
      primary: '#D8DFF3',
      secondary: '#D8DFF3',
      tertiary: '#D8DFF3'
    },
    accent: {
      primary: '#1E96E4',
      secondary: '#1E96E4',
      tertiary: '#1E96E4'
    },
    sidebar: {
      background: '#131F2E',
      border: '#606589',
      color: '#D8DFF3',
      hoverColor: '#1E96E4',
      hoverBackground: '#1D2E43',
      colorSchemeBackground: '#1D2E43'
    },
    editor: {
      inputColor: '#212121',
      labelColor: '#212121'
    },
    buttons: {
      primary: {
        default: {
          text: '#FFFFFF',
          border: '#1E96E4',
          background: '#1E96E4'
        },
        hover: {
          text: '#F0F9FF',
          border: '#063E88',
          background: '#063E88'
        }
      },
      secondary: {
        default: {
          text: '#1E96E4',
          border: '#1E96E4',
          background: 'transparent'
        },
        hover: {
          text: '#063E88',
          border: '#063E88',
          background: 'transparent'
        }
      },
      tertiary: {
        default: {
          text: '#FFFFFF',
          border: '#606589',
          background: '#606589'
        },
        hover: {
          text: '#F1F5FF',
          border: '#1D2E43',
          background: '#1D2E43'
        }
      },
      delete: {
        default: {
          text: '#FFFFFF',
          border: '#DC2B2B',
          background: '#DC2B2B'
        },
        hover: {
          text: '#FFF5F2',
          border: '#741500',
          background: '#741500'
        }
      },
      success: {
        default: {
          text: '#FFFFFF',
          border: '#11845B',
          background: '#11845B'
        },
        hover: {
          text: '#DEF2E6',
          border: '#005F3D',
          background: '#005F3D'
        }
      }
    },
    error: {
      text: '#741500',
      border: '#FFEFF0',
      background: '#FFEFF0'
    },
    success: {
      text: '#005F3D',
      border: '#DEF2E6',
      background: '#DEF2E6'
    },
    alert: {
      text: '#8E4B10',
      border: '#FFF6E4',
      background: '#FFF6E4'
    },
    info: {
      text: '#063E88',
      border: '#F0F9FF',
      background: '#F0F9FF'
    },
    default: {
      text: '#063E88',
      border: '#F0F9FF',
      background: '#F0F9FF'
    },
    badges: {
      primary: {
        text: '#063E88',
        background: '#F0F9FF'
      },
      secondary: {
        text: '#741500',
        background: '#FFF5F2'
      },
      info: {
        text: '#063E88',
        background: '#F0F9FF'
      },
      error: {
        text: '#741500',
        background: '#FFEFF0'
      },
      warning: {
        text: '#8E4B10',
        background: '#FFF6E4'
      },
      success: {
        text: '#005F3D',
        background: '#DEF2E6'
      },
      default: {
        text: '#FFFFFF',
        background: '#606589'
      }
    }
  }
}

export const makeTheme = (mode: ColorScheme): Theme => {
  const currentTheme = themes[mode]
  return {
    breakpoints: {
      mobile: '640px',
      tablet: '1024px',
      desktop: '1024px'
    },
    landing: {
      text: {
        primary: '#1D2E43',
        secondary: '#606589'
      },
      background: {
        primary: '#ffffff',
        secondary: '#F8FAFF'
      },
      border: {
        primary: '#D8DFF3',
        secondary: '#D8DFF3'
      },
      accent: {
        primary: '#1e96e4',
        secondary: '#063e88'
      }
    },
    layout: {
      desktop: {
        header: {
          height: '7.2rem'
        },
        sidebar: {
          width: '20rem',
          padding: '3rem'
        },
        pageWidth: '54rem',
        padding: '6rem',
        border: '0.1rem',
        margin: '6rem',
        inputWidth: '16rem',
        gap: '3rem',
        lineHeight: 1.4
      },
      mobile: {
        header: {
          height: '6rem'
        },
        sidebar: {
          width: '20rem',
          padding: '3rem'
        },
        pageWidth: '64rem',
        padding: '2rem',
        border: '0.1rem',
        margin: '3rem',
        inputWidth: '16rem',
        gap: '1.5rem',
        lineHeight: 1.4
      }
    },
    transition: 'all 0.15s ease',
    overlay: 'rgba(0, 0, 0, 0.65)',
    borderRadius: {
      small: '0.4rem',
      medium: '0.6rem',
      large: '1.2rem'
    },
    colors: {
      sidebar: {
        background: currentTheme.sidebar.background,
        border: currentTheme.sidebar.border,
        color: currentTheme.sidebar.color,
        hoverColor: currentTheme.sidebar.hoverColor,
        hoverBackground: currentTheme.sidebar.hoverBackground,
        colorSchemeBackground: currentTheme.sidebar.colorSchemeBackground
      },
      background: {
        primary: currentTheme.background.primary,
        secondary: currentTheme.background.secondary,
        tertiary: currentTheme.background.tertiary,
        quarternary: currentTheme.background.tertiary
      },
      border: {
        primary: currentTheme.border.primary,
        secondary: currentTheme.border.secondary,
        tertiary: currentTheme.border.tertiary
      },
      text: {
        primary: currentTheme.text.primary,
        secondary: currentTheme.text.primary,
        tertiary: currentTheme.text.secondary,
        quarternary: currentTheme.text.secondary
      },
      accent: {
        primary: currentTheme.accent.primary,
        secondary: currentTheme.accent.secondary,
        tertiary: currentTheme.accent.tertiary
      },
      error: {
        color: currentTheme.error.text,
        border: currentTheme.error.border,
        background: currentTheme.error.background
      },
      success: {
        color: currentTheme.success.text,
        border: currentTheme.success.border,
        background: currentTheme.success.background
      },
      alert: {
        color: currentTheme.alert.text,
        border: currentTheme.alert.border,
        background: currentTheme.alert.background
      },
      info: {
        color: currentTheme.info.text,
        border: currentTheme.info.border,
        background: currentTheme.info.background
      },
      default: {
        color: currentTheme.default.text,
        border: currentTheme.default.border,
        background: currentTheme.default.background
      }
    },
    sizes: {
      xxxsmall: '1.0rem',
      xxsmall: '1.1rem',
      xsmall: '1.28rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.4rem',
      xxxlarge: '2.8rem',
      mega: '6.4rem',
      giga: '8.0rem',
      tera: '9.6rem',
      h1: '4.0rem',
      h2: '3.4rem',
      h3: '3.0rem',
      h4: '2.6rem',
      h5: '2.2rem',
      h6: '1.8rem'
    },
    labels: {
      color: currentTheme.text.secondary,
      fontSize: '1.4rem',
      fontSizeSmall: '1.2rem'
    },
    inputs: {
      padding: '1rem 1.2rem',
      borderWidth: '0.1rem',
      borderColor: currentTheme.border.primary,
      borderRadius: '0.8rem',
      backgroundColor: currentTheme.background.secondary,
      checkboxBorderColor: currentTheme.border.primary,
      color: currentTheme.text.primary,
      placeholderColor: currentTheme.text.secondary,
      placeholderOpacity: 1,
      fontSize: '1.4rem',
      fontSizeSmall: '1.4rem',
      fontFamily: "'Figtree', sans-serif",
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: 1.42857
    },
    editor: {
      sidebar: {
        width: '3.2rem',
        padding: '1rem'
      },
      sidebarNav: {
        width: '20rem',
        padding: '1rem'
      },
      panel: {
        width: '32.6rem',
        padding: '1.5rem',
        gap: '0.5rem'
      },
      input: {
        color: currentTheme.editor.inputColor,
        bordeColor: currentTheme.border.primary,
        borderRadius: '0.4rem',
        borderWidth: '0.1rem',
        fontSize: '1.2rem',
        height: '3rem',
        padding: '0 0.9rem',
        placeholderColor: currentTheme.text.secondary,
        width: '22.5rem'
      },
      label: {
        color: currentTheme.editor.labelColor,
        fontSize: '1.1rem',
        margin: '0 0 1rem',
        width: '7rem'
      }
    },
    messages: {
      padding: '0.8rem 1.5rem',
      borderRadius: '0.4rem',
      lineHeight: 1.2
    },
    buttons: {
      sizes: {
        xsmall: {
          padding: '4px 12px',
          borderRadius: '8px',
          fontSize: '14px',
          height: '32px'
        },
        small: {
          padding: '4px 12px',
          borderRadius: '8px',
          fontSize: '14px',
          height: '32px'
        },
        medium: {
          padding: '4px 16px',
          borderRadius: '8px',
          fontSize: '14px',
          height: '36px'
        },
        large: {
          padding: '4px 20px',
          borderRadius: '8px',
          fontSize: '14px',
          height: '40px'
        }
      },
      colors: {
        primary: {
          default: {
            text: currentTheme.buttons.primary.default.text,
            border: currentTheme.buttons.primary.default.border,
            background: currentTheme.buttons.primary.default.background
          },
          hover: {
            text: currentTheme.buttons.primary.hover.text,
            border: currentTheme.buttons.primary.hover.border,
            background: currentTheme.buttons.primary.hover.background
          }
        },
        secondary: {
          default: {
            text: currentTheme.buttons.secondary.default.text,
            border: currentTheme.buttons.secondary.default.border,
            background: currentTheme.buttons.secondary.default.background
          },
          hover: {
            text: currentTheme.buttons.secondary.hover.text,
            border: currentTheme.buttons.secondary.hover.border,
            background: currentTheme.buttons.secondary.hover.background
          }
        },
        tertiary: {
          default: {
            text: currentTheme.buttons.tertiary.default.text,
            border: currentTheme.buttons.tertiary.default.border,
            background: currentTheme.buttons.tertiary.default.background
          },
          hover: {
            text: currentTheme.buttons.tertiary.hover.text,
            border: currentTheme.buttons.tertiary.hover.border,
            background: currentTheme.buttons.tertiary.hover.background
          }
        },
        delete: {
          default: {
            text: currentTheme.buttons.delete.default.text,
            border: currentTheme.buttons.delete.default.border,
            background: currentTheme.buttons.delete.default.background
          },
          hover: {
            text: currentTheme.buttons.delete.hover.text,
            border: currentTheme.buttons.delete.hover.border,
            background: currentTheme.buttons.delete.hover.background
          }
        },
        success: {
          default: {
            text: currentTheme.buttons.success.default.text,
            border: currentTheme.buttons.success.default.border,
            background: currentTheme.buttons.success.default.background
          },
          hover: {
            text: currentTheme.buttons.success.hover.text,
            border: currentTheme.buttons.success.hover.border,
            background: currentTheme.buttons.success.hover.background
          }
        }
      }
    },
    badges: {
      primary: {
        text: currentTheme.badges.primary.text,
        background: currentTheme.badges.primary.background
      },
      secondary: {
        text: currentTheme.badges.secondary.text,
        background: currentTheme.badges.secondary.background
      },
      info: {
        text: currentTheme.badges.info.text,
        background: currentTheme.badges.info.background
      },
      error: {
        text: currentTheme.badges.error.text,
        background: currentTheme.badges.error.background
      },
      warning: {
        text: currentTheme.badges.warning.text,
        background: currentTheme.badges.warning.background
      },
      success: {
        text: currentTheme.badges.success.text,
        background: currentTheme.badges.success.background
      },
      default: {
        text: currentTheme.badges.default.text,
        background: currentTheme.badges.default.background
      }
    }
  }
}

export const makeIconSize = (size: keyof ThemeButtonSizes) => {
  switch (size) {
    case 'xsmall':
      return '1.4rem'
    case 'small':
      return '1.6rem'
    case 'medium':
      return '1.8rem'
    case 'large':
      return '2.0rem'
    default:
      return '1.8rem'
  }
}
