import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

interface UploadHeaderProps {
  icon: React.ReactNode
  text: string
}

const UploadHeaderView = styled.div`
  width: 100%;
  padding: 0 2rem;

  span:first-of-type {
    color: ${props => props.theme.colors.text.primary};
    width: 3.2rem;
  }

  span:last-of-type {
    text-align: center;
    color: ${props => props.theme.colors.text.primary};
  }
`

const UploadHeader = ({ icon, text }: UploadHeaderProps) => {
  return (
    <UploadHeaderView>
      <span>{icon}</span>
      <TextBody size="small">{text}</TextBody>
    </UploadHeaderView>
  )
}

export default UploadHeader
