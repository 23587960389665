import { formatDollars } from 'utils'
import {
  ItemRowModifierGroupCheckBox,
  ItemRowStyled,
  PrintSeparator
} from './PrintOrder.styled'
import { BulkOrder } from 'types'

const ItemRow = ({
  name,
  quantitiy,
  price,
  total,
  isBold,
  borderHeight = '1px',
  isModifier,
  withUnderlineBorder = true,
  isReceipt,
  isHeader
}: {
  name: string
  quantitiy?: string
  price?: string
  total: string
  isBold?: boolean
  borderHeight?: string
  isModifier?: boolean
  withUnderlineBorder?: boolean
  isReceipt?: boolean
  isHeader?: boolean
}) => {
  return (
    <ItemRowStyled
      isBold={isBold}
      borderHeight={borderHeight}
      isModifier={isModifier}
      withUnderlineBorder={withUnderlineBorder}
      isReceipt={isReceipt}
      isHeader={isHeader}
    >
      <div>
        {isReceipt ? (
          <span />
        ) : isModifier ? (
          <ItemRowModifierGroupCheckBox />
        ) : (
          <span>{quantitiy}</span>
        )}
        <span> {name}</span>
      </div>
      <p>{price}</p>
      <p>{quantitiy}</p>
      <p>{total}</p>
    </ItemRowStyled>
  )
}

const PrintOrderItems = ({
  order,
  isReceipt
}: {
  order: BulkOrder
  isReceipt?: boolean
}) => {
  return (
    <>
      <ItemRow
        name="ITEMS"
        price={isReceipt ? '' : 'PRICE'}
        quantitiy=""
        total="TOTAL"
        isBold
        isHeader
        borderHeight="2px"
        isReceipt={isReceipt}
      />
      {order.items.map((item, index) => {
        return (
          <div key={index}>
            <ItemRow
              name={item.short_name}
              price={isReceipt ? '' : formatDollars(item.price)}
              quantitiy={item.quantity_total.toString()}
              total={formatDollars(item.price_total)}
              isBold
              withUnderlineBorder={!Boolean(item.modifier_groups?.length)}
              borderHeight={
                index + 1 === order.items.length ? '2px' : undefined
              }
              isReceipt={isReceipt}
            />
            {item.modifier_groups.map((g, i, a) =>
              g.modifiers.map((option, index, arr) => {
                const isLast = Boolean(
                  !item.notes &&
                    !item.made_for &&
                    a.length === i + 1 &&
                    arr.length === index + 1
                )

                return (
                  <div key={`${item.item_no}-${index}-${option.item_id}`}>
                    <ItemRow
                      name={option.short_name}
                      price={isReceipt ? '' : formatDollars(option.price)}
                      quantitiy={option.quantity_total.toString()}
                      total={formatDollars(option.price_total)}
                      isModifier
                      withUnderlineBorder={isLast}
                    />
                  </div>
                )
              })
            )}
            {item.made_for && (
              <>
                <span>Made for: {item.made_for}</span>
                <PrintSeparator />
              </>
            )}
            {item.notes && (
              <>
                <span>Notes: {item.notes}</span>
                <PrintSeparator />
              </>
            )}
          </div>
        )
      })}
      <ItemRow
        name="Cart Total"
        total={formatDollars(order.subtotal)}
        isBold
        borderHeight="4px"
      />
    </>
  )
}
export default PrintOrderItems
