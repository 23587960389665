import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Scanner } from 'types'
import { showNotification } from 'slices/notification'
import { fetchScanners } from 'slices/scanners'

export interface ScannerState {
  data: Scanner | null
  loading: RequestStatus
  error: RequestError
}

const initialState: ScannerState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type ScannerParams = {
  expand?: string
  with_related?: string
}

export const fetchScanner = createAsyncThunk<
  Scanner | null,
  { scannerId: string | number; params?: ScannerParams },
  { state: RootState; rejectValue: RequestError }
>(
  'scanner/fetchScanner',
  async ({ scannerId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/pos-scanners/${scannerId}${queryParams}`
      )) as Scanner
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createScanner = createAsyncThunk<
  Scanner,
  { data: Scanner },
  { state: RootState; rejectValue: RequestError }
>(
  'scanner/createScanner',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/pos-scanners',
        'POST',
        data
      )) as Scanner
      dispatch(showNotification('Scanner successfully created!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchScanners({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateScanner = createAsyncThunk<
  Scanner,
  { scannerId: string | number; data: Scanner },
  { state: RootState; rejectValue: RequestError }
>(
  'scanner/updateScanner',
  async ({ scannerId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/pos-scanners/${scannerId}`,
        'PUT',
        data
      )) as Scanner
      dispatch(showNotification('Scanner successfully updated!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchScanners({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteScanner = createAsyncThunk<
  void,
  Scanner,
  { state: RootState; rejectValue: RequestError }
>(
  'scanner/deleteScanner',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(`pos-v2/pos-scanners/${data.pos_scanner_id}`, 'DELETE')
      dispatch(showNotification('Scanner successfully deleted!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchScanners({ revenue_center_id }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    resetScanner: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchScanner.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchScanner.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchScanner.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createScanner.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createScanner.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createScanner.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateScanner.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateScanner.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateScanner.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteScanner.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteScanner.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteScanner.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetScanner } = scannerSlice.actions

export const selectScanner = (state: RootState) => state.scanner

export default scannerSlice.reducer
