import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { gql } from 'graphql-request'
import { QualityControlReportsResponse } from 'types'
import {
  RequestError,
  RequestStatus,
  QUALITY_CONTROL_REPORT_FRAGMENT
} from 'utils'

export interface OnboardingQualityControlReportsState {
  data: QualityControlReportsResponse | null
  loading: RequestStatus
  error: RequestError
}

export interface OnboardingQualityControlReportsAPI {
  qualityControlReports: QualityControlReportsResponse
}

const initialState: OnboardingQualityControlReportsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_QUALITY_CONTROL_REPORTS = gql`
  query QualityControlReports(
    $locationOrderingPlatformId: ID!
    $page: Int!
    $perPage: Int!
  ) {
    qualityControlReports(
      locationOrderingPlatformId: $locationOrderingPlatformId
      page: $page
      perPage: $perPage
    ) {
      page
      totalPageCount
      totalRecordsCount
      results {
        ...QualityControlReportFragment
      }
    }
  }
  ${QUALITY_CONTROL_REPORT_FRAGMENT}
`

export type OnboardingQualityControlReportsParams = {
  page: number
  perPage: number
  locationOrderingPlatformId: string
}

export const fetchOnboardingQualityControlReports = createAsyncThunk<
  OnboardingQualityControlReportsAPI,
  OnboardingQualityControlReportsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingQualityControlReports/fetchOnboardingQualityControlReports',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_QUALITY_CONTROL_REPORTS,
        params
      )) as OnboardingQualityControlReportsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingQualityControlReportsSlice = createSlice({
  name: 'onboardingQualityControlReports',
  initialState,
  reducers: {
    resetOnboardingQualityControlReports: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOnboardingQualityControlReports.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchOnboardingQualityControlReports.fulfilled,
      (state, { payload }) => {
        state.data = payload.qualityControlReports
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchOnboardingQualityControlReports.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetOnboardingQualityControlReports } =
  onboardingQualityControlReportsSlice.actions

export const selectOnboardingQualityControlReports = (state: RootState) =>
  state.onboardingQualityControlReports

export default onboardingQualityControlReportsSlice.reducer
