import { Customer } from './customer'
import { DiscountType, TenderType } from './global'
import { ServiceType } from './global'
import { OrderTypeOld, ChannelType, Files } from './global'

export interface DiscountItemCategory {
  category_id: number
  item_id: number
}

export interface DiscountItemModifier {
  modifier_group_id: number
  item_id: number
}

export function isDiscountItemCategory(item: {}): item is DiscountItemCategory {
  return 'category_id' in item
}

export function isDiscountItemModifier(item: {}): item is DiscountItemModifier {
  return 'modifier_group_id' in item
}

export type DiscountAuthType = ['ACCOUNT', 'VERIFIED']

export interface DiscountQrCode {
  code: string
  customer_id: string | null
  discount_id: number
  discount_qrcode_id: number
  is_active: boolean
  is_redeemed: boolean
  is_single_use: boolean
  qr_code_url: string
  updated_at: string
}

export interface DiscountCustomer {
  created_at: string
  customer: Customer
  is_redeemed: boolean
  updated_at: string
}

export interface Discount {
  allow_zero: boolean
  amount: string
  auth_type?: DiscountAuthType | null
  before_surcharge: boolean
  channel_type: ChannelType
  description: string
  discount_id?: number
  discount_type: DiscountType
  discounted_items?: DiscountItemCategory[] | DiscountItemModifier[] | null
  discounted_quantity?: number | null
  display_order: number
  domain: string
  emails?: DiscountEmails
  end_date?: string
  end_time?: string
  files?: Files
  entity_type?: string | null
  internal_desc: string
  is_active: boolean
  is_deal: boolean
  is_promo_code: boolean
  is_reusable_reward: boolean
  is_reward: boolean
  is_taxed: boolean
  max_amount: string
  max_order_size: string
  max_redemptions: number
  max_redemptions_per: number
  menu_id?: number | null
  min_amount: string
  min_order_size: string
  name: string
  order_type: OrderTypeOld
  per_order?: number | null
  point_shop_cost: string | null
  pos_ext_id: string
  required_items?: DiscountItemCategory[] | DiscountItemModifier[] | null
  required_quantity?: number | null
  requires_approval: boolean
  requires_email: boolean
  requires_new: boolean
  service_type: ServiceType
  start_date?: string
  start_time?: string
  tender_type?: TenderType | null
  title: string
  qrcodes?: DiscountQrCode[]
  customers?: DiscountCustomer[]
}

export type Discounts = Discount[]

export interface DiscountExisting extends Omit<Discount, 'discount_id'> {
  discount_id: number
}

export type DiscountsExisting = DiscountExisting[]

export interface DiscountRevenueCenter {
  revenue_center_id: number
}

export type DiscountRevenueCenters = DiscountRevenueCenter[]

export type DiscountEmail = {
  email: string
}

export type DiscountEmails = DiscountEmail[]
