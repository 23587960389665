import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'

export const NavSectionsView = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.layout.desktop.gap};
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${props => props.theme.layout.mobile.gap};
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const NavSection = styled.div`
  padding: 2rem 2rem;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  background-color: ${props => props.theme.colors.background.secondary};
`

export const NavSectionHeader = styled.div`
  padding: 0 0 1rem;
  border-style: solid;
  border-color: ${props => props.theme.colors.border.primary};
  border-width: 0;
  border-bottom-width: ${props => props.theme.layout.desktop.border};
  margin: 0 0 2rem;
`

export const NavSectionTitle = styled(TextHeading)``

export const NavSectionLinks = styled.ul``

export const NavSectionLinkView = styled.li`
  margin: 0 0 1rem;

  a {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    transition: all 0.3s ease;
    padding: 0.5rem 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.border.primary};
    border-width: 0;
    border-bottom-width: ${props => props.theme.layout.desktop.border};
  }

  a span {
    transition: all 0.3s ease;
  }

  a:hover {
    border-color: ${props => props.theme.colors.accent.tertiary};
  }

  a:hover span {
    color: ${props => props.theme.colors.accent.primary};
  }
`

export const NavSectionLinkContent = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`

export const NavSectionLinkIcon = styled.span`
  display: block;
  line-height: 0;
  margin-top: 0.1rem;
`

export const NavSectionLinkTitle = styled(TextBody)``
