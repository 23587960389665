import { Container, EntityNav, Page, PageContainer } from 'components'
import { SidebarLinkProps } from 'types'
import { EntityRoutes, EntityView } from './Entity.styled'

const Entity = ({
  crumbs,
  title,
  select,
  links,
  children,
  hideNav,
  hasChild = false
}: {
  crumbs: React.ReactNode
  title?: string
  select?: React.ReactNode
  links?: SidebarLinkProps[]
  children: JSX.Element
  hideNav?: boolean
  hasChild?: boolean
}) => {
  if (hasChild) return children
  return (
    <EntityView>
      {hideNav ? null : (
        <EntityNav title={title} links={links}>
          {select}
        </EntityNav>
      )}
      <EntityRoutes hasNav={!hideNav}>
        {crumbs}
        <Page>
          <Container>
            <PageContainer>{children}</PageContainer>
          </Container>
        </Page>
      </EntityRoutes>
    </EntityView>
  )
}

export default Entity
