import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Stylesheet, StylesheetType, Stylesheets } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface StylesheetsState {
  data: Stylesheets
  links: Record<string, string>
  latest: Stylesheet | null
  loading: RequestStatus
  error: RequestError
}

export interface StylesheetsAPI {
  data: Stylesheets
  links: {
    next: string
  }
}

export type StylesheetsParams = {
  cursor?: string
  limit?: number
  with_related?: string
  stylesheet_type?: StylesheetType
  is_active?: boolean
}

const defaultParams: StylesheetsParams = {
  stylesheet_type: 'FONTS',
  is_active: true,
  with_related: 'true'
}

const initialState: StylesheetsState = {
  data: [],
  links: {},
  latest: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStylesheets = createAsyncThunk<
  StylesheetsAPI,
  StylesheetsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'stylesheets/fetchStylesheets',
  async (params, { getState, rejectWithValue }) => {
    try {
      const stylesheetParams = params || defaultParams
      const queryParams = makeQueryParams(stylesheetParams)
      const endpoint = `stylesheets${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as StylesheetsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchStylesheetLatest = createAsyncThunk<
  Stylesheet,
  StylesheetsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'stylesheets/fetchStylesheetLatest',
  async (_, { getState, rejectWithValue }) => {
    try {
      const endpoint = `stylesheets/latest-fonts?with_related=true`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as Stylesheet
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const stylesheetsSlice = createSlice({
  name: 'stylesheets',
  initialState,
  reducers: {
    resetStylesheets: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStylesheets.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStylesheets.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchStylesheets.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchStylesheetLatest.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStylesheetLatest.fulfilled, (state, { payload }) => {
      state.latest = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchStylesheetLatest.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetStylesheets } = stylesheetsSlice.actions

export const selectStylesheets = (state: RootState) => state.stylesheets

export const selectStylesheetUrls = createSelector(
  (state: RootState) => {
    const { data } = state.stylesheets
    return data
  },
  data => {
    return data.reduce((urls, i) => {
      const fileUrls = i.files?.map(f => `https:${f.file_url}`) || []
      return [...urls, ...fileUrls]
    }, [] as string[])
  }
)

export const selectStylesheetLatest = (state: RootState) =>
  state.stylesheets.latest?.files?.find(i => i.file_type === 'CSS')?.file_url ||
  null

export default stylesheetsSlice.reducer
