import { css, Global, Theme, withTheme } from '@emotion/react'
import { ThemeProps } from './utils'

const makeGlobalStyles = (theme: Theme) => css`
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: sans-serif;
    min-height: 100%;
    @media (max-width: 1366px) {
      font-size: 62.5%;
    }
  }

  .ql-editor {
    background: ${theme.inputs.backgroundColor};
    border: 0.1rem solid ${theme.colors.border.primary};
    border-top: none;
    min-height: 250px;
    border-top: none;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  .ql-container.ql-snow {
    border: none !important;
  }

  .ql-snow .ql-picker {
    color: ${theme.colors.text.primary} !important;
  }

  .ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-item:hover,
  .ql-toolbar .ql-picker-label.ql-active {
    color: ${theme.colors.sidebar.hoverColor} !important;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: ${theme.inputs.backgroundColor};
    border: 0.1rem solid ${theme.colors.border.primary};
    border-radius: 0 0 0.8rem 0.8rem;
    margin-top: 8px !important;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border: 1px solid transparent !important;
  }

  .ql-editor > * {
    font-size: 14px;
    font-family: 'Figtree', sans-serif;
    font-weight: 500;
  }

  .ql-toolbar {
    font-family: 'Figtree', sans-serif !important;
    border: 0.1rem solid ${theme.colors.border.primary} !important;
    background: ${theme.colors.background.primary};
    padding: 0 !important;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    display: flex !important;
  }

  .ql-toolbar .ql-formats {
    display: flex !important;
    align-items: center;
  }

  .ql-stroke {
    stroke: ${theme.colors.text.primary} !important;
    stroke-width: 1px !important;
  }

  .ql-fill {
    fill: ${theme.colors.text.primary} !important;
  }

  .ql-toolbar button {
    width: 34px !important;
    padding: 0 !important;
    height: 28px !important;
    margin: 6px 1px 5px 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    float: none !important;
  }

  .ql-toolbar button svg {
    float: none !important;
    width: 24px !important;
    height: 24px !important;
  }

  .ql-toolbar button:hover {
    background: ${theme.colors.background.secondary};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul,
  legend {
    margin: 0;
    padding: 0;
  }

  fieldset {
    padding: 0;
    border: 0;
    margin: 0;
  }

  ol,
  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button {
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    padding: 0;
  }

  button:disabled {
    cursor: default;
  }

  button > * {
    pointer-events: none;
  }

  body {
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: ${theme.colors.text.primary};
    background: ${theme.colors.background.primary};
    font-size: ${theme.sizes.medium};
    font-family: 'Figtree', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  body.has-modal {
    overflow: hidden;
  }

  strong {
    letter-spacing: 0.01em;
    font-weight: 650;
    font-variation-settings: 'wght' 650;
  }

  a,
  button {
    outline: none;
    transition: all 0.4s ease;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Figtree', sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.text.primary};
  }

  p a {
    padding: 0.2rem 0 0.1rem;
    border-bottom: 0.05rem solid ${theme.colors.text.primary};

    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.accent.primary};
      border-color: ${theme.colors.accent.primary};
    }
  }

  pre {
    text-align: left;
    line-height: 1.75;
    white-space: pre-wrap;
    font-family: 'Neue Montreal Mono', monospace;
    font-weight: 450;
    font-size: 1.3rem;
    color: ${theme.colors.text.primary};
    background-color: ${theme.colors.background.secondary};
    padding: 1rem 1.5rem;
  }

  input[type='search'] {
    -webkit-appearance: none;

    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      display: none;
    }
  }

  input[type='submit'] {
    width: auto;
    cursor: pointer;
  }

  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(1rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .slide-up {
    opacity: 0;
    animation: slide-up 0.25s ease-in-out 0.125s forwards;
  }

  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(3rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeUp {
    opacity: 0;
    transform: translateY(3rem);
    animation-name: fadeUp;
    animation-fill-mode: both, both;
    // transition-timing-function: linear, ease-out;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1),
      cubic-bezier(0.19, 1, 0.22, 1);
    animation-duration: 500ms;
    animation-delay: 50ms;
  }

  // .fadeUp:nth-of-type(1) {
  //   animation-delay: 50ms;
  // }

  // .fadeUp:nth-of-type(2) {
  //   animation-delay: 150ms;
  // }

  // .fadeUp:nth-of-type(3) {
  //   animation-delay: 250ms;
  // }

  // .fadeUp:nth-of-type(4) {
  //   animation-delay: 350ms;
  // }

  // .fadeUp:nth-of-type(5) {
  //   animation-delay: 450ms;
  // }

  @keyframes fadeUpSlow {
    0% {
      opacity: 0;
      transform: translateY(10rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeUpSlow {
    animation-name: fadeUpSlow;
    animation-fill-mode: both, both;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1),
      cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-duration: 1s;
    animation-delay: 50ms;
  }

  @keyframes fadeInFromLeft {
    0% {
      transform: translateX(-40px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .fadeInFromLeft {
    transform: translateX(-40px);
    animation-name: fadeInFromLeft;
    animation-fill-mode: both, both;
    transition-timing-function: linear, ease-out;
    animation-duration: 250ms;
    animation-delay: 0ms;
  }

  @keyframes revealFromLeft {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  .revealFromLeft {
    transform-origin: left;
    animation: revealFromLeft 0.5s cubic-bezier(0.86, 0, 0.12, 1) 0s;
  }

  .revealUp {
    opacity: 0;
    transform: translate3D(0, 150%, 0);
    will-change: opacity, transform;
    transition:
      opacity 0.3s ease,
      transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.5s;

    &.-show {
      opacity: 1;
      transform: translate3D(0, 0, 0);
    }
  }

  .reveal-enter,
  .reveal-exit.reveal-exit-active {
    transition: all 250ms ease;
    opacity: 0;
    visibility: hidden;
  }

  .reveal-enter.reveal-enter-active,
  .reveal-exit {
    opacity: 1;
    visibility: visible;
  }

  .md-enter,
  .md-exit.md-exit-active {
    transition: all 0.25s ease;
    opacity: 0;
    visibility: hidden;

    & > div:last-of-type {
      transition: all 0.25s ease;
      transform: translateY(10%);
    }
  }

  .md-enter.md-enter-active,
  .md-exit {
    opacity: 1;
    visibility: visible;

    & > div:last-of-type {
      transform: translateY(0);
    }
  }

  .flash-enter {
    opacity: 0;
    transform: translateY(20%);
  }

  .flash-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  .flash-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .flash-exit-active {
    opacity: 0;
    transform: translateY(20%);
  }

  .slide-enter,
  .slide-exit.slide-exit-active {
    transition: all 250ms ease;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
  }

  .slide-enter.slide-enter-active,
  .slide-exit {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  .fade-up-enter {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
  }

  .fade-up-enter.fade-up-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 250ms ease;
  }

  .fade-up-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .fade-up-exit.fade-up-exit-active {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
    transition: all 250ms ease;
  }
`

const GlobalStyles = withTheme((props: ThemeProps) => {
  const { theme } = props
  return <Global styles={makeGlobalStyles(theme)} />
})

export default GlobalStyles
