import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { gql } from 'graphql-request'
import { showNotification } from 'slices/notification'
import { OnboardingOrderingPlatform } from 'types'
import {
  LOCATION_ORDERING_PLATFORM,
  ONBOARDING_COLLABORATOR_FRAGMENT,
  RequestError,
  RequestStatus
} from 'utils'

export interface OnboardingOrderingPlatformState {
  data: OnboardingOrderingPlatform | null
  loading: RequestStatus
  error: RequestError
}

export interface OnboardingOrderingPlatformAPI {
  locationOrderingPlatform: OnboardingOrderingPlatform
}

const initialState: OnboardingOrderingPlatformState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_LOCATION_ORDERING_PLATFORM = gql`
  query UseLocationOrderingPlatform($id: ID!) {
    locationOrderingPlatform(id: $id) {
      onboardingUsername
      onboardingUrl
      onboardingData
      ...LocationOrderingPlatformFragment
      statusVersions {
        id
        statusName
        statusCode
        completedAt
        completedBy {
          id
          email
          fullName
        }
      }
      location {
        brandName
        brandId
        address
        addressCity
        addressState
        creator {
          ...OnboardingCollaboratorFragment
        }
        locationLevelCollaborators {
          ...OnboardingCollaboratorFragment
        }
        liveOrderingPlatforms {
          id
          name
          displayName
        }
        inProgressOrderingPlatforms {
          id
          orderingPlatform {
            id
            name
            displayName
          }
          status
          qualityCheckComment
        }
        state {
          onboardingSpecialist {
            id
            fullName
          }
          goLiveSpecialist {
            id
            fullName
          }
        }
      }
    }
  }
  ${LOCATION_ORDERING_PLATFORM}
  ${ONBOARDING_COLLABORATOR_FRAGMENT}
`

const UPDATE_LOCATION_ORDERING_PLATFORM_STATUS = gql`
  mutation UpdateLocationOrderingPlatformStatus(
    $input: UpdateLocationOrderingPlatformStatusInput!
  ) {
    updateLocationOrderingPlatformStatus(input: $input) {
      id
      status
      onboardingStatus
      qualityCheckComment
      goLiveAt
      menuBuildingPendingReason
      statusVersions {
        id
        statusName
        statusCode
        completedAt
        completedBy {
          id
          email
          fullName
        }
      }
      orderingPlatform {
        id
        name
        displayName
      }
    }
  }
`

export type OnboardingOrderingPlatformParams = {
  id: string
}

export type UpdateOrderingPlatformParams = {
  id: string
  status: string
  qualityCheckComment: string
}

export const fetchOnboardingOrderingPlatform = createAsyncThunk<
  OnboardingOrderingPlatformAPI,
  OnboardingOrderingPlatformParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingOrderingPlatform/fetchOnboardingOrderingPlatform',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_LOCATION_ORDERING_PLATFORM,
        params
      )) as OnboardingOrderingPlatformAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateOnboardingOrderingPlatform = createAsyncThunk<
  OnboardingOrderingPlatformAPI,
  UpdateOrderingPlatformParams,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingOrderingPlatform/updateOnboardingOrderingPlatform',
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      const resp = (await api?.request(
        UPDATE_LOCATION_ORDERING_PLATFORM_STATUS,
        { input: params }
      )) as OnboardingOrderingPlatformAPI
      dispatch(showNotification('OP Status updated successfully!'))
      dispatch(fetchOnboardingOrderingPlatform({ id: params.id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingOrderingPlatformSlice = createSlice({
  name: 'onboardingOrderingPlatform',
  initialState,
  reducers: {
    resetOnboardingOrderingPlatform: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOnboardingOrderingPlatform.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchOnboardingOrderingPlatform.fulfilled,
      (state, { payload }) => {
        state.data = payload.locationOrderingPlatform
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchOnboardingOrderingPlatform.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateOnboardingOrderingPlatform.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateOnboardingOrderingPlatform.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      updateOnboardingOrderingPlatform.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetOnboardingOrderingPlatform } =
  onboardingOrderingPlatformSlice.actions

export const selectOnboardingOrderingPlatform = (state: RootState) =>
  state.onboardingOrderingPlatform

export default onboardingOrderingPlatformSlice.reducer
