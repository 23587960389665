import { useState } from 'react'
import { useAppDispatch } from 'app/hooks'
import { addPromoCode } from 'slices/order'
import { Input } from 'components/Forms'
import { ModalHeader } from 'components/Modal'
import { Button } from 'components'
import { closeModal } from 'slices/modal'

const OrderPromoCode = () => {
  const dispatch = useAppDispatch()
  const [promoCode, setPromoCode] = useState('')

  const add = () => {
    dispatch(addPromoCode(promoCode))
    dispatch(closeModal())
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(evt.target.value)
  }

  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') add()
  }

  return (
    <>
      <ModalHeader title="Enter promo code" />
      <Input
        type="text"
        name="search"
        label=""
        value={promoCode}
        onChange={onChange}
        onKeyDown={onKeyPress}
        placeholder="enter part of search"
      />
      <Button onClick={add}>Submit Promo Code</Button>
    </>
  )
}

export default OrderPromoCode
