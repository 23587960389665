import styled from '@emotion/styled'
import {
  CheckmateMenuItemSummary,
  CheckmateMenuModifiers
} from 'components/CheckmateMenu'
import { CheckmateItem as CheckmateItemType } from 'types'

const CheckmateItemModalView = styled.div``

const CheckmateItemModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${props => props.theme.layout.desktop.padding};
`

const CheckmateItemModalContent = styled.div`
  flex: 3;
`

const CheckmateItemModalImages = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

const CheckmateItemModalImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  background-color: ${props => props.theme.colors.background.secondary};

  img {
    display: block;
  }
`

const CheckmateItemModalModifierGroups = styled.div`
  margin: 2rem 0 0;
`

interface CheckmateItemModalProps {
  args: {
    item: CheckmateItemType
  }
}

const CheckmateItemModal = ({ args }: CheckmateItemModalProps) => {
  const { item } = args
  const imageUrls = item.image_urls?.map(i => i.link)
  return (
    <CheckmateItemModalView>
      <CheckmateItemModalContainer>
        <CheckmateItemModalImages>
          {imageUrls &&
            imageUrls.map(imageUrl => (
              <CheckmateItemModalImage key={imageUrl}>
                <img src={imageUrl} alt={item.name} />
              </CheckmateItemModalImage>
            ))}
        </CheckmateItemModalImages>
        <CheckmateItemModalContent>
          <CheckmateMenuItemSummary
            item={item}
            titleSize="xxlarge"
            subtitleSize="medium"
          />
          <CheckmateItemModalModifierGroups>
            <CheckmateMenuModifiers groups={item.modifier_groups} />
          </CheckmateItemModalModifierGroups>
        </CheckmateItemModalContent>
      </CheckmateItemModalContainer>
    </CheckmateItemModalView>
  )
}

export default CheckmateItemModal
