import styled from '@emotion/styled'
import { ThemeColor, ThemeFontSizes } from 'types'

interface TextHeadingProps {
  size?: keyof ThemeFontSizes
  color?: keyof ThemeColor
}

const TextHeading = styled.span<TextHeadingProps>`
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: ${props => props.theme.sizes[props.size || 'medium']};
  color: ${props => props.theme.colors.text[props.color || 'primary']};
  font-family: 'Figtree', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
`

export default TextHeading
