import styled from '@emotion/styled'
import { Trash2 } from 'react-feather'

const DeleteTextView = styled('span')`
  color: ${props => props.theme.colors.text.primary};
  padding-right: 0.8rem;
`

const DeleteIconView = styled('span')`
  color: ${props => props.theme.colors.text.primary};
  margin-top: 0.1rem;
`

const DeleteButton = styled.button`
  label: DeleteButton;
  position: absolute;
  z-index: 4;
  top: 1rem;
  right: 1rem;
  height: 3rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.background.primary};
  opacity: 0;
  visibility: hidden;
  border-radius: ${props => props.theme.borderRadius.small};
`

interface DeleteFileProps {
  deleteFile: (fileId: number) => void
  fileId: number
}

const DeleteFile = ({ deleteFile, fileId }: DeleteFileProps) => {
  const handleDeleteFile = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    deleteFile(fileId)
  }
  return (
    <DeleteButton onClick={handleDeleteFile}>
      <DeleteTextView>Delete</DeleteTextView>
      <DeleteIconView>
        <Trash2 size={14} />
      </DeleteIconView>
    </DeleteButton>
  )
}

export default DeleteFile
