import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { StoreMenus } from 'types'
import { showNotification } from 'slices/notification'

export interface StoreMenusState {
  data: StoreMenus
  loading: RequestStatus
  error: RequestError
}

export type StoreMenusParams = {
  expand?: string
}

export type StoreMenusPublishRevenueCenter = {
  revenue_center_id: number
}

const initialState: StoreMenusState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreMenus = createAsyncThunk<
  StoreMenus,
  { storeId: string | number; params?: StoreMenusParams },
  { state: RootState; rejectValue: RequestError }
>(
  'storeMenus/fetchStoreMenus',
  async ({ storeId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `stores/${storeId}/menus${queryParams}`
      const resp = (await api?.request(endpoint)) as StoreMenus
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreMenus = createAsyncThunk<
  StoreMenus,
  { storeId: string | number; data: StoreMenus },
  { state: RootState; rejectValue: RequestError }
>(
  'storeMenus/upsertStoreMenus',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/menus`
      const resp = (await api?.request(endpoint, 'PUT', data)) as StoreMenus
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreMenus = createAsyncThunk<
  StoreMenus,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeMenus/deleteStoreMenus',
  async (storeId, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/menus`
      const resp = (await api?.request(endpoint, 'PUT', [])) as StoreMenus
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const publishStoreMenus = createAsyncThunk<
  void,
  { storeId: number | string; revenueCenterIds: number[] },
  { state: RootState; rejectValue: RequestError }
>(
  'storeMenus/publishStoreMenus',
  async (
    { storeId, revenueCenterIds },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/publish`
      const data = revenueCenterIds.reduce((arr, id) => {
        return [...arr, { revenue_center_id: id }]
      }, [] as StoreMenusPublishRevenueCenter[])
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Menus published successfully!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeMenusSlice = createSlice({
  name: 'storeMenus',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreMenus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchStoreMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreMenus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertStoreMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreMenus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(deleteStoreMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(publishStoreMenus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(publishStoreMenus.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(publishStoreMenus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

// selectors
export const selectStoreMenus = (state: RootState) => state.storeMenus

export default storeMenusSlice.reducer
