import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { LoyaltyProgram } from 'types'
import { showNotification } from 'slices/notification'

export interface LoyaltyProgramState {
  data: LoyaltyProgram | null
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyProgramState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type LoyaltyProgramParams = {
  with_related?: string
}

export const fetchLoyaltyProgram = createAsyncThunk<
  LoyaltyProgram,
  { loyaltyProgramId: string | number; params?: LoyaltyProgramParams },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyProgram/fetchLoyaltyProgram',
  async ({ loyaltyProgramId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `loyalty-programs/${loyaltyProgramId}${queryParams}`
      const resp = (await api?.request(endpoint)) as LoyaltyProgram
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createLoyaltyProgram = createAsyncThunk<
  LoyaltyProgram,
  { data: LoyaltyProgram; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyProgram/createLoyaltyProgram',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'loyalty-programs',
        'POST',
        data
      )) as LoyaltyProgram
      dispatch(showNotification('Loyalty program successfully created!'))
      const pathname = `/loyalty/loyalty-programs/${resp.loyalty_program_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateLoyaltyProgram = createAsyncThunk<
  LoyaltyProgram,
  {
    loyaltyProgramId: string | number
    data: LoyaltyProgram
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyProgram/updateLoyaltyProgram',
  async (
    { loyaltyProgramId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-programs/${loyaltyProgramId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as LoyaltyProgram
      dispatch(showNotification('Loyalty program successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const addLoyaltyProgramCustomer = createAsyncThunk<
  void,
  { loyaltyProgramId: string | number; customerId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyProgram/addLoyaltyProgramCustomer',
  async (
    { loyaltyProgramId, customerId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-programs/${loyaltyProgramId}/customers`
      const data = [
        {
          customer_id: customerId
        }
      ]
      await api?.request(endpoint, 'POST', data)
      dispatch(showNotification('Customer added successfully!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const removeLoyaltyProgramCustomer = createAsyncThunk<
  void,
  { loyaltyProgramId: string | number; customerId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyProgram/removeLoyaltyProgramCustomer',
  async (
    { loyaltyProgramId, customerId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-programs/${loyaltyProgramId}/customers`
      const data = [
        {
          customer_id: customerId
        }
      ]
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Customer removed successfully!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteLoyaltyProgram = createAsyncThunk<
  void,
  {
    loyaltyProgramId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyProgram/deleteLoyaltyProgram',
  async (
    { loyaltyProgramId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-programs/${loyaltyProgramId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Loyalty program removed!'))
      navigate({ pathname: '/loyalty/loyalty-programs' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyProgramSlice = createSlice({
  name: 'loyaltyProgram',
  initialState,
  reducers: {
    resetLoyaltyProgram: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyProgram.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyProgram.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchLoyaltyProgram.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createLoyaltyProgram.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyProgram.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createLoyaltyProgram.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateLoyaltyProgram.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateLoyaltyProgram.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateLoyaltyProgram.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteLoyaltyProgram.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteLoyaltyProgram.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteLoyaltyProgram.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(addLoyaltyProgramCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addLoyaltyProgramCustomer.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      addLoyaltyProgramCustomer.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(removeLoyaltyProgramCustomer.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(removeLoyaltyProgramCustomer.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      removeLoyaltyProgramCustomer.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetLoyaltyProgram } = loyaltyProgramSlice.actions

export const selectLoyaltyProgram = (state: RootState) => state.loyaltyProgram

export default loyaltyProgramSlice.reducer
