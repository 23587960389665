import styled from '@emotion/styled'
import DesignInput from './DesignInput'
import DesignLabel from './DesignLabel'

// https://blog.logrocket.com/creating-custom-css-range-slider-javascript-upgrades/

const InputRangeInput = styled.input`
  flex: 0 0 10rem;
  width: 10rem;
  height: 0.1rem;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-color: ${props => props.theme.colors.border.primary};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.accent.secondary};
  }

  &::-moz-range-thumb {
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.accent.secondary};
  }
`

const InputRangeView = styled.div`
  position: relative;
  flex: 0 0 7.2rem;
  margin: 0 0 0 1rem;
`

const InputRange = ({
  label,
  required,
  name,
  value,
  setValue,
  min,
  max,
  step,
  disabled,
  suffix
}: {
  label: string
  name: string
  value: number
  setValue: (value: string | number) => void
  required?: boolean
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  suffix?: string
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(evt.target.value))
  }

  const setInputValue = (value: string | number | null) => {
    if (!value) return
    const val = parseFloat(value.toString())
    // const capped = max && val > max ? max : min && val < min ? min : val
    setValue(val)
  }

  return (
    <DesignLabel label={label} required={required} htmlFor={name}>
      <InputRangeInput
        type="range"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        tabIndex={-1}
      />
      <InputRangeView>
        <DesignInput
          type="number"
          name={name}
          value={value}
          setValue={setInputValue}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          suffix={suffix}
        />
      </InputRangeView>
    </DesignLabel>
  )
}

export default InputRange
