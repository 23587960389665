import styled from '@emotion/styled'
import { Edit } from 'iconoir-react'

export const ImageGridWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;

  grid-template-columns: repeat(auto-fit, 24rem);

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
`

export const ImageCardWrapper = styled.div<{
  selected: boolean
  isLoading?: boolean
}>`
  border: solid ${props => props.theme.colors.border.primary}
    ${props => props.theme.layout.desktop.border};
  border-color: ${props =>
    props.selected
      ? props.theme.colors.accent.primary
      : props.theme.colors.border.primary};
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
  cursor: ${props => (props.isLoading ? 'progress' : 'pointer')};
  border-radius: 1rem;
  transition: all 250ms ease;
  display: block;
  height: 30rem;
  width: 100%;
  position: relative;

  &:hover {
    border: solid ${props => props.theme.colors.accent.primary};
    border-bottom: solid ${props => props.theme.colors.accent.primary};

    div {
      display: flex;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
`

export const ImageCardFooter = styled.div`
  position: absolute;
  font-family: ${props => props.theme.inputs.fontFamily};
  bottom: 0;
  width: 100%;
  background-color: ${props => props.theme.colors.accent.primary};
  color: ${props => props.theme.colors.background.primary};
  padding: 1rem;
  display: none;
  justify-content: center;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`

export const ImageCardPreviewCanvas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.default.color};
  color: ${props => props.theme.colors.default.background};
  cursor: pointer;
  border-radius: 1rem;
  padding: 1.6rem;
  height: 100%;
`

export const ImageCardPreviewCanvasHeading = styled.h2`
  font-weight: bold;
  margin: 4px 0;
`

export const ImageCardPreviewCanvasText = styled.p`
  margin: 4px 0;
  color: ${props => props.theme.colors.text.secondary};
  font-size: ${props => props.theme.sizes.medium};
  font-weight: ${props => props.theme.inputs.fontWeight};
`
export const ImageCardPreviewCanvasEditIcon = styled(Edit)`
  font-size: 3rem;
`

export const ImageCardTemplateName = styled.h4`
  font-variation-settings: 'wght' 800;
`

export const ImageCardScratchCanvas = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
  cursor: ${props => (props.isLoading ? 'progress' : 'pointer')};
  background-color: ${props => props.theme.colors.default.color};
  color: ${props => props.theme.colors.default.background};
  border-radius: 1rem;
  padding: 1.6rem;
  height: 30rem;

  &:hover {
    border: solid ${props => props.theme.colors.accent.primary};
  }
`
