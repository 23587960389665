import { useEffect, useState } from 'react'
import { ModalWindow, OnboardingBrand } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal } from 'slices/modal'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { Button, ErrMsg } from 'components'
import {
  archiveOnboardingBrand,
  resetOnboardingBrand,
  selectOnboardingBrand
} from 'slices/iacm/onboardingBrand'
import { RequestStatus, capitalize, makeErrMsg } from 'utils'

const ArchiveBrand = ({
  args,
  modalWindow
}: {
  args: { onboardingBrand: OnboardingBrand; fetchBrands: () => void }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { onboardingBrand, fetchBrands } = args
  const { brandId, name, status } = onboardingBrand
  const isArchived = status === 'archived' ? true : false
  const { loading, error } = useAppSelector(selectOnboardingBrand)
  const isLoading = loading === RequestStatus.Pending
  const [submitting, setSubmitting] = useState(false)
  const errMsg = makeErrMsg(error)
  const verb = isArchived ? 'unarchive' : 'archive'
  const archive = () => {
    setSubmitting(true)
    dispatch(archiveOnboardingBrand({ id: brandId, archived: !isArchived }))
  }

  useEffect(() => {
    return () => {
      dispatch(resetOnboardingBrand())
    }
  }, [dispatch])

  useEffect(() => {
    if (submitting && !isLoading) {
      setSubmitting(false)
      if (!errMsg) {
        fetchBrands()
        dispatch(closeModal())
      }
    }
  }, [submitting, isLoading, errMsg, dispatch, fetchBrands])

  return (
    <>
      <ModalHeader title={name} />
      <ModalContent>
        {error && <ErrMsg errMsg={error.detail} />}
        <p>
          Are you sure you want to <strong>{verb}</strong> this brand?
        </p>
        {!isArchived && (
          <p>
            Archiving this brand will hide it from view in the Onboarding and
            Partner Portals. This should be done for brands that were mistakenly
            created, are duplicates or are no longer needed for other reasons.
            Please do not archive brands whose locations have churned.
          </p>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          color={isArchived ? 'primary' : 'delete'}
          onClick={archive}
          disabled={loading === RequestStatus.Pending}
        >
          {capitalize(verb)}
        </Button>
        <Button color="secondary" onClick={() => dispatch(closeModal())}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  )
}

export default ArchiveBrand
