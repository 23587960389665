import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { DesignLabel, InputRange } from '.'
import { makeThemePadding } from 'utils'
import TextBody from 'components/TextBody'

const InputPaddingView = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
  border-radius: ${props => props.theme.inputs.borderRadius};
  margin: 0 0 1rem;

  label + & {
    margin-top: 1rem;
  }
`

const InputPaddingValue = styled.span`
  display: block;
`

// interface InputPaddingValueProps {
//   paddingPx: string
// }

// const InputPaddingValue = styled.span<InputPaddingValueProps>`
//   display: block;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: ${props => props.paddingPx};
//   border-style: solid;
//   border-width: ${props => props.theme.inputs.borderWidth};
//   border-color: ${props => props.theme.inputs.borderColor};
//   border-radius: ${props => props.theme.inputs.borderRadius};
//   background-color: ${props => props.theme.colors.background.secondary};
//   background-color: ${props => props.theme.inputs.borderColor};

//   span {
//     display: block;
//     flex-grow: 1;
//     border-style: solid;
//     height: 20px;
//     border-width: ${props => props.theme.inputs.borderWidth};
//     border-color: ${props => props.theme.inputs.borderColor};
//     border-radius: ${props => props.theme.inputs.borderRadius};
//     background-color: ${props => props.theme.inputs.borderColor};
//     background-color: ${props => props.theme.colors.background.secondary};
//     // background-color: ${props => props.theme.colors.text.primary};
//   }
// `

interface InputPaddingProps {
  label: string
  id: string
  name: string
  value: number[]
  setValue: (value: number[]) => void
  required?: boolean
}

const InputPadding = ({
  label,
  id,
  name,
  value,
  setValue,
  required
}: InputPaddingProps) => {
  const [top, setTop] = useState<number | string>(value[0] ?? 0)
  const [right, setRight] = useState<number | string>(value[1] ?? 0)
  const [bottom, setBottom] = useState<number | string>(value[2] ?? 0)
  const [left, setLeft] = useState<number | string>(value[3] ?? 0)
  const paddingPx = makeThemePadding([top, right, bottom, left])

  useEffect(() => {
    setValue([
      parseInt(top.toString()),
      parseInt(right.toString()),
      parseInt(bottom.toString()),
      parseInt(left.toString())
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top, right, bottom, left])

  return (
    <InputPaddingView>
      <DesignLabel label={label} required={required}>
        {/* <InputPaddingValue paddingPx={paddingPx}>
          <span></span>
        </InputPaddingValue> */}
        <InputPaddingValue>
          <TextBody size="xsmall" color="tertiary">
            {paddingPx}
          </TextBody>
        </InputPaddingValue>
      </DesignLabel>
      <InputRange
        label="Top"
        name="paddingTop"
        value={parseInt(top.toString())}
        setValue={setTop}
        min={0}
        max={100}
        step={1}
        suffix="PX"
      />
      <InputRange
        label="Right"
        name="paddingRight"
        value={parseInt(right.toString())}
        setValue={setRight}
        min={0}
        max={100}
        step={1}
        suffix="PX"
      />
      <InputRange
        label="Bottom"
        name="paddingBottom"
        value={parseInt(bottom.toString())}
        setValue={setBottom}
        min={0}
        max={100}
        step={1}
        suffix="PX"
      />
      <InputRange
        label="Left"
        name="paddingLeft"
        value={parseInt(left.toString())}
        setValue={setLeft}
        min={0}
        max={100}
        step={1}
        suffix="PX"
      />
    </InputPaddingView>
  )
}

export default InputPadding
