import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalContent } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { selectOrder } from 'slices/order'
import Loader from 'components/Loader'
import { useNavigate } from 'react-router-dom'
import { showNotification } from 'slices/notification'
import { makeErrMsg } from 'utils'

const OrderEditing = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { submitting, error, isCollect } = useAppSelector(selectOrder)
  const errMsg = makeErrMsg(error)
  const path = isCollect ? '/orders/order/checkout' : '/orders/order'

  useEffect(() => {
    if (!submitting) {
      dispatch(closeModal())
      navigate(path)
    } else if (errMsg) {
      dispatch(closeModal())
      dispatch(showNotification(errMsg))
    }
  }, [dispatch, navigate, submitting, errMsg, path])

  return (
    <>
      <ModalContent>
        <Loader text="Building your order. Please sit tight..." />
      </ModalContent>
    </>
  )
}

export default OrderEditing
