import styled from '@emotion/styled'
import Transition from './Transition'

export const ErrMsgView = styled.span`
  display: block;
  width: 100%;
  margin: 1.5rem 0;
  padding: ${props => props.theme.messages.padding};
  border-radius: ${props => props.theme.messages.borderRadius};
  line-height: ${props => props.theme.messages.lineHeight};
  color: ${props => props.theme.colors.error.color};
  background-color: ${props => props.theme.colors.error.background};
  border-color: ${props => props.theme.colors.error.border};
`

const ErrMsg = ({
  errMsg,
  style
}: {
  errMsg?: string | null
  style?: React.CSSProperties
}) => {
  return (
    <Transition
      showTransition={!!errMsg}
      classNames="reveal"
      transitionElements={[
        <ErrMsgView style={style}>
          <p>{errMsg}</p>
        </ErrMsgView>
      ]}
    />
  )
}

export default ErrMsg
