import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Region } from 'types'
import { showNotification } from 'slices/notification'
import { fetchRegions } from './regions'

export interface RegionState {
  data: Region | null
  loading: RequestStatus
  error: RequestError
}

const initialState: RegionState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchRegion = createAsyncThunk<
  Region,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('regions/fetchRegion', async (regionId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `regions/${regionId}`
    const resp = (await api?.request(endpoint)) as Region
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createRegion = createAsyncThunk<
  Region,
  { data: Region; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'regions/createRegion',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request('regions', 'POST', data)) as Region
      dispatch(showNotification('Region successfully created!'))
      const pathname = `/stores/regions/${resp.region_id}`
      dispatch(fetchRegions())
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateRegion = createAsyncThunk<
  Region,
  {
    regionId: string | number
    data: Region
  },
  { state: RootState; rejectValue: RequestError }
>(
  'regions/updateRegion',
  async ({ regionId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `regions/${regionId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Region
      dispatch(showNotification('Region successfully updated!'))
      dispatch(fetchRegions())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    resetRegion: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRegion.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchRegion.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchRegion.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createRegion.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createRegion.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createRegion.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateRegion.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateRegion.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateRegion.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetRegion } = regionSlice.actions

export const selectRegion = (state: RootState) => state.region

export default regionSlice.reducer
