import { format, parseISO } from 'date-fns'
import { isoToDateStr, weekdaysUpper } from '@open-tender/utils'
import { RequestedAt, Timezone } from '@open-tender/types'
import {
  BulkOrder,
  ReceiptStatus,
  RevenueCenter,
  ServiceType,
  TenderStatus,
  Tender,
  SimplifiedModifier,
  RefundableModifier
} from 'types'
import { replaceAmPm } from './datetimes'
import { cardTypeMap } from 'config'

export const getOrderStatusColor = (receiptStatus?: ReceiptStatus | null) => {
  switch (receiptStatus) {
    case 'OPEN':
      return 'primary'
    case 'PENDING':
      return 'info'
    case 'CLOSED':
      return 'success'
    case 'VOID':
      return 'error'
    case 'HELD':
      return 'default'
    default:
      return 'default'
  }
}

export const getTenderStatusColor = (tenderStatus?: TenderStatus | null) => {
  switch (tenderStatus) {
    case 'AUTHORIZED':
      return 'primary'
    case 'PAID':
      return 'success'
    case 'VOID':
      return 'error'
    case 'REFUNDED':
      return 'error'
    default:
      return 'default'
  }
}

export const makeTimeRange = (
  revenueCenter?: RevenueCenter,
  serviceType?: ServiceType
) => {
  if (!revenueCenter || !serviceType) return 0
  const { delivery_time_range, pickup_time_range } = revenueCenter
  return serviceType === 'DELIVERY' ? delivery_time_range : pickup_time_range
}

export const makeCustomer = (order: BulkOrder) => {
  const { customer, customer_address } = order
  if (!customer) return '--'
  const name = `${customer.first_name} ${customer.last_name}`
  const { company } = customer_address || {}
  if (!company) return name
  return `${company} (${name})`
}

export const formatRequestedAt = (
  requestedAt: RequestedAt | null,
  tz: Timezone | null,
  fmt = 'MMM d @ h:mma'
) => {
  return requestedAt === 'asap'
    ? 'ASAP'
    : requestedAt && tz
      ? replaceAmPm(isoToDateStr(requestedAt, tz, fmt))
      : null
}

export const requestedAtToDate = (requestedAt: RequestedAt | null) => {
  if (!requestedAt || requestedAt === 'asap') return null
  return parseISO(requestedAt)
}

export const isDate = (requestedAt: RequestedAt | null) => {
  return Object.prototype.toString.call(requestedAt) === '[object Date]'
}

export const makeClosedWeekdays = ({
  weekdayTimes
}: Record<string, number[]>) => {
  return Object.entries(weekdayTimes)
    .filter(([, times]) => !times)
    .map(([weekday]) => weekdaysUpper.indexOf(weekday))
}

// requestedDate must be submitted as a date object

export const makeDatepickerArgs = (
  requestedDate: any,
  weekdayTimes: any,
  excludedTimes: any
) => {
  const weekday = format(requestedDate, 'EEEE').toUpperCase()
  const dateStr = format(requestedDate, 'yyyy-MM-dd')
  // weekdayExcluded = times excluded based on regular hours + blocked hours
  const weekdayExcluded = weekdayTimes[weekday] || []
  // otherExcluded = times excluded due to holiday hours + throttled times
  const otherExcluded = excludedTimes[dateStr] || []
  const allExcluded = Array.from(
    new Set([...weekdayExcluded, ...otherExcluded])
  ).sort()
  // convert excluded minutes to excluded dates
  const excludeTimes = allExcluded.map(minutes => {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60
    const d = new Date()
    d.setHours(hours)
    d.setMinutes(mins)
    return d
  })
  // filter out days of the week that are always closed
  const closedWeekdays = makeClosedWeekdays(weekdayTimes)
  const isClosed = (date: Date) => {
    return !closedWeekdays.includes(date.getDay())
  }
  return { excludeTimes, isClosed }
}

export const makeTenderName = (tender: Tender) => {
  const { tender_type, card_type, last4, house_account_id, gift_card_id } =
    tender
  switch (tender_type) {
    case 'CREDIT':
      return `${cardTypeMap[card_type]} ending in ${last4}`
    case 'HOUSE_ACCOUNT':
      return `House Account ${house_account_id}`
    case 'GIFT_CARD':
      return `Gift Card ${gift_card_id}`
    case 'CASH':
      return 'Cash'
    default:
      return `${tender_type}`
  }
}

export const getModifierTotalAmount = (
  modifier: SimplifiedModifier | RefundableModifier
) => {
  return (
    modifier.groups?.reduce((total, group) => {
      const subModifiersAmount: number = group.modifiers.reduce((t, m) => {
        return t + parseFloat(m.price) + getModifierTotalAmount(m)
      }, 0)
      return total + subModifiersAmount
    }, 0) || 0
  )
}
