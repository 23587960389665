import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'
import { StorePos, StoreSettingsFrom } from 'types'

export interface StorePosState {
  data: StorePos | null
  from: StoreSettingsFrom | null
  loading: RequestStatus
  error: RequestError | null
}

export interface StorePosApi {
  data: StorePos
  from: StoreSettingsFrom
}

const initialState: StorePosState = {
  data: null,
  from: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStorePos = createAsyncThunk<
  StorePosApi | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('storePos/fetchStorePos', async (storeId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const resp = (await api?.request(
      `stores/${storeId}/settings/external-pos`
    )) as StorePosApi
    return resp || null
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const upsertStorePos = createAsyncThunk<
  StorePosApi,
  { storeId: string | number; data: StorePos },
  { state: RootState; rejectValue: RequestError }
>(
  'storePos/upsertStorePos',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `stores/${storeId}/settings/external-pos`,
        'PUT',
        data
      )) as StorePosApi
      dispatch(showNotification('Updated successfully!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStorePos = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storePos/deleteStorePos',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request<void>(
        `stores/${storeId}/settings/external-pos`,
        'DELETE'
      )
      dispatch(showNotification('Successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storePosSlice = createSlice({
  name: 'storePos',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStorePos.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStorePos.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload?.data || null
      state.from = payload?.from || null
      state.error = null
    })
    builder.addCase(fetchStorePos.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStorePos.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStorePos.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload.data
      state.from = payload.from
      state.error = null
    })
    builder.addCase(upsertStorePos.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStorePos.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStorePos.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.from = null
      state.error = null
    })
    builder.addCase(deleteStorePos.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStorePos = (state: RootState) => state.storePos

export default storePosSlice.reducer
