import styled from '@emotion/styled'
import { ModalWindow } from 'types'
import { ModalHeader } from 'components/Modal'
import PageColumns from 'components/PageColumns'
import TextHeading from 'components/TextHeading'

const ApiDetailView = styled.div``

const ApiDetailHeader = styled.div``

const ApiDetailJson = styled.pre`
  padding: 1.5rem;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  background-color: ${props => props.theme.colors.background.secondary};
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.sizes.xsmall};
`

const ApiDetail = ({
  args,
  modalWindow
}: {
  args: {
    request: {}
    response: {}
    createdAt: string
  }
  modalWindow: ModalWindow
}) => {
  const { request, response, createdAt } = args
  const requestJSON = JSON.stringify(request, null, 2)
  const responseJSON = JSON.stringify(response, null, 2)

  return (
    <>
      <ModalHeader title="API Request Details" subtitle={`Sent ${createdAt}`} />
      <PageColumns>
        <ApiDetailView>
          <ApiDetailHeader>
            <TextHeading size="large">Request Body</TextHeading>
          </ApiDetailHeader>
          <ApiDetailJson>{requestJSON}</ApiDetailJson>
        </ApiDetailView>
        <ApiDetailView>
          <ApiDetailHeader>
            <TextHeading size="large">Response Body</TextHeading>
          </ApiDetailHeader>
          <ApiDetailJson>{responseJSON}</ApiDetailJson>
        </ApiDetailView>
      </PageColumns>
    </>
  )
}

export default ApiDetail
