import { useEffect, useState } from 'react'
import { ModalWindow } from 'types'
import { ModalHeader } from 'components/Modal'
import { ButtonSubmit, ErrMsg, Input } from 'components'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { editOrder, fetchOrderMgmt, selectOrderMgmt } from 'slices/orderMgmt'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'

export interface AdjustTipArgs {
  orderId: number
  tip: string
  storeId: string
  // adjust: (tip: string) => void
}

const AdjustTip = ({
  args,
  modalWindow
}: {
  args: AdjustTipArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectOrderMgmt)
  const { orderId, tip, storeId } = args
  const [submitted, setSubmitted] = useState(false)
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const [amount, setAmount] = useState<string>(tip || '')
  const preface = orderId ? `Order #${orderId}` : null
  const isLoading = loading === RequestStatus.Pending

  const submit = () => {
    setSubmitted(true)
    setErrMsg(null)
    const newTip = Number(amount).toFixed(2)
    dispatch(editOrder({ orderId, data: { tip: newTip } }))
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(evt.target.value)
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (error) {
        setErrMsg(error.detail)
      } else {
        dispatch(fetchOrderMgmt({ store_id: storeId }))
        dispatch(closeModal())
      }
    }
  }, [dispatch, submitted, isLoading, error, storeId])

  return (
    <>
      <ModalHeader
        preface={preface}
        title="Adjust tip amount"
        subtitle="Enter a dollar amount or 0.00"
      />
      <ErrMsg errMsg={errMsg} />
      <Input
        name="amount"
        type="number"
        value={amount}
        label="Enter Tip Amount"
        required={true}
        onChange={onChange}
        min={0}
      />
      <ButtonSubmit onClick={submit} disabled={isLoading}>
        {isLoading ? 'Submitting...' : 'Submit'}
      </ButtonSubmit>
    </>
  )
}

export default AdjustTip
