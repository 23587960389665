import { useContext } from 'react'
import BeeEditorContext from './BeeEditorContext'
import { BeeEditorView } from './BeeEditor.styled'

export const BeeEditor = () => {
  const { editorRef } = useContext(BeeEditorContext)

  return <BeeEditorView ref={editorRef} />
}

export default BeeEditor
