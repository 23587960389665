import { Form } from 'components'
import { CampaignContentEmailMetadata, FormFieldType, ModalWindow } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

const SavedEmailTemplateSettings = ({
  args: { value, onSubmit },
  modalWindow
}: {
  args: {
    value: CampaignContentEmailMetadata
    onSubmit: (value: CampaignContentEmailMetadata) => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()

  const submit = (value: CampaignContentEmailMetadata) => {
    onSubmit(value)
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Edit Template Details" />
      <Form
        fields={campaignContentWebFields}
        data={value}
        isLoading={false}
        submit={submit}
        submitText="Save"
      />
    </>
  )
}

const campaignContentWebFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'subject',
    label: 'Subject',
    parenthetical: 'Max length 190 characters'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'preview_text',
    label: 'Preview Text',
    parenthetical: 'Max length 130 characters'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'sender_name',
    label: 'Sender Name',
    parenthetical: 'Max length 40 characters'
  },
  {
    fieldType: 'Input',
    type: 'email',
    name: 'sender_email',
    label: 'Sender Email'
  },
  {
    fieldType: 'Input',
    type: 'email',
    name: 'reply_to',
    label: 'Reply to Email'
  }
]

export default SavedEmailTemplateSettings
