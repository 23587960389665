import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Segment, SegmentRecipients } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface SegmentRecipientsState {
  data: SegmentRecipients
  loading: RequestStatus
  error: RequestError | null
}

const initialState: SegmentRecipientsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchSegmentRecipients = createAsyncThunk<
  SegmentRecipients,
  Pick<Segment, 'marketing_segment_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'segments/getSegmentRecipients',
  async ({ marketing_segment_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `segments/${marketing_segment_id}/validate`
      const resp = (await api?.request(endpoint)) as SegmentRecipients
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const testSegment = createAsyncThunk<
  SegmentRecipients,
  { segment: Segment },
  { state: RootState; rejectValue: RequestError }
>('segment/testSegment', async ({ segment }, { getState, rejectWithValue }) => {
  try {
    const endpoint = 'segments/validate-criteria'
    const api = getState().authUser.api
    const result = (await api?.request(endpoint, 'POST', {
      criteria: segment.criteria || null
    })) as SegmentRecipients
    return result
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const segmentRecipientsSlice = createSlice({
  name: 'segmentRecipients',
  initialState,
  reducers: {
    resetSegmentRecipients: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSegmentRecipients.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(fetchSegmentRecipients.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
      })
      .addCase(fetchSegmentRecipients.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Failed
        state.error = payload
      })
      .addCase(testSegment.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(testSegment.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
      })
      .addCase(testSegment.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      })
  }
})

export const { resetSegmentRecipients } = segmentRecipientsSlice.actions

export const selectSegmentRecipients = (state: RootState) =>
  state.segmentRecipients

export default segmentRecipientsSlice.reducer
