import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { StoreAddress } from 'types'
import { showNotification } from 'slices/notification'

export interface StoreAddressApi {
  data: StoreAddress
  from: {
    store_id: number
  }
}

export interface StoreAddressState {
  data: StoreAddress | null
  loading: RequestStatus
  error: RequestError
}

const initialState: StoreAddressState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreAddress = createAsyncThunk<
  StoreAddress | null,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeAddress/fetchStoreAddress',
  async (storeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/settings/address`
      const resp = (await api?.request(endpoint)) as StoreAddressApi
      return resp ? resp.data : null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upsertStoreAddress = createAsyncThunk<
  StoreAddress,
  { storeId: string | number; data: StoreAddress },
  { state: RootState; rejectValue: RequestError }
>(
  'storeAddress/upsertStoreAddress',
  async ({ storeId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/settings/address`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as StoreAddressApi
      dispatch(showNotification('Updated successfully!'))
      return resp.data
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteStoreAddress = createAsyncThunk<
  void,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'storeAddress/deleteStoreAddress',
  async (storeId, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `stores/${storeId}/settings/address`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Store address removed'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeAddressSlice = createSlice({
  name: 'storeAddress',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoreAddress.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchStoreAddress.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchStoreAddress.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(upsertStoreAddress.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(upsertStoreAddress.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(upsertStoreAddress.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteStoreAddress.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteStoreAddress.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteStoreAddress.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectStoreAddress = (state: RootState) => state.storeAddress

export default storeAddressSlice.reducer
