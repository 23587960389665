import { FirstTime, RequestedAt, RevenueCenter } from '@open-tender/types'
import { useAppDispatch } from 'app/hooks'
import { ModalWindow, OrderType, ServiceType } from 'types'
import { ModalHeader } from 'components/Modal'
import { RequestedAtDateTime } from 'components'
import { closeModal } from 'slices/modal'

export interface OrderRequestedAtArgs {
  chooseTime: (requestedAt: RequestedAt) => void
  firstTime: FirstTime
  orderType: OrderType
  requestedAt: RequestedAt
  revenueCenter: RevenueCenter
  serviceType: ServiceType
}

const OrderRequestedAt = ({
  args,
  modalWindow
}: {
  args: OrderRequestedAtArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const {
    chooseTime,
    firstTime,
    orderType,
    requestedAt,
    revenueCenter,
    serviceType
  } = args

  const cancel = () => dispatch(closeModal())

  return (
    <>
      <ModalHeader
        title="Requested order time"
        subtitle="Please choose a date and time below."
      />
      {firstTime ? (
        <RequestedAtDateTime
          firstTime={firstTime}
          revenueCenter={revenueCenter}
          serviceType={serviceType}
          orderType={orderType}
          requestedAt={requestedAt}
          chooseTime={chooseTime}
          cancel={cancel}
        />
      ) : null}
    </>
  )
}

export default OrderRequestedAt
