import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ItemTypes } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface ItemTypesState {
  data: ItemTypes
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface ItemTypesAPI {
  data: ItemTypes
  links: {
    next: string
  }
}

export type ItemTypesParams = {
  cursor?: string
  limit?: number
  with_related?: string
}

const initialState: ItemTypesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItemTypes = createAsyncThunk<
  ItemTypesAPI,
  ItemTypesParams | void,
  { state: RootState; rejectValue: RequestError }
>('itemTypes/fetchItemTypes', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `item-types${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as ItemTypesAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const itemTypesSlice = createSlice({
  name: 'itemTypes',
  initialState,
  reducers: {
    resetItemTypes: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemTypes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemTypes.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchItemTypes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItemTypes } = itemTypesSlice.actions

export const selectItemTypes = (state: RootState) => state.itemTypes

export default itemTypesSlice.reducer
