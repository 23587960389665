import TextBody from 'components/TextBody'
import { CheckmateSpecialHours } from 'types'
import {
  CheckmateMenuHoursWeekday,
  CheckmateMenuInfoSectionHeader,
  CheckmateSpecialHoursView
} from './CheckmateMenu.styled'
import { time24ToHumanTime } from 'utils'
import TextHeading from 'components/TextHeading'

const CheckmateMenuSpecialHours = ({
  specialHours
}: {
  specialHours: CheckmateSpecialHours
}) => {
  if (!specialHours || !specialHours.length) return null
  return (
    <CheckmateSpecialHoursView>
      <CheckmateMenuInfoSectionHeader>
        <TextHeading size="large">Special / Holiday Hours</TextHeading>
      </CheckmateMenuInfoSectionHeader>
      {specialHours.map(i => {
        const hours = i.closed
          ? 'Closed'
          : `${time24ToHumanTime(i.start_time)}-${time24ToHumanTime(
              i.end_time
            )}`
        return (
          <CheckmateMenuHoursWeekday key={i.date}>
            <TextBody size="small">{i.date}</TextBody>
            <TextBody size="small">{hours}</TextBody>
          </CheckmateMenuHoursWeekday>
        )
      })}
    </CheckmateSpecialHoursView>
  )
}

export default CheckmateMenuSpecialHours
