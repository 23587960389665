import styled from '@emotion/styled'

export const IPhoneChatTopSection = styled.div`
  background-color: ${props => props.theme.colors.background.primary};
  height: 9.3rem;
  margin: 0;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
`

export const IPhoneChatTopSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 2.6rem 0;
`

export const IPhoneChatActionsView = styled.div`
  margin-top: 2rem;
  display: flex;
  padding: 0 1.6rem;
  justify-content: space-between;
  align-items: center;
  color: #3478f6;
`

export const IPhoneChatTime = styled.strong`
  margin-left: 0.5rem;
  font-size: 1.2rem;
`

export const IPhoneChatNetworkIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
`

export const IPhoneChatUserIcon = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: lightgrey;
`

export const IPhoneChatUserName = styled.div`
  text-align: center;
  padding-top: 0.6rem;
  color: ${props => props.theme.colors.text.primary};
  font-size: 1.2rem;
`

export const IPhoneChatMessagesContainer = styled.div`
  height: 100%;
  background: ${props => props.theme.colors.background.primary};
  overflow: auto;
  padding: 3rem 2rem;
`

export const IPhoneChatMessage = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  font-size: ${props => props.theme.sizes.medium};
  border-radius: ${props => props.theme.borderRadius.large};
  padding: 1rem 1.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
`
