import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { LoyaltyBonus } from 'types'
import { showNotification } from 'slices/notification'

export interface LoyaltyBonusState {
  data: LoyaltyBonus | null
  loading: RequestStatus
  error: RequestError
}

const initialState: LoyaltyBonusState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type LoyaltyBonusParams = {
  expand?: string
  with_related?: string
}

export const fetchLoyaltyBonus = createAsyncThunk<
  LoyaltyBonus | null,
  { loyaltyBonusId: string | number; params?: LoyaltyBonusParams },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyBonus/fetchLoyaltyBonus',
  async ({ loyaltyBonusId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `loyalty-bonus/${loyaltyBonusId}${queryParams}`
      const resp = (await api?.request(endpoint)) as LoyaltyBonus
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createLoyaltyBonus = createAsyncThunk<
  LoyaltyBonus,
  {
    data: LoyaltyBonus
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyBonus/createLoyaltyBonus',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'loyalty-bonus',
        'POST',
        data
      )) as LoyaltyBonus
      dispatch(showNotification('Loyalty bonus successfully created!'))
      const pathname = `/loyalty/loyalty-programs/${data.loyalty_program_id}/bonus-periods/${resp.loyalty_bonus_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateLoyaltyBonus = createAsyncThunk<
  LoyaltyBonus,
  {
    loyaltyBonusId: string | number
    data: LoyaltyBonus
  },
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyBonus/updateLoyaltyBonus',
  async ({ loyaltyBonusId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `loyalty-bonus/${loyaltyBonusId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as LoyaltyBonus
      dispatch(showNotification('Loyalty bonus successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyBonusSlice = createSlice({
  name: 'loyaltyBonus',
  initialState,
  reducers: {
    resetLoyaltyBonus: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyBonus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createLoyaltyBonus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateLoyaltyBonus.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateLoyaltyBonus.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateLoyaltyBonus.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyBonus } = loyaltyBonusSlice.actions

export const selectLoyaltyBonus = (state: RootState) => state.loyaltyBonus

export default loyaltyBonusSlice.reducer
