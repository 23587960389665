import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'
import { CheckmateModifierGroups } from 'types'
import {
  CheckmateMenuModifiersGroup,
  CheckmateMenuModifiersGroupEmpty,
  CheckmateMenuModifiersGroupHeader,
  CheckmateMenuModifiersGroupModifier,
  CheckmateMenuModifiersGroupModifiers,
  CheckmateMenuModifiersGroupTitle,
  CheckmateMenuModifiersView
} from './CheckmateMenu.styled'
import CheckmateMenuModifier from './CheckmateMenuModifier'

interface CheckmateMenuModifiersProps {
  groups: CheckmateModifierGroups
}

const CheckmateMenuModifiers = ({ groups }: CheckmateMenuModifiersProps) => {
  if (!groups.length) return null

  return (
    <CheckmateMenuModifiersView>
      {groups.map(group => {
        const {
          id,
          name,
          description,
          modifiers,
          maximum_amount,
          minimum_amount
        } = group
        return (
          <CheckmateMenuModifiersGroup key={id}>
            <CheckmateMenuModifiersGroupHeader>
              <CheckmateMenuModifiersGroupTitle>
                <TextHeading size="medium">{name}</TextHeading>
                <TextBody size="small" color="secondary">
                  (min: {minimum_amount}, max: {maximum_amount})
                </TextBody>
              </CheckmateMenuModifiersGroupTitle>
              {!!description && (
                <TextBody
                  as="p"
                  size="small"
                  color="secondary"
                  style={{ margin: '1rem 0 0' }}
                >
                  {description}
                </TextBody>
              )}
            </CheckmateMenuModifiersGroupHeader>
            {modifiers.length ? (
              <CheckmateMenuModifiersGroupModifiers>
                {modifiers.map(modifier => (
                  <CheckmateMenuModifiersGroupModifier key={modifier.id}>
                    <CheckmateMenuModifier modifier={modifier} />
                  </CheckmateMenuModifiersGroupModifier>
                ))}
              </CheckmateMenuModifiersGroupModifiers>
            ) : (
              <CheckmateMenuModifiersGroupEmpty as="p" size="xsmall">
                Empty modifier group
              </CheckmateMenuModifiersGroupEmpty>
            )}
          </CheckmateMenuModifiersGroup>
        )
      })}
    </CheckmateMenuModifiersView>
  )
}

export default CheckmateMenuModifiers
