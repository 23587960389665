import { Button, Checkbox, ErrMsg, Input, Loader, TextBody } from 'components'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { IconoirProvider, Minus, Plus } from 'iconoir-react'
import {
  BulkOrder,
  OrderRemainingRefund,
  OrderRemainingSurcharge,
  OrderRemainingTender,
  RefundableItem,
  RefundableItems,
  RefundableModifier,
  RefundGiftCards,
  RefundItem,
  RefundItems
} from 'types'
import {
  RefundButtons,
  RefundHeader,
  RefundHeaderConfirm,
  RefundHeaders,
  RefundHeadersConfirm,
  RefundInfo,
  RefundInfos,
  RefundTableButtons,
  RefundTableCell,
  RefundTableCellConfirm,
  RefundTableConfirm,
  RefundTableModifiers,
  RefundTableRow,
  RefundTableTotals,
  RefundView
} from './RefundCard.styled'
import {
  applyPartialRefund,
  calculatePartialRefund,
  resetPartialRefund,
  selectPartialRefund
} from 'slices/partialRefund'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getModifierTotalAmount, RequestError, RequestStatus } from 'utils'
import { useNavigate } from 'react-router-dom'
import { TableCheckbox } from 'components/Table/Table.styled'
import { formatDollars, makeTenderName } from 'utils'
import RefundModifierWrapper from './RefundModifierWrapper'

const RefundCard = ({
  orderDetail,
  headers,
  items,
  orderId,
  loading,
  error
}: {
  orderDetail: BulkOrder | null
  headers: string[]
  items: RefundableItems | null
  orderId: string
  loading: boolean
  error: RequestError | null
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [refundItems, setRefundItems] = useState<RefundItems>([])
  const [refundGiftCards, setRefundGiftCards] = useState<RefundGiftCards>([])
  const [refundSurcharges, setRefundSurcharges] = useState<
    OrderRemainingSurcharge[]
  >([])
  const [selectedRefundTenders, setRefundTenders] = useState<
    OrderRemainingTender[]
  >([])
  const [refundTip, setRefundTip] = useState<number>(0)
  const [refundConfirmation, setRefundConfirmation] = useState<boolean>(false)
  const {
    data: partialRefund,
    loading: refundLoading,
    error: refundError
  } = useAppSelector(selectPartialRefund)
  const isLoading = loading || refundLoading === RequestStatus.Pending
  const surcharges = orderDetail?.surcharges
  const tip = parseFloat(orderDetail?.tip || '0')
  const giftCards = orderDetail?.gift_cards || []

  useEffect(() => {
    dispatch(resetPartialRefund())
    setRefundConfirmation(false)
  }, [dispatch, orderDetail])

  const itemsTotal = useMemo(() => {
    if (!items) return 0
    return items.reduce((total, item) => {
      const unitPrice = item.quantity
        ? parseFloat(item.price_total) / item.quantity
        : 0.0

      const modifiersTotal = item.modifiers.reduce((total, modifier) => {
        const modifierTotalPrice = modifier.groups
          ? getModifierTotalAmount(modifier) + parseFloat(modifier.price)
          : parseFloat(modifier.price_total) !== 0
            ? parseFloat(modifier.price)
            : 0

        const modifierTotal = modifier.quantity
          ? modifierTotalPrice * modifier.quantity
          : 0.0

        return total + modifierTotal
      }, 0)

      return total + unitPrice * item.quantity + modifiersTotal
    }, 0)
  }, [items])

  const getModifiers = useCallback((modifiers: RefundableModifier[]) => {
    return modifiers.reduce((allModifiers, modifier) => {
      allModifiers.push(modifier)
      if (modifier.groups && modifier.groups.length > 0) {
        modifier.groups.forEach(group => {
          allModifiers = allModifiers.concat(getModifiers(group.modifiers))
        })
      }

      return allModifiers
    }, [] as RefundableModifier[])
  }, [])

  const itemsRefund = useMemo(() => {
    if (!items) return 0
    return items.reduce((total, item) => {
      const unitPrice = item.quantity
        ? parseFloat(item.price_total) / item.quantity
        : 0.0

      const modifierTotal = item.modifiers.reduce((total, modifier) => {
        const mUnitPrice = modifier.groups
          ? getModifierTotalAmount(modifier) + parseFloat(modifier.price)
          : modifier.quantity
            ? parseFloat(modifier.price_total) / modifier.quantity
            : 0.0
        const matchingModifier = refundItems.find(
          i => i.line_no === modifier.line_no
        )
        return (
          total +
          (matchingModifier ? mUnitPrice * matchingModifier.quantity : 0)
        )
      }, 0)

      const matchingItem = refundItems.find(i => i.line_no === item.line_no)
      const matchingModifier = item.modifiers.find(i =>
        refundItems.some(ri => ri.line_no === i.line_no)
      )
      if (!matchingItem && !matchingModifier) return total

      return (
        total +
        (matchingItem ? unitPrice * matchingItem.quantity : 0) +
        modifierTotal
      )
    }, 0)
  }, [items, refundItems])

  const totalRefundBeforeTax = useMemo(() => {
    const giftCardRefund = (refundGiftCards || []).reduce((total, giftCard) => {
      return total + parseFloat(giftCard.amount)
    }, 0)

    const surchargeRefund = (refundSurcharges || []).reduce(
      (total, surcharge) => {
        return (
          total +
          parseFloat(
            surcharges?.find(s => s.surcharge_id === surcharge.surcharge_id)
              ?.amount || '0'
          )
        )
      },
      0
    )

    return itemsRefund + giftCardRefund + surchargeRefund + refundTip
  }, [itemsRefund, refundGiftCards, refundSurcharges, surcharges, refundTip])

  const increaseQty = (item: RefundableItem | RefundableModifier) => {
    const existingItemIndex = refundItems.findIndex(
      ri => ri.line_no === item.line_no
    )

    if (existingItemIndex !== -1) {
      const updatedRefundItems = [...refundItems]
      updatedRefundItems[existingItemIndex].quantity++
      setRefundItems(updatedRefundItems)
    } else {
      const refundItem = {
        line_no: item.line_no,
        quantity: 1,
        void: false
      }
      setRefundItems([...refundItems, refundItem])
    }
  }

  const decreaseQty = (item: RefundableItem | RefundableModifier) => {
    const existingItemIndex = refundItems.findIndex(
      ri => ri.line_no === item.line_no
    )

    if (existingItemIndex !== -1) {
      const updatedRefundItems = [...refundItems]
      if (updatedRefundItems[existingItemIndex].quantity > 1) {
        updatedRefundItems[existingItemIndex].quantity--
        setRefundItems(updatedRefundItems)
      } else {
        updatedRefundItems.splice(existingItemIndex, 1)
        setRefundItems(updatedRefundItems)
      }
    }
  }

  const handleSurcharge =
    (surcharge_id: number, amount: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const surcharge = { amount, surcharge_id }
        setRefundSurcharges([...refundSurcharges, surcharge])
      } else {
        const updatedSurcharges = refundSurcharges.filter(
          surcharge => surcharge.surcharge_id !== surcharge_id
        )
        setRefundSurcharges(updatedSurcharges)
      }
    }

  const handleGiftCard =
    (gift_card_id: number, amount: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const giftCard = { amount, gift_card_id }
        setRefundGiftCards([...refundGiftCards, giftCard])
      } else {
        const updatedGiftCards = refundGiftCards.filter(
          card => card.gift_card_id !== gift_card_id
        )
        setRefundGiftCards(updatedGiftCards)
      }
    }

  const getQuantity = (lineNo: number) => {
    const matchingItem = refundItems.find(ri => ri.line_no === lineNo)
    return matchingItem ? matchingItem.quantity : 0
  }

  const getAmount = (lineNo: number): number => {
    if (!items) return 0
    const refundItem = refundItems.find(i => i.line_no === lineNo)

    if (!refundItem || refundItem.quantity === 0) {
      return 0
    }

    const item = items.find(i => i.line_no === lineNo)

    if (!item || item.quantity === 0) {
      return 0
    }

    const totalAmount = parseFloat(item.price) * refundItem.quantity
    return totalAmount
  }

  const getModifierAmount = (lineNo: number, itemId: number): number => {
    if (!items) return 0
    const refundItem = refundItems.find(ri => ri.line_no === lineNo)

    if (!refundItem || refundItem.quantity === 0) {
      return 0
    }

    const matchingItem = items.find(item => item.item_id === itemId)

    if (!matchingItem) {
      return 0
    }
    const matchingModifier = matchingItem.modifiers.find(
      item => item.line_no === lineNo
    )

    if (!matchingModifier) {
      return 0
    }

    const modifierAmount = matchingModifier.groups
      ? getModifierTotalAmount(matchingModifier)
      : parseFloat(matchingModifier.price)

    const totalAmount = modifierAmount * refundItem.quantity
    return totalAmount
  }

  const onTipChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const maxTip = tip
    const newTip = parseFloat(evt.target.value)
    setRefundTip(Math.min(newTip, maxTip))
  }

  const handleTenderChange =
    (tender: OrderRemainingTender) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const maxAmount = parseFloat(tender.amount)
      const newAmount = parseFloat(evt.target.value)
      setRefundTenders([
        ...selectedRefundTenders.filter(t => t.tender_id !== tender.tender_id),
        {
          ...tender,
          amount: Math.min(newAmount, maxAmount).toString()
        } as OrderRemainingTender
      ])
    }

  const resetRefund = () => {
    setRefundItems([])
    setRefundGiftCards([])
    setRefundSurcharges([])
    setRefundTip(0)
  }

  const resetRefundItems = () => {
    setRefundItems([])
  }

  const addAllItemsToRefund = (includeTip: boolean) => {
    const refundItems = items?.flatMap(item => {
      const itemWithModifiers = [
        { line_no: item.line_no, quantity: item.quantity, void: false },
        ...item.modifiers.map(modifier => ({
          line_no: modifier.line_no,
          quantity: modifier.quantity,
          void: false
        }))
      ]

      return itemWithModifiers
    })
    if (refundItems) setRefundItems(refundItems)

    if (!includeTip) return

    setRefundTip(tip)

    const refundGiftCards = giftCards?.map(giftCard => ({
      gift_card_id: giftCard.gift_card_id,
      amount: giftCard.amount,
      void: false
    }))

    if (refundGiftCards) setRefundGiftCards(refundGiftCards)

    const refundSurcharges = surcharges?.map(surcharge => ({
      surcharge_id: surcharge.surcharge_id,
      amount: surcharge.amount,
      void: false
    }))

    if (refundSurcharges) setRefundSurcharges(refundSurcharges)
  }

  const getRefundItemsSubModifiers = (refundItem: RefundItem): RefundItems => {
    const itemModifiers = items?.flatMap(i => i.modifiers)
    const matchingModifier = itemModifiers?.find(
      mod => mod.line_no === refundItem.line_no
    )
    if (matchingModifier && matchingModifier.groups) {
      return matchingModifier.groups.flatMap(g => {
        const subModifiers = getModifiers(g.modifiers).map(m => ({
          line_no: m.line_no,
          quantity: refundItem.quantity,
          void: false
        }))
        return subModifiers
      })
    } else {
      return []
    }
  }

  const calculateRefund = () => {
    const additionalRefundItems = refundItems.flatMap(
      getRefundItemsSubModifiers
    )
    const partialRefundReq: OrderRemainingRefund = {
      gift_cards: refundGiftCards,
      items: refundItems.concat(additionalRefundItems),
      surcharges: refundSurcharges,
      tip: refundTip.toString()
    }
    dispatch(calculatePartialRefund({ orderId, data: partialRefundReq }))
  }

  const totalRefundWithTax = parseFloat(partialRefund?.total || '0')

  const amountRemaining =
    parseFloat(orderDetail?.total || '0') + totalRefundWithTax

  const tenders = orderDetail?.tenders || []
  const tendersDisabled = tenders.length === 1
  const refundTenders = tendersDisabled
    ? [
        {
          tender_id: tenders[0].tender_id,
          amount: Math.min(
            parseFloat(tenders[0].amount),
            -parseFloat(partialRefund?.total || '0')
          ).toString()
        }
      ]
    : selectedRefundTenders

  const tendersTotal =
    tenders.reduce((total, tender) => total + parseFloat(tender.amount), 0) || 0

  const refundsTotal = refundTenders.reduce(
    (total, tender) => total + parseFloat(tender.amount),
    0
  )

  const amountLeftToRefund =
    parseFloat(partialRefund?.total || '0') + refundsTotal

  const applyDisabled = amountLeftToRefund !== 0

  useEffect(() => {
    if (!partialRefund) return
    setRefundConfirmation(true)
  }, [partialRefund])

  const applyRefund = () => {
    if (!orderDetail || !orderDetail.tenders) return
    const additionalRefundItems = refundItems.flatMap(
      getRefundItemsSubModifiers
    )
    const applyRefundReq: OrderRemainingRefund = {
      gift_cards: refundGiftCards,
      items: refundItems.concat(additionalRefundItems),
      surcharges: refundSurcharges.map(t => ({ surcharge_id: t.surcharge_id })),
      tenders: refundTenders,
      tip: refundTip.toString()
    }
    dispatch(applyPartialRefund({ orderId, data: applyRefundReq, navigate }))
    setRefundConfirmation(false)
  }

  const confirmationHeaders = [
    'Tender',
    'Amount',
    'Amount Refunded',
    'Amount Left'
  ]

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ErrMsg errMsg={error?.detail || refundError?.detail} />
          <RefundView>
            {!refundConfirmation ? (
              <>
                <RefundHeaders>
                  {headers.map(header => (
                    <RefundHeader key={header}>{header}</RefundHeader>
                  ))}
                </RefundHeaders>
                {items?.map(item => (
                  <React.Fragment key={`item-${item.item_id}`}>
                    <RefundTableRow>
                      <RefundTableCell>{item.name}</RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(
                          item.quantity
                            ? parseFloat(item.price_total) / item.quantity
                            : 0
                        )}
                      </RefundTableCell>
                      <RefundTableCell>{item.quantity}</RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(item.price_total)}
                      </RefundTableCell>
                      <RefundTableCell>
                        <IconoirProvider
                          iconProps={{
                            width: '2rem',
                            height: '2rem',
                            strokeWidth: 1.5
                          }}
                        >
                          <Button
                            onClick={() => decreaseQty(item)}
                            disabled={getQuantity(item.line_no) === 0}
                          >
                            <Minus />
                          </Button>
                          <Input
                            id={`refund_amount_${item.line_no}`}
                            type="number"
                            name="refund_amount"
                            value={getQuantity(item.line_no)}
                            readOnly
                          />
                          <Button
                            onClick={() => increaseQty(item)}
                            disabled={
                              getQuantity(item.line_no) === item.quantity
                            }
                          >
                            <Plus />
                          </Button>
                        </IconoirProvider>
                      </RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(-getAmount(item.line_no))}
                      </RefundTableCell>
                    </RefundTableRow>
                    {item.modifiers.length ? (
                      <RefundTableModifiers>
                        {item.modifiers.map(i => (
                          <RefundModifierWrapper
                            key={i.name}
                            modifier={i}
                            item={item}
                            level={1}
                            increaseQty={increaseQty}
                            decreaseQty={decreaseQty}
                            getQuantity={getQuantity}
                            getModifierAmount={getModifierAmount}
                          />
                        ))}
                      </RefundTableModifiers>
                    ) : null}
                  </React.Fragment>
                )) || null}
                <RefundTableTotals>
                  <RefundTableCell>Items Total</RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell>{formatDollars(itemsTotal)}</RefundTableCell>
                  <RefundTableCell>
                    <RefundTableButtons>
                      <Button
                        onClick={resetRefundItems}
                        size="small"
                        color="secondary"
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={() => addAllItemsToRefund(false)}
                        size="small"
                        color="secondary"
                      >
                        Select All
                      </Button>
                    </RefundTableButtons>
                  </RefundTableCell>
                  <RefundTableCell>
                    {formatDollars(-itemsRefund)}
                  </RefundTableCell>
                </RefundTableTotals>
                {giftCards?.map(g => (
                  <React.Fragment key={`gift-card-${g.gift_card_id}`}>
                    <RefundTableRow>
                      <RefundTableCell>{g.card_number}</RefundTableCell>
                      <RefundTableCell></RefundTableCell>
                      <RefundTableCell></RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(g.amount)}
                      </RefundTableCell>
                      <RefundTableCell>
                        <TableCheckbox>
                          <Checkbox
                            name={`${g.gift_card_id}`}
                            checked={refundGiftCards.some(
                              card => card.gift_card_id === g.gift_card_id
                            )}
                            onChange={handleGiftCard(g.gift_card_id, g.amount)}
                            disabled={false}
                          />
                        </TableCheckbox>
                      </RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(
                          refundGiftCards.find(
                            r => r.gift_card_id === g.gift_card_id
                          )
                            ? -g.amount
                            : 0
                        )}
                      </RefundTableCell>
                    </RefundTableRow>
                  </React.Fragment>
                )) || null}
                {surcharges?.map(s => (
                  <React.Fragment key={`surcharge-${s.surcharge_id}`}>
                    <RefundTableRow>
                      <RefundTableCell>{s.name}</RefundTableCell>
                      <RefundTableCell></RefundTableCell>
                      <RefundTableCell></RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(s?.amount || 0)}
                      </RefundTableCell>
                      <RefundTableCell>
                        <TableCheckbox>
                          <Checkbox
                            name={`surcharge-${s.surcharge_id}`}
                            checked={refundSurcharges.some(
                              r => r.surcharge_id === s.surcharge_id
                            )}
                            onChange={handleSurcharge(s.surcharge_id, s.amount)}
                            disabled={false}
                          />
                        </TableCheckbox>
                      </RefundTableCell>
                      <RefundTableCell>
                        {formatDollars(
                          refundSurcharges.find(
                            r => r.surcharge_id === s.surcharge_id
                          )
                            ? -s.amount
                            : 0
                        )}
                      </RefundTableCell>
                    </RefundTableRow>
                  </React.Fragment>
                )) || null}
                <RefundInfo>
                  <RefundTableCell>Tip</RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell>{formatDollars(tip)}</RefundTableCell>
                  <RefundTableCell>
                    <Input
                      type="number"
                      min={0}
                      max={tip}
                      name="tip"
                      step={'any'}
                      placeholder="0.00"
                      value={refundTip || ''}
                      onChange={onTipChange}
                    />
                  </RefundTableCell>
                  <RefundTableCell>{formatDollars(-refundTip)}</RefundTableCell>
                </RefundInfo>
                <RefundTableTotals>
                  <RefundTableCell>Total Refund before Tax</RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell></RefundTableCell>
                  <RefundTableCell>
                    <RefundTableButtons>
                      <Button
                        onClick={() => addAllItemsToRefund(true)}
                        size="small"
                        color="secondary"
                      >
                        Refund In Full
                      </Button>
                    </RefundTableButtons>
                  </RefundTableCell>
                  <RefundTableCell>
                    {formatDollars(-totalRefundBeforeTax)}
                  </RefundTableCell>
                </RefundTableTotals>
                <RefundButtons>
                  <Button
                    color="primary"
                    onClick={() => calculateRefund()}
                    disabled={totalRefundBeforeTax === 0}
                  >
                    Calculate Refund
                  </Button>
                  <Button
                    color="secondary"
                    onClick={resetRefund}
                    disabled={totalRefundBeforeTax === 0}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => navigate(`/orders/${orderId}`)}
                  >
                    Cancel
                  </Button>
                </RefundButtons>
              </>
            ) : (
              <>
                <RefundInfos>
                  <RefundInfo>
                    <TextBody>Total Refund With Tax</TextBody>
                    <TextBody>{formatDollars(totalRefundWithTax)}</TextBody>
                  </RefundInfo>
                  <RefundInfo>
                    <TextBody>Amount Remaining on Check</TextBody>
                    <TextBody>{formatDollars(amountRemaining)}</TextBody>
                  </RefundInfo>
                  {tendersDisabled && (
                    <RefundInfo>
                      <TextBody size="small" variant="error">
                        Please note that refund amounts cannot be adjusted when
                        there's only 1 tender.
                      </TextBody>
                    </RefundInfo>
                  )}
                </RefundInfos>
                <RefundHeadersConfirm>
                  {confirmationHeaders.map(header => (
                    <RefundHeaderConfirm key={header}>
                      {header}
                    </RefundHeaderConfirm>
                  ))}
                </RefundHeadersConfirm>
                {tenders?.map(t => (
                  <RefundTableConfirm key={t.tender_id}>
                    <RefundTableCellConfirm>
                      {makeTenderName(t)}
                    </RefundTableCellConfirm>
                    <RefundTableCellConfirm>
                      {formatDollars(t.amount)}
                    </RefundTableCellConfirm>
                    <RefundTableCellConfirm>
                      <Input
                        type="number"
                        min={0}
                        max={t.amount}
                        name={`tender-${t.tender_id}`}
                        step={'any'}
                        placeholder="0.00"
                        value={
                          refundTenders.find(r => r.tender_id === t.tender_id)
                            ?.amount || ''
                        }
                        onChange={handleTenderChange(t)}
                        disabled={tendersDisabled}
                      />
                    </RefundTableCellConfirm>
                    <RefundTableCellConfirm>
                      {formatDollars(
                        Math.min(
                          parseFloat(
                            refundTenders.find(r => r.tender_id === t.tender_id)
                              ?.amount || '0'
                          ) - parseFloat(t.amount),
                          0
                        )
                      )}
                    </RefundTableCellConfirm>
                  </RefundTableConfirm>
                )) || null}
                <RefundTableConfirm>
                  <RefundTableCellConfirm>Totals</RefundTableCellConfirm>
                  <RefundTableCellConfirm>
                    {formatDollars(tendersTotal)}
                  </RefundTableCellConfirm>
                  <RefundTableCellConfirm>
                    {formatDollars(-refundsTotal)}
                  </RefundTableCellConfirm>
                  <RefundTableCellConfirm>
                    {formatDollars(tendersTotal - refundsTotal)}
                  </RefundTableCellConfirm>
                </RefundTableConfirm>
                <RefundTableConfirm>
                  <RefundTableCellConfirm>
                    Amount Left to Refund
                  </RefundTableCellConfirm>
                  <RefundTableCellConfirm></RefundTableCellConfirm>
                  <RefundTableCellConfirm></RefundTableCellConfirm>
                  <RefundTableCellConfirm>
                    {formatDollars(amountLeftToRefund)}
                  </RefundTableCellConfirm>
                </RefundTableConfirm>
                <RefundButtons>
                  <Button
                    color="primary"
                    onClick={() => applyRefund()}
                    disabled={applyDisabled || isLoading}
                  >
                    Apply Refund
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setRefundConfirmation(false)}
                    disabled={isLoading}
                  >
                    Go Back
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => navigate(`/orders/${orderId}`)}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                </RefundButtons>
              </>
            )}
          </RefundView>
        </>
      )}
    </>
  )
}

export default RefundCard
