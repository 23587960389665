import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { Button, ErrMsg, Loader } from 'components'
import { closeModal } from 'slices/modal'
import { useNavigate } from 'react-router-dom'
import { deleteBrand, selectBrand } from 'slices/brand'
import { RequestStatus } from 'utils'

const DeleteBrand = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data, loading, error } = useAppSelector(selectBrand)

  const handleDeleteBrand = () => {
    data?.brand_id &&
      dispatch(deleteBrand({ brandId: data.brand_id, navigate }))
  }

  const isLoading = loading === RequestStatus.Pending

  return (
    <>
      <ModalHeader title={`Delete Brand ${data?.name}`} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ModalContent>
            {error && <ErrMsg errMsg={error.detail} />}
            <p>
              Are you sure you want to delete this brand? This will delete the
              brand forever. There's no going back.
            </p>
          </ModalContent>
          <ModalFooter>
            <Button color="delete" onClick={handleDeleteBrand}>
              Delete Brand
            </Button>
            <Button color="secondary" onClick={() => dispatch(closeModal())}>
              Nevermind
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  )
}

export default DeleteBrand
