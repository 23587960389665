import { FormFieldType, Region } from 'types'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Form, PageContent, PageHeader } from 'components'
import { useLoading } from 'hooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { omit, RequestStatus } from 'utils'
import {
  createRegion,
  fetchRegion,
  selectRegion,
  updateRegion
} from 'slices/region'

const RegionSettings = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { regionId } = useParams()
  const { data, loading, error } = useAppSelector(selectRegion)

  const hasError = error ? true : false
  const isLoading = loading === RequestStatus.Pending
  const pageLoading = useLoading(regionId, isLoading)

  const formData =
    regionId !== 'new' && data?.region_id
      ? omit<Region, 'region_id'>(data, 'region_id')
      : regionEmpty

  useEffect(() => {
    return () => {
      if (hasError && regionId) {
        dispatch(fetchRegion(regionId))
      }
    }
  }, [hasError, regionId, dispatch])

  const submit = (values: Region) => {
    if (regionId === 'new') {
      dispatch(createRegion({ data: values, navigate }))
    } else if (regionId) {
      dispatch(updateRegion({ regionId, data: values }))
    }
  }

  return (
    <>
      <PageHeader title={data?.name || 'New Region'} />
      <PageContent
        subtitle="Use the form below to update this region"
        loading={pageLoading}
      >
        <Form
          fields={RegionFields()}
          data={formData}
          error={error}
          isLoading={isLoading}
          submit={submit}
        ></Form>
      </PageContent>
    </>
  )
}

export default RegionSettings

const regionEmpty = {
  name: '',
  is_active: false
}

const RegionFields = (): FormFieldType[] => [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'name',
    required: true,
    label: 'Name',
    error: 'Please enter a name'
  },
  {
    fieldType: 'Checkbox',
    label: 'Active',
    name: 'is_active'
  }
]
