import styled from '@emotion/styled'
import DesignInput from './DesignInput'
import DesignLabel from './DesignLabel'

const InputColorView = styled.span`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex: 0 0 ${props => props.theme.layout.desktop.inputWidth};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex: 0 0 ${props => props.theme.layout.mobile.inputWidth};
  }

  input[type='text'] {
    flex: 1 1 auto;
    padding-left: 4.8rem;
  }
`

const InputColorWrapper = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  aspect-ratio: 1;
  overflow: hidden;
  border-top-left-radius: ${props => props.theme.inputs.borderRadius};
  border-bottom-left-radius: ${props => props.theme.inputs.borderRadius};
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
`

const InputColorHash = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3.6rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.sizes.small};
  opacity: 0.5;
`

const InputColorInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
`

const InputColorNew = ({
  label,
  name,
  value,
  setValue,
  required,
  inherited
}: {
  label: string
  name: string
  value: string
  setValue: (value: string | number | null) => void
  required?: boolean
  inherited?: boolean
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value)
  }

  return (
    <DesignLabel
      label={label}
      required={required}
      htmlFor={name}
      inherited={inherited}
    >
      <InputColorView>
        <InputColorWrapper>
          <InputColorInput type="color" value={value} onChange={onChange} />
        </InputColorWrapper>
        <InputColorHash>#</InputColorHash>
        <DesignInput
          type="text"
          name={name}
          value={value ? value.replace('#', '') : ''}
          setValue={val => setValue(val ? `#${val}` : val)}
          inherited={inherited}
        />
      </InputColorView>
    </DesignLabel>
  )
}

export default InputColorNew
