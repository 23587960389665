import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import TextBody from 'components/TextBody'
import { DesignLabel, InputCheckbox, InputColor, InputRange } from '.'

const InputBoxShadowView = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
  border-radius: ${props => props.theme.inputs.borderRadius};
  margin: 1rem 0;
`

const InputBoxShadowValue = styled.span`
  display: block;
`

const componentToHex = (c: number) => {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

const rgbToHex = (r: number, g: number, b: number) => {
  return componentToHex(r) + componentToHex(g) + componentToHex(b)
}

const parseBoxShadow = (value: string) => {
  if (value === 'none' || !value.includes('rgba')) return 'none'
  const [params, rgba] = value.replace(')', '').split(' rgba(')
  const [offsetX, offsetY, blurRadius, spreadRadius] = params
    .split(' ')
    .map(i => parseInt(i.replace('px', '')))
  const [r, g, b, o] = rgba.split(',')
  const color = rgbToHex(parseInt(r), parseInt(g), parseInt(b))
  const opacity = parseFloat(o)
  return { offsetX, offsetY, blurRadius, spreadRadius, color, opacity }
}

const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

const hexToRgba = (hex: string, opacity: number) => {
  const rgb = hexToRgb(hex)
  if (!rgb) return `rgba(0, 0, 0, ${opacity.toFixed(2)})`
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity.toFixed(2)})`
}

const makeBoxShadowCss = (
  offsetX: number,
  offsetY: number,
  blurRadius: number,
  spreadRadius: number,
  hex: string,
  opacity: number
) => {
  const rgba = hexToRgba(hex, opacity)
  return `${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px ${rgba}`
}

interface InputBoxShadowProps {
  label: string
  id: string
  name: string
  value: string
  setValue: (value: string) => void
  required?: boolean
}

const InputBoxShadow = ({
  label,
  id,
  name,
  value,
  setValue,
  required
}: InputBoxShadowProps) => {
  const [checked, setChecked] = useState(false)
  const [offsetX, setOffsetX] = useState<string | number>(0)
  const [offsetY, setOffsetY] = useState<string | number>(0)
  const [blurRadius, setBlurRadius] = useState<string | number>(0)
  const [spreadRadius, setSpreadRadius] = useState<string | number | undefined>(
    0
  )
  const [color, setColor] = useState<string | number | null>('000000')
  const [opacity, setOpacity] = useState<string | number>(0.0)

  const sanitizeOpacity = (value: string | number) => {
    const rounded = parseFloat(value.toString()).toFixed(2)
    setOpacity(rounded)
  }

  useEffect(() => {
    const parsed = parseBoxShadow(value)
    if (parsed === 'none') {
      setChecked(true)
    } else {
      setOffsetX(parsed.offsetX)
      setOffsetY(parsed.offsetY)
      setBlurRadius(parsed.blurRadius)
      setSpreadRadius(parsed.spreadRadius)
      setColor(parsed.color)
      setOpacity(parsed.opacity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (checked) {
      setValue('none')
    } else {
      const boxShadowCss = makeBoxShadowCss(
        parseInt(offsetX.toString()),
        parseInt(offsetY.toString()),
        parseInt(blurRadius.toString()),
        parseInt((spreadRadius ?? 0).toString()),
        color ? color.toString() : '000000',
        parseFloat(opacity.toString())
      )
      setValue(boxShadowCss)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, offsetX, offsetY, blurRadius, spreadRadius, color, opacity])

  return (
    <InputBoxShadowView>
      <DesignLabel label={label} required={required}>
        <InputBoxShadowValue>
          <TextBody size="xsmall" color="tertiary">
            {value}
          </TextBody>
        </InputBoxShadowValue>
      </DesignLabel>
      <InputCheckbox
        label="No Box Shadow"
        id={id}
        name={id}
        value={checked}
        setValue={setChecked}
      />
      <InputRange
        label="Offset X"
        name="offsetX"
        value={parseInt(offsetX.toString())}
        setValue={setOffsetX}
        min={-100}
        max={100}
        step={1}
        disabled={checked}
        suffix="PX"
      />
      <InputRange
        label="Offset Y"
        name="offsetY"
        value={parseInt(offsetY.toString())}
        setValue={setOffsetY}
        min={-100}
        max={100}
        step={1}
        disabled={checked}
        suffix="PX"
      />
      <InputRange
        label="Blur Radius"
        name="blurRadius"
        value={parseInt(blurRadius.toString())}
        setValue={setBlurRadius}
        min={-100}
        max={100}
        step={1}
        disabled={checked}
        suffix="PX"
      />
      <InputRange
        label="Spread Radius"
        name="spreadRadius"
        value={parseInt((spreadRadius ?? 0).toString())}
        setValue={setSpreadRadius}
        min={-100}
        max={100}
        step={1}
        disabled={checked}
        suffix="PX"
      />
      <InputRange
        label="Opacity"
        name="opacity"
        value={parseFloat(opacity.toString())}
        setValue={sanitizeOpacity}
        min={0}
        max={1}
        step={0.01}
        disabled={checked}
      />
      <InputColor
        label="Color"
        name="color"
        value={color as string}
        setValue={setColor}
      />
    </InputBoxShadowView>
  )
}

export default InputBoxShadow
