import styled from '@emotion/styled'

export const ContentCardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ContentCardDescription = styled.div`
  margin: 0 1rem;
  text-wrap: nowrap;
`

export const ContentCardIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

export const ContentCardCheckWrapper = styled.span<{
  selected?: boolean
}>`
  margin-left: auto;
  color: ${props =>
    props.selected
      ? props.theme.colors.success.background
      : props.theme.colors.text.primary};
  &:hover {
    color: ${props => props.theme.colors.success.background};
  }
`

export const ContentCardFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
`

export const ContentCardContainer = styled.div<{
  disabled?: boolean
  selected?: boolean
  isLoading?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 45%;
  max-width: 45%;
  cursor: ${props => (props.isLoading ? 'progress' : 'pointer')};
  opacity: ${props => (props.disabled || props.isLoading ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  transition: all 250ms ease;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 50%;
    min-width: 30rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  &:hover {
    border-color: ${props => props.theme.colors.accent.primary};
  }
`

export const ContentCardCompletedOptionsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

export const ContentCardQuicklinkSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  button {
    padding: 0 !important;
  }
`

export const ContentCardBodyWrapper = styled.span`
  // width: 100%;
  :nth-of-type(1) > div {
    margin: 0;
    min-width: 100%;
  }
`

export const ContentCardTitleWrapper = styled.span`
  width: 100%;
`
