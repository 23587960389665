import { useAppDispatch } from 'app/hooks'
import { Button, TextBody } from 'components'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { ModalWindow } from 'types'

const Warning = ({
  args
}: {
  args: {
    title: string
    warning: string
  }
  modalWindow: ModalWindow
}) => {
  const { title, warning } = args
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title={title} />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        {warning}
      </TextBody>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

export default Warning
