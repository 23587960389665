import Button from 'components/Button'

const ButtonReload = ({ reload }: { reload?: (() => void) | null }) => {
  if (!reload) return null
  return (
    <Button color="primary" size="small" onClick={reload}>
      Reload Results
    </Button>
  )
}

export default ButtonReload
