import { RequestError } from '@open-tender/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  BackgroundTask,
  OrderSource,
  ReceiptType,
  TendersSummaryReport
} from 'types'
import { RequestStatus, makeQueryParams } from 'utils'

export interface TendersSummaryReportState {
  data: TendersSummaryReport
  loading: RequestStatus
  error: RequestError
}

const initialState: TendersSummaryReportState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export interface TendersSummaryReportCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export type FetchTendersSummaryReportParams = {
  end_date: string
  start_date: string
  store_id?: number
  receipt_type?: ReceiptType
  order_source?: OrderSource
}

export const createTendersSummaryReportCSV = createAsyncThunk<
  TendersSummaryReportCSVAPI,
  FetchTendersSummaryReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'tendersSummary/createTendersSummaryReportCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/tender-totals/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(
        endpoint,
        'POST'
      )) as TendersSummaryReportCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchTendersSummaryReport = createAsyncThunk<
  TendersSummaryReport,
  FetchTendersSummaryReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'tendersSummary/fetchTendersSummaryReport',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/tender-totals${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as TendersSummaryReport
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const TendersSummaryReportSlice = createSlice({
  name: 'tendersSummary',
  initialState,
  reducers: {
    resetTendersSummaryReport: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTendersSummaryReport.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchTendersSummaryReport.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchTendersSummaryReport.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetTendersSummaryReport } = TendersSummaryReportSlice.actions

export const selectTendersSummaryReport = (state: RootState) =>
  state.tendersSummaryReport

export default TendersSummaryReportSlice.reducer
