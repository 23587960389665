import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Campaigns } from 'types'
import { beeTypes } from '@mailupinc/bee-plugin'
import { BeeEditorVariants } from 'types'
export interface CampaignsState {
  data: Campaigns
  loading: RequestStatus
  error: RequestError
}

export interface CampaignContentTokenState {
  data?: beeTypes.IToken
  loading: RequestStatus
  error: RequestError
}

export type GetCampaignContentTokenParams = {
  variant: BeeEditorVariants
}

const initialState: CampaignContentTokenState = {
  data: undefined,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCampaignContentToken = createAsyncThunk<
  beeTypes.IToken,
  GetCampaignContentTokenParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaigns/fetchCampaignContentToken',
  async ({ variant }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const endpoint = `campaign-content-token/${variant}`
      const api = getState().authUser.api
      const resp = (await api?.request(endpoint)) as beeTypes.IToken
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const campaignContentTokenSlice = createSlice({
  name: 'campaigns/campaignContentTokenSlice',
  initialState,
  reducers: {
    resetCampaigns: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCampaignContentToken.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchCampaignContentToken.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchCampaignContentToken.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
      }
    )
  }
})

export const { resetCampaigns } = campaignContentTokenSlice.actions

export const selectCampaignContentToken = (state: RootState) =>
  state.campaignContentToken

export default campaignContentTokenSlice.reducer
