import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTask, Customers, SortDirection } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface CustomersState {
  data: Customers
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface CustomersAPI {
  data: Customers
  links: {
    next: string
  }
}
export interface CustomersCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export type CustomersParams = {
  limit?: number
  cursor?: string
  with_related?: string
  created_before?: string
  created_after?: string
  updated_before?: string
  updated_after?: string
  last_ordered_before?: string
  last_ordered_after?: string
  is_blocked?: boolean
  search?: string
  'sort-by'?: sortBy
  'sort-direction'?: SortDirection
}

type sortBy = 'last_ordered' | 'created' | 'updated'

const initialState: CustomersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCustomers = createAsyncThunk<
  CustomersAPI,
  CustomersParams | void,
  { state: RootState; rejectValue: RequestError }
>('customers/fetchCustomers', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `customers${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as CustomersAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createCustomersCSV = createAsyncThunk<
  CustomersCSVAPI,
  CustomersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'giftCards/createGiftCardsCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `customers/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as CustomersCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetCustomers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCustomers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchCustomers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetCustomers } = customersSlice.actions

export const selectCustomers = (state: RootState) => state.customers

export default customersSlice.reducer
