import styled from '@emotion/styled'
import DesignLabel from './DesignLabel'
import DesignInputClear from './DesignInputClear'

const InputSelectWrapper = styled.span`
  display: block;
  position: relative;
  flex: 0 0 ${props => props.theme.layout.desktop.inputWidth};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex: 0 0 ${props => props.theme.layout.mobile.inputWidth};
  }
`

const InputSelectView = styled.select<{
  inherited?: boolean
  hasPlaceholder: boolean
}>`
  width: 100%;
  outline: none;
  background-color: inherit;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-color: ${props => props.theme.inputs.borderColor};
  border-radius: ${props => props.theme.inputs.borderRadius};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  // line-height: ${props => props.theme.inputs.lineHeight};
  // padding: 0.7rem 0.9rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0 0.9rem;
  color: ${props =>
    props.hasPlaceholder
      ? props => props.theme.colors.text.secondary
      : props.inherited
        ? props.theme.colors.text.secondary
        : props.theme.inputs.color};
  font-size: ${props => props.theme.sizes.xsmall};
  transition: ${props => props.theme.transition};

  &:active,
  &:focus {
    color: ${props =>
      props.inherited
        ? props.theme.colors.text.secondary
        : props.theme.inputs.color};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props =>
      props.inherited
        ? props.theme.colors.text.secondary
        : props.theme.inputs.color};
  }
`

const InputSelectDropdown = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3rem;
  border-left: 0.1rem solid ${props => props.theme.inputs.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const InputSelectArrow = styled.span`
  display: block;
  width: 0;
  height: 0;
  margin: 0.1rem 0 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid ${props => props.theme.colors.text.primary};
`

const InputSelect = ({
  label,
  required,
  name,
  value,
  setValue,
  options,
  inherited,
  allowZero = true
}: {
  label: string
  name: string
  value: string | number
  setValue: (value: string | number | null) => void
  required?: boolean
  options?: {
    value: string | number | undefined
    text: string
  }[]
  inherited?: boolean
  allowZero?: boolean
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const intVal = Number(evt.target.value)
    const val =
      intVal || (intVal === 0 && allowZero) ? intVal : evt.target.value
    setValue(val)
  }

  if (!options) return null

  const hasPlaceholder = value === '' && options[0].text === '--'

  return (
    <DesignLabel
      label={label}
      required={required}
      htmlFor={name}
      inherited={inherited}
    >
      <InputSelectWrapper>
        <InputSelectView
          value={value || 'no-option'}
          name={name}
          onChange={onChange}
          inherited={inherited}
          hasPlaceholder={hasPlaceholder}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </InputSelectView>
        <InputSelectDropdown>
          <InputSelectArrow />
        </InputSelectDropdown>
        {inherited === false && <DesignInputClear setValue={setValue} />}
      </InputSelectWrapper>
    </DesignLabel>
  )
}

export default InputSelect
