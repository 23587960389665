import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { WaitTimeDelivery } from 'types'
import { showNotification } from 'slices/notification'

export interface WaitTimeDeliveryState {
  data: WaitTimeDelivery | null
  loading: RequestStatus
  error: RequestError
}

const initialState: WaitTimeDeliveryState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const navigatePath = '/settings/ops/wait-times/delivery'

export const fetchWaitTimeDelivery = createAsyncThunk<
  WaitTimeDelivery,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimeDelivery/fetchWaitTimeDelivery',
  async (deliveryWaitTimeId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `delivery-wait-times/${deliveryWaitTimeId}`
      const resp = (await api?.request(endpoint)) as WaitTimeDelivery
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createWaitTimeDelivery = createAsyncThunk<
  WaitTimeDelivery,
  { data: WaitTimeDelivery; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimeDelivery/createWaitTimeDelivery',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'delivery-wait-times',
        'POST',
        data
      )) as WaitTimeDelivery
      dispatch(showNotification('Delivery wait time successfully created!'))
      navigate({ pathname: navigatePath })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateWaitTimeDelivery = createAsyncThunk<
  WaitTimeDelivery,
  {
    deliveryWaitTimeId: string | number
    data: WaitTimeDelivery
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimeDelivery/updateWaitTimeDelivery',
  async (
    { deliveryWaitTimeId, data, navigate },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `delivery-wait-times/${deliveryWaitTimeId}`,
        'PUT',
        data
      )) as WaitTimeDelivery
      dispatch(showNotification('Delivery wait time successfully updated!'))
      navigate({ pathname: navigatePath })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteWaitTimeDelivery = createAsyncThunk<
  void,
  {
    deliveryWaitTimeId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'waitTimeDelivery/deleteWaitTimeDelivery',
  async (
    { deliveryWaitTimeId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `delivery-wait-times/${deliveryWaitTimeId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Delivery wait time removed!'))
      navigate({ pathname: navigatePath })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const waitTimeDeliverySlice = createSlice({
  name: 'waitTimeDelivery',
  initialState,
  reducers: {
    resetWaitTimeDelivery: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchWaitTimeDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchWaitTimeDelivery.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchWaitTimeDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createWaitTimeDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createWaitTimeDelivery.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createWaitTimeDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateWaitTimeDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateWaitTimeDelivery.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateWaitTimeDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteWaitTimeDelivery.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteWaitTimeDelivery.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteWaitTimeDelivery.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetWaitTimeDelivery } = waitTimeDeliverySlice.actions

export const selectWaitTimeDelivery = (state: RootState) =>
  state.waitTimeDelivery

export default waitTimeDeliverySlice.reducer
