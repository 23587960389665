import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { NotificationPreferences } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface NotificationPreferencesState {
  data: NotificationPreferences
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface NotificationPreferencesAPI {
  data: NotificationPreferences
  links: {
    next: string
  }
}

export type NotificationPreferencesParams = {
  limit?: number
  cursor?: string
}

const initialState: NotificationPreferencesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchNotificationPreferences = createAsyncThunk<
  NotificationPreferencesAPI,
  NotificationPreferencesParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'notificationPreferences/fetchNotificationPreferences',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `brand-notification-preferences${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as NotificationPreferencesAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const notificationPreferencesSlice = createSlice({
  name: 'notificationPreferences',
  initialState,
  reducers: {
    resetNotificationPreferences: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchNotificationPreferences.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchNotificationPreferences.fulfilled,
      (state, { payload }) => {
        state.data = payload.data
        state.links = payload.links
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchNotificationPreferences.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetNotificationPreferences } =
  notificationPreferencesSlice.actions

export const selectNotificationPreferences = (state: RootState) =>
  state.notificationPreferences

export default notificationPreferencesSlice.reducer
