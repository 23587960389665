import { Page } from 'components'
import PageHeader from 'components/PageHeader'

const Dashboard = () => {
  return (
    <Page>
      <PageHeader
        title="Dashboard"
        subtitle="A summary of operating metrics across all stores"
      />
    </Page>
  )
}

Dashboard.displayName = 'Dashboard'

export default Dashboard
