import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { gql } from 'graphql-request'
import { ListFilter, LocationOrderingPlatforms } from 'types'
import { LOCATION_ORDERING_PLATFORM, RequestError, RequestStatus } from 'utils'

export interface OnboardingOrderingPlatformsState {
  data: LocationOrderingPlatforms | null
  loading: RequestStatus
  error: RequestError
}

export interface OnboardingOrderingPlatformsAPI {
  locationOrderingPlatforms: LocationOrderingPlatforms
}

const initialState: OnboardingOrderingPlatformsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

const LOAD_LOCATION_ORDERING_PLATFORMS = gql`
  query UseLocationOrderingPlatforms(
    $filters: [JSON!]
    $page: Int
    $perPage: Int
    $order: String
    $orderBy: String
    $pageType: String
  ) {
    locationOrderingPlatforms(
      filters: $filters
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
      pageType: $pageType
    ) {
      page
      totalPageCount
      totalRecordsCount
      results {
        ...LocationOrderingPlatformFragment
        credentialVerified
      }
    }
  }
  ${LOCATION_ORDERING_PLATFORM}
`

export type OnboardingOrderingPlatformsParams = {
  filters?: ListFilter[]
  order?: string
  orderBy?: string
  page?: number
  perPage?: number
  pageType?: string
  brandIds?: string[]
  searchTerm?: string
}

export const fetchOnboardingOrderingPlatforms = createAsyncThunk<
  OnboardingOrderingPlatformsAPI,
  OnboardingOrderingPlatformsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'onboardingOrderingPlatforms/fetchOnboardingOrderingPlatforms',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().iacmUser.api
      return (await api?.request(
        LOAD_LOCATION_ORDERING_PLATFORMS,
        params
      )) as OnboardingOrderingPlatformsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const onboardingOrderingPlatformsSlice = createSlice({
  name: 'onboardingOrderingPlatforms',
  initialState,
  reducers: {
    resetOnboardingOrderingPlatforms: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOnboardingOrderingPlatforms.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchOnboardingOrderingPlatforms.fulfilled,
      (state, { payload }) => {
        state.data = payload.locationOrderingPlatforms
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchOnboardingOrderingPlatforms.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetOnboardingOrderingPlatforms } =
  onboardingOrderingPlatformsSlice.actions

export const selectOnboardingOrderingPlatforms = (state: RootState) =>
  state.onboardingOrderingPlatforms

export default onboardingOrderingPlatformsSlice.reducer
