import TextBody from 'components/TextBody'
import TextHeading from 'components/TextHeading'
import { CheckmateMenu as CheckmateMenuType } from 'types'
import {
  CheckmateMenuSections,
  CheckmateMenuHeader,
  CheckmateMenuInfo,
  CheckmateMenuSection,
  CheckmateMenuSectionHeader,
  CheckmateMenuView,
  CheckmateMenuSectionsLinks,
  CheckmateMenuSectionsLink,
  CheckmateMenuItems,
  CheckmateMenuInfoSection
} from './CheckmateMenu.styled'
import CheckmateMenuDetails from './CheckmateMenuDetails'
import CheckmateMenuHours from './CheckmateMenuHours'
import CheckmateMenuItem from './CheckmateMenuItem'
import CheckmateMenuSpecialHours from './CheckmateMenuSpecialHours'
import CheckmateMenuDynamicPrepTimes from './CheckmateMenuDynamicPrepTimes'

interface CheckmateMenuProps {
  menu: CheckmateMenuType
}

const CheckmateMenu = ({ menu }: CheckmateMenuProps) => {
  const {
    id,
    name,
    description,
    currency_name,
    meta,
    location,
    hours,
    sections,
    special_hours,
    dynamic_prep_time
  } = menu
  return (
    <CheckmateMenuView>
      <CheckmateMenuHeader id={id}>
        {/* <TextBody as="p" size="xsmall" style={{ margin: '0 0 1rem' }}>
          {id}
        </TextBody> */}
        <TextHeading as="p" size="xxxlarge">
          {name}
        </TextHeading>
        {!!description && (
          <TextBody as="p" size="large" style={{ margin: '1rem 0 0' }}>
            {description}
          </TextBody>
        )}
      </CheckmateMenuHeader>
      <CheckmateMenuInfo>
        <CheckmateMenuInfoSection>
          <CheckmateMenuHours hours={hours} />
          <CheckmateMenuSpecialHours specialHours={special_hours} />
        </CheckmateMenuInfoSection>
        <CheckmateMenuInfoSection>
          <CheckmateMenuDetails
            currency={currency_name}
            meta={meta}
            location={location}
          />
        </CheckmateMenuInfoSection>
        {dynamic_prep_time && (
          <CheckmateMenuInfoSection>
            <CheckmateMenuDynamicPrepTimes
              dynamicPrepTimes={dynamic_prep_time}
            />
          </CheckmateMenuInfoSection>
        )}
      </CheckmateMenuInfo>
      <CheckmateMenuSections>
        <TextHeading as="p" size="medium">
          This menu has {sections.length} section(s)
        </TextHeading>
        <CheckmateMenuSectionsLinks>
          {sections.map(section => (
            <CheckmateMenuSectionsLink key={section.id}>
              <TextBody size="small" color="tertiary">
                <a href={`#${section.id}`}>{section.name}</a> (
                {section.items.length} items)
              </TextBody>
            </CheckmateMenuSectionsLink>
          ))}
        </CheckmateMenuSectionsLinks>
      </CheckmateMenuSections>
      {sections.map(section => (
        <CheckmateMenuSection key={section.id} id={section.id}>
          <CheckmateMenuSectionHeader>
            <div>
              <TextHeading as="p" size="xxlarge">
                {section.name}
              </TextHeading>
              {!!section.description && (
                <TextBody as="p" style={{ margin: '1rem 0 0' }}>
                  {section.description}
                </TextBody>
              )}
            </div>
            <div>
              <TextBody>{section.items.length} items</TextBody>
            </div>
          </CheckmateMenuSectionHeader>
          <CheckmateMenuItems>
            {section.items.map(item => (
              <CheckmateMenuItem key={item.id} item={item} />
            ))}
          </CheckmateMenuItems>
        </CheckmateMenuSection>
      ))}
    </CheckmateMenuView>
  )
}

export default CheckmateMenu
