import { Categories, Category, Item, RecommendedType } from 'types'
import {
  MenuCheckboxesOptionsAllCheckbox,
  MenuCheckboxesOptionsCategory,
  MenuCheckboxesOptionsContent,
  MenuCheckboxesOptionsHeader,
  MenuCheckboxesOptionsList,
  MenuCheckboxesOptionsNone,
  MenuCheckboxesOptionsTitle,
  MenuCheckboxesOptionsView
} from './MenuCheckboxes.styled'
import { Checkbox } from 'components/Forms'
import ButtonLink from 'components/ButtonLink'
import { unique } from '../../utils'
import { useCallback } from 'react'

const MenuCheckboxesOptions = ({
  title,
  type,
  categories,
  selectedIds,
  selectCategory,
  updateValues
}: {
  title: string
  type: RecommendedType
  categories: Categories
  selectedIds: number[] | null
  selectCategory: (category: Category, type: RecommendedType) => void
  updateValues: (value: Item, categoryId: number) => void
}) => {
  const isAllChecked = useCallback(
    (category: Category) => {
      const categoryItems = category.items ?? []
      const categoryItemsIds =
        categoryItems.map(item => item.item.item_id) ?? []
      const categorySelectedIds =
        selectedIds?.filter(id => categoryItemsIds.includes(id)) ?? []
      return unique(categorySelectedIds).length === categoryItems.length
    },
    [selectedIds]
  )

  return (
    <MenuCheckboxesOptionsView>
      <MenuCheckboxesOptionsHeader as="div" size="xlarge">
        {title}
      </MenuCheckboxesOptionsHeader>
      <MenuCheckboxesOptionsContent>
        {categories.map(category => (
          <MenuCheckboxesOptionsCategory
            key={`${title}-${category.category_id}`}
          >
            <MenuCheckboxesOptionsTitle>
              <MenuCheckboxesOptionsAllCheckbox
                name={`${title}-${category.category_id}-all`}
                checked={isAllChecked(category)}
                onChange={() => selectCategory(category, type)}
              />
              <ButtonLink
                onClick={() => selectCategory(category, type)}
                size="medium"
              >
                {category.full_name}
              </ButtonLink>
            </MenuCheckboxesOptionsTitle>
            <MenuCheckboxesOptionsList>
              {category.items?.length ? (
                category.items.map(i => {
                  const item = i.item
                  return (
                    <Checkbox
                      key={item.item_id}
                      label={item.full_name}
                      name={`${title}-${category.category_id}-${item.item_id}`}
                      checked={selectedIds?.includes(item.item_id) || false}
                      onChange={() => updateValues(item, category.category_id)}
                    />
                  )
                })
              ) : (
                <MenuCheckboxesOptionsNone size="small">
                  No items in this category
                </MenuCheckboxesOptionsNone>
              )}
            </MenuCheckboxesOptionsList>
          </MenuCheckboxesOptionsCategory>
        ))}
      </MenuCheckboxesOptionsContent>
    </MenuCheckboxesOptionsView>
  )
}

export default MenuCheckboxesOptions
