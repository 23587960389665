import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import {
  selectOnboardingOrderingPlatform,
  updateOnboardingOrderingPlatform
} from 'slices/iacm/onboardingOrderingPlatform'
import { FormFieldType, ModalWindow } from 'types'
import { RequestStatus } from 'utils'
import { OnboardingQualityCheckOPStatusFormView } from './OnboardingQualityCheck.styled'
import { useEffect, useState } from 'react'
import { closeModal } from 'slices/modal'

export interface OnboardingQualityCheckOPStatusArgs {
  opStatusOptions: { value: string; text: string }[]
  locationId: string
}

interface OPStatusFormValues {
  status: string
  qualityCheckComment: string
}

const OnboardingQualityCheckOPStatus = ({
  args,
  modalWindow
}: {
  args: OnboardingQualityCheckOPStatusArgs
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { opStatusOptions, locationId } = args || {}
  const { data, loading, error } = useAppSelector(
    selectOnboardingOrderingPlatform
  )
  const isLoading = loading === RequestStatus.Pending
  const { status, qualityCheckComment } = data || {}

  const opStatusData = {
    status,
    qualityCheckComment
  }

  const submit = (data: OPStatusFormValues) => {
    if (locationId) {
      setSubmitted(true)
      dispatch(updateOnboardingOrderingPlatform({ ...data, id: locationId }))
    }
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <>
      <ModalHeader title="Update OP Status" />
      <OnboardingQualityCheckOPStatusFormView>
        <Form
          fields={generateOPStatusFromFields(opStatusOptions)}
          data={opStatusData || opDetailsEmpty}
          isLoading={isLoading}
          submit={submit}
          submitText="Save"
        />
      </OnboardingQualityCheckOPStatusFormView>
    </>
  )
}

export default OnboardingQualityCheckOPStatus

const opDetailsEmpty = {
  status: '',
  qualityCheckComment: ''
}

const generateOPStatusFromFields = (
  opStatusOptions: { value: string; text: string }[]
) => {
  const opDetailsFormFields: FormFieldType[] = [
    {
      fieldType: 'Select',
      type: 'select',
      options: opStatusOptions,
      label: 'OP Status',
      name: 'status'
    },
    {
      fieldType: 'Input',
      type: 'text',
      label: 'Comment',
      name: 'qualityCheckComment'
    }
  ]
  return opDetailsFormFields
}
