import styled from '@emotion/styled'
import DesignLabel from './DesignLabel'
import DesignTextarea from './DesignTextarea'

const InputTextareaView = styled.span`
  position: relative;
  display: block;

  label {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0.5rem;
  }
`

const InputTextarea = ({
  label,
  required,
  name,
  value,
  setValue
}: {
  label: string
  name: string
  value: string
  setValue: (value: string | number) => void
  required?: boolean
}) => {
  return (
    <InputTextareaView>
      <DesignLabel label={label} required={required} htmlFor={name}>
        <DesignTextarea name={name} value={value} setValue={setValue} />
      </DesignLabel>
    </InputTextareaView>
  )
}

export default InputTextarea
