import { useEffect, useState } from 'react'
import { ModalWindow, FormFieldType, SpecialistField } from 'types'
import { Form } from 'components'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal } from 'slices/modal'
import { ModalContent, ModalHeader } from 'components/Modal'
import { RequestStatus } from 'utils'
import {
  selectOnboardingLocation,
  updateLocation
} from 'slices/iacm/onboardingLocation'
import { selectExternalUsersOptions } from 'slices/iacm/externalUsers'

const specialistFieldMap: Record<SpecialistField, string> = {
  onboardingSpecialistId: 'Onboarding Specialist',
  menuMappingSpecialistId: 'Menu Mapping Specialist',
  goLiveSpecialistId: 'Go Live Specialist'
}

const EditOnboardingSpecialist = ({
  args,
  modalWindow
}: {
  args: {
    specialistId: number
    locationId: string
    specialistField: SpecialistField
    fetchLocations: () => void
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { specialistId, locationId, specialistField, fetchLocations } = args
  const { error, loading } = useAppSelector(selectOnboardingLocation)
  const externalUsersOptions = useAppSelector(selectExternalUsersOptions)
  const isLoading = loading === RequestStatus.Pending

  const editSpecialistData = {
    [specialistField]: specialistId
  }
  const editSpecialistEmpty = {
    [specialistField]: ''
  }
  const editSpecialistFields: FormFieldType[] = [
    {
      fieldType: 'Select',
      type: 'select',
      options: externalUsersOptions,
      label: specialistFieldMap[specialistField],
      name: specialistField,
      nullable: true
    }
  ]

  const submit = (data: { [K in SpecialistField]: string }) => {
    setSubmitted(true)
    dispatch(
      updateLocation({
        id: locationId,
        ...data
      })
    )
  }

  useEffect(() => {
    if (submitted && !isLoading && !error) {
      fetchLocations()
      dispatch(closeModal())
    }
  }, [dispatch, submitted, isLoading, error, fetchLocations])

  return (
    <>
      <ModalHeader title={`Edit ${specialistFieldMap[specialistField]}`} />
      <ModalContent>
        <Form
          fields={editSpecialistFields}
          data={editSpecialistData || editSpecialistEmpty}
          isLoading={isLoading}
          error={error}
          submit={submit}
        />
      </ModalContent>
    </>
  )
}

export default EditOnboardingSpecialist
