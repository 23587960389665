import { useRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import Button from 'components/Button'
import { QrCodeContainer, QrCodeDownload } from './QrCode.styled'
import { RevenueCenterQrCode } from 'pages/Store/StoreRevenueCenter/StoreRevenueCenterQrCodes/StoreRevenueCenterQrCodes'

interface QrCodeProps {
  revenueCenterQrCode: RevenueCenterQrCode
  revenueCenterName?: string
  qrCodeFileName: string
}

const QrCode = ({
  revenueCenterQrCode,
  revenueCenterName,
  qrCodeFileName
}: QrCodeProps) => {
  const { url, label } = revenueCenterQrCode
  const qrRef = useRef<HTMLDivElement>(null)

  const downloadQRCode = () => {
    if (qrRef.current instanceof HTMLDivElement) {
      let canvas = qrRef.current.querySelector('canvas') as HTMLCanvasElement
      let image = canvas.toDataURL('image/png')
      let anchor = document.createElement('a')
      anchor.href = image
      anchor.download = qrCodeFileName
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }
  }

  const qrcode = (
    <QRCodeCanvas
      value={url}
      size={200}
      bgColor="#ffffff"
      level="H"
      includeMargin={true}
    />
  )

  return (
    <QrCodeContainer>
      {label ? <strong>{label}</strong> : null}
      {qrcode}
      <QrCodeDownload ref={qrRef}>{qrcode}</QrCodeDownload>
      <Button disabled={!url} onClick={downloadQRCode}>
        Download
      </Button>
    </QrCodeContainer>
  )
}

export default QrCode
