import {
  CardView,
  CardHeader,
  CardCta,
  CardTitle,
  CardFooter,
  CardHeaderDescription,
  CardHeaderTitle
} from './Card.styled'

const Card = ({
  title,
  titleCta,
  children,
  footer,
  subtitle
}: {
  title?: React.ReactNode
  titleCta?: React.ReactNode
  children?: React.ReactNode
  footer?: React.ReactNode
  subtitle?: React.ReactNode
}) => {
  return (
    <CardView>
      {title || titleCta ? (
        <CardHeader>
          <CardHeaderTitle>
            {title && <CardTitle size="xlarge">{title}</CardTitle>}
            {titleCta && <CardCta>{titleCta}</CardCta>}
          </CardHeaderTitle>
          {subtitle && (
            <CardHeaderDescription>{subtitle}</CardHeaderDescription>
          )}
        </CardHeader>
      ) : null}
      {children}
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardView>
  )
}

export default Card
