import { useState } from 'react'
import ImageCard from './ImageCard'
import {
  ImageCardPreviewCanvasEditIcon,
  ImageCardPreviewCanvasHeading,
  ImageCardPreviewCanvasText,
  ImageCardScratchCanvas,
  ImageGridWrapper
} from './ImageCard.styled'

interface ImageGridProps {
  templates: {
    id: number
    image?: string
    name?: string
  }[]
  onSelect?: (index: number) => void
  isLoading?: boolean
}

const ImageGrid = ({ templates, onSelect, isLoading }: ImageGridProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<number>()

  const handleSelect = (id: number) => {
    setSelectedTemplate(id)
    onSelect && onSelect(id)
  }

  return (
    <ImageGridWrapper>
      <ImageCardScratchCanvas
        onClick={() => handleSelect(0)}
        isLoading={isLoading}
      >
        <ImageCardPreviewCanvasEditIcon />
        <ImageCardPreviewCanvasHeading>
          Blank Canvas
        </ImageCardPreviewCanvasHeading>
        <ImageCardPreviewCanvasText>
          Start from scratch
        </ImageCardPreviewCanvasText>
      </ImageCardScratchCanvas>
      {templates.map((template, index) => (
        <ImageCard
          isLoading={isLoading}
          key={template.id}
          image={template.image}
          name={template.name || ''}
          index={template.id}
          selected={selectedTemplate === template.id}
          onClick={() => handleSelect(template.id)}
        />
      ))}
    </ImageGridWrapper>
  )
}

export default ImageGrid
