import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { RevenueCenterOrderWindows } from 'types'
import { showNotification } from './notification'

export interface RevenueCenterOrderWindowsState {
  data: RevenueCenterOrderWindows | null
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: RevenueCenterOrderWindowsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface RevenueCenterOrderWindowsAPI {
  data: RevenueCenterOrderWindows
  links: {
    next: string
  }
}

export type RevenueCenterOrderWindowsParams = {
  cursor?: string
  limit?: number
  revenue_center_id?: number
  service_type?: string
  weekday?: string
}

export const fetchRevenueCenterOrderWindows = createAsyncThunk<
  RevenueCenterOrderWindowsAPI,
  RevenueCenterOrderWindowsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterOrderWindows/fetchRevenueCenterOrderWindows',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `order-times${queryParams}`
      const api = getState().authUser.api
      const resp = (await api?.request(
        endpoint
      )) as RevenueCenterOrderWindowsAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createRevenueCenterOrderWindows = createAsyncThunk<
  RevenueCenterOrderWindows,
  { data: RevenueCenterOrderWindows },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterOrderWindow/createRevenueCenterOrderWindow',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `order-times`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as RevenueCenterOrderWindows
      dispatch(showNotification('Order Window successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteRevenueCenterOrderWindows = createAsyncThunk<
  void,
  { data: RevenueCenterOrderWindows },
  { state: RootState; rejectValue: RequestError }
>(
  'revenueCenterOrderWindow/deleteRevenueCenterOrderWindow',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `order-times`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Order Window successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const revenueCenterOrderWindowsSlice = createSlice({
  name: 'revenueCenterOrderWindows',
  initialState,
  reducers: {
    resetRevenueCenterOrderWindows: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRevenueCenterOrderWindows.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchRevenueCenterOrderWindows.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload.data || initialState.data
        state.error = null
      }
    )
    builder.addCase(
      fetchRevenueCenterOrderWindows.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(createRevenueCenterOrderWindows.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      createRevenueCenterOrderWindows.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      createRevenueCenterOrderWindows.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(deleteRevenueCenterOrderWindows.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteRevenueCenterOrderWindows.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(
      deleteRevenueCenterOrderWindows.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetRevenueCenterOrderWindows } =
  revenueCenterOrderWindowsSlice.actions

export const selectRevenueCenterOrderWindows = (state: RootState) =>
  state.revenueCenterOrderWindows

export default revenueCenterOrderWindowsSlice.reducer
