import { useCallback } from 'react'
import { UploadFile } from 'components/Files'
import { useAppDispatch } from 'app/hooks'
import { PagesImageLink, PagesImageView } from './PagesImage.styled'
import { fetchAppContent } from 'slices/appContent'

interface PagesImageViewProps {
  fileName: string
  fileType: string
  fileUrl?: string
}

const PagesImage = ({ fileName, fileType, fileUrl }: PagesImageViewProps) => {
  const dispatch = useAppDispatch()
  const fetchFiles = useCallback(() => dispatch(fetchAppContent()), [dispatch])

  return (
    <PagesImageView>
      <UploadFile
        endpoint="order-app-images"
        fileName={fileName}
        fileType={fileType}
        fileUrl={fileUrl}
        fetchFiles={fetchFiles}
      />
      <PagesImageLink>
        <a href={fileUrl} target="_blank" rel="noreferrer">
          {fileUrl}
        </a>
      </PagesImageLink>
    </PagesImageView>
  )
}

export default PagesImage
