import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LoyaltyBonuses } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface LoyaltyBonusesState {
  data: LoyaltyBonuses
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface LoyaltyBonusesAPI {
  data: LoyaltyBonuses
  links: {
    next: string
  }
}

export type LoyaltyBonusesParams = {
  cursor?: string
  limit?: number
  with_related?: string
  loyalty_program_id: number
}

const initialState: LoyaltyBonusesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchLoyaltyBonuses = createAsyncThunk<
  LoyaltyBonusesAPI,
  LoyaltyBonusesParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'loyaltyBonuses/fetchLoyaltyBonuses',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `loyalty-bonus${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as LoyaltyBonusesAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const loyaltyBonusesSlice = createSlice({
  name: 'loyaltyBonuses',
  initialState,
  reducers: {
    resetLoyaltyBonuses: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchLoyaltyBonuses.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchLoyaltyBonuses.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchLoyaltyBonuses.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetLoyaltyBonuses } = loyaltyBonusesSlice.actions

export const selectLoyaltyBonuses = (state: RootState) => state.loyaltyBonuses

export default loyaltyBonusesSlice.reducer
