import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, DiscountEmail } from 'types'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import { addDiscountEmails, selectDiscountEmails } from 'slices/discountEmail'
import { fetchDiscount } from 'slices/discount'

const PromoCodeApprovedEmail = ({
  args,
  modalWindow
}: {
  args: { discountId: number }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectDiscountEmails)
  const isLoading = loading === RequestStatus.Pending
  const [submitted, setSubmitted] = useState(false)
  const { discountId } = args

  const submit = async (values: DiscountEmail) => {
    if (discountId) {
      const discount_id = Number(discountId)
      dispatch(addDiscountEmails({ data: [values], discount_id }))
      setSubmitted(true)
    }
  }

  useEffect(() => {
    if (submitted && !error && !isLoading) {
      const params = { with_related: 'emails' }
      dispatch(fetchDiscount({ discountId, params }))
      dispatch(closeModal())
    }
  }, [dispatch, submitted, error, isLoading, discountId])

  return (
    <div>
      <ModalHeader
        title="New email"
        subtitle="Use the form below to add a new email address to this promo code"
      />
      <Form
        fields={PromoCodeApprovedEmailsFields}
        data={{}}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
        isRelation={true}
      />
    </div>
  )
}

const PromoCodeApprovedEmailsFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    label: 'Email',
    placeholder: 'email address',
    name: 'email',
    type: 'email',
    id: 'email',
    error: 'Please enter an email'
  }
]

export default PromoCodeApprovedEmail
