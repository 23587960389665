import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BulkOrders } from 'types'
import {
  getCurrentDate,
  makeQueryParams,
  RequestError,
  RequestStatus
} from 'utils'
import { BulkOrdersAPI, BulkOrdersParams } from './bulkOrders'

export interface UpcomingOrdersState {
  data: BulkOrders
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: UpcomingOrdersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchUpcomingOrders = createAsyncThunk<
  BulkOrdersAPI | void,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'upcomingOrders/fetchUpcomingOrders',
  async (_, { getState, rejectWithValue }) => {
    const currentDate = getCurrentDate()
    try {
      const params: BulkOrdersParams = {
        start_date: currentDate,
        limit: 1000,
        exclude_in_store_orders: true
      }
      const queryParams = makeQueryParams(params)
      const endpoint = `bulk-orders${queryParams}`
      const api = getState().authUser.api
      if (!api) return
      return (await api?.request(endpoint)) as BulkOrdersAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const upcomingOrdersSlice = createSlice({
  name: 'upcomingOrders',
  initialState,
  reducers: {
    resetUpcomingOrders: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpcomingOrders.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchUpcomingOrders.fulfilled, (state, { payload }) => {
      state.data = payload?.data || state.data
      state.links = payload?.links || state.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchUpcomingOrders.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetUpcomingOrders } = upcomingOrdersSlice.actions

export const selectUpcomingOrders = (state: RootState) => state.upcomingOrders

export const selectUpcomingOrdersCount = createSelector(
  (state: RootState) => {
    const { data } = state.upcomingOrders
    return data
  },
  orders => orders.length
)

export default upcomingOrdersSlice.reducer
