import { useCallback, useEffect } from 'react'
import { Customer as CustomerType, ModalWindow } from 'types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal } from 'slices/modal'
import { fetchCustomer, resetCustomer, selectCustomer } from 'slices/customer'
import { addCustomer } from 'slices/order'
import { ModalHeader } from 'components/Modal'
import { CustomerForm } from 'components'

const Customer = ({
  args,
  modalWindow
}: {
  args: {
    customerId: number | string
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { error } = useAppSelector(selectCustomer)
  const { customerId } = args

  const callback = useCallback(
    (data: CustomerType) => {
      const { customer_id } = data
      dispatch(addCustomer(customer_id))
      dispatch(closeModal())
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(resetCustomer())
    if (customerId !== 'new') {
      dispatch(fetchCustomer({ customerId }))
    }
    return () => {
      dispatch(resetCustomer())
    }
  }, [dispatch, customerId])

  useEffect(() => {
    if (error) {
      modalWindow?.scroll(0, 0)
    }
  }, [error, modalWindow])

  return (
    <>
      <ModalHeader title="Add a new customer" />
      <CustomerForm customerId={customerId} callback={callback} />
    </>
  )
}

export default Customer
