import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { Allergens } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface AllergensState {
  data: Allergens
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface AllergensAPI {
  data: Allergens
  links: {
    next: string
  }
}

export type AllergensParams = {
  cursor?: string
  limit?: number
  with_related?: string
}

const initialState: AllergensState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAllergens = createAsyncThunk<
  AllergensAPI,
  AllergensParams | void,
  { state: RootState; rejectValue: RequestError }
>('allergens/fetchAllergens', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params ? makeQueryParams(params) : ''
    const endpoint = `allergens${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as AllergensAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const allergensSlice = createSlice({
  name: 'allergens',
  initialState,
  reducers: {
    resetAllergens: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAllergens.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAllergens.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchAllergens.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAllergens } = allergensSlice.actions

export const selectAllergens = (state: RootState) => state.allergens

export default allergensSlice.reducer
