import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button, ErrMsg, TextBody } from 'components'
import { RequestStatus } from 'utils'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'
import { selectCampaign } from 'slices/campaign'

const CampaignPause = ({
  args: { campaignId, handleUnschedule }
}: {
  args: {
    campaignId: number
    handleUnschedule: (campaignId: number) => void
  }
}) => {
  const dispatch = useAppDispatch()
  const { error, loading } = useAppSelector(selectCampaign)
  const isLoading = loading === RequestStatus.Pending

  const handleCancel = () => {
    dispatch(closeModal())
  }

  const handleUnscheduleClick = (campaignId: number) => {
    handleUnschedule(campaignId)
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Confirmation" />
      <ErrMsg errMsg={error?.detail} />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        Are you sure you want to pause this campaign?
      </TextBody>
      <ModalFooter>
        <Button
          color="secondary"
          size="medium"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={() => handleUnscheduleClick(campaignId)}
          disabled={isLoading}
        >
          {isLoading ? 'Pausing...' : 'Pause'}
        </Button>
      </ModalFooter>
    </>
  )
}

export default CampaignPause
