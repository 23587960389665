import {
  ImageCardPreviewCanvas,
  ImageCardPreviewCanvasHeading,
  ImageCardFooter,
  ImageCardTemplateName,
  ImageCardWrapper
} from './ImageCard.styled'

interface ImageCardProps {
  image: string | undefined
  name: string
  index: string | number
  selected: boolean
  onClick: () => void
  isLoading?: boolean
}

const ImageCard = ({
  image,
  name,
  index,
  selected,
  onClick,
  isLoading
}: ImageCardProps) => {
  return (
    <ImageCardWrapper
      onClick={onClick}
      selected={selected}
      key={index}
      isLoading={isLoading}
    >
      {image ? (
        <img src={image} alt="" key={index} />
      ) : (
        <ImageCardPreviewCanvas onClick={onClick}>
          <ImageCardPreviewCanvasHeading>
            No Preview Found
          </ImageCardPreviewCanvasHeading>
        </ImageCardPreviewCanvas>
      )}
      <ImageCardFooter>
        <ImageCardTemplateName>{name}</ImageCardTemplateName>
      </ImageCardFooter>
    </ImageCardWrapper>
  )
}

export default ImageCard
