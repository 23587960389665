import styled from '@emotion/styled'
import Button from 'components/Button'
import { ThemeFontSizes } from 'types'

const ButtonListView = styled.div<{ size: keyof ThemeFontSizes }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // gap: ${props => props.theme.layout.desktop.border};
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  border-bottom: 0;
  // background-color: ${props => props.theme.colors.border.primary};

  button {
    width: 100%;
    height: auto;
    padding: 1.2rem 0;
    border: 0;
    border-radius: 0;
    text-align: center;
    font-size: ${props => props.theme.sizes[props.size]};
    border-style: solid;
    border-bottom-width: ${props => props.theme.layout.desktop.border};
    border-color: ${props => props.theme.colors.border.primary};
    background-color: ${props => props.theme.colors.background.secondary};
  }

  button:enabled:hover {
    // background-color: ${props => props.theme.colors.background.tertiary};
  }

  button:disabled {
    opacity: 1;
    background-color: ${props => props.theme.colors.border.primary};

    & > span {
      opacity: 0.25;
    }
  }
`

export interface ButtonListButton {
  title: string
  onClick: () => void
  disabled?: boolean
}

const ButtonList = ({
  buttons,
  size = 'medium'
}: {
  buttons: ButtonListButton[]
  size?: keyof ThemeFontSizes
}) => {
  return (
    <ButtonListView size={size}>
      {buttons.map(button => (
        <Button
          key={button.title}
          color="tertiary"
          onClick={button.onClick}
          disabled={button.disabled}
        >
          {button.title}
        </Button>
      ))}
    </ButtonListView>
  )
}

export default ButtonList
