import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

export const NavTilesView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -1rem;
`

export const NavTilesItem = styled.div`
  width: 25%;
  padding: 1rem;
`

export const NavTileView = styled.div`
  width: 100%;
  aspect-ratio: 1;

  & > a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border: 0.1rem solid ${props => props.theme.colors.border.primary};
    background-color: transparent;
  }

  & > a:hover {
    opacity: 1;
  }

  & > a:hover .nav-tile-image > div {
    transform: scale(1.05);
  }
`

export const NavTileImage = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  border-top: 0.1rem solid ${props => props.theme.colors.border.primary};
  border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};

  & > div {
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform 0.4s ease 0s;
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
`

export const NavTileHeader = styled.div`
  flex: 0 0 auto;
  padding: 1.5rem;
`

export const NavTileContent = styled.div`
  flex: 0 0 8.8rem;
  height: 8.8rem;
  padding: 1.5rem;
`

export const NavTileDescription = styled(TextBody)`
  line-height: 1.35;
`
