const Logo = ({ width = 245 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 146 24"
      fill="#fff"
    >
      <path
        d="M140.389 19.5965C139.269 19.5965 138.28 19.3694 137.419 18.9153C136.558 18.4611 135.884 17.828 135.396 17.016C134.908 16.204 134.664 15.2613 134.664 14.1878C134.664 13.1143 134.908 12.1715 135.396 11.3595C135.884 10.5475 136.558 9.91443 137.419 9.46026C138.294 9.00609 139.298 8.779 140.432 8.779C141.45 8.779 142.368 9.01297 143.186 9.4809C144.004 9.94884 144.649 10.6507 145.123 11.5866C145.611 12.5087 145.854 13.651 145.854 15.0135H137.763L138.129 14.6213C138.129 15.0892 138.229 15.5021 138.43 15.8599C138.631 16.2178 138.911 16.493 139.269 16.6857C139.628 16.8646 140.03 16.9541 140.475 16.9541C141.005 16.9541 141.45 16.844 141.809 16.6238C142.167 16.3898 142.419 16.1008 142.562 15.7567L145.553 16.727C145.266 17.3876 144.857 17.9312 144.327 18.3579C143.81 18.7845 143.215 19.1011 142.54 19.3075C141.866 19.5002 141.149 19.5965 140.389 19.5965ZM138.215 13.3001L137.871 12.8665H142.691L142.368 13.3001C142.368 12.7771 142.261 12.3711 142.046 12.0821C141.83 11.7793 141.565 11.5591 141.249 11.4215C140.948 11.2838 140.64 11.215 140.324 11.215C140.008 11.215 139.686 11.2838 139.356 11.4215C139.04 11.5591 138.767 11.7793 138.538 12.0821C138.323 12.3711 138.215 12.7771 138.215 13.3001Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M131.518 19.5712C130.241 19.5712 129.287 19.2822 128.656 18.7041C128.039 18.1123 127.731 17.2728 127.731 16.1856V5.78092H131.088V15.3804C131.088 15.7933 131.167 16.0961 131.324 16.2888C131.482 16.4815 131.726 16.5778 132.056 16.5778C132.185 16.5778 132.336 16.5503 132.508 16.4952C132.68 16.4264 132.838 16.3438 132.981 16.2475L134.143 18.6009C133.799 18.8762 133.383 19.1101 132.895 19.3028C132.422 19.4817 131.963 19.5712 131.518 19.5712ZM126.052 11.7058V9.0014H133.541V11.7058H126.052Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M121.825 19.3488L121.718 17.1192V14.2084C121.718 13.6579 121.668 13.1762 121.567 12.7633C121.481 12.3504 121.302 12.0339 121.029 11.8137C120.771 11.5797 120.391 11.4627 119.889 11.4627C119.487 11.4627 119.135 11.5522 118.834 11.7311C118.533 11.91 118.275 12.1509 118.059 12.4537L115.111 11.5247C115.369 11.0154 115.707 10.5544 116.123 10.1415C116.553 9.72863 117.077 9.39833 117.694 9.1506C118.31 8.90287 119.028 8.779 119.846 8.779C120.965 8.779 121.911 8.97856 122.686 9.37768C123.461 9.7768 124.042 10.3342 124.429 11.0499C124.817 11.7655 125.01 12.6119 125.01 13.5891L124.967 19.3488H121.825ZM118.877 19.5965C117.615 19.5965 116.632 19.3281 115.929 18.7914C115.226 18.2409 114.874 17.4564 114.874 16.438C114.874 15.3507 115.24 14.5387 115.972 14.002C116.704 13.4652 117.729 13.1968 119.049 13.1968H121.997V15.2819H120.297C119.594 15.2819 119.085 15.3782 118.77 15.5709C118.454 15.7498 118.296 16.0113 118.296 16.3554C118.296 16.6031 118.411 16.8027 118.64 16.9541C118.884 17.0917 119.214 17.1605 119.63 17.1605C120.046 17.1605 120.405 17.0779 120.706 16.9128C121.022 16.7339 121.266 16.4999 121.438 16.2109C121.624 15.9219 121.718 15.6122 121.718 15.2819H122.579C122.579 16.6444 122.27 17.7041 121.653 18.4611C121.051 19.218 120.125 19.5965 118.877 19.5965Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M96.9178 19.3488V9.02673H100.081L100.21 10.3273C100.555 9.81809 100.978 9.43273 101.48 9.17124C101.996 8.90975 102.563 8.779 103.18 8.779C104.07 8.779 104.801 8.95792 105.375 9.31575C105.963 9.65982 106.394 10.1897 106.666 10.9053C106.996 10.2172 107.448 9.69423 108.022 9.3364C108.596 8.9648 109.241 8.779 109.959 8.779C111.25 8.779 112.218 9.15748 112.864 9.91443C113.524 10.6714 113.847 11.8481 113.832 13.4446V19.3488H110.475V14.0639C110.475 13.5134 110.411 13.0799 110.282 12.7633C110.167 12.433 109.995 12.1922 109.765 12.0408C109.536 11.8894 109.263 11.8068 108.947 11.793C108.33 11.7793 107.857 11.9513 107.527 12.3091C107.211 12.6532 107.054 13.1831 107.054 13.8987V19.3488H103.697V14.0639C103.697 13.5134 103.632 13.0799 103.503 12.7633C103.388 12.433 103.216 12.1922 102.986 12.0408C102.757 11.8894 102.484 11.8068 102.169 11.793C101.552 11.7793 101.078 11.9513 100.748 12.3091C100.433 12.6532 100.275 13.1831 100.275 13.8987V19.3488H96.9178Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M88.2366 17.0659L86.2568 14.8363L91.6582 9.03531H95.5963L88.2366 17.0659ZM85.0517 19.3574V4.90648H88.4087V19.3574H85.0517ZM91.981 19.3574L88.6455 14.795L90.8405 12.5861L95.8761 19.3574H91.981Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M78.9958 19.5965C77.8481 19.5965 76.8223 19.3694 75.9185 18.9153C75.029 18.4473 74.3332 17.8074 73.8311 16.9954C73.329 16.1696 73.0779 15.2337 73.0779 14.1878C73.0779 13.128 73.329 12.1922 73.8311 11.3802C74.3332 10.5682 75.0219 9.93507 75.897 9.4809C76.7865 9.01297 77.8051 8.779 78.9528 8.779C80.0575 8.779 81.0689 9.04738 81.9871 9.58412C82.9053 10.1209 83.5652 10.8985 83.9669 11.9169L80.782 12.9491C80.6242 12.6188 80.3588 12.3504 79.9857 12.144C79.6271 11.9238 79.2254 11.8137 78.7806 11.8137C78.3359 11.8137 77.9414 11.9169 77.5971 12.1233C77.2671 12.316 77.0017 12.5913 76.8008 12.9491C76.6143 13.3069 76.5211 13.7198 76.5211 14.1878C76.5211 14.6557 76.6143 15.0686 76.8008 15.4264C77.0017 15.7705 77.2743 16.0457 77.6186 16.2522C77.9772 16.4586 78.3789 16.5618 78.8237 16.5618C79.2684 16.5618 79.6701 16.4517 80.0288 16.2315C80.3874 16.0113 80.6529 15.7223 80.825 15.3645L84.0099 16.3967C83.5939 17.4289 82.9268 18.2202 82.0086 18.7708C81.1048 19.3213 80.1005 19.5965 78.9958 19.5965Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M66.5702 19.5965C65.4512 19.5965 64.4612 19.3694 63.6005 18.9153C62.7397 18.4611 62.0654 17.828 61.5776 17.016C61.0898 16.204 60.8459 15.2613 60.8459 14.1878C60.8459 13.1143 61.0898 12.1715 61.5776 11.3595C62.0654 10.5475 62.7397 9.91443 63.6005 9.46026C64.4756 9.00609 65.4798 8.779 66.6132 8.779C67.6318 8.779 68.55 9.01297 69.3677 9.4809C70.1855 9.94884 70.8311 10.6507 71.3045 11.5866C71.7923 12.5087 72.0362 13.651 72.0362 15.0135H63.9448L64.3106 14.6213C64.3106 15.0892 64.411 15.5021 64.6119 15.8599C64.8127 16.2178 65.0925 16.493 65.4512 16.6857C65.8098 16.8646 66.2115 16.9541 66.6562 16.9541C67.1871 16.9541 67.6318 16.844 67.9905 16.6238C68.3491 16.3898 68.6002 16.1008 68.7437 15.7567L71.7349 16.727C71.448 17.3876 71.0391 17.9312 70.5083 18.3579C69.9918 18.7845 69.3964 19.1011 68.7221 19.3075C68.0479 19.5002 67.3305 19.5965 66.5702 19.5965ZM64.3967 13.3001L64.0524 12.8665H68.8728L68.55 13.3001C68.55 12.7771 68.4424 12.3711 68.2272 12.0821C68.012 11.7793 67.7466 11.5591 67.431 11.4215C67.1297 11.2838 66.8212 11.215 66.5056 11.215C66.19 11.215 65.8672 11.2838 65.5372 11.4215C65.2216 11.5591 64.949 11.7793 64.7195 12.0821C64.5043 12.3711 64.3967 12.7771 64.3967 13.3001Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M49.5178 19.3574V4.90648H52.8749V19.3574H49.5178ZM56.4471 19.3574V14.0725H59.8042V19.3574H56.4471ZM56.4471 14.0725C56.4471 13.522 56.3826 13.0884 56.2534 12.7719C56.1243 12.4416 55.9378 12.2007 55.6939 12.0493C55.45 11.898 55.1631 11.8154 54.8331 11.8016C54.1876 11.7879 53.6998 11.9599 53.3698 12.3177C53.0398 12.6618 52.8749 13.1917 52.8749 13.9073H51.8634C51.8634 12.8201 52.0356 11.898 52.3799 11.141C52.7242 10.3703 53.1905 9.78537 53.7787 9.38626C54.3812 8.98714 55.0699 8.78758 55.8446 8.78758C56.7197 8.78758 57.4514 8.95961 58.0396 9.30368C58.6278 9.63398 59.0653 10.1432 59.3523 10.8313C59.6536 11.5195 59.8042 12.4003 59.8042 13.4738V14.0725H56.4471Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M41.5252 19.603C40.0332 19.603 38.7133 19.2864 37.5656 18.6533C36.4322 18.0065 35.5355 17.1257 34.8756 16.0109C34.23 14.8823 33.9072 13.5886 33.9072 12.1298C33.9072 10.6709 34.23 9.38412 34.8756 8.26934C35.5355 7.14079 36.4322 6.25998 37.5656 5.62689C38.7133 4.98004 40.0332 4.65662 41.5252 4.65662C42.6155 4.65662 43.6126 4.84241 44.5164 5.21401C45.4346 5.57184 46.2237 6.08106 46.8836 6.74167C47.5579 7.40228 48.0743 8.16612 48.433 9.03317L45.1835 10.086C44.9683 9.60432 44.6742 9.18456 44.3012 8.82673C43.9426 8.46889 43.5265 8.19364 43.0531 8.00096C42.594 7.80828 42.0847 7.71194 41.5252 7.71194C40.7361 7.71194 40.0332 7.89774 39.4163 8.26934C38.7994 8.64093 38.3188 9.16391 37.9744 9.83829C37.6301 10.4989 37.458 11.2627 37.458 12.1298C37.458 12.9968 37.6301 13.7676 37.9744 14.4419C38.3331 15.1025 38.8209 15.6255 39.4378 16.0109C40.069 16.3825 40.7864 16.5683 41.5898 16.5683C42.178 16.5683 42.7016 16.465 43.1607 16.2586C43.6198 16.0522 44.0143 15.7631 44.3443 15.3916C44.6886 15.02 44.9827 14.5933 45.2266 14.1116L48.476 15.1645C48.1317 16.0453 47.6224 16.8229 46.9482 17.4973C46.2739 18.1579 45.4776 18.674 44.5595 19.0456C43.6413 19.4172 42.6299 19.603 41.5252 19.603Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M27.7082 8.73822L19.5301 0.892791C18.2892 -0.297597 16.2775 -0.297597 15.0366 0.892791L11.3519 4.42758L12.7298 5.74945C13.9707 6.93983 15.9824 6.93983 17.2233 5.74945L17.2832 5.69194L17.7325 6.12294C17.9808 6.3611 17.9808 6.74699 17.7325 6.98515L14.0029 10.563C13.235 11.1993 12.639 11.5799 11.7455 11.8144C10.6003 12.1149 10.115 11.8532 9.99239 11.8024L12.7296 14.4282C13.9705 15.6186 15.9822 15.6186 17.2231 14.4282L21.3571 10.4624C21.6053 10.2243 22.0076 10.2243 22.2558 10.4624C22.5041 10.7006 22.5041 11.0865 22.2558 11.3246L14.0027 19.242C13.2348 19.8782 12.6387 20.2589 11.7453 20.4934C10.6001 20.7939 10.1148 20.5322 9.99219 20.4814L12.7294 23.1072C13.9703 24.2976 15.982 24.2976 17.2229 23.1072L27.7076 13.0491C28.9485 11.8587 28.9485 9.92881 27.7076 8.73842L27.7082 8.73822Z"
        fill="#1D2E43"
      ></path>
      <path
        d="M13.684 16.1539C12.7905 15.9194 12.1944 15.539 11.4266 14.9025L6.38427 10.0654C6.13601 9.82724 6.13601 9.44135 6.38427 9.20319C6.63253 8.96503 7.03479 8.96503 7.28305 9.20319L8.20619 10.0888C9.44706 11.2792 11.4588 11.2792 12.6996 10.0888L15.4369 7.46293C15.3142 7.51373 14.8287 7.77546 13.6838 7.47492C12.7903 7.24041 12.1942 6.86001 11.4264 6.22356L9.07637 3.96918C7.83486 2.77818 5.82187 2.779 4.58142 3.9706L0.929123 7.47898C-0.31027 8.66957 -0.309638 10.5984 0.930602 11.7882L8.20619 18.7678C9.44706 19.9581 11.4588 19.9581 12.6996 18.7678L15.4369 16.1419C15.3142 16.1927 14.8287 16.4544 13.6838 16.1539H13.684Z"
        fill="#E02419"
      ></path>
    </svg>
  )
}

export default Logo
