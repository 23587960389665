import { Form } from 'components'
import { CampaignContent, FormFieldType, ModalWindow } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { setCampaignContent } from 'slices/campaignContent'
import { closeModal } from 'slices/modal'

const CampaignContentWeb = ({
  args: { content },
  modalWindow
}: {
  args: { content: CampaignContent }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()

  const campaignContentWebFieldsData = {
    title: content.subject || '',
    description: content.preview_text || ''
  }

  const submit = (values: { title: string; description: string }) => {
    dispatch(
      setCampaignContent({
        ...content,
        subject: values.title,
        preview_text: values.description
      })
    )
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Edit Web Page Details" />
      <Form
        fields={campaignContentWebFields}
        data={campaignContentWebFieldsData}
        isLoading={false}
        submit={submit}
        submitText="Save"
      />
    </>
  )
}

const campaignContentWebFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'title',
    label: 'Title',
    placeholder: 'Title',
    parenthetical: 'Max 190 characters',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    parenthetical: 'Max 190 characters'
  }
]

export default CampaignContentWeb
