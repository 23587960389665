import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { ItemTags } from 'types'
import { showNotification } from 'slices/notification'

export interface ItemTagsState {
  data: ItemTags
  loading: RequestStatus
  error: RequestError
}

const initialState: ItemTagsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchItemTags = createAsyncThunk<
  ItemTags,
  string | number,
  { state: RootState; rejectValue: RequestError }
>('itemTags/fetchItemTags', async (itemId, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const endpoint = `items/${itemId}/tags`
    const resp = (await api?.request(endpoint)) as ItemTags
    return resp
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const createItemTags = createAsyncThunk<
  ItemTags,
  { itemId: string | number; data: ItemTags },
  { state: RootState; rejectValue: RequestError }
>(
  'itemTags/createItemTags',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/tags`
      const resp = (await api?.request(endpoint, 'POST', data)) as ItemTags
      dispatch(showNotification('Tags successfully added!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateItemTags = createAsyncThunk<
  ItemTags,
  { itemId: string | number; data: ItemTags },
  { state: RootState; rejectValue: RequestError }
>(
  'itemTags/updateItemTags',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/tags`
      const resp = (await api?.request(endpoint, 'PUT', data)) as ItemTags
      dispatch(showNotification('Tags successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteItemTags = createAsyncThunk<
  void,
  { itemId: string | number; data: ItemTags },
  { state: RootState; rejectValue: RequestError }
>(
  'itemTags/deleteItemTags',
  async ({ itemId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `items/${itemId}/tags`
      await api?.request(endpoint, 'DELETE', data)
      dispatch(showNotification('Tags successfully deleted!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const itemTagsSlice = createSlice({
  name: 'itemTags',
  initialState,
  reducers: {
    resetItemTags: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchItemTags.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchItemTags.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchItemTags.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createItemTags.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createItemTags.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(createItemTags.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateItemTags.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateItemTags.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateItemTags.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteItemTags.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteItemTags.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deleteItemTags.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetItemTags } = itemTagsSlice.actions

export const selectItemTags = (state: RootState) => state.itemTags

export default itemTagsSlice.reducer
