import styled from '@emotion/styled'

export const ChannelTimeSelectContainer = styled.div`
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props => props.theme.colors.border.primary};
  padding: 3rem 1.5rem 0rem 1.5rem;

  label > span:first-of-type {
    display: none;
  }
`

export const ChannelTimeSelectHeader = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
  gap: 0.5rem;
`

export const ChannelTimeSelectStatusContainer = styled.div`
  margin-left: 1.5rem;
`
