import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { ConfirmationEmail } from 'types'
import { showNotification } from 'slices/notification'
import { orderTypeConvertMap } from 'config'

export interface ConfirmationEmailState {
  data: ConfirmationEmail | null
  loading: RequestStatus
  error: RequestError
}

const initialState: ConfirmationEmailState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchConfirmationEmail = createAsyncThunk<
  ConfirmationEmail,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'confirmationEmail/fetchConfirmationEmail',
  async (confirmationEmailId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `confirmation-emails/${confirmationEmailId}`
      const resp = (await api?.request(endpoint)) as ConfirmationEmail
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createConfirmationEmail = createAsyncThunk<
  ConfirmationEmail,
  { data: ConfirmationEmail; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'confirmationEmail/createConfirmationEmail',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'confirmation-emails',
        'POST',
        data
      )) as ConfirmationEmail
      dispatch(showNotification('Confirmation email successfully created!'))
      const orderType = orderTypeConvertMap[data.order_type]
      const pathname = `/settings/comms/emails/${orderType.toLowerCase()}/${
        resp.confirmation_email_id
      }`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateConfirmationEmail = createAsyncThunk<
  ConfirmationEmail,
  {
    confirmationEmailId: string | number
    data: ConfirmationEmail
  },
  { state: RootState; rejectValue: RequestError }
>(
  'confirmationEmail/updateConfirmationEmail',
  async (
    { confirmationEmailId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `confirmation-emails/${confirmationEmailId}`,
        'PUT',
        data
      )) as ConfirmationEmail
      dispatch(showNotification('Confirmation email successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteConfirmationEmail = createAsyncThunk<
  void,
  {
    confirmationEmailId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'confirmationEmail/deleteConfirmationEmail',
  async (
    { confirmationEmailId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `confirmation-emails/${confirmationEmailId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Confirmation email removed!'))
      navigate({ pathname: '/settings/confirmation-emails' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const confirmationEmailSlice = createSlice({
  name: 'confirmationEmail',
  initialState,
  reducers: {
    resetConfirmationEmail: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchConfirmationEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchConfirmationEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchConfirmationEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createConfirmationEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createConfirmationEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createConfirmationEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateConfirmationEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateConfirmationEmail.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateConfirmationEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteConfirmationEmail.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteConfirmationEmail.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteConfirmationEmail.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetConfirmationEmail } = confirmationEmailSlice.actions

export const selectConfirmationEmail = (state: RootState) =>
  state.confirmationEmail

export default confirmationEmailSlice.reducer
