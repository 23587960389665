import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { AuthToken, UserBrand, UserBrands, AuthUser, Section } from 'types'
import AdminAPI, { RequestStatus, RequestError } from 'utils/api'
import { FilesApi } from './files'
import { stateKey } from 'config'
import { setLocalStorageItem } from 'utils/localStorage'

export interface authUserState {
  user: AuthUser | null
  brands: UserBrands
  brand: UserBrand | null
  logo: string | null
  logoLight: string | null
  icon: string | null
  api: AdminAPI | null
  loading: RequestStatus
  error: RequestError
}

export interface ApiUser {
  api: AdminAPI
  user: {
    user_id: number
    is_super: boolean
    email: string
    first_name: string
    last_name: string
  }
  brands: UserBrand[]
}

const initialState: authUserState = {
  user: null,
  brands: [],
  brand: null,
  logo: null,
  logoLight: null,
  icon: null,
  api: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAuthUser = createAsyncThunk<
  ApiUser,
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'authUser/fetchAuthUser',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const token = getState().auth.token as AuthToken
      const api = new AdminAPI({ token, dispatch })
      const user = (await api.request('me')) as ApiUser
      return { ...user, api }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const refreshAuthUser = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: RequestError }
>('authUser/refreshAuthUser', async (token, { getState, rejectWithValue }) => {
  try {
    const { notifications, modal, authUser, auth, ...rest } = getState()
    const saveableState = {
      ...rest,
      authUser: { ...authUser, api: null },
      auth: { ...auth, token }
    }
    setLocalStorageItem(JSON.stringify(saveableState), stateKey)
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const fetchBrandLogo = createAsyncThunk<
  { logo: string | null; logoLight: string | null; icon: string | null },
  void,
  { state: RootState; rejectValue: RequestError }
>('authUser/fetchBrandLogo', async (_, { getState, rejectWithValue }) => {
  try {
    const api = getState().authUser.api
    const { data } = (await api?.request('website/files', 'GET')) as FilesApi
    const icon =
      data.find(i => i.file_type === 'APPLE_TOUCH_ICON')?.file_url || null
    const logo = data.find(i => i.file_type === 'LOGO')?.file_url || null
    const logoLight =
      data.find(i => i.file_type === 'LOGO_LIGHT')?.file_url || null
    return { icon, logo, logoLight }
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    resetUser: () => initialState,
    setApi: (state: authUserState, { payload }) => {
      state.api = new AdminAPI({ ...payload })
    },
    setBrand: (state: authUserState, { payload }) => {
      state.brand = payload
      const token = state.api?.token || null
      const dispatch = state.api?.dispatch
      const brandId = payload.brand_id
      state.api = new AdminAPI({ token, dispatch, brandId })
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAuthUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAuthUser.fulfilled, (state, { payload }) => {
      state.api = payload.api
      state.user = payload.user
      state.brands = payload.brands
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchAuthUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload || null
    })
    builder.addCase(refreshAuthUser.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(refreshAuthUser.fulfilled, state => {
      window.location.reload()
    })
    builder.addCase(refreshAuthUser.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload || null
    })
    builder.addCase(fetchBrandLogo.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBrandLogo.fulfilled, (state, { payload }) => {
      state.icon = payload.icon
      state.logo = payload.logo
      state.logoLight = payload.logoLight
      state.loading = RequestStatus.Idle
    })
    builder.addCase(fetchBrandLogo.rejected, (state, { payload }) => {
      state.error = payload || null
      state.loading = RequestStatus.Idle
    })
  }
})

export const { resetUser, setApi, setBrand } = authUserSlice.actions

export const selectAuthUser = (state: RootState) => state.authUser

export const selectIsSuper = (state: RootState) => {
  const { user } = state.authUser
  return !!user?.is_super
}

export const selectUserSections = (state: RootState) =>
  state.authUser.brand?.sections ?? null

export const selectCanAccess =
  (section: Section | null) => (state: RootState) => {
    if (!section) return true
    const { sections } = state.authUser.brand || {}
    if (!sections) return false
    if (sections.length === 0 || sections.includes(section)) {
      return true
    }
    return false
  }

export const selectBrand = (state: RootState) => state.authUser.brand
export const selectLogo = (state: RootState) => state.authUser.logo
export const selectApi = (state: RootState) => state.authUser.api

export default authUserSlice.reducer
