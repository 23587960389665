import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { PosTerminals } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface PosTerminalsState {
  data: PosTerminals
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface PosTerminalsAPI {
  data: PosTerminals
  links: {
    next: string
  }
}

const initialState: PosTerminalsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type PosTerminalsParams = {
  cursor?: string
  is_active?: boolean
  limit?: number
  revenue_center_id?: number
  search?: string
  with_related?: string
}

export const fetchPosTerminals = createAsyncThunk<
  PosTerminalsAPI,
  PosTerminalsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'posTerminals/fetchPosTerminals',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `pos-v2/pos-terminals${queryParams}`
      return (await api?.request(endpoint)) as PosTerminalsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posTerminalsSlice = createSlice({
  name: 'posTerminals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPosTerminals.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPosTerminals.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchPosTerminals.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectPosTerminals = (state: RootState) => state.posTerminals

export default posTerminalsSlice.reducer
