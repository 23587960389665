import { CloudUpload } from 'iconoir-react'
import TextBody from 'components/TextBody'
import FileProgress from './FileProgress'
import {
  ImageElement,
  ImageFileUpload,
  ImageFileUploadCover,
  ImageUploadIcon,
  ImageView
} from './Files.styled'

interface ImageProps {
  file?: {
    file_id: string
    file_type: string
    file_url: string
  }
  progState: string
  progPercent: number
  isProcessing: boolean
}

const Image = ({ file, progPercent, isProcessing }: ImageProps) => {
  const hasFile = !!file
  return (
    <ImageView hasFile={!!file} isProcessing={isProcessing}>
      <ImageFileUpload isProcessing={isProcessing} hasFile={hasFile}>
        <ImageUploadIcon>
          <CloudUpload width="4rem" height="4rem" />
        </ImageUploadIcon>
        <TextBody size="small">
          Drag & drop a file or click to browse your computer
        </TextBody>
        <ImageFileUploadCover />
      </ImageFileUpload>
      {isProcessing && <FileProgress progPercent={progPercent} />}
      {file ? <ImageElement alt={file.file_type} src={file.file_url} /> : null}
    </ImageView>
  )
}

export default Image
