import React from 'react'
import { TableTotalsView, TableView } from './Table.styled'

const TableTotals = ({ data }: { data: (string | React.ReactNode)[] }) => {
  return (
    <TableTotalsView>
      <TableView size="small" className="table-view-totals">
        <tbody>
          <tr>
            {data.map((value, index) => (
              <td key={index}>
                <strong>{value}</strong>
              </td>
            ))}
          </tr>
        </tbody>
      </TableView>
    </TableTotalsView>
  )
}

export default React.memo(TableTotals)
