import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { showNotification } from 'slices/notification'

export interface PosState {
  loading: RequestStatus
  error: RequestError
}

const initialState: PosState = {
  loading: RequestStatus.Idle,
  error: null
}

export const releaseLock = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: RequestError }
>(
  'releaseRedisLock/releaseLock',
  async (lockName, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request('super/release-redis-lock', 'POST', {
        lock_name: lockName
      })
      dispatch(showNotification('Redis lock released!'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const releaseRedisLockSlice = createSlice({
  name: 'releaseRedisLock',
  initialState,
  reducers: {
    resetReleaseRedisLock: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(releaseLock.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(releaseLock.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(releaseLock.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetReleaseRedisLock } = releaseRedisLockSlice.actions

export const selectReleaseRedisLock = (state: RootState) =>
  state.releaseRedisLock

export default releaseRedisLockSlice.reducer
