import styled from '@emotion/styled'

export const TextAreaView = styled.textarea`
  width: 100%;
  outline: none;
  background-color: inherit;
  border-style: solid;
  border-width: ${props => props.theme.inputs.borderWidth};
  border-radius: ${props => props.theme.inputs.borderRadius};
  font-size: ${props => props.theme.inputs.fontSize};
  font-family: ${props => props.theme.inputs.fontFamily};
  font-weight: ${props => props.theme.inputs.fontWeight};
  letter-spacing: ${props => props.theme.inputs.letterSpacing};
  transition: ${props => props.theme.transition};
  padding: ${props => props.theme.inputs.padding};
  line-height: 1.4; // different than input
  height: 7em;

  background-color: ${props => props.theme.inputs.backgroundColor};

  color: ${props => props.theme.colors.text.primary};
  border-color: ${props => props.theme.colors.border.primary};

  &:active,
  &:focus {
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.text.primary};
  }

  &:disabled,
  &:read-only {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.colors.text.primary};
  }

  &::placeholder {
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.border.primary};
    opacity: 1;
  }

  &::selection {
    color: ${props => props.theme.colors.background.primary};
    background-color: ${props => props.theme.colors.text.primary};
  }
`
