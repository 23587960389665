import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import { Button, ErrMsg } from 'components'
import { closeModal } from 'slices/modal'
import { cancelOrder, selectOrderMgmt } from 'slices/orderMgmt'
import { useEffect, useState } from 'react'
import { RequestStatus } from 'utils'

const OrderMgmtCancel = ({ args }: { args: { orderId: number } }) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectOrderMgmt)
  const isLoading = loading === RequestStatus.Pending
  const [submitted, setSubmitted] = useState(false)
  const [errMsg, setErrMsg] = useState<string | null>(null)

  const cancel = () => {
    setSubmitted(true)
    setErrMsg(null)
    dispatch(cancelOrder(args.orderId))
  }

  useEffect(() => {
    if (submitted && !isLoading) {
      setSubmitted(false)
      if (error) {
        setErrMsg(error.detail)
      } else {
        dispatch(closeModal())
      }
    }
  }, [dispatch, submitted, isLoading, error])

  return (
    <>
      <ModalHeader title={`Cancel Order #${args.orderId}`} />
      <ErrMsg errMsg={errMsg} />
      <ModalContent>
        <p>Are you sure you want to cancel this order?</p>
        <p>This will cancel this order forever. There's no going back.</p>
      </ModalContent>
      <ModalFooter>
        <Button color="delete" onClick={cancel} disabled={isLoading}>
          {isLoading ? 'Cancelling Order...' : 'Cancel Order'}
        </Button>
        <Button
          color="secondary"
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
        >
          Nevermind
        </Button>
      </ModalFooter>
    </>
  )
}

export default OrderMgmtCancel
