import { Link } from 'react-router-dom'
import {
  formatDollars,
  getOrderStatusColor,
  replaceAmPm,
  zonedDateStrToDate,
  zonedDateStrToDateStr
} from 'utils'
import { BulkOrder } from 'types'
import { Badge, Highlight, TextBody } from 'components'
import TextHeading from 'components/TextHeading'
import OrderCardAddress from './OrderCardAddress'
import OrderCardTender from './OrderCardTender'
import {
  OrderCardAlert,
  OrderCardBanner,
  OrderCardContainer,
  OrderCardContent,
  OrderCardCustomer,
  OrderCardFooter,
  OrderCardHeader,
  OrderCardHighlight,
  OrderCardOrderType,
  OrderCardRow,
  OrderCardStatus,
  OrderCardTaxExempt,
  OrderCardTenders,
  OrderCardTitle,
  OrderCardView
} from './OrderCard.styled'
import OrderCardButtons from './OrderCardButtons'
import {
  orderTypeOptions,
  prepStatusOptionsSimple,
  serviceTypeOptions
} from 'config'
import { useState } from 'react'
import { useAppDispatch } from 'app/hooks'
import { markReviewed } from 'slices/orderMgmt'
import { WarningTriangle } from 'iconoir-react'

const OrderCard = ({ order }: { order: BulkOrder }) => {
  const dispatch = useAppDispatch()
  const {
    receipt_id: orderId,
    order_type,
    service_type,
    prep_status,
    receipt_status,
    requested_at,
    timezone,
    customer,
    customer_address,
    items = [],
    tenders = [],
    total,
    deposit,
    is_future,
    is_reviewed,
    is_tax_exempt
  } = order
  const isAsap = !is_future
  const currentDt = new Date()
  const requestedDt = zonedDateStrToDate(requested_at, timezone)
  const isClosed = receipt_status === 'CLOSED'
  const isOverdue = currentDt > requestedDt && !isClosed
  const requestedAt = replaceAmPm(
    zonedDateStrToDateStr(requested_at, timezone, "EEE, MMM dd 'at' h:mma")
  )
  const orderType =
    orderTypeOptions.find(i => i.value === order_type)?.text || ''
  const serviceType =
    serviceTypeOptions.find(i => i.value === service_type)?.text || ''
  const prepStatus =
    prepStatusOptionsSimple.find(i => i.value === prep_status)?.text || ''
  const statusColor = getOrderStatusColor(receipt_status)
  const { first_name, last_name, phone } = customer || {}
  const name = customer ? `${first_name} ${last_name}` : 'No customer'
  const cartCount = items ? items.reduce((t, i) => (t += i.quantity), 0) : null
  const [needsReview, setNeedsReview] = useState(
    order_type === 'CATERING' && !is_reviewed
  )
  const tendersTotal =
    tenders?.reduce((t, i) => (t += parseFloat(i.amount)), 0.0) || 0.0
  const amountDue = Math.round(parseFloat(total) - tendersTotal)
  const hasDeposit = deposit && amountDue > 0

  const review = () => {
    setNeedsReview(false)
    dispatch(markReviewed(orderId))
  }

  return (
    <OrderCardView isClosed={isClosed} isOverdue={isOverdue}>
      <OrderCardBanner orderType={order_type} needsReview={needsReview} />
      {/* {isOverdue && <OrderCardBackground />} */}
      <OrderCardContainer
        isClosed={isClosed}
        isOverdue={isOverdue}
        needsReview={needsReview}
      >
        <OrderCardHeader>
          <OrderCardTitle isOverdue={isOverdue} needsReview={needsReview}>
            <TextHeading size="large">
              <Link to={`/orders/${orderId}`}>#{orderId}</Link>
            </TextHeading>
            {/* {isOverdue && (
              <OrderCardOverdue size="small">
                <strong>Overdue</strong>
              </OrderCardOverdue>
            )} */}
            {isOverdue && <Highlight color="default">OVERDUE</Highlight>}
            {needsReview && (
              <OrderCardAlert>
                <WarningTriangle width={18} height={18} />
              </OrderCardAlert>
            )}
            {/* {needsReview && <Highlight color="error">UPDATED</Highlight>} */}
          </OrderCardTitle>
          <OrderCardStatus>
            <TextBody size="small">{prepStatus}</TextBody>
            <OrderCardHighlight>
              <Badge
                color={statusColor}
                style={{ padding: '0.3rem 0.5rem 0.2rem' }}
              >
                {receipt_status}
              </Badge>
            </OrderCardHighlight>
          </OrderCardStatus>
        </OrderCardHeader>
        <OrderCardContent>
          <OrderCardRow>
            <OrderCardCustomer>
              <TextHeading size="small">
                <strong>{name}</strong>
              </TextHeading>
              <TextBody size="small">{phone}</TextBody>
            </OrderCardCustomer>
            <OrderCardOrderType>
              {/* <TextBody size="small">
                <OrderCardOrderTypeName orderType={order_type}>
                  <strong>{orderTypeName}</strong>
                </OrderCardOrderTypeName>{' '}
                /{' '}
                <OrderCardServiceTypeName serviceType={service_type}>
                  <strong>{serviceTypeName}</strong>
                </OrderCardServiceTypeName>
              </TextBody> */}
              <TextBody size="small">
                <strong>
                  {orderType} / {serviceType}
                </strong>
              </TextBody>
              <TextHeading size="small">
                {isAsap && (
                  <>
                    <strong>ASAP</strong>
                    <span> - </span>
                  </>
                )}
                {requestedAt}
              </TextHeading>
            </OrderCardOrderType>
          </OrderCardRow>
          <OrderCardRow>
            <OrderCardAddress address={customer_address} />
          </OrderCardRow>
        </OrderCardContent>
        <OrderCardFooter>
          <OrderCardRow style={{ alignItems: 'flex-end' }}>
            <OrderCardCustomer style={{ gap: 5 }}>
              {is_tax_exempt && (
                <OrderCardTaxExempt size="small">
                  <strong>TAX EXEMPT</strong>
                </OrderCardTaxExempt>
              )}
              {hasDeposit && (
                <OrderCardTaxExempt size="small">
                  <strong>{formatDollars(deposit)} Deposit</strong>
                </OrderCardTaxExempt>
              )}
              <div>
                <TextBody size="small">
                  <strong>{formatDollars(total)}</strong>
                </TextBody>
                {cartCount && (
                  <TextBody size="small"> - {cartCount} items</TextBody>
                )}
              </div>
            </OrderCardCustomer>
            <OrderCardTenders>
              {tenders.map(tender => (
                <OrderCardTender key={tender.tender_id} tender={tender} />
              ))}
            </OrderCardTenders>
          </OrderCardRow>
          <OrderCardButtons
            order={order}
            review={needsReview ? review : null}
          />
        </OrderCardFooter>
      </OrderCardContainer>
    </OrderCardView>
  )
}

export default OrderCard
