import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Printer } from 'types'
import { showNotification } from 'slices/notification'
import { fetchPrinters } from 'slices/printers'

export interface PrinterState {
  data: Printer | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PrinterState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type PrinterParams = {
  expand?: string
  with_related?: string
}

export const fetchPrinter = createAsyncThunk<
  Printer | null,
  { printerId: string | number; params?: PrinterParams },
  { state: RootState; rejectValue: RequestError }
>(
  'printer/fetchPrinter',
  async ({ printerId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/pos-printers/${printerId}${queryParams}`
      )) as Printer
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createPrinter = createAsyncThunk<
  Printer,
  { data: Printer },
  { state: RootState; rejectValue: RequestError }
>(
  'printer/createPrinter',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/pos-printers',
        'POST',
        data
      )) as Printer
      dispatch(showNotification('Printer successfully created!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPrinters({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePrinter = createAsyncThunk<
  Printer,
  { printerId: string | number; data: Printer },
  { state: RootState; rejectValue: RequestError }
>(
  'printer/updatePrinter',
  async ({ printerId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/pos-printers/${printerId}`,
        'PUT',
        data
      )) as Printer
      dispatch(showNotification('Printer successfully updated!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPrinters({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deletePrinter = createAsyncThunk<
  void,
  Printer,
  { state: RootState; rejectValue: RequestError }
>(
  'printer/deletePrinter',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(
        `pos-v2/pos-printers/${data.pos_printer_v2_id}`,
        'DELETE'
      )
      dispatch(showNotification('Printer successfully deleted!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPrinters({ revenue_center_id }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const printerSlice = createSlice({
  name: 'printer',
  initialState,
  reducers: {
    resetPrinter: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPrinter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPrinter.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchPrinter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createPrinter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createPrinter.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createPrinter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePrinter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updatePrinter.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updatePrinter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deletePrinter.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deletePrinter.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deletePrinter.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPrinter } = printerSlice.actions

export const selectPrinter = (state: RootState) => state.printer

export default printerSlice.reducer
