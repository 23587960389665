import styled from '@emotion/styled'

export const FiltersView = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  width: 100%;
`

export const FiltersItem = styled.div`
  flex: 0 1 20rem;
  padding: 0 1rem 2rem;
  @media (max-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    max-width: 50%;
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    max-width: 50%;
  }

  label {
    margin: 0;
  }

  input,
  select {
    padding: 0.8rem 1rem;
    font-size: ${props => props.theme.sizes.small};
    text-align: left;
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  select + span {
    width: 3.6rem;
  }

  input {
    padding-right: 2.5rem;
  }
`
