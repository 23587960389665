import styled from '@emotion/styled'
import React from 'react'
import { NavLink } from 'react-router-dom'
import TextBody from './TextBody'

const PageCrumbsView = styled.div`
  position: sticky;
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.background.primary};
  height: ${props => props.theme.layout.desktop.header.height};
  padding: 0 ${props => props.theme.layout.desktop.padding};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    height: ${props => props.theme.layout.mobile.header.height};
    padding: 0 ${props => props.theme.layout.mobile.padding};
    padding-left: 6rem; // width of hamburger menu
    flex-wrap: wrap;
    align-content: center;
  }
`

const PageCrumbsLink = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 0.2rem 0;
  }

  a {
    display: block;
    opacity: 1;
  }

  a:hover,
  a:active {
    opacity: 0.5;
  }

  span {
    transition: all 0.15s ease;
  }
`

const PageCrumbsSeparator = styled.div`
  line-height: 1;
  padding: 0 1rem;
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 0 0.5rem;
  }

  & > span {
    color: ${props => props.theme.colors.border.secondary};
  }
`

const PageCrumbsTitle = styled(TextBody)`
  font-weight: 700;
  font-variation-settings: 'wght' 700;
`

interface PageCrumbsLinkProps {
  title: string
  path: string
}

interface PageCrumbsProps {
  links: PageCrumbsLinkProps[]
  title?: string | null
}

const PageCrumbs = ({ links, title }: PageCrumbsProps) => {
  return (
    <PageCrumbsView>
      {links.map((link, index) => {
        const current = index === links.length - 1
        return (
          <React.Fragment key={`${link.path}-${index}`}>
            <PageCrumbsLink>
              <NavLink to={link.path}>
                <TextBody size="small">{link.title}</TextBody>
              </NavLink>
            </PageCrumbsLink>
            {!current || title ? (
              <PageCrumbsSeparator>
                <TextBody size="small">/</TextBody>
              </PageCrumbsSeparator>
            ) : null}
          </React.Fragment>
        )
      })}
      {title && (
        <PageCrumbsLink>
          <PageCrumbsTitle size="small">{title}</PageCrumbsTitle>
        </PageCrumbsLink>
      )}
    </PageCrumbsView>
  )
}

export default PageCrumbs
