import styled from '@emotion/styled'
import { ThemeColor, ThemeElementColors, ThemeFontSizes } from 'types'

interface TextBodyProps {
  size?: keyof ThemeFontSizes
  color?: keyof ThemeColor
  variant?: keyof ThemeElementColors
}

const TextBody = styled.span<TextBodyProps>`
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: ${props => props.theme.sizes[props.size || 'medium']};
  color: ${props => {
    if (props.variant) return props.theme.colors[props.variant].background
    return props.theme.colors.text[props.color || 'secondary']
  }};
  font-family: 'Figtree', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  line-height: 1;
`

export default TextBody
