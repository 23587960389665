import { useCallback, useEffect, useRef, useState } from 'react'
import configs from 'config/enviroment'
import { Coordinates, LatLng } from 'types'

const useGoogleMap = (
  color: string,
  mapStyles: any,
  address: string,
  isDrawnFromRadius: boolean,
  zone: Coordinates,
  updateZone: (lat: number, lng: number) => void,
  resetZone: () => void
) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const markers = useRef<google.maps.Marker[]>([])
  const polygon = useRef<google.maps.Polygon | null>(null)
  const polyline = useRef<google.maps.Polyline | null>(null)
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null)
  const [editMode, setEditMode] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const apiKey = configs.googleMapsApiKey
  const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=drawing&callback=Function.prototype`

  const geocode = (address: string): Promise<LatLng> => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results) {
            resolve({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            })
          } else {
            const msg = `Geocode of the revenue center's address did not return a result for some reason. Please check your location address info on the Settings page and try again.`
            reject(msg)
          }
        } else {
          const msg = `Geocode of the revenue center's address was not successful for the following reason: ${status}. Please check your location address info on the Settings page and try again.`
          reject(msg)
        }
      })
    })
  }

  const createGoogleMap = useCallback(
    (latLng: LatLng) => {
      if (!mapRef.current) return
      const { lat, lng } = latLng
      var latlng = new window.google.maps.LatLng(lat, lng)
      const googleMap = new window.google.maps.Map(mapRef.current, {
        zoom: 15,
        center: latlng,
        scrollwheel: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        controlSize: 30,
        draggableCursor: 'crosshair',
        styles: mapStyles
      })
      setGoogleMap(googleMap)
      // add map pin for restaurant location
      new window.google.maps.Marker({
        map: googleMap,
        position: latLng
      })
    },
    [mapStyles]
  )

  useEffect(() => {
    if (!address) return
    let googleMapScript = document.createElement('script')
    googleMapScript.setAttribute('id', 'google-map-script')
    googleMapScript.src = scriptSrc
    window.document.body.appendChild(googleMapScript)
    googleMapScript.addEventListener('load', () => {
      geocode(address)
        .then(latLng => createGoogleMap(latLng))
        .catch(err => setError(err))
    })
    return () => {
      googleMapScript.remove()
    }
  }, [scriptSrc, address, createGoogleMap])

  const addPoint = useCallback(
    (evt: google.maps.MapMouseEvent) => {
      if (!editMode || !polyline.current) return
      let path = polyline.current.getPath()
      if (evt.latLng) {
        path.push(evt.latLng)
        const marker = new window.google.maps.Marker({
          position: evt.latLng,
          title: '#' + path.getLength(),
          map: googleMap,
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 2,
            fillOpacity: 1,
            fillColor: color,
            strokeColor: color
          }
        })
        markers.current.push(marker)
        updateZone(evt.latLng.lat(), evt.latLng.lng())
      }
    },
    [googleMap, editMode, color, updateZone]
  )

  useEffect(() => {
    if (googleMap) {
      window.google.maps.event.addListener(googleMap, 'click', addPoint)
    }
  }, [googleMap, addPoint])

  const drawZone = useCallback(
    (zone: Coordinates) => {
      if (googleMap) {
        const polygonOptions: google.maps.PolygonOptions = {
          strokeColor: color,
          strokeOpacity: 1.0,
          strokeWeight: 1,
          fillColor: color,
          fillOpacity: 0.25
        }
        const points = zone.map(latlng => {
          return new window.google.maps.LatLng(latlng[0], latlng[1])
        })
        polygonOptions.paths = points
        const latLngBounds = new window.google.maps.LatLngBounds()
        points.map(latLng => latLngBounds.extend(latLng))
        googleMap.fitBounds(latLngBounds)
        polygon.current = new window.google.maps.Polygon(polygonOptions)
        polygon.current.setMap(googleMap)
      }
    },
    [googleMap, color]
  )

  const createPolyline = useCallback(() => {
    const polylineOptions: google.maps.PolylineOptions = {
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    }
    polyline.current = new window.google.maps.Polyline(polylineOptions)
    polyline.current.setMap(googleMap)
  }, [googleMap, color])

  const resetMap = useCallback(() => {
    resetZone()
    markers.current.map(marker => marker.setMap(null))
    markers.current = []
    polygon.current?.getPath().clear()
    if (polyline.current) {
      polyline.current.getPath().clear()
    } else if (googleMap) {
      createPolyline()
    }
    if (mapRef.current) {
      mapRef.current.style.border = `1px solid ${color}`
      setEditMode(true)
    }
  }, [googleMap, resetZone, createPolyline, color])

  useEffect(() => {
    if (!googleMap || (editMode && !isDrawnFromRadius)) return
    if (zone.length) {
      drawZone(zone)
    } else {
      if (mapRef.current) {
        createPolyline()
        mapRef.current.style.border = `1px solid ${color}`
        setEditMode(true)
      }
    }
  }, [
    googleMap,
    zone,
    editMode,
    drawZone,
    color,
    createPolyline,
    isDrawnFromRadius
  ])

  return {
    mapRef,
    resetMap,
    error
  }
}

export default useGoogleMap
