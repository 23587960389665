import { useEffect } from 'react'
import {
  RevenueCenter,
  DateString,
  OrderType,
  ServiceType,
  RequestedAt,
  FirstTime
} from '@open-tender/types'
import { useFirstTimes } from '@open-tender/utils'
import { Button, ButtonLink, Select } from 'components'
import {
  RequestedAtDateTimeAsap,
  RequestedAtDateTimeNevermind,
  RequestedAtDateTimeSelect,
  RequestedAtDateTimeSelects,
  RequestedAtDateTimeView
} from './RequestedAtDateTime.styled'

const RequestedAtDateTime = ({
  firstTime,
  revenueCenter,
  serviceType,
  orderType,
  requestedAt,
  isGroupOrder = false,
  chooseTime,
  cancel
}: {
  firstTime: FirstTime
  revenueCenter: RevenueCenter
  serviceType: ServiceType
  orderType: OrderType
  requestedAt: RequestedAt
  isGroupOrder?: boolean
  chooseTime: (requestedAt: RequestedAt) => void
  cancel: () => void
}) => {
  const {
    date,
    setDate,
    time,
    setTime,
    dateChange,
    setDateChange,
    dateOptions,
    timeOptions,
    firstMinutes,
    requestedTime,
    orderMsg
  } = useFirstTimes(
    firstTime,
    revenueCenter,
    serviceType,
    orderType,
    requestedAt,
    isGroupOrder,
    365
  )

  const dateSelectOptions = dateOptions.map(option => ({
    value: option.value,
    text: option.name
  }))

  const timeSelectOptions = timeOptions.map(option => ({
    value: option.value,
    text: option.name
  }))

  const isClosed = timeOptions.find(i => i.name === 'Closed') ? true : false

  const changeDate = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setDate(evt.target.value as DateString)
    setDateChange(true)
  }

  const changeTime = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value
    const timeValue = value === 'asap' ? value : parseInt(value)
    setTime(timeValue)
  }

  useEffect(() => {
    if (dateChange) {
      if (firstMinutes) setTime(firstMinutes)
      setDateChange(false)
    }
  }, [dateChange, firstMinutes, setTime, setDateChange])

  return (
    <RequestedAtDateTimeView>
      <RequestedAtDateTimeSelects>
        <RequestedAtDateTimeSelect>
          <Select
            label="Order Date"
            name="order-date"
            value={date || ''}
            onChange={changeDate}
            options={dateSelectOptions}
          />
        </RequestedAtDateTimeSelect>
        <RequestedAtDateTimeSelect>
          <Select
            label="Order Time"
            name="order-time"
            value={time || ''}
            onChange={changeTime}
            options={timeSelectOptions}
          />
        </RequestedAtDateTimeSelect>
      </RequestedAtDateTimeSelects>
      <RequestedAtDateTimeAsap>
        <Button
          onClick={() => chooseTime(requestedTime)}
          color="primary"
          disabled={isClosed}
        >
          {orderMsg}
        </Button>
      </RequestedAtDateTimeAsap>
      <RequestedAtDateTimeNevermind>
        <ButtonLink size="small" onClick={cancel}>
          Nevermind, keep current date & time
        </ButtonLink>
      </RequestedAtDateTimeNevermind>
    </RequestedAtDateTimeView>
  )
}

export default RequestedAtDateTime
