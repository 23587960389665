import { RequestError } from '@open-tender/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BackgroundTask, TendersReport } from 'types'
import { RequestStatus, makeQueryParams } from 'utils'

export interface TendersReportState {
  data: TendersReport
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

const initialState: TendersReportState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export interface TendersReportAPI {
  data: TendersReport
  links: {
    next: string
  }
}

export interface TendersReportCSVAPI {
  data: BackgroundTask
  links: {
    next: string
  }
}

export type FetchTendersReportParams = {
  end_date: string
  start_date: string
  store_id?: string
  order_type?: string
  service_type?: string
  receipt_type?: string
  order_source?: string
  tender_type?: string
  card_type?: string
  is_donation?: string
}

export const createTendersReportCSV = createAsyncThunk<
  TendersReportCSVAPI,
  FetchTendersReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'tendersReport/createTendersReportCSV',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/tenders/csv${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint, 'POST')) as TendersReportCSVAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchTendersReport = createAsyncThunk<
  TendersReportAPI,
  FetchTendersReportParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'tendersReport/fetchTendersReport',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `reporting/tenders${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as TendersReportAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const TendersReportSlice = createSlice({
  name: 'tendersReport',
  initialState,
  reducers: {
    resetTendersReport: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchTendersReport.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTendersReport.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchTendersReport.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetTendersReport } = TendersReportSlice.actions

export const selectTendersReport = (state: RootState) => state.tendersReport

export default TendersReportSlice.reducer
