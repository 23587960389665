import styled from '@emotion/styled'
import TextHeading from 'components/TextHeading'

export const OnboardingWelcomeTitle = styled(TextHeading)`
  margin: 0 0 3rem;
`

export const OnboardingWelcomeFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  // & > button {
  //   flex: 1;
  // }
`

export const OnboardingWelcomeButton = styled.div`
  flex: 1;

  &:last-of-type {
    text-align: right;
  }
`

export const OnboardingWelcomePagerView = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const OnboardingWelcomePagerButton = styled.button`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  background-color: ${props => props.theme.colors.text.primary};

  &:enabled:hover {
    background-color: ${props => props.theme.colors.accent.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.accent.primary};
    // opacity: 0.3;
  }
`
