import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { OrderingPlatforms } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface OrderingPlatformsState {
  data: OrderingPlatforms | null
  loading: RequestStatus
  error: RequestError
}

const initialState: OrderingPlatformsState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type OrderingPlatformsParams = {
  active: boolean
}

export const fetchOrderingPlatforms = createAsyncThunk<
  OrderingPlatforms,
  OrderingPlatformsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'orderingPlatforms/fetchOrderingPlatforms',
  async (params, { getState, rejectWithValue }) => {
    try {
      const iacmUser = getState().iacmUser
      const { headers } = iacmUser
      const endpoint =
        'https://staging-api.itsacheckmate.com/token_api/ordering_platforms?active=true'
      const requestHeaders = {
        'Access-Token': `${headers?.accessToken}`,
        Client: `${headers?.client}`,
        Uid: `${headers?.uid}`,
        Expiry: `${headers?.expiry}`
      }
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: requestHeaders
      })
      return (await response.json()) as OrderingPlatforms
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const orderingPlatformsSlice = createSlice({
  name: 'orderingPlatforms',
  initialState,
  reducers: {
    resetOrderingPlatforms: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderingPlatforms.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderingPlatforms.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOrderingPlatforms.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectOrderingPlatformOptions = createSelector(
  (state: RootState) => {
    const { data, loading, error } = state.orderingPlatforms
    return { data, loading, error }
  },
  ({ data }) => {
    if (!data) return []
    const orderingPlatforms = data.map(({ name, display_name }) => ({
      value: name,
      text: display_name
    }))
    return orderingPlatforms
  }
)

export const { resetOrderingPlatforms } = orderingPlatformsSlice.actions

export const selectOrderingPlatforms = (state: RootState) =>
  state.orderingPlatforms

export default orderingPlatformsSlice.reducer
