import { CloudUpload } from 'iconoir-react'
import FileProgress from './FileProgress'
import UploadHeader from './UploadHeader'
import { RawView } from './Files.styled'

interface RawProps {
  progState: string
  progPercent: number
  isProcessing: boolean
}

const Raw = ({ progPercent, isProcessing }: RawProps) => {
  return (
    <RawView>
      <UploadHeader
        icon={<CloudUpload width="2rem" height="2rem" />}
        text="Upload a high resolution image for procesing"
      />
      {isProcessing && <FileProgress progPercent={progPercent} iconSize={20} />}
    </RawView>
  )
}

export default Raw
