import React from 'react'
import { LabelTooltipTrigger, LabelTooltipContent } from './Label.styled'
import { InfoCircle } from 'iconoir-react'
import { isHTML } from 'utils'

interface LabelTooltipProps {
  children: string
}
const LabelTooltip = ({ children }: LabelTooltipProps) => {
  return (
    <>
      <LabelTooltipTrigger>
        <InfoCircle fontSize={11} strokeWidth={2} />
      </LabelTooltipTrigger>
      <LabelTooltipContent className="tooltip__content">
        {isHTML(children) ? (
          <span dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          children
        )}
      </LabelTooltipContent>
    </>
  )
}
export default LabelTooltip
