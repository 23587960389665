import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestStatus } from 'utils'
import { FormFieldType } from 'types'
import { closeModal } from 'slices/modal'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { selectIacm, signInIacmUser } from 'slices/iacmUser'

const IacmLogin = () => {
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)
  const { api, loading, error } = useAppSelector(selectIacm)
  const isLoading = loading === RequestStatus.Pending

  const submit = (values: { email: string; password: string }) => {
    setSubmitting(true)
    dispatch(signInIacmUser(values))
  }

  useEffect(() => {
    if (submitting && !isLoading) {
      setSubmitting(false)
      if (api) {
        dispatch(closeModal())
      }
    }
  }, [submitting, isLoading, api, dispatch])

  return (
    <>
      <ModalHeader
        title="Login to Checkmate"
        subtitle="Please enter your email address and password."
      />
      <Form
        fields={iacmLoginFields}
        data={{ email: '', password: '' }}
        submit={submit}
        required={['email', 'password']}
        error={error}
        isLoading={isLoading}
      />
    </>
  )
}

const iacmLoginFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    label: 'Email',
    placeholder: 'email address',
    name: 'email',
    type: 'email',
    id: 'email',
    error: 'Please enter an email'
  },
  {
    fieldType: 'Input',
    label: 'Password',
    placeholder: 'password',
    name: 'password',
    type: 'password',
    id: 'password',
    error: 'Please enter a password'
  }
]

export default IacmLogin
