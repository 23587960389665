import { TextHeading } from 'components'
import { CheckmateItem, CheckmateModifier, ThemeFontSizes } from 'types'
import { centsToPrice, makeSuspendUntil } from 'utils'
import {
  CheckmateMenuItemDefault,
  CheckmateMenuItemDescription,
  CheckmateMenuItemDetails,
  CheckmateMenuItemSoldOut
} from './CheckmateMenu.styled'

interface CheckmateMenuItemSummaryProps {
  item: CheckmateItem | CheckmateModifier
  titleSize?: keyof ThemeFontSizes
  subtitleSize?: keyof ThemeFontSizes
}

const CheckmateMenuItemSummary = ({
  item,
  titleSize = 'large',
  subtitleSize = 'small'
}: CheckmateMenuItemSummaryProps) => {
  const price = centsToPrice(item.price)
  const { tax_rate = null } = item
  const taxRate = tax_rate !== null ? `${tax_rate.toFixed(3)}%` : null
  const suspendUntilDateStr = makeSuspendUntil(item.suspend_until)

  return (
    <>
      <TextHeading as="p" size={titleSize} style={{ lineHeight: 1.2 }}>
        {item.name} - {price}
        {item.default ? (
          <>
            {' '}
            - <CheckmateMenuItemDefault>default</CheckmateMenuItemDefault>
          </>
        ) : null}
      </TextHeading>
      <CheckmateMenuItemDetails>
        {!!item.description && (
          <CheckmateMenuItemDescription
            as="p"
            color="secondary"
            size={subtitleSize}
          >
            {item.description}
          </CheckmateMenuItemDescription>
        )}
        {/* {!!item.nutritional_info && (
            <CheckmateMenuItemDescription
              as="p"
              color="secondary"
              size={subtitleSize}
            >
              {item.nutritional_info}
            </CheckmateMenuItemDescription>
          )} */}
        {taxRate !== null && (
          <CheckmateMenuItemDescription
            as="p"
            color="secondary"
            size={subtitleSize}
          >
            Tax rate: {taxRate}
          </CheckmateMenuItemDescription>
        )}
        {suspendUntilDateStr ? (
          <CheckmateMenuItemSoldOut as="p" size={subtitleSize}>
            Sold out until {suspendUntilDateStr}
          </CheckmateMenuItemSoldOut>
        ) : null}
        {/* <CheckmateMenuModifiers groups={item.modifier_groups} /> */}
      </CheckmateMenuItemDetails>
    </>
  )
}

export default CheckmateMenuItemSummary
