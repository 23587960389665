import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { PaymentTerminal } from 'types'
import { showNotification } from 'slices/notification'
import { fetchPaymentTerminals } from 'slices/paymentTerminals'

export interface PaymentTerminalState {
  data: PaymentTerminal | null
  loading: RequestStatus
  error: RequestError
}

const initialState: PaymentTerminalState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type PaymentTerminalParams = {
  expand?: string
  with_related?: string
}

export const fetchPaymentTerminal = createAsyncThunk<
  PaymentTerminal | null,
  { paymentTerminalId: string | number; params?: PaymentTerminalParams },
  { state: RootState; rejectValue: RequestError }
>(
  'paymentTerminal/fetchPaymentTerminal',
  async ({ paymentTerminalId, params }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/pos-chipdna-terminals/${paymentTerminalId}${queryParams}`
      )) as PaymentTerminal
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createPaymentTerminal = createAsyncThunk<
  PaymentTerminal,
  { data: PaymentTerminal },
  { state: RootState; rejectValue: RequestError }
>(
  'paymentTerminal/createPaymentTerminal',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/pos-chipdna-terminals',
        'POST',
        data
      )) as PaymentTerminal
      dispatch(showNotification('Payment terminal successfully created!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPaymentTerminals({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updatePaymentTerminal = createAsyncThunk<
  PaymentTerminal,
  { paymentTerminalId: string | number; data: PaymentTerminal },
  { state: RootState; rejectValue: RequestError }
>(
  'paymentTerminal/updatePaymentTerminal',
  async (
    { paymentTerminalId, data },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/pos-chipdna-terminals/${paymentTerminalId}`,
        'PUT',
        data
      )) as PaymentTerminal
      dispatch(showNotification('Payment terminal successfully updated!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPaymentTerminals({ revenue_center_id }))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deletePaymentTerminal = createAsyncThunk<
  void,
  PaymentTerminal,
  { state: RootState; rejectValue: RequestError }
>(
  'paymentTerminal/deletePaymentTerminal',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(
        `pos-v2/pos-chipdna-terminals/${data.pos_chipdna_terminal_id}`,
        'DELETE'
      )
      dispatch(showNotification('Payment terminal successfully deleted!'))
      const revenue_center_id = data.revenue_center_id
      dispatch(fetchPaymentTerminals({ revenue_center_id }))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const paymentTerminalSlice = createSlice({
  name: 'paymentTerminal',
  initialState,
  reducers: {
    resetPaymentTerminal: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPaymentTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPaymentTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchPaymentTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createPaymentTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createPaymentTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createPaymentTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updatePaymentTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updatePaymentTerminal.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updatePaymentTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deletePaymentTerminal.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deletePaymentTerminal.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(deletePaymentTerminal.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetPaymentTerminal } = paymentTerminalSlice.actions

export const selectPaymentTerminal = (state: RootState) => state.paymentTerminal

export default paymentTerminalSlice.reducer
