import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { CampaignRecipients } from 'types'
import { RequestError, RequestStatus } from 'utils'

export interface CampaignRecipientsState {
  data: CampaignRecipients
  loading: RequestStatus
  error: RequestError | null
}

const initialState: CampaignRecipientsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCampaignRecipients = createAsyncThunk<
  CampaignRecipients,
  { campaignId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/getCampaignRecipients',
  async ({ campaignId }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaignId}/get-recipients-quantity`
      const resp = (await api?.request(endpoint)) as CampaignRecipients
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const campaignRecipientsSlice = createSlice({
  name: 'campaignRecipients',
  initialState,
  reducers: {
    resetCampaignRecipients: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCampaignRecipients.pending, state => {
        state.loading = RequestStatus.Pending
      })
      .addCase(fetchCampaignRecipients.fulfilled, (state, { payload }) => {
        state.loading = RequestStatus.Succeeded
        state.data = payload
      })
      .addCase(fetchCampaignRecipients.rejected, (state, { payload }) => {
        state.loading = RequestStatus.Failed
        state.error = payload
      })
  }
})

export const resetCampaignRecipients = campaignRecipientsSlice.actions

export const selectCampaignRecipients = (state: RootState) =>
  state.campaignRecipients

export default campaignRecipientsSlice.reducer
