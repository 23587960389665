import styled from '@emotion/styled'

export const PagesContent = styled.div`
  label: PagesContent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${props => props.theme.layout.desktop.padding};
`

export const PagesForm = styled.div`
  label: PagesForm;
  flex: 0 0 ${props => props.theme.layout.desktop.pageWidth};
`

export const PagesSections = styled.div`
  label: PagesSections;
  flex: 1 1 32rem;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout.desktop.padding};
`

export const PagesSection = styled.div`
  label: PagesSection;
  width: 100%;
`

export const PagesSectionHeader = styled.div`
  label: PagesSectionHeader;
  padding: 0 0 1rem;
  border-bottom-style: solid;
  border-bottom-width: ${props => props.theme.layout.desktop.border};
  border-bottom-color: ${props => props.theme.colors.border.primary};
  margin: 0 0 3rem;
`
