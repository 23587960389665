import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { Announcement } from 'types'
import { showNotification } from 'slices/notification'

export interface AnnouncementState {
  data: Announcement | null
  loading: RequestStatus
  error: RequestError
}

const initialState: AnnouncementState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAnnouncement = createAsyncThunk<
  Announcement,
  string | number,
  { state: RootState; rejectValue: RequestError }
>(
  'announcement/fetchAnnouncement',
  async (announcementId, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcements/${announcementId}`
      const resp = (await api?.request(endpoint)) as Announcement
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createAnnouncement = createAsyncThunk<
  Announcement,
  { data: Announcement; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'announcement/createAnnouncement',
  async ({ data, navigate }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'announcements',
        'POST',
        data
      )) as Announcement
      dispatch(showNotification('Announcement successfully created!'))
      const pathname = `/settings/comms/announcements/${resp.announcement_id}`
      navigate({ pathname })
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateAnnouncement = createAsyncThunk<
  Announcement,
  {
    announcementId: string | number
    data: Announcement
  },
  { state: RootState; rejectValue: RequestError }
>(
  'announcement/updateAnnouncement',
  async ({ announcementId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcements/${announcementId}`
      const resp = (await api?.request(endpoint, 'PUT', data)) as Announcement
      dispatch(showNotification('Announcement successfully updated!'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteAnnouncement = createAsyncThunk<
  void,
  {
    announcementId: string | number
    navigate: NavigateFunction
  },
  { state: RootState; rejectValue: RequestError }
>(
  'announcement/deleteAnnouncement',
  async (
    { announcementId, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `announcements/${announcementId}`
      await api?.request(endpoint, 'DELETE')
      dispatch(showNotification('Announcement removed!'))
      navigate({ pathname: '/website/announcements' })
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    resetAnnouncement: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAnnouncement.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAnnouncement.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchAnnouncement.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(createAnnouncement.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createAnnouncement.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createAnnouncement.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateAnnouncement.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateAnnouncement.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateAnnouncement.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteAnnouncement.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteAnnouncement.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.data = initialState.data
      state.error = null
    })
    builder.addCase(deleteAnnouncement.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetAnnouncement } = announcementSlice.actions

export const selectAnnouncement = (state: RootState) => state.announcement

export default announcementSlice.reducer
