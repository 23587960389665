import { useState } from 'react'
import { Button, OnboardingSteps } from 'components'
import { ArrowLeftCircle, ArrowRightCircle } from 'iconoir-react'
import {
  OnboardingWelcomeButton,
  OnboardingWelcomeFooter,
  OnboardingWelcomeTitle
} from './OnboardingWelcome.styled'
import OnboardingWelcomePager from './OnboardingWelcomePager'

const OnboardingWelcome = () => {
  const [currentStep, setCurrentStep] = useState(1)

  return (
    <>
      <OnboardingWelcomeTitle as="h2" size="h4">
        Welcome to the Onboarding Module
      </OnboardingWelcomeTitle>
      <OnboardingSteps currentStep={currentStep} />
      <OnboardingWelcomeFooter>
        <OnboardingWelcomeButton>
          <Button
            color="primary"
            size="small"
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 1}
          >
            <ArrowLeftCircle width={16} height={16} strokeWidth={1.5} />
            <span>Previous</span>
          </Button>
        </OnboardingWelcomeButton>
        <OnboardingWelcomePager
          steps={[1, 2, 3, 4]}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
        <OnboardingWelcomeButton>
          <Button
            color="primary"
            size="small"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === 4}
          >
            <span>Next</span>
            <ArrowRightCircle width={16} height={16} strokeWidth={1.5} />
          </Button>
        </OnboardingWelcomeButton>
      </OnboardingWelcomeFooter>
    </>
  )
}

export default OnboardingWelcome
