import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { PosServers } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface PosServersState {
  data: PosServers
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface PosServersAPI {
  data: PosServers
  links: {
    next: string
  }
}

const initialState: PosServersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export type PosServersParams = {
  cursor?: string
  is_active?: boolean
  limit?: number
  revenue_center_id?: number
  search?: string
  with_related?: string
}

export const fetchPosServers = createAsyncThunk<
  PosServersAPI,
  PosServersParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'posServers/fetchPosServers',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `pos-v2/pos-servers${queryParams}`
      return (await api?.request(endpoint)) as PosServersAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const posServersSlice = createSlice({
  name: 'posServers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPosServers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchPosServers.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchPosServers.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const selectPosServers = (state: RootState) => state.posServers

export default posServersSlice.reducer
