import styled from '@emotion/styled'

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`

export const QrCodeDownload = styled.div`
  display: none;
`
