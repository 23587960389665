import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'
import {
  Attribute,
  AttributesAPI,
  AttributesFilters,
  AttributesParams,
  AttributesState
} from 'types/attributes'
import { Items } from 'types'

const initialState: AttributesState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchAttributes = createAsyncThunk<
  AttributesAPI,
  AttributesParams,
  { state: RootState; rejectValue: RequestError }
>(
  'attributes/fetchAttributes',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `attributes${queryParams}`
      const resp = (await api?.request(endpoint)) as AttributesAPI
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

const validateValues = (updated: Attribute[]): Attribute[] => {
  return updated.map(i => {
    let value
    if (i.attribute_type === 'SERVING_SIZE') {
      value = Math.abs(parseFloat(i.attribute_value)).toFixed(2)
    } else if (i.attribute_type === 'PRICE') {
      value = parseFloat(i.attribute_value).toFixed(2)
    } else {
      value = Math.abs(parseInt(i.attribute_value)).toFixed(0)
    }
    return value === 'NaN'
      ? { ...i, error: 'Invalid' }
      : { ...i, attribute_value: value }
  })
}

export const updateAttributes = createAsyncThunk<
  Attribute[],
  void,
  { state: RootState; rejectValue: RequestError }
>(
  'attributes/updateAttributes',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const attributes = getState().attributes.data
      const deleted = attributes
        .filter(i => !i.attribute_value.length)
        .map(i => ({ ...i, attribute_value: '0.00' }))
      const updated = attributes.filter(i => i.attribute_value.length)
      const validated = validateValues(updated)
      const errors = validated.filter(i => i.error)
      if (errors.length) {
        const msg = 'One or more values is invalid. Please see below.'
        dispatch(setAttributesErrors(msg))
        return validated
      }

      const api = getState().authUser.api
      const endpoint = `attributes`

      let postRequest = null
      if (validated.length) {
        postRequest = api?.request(endpoint, 'POST', validated)
      }
      let deleteRequest = null
      if (deleted.length) {
        deleteRequest = api?.request(endpoint, 'DELETE', deleted)
      }

      Promise.all([postRequest, deleteRequest]).then(() =>
        dispatch(showNotification('Successfully updated!'))
      )

      return validated
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    resetAttributes: () => initialState,
    setAttributes: (state: AttributesState, { payload }) => {
      return {
        ...state,
        data: payload
      }
    },
    updateAttribute: (
      state: AttributesState,
      {
        payload
      }: { payload: { attribute: Attribute; filters: AttributesFilters } }
    ) => {
      const attributes = state.data
      const { attribute, filters } = payload
      const targetAttribute = attributes.filter(
        i =>
          i.item_id === attribute.item_id &&
          i.region_id === attribute.region_id &&
          i.service_type === attribute.service_type &&
          i.revenue_center_id === attribute.revenue_center_id
      )

      let newAttribute: Attribute
      if (targetAttribute.length) {
        newAttribute = { ...targetAttribute[0], ...attribute }
      } else {
        if (attributes.length) {
          newAttribute = { ...attributes[0], ...attribute }
        } else {
          let values: Partial<Attribute> = {
            attribute_type: filters.attribute_type
          }
          if (filters.category_id) {
            values.category_id = filters.category_id
            values.modifier_group_id = null
          } else {
            values.category_id = null
            values.modifier_group_id = filters.modifier_group_id
          }
          newAttribute = { ...values, ...attribute }
        }
      }
      if (newAttribute.error) delete newAttribute.error

      const otherAttributes = attributes.filter(
        i =>
          i.item_id !== attribute.item_id ||
          i.region_id !== attribute.region_id ||
          i.service_type !== attribute.service_type ||
          i.revenue_center_id !== attribute.revenue_center_id
      )
      const newAttributes = [...otherAttributes, newAttribute]
      return {
        ...state,
        data: newAttributes
      }
    },
    copyDown: (
      state: AttributesState,
      {
        payload
      }: {
        payload: {
          regionId: number | null
          revenueCenterId: number | null
          serviceType: any
          filters: AttributesFilters
          items: Items
        }
      }
    ) => {
      const { regionId, revenueCenterId, serviceType, filters, items } = payload
      const attributes = state.data
      const sameColumnAttributes = attributes.filter(
        i =>
          i.region_id === regionId &&
          i.service_type === serviceType &&
          i.revenue_center_id === revenueCenterId
      )

      const firstAttribute = sameColumnAttributes.find(
        i => i.item_id === items[0].item_id
      )

      let values: Partial<Attribute> = {
        attribute_type: filters.attribute_type,
        attribute_value: firstAttribute ? firstAttribute.attribute_value : '',
        service_type: serviceType,
        region_id: regionId,
        revenue_center_id: revenueCenterId
      }

      if (filters.category_id) {
        values.category_id = filters.category_id
        values.modifier_group_id = null
      } else if (filters.modifier_group_id) {
        values.category_id = null
        values.modifier_group_id = filters.modifier_group_id
      }

      let newAttributes = items.map(i => ({
        ...values,
        item_id: i.item_id
      })) as Attribute[]

      const otherAttributes = attributes.filter(
        i =>
          i.region_id !== regionId ||
          i.service_type !== serviceType ||
          i.revenue_center_id !== revenueCenterId
      )

      const allAttributes = [...otherAttributes, ...newAttributes]
      return {
        ...state,
        data: allAttributes
      }
    },
    setAttributesErrors: (state: AttributesState, { payload }) => {
      return {
        ...state,
        error: payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAttributes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchAttributes.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchAttributes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateAttributes.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateAttributes.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(updateAttributes.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const {
  resetAttributes,
  setAttributes,
  setAttributesErrors,
  copyDown,
  updateAttribute
} = attributesSlice.actions

export const selectAttributes = (state: RootState) => state.attributes
export default attributesSlice.reducer
