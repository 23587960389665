import styled from '@emotion/styled'
import TextBody from '../TextBody'
import TextHeading from '../TextHeading'

const ModalHeaderView = styled.div`
  margin: 0 0 3rem;
`

const ModalHeaderTitle = styled(TextHeading)`
  margin-left: -0.1rem;
`

const ModalHeaderSubtitle = styled(TextBody)`
  margin: 1rem 0 0;
  line-height: 1.4;
`

const ModalHeaderPreface = styled(TextBody)`
  margin: 0 0 1rem;
`

interface ModalHeaderProps {
  title: string
  subtitle?: string
  preface?: string | null
}

const ModalHeader = ({ title, subtitle, preface }: ModalHeaderProps) => {
  return (
    <ModalHeaderView>
      {preface && (
        <ModalHeaderPreface as="p" color="secondary" size="small">
          {preface}
        </ModalHeaderPreface>
      )}
      <ModalHeaderTitle as="h2" size="h2">
        {title}
      </ModalHeaderTitle>
      {subtitle && (
        <ModalHeaderSubtitle as="p" color="secondary">
          {subtitle}
        </ModalHeaderSubtitle>
      )}
    </ModalHeaderView>
  )
}

export default ModalHeader
