import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

export const OrderEditFull = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 3rem 0;
`

export const OrderEditWarning = styled(TextBody)`
  color: ${props => props.theme.colors.error.background};
`
