import { ArrowDown, ArrowUp } from 'iconoir-react'
import { TableSort } from 'types'
import {
  TableHeaderSortableIconWrapper,
  TableHeaderSortableView
} from './Table.styled'

interface TableHeaderSortableProps {
  text: string
  value: string
  sort: TableSort
  onClick: (value: string) => void
}

const TableHeaderSortable = ({
  text,
  value,
  sort,
  onClick
}: TableHeaderSortableProps) => {
  return (
    <TableHeaderSortableView onClick={() => onClick(value)}>
      <strong>{text}</strong>
      <TableHeaderSortableIconWrapper>
        {sort.order === 'asc' && sort.orderBy === value && (
          <ArrowUp fontSize={8} />
        )}
        {sort.order === 'desc' && sort.orderBy === value && (
          <ArrowDown fontSize={8} />
        )}
      </TableHeaderSortableIconWrapper>
    </TableHeaderSortableView>
  )
}

export default TableHeaderSortable
