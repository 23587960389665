import styled from '@emotion/styled'
import TextBody from 'components/TextBody'

export const MenuItemOptionView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;
`

export const MenuItemOptionName = styled(TextBody)`
  display: block;
  flex: 1 1 auto;

  & > span {
    color: ${props => props.theme.colors.text.secondary};
    display: inline-black;
    margin: 0 0 0 0.7rem;
  }
`

export const MenuItemOptionPrice = styled(TextBody)`
  flex: 0 0 7rem;
  text-align: right;
  // background-color: pink;
`

export const MenuItemOptionQuantity = styled.span`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 15rem;
`

export const MenuItemOptionRadio = styled.button<{ isChecked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 100%;
  border-style: solid;
  border-width: ${props => props.theme.layout.desktop.border};
  border-color: ${props =>
    props.isChecked
      ? props.theme.colors.text.primary
      : props.theme.colors.border.primary};

  & > span {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    transition: all 0.4s ease;
    border-radius: 100%;
    background-color: ${props =>
      props.isChecked
        ? props.theme.colors.text.primary
        : props.theme.colors.background.secondary};
  }
`
