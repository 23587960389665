import {
  OnboardingWelcomePagerButton,
  OnboardingWelcomePagerView
} from './OnboardingWelcome.styled'

const OnboardingWelcomePager = ({
  steps,
  currentStep,
  setCurrentStep
}: {
  steps: number[]
  currentStep: number
  setCurrentStep: (step: number) => void
}) => {
  return (
    <OnboardingWelcomePagerView>
      {steps.map(step => (
        <OnboardingWelcomePagerButton
          key={step}
          disabled={step === currentStep}
          onClick={() => setCurrentStep(step)}
        />
      ))}
    </OnboardingWelcomePagerView>
  )
}

export default OnboardingWelcomePager
