import { Routes, Route, Navigate } from 'react-router-dom'
import { Auth, Reset, SendReset, SignUp } from 'pages'

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="signup" element={<SignUp />} />
      <Route path="reset" element={<Reset />} />
      <Route path="send-reset" element={<SendReset />} />
      <Route index element={<Auth />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AuthRoutes
