import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { OrderNotifications, ServiceType } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface OrderNotificationsState {
  data: OrderNotifications
  loading: RequestStatus
  error: RequestError
}

export type OrderNotificationsParams = {
  service_type?: ServiceType
}

const initialState: OrderNotificationsState = {
  data: [],
  loading: RequestStatus.Idle,
  error: null
}

export const fetchOrderNotifications = createAsyncThunk<
  OrderNotifications,
  OrderNotificationsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'orderNotifications/fetchOrderNotifications',
  async (params, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const endpoint = `notification-settings${queryParams}`
      const api = getState().authUser.api
      return (await api?.request(endpoint)) as OrderNotifications
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const orderNotificationsSlice = createSlice({
  name: 'orderNotifications',
  initialState,
  reducers: {
    resetOrderNotifications: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderNotifications.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchOrderNotifications.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchOrderNotifications.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetOrderNotifications } = orderNotificationsSlice.actions

export const selectOrderNotifications = (state: RootState) =>
  state.orderNotifications

export default orderNotificationsSlice.reducer
